import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { RECORDING_VIDEO_STATUS } from 'constants.js';
import Button from 'components/FormUI/Button';
import { useAccount, useVideoChatActions } from 'hooks';
import VideoPlayer from 'components/UI/VideoPlayer/VideoPlayer';
import PopupOptions from 'components/PopupOptions/PopupOptions';
import useVideoPlayer from 'hooks/useVideoPlayer';
import Popup from 'components/Popup/Popup';
import styled from 'styled-components';
import { UserRoles } from 'helpers/constants';

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const LaunchRecordedSessionVideo = ({ session, contribution: { id, type, chat, title }, sessionTime, mobileView }) => {
  const [isPlayVideo, setIsPlayVideo] = useState(false);
  const [isShowOptionsPopup, setIsShowOptionsPopup] = useState(false);
  const [isShowInfoPopup, setIsShowInfoPopup] = useState(false);
  const { currentRole } = useAccount();

  const { startVideoChat } = useVideoChatActions();
  const { loading, getVideoLink, error, link } = useVideoPlayer();
  console.log(session?.isPrerecorded);
  const makeOptions = recordingInfos => {
    return recordingInfos.map((info, i) => ({
      text: `${i + 1} ${session?.title || ''} ${title || ''} ${moment.utc(info.duration * 1000).format('HH:mm:ss')}`,
      value: { contributionId: id, roomId: info.roomId, status: info.status },
    }));
  };
  const options = makeOptions(sessionTime.recordingInfos);

  const checkVideoStatus = status => {
    if (status !== RECORDING_VIDEO_STATUS.available) {
      setIsShowOptionsPopup(false);
      setIsShowInfoPopup(true);
      return false;
    }
    return true;
  };

  const onCloseVideo = () => {
    setIsPlayVideo(false);
  };

  const onClosePopup = () => {
    setIsShowOptionsPopup(false);
    setIsShowInfoPopup(false);
  };

  const onSelectOption = option => {
    if (!checkVideoStatus(option.status)) return;
    getVideoLink(option);
    setIsShowOptionsPopup(false);
    setIsPlayVideo(true);
  };

  const onPlayVideo = () => {
    startVideoChat({
      contributionId: id,
      type,
      sessionId: session?.id,
      classId: sessionTime?.id,
      title: session?.title,
      chatId: chat?.sid,
      token: '',
    });

    if (options.length === 1) {
      if (!checkVideoStatus(options[0].value.status)) return;
      setIsPlayVideo(true);
      getVideoLink(options[0].value);
    } else {
      setIsShowOptionsPopup(true);
    }
  };

  const isFirstVideoViewable = sessionTime.recordingInfos[0]?.status === RECORDING_VIDEO_STATUS.available;

  return (
    <>
      {(currentRole === UserRoles.cohealer || sessionTime.isCompleted) && (
        <StyledButton autoWidth onClick={onPlayVideo} mobileView={mobileView} disabled={!isFirstVideoViewable}>
          {isFirstVideoViewable ? 'View recording' : 'Processing Recording'}
        </StyledButton>
      )}
      {isPlayVideo && (
        <VideoPlayer onCloseVideo={onCloseVideo} chatId={chat?.sid} link={link} error={error} loading={loading} />
      )}
      {isShowOptionsPopup && (
        <PopupOptions
          title="Please select the video you would like to view"
          options={options}
          onSelectOption={onSelectOption}
          open={isShowOptionsPopup}
          onClose={onClosePopup}
        />
      )}
      {isShowInfoPopup && (
        <Popup
          title="Video is processing"
          text="Video is processing. Please, try out later!"
          open={isShowInfoPopup}
          onCloseText="OK"
          onClose={onClosePopup}
        />
      )}
    </>
  );
};

LaunchRecordedSessionVideo.defaultProps = {
  session: {},
  mobileView: false,
};

LaunchRecordedSessionVideo.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    chat: PropTypes.shape({
      sid: PropTypes.string,
    }),
  }).isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
  }),
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
    isCompleted: PropTypes.bool,
    recordingInfos: PropTypes.shape().isRequired,
  }).isRequired,
  mobileView: PropTypes.bool,
};

export default LaunchRecordedSessionVideo;
