import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { useAccount } from 'hooks';

import { colors } from 'utils/styles';
import { PageSubtitle } from 'components/UI/Text/TextStyles';
import Loader from 'components/UI/Loader';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarContributionView.scss';
import { TIMEZONES } from 'constants.js';
import { UserRoles } from 'helpers/constants';

const localizer = momentLocalizer(moment);

const allViews = [Views.MONTH];

const CalendarContributionView = ({
  events,
  onSelectEvent,
  onDrillDown,
  calendarRef,
  dateToShow,
  loadingTimes,
  timeZoneId,
}) => {
  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;

  return (
    <div className="calendar-container" ref={calendarRef}>
      <PageSubtitle>
        Session Availability in {TIMEZONES[timeZoneId]}.{' '}
        {isCoach
          ? 'These are the session times that can be booked.'
          : 'Choose a time on the calendar below that works best for you.'}
      </PageSubtitle>
      {loadingTimes && (
        <div className="custom-calendar" style={{ position: 'relative' }}>
          <div className="rbc-month-view">
            <div className="">
              <Loader relative />
            </div>
          </div>
        </div>
      )}
      {!loadingTimes && (
        <BigCalendar
          className="custom-calendar"
          localizer={localizer}
          events={events}
          views={allViews}
          defaultView={Views.MONTH}
          // date={dateToShow}
          step={30}
          showMultiDayTimes
          titleAccessor={event => `${event.title.substring(0, 15)}...`}
          defaultDate={new Date()}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={onSelectEvent}
          onDrillDown={onDrillDown}
          eventPropGetter={event => {
            const newStyle = {
              backgroundColor: colors.darkOceanBlue,
            };

            if (event.isBooked) {
              newStyle.backgroundColor = colors.darkBlue;
            }

            return {
              className: '',
              style: newStyle,
            };
          }}
        />
      )}
    </div>
  );
};

CalendarContributionView.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  calendarRef: PropTypes.shape({ current: PropTypes.any }),
  loadingTimes: PropTypes.bool,
  timeZoneId: PropTypes.string,
};

CalendarContributionView.defaultProps = {
  calendarRef: null,
  loadingTimes: false,
};

const mapStateToProps = ({ contributions }) => ({
  loadingTimes: contributions?.loadingTimes,
});

export default connect(mapStateToProps, null)(CalendarContributionView);
