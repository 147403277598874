import React, { useEffect, useState } from 'react';
import CreatePost from './components/CreatePost/CreatePost';
import Grid from '@material-ui/core/Grid';
import PostList from './components/PostList';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../actions/community';
import useContribution from '../hooks/useContribution';
import InfiniteScroll from 'react-infinite-scroll-component';
import { COUNT_POSTS_BY_PAGE } from '../../../constants';
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import { useHttp } from '../../../hooks';

const StyledWrap = styled(Grid)`
  padding-left: 30px;
  ${({ mobileView }) =>
    mobileView &&
    `
  padding-left: 0;
  `}
`;

const ContributionSocialContainer = () => {
  const dispatch = useDispatch();
  const contribution = useContribution();
  const [isLoad, setIsLoad] = useState(false);
  const communityPosts = useSelector(({ communityPosts }) => communityPosts);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(actions.getAllPostsForContribution(`${contribution.id}/1/${COUNT_POSTS_BY_PAGE}`)).then(() => {
      setIsLoad(true);
    });
  }, [dispatch, contribution.id]);

  const fetchMoreData = () => {
    dispatch(
      actions.getByPagePostsForContribution(
        `${contribution.id}/${communityPosts.currentPage + 1}/${COUNT_POSTS_BY_PAGE}`,
      ),
    );
  };
  const Loader = () => {
    return (
      <Grid container justify="center" className="mb-4 mt-4">
        <CircularProgress color="primary" />
      </Grid>
    );
  };
  return (
    <>
      {isLoad ? (
        <>
          <StyledWrap mobileView={mobileView} container>
            <Grid item xs={12} md={11}>
              <Grid container>
                <CreatePost item={communityPosts.userDraftPost} />
              </Grid>
            </Grid>
          </StyledWrap>
          <InfiniteScroll
            dataLength={communityPosts.posts.length}
            next={fetchMoreData}
            pullDownToRefreshThreshold={0}
            hasMore={communityPosts.currentPage < communityPosts.totalPages}
            loader={<Loader />}
          >
            <StyledWrap mobileView={mobileView} container className="mb-4 mt-4">
              <Grid item xs={12} md={11}>
                <PostList items={communityPosts.posts} />
              </Grid>
            </StyledWrap>
          </InfiniteScroll>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default ContributionSocialContainer;
