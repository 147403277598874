export const SESSIONS_TYPES = {
  COMPLETED: 'completed',
  UPCOMING: 'upcoming',
  ALL: 'all',
};

export const FILTER_TABS_OPTIONS = [
  {
    title: 'Upcoming',
    value: SESSIONS_TYPES.UPCOMING,
  },
  {
    title: 'Completed',
    value: SESSIONS_TYPES.COMPLETED,
  },
  {
    title: 'All',
    value: SESSIONS_TYPES.ALL,
  },
];
