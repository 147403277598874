import * as R from 'ramda';

import { handleActions, combineActions } from 'redux-actions';

import * as actions from 'actions/contributions';
import { SET_INVITE_CODE } from 'actions/contributions';

const initialState = {
  loading: false,
  error: null,
  contributionsCollection: {},
  activeContribution: null,
  adminContributions: null,
  inviteCode: null,
};

const reducerContribution = handleActions(
  new Map([
    [
      combineActions(actions.fetchContributionsActions.request, actions.fetchContributionAdminActions.request),
      state => ({
        ...state,
        error: null,
        loading: true,
        activeContribution: null,
      }),
    ],
    [
      combineActions(actions.createContributionActions.request, actions.fetchContributionActions.request),
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.fetchContributionsActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        contributionsCollection: action.payload,
      }),
    ],
    [
      combineActions(
        actions.fetchContributionActions.success,
        actions.getContributionFromLS,
        actions.saveContributionToLS,
        actions.saveContribution,
      ),
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: null,
          activeContribution: action.payload,
        };
      },
    ],
    [
      actions.fetchContributionAdminActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        adminContributions: action.payload,
      }),
    ],
    [
      combineActions(
        actions.fetchContributionsActions.error,
        actions.fetchContributionActions.error,
        actions.fetchContributionAdminActions.error,
      ),
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        contributionsCollection: [],
        activeContribution: null,
        adminContributions: [],
      }),
    ],
    [
      actions.resetContribution,
      state => ({
        ...state,
        activeContribution: null,
      }),
    ],
    [
      actions.createContributionActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],
    [
      actions.createContributionActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        activeContribution: null,
      }),
    ],
    [
      actions.rescheduleSessionActions.request,
      state => ({
        ...state,
      }),
    ],
    [
      actions.rescheduleSessionActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],
    [
      actions.rescheduleSessionActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        activeContribution: action.payload,
      }),
    ],
    [
      actions.updateSessionAttachments,
      (state, { payload: { sessionId, documentId } }) => {
        const {
          activeContribution: { sessions, timeZoneId },
        } = state;
        const currentSessionIdx = R.findIndex(R.propEq('id', sessionId))(sessions);
        const currentSession = R.find(R.propEq('id', sessionId))(sessions);

        return {
          ...state,
          activeContribution: {
            ...state.activeContribution,
            timeZoneId,
            sessions: R.update(
              currentSessionIdx,
              {
                ...currentSession,
                attachments: R.remove(
                  R.findIndex(R.propEq('id', documentId))(currentSession.attachments),
                  1,
                  currentSession.attachments,
                ),
              },
              sessions,
            ),
          },
        };
      },
    ],
    [
      actions.addSessionAttachments,
      (state, { payload: { sessions } }) => {
        return {
          ...state,
          activeContribution: {
            ...state.activeContribution,
            sessions,
          },
        };
      },
    ],
    [
      actions.updateOneToOneSessionAttachments,
      (state, { payload: { documentId } }) => {
        const {
          activeContribution: { availabilityTimes },
        } = state;

        const times = availabilityTimes.map(time => ({
          ...time,
          bookedTimes: time.bookedTimes.map(booked => ({
            ...booked,
            attachments: booked.attachments.filter(attach => attach.id !== documentId),
          })),
        }));

        return {
          ...state,
          activeContribution: {
            ...state.activeContribution,
            availabilityTimes: times,
          },
        };
      },
    ],
    [
      actions.updateSubscriptionInfo,
      (state, { payload }) => {
        return {
          ...state,
          activeContribution: {
            ...state.activeContribution,
            subscriptionStatus: payload,
          },
        };
      },
    ],
    [
      actions.updateContributionActions.request,
      state => ({
        ...state,
        updating: true,
      }),
    ],
    [
      actions.updateContributionActions.success,
      (state, action) => ({
        ...state,
        updating: false,
        activeContribution: {
          ...action.payload,
          serviceProviderName: state.activeContribution.serviceProviderName,
        },
      }),
    ],
    [
      actions.updateContributionActions.error,
      (state, { payload: { message } }) => ({
        ...state,
        updating: false,
        error: {
          message,
        },
      }),
    ],
    [
      actions.fetchContributionTimesActions.request,
      state => ({
        ...state,
        loadingTimes: false,
      }),
    ],
    [
      actions.fetchContributionTimesActions.success,
      (state, action) => ({
        ...state,
        loadingTimes: false,
        contributionTimes: action.payload,
      }),
    ],
    [
      actions.fetchContributionTimesActions.error,
      (state, { payload: { message } }) => ({
        ...state,
        loadingTimes: false,
        error: {
          message,
        },
      }),
    ],
    [
      actions.fetchEditContributionTimesActions.request,
      state => ({
        ...state,
        loadingTimes: true,
      }),
    ],
    [
      actions.fetchEditContributionTimesActions.success,
      (state, action) => ({
        ...state,
        loadingTimes: false,
        editContributionTimes: action.payload,
      }),
    ],
    [
      actions.fetchEditContributionTimesActions.error,
      (state, { payload: { message } }) => ({
        ...state,
        loadingTimes: false,
        error: {
          message,
        },
      }),
    ],
    [
      actions.calculateCreateSlotsActions.request,
      state => ({
        ...state,
        loadingTimes: true,
      }),
    ],
    [
      actions.calculateCreateSlotsActions.success,
      (state, action) => ({
        ...state,
        loadingTimes: false,
        calculatedSlots: action.payload,
      }),
    ],
    [
      SET_INVITE_CODE,
      (state, action) => ({
        ...state,
        inviteCode: action.payload,
      }),
    ],
    [
      actions.calculateCreateSlotsActions.error,
      (state, { payload: { message } }) => ({
        ...state,
        loadingTimes: false,
        error: {
          message,
        },
      }),
    ],
  ]),
  initialState,
);

export default reducerContribution;
