import React from 'react';

import Grid from '@material-ui/core/Grid';

import VerificationCard from './components/VerificationCard/VerificationCard';
import BankAccountCard from './components/BankAccountCard/BankAccountCard';
import BalanceCard from './components/BalanceCard/BalanceCard';
import SalesSummaryCard from './components/SalesSummaryCard/SalesSummaryCard';

import './Payment.scss';

function Payment() {
  return (
    <Grid container direction="column" alignItems="center" spacing={3}>
      <VerificationCard />
      <BankAccountCard />
      <BalanceCard />
      <SalesSummaryCard />
    </Grid>
  );
}

export default Payment;
