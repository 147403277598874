import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { useAccount } from 'hooks';

import Banner from 'components/Banner/Banner';

import './BannerContainer.scss';

const BannerContainer = ({ closestClassForBanner, coachDeclinedSubscriptions }) => {
  const [bannerTypes, setBannerTypes] = useState([]);
  const { user } = useAccount();

  const getType = useCallback(() => {
    const types = [];
    if (!user.isEmailConfirmed) {
      types.push('email');
    }
    if (!user.transfersEnabled || !user.payoutsEnabled) {
      types.push('bankInfo');
    }
    if (!isEmpty(closestClassForBanner)) {
      types.push('closestSession');
    }
    if (user.transfersEnabled) {
      types.push('testVideo');
    }
    if (!user.isSocialSecurityCheckPassed && user.isFirstAcceptedCourseExists) {
      types.push('socialSecurityInfo');
    }
    if (coachDeclinedSubscriptions && coachDeclinedSubscriptions.length > 0) {
      types.push('coachDeclinedSubscriptions');
    }
    setBannerTypes(types);
  }, [
    user.isEmailConfirmed,
    user.transfersEnabled,
    user.payoutsEnabled,
    closestClassForBanner,
    coachDeclinedSubscriptions,
    user.isSocialSecurityCheckPassed,
    setBannerTypes,
  ]);

  useEffect(() => {
    getType();
  }, [getType]);

  return (
    <div className="banners-cohealer">
      {bannerTypes.map(type => (
        <Banner
          type={type}
          closestClassForBanner={closestClassForBanner}
          coachDeclinedSubscriptions={coachDeclinedSubscriptions}
        />
      ))}
    </div>
  );
};

BannerContainer.defaultProps = {
  closestClassForBanner: {},
};

BannerContainer.propTypes = {
  closestClassForBanner: PropTypes.shape({
    contributionId: PropTypes.string.isRequired,
    classId: PropTypes.string.isRequired,
    contributionTitle: PropTypes.string.isRequired,
    minutesLeft: PropTypes.number.isRequired,
  }),
  coachDeclinedSubscriptions: PropTypes.shape({}),
};

export default BannerContainer;
