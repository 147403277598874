import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';

import chatService from 'services/chat.service';
import Modal from 'components/UI/Modal';
import { LabelText } from 'components/UI/Text/TextStyles';
import { deleteClient } from 'services/contributions.service';
import { useRouter } from 'hooks';

import './CourseClients.scss';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { fetchCohealerContribution } from 'actions/contributions';
import CloseIcon from '@material-ui/icons/Close';
import useAccount from 'hooks/useAccount';
import find from 'lodash/find';

const ClientContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 0 auto;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  justify-content: space-between;
  align-items: center;
`;

const UserWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;
const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  width: 60px !important;
`;
const CourseClients = ({ contribution: { participants } }) => {
  const { history } = useRouter();
  const dispatch = useDispatch();
  const { user } = useAccount();
  const contribution = useContribution();

  const isHasAccessRemove =
    user.id === contribution.userId || find(contribution.contributionPartners, { userId: user.id });

  const contributionId = useSelector(state => state.contributions.activeContribution.id);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [participantIdToDelete, setParticipantIdToDelete] = useState('');
  const [participantNameToDelete, setParticipantNameToDelete] = useState('');
  const onStartDirectChat = useCallback(
    participantId => {
      chatService
        .getPersonalChatWithAsync(participantId)
        .then(({ sid }) => history.push(`/conversations/all`, { preferredConversationId: sid }));
    },
    [history],
  );
  const handleDeleteParticipant = useCallback(() => {
    deleteClient(contributionId, participantIdToDelete).then(() => {
      dispatch(fetchCohealerContribution(contributionId)).then(() => {
        setIsOpenConfirmation(false);
      });
    });
  }, [contributionId, participantIdToDelete]);

  const handleOpenConfirmation = useCallback((participantId, firstName, lastName) => {
    setIsOpenConfirmation(true);
    setParticipantNameToDelete(`${firstName} ${lastName}`);
    setParticipantIdToDelete(participantId);
  }, []);
  const handleCancel = useCallback(() => {
    setIsOpenConfirmation(false);
  }, []);
  return (
    <div className="course-clients">
      <p className="course-clients__header">Clients ({participants.length})</p>
      <div className="course-clients-list">
        {participants.map(({ id, firstName, lastName, avatarUrl, isAddedByAccessCode }) => (
          <ClientContainer>
            <UserWrapper
              key={id}
              className="course-clients-list__item course-clients-list-item"
              onClick={() => onStartDirectChat(id)}
            >
              <Avatar src={avatarUrl}>{`${firstName[0]}${lastName[0]}`.toUpperCase()}</Avatar>

              <p className="course-clients-list-item__name">
                {firstName} {lastName}
              </p>
            </UserWrapper>
            {isAddedByAccessCode && isHasAccessRemove && (
              <StyledCloseIcon onClick={() => handleOpenConfirmation(id, firstName, lastName)} />
            )}
          </ClientContainer>
        ))}
      </div>
      {isOpenConfirmation && (
        <Modal
          isOpen={isOpenConfirmation}
          onCancel={handleCancel}
          onSubmit={handleDeleteParticipant}
          title="Remove client"
        >
          <LabelText>Are you sure you wish to remove {participantNameToDelete}?</LabelText>
        </Modal>
      )}
    </div>
  );
};

CourseClients.propTypes = {
  contribution: PropTypes.shape({
    participants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        avatarUrl: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default CourseClients;
