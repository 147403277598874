import React, { useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import Input from 'components/FormUI/Input';
import MaskedInput from 'components/FormUI/MaskedInput';
import { LabelText } from 'components/UI/Text/TextStyles';
import { StyledLink } from 'pages/Auth/SignInPage';
import Select from 'components/FormUI/Select';
import {
  ACCOUNT_FORM_HEADERS,
  ACCOUNT_FORM_FIELDS,
  RESTORE_PASS,
  PURCHASE_MODAL_STEPS,
} from '../PurchaseModal.constants';

import './AccountForm.scss';

export const AccountForm = ({ handleChange, touched, errors, step }) => {
  const isShowInput = (step, input) => {
    const visible = {
      memberInit: [ACCOUNT_FORM_FIELDS.email],
      memberLogin: [ACCOUNT_FORM_FIELDS.email, ACCOUNT_FORM_FIELDS.password, RESTORE_PASS],
      memberCreate: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.birthDate,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
      ],
    };
    return visible[step].includes(input);
  };

  const confirmEmailRef = useRef(null);
  const emailRef = useRef(null);
  useEffect(() => {
    const disablePaste = e => {
      e.preventDefault();
    };

    const confirmEmailInput = confirmEmailRef.current;
    if (!confirmEmailInput) return;

    confirmEmailInput.addEventListener('paste', disablePaste);
    return () => {
      confirmEmailInput.removeEventListener('paste', disablePaste);
    };
  }, [step]);

  useEffect(() => {
    if (emailRef.current && step !== PURCHASE_MODAL_STEPS.init) {
      emailRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [step]);

  return (
    <Grid item container xs={12} className="my-3">
      <Grid item xs={12}>
        <LabelText>{ACCOUNT_FORM_HEADERS[step]}</LabelText>
        <br />
        <br />
      </Grid>
      <Grid item container xs={12} justify="space-between">
        <Grid item container spacing={2} sm={6} xs={12}>
          <Grid item xs={12}>
            <Input
              fullWidth
              name={ACCOUNT_FORM_FIELDS.email}
              type="text"
              label="Email"
              error={touched.Email && errors.Email}
              ref={emailRef}
            />
          </Grid>
          {isShowInput(step, ACCOUNT_FORM_FIELDS.confirmEmail) && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.confirmEmail}
                type="email"
                label="Confirm email"
                onChange={handleChange}
                error={touched.ConfirmEmail && errors.ConfirmEmail}
                ref={confirmEmailRef}
              />
            </Grid>
          )}
          {isShowInput(step, ACCOUNT_FORM_FIELDS.password) && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.password}
                type="password"
                label="Password"
                onChange={handleChange}
                error={touched.Password && errors.Password}
              />
            </Grid>
          )}
        </Grid>
        {isShowInput(step, RESTORE_PASS) && (
          <Grid item xs={12}>
            <StyledLink to="/auth/restore-pass">I forgot my password</StyledLink>
          </Grid>
        )}
        <Grid item container spacing={2} sm={6} xs={12}>
          {isShowInput(step, ACCOUNT_FORM_FIELDS.firstName) && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.firstName}
                type="text"
                label="First name"
                onChange={handleChange}
                error={touched.FirstName && errors.FirstName}
              />
            </Grid>
          )}
          {isShowInput(step, ACCOUNT_FORM_FIELDS.lastName) && (
            <Grid item xs={12}>
              <Input
                fullWidth
                name={ACCOUNT_FORM_FIELDS.lastName}
                type="text"
                label="Last name"
                onChange={handleChange}
                error={touched.LastName && errors.LastName}
              />
            </Grid>
          )}
          {isShowInput(step, ACCOUNT_FORM_FIELDS.birthDate) && (
            <Grid item xs={12}>
              <Input
                label="Date of Birth"
                mask="date"
                type="text"
                name={ACCOUNT_FORM_FIELDS.birthDate}
                inputComponent={MaskedInput}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          )}
          {isShowInput(step, ACCOUNT_FORM_FIELDS.timeZoneId) && (
            <Grid item xs={12}>
              <Select
                label="Timezone"
                name={ACCOUNT_FORM_FIELDS.timeZoneId}
                fullWidth
                onChange={handleChange}
                items={[
                  {
                    value: 'America/Los_Angeles',
                    title: 'Pacific Standard Time',
                  },
                  {
                    value: 'America/Denver',
                    title: 'Mountain Standard Time',
                  },
                  {
                    value: 'America/Chicago',
                    title: 'Central Standard Time',
                  },
                  {
                    value: 'America/New_York',
                    title: 'Eastern Standard Time',
                  },
                  {
                    value: 'America/Anchorage',
                    title: 'Alaskan Standard Time',
                  },
                  {
                    value: 'America/St_Johns',
                    title: 'Newfoundland Standard Time',
                  },
                  {
                    value: 'Pacific/Honolulu',
                    title: 'Hawaiian Standard Time',
                  },
                  {
                    value: 'America/Phoenix',
                    title: 'Arizona Standard Time',
                  },
                  {
                    value: 'Australia/Sydney',
                    title: 'Australia/Sydney',
                  },
                  {
                    value: 'Canada/Atlantic',
                    title: 'Canada/Atlantic',
                  },
                  { value: 'Canada/Central', title: 'Canada/Central' },
                  { value: 'Canada/Eastern', title: 'Canada/Eastern' },
                  {
                    value: 'Canada/Mountain',
                    title: 'Canada/Mountain',
                  },
                  {
                    value: 'Canada/Newfoundland',
                    title: 'Canada/Newfoundland',
                  },
                  { value: 'Canada/Pacific', title: 'Canada/Pacific' },
                  {
                    value: 'Canada/Saskatchewan',
                    title: 'Canada/Saskatchewan',
                  },
                  { value: 'Canada/Yukon', title: 'Canada/Yukon' },
                  {
                    value: 'Europe/Amsterdam',
                    title: 'Europe/Amsterdam',
                  },
                  { value: 'Europe/Andorra', title: 'Europe/Andorra' },
                  { value: 'Europe/Athens', title: 'Europe/Athens' },
                  { value: 'Europe/Belfast', title: 'Europe/Belfast' },
                  {
                    value: 'Europe/Belgrade',
                    title: 'Europe/Belgrade',
                  },
                  { value: 'Europe/Berlin', title: 'Europe/Berlin' },
                  {
                    value: 'Europe/Bratislava',
                    title: 'Europe/Bratislava',
                  },
                  {
                    value: 'Europe/Brussels',
                    title: 'Europe/Brussels',
                  },
                  {
                    value: 'Europe/Bucharest',
                    title: 'Europe/Bucharest',
                  },
                  {
                    value: 'Europe/Budapest',
                    title: 'Europe/Budapest',
                  },
                  {
                    value: 'Europe/Busingen',
                    title: 'Europe/Busingen',
                  },
                  {
                    value: 'Europe/Chisinau',
                    title: 'Europe/Chisinau',
                  },
                  {
                    value: 'Europe/Copenhagen',
                    title: 'Europe/Copenhagen',
                  },
                  { value: 'Europe/Dublin', title: 'Europe/Dublin' },
                  {
                    value: 'Europe/Gibraltar',
                    title: 'Europe/Gibraltar',
                  },
                  {
                    value: 'Europe/Guernsey',
                    title: 'Europe/Guernsey',
                  },
                  {
                    value: 'Europe/Helsinki',
                    title: 'Europe/Helsinki',
                  },
                  {
                    value: 'Europe/Isle_of_Man',
                    title: 'Europe/Isle of Man',
                  },
                  {
                    value: 'Europe/Istanbul',
                    title: 'Europe/Istanbul',
                  },
                  { value: 'Europe/Jersey', title: 'Europe/Jersey' },
                  { value: 'Europe/Kaliningrad', title: 'Europe/Kiev' },
                  { value: 'Europe/Lisbon', title: 'Europe/Lisbon' },
                  {
                    value: 'Europe/Ljubljana',
                    title: 'Europe/Ljubljana',
                  },
                  { value: 'Europe/London', title: 'Europe/London' },
                  {
                    value: 'Europe/Luxembourg',
                    title: 'Europe/Luxembourg',
                  },
                  { value: 'Europe/Madrid', title: 'Europe/Madrid' },
                  { value: 'Europe/Malta', title: 'Europe/Malta' },
                  {
                    value: 'Europe/Mariehamn',
                    title: 'Europe/Mariehamn',
                  },
                  { value: 'Europe/Minsk', title: 'Europe/Minsk' },
                  { value: 'Europe/Monaco', title: 'Europe/Monaco' },
                  { value: 'Europe/Moscow', title: 'Europe/Moscow' },
                  { value: 'Europe/Nicosia', title: 'Europe/Nicosia' },
                  { value: 'Europe/Oslo', title: 'Europe/Oslo' },
                  { value: 'Europe/Paris', title: 'Europe/Paris' },
                  {
                    value: 'Europe/Podgorica',
                    title: 'Europe/Podgorica',
                  },
                  { value: 'Europe/Prague', title: 'Europe/Prague' },
                  { value: 'Europe/Riga', title: 'Europe/Riga' },
                  { value: 'Europe/Rome', title: 'Europe/Rome' },
                  { value: 'Europe/Samara', title: 'Europe/Samara' },
                  {
                    value: 'Europe/San_Marino',
                    title: 'Europe/San_Marino',
                  },
                  {
                    value: 'Europe/Sarajevo',
                    title: 'Europe/Sarajevo',
                  },
                  {
                    value: 'Europe/Simferopol',
                    title: 'Europe/Simferopol',
                  },
                  { value: 'Europe/Skopje', title: 'Europe/Skopje' },
                  { value: 'Europe/Sofia', title: 'Europe/Sofia' },
                  {
                    value: 'Europe/Stockholm',
                    title: 'Europe/Stockholm',
                  },
                  { value: 'Europe/Tallinn', title: 'Europe/Tallinn' },
                  { value: 'Europe/Tirane', title: 'Europe/Tirane' },
                  {
                    value: 'Europe/Tiraspol',
                    title: 'Europe/Tiraspol',
                  },
                  {
                    value: 'Europe/Uzhgorod',
                    title: 'Europe/Uzhgorod',
                  },
                  { value: 'Europe/Vaduz', title: 'Europe/Vaduz' },
                  { value: 'Europe/Vatican', title: 'Europe/Vatican' },
                  { value: 'Europe/Vienna', title: 'Europe/Vienna' },
                  { value: 'Europe/Vilnius', title: 'Europe/Vilnius' },
                  {
                    value: 'Europe/Volgograd',
                    title: 'Europe/Volgograd',
                  },
                  { value: 'Europe/Warsaw', title: 'Europe/Warsaw' },
                  { value: 'Europe/Zagreb', title: 'Europe/Zagreb' },
                  {
                    value: 'Europe/Zaporozhye',
                    title: 'Europe/Zaporozhye',
                  },
                  { value: 'Europe/Zurich', title: 'Europe/Zurich' },
                  { value: 'Hongkong', title: 'Hongkong' },
                  { value: 'Iceland', title: 'Iceland' },
                  { value: 'Israel', title: 'Israel' },
                  { value: 'Japan', title: 'Japan' },
                  {
                    value: 'Mexico/BajaNorte',
                    title: 'Mexico/Pacific',
                  },
                  { value: 'Mexico/BajaSur', title: 'Mexico/Mountain' },
                  { value: 'Mexico/General', title: 'Mexico/Central' },
                  { value: 'Navajo', title: 'Navajo' },
                  { value: 'NZ', title: 'NZ' },
                  { value: 'NZ-CHAT', title: 'NZ-CHAT' },
                  { value: 'Singapore', title: 'Singapore' },
                ]}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

AccountForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
  step: PropTypes.string.isRequired,
};
