import React from 'react';
import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import { Box } from '@material-ui/core';
import { Field } from 'formik';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import Button from '../../../../../components/FormUI/Button';
import { colors, toRem } from '../../../../../utils/styles';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

export const StyledContainer = styled(Grid)`
  ${({ additionalHeight }) => {
    return `height: calc(0 + ${additionalHeight}px);`;
  }}
  flex-wrap: nowrap !important;
  flex-direction: row;
  ${({ mobileView }) => {
    return (
      mobileView &&
      `  flex-direction: column;
`
    );
  }}
`;
export const StyledWrapper = styled(Grid)`
  border-radius: 23px;
  background: ${colors.white};
  ${({ boxShadow }) => {
    return boxShadow ? '' : 'box-shadow: 0 9px 24px 14px rgba(0, 0, 0, 0.02);';
  }}
`;

export const AvatarComponent = styled(Avatar)`
  width: 46px;
  height: 46px;
  margin: 15px;

  ${({ mobileView }) => {
    return (
      mobileView &&
      ` 
        font-size: 0.7rem;
            margin: 20px 15px 0 15px;
      `
    );
  }}
`;

export const StyledInputWrapper = styled(Grid)`
  ${({ additionalHeight, mobileView, hasText }) => {
    return `height: calc(46px + ${mobileView && hasText ? 60 : additionalHeight}px);`;
  }}
  ${({ additionalWidth }) => {
    return `  width: calc(100% - ${additionalWidth}px) !important;`;
  }}
  border-radius: 46px;
  background: ${colors.lightGray};
  margin-top: 15px;
  margin-bottom: 15px;
  ${({ mobileView, additionalWidth }) => {
    return (
      mobileView &&
      `  width: calc(100% - ${additionalWidth + 15}px) !important;
              margin-left: 15px;
      `
    );
  }}
`;

export const StyledTextArea = styled(Field)`
  border: none;
  border-radius: 40px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.24;
  letter-spacing: 0.25px;
  resize: none;
  padding: 0.85rem;
  font-family: 'Avenir';
  font-weight: normal;
  outline: none;
  background: ${colors.lightGray};
  width: 100%;
  height: 100%;
  ${({ mobileView }) => {
    return mobileView && `padding: 0.85rem 1rem;`;
  }}
`;

export const StyledButton = styled(Button)`
  height: 36px;
  width: 36px;
  margin-top: 19px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100px;
`;
export const StyledTextAreaWrap = styled(Grid)`
  display: flex;
  align-items: flex-end;
  margin-left: 1rem !important;
  ${({ mobileView }) => {
    return mobileView && `margin-left: 0rem !important;`;
  }}
`;
export const StyledButtonEditWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const StyledLabel = styled.label`
  margin: 0;
`;
export const StyledAttachFileIcon = styled(AttachFileIcon)`
  margin-right: 1rem;
  ${({ mobileView }) => {
    return mobileView && `margin-right: .5rem;`;
  }}
`;
export const StyledPhotoCameraIcon = styled(PhotoCameraIcon)`
  margin-right: 1rem;
  ${({ mobileView }) => {
    return mobileView && `margin-right: .5rem;`;
  }}
`;
export const StyledEmojiObjectsIcon = styled(EmojiObjectsIcon)`
  margin-right: 1rem;
  ${({ mobileView }) => {
    return mobileView && `margin-right: .5rem;`;
  }}
`;
export const StyledTextError = styled.span`
  color: ${colors.fadedPurple} !important;
  text-align: left;
  font-size: 1rem;
  letter-spacing: 0.1px;
  margin-top: -15px;
  padding-bottom: 10px;
  padding-left: 30px;
  ${({ mobileView }) => {
    return (
      mobileView &&
      ` 
         font-size: .8rem;
         padding-left: 20px;`
    );
  }}
`;
export const StyledTextAreaIcon = styled(Box)`
  ${({ disabled }) => {
    if (disabled) {
      return `pointer-events: none;
              opacity:.5;`;
    }
  }}
  margin-bottom: 11px;
`;
