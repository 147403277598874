import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import { getAffiliateSummary, getPaidFull } from 'services/affiliate.service';
import AffiliateSummaryCard from './components/AffiliateSummaryCard/AffiliateSummaryCard';

import './Affiliate.scss';

function Affiliate() {
  const [affiliateSummary, setAffiliateSummary] = useState(undefined);

  useEffect(() => {
    getAffiliateSummary().then(summary => setAffiliateSummary(summary));
  }, [setAffiliateSummary]);

  const onGetPaidClick = useCallback(() => {
    getPaidFull()
      .then(() => getAffiliateSummary())
      .then(summary => setAffiliateSummary(summary));
  }, []);

  return (
    <Grid container direction="column" alignItems="center" spacing={3}>
      <AffiliateSummaryCard incomes={affiliateSummary} onGetPaidClick={onGetPaidClick} />
    </Grid>
  );
}

export default Affiliate;
