import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { FieldArray } from 'formik';
import { Icon } from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import moment from 'moment';
import { useTheme, useMediaQuery } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import * as oneToOneDataActions from 'actions/oneToOneData';
import * as contributionActions from 'actions/contributions';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { generateOneToOneSlots } from 'pages/CreateContribution/utils';
import Input from 'components/FormUI/Input';
import Select from 'components/UI/Select';
import DateTimePicker from 'components/FormUI/DateTimePicker';
import { PageSubtitle } from 'components/UI/Text/TextStyles';
import { toRem, colors } from 'utils/styles';
import { SessionTime } from './SessionTime';
import { SessionTimeOneToOne } from './SessionTimeOneToOne';
import { useCheckSessionDuration } from '../hooks/useCheckSessionDuration';
import { TOOLTIP } from 'constants.js';
import Dropzone from '../../../components/FormUI/Dropzone';
import * as R from 'ramda';
import { useHttp } from '../../../hooks';
import { useVideoDurationParse } from '../hooks/useVideoDurationParse';

const StyledTitle = styled.h3`
  font-size: ${toRem(18)};
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: 0.12px;
  color: #000000;
  margin: 0;
`;

const StyledContainer = styled.div`
  margin-bottom: 22px;
`;

const StyledHeader = styled.div`
  padding: 16px 0;
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: flex-end;
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledVideo = styled.video`
  width: 100%;
  max-height: 200px;
  margin-top: 20px;
`;

const StyledBadge = styled.span`
  background-color: ${colors.darkOceanBlue};
  color: white;
  padding: 4px 12px;
  font-size: ${toRem(11)};
  font-weight: 900;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  border-radius: 10px;
  vertical-align: middle;
`;

const StyledSessionTimesContainer = styled.div`
  padding: 0 16px;

  ${mobileView => mobileView && `padding: 0`}
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 10px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;

const durationsOneToOne = [
  { title: '30 minutes', value: 30 },
  { title: '45 minutes', value: 45 },
  { title: '1 hour', value: 60 },
  { title: '90 minutes', value: 90 },
];

const durationValues = {
  tenDays: 10,
  thirtyDays: 30,
  sixtyDays: 60,
  ninetyDays: 90,
  customDaysValue: 'custom',
};

const dateRangeOneToOne = [
  { title: '10 days out', value: durationValues.tenDays },
  { title: '30 days out', value: durationValues.thirtyDays },
  { title: '60 days out', value: durationValues.sixtyDays },
  { title: '90 days out', value: durationValues.ninetyDays },
  { title: 'Custom', value: durationValues.customDaysValue },
];

function Session({
  form,
  index,
  timesCount,
  oneToOne,
  onDelete,
  setStartDate,
  setEndDate,
  oneToOneStartDate,
  oneToOneEndDate,
  setOneToDateRange,
  oneToDateRange,
  sessionDuration,
  setSessionDuration,
  otherEvents,
  alreadyBooked,
  selectedWeeks,
  sessionDurationError,
  getEditContributionTimes,
  editContributionTimes,
  setSlots,
  oneToOneData,
  calculatedSlots,
  calculateSlots,
  session,
  providerName,
  isPrerecorded,
}) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const smView = useMediaQuery(theme.breakpoints.down('sm'));
  const { convertDuration, videoDuration, videoSrc, loading, progress } = useVideoDurationParse();
  const computeDifference = useCallback((startTime, endTime) => {
    if (!startTime || !endTime) {
      return null;
    }

    const start = moment(startTime);
    const end = moment(endTime);

    const duration = moment.duration(end.diff(start));
    let minutes = Math.round(duration.asMinutes() % 60);
    let hours = Math.floor(duration.asMinutes() / 60);

    if (hours < 0) {
      hours = 24 + hours;
    }
    if (minutes < 0) {
      minutes = 60 + minutes;
    }

    return `${hours}h ${minutes}m`;
  }, []);

  const contribution = useContribution();

  useEffect(() => {
    const data = {
      startDay: moment(oneToOneStartDate).utcOffset(0, true).startOf('day').toISOString(),
      endDay: moment(oneToOneEndDate).utcOffset(0, true).startOf('day').toISOString(),
      duration: `${oneToDateRange}`,
      sessionDuration,
      selectedWeeks,
    };
    if (contribution.id) {
      getEditContributionTimes(contribution.id, data, true);
    } else {
      calculateSlots(data);
    }
  }, [oneToOneStartDate, oneToOneEndDate, oneToDateRange, sessionDuration, selectedWeeks, contribution]);

  useEffect(() => {
    const { availabilityTimes } = contribution;
    const calculatedTimes = contribution.id ? editContributionTimes : calculatedSlots;
    const { slots } = generateOneToOneSlots(oneToOneData, availabilityTimes || [], otherEvents, calculatedTimes);
    setSlots(slots);
  }, [editContributionTimes, setSlots, calculatedSlots]);

  useEffect(() => {
    if (oneToDateRange === 'custom') {
      return;
    }
    setStartDate(moment(new Date()));
    setEndDate(moment(new Date()).add(oneToDateRange, 'days'));
  }, [oneToDateRange]);
  useEffect(() => {}, [oneToDateRange]);

  useEffect(() => {
    const diff = moment(oneToOneEndDate).diff(moment(oneToOneStartDate), 'days');
    if (
      !(
        diff === durationValues.tenDays ||
        diff === durationValues.thirtyDays ||
        diff === durationValues.sixtyDays ||
        diff === durationValues.ninetyDays
      )
    ) {
      setOneToDateRange(durationValues.customDaysValue);
    }
  }, [oneToOneStartDate, oneToOneEndDate]);

  const onChangeSessionDuration = e => {
    const { value } = e.target;

    setSessionDuration(value);
  };

  const handleSendVideo = useCallback(files => {
    convertDuration(files, form, contribution, index);
  }, []);
  useCheckSessionDuration();
  return (
    <StyledContainer>
      {!oneToOne && (
        <StyledHeader>
          <StyledTitle>
            Session <StyledBadge>{index + 1}</StyledBadge>
          </StyledTitle>
          <StyledIcon path={mdiDelete} size={1} onClick={() => onDelete(index)} />
          <StyledTitle>{isPrerecorded && 'Self-Paced Session'}</StyledTitle>
        </StyledHeader>
      )}
      <StyledBody>
        <Grid container spacing={4}>
          {oneToOne ? (
            <Grid item md={6} xs={12}>
              <Select
                label="How long are your sessions?"
                onChange={onChangeSessionDuration}
                value={sessionDuration}
                fullWidth
                items={durationsOneToOne.map(({ title, value }) => ({
                  title,
                  value,
                }))}
                disabled={alreadyBooked}
                error={sessionDurationError}
                errorHeight={50}
              />
            </Grid>
          ) : (
            <>
              <Grid item md={6} xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Input fullWidth style={{ flexGrow: 1 }} name={`sessions[${index}].title`} label="Title" />
                  </Grid>
                  {isPrerecorded && (
                    <Grid item xs={12}>
                      <PageSubtitle>Sessions</PageSubtitle>
                      <FieldArray name={`sessions[${index}].sessionTimes`} validateOnChange={false}>
                        {({ push, remove, form }) => {
                          return (
                            <StyledSessionTimesContainer mobileView={mobileView}>
                              <SessionTime
                                form={form}
                                push={push}
                                remove={remove}
                                isPrerecorded={isPrerecorded}
                                videoDuration={videoDuration}
                                sessionIdx={index}
                                timesCount={timesCount}
                                computeDifference={computeDifference}
                              />
                            </StyledSessionTimesContainer>
                          );
                        }}
                      </FieldArray>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container spacing={4}>
                  {isPrerecorded ? (
                    <Grid item xs={12}>
                      {videoSrc && !loading && progress === 100 ? (
                        <StyledVideo controls>
                          <source src={videoSrc} />
                        </StyledVideo>
                      ) : (
                        <Dropzone
                          action={handleSendVideo}
                          type="video/mpeg,video/mp4"
                          icon={<VideoCallIcon fontSize="inherit" />}
                          progress={progress}
                          loading={loading}
                          placeholder="Drag 'n' drop some video here, or click to select files"
                        />
                      )}
                    </Grid>
                  ) : (
                    <>
                      <Grid item md={6} xs={12}>
                        <Input
                          fullWidth
                          type="number"
                          name={`sessions[${index}].minParticipantsNumber`}
                          label="Min Number of Participants"
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <Input
                          error={session?.maxParticipantsNumber > 50 && providerName === 'Cohere'}
                          helperText={
                            session?.maxParticipantsNumber > 50 &&
                            providerName === 'Cohere' &&
                            `
                  Cohere’s live video service currently supports up to 50 participants. If you expect to have more than 50, please change the live video service provider using the “Live Video” settings below. For example, use a private zoom room link, or equivalent.`
                          }
                          fullWidth
                          type="number"
                          name={`sessions[${index}].maxParticipantsNumber`}
                          label="Max Number of Participants"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              {/*<Grid item md={6} xs={12}>*/}
              {/*  <Input fullWidth style={{ flexGrow: 1 }} name={`sessions[${index}].title`} label="Title" />*/}
              {/*</Grid>*/}
              {/*<Grid item md={3} xs={12}>*/}
              {/*  <Input*/}
              {/*    fullWidth*/}
              {/*    type="number"*/}
              {/*    name={`sessions[${index}].minParticipantsNumber`}*/}
              {/*    label="Min Number of Participants"*/}
              {/*  />*/}
              {/*</Grid>*/}
              {/*<Grid item md={3} xs={12}>*/}
              {/*  <Input*/}
              {/*    fullWidth*/}
              {/*    type="number"*/}
              {/*    name={`sessions[${index}].maxParticipantsNumber`}*/}
              {/*    label="Max Number of Participants"*/}
              {/*  />*/}
              {/*</Grid>*/}
            </>
          )}
        </Grid>

        {oneToOne ? (
          <>
            <PageSubtitle>Set Your Booking Availability</PageSubtitle>
            <Grid
              container
              direction={smView ? 'column' : 'row'}
              justify="space-between"
              style={{ marginBottom: '20px' }}
            >
              <Grid sm={smView ? 12 : 6} xs={smView ? 12 : 6}>
                <Grid item md={10} xs={12}>
                  <Select
                    label={
                      <div>
                        Date range: How far out can client book?
                        <Tooltip
                          title="This allows your calendar availability to always have the next indicated number of days available for client booking. You can also select custom dates."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <StyledInfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                        </Tooltip>
                      </div>
                    }
                    labelTop={mobileView ? '32px' : '24px'}
                    name={`sessions[${index}].daterange`}
                    value={oneToDateRange}
                    onChange={e => {
                      setOneToDateRange(e.target.value);
                    }}
                    fullWidth
                    items={dateRangeOneToOne.map(({ title, value }) => ({
                      title,
                      value,
                    }))}
                  />
                </Grid>
              </Grid>
              <Grid sm={smView ? 12 : 6} xs={smView ? 12 : 6} container direction="row" justify="space-around">
                <Grid item sm="5" xs="6">
                  <DateTimePicker
                    label="Start Day"
                    type="date"
                    fullWidth
                    name={`sessions[${index}].startDate`}
                    value={oneToOneStartDate}
                    onChange={momentDate => {
                      setStartDate(momentDate);
                    }}
                    format="MMMM Do"
                  />
                </Grid>
                <Grid item sm="5" xs="6">
                  <DateTimePicker
                    label="End Day"
                    type="date"
                    fullWidth
                    name={`sessions[${index}].endDate`}
                    value={oneToOneEndDate}
                    onChange={momentDate => {
                      setEndDate(momentDate);
                    }}
                    format="MMMM Do"
                  />
                </Grid>
              </Grid>
            </Grid>
            <FieldArray name={`sessions[${index}].sessionTimes`} validateOnChange={false}>
              {({ push, remove, form }) => {
                return (
                  <StyledSessionTimesContainer mobileView={mobileView}>
                    <SessionTimeOneToOne
                      form={form}
                      push={push}
                      remove={remove}
                      isOneToOne={oneToOne}
                      sessionIdx={index}
                      timesCount={timesCount}
                      oneToOneStartDate={oneToOneStartDate}
                      oneToOneEndDate={oneToOneEndDate}
                      oneToDateRange={oneToDateRange}
                      otherEvents={otherEvents}
                    />
                  </StyledSessionTimesContainer>
                );
              }}
            </FieldArray>
          </>
        ) : (
          <>
            {!isPrerecorded && (
              <>
                <PageSubtitle>Sessions</PageSubtitle>
                <FieldArray name={`sessions[${index}].sessionTimes`} validateOnChange={false}>
                  {({ push, remove, form }) => {
                    return (
                      <StyledSessionTimesContainer mobileView={mobileView}>
                        <SessionTime
                          form={form}
                          push={push}
                          remove={remove}
                          sessionIdx={index}
                          timesCount={timesCount}
                          computeDifference={computeDifference}
                        />
                      </StyledSessionTimesContainer>
                    );
                  }}
                </FieldArray>
              </>
            )}
          </>
        )}
      </StyledBody>
    </StyledContainer>
  );
}

Session.propTypes = {
  index: PropTypes.number.isRequired,
  timesCount: PropTypes.number.isRequired,
  oneToOne: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  alreadyBooked: PropTypes.bool,
  selectedWeeks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sessionDurationError: PropTypes.string,
};

Session.defaultProps = {
  oneToOne: false,
  alreadyBooked: false,
  sessionDurationError: undefined,
  isPrerecorded: false,
};

const mapStateToProps = ({
  oneToOneData,
  contributions: { updating, loading, editContributionTimes, calculatedSlots },
}) => ({
  oneToOneStartDate: oneToOneData?.startDay,
  oneToOneEndDate: oneToOneData?.endDay,
  selectedWeeks: oneToOneData?.selectedWeeks,
  oneToDateRange: oneToOneData?.duration,
  sessionDuration: oneToOneData?.sessionDuration,
  sessionDurationError: oneToOneData?.sessionDurationError,
  oneToOneData,
  updating,
  loading,
  editContributionTimes,
  calculatedSlots,
});

const actions = {
  setStartDate: oneToOneDataActions.setStartDate,
  setEndDate: oneToOneDataActions.setEndDate,
  setOneToDateRange: oneToOneDataActions.setDuration,
  setSessionDuration: oneToOneDataActions.setSessionDuration,
  getEditContributionTimes: contributionActions.fetchEditContributionTimes,
  setSlots: oneToOneDataActions.setSlots,
  calculateSlots: contributionActions.calculateCreateSlots,
};

export default connect(mapStateToProps, actions)(Session);
