import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TwilioVideo from 'twilio-video';

import { LOCAL_TRACK_PRIORITIES } from 'constants.js';
import { setVideoChatRecordingStatus } from 'actions/videoChat';

import VideoChatConnectionStatus from '../VideoChatConnectionStatus';
import VideoChatConnectionError from '../VideoChatConnectionError';
import VideoChatTrackKind from '../VideoChatTrackKind';
import VideoChatVideoTrackType from '../VideoChatVideoTrackType';

const checkIfScreenSharePublication = publication => {
  return publication.kind === VideoChatTrackKind.video && publication.trackName === VideoChatVideoTrackType.screenShare;
};

const useVideoChat = (token, onDisconnected) => {
  const dispatch = useDispatch();

  const [connectionStatus, setConnectionStatus] = useState(VideoChatConnectionStatus.pending);
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [dominantSpeakerParticipant, setDominantSpeakerParticipant] = useState(null);
  const [screenShareParticipants, setScreenShareParticipants] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async function connectTwilioVideoAsync(roomToken) {
      try {
        setError(null);
        setConnectionStatus(VideoChatConnectionStatus.connecting);

        const connectedRoom = await TwilioVideo.connect(roomToken, {
          logLevel: 'debug',
          audio: true,
          video: false,
          bandwidthProfile: {
            video: {
              renderDimensions: {
                high: { height: 1080, width: 1980 },
                standard: { height: 720, width: 1280 },
                low: { height: 176, width: 144 },
              },
              dominantSpeakerPriority: LOCAL_TRACK_PRIORITIES.high,
              mode: 'presentation',
              trackSwitchOffMode: 'detected',
            },
          },
          dominantSpeaker: true,
          maxAudioBitrate: 16000,
          preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
          networkQuality: { local: 3, remote: 3 },
        });
        window.twilioRoom = connectedRoom;
        const connectedRoomParticipants = Array.from(connectedRoom.participants.values());

        setRoom(connectedRoom);
        setParticipants(connectedRoomParticipants);
        setDominantSpeakerParticipant(connectedRoom.dominantSpeaker);
        setScreenShareParticipants(
          connectedRoomParticipants.filter(p => Array.from(p.videoTracks.values()).some(checkIfScreenSharePublication)),
        );
        setConnectionStatus(VideoChatConnectionStatus.connected);

        connectedRoom.on('recordingStarted', cp => {
          dispatch(setVideoChatRecordingStatus('Started'));
        });

        connectedRoom.on('recordingStopped', cp => {
          dispatch(setVideoChatRecordingStatus('Stopped'));
        });

        connectedRoom.on('participantConnected', cp => {
          console.log(`Remote Participant (sid: ${cp.sid}, identity: ${cp.identity}) connected.`);

          setParticipants(pp => [...pp, cp]);
        });
        connectedRoom.on('participantDisconnected', dp => {
          console.log(`Remote Participant (sid: ${dp.sid}, identity: ${dp.identity}) disconnected.`);

          setParticipants(pp => pp.filter(p => p !== dp));
        });
        connectedRoom.on('dominantSpeakerChanged', setDominantSpeakerParticipant);
        connectedRoom.on('trackPublished', (publication, sp) => {
          console.log(
            `Participant (sid: ${sp.sid}, identity: ${sp.identity}) published track (type: ${publication.kind}, name: ${publication.trackName}).`,
          );

          if (checkIfScreenSharePublication(publication)) {
            setScreenShareParticipants(ssp => [...ssp, sp]);
          }
        });
        connectedRoom.on('trackUnpublished', (publication, sp) => {
          console.log(
            `Participant (sid: ${sp.sid}, identity: ${sp.identity}) unpublished track (type: ${publication.kind}, name: ${publication.trackName}).`,
          );

          if (checkIfScreenSharePublication(publication)) {
            setScreenShareParticipants(ssp => ssp.filter(p => p !== sp));
          }
        });
        connectedRoom.on('disconnected', (room, error) => {
          if (error) {
            console.log('You were disconnected from the Room:', error.code, error.message);
          }
        });
        connectedRoom.on('disconnected', onDisconnected);
      } catch {
        setError(VideoChatConnectionError.unknown);
        setConnectionStatus(VideoChatConnectionStatus.connectionError);
      }
    })(token);
  }, [token, onDisconnected]);

  useEffect(() => {
    if (room) {
      return () => {
        if (room) {
          const localTracks = Array.from(room.localParticipant.tracks.values())
            .map(p => p.track)
            .filter(t => t);
          localTracks.forEach(lt => lt.stop());
          room.localParticipant.unpublishTracks(localTracks);
          room.removeAllListeners();
          room.disconnect();
        }
      };
    }
  }, [room]);

  return {
    connectionStatus,
    room,
    participants,
    dominantSpeakerParticipant,
    screenShareParticipants,
    error,
  };
};

export default useVideoChat;
