import React from 'react'
import PropTypes from 'prop-types'

import { Chat } from 'components/UI/chats'

const ChatPanel = ({ className, chatId }) => (
  <div className={className}>
    <Chat channelId={chatId} compact />
  </div>
)

ChatPanel.propTypes = {
  className: PropTypes.string.isRequired,
  chatId: PropTypes.string.isRequired,
}

export default ChatPanel
