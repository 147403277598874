import React, { useState, useEffect, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'

import { useAccount } from 'hooks'

import Banner from 'components/Banner/Banner'
import * as contributionService from 'services/contributions.service'

import './ClosestContributionBannerContainer.scss'

const ClosestContributionBannerContainer = () => {
  const [bannerTypes, setBannerTypes] = useState([])
  const { user } = useAccount()

  const [closestContribution, setClosestContribution] = useState(null)
  useEffect(() => {
    setInterval(() => {
      contributionService
        .getAllBoughtByUserId(user.id)
        .then(({ closestClassForBanner }) => setClosestContribution(closestClassForBanner))
        .catch(() => setClosestContribution(null))
    }, 10000)
  }, [])

  const getType = useCallback(() => {
    const types = []
    if (!isEmpty(closestContribution)) {
      types.push('closestSession')
    }
    setBannerTypes(types)
  }, [closestContribution, setBannerTypes])

  useEffect(() => {
    getType()
  }, [getType])

  return (
    <div className="banners-client">
      {bannerTypes.map((type) => (
        <Banner type={type} closestClassForBanner={closestContribution} />
      ))}
    </div>
  )
}

export default ClosestContributionBannerContainer
