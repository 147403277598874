import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledBox = styled(Grid)`
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 20px;
`
const StyledLabel = styled.span`
  color: #a4a4a4;
  font-size: 12px;
`
export const AddCalendarModalBox = ({ children, additionalField }) => {
  return (
    <StyledBox container xs={12}>
      <Grid item xs={6}>
        <StyledLabel>Check activity form</StyledLabel>
      </Grid>
      <Grid container item xs={6} justify="flex-end" alignContent="center">
        {additionalField}
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </StyledBox>
  )
}

AddCalendarModalBox.propTypes = {
  children: PropTypes.element.isRequired,
  additionalField: PropTypes.element,
}

AddCalendarModalBox.defaultProps = {
  additionalField: '',
}
