import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { Icon } from '@mdi/react';
import { mdiLoading, mdiWeatherSunny, mdiTimerSandEmpty, mdiEyeCircleOutline } from '@mdi/js';
import classnames from 'classnames';
import moment from 'moment';

import { useHttp } from 'hooks';
import { toRem, devices } from 'utils/styles';
import { connect } from 'react-redux';
import { getClientProfileValidationSchema } from 'utils/validation';
import { parseDate } from 'utils/datesAndMoney';
import Button from 'components/FormUI/Button';
import TextArea from 'components/FormUI/TextArea';
import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import MaskedInput from 'components/FormUI/MaskedInput';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import * as userActions from 'actions/user';
import { ProfileAvatar } from 'components/ProfileAvatar/ProfileAvatar';
import Interests from './components/Interests';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 550,
  },
  row: {
    '& + &': {
      marginTop: 20,
    },
  },
  item: {
    flexBasis: 'auto',
  },
}));

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media screen and (${devices.laptop}) {
    flex-direction: row;
  }
`;

const StyledInterestsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (${devices.laptop}) {
    margin-left: 40px;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: ${toRem(24)};
`;

function ClientProfilePage({ user, editProfile, loading, error }) {
  const { request } = useHttp();
  const classes = useStyles();
  const [preferences, setPreferences] = useState([]);
  const [userPreferences, setUserPreferences] = useState(
    user.clientPreferences || {
      interests: {},
      curiosities: {},
      experiences: {},
    },
  );

  let savedValues = {};

  const getPreferences = useCallback(() => {
    request('/referencedata/preferences', 'GET').then(setPreferences).catch(console.dir);
  }, [request]);

  const submitHandler = useCallback(
    async values => {
      const userData = {
        ...values,
        id: user.id,
        accountId: user.accountId,
        avatarUrl: user.avatarUrl,
        BirthDate: moment.utc(values.BirthDate).format(),
        clientPreferences: userPreferences,
      };

      await editProfile(user.id, userData);
    },
    [editProfile, user.accountId, user.avatarUrl, user.id, userPreferences],
  );

  const onEditPreferencesHandler = useCallback(
    section => prefs => {
      setUserPreferences(up => ({
        ...up,
        [section]: prefs,
      }));
    },
    [],
  );

  useEffect(() => {
    getPreferences();
  }, [getPreferences]);

  useEffect(() => {
    submitHandler(savedValues);
  }, [user.avatarUrl]);

  return (
    <StyledContainer>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        classes={{
          container: classes.container,
        }}
      >
        <Formik
          initialValues={{
            FirstName: user.firstName,
            LastName: user.lastName,
            BirthDate: parseDate(user.birthDate),
            Bio: user.bio || '',
            TimeZoneId: user.timeZoneId || 'America/Los_Angeles',
            HasAgreedToTerms: true,
          }}
          validationSchema={getClientProfileValidationSchema()}
          onSubmit={submitHandler}
        >
          {({ values }) => {
            savedValues = values;
            return (
              <Form id="signUpFormTwo">
                <div className="d-flex">
                  <ProfileAvatar avatarUrl={user.avatarUrl} editHandler={submitHandler} values={values} />
                  <Grid container spacing={4} direction="column" className={classes.row}>
                    <Grid item lg={12} xs={12} className={classes.item}>
                      <Input label="First Name" type="text" name="FirstName" fullWidth />
                    </Grid>
                    <Grid item lg={12} xs={12} className={classes.item}>
                      <Input label="Last Name" type="text" name="LastName" fullWidth />
                    </Grid>
                    <Grid item lg={12} xs={12} className={classes.item}>
                      <Input
                        label="Date of Birth"
                        mask="date"
                        type="text"
                        name="BirthDate"
                        inputComponent={MaskedInput}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </div>

                <Grid container spacing={4} className={classes.row}>
                  <Grid item sm={12} xs={12}>
                    <Select
                      label="Timezone"
                      name="TimeZoneId"
                      fullWidth
                      items={[
                        {
                          value: 'America/Los_Angeles',
                          title: 'Pacific Standard Time',
                        },
                        {
                          value: 'America/Denver',
                          title: 'Mountain Standard Time',
                        },
                        {
                          value: 'America/Chicago',
                          title: 'Central Standard Time',
                        },
                        {
                          value: 'America/New_York',
                          title: 'Eastern Standard Time',
                        },
                        {
                          value: 'America/Anchorage',
                          title: 'Alaskan Standard Time',
                        },
                        {
                          value: 'America/St_Johns',
                          title: 'Newfoundland Standard Time',
                        },
                        {
                          value: 'Pacific/Honolulu',
                          title: 'Hawaiian Standard Time',
                        },
                        {
                          value: 'America/Phoenix',
                          title: 'Arizona Standard Time',
                        },
                        { value: 'Australia/Sydney', title: 'Australia/Sydney' },
                        { value: 'Canada/Atlantic', title: 'Canada/Atlantic' },
                        { value: 'Canada/Central', title: 'Canada/Central' },
                        { value: 'Canada/Eastern', title: 'Canada/Eastern' },
                        { value: 'Canada/Mountain', title: 'Canada/Mountain' },
                        {
                          value: 'Canada/Newfoundland',
                          title: 'Canada/Newfoundland',
                        },
                        { value: 'Canada/Pacific', title: 'Canada/Pacific' },
                        {
                          value: 'Canada/Saskatchewan',
                          title: 'Canada/Saskatchewan',
                        },
                        { value: 'Canada/Yukon', title: 'Canada/Yukon' },
                        { value: 'Europe/Amsterdam', title: 'Europe/Amsterdam' },
                        { value: 'Europe/Andorra', title: 'Europe/Andorra' },
                        { value: 'Europe/Athens', title: 'Europe/Athens' },
                        { value: 'Europe/Belfast', title: 'Europe/Belfast' },
                        { value: 'Europe/Belgrade', title: 'Europe/Belgrade' },
                        { value: 'Europe/Berlin', title: 'Europe/Berlin' },
                        {
                          value: 'Europe/Bratislava',
                          title: 'Europe/Bratislava',
                        },
                        { value: 'Europe/Brussels', title: 'Europe/Brussels' },
                        { value: 'Europe/Bucharest', title: 'Europe/Bucharest' },
                        { value: 'Europe/Budapest', title: 'Europe/Budapest' },
                        { value: 'Europe/Busingen', title: 'Europe/Busingen' },
                        { value: 'Europe/Chisinau', title: 'Europe/Chisinau' },
                        {
                          value: 'Europe/Copenhagen',
                          title: 'Europe/Copenhagen',
                        },
                        { value: 'Europe/Dublin', title: 'Europe/Dublin' },
                        { value: 'Europe/Gibraltar', title: 'Europe/Gibraltar' },
                        { value: 'Europe/Guernsey', title: 'Europe/Guernsey' },
                        { value: 'Europe/Helsinki', title: 'Europe/Helsinki' },
                        {
                          value: 'Europe/Isle_of_Man',
                          title: 'Europe/Isle of Man',
                        },
                        { value: 'Europe/Istanbul', title: 'Europe/Istanbul' },
                        { value: 'Europe/Jersey', title: 'Europe/Jersey' },
                        { value: 'Europe/Kaliningrad', title: 'Europe/Kiev' },
                        { value: 'Europe/Lisbon', title: 'Europe/Lisbon' },
                        { value: 'Europe/Ljubljana', title: 'Europe/Ljubljana' },
                        { value: 'Europe/London', title: 'Europe/London' },
                        {
                          value: 'Europe/Luxembourg',
                          title: 'Europe/Luxembourg',
                        },
                        { value: 'Europe/Madrid', title: 'Europe/Madrid' },
                        { value: 'Europe/Malta', title: 'Europe/Malta' },
                        { value: 'Europe/Mariehamn', title: 'Europe/Mariehamn' },
                        { value: 'Europe/Minsk', title: 'Europe/Minsk' },
                        { value: 'Europe/Monaco', title: 'Europe/Monaco' },
                        { value: 'Europe/Moscow', title: 'Europe/Moscow' },
                        { value: 'Europe/Nicosia', title: 'Europe/Nicosia' },
                        { value: 'Europe/Oslo', title: 'Europe/Oslo' },
                        { value: 'Europe/Paris', title: 'Europe/Paris' },
                        { value: 'Europe/Podgorica', title: 'Europe/Podgorica' },
                        { value: 'Europe/Prague', title: 'Europe/Prague' },
                        { value: 'Europe/Riga', title: 'Europe/Riga' },
                        { value: 'Europe/Rome', title: 'Europe/Rome' },
                        { value: 'Europe/Samara', title: 'Europe/Samara' },
                        {
                          value: 'Europe/San_Marino',
                          title: 'Europe/San_Marino',
                        },
                        { value: 'Europe/Sarajevo', title: 'Europe/Sarajevo' },
                        {
                          value: 'Europe/Simferopol',
                          title: 'Europe/Simferopol',
                        },
                        { value: 'Europe/Skopje', title: 'Europe/Skopje' },
                        { value: 'Europe/Sofia', title: 'Europe/Sofia' },
                        { value: 'Europe/Stockholm', title: 'Europe/Stockholm' },
                        { value: 'Europe/Tallinn', title: 'Europe/Tallinn' },
                        { value: 'Europe/Tirane', title: 'Europe/Tirane' },
                        { value: 'Europe/Tiraspol', title: 'Europe/Tiraspol' },
                        { value: 'Europe/Uzhgorod', title: 'Europe/Uzhgorod' },
                        { value: 'Europe/Vaduz', title: 'Europe/Vaduz' },
                        { value: 'Europe/Vatican', title: 'Europe/Vatican' },
                        { value: 'Europe/Vienna', title: 'Europe/Vienna' },
                        { value: 'Europe/Vilnius', title: 'Europe/Vilnius' },
                        { value: 'Europe/Volgograd', title: 'Europe/Volgograd' },
                        { value: 'Europe/Warsaw', title: 'Europe/Warsaw' },
                        { value: 'Europe/Zagreb', title: 'Europe/Zagreb' },
                        {
                          value: 'Europe/Zaporozhye',
                          title: 'Europe/Zaporozhye',
                        },
                        { value: 'Europe/Zurich', title: 'Europe/Zurich' },
                        { value: 'Hongkong', title: 'Hongkong' },
                        { value: 'Iceland', title: 'Iceland' },
                        { value: 'Israel', title: 'Israel' },
                        { value: 'Japan', title: 'Japan' },
                        { value: 'Mexico/BajaNorte', title: 'Mexico/Pacific' },
                        { value: 'Mexico/BajaSur', title: 'Mexico/Mountain' },
                        { value: 'Mexico/General', title: 'Mexico/Central' },
                        { value: 'Navajo', title: 'Navajo' },
                        { value: 'NZ', title: 'NZ' },
                        { value: 'NZ-CHAT', title: 'NZ-CHAT' },
                        { value: 'Singapore', title: 'Singapore' },
                      ]}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4} className={classes.row}>
                  <Grid item xs={12}>
                    <TextArea label="Bio" name="Bio" rows="2" fullWidth optional helperText="optional" />
                  </Grid>
                </Grid>

                {error?.message && <CommonErrorMessage message={error.message} />}

                <Grid container justify="center">
                  <ButtonsContainer>
                    <Button disabled={loading} type="submit">
                      {loading ? <Icon path={mdiLoading} size={1} spin={1} /> : 'Save Profile'}
                    </Button>
                  </ButtonsContainer>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>

      <StyledInterestsContainer>
        <Interests
          title="My Interests"
          source={preferences}
          buttonTitle="Edit My Interests"
          list={userPreferences?.interests}
          onEdit={onEditPreferencesHandler('interests')}
          icon={<Icon path={mdiWeatherSunny} size={1} />}
          type="Interests"
        />

        <Interests
          title="My Curiosities"
          source={preferences}
          buttonTitle="Edit My Curiosities"
          list={userPreferences?.curiosities}
          onEdit={onEditPreferencesHandler('curiosities')}
          icon={<Icon path={mdiEyeCircleOutline} size={1} />}
          type="Curiosities"
        />

        <Interests
          title="My Experience"
          source={preferences}
          buttonTitle="Edit My Experience"
          list={userPreferences?.experiences}
          onEdit={onEditPreferencesHandler('experiences')}
          icon={<Icon path={mdiTimerSandEmpty} size={1} />}
          type="Experiences"
        />
      </StyledInterestsContainer>
    </StyledContainer>
  );
}

ClientProfilePage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    birthDate: PropTypes.string,
    businessType: PropTypes.string,
    occupation: PropTypes.string,
    certification: PropTypes.string,
    customBusinessType: PropTypes.string,
    clientPreferences: PropTypes.shape(),
    avatarUrl: PropTypes.string,
    customerLabelPreference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    businessName: PropTypes.string,
    bio: PropTypes.string,
    timeZoneId: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  editProfile: PropTypes.func.isRequired,

  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

ClientProfilePage.defaultProps = {
  error: null,
};

const mapStateToProps = ({ account }) => ({
  user: account?.user || {},
  loading: account?.isLoading,
  error: account?.error,
});

const actions = {
  editProfile: userActions.editProfile,
};

export default connect(mapStateToProps, actions)(ClientProfilePage);
