import React, { useState } from 'react';

import { useAccount } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Modal from 'components/UI/Modal';
import moment from 'moment';

import Button from 'components/FormUI/Button';

import styled from 'styled-components';

import '../Banner.scss';

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`;

const CoachDeclinedSubscriptions = ({ coachDeclinedSubscriptions }) => {
  const { user } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const BannerButton = mobileView ? StyledButton : Button;

  const [showModal, setShowModal] = useState(false);

  const togglePopup = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
        <p className="banner-container__text">
          One of your clients had a failed payment. They have been notified to update their payment method.
        </p>
        <BannerButton
          disabled={!user.transfersEnabled}
          variant="primary"
          className="banner-container__button"
          autoWidth
          onClick={togglePopup}
        >
          Learn more
        </BannerButton>
      </div>
      <Modal
        title="Affiliate Issues"
        isOpen={showModal}
        onCloseText="OK"
        hiddenCancel
        onSubmit={togglePopup}
        onCancel={togglePopup}
      >
        An automated email was sent to your client(s) to update their payment information. Please feel free to also
        contact your Clients via “Conversations”
        <ul>
          {coachDeclinedSubscriptions?.map((sub, index) => {
            return (
              <li>
                {sub?.contributionName} {sub?.clientName} - remaining: $
                {sub?.declinedSubscriptionPurchase?.amountRemaining}. Date:{' '}
                {moment.utc(sub?.declinedSubscriptionPurchase?.lastPaymentFailedDate).format('MMMM Do YYYY')}
              </li>
            );
          })}
        </ul>
      </Modal>
    </>
  );
};

export default CoachDeclinedSubscriptions;
