import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toRem, colors, devices } from 'utils/styles';
import { NavLink } from 'react-router-dom';

const StyledContributionHaderWrapper = styled.div`
  background-color: #ffffff;
  border: solid 1px #e7e7e7;
  padding: 0;
`;

const StyledContributionHaderContainer = styled.div`
  padding: 0 16px;
  margin: 0 auto;

  @media screen and (${devices.desktop}) {
    padding: 0 240px;
  }

  ${({ mobileView }) => mobileView && `padding: 0px`}
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: ${toRem(16)};
  line-height: 1;
  font-weight: 900;
  color: #4a4a4a;
  padding: 16px 32px;
  display: inline-block;
  position: relative;

  &.active {
    color: ${colors.darkOceanBlue};
  }

  &.active:after {
    content: '';
    width: 100%;
    height: 4px;
    background-color: ${colors.darkOceanBlue};
    position: absolute;
    bottom: 0;
    left: 0;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
    padding: 16px;
    font-size: 16px;
  `}
`;

const ContributionHeaderView = ({ mobileView, children }) => {
  return (
    <StyledContributionHaderWrapper>
      <StyledContributionHaderContainer mobileView={mobileView}>{children}</StyledContributionHaderContainer>
    </StyledContributionHaderWrapper>
  );
};

ContributionHeaderView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContributionHeaderView;
