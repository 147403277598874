import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import * as R from 'ramda';

import { useHttp, useShallowEqualSelector, useEndVideoChat } from 'hooks';
import Loader from 'components/UI/Loader';
import { setDashboardSessions, clearDashboardSessions } from 'actions/dashboardSessions';
import { setDashboardIncome, clearDashboardIncome } from 'actions/dashboardIncome';

import BannerContainer from './BannerContainer/BannerContainer';
import IntroScreen from './IntroScreen';
import Dashboard from './Dashboard/Dashboard';
import SelectingPlanModal from '../../../../components/Modals/SelectingPlanModal';

import { userSelector } from '../../../../selectors/user';

const StyledWrapper = styled.div`
  padding: 30px 55px;

  ${({ mobileview }) => mobileview && `padding: 16px;`}
`;

const CohealerDashboardContainer = () => {
  const dispatch = useDispatch();
  const { request, loading } = useHttp();

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const user = useSelector(userSelector);

  const getDashboardSessions = useCallback(() => {
    request(`/Contribution/GetForCohealerDashboard`, 'GET')
      .then(R.compose(dispatch, setDashboardSessions))
      .catch(console.dir);
  }, [dispatch, request]);

  const { Popup } = useEndVideoChat(getDashboardSessions);

  const closestClassForBanner = useShallowEqualSelector(state => state?.dashboardSessions?.closestClassForBanner);
  const coachDeclinedSubscriptions = useShallowEqualSelector(
    state => state?.dashboardSessions?.coachDeclinedSubscriptions,
  );
  const sessions = useShallowEqualSelector(state => state?.dashboardSessions?.contributionsForDashboard || []);
  const incomes = useShallowEqualSelector(state => state?.dashboardIncome?.incomes || []);

  useEffect(() => {
    getDashboardSessions();

    return R.compose(dispatch, clearDashboardSessions);
  }, [dispatch, getDashboardSessions]);

  useEffect(() => {
    request(`/api/cohealer-income/dashboard`, 'GET').then(R.compose(dispatch, setDashboardIncome)).catch(console.dir);

    return R.compose(dispatch, clearDashboardIncome);
  }, [dispatch, request]);

  if (loading) {
    return <Loader />;
  }

  return (
    <StyledWrapper mobileview={mobileView}>
      <BannerContainer
        closestClassForBanner={closestClassForBanner}
        coachDeclinedSubscriptions={coachDeclinedSubscriptions}
      />
      {!R.isEmpty(sessions) || !R.isEmpty(incomes) ? (
        <Dashboard sessions={sessions} incomes={incomes} />
      ) : (
        <IntroScreen />
      )}

      {user.paidTierOptionBannerHidden ? null : <SelectingPlanModal />}
      <Popup />
    </StyledWrapper>
  );
};
export default CohealerDashboardContainer;
