import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
// import logger from "redux-logger";

import rootReducer from 'reducers';

const middlewares = [thunk];

// if (process.env.NODE_ENV === "development") {
//   middlewares.push(logger);
// }

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['alerts', 'paidTierOptions'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store;
let persistor;

const configureStore = () => {
  if (!store || !persistor) {
    const middlewaresStoreEnhancer = applyMiddleware(...middlewares);
    const storeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION__ // eslint-disable-line
      ? compose(middlewaresStoreEnhancer, window.__REDUX_DEVTOOLS_EXTENSION__()) // eslint-disable-line
      : middlewaresStoreEnhancer;
    store = createStore(persistedReducer, storeEnhancer);
    persistor = persistStore(store);
  }

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  return { store, persistor };
};

export { configureStore };
