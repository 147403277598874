import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { Link } from 'react-router-dom';
import orderBy from 'lodash/orderBy';

import PlaceholderImage from 'assets/placeholder.jpg';
import { ClientJourneyContribution } from 'services/dataContracts/contributions';

import './ContributionsList.scss';
import { useMediaQuery, useTheme } from '@material-ui/core';

const useColsCount = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return 1;
  }

  if (isTablet) {
    return 2;
  }

  if (isLaptop) {
    return 3;
  }

  return 4;
};

const ContributionsList = ({ className, contributions, sortKeySelector, sortOrder }) => {
  const contributionsListRef = useRef();

  const orderedContributions = useMemo(() => orderBy(contributions, sortKeySelector, sortOrder), [
    contributions,
    sortKeySelector,
    sortOrder,
  ]);

  const colsCount = useColsCount();

  return (
    <div ref={contributionsListRef} className={className}>
      <GridList cols={colsCount}>
        {orderedContributions.map(c => (
          <GridListTile key={c.id} className="client-journey-contributions-list-contribution">
            <img src={c.previewContentUrls[0] || PlaceholderImage} alt={c.title} />
            <GridListTileBar
              classes={{
                root: 'client-journey-contributions-list-contribution__title-bar',
                subtitle: 'client-journey-contributions-list-contribution__subtitle',
              }}
              title={
                <div className="client-journey-contributions-list-contribution__title">
                  <span>{c.title}</span>
                  <span>
                    {c.numberCompletedSessions}/{c.totalNumberSessions}
                  </span>
                </div>
              }
              titlePosition="bottom"
              subtitle={`by: ${c.serviceProviderName}${
                c.sessionTime ? `, ${c.sessionTime.format('MMM Do YYYY h:mm a')}` : ''
              }`}
            />
            <Link
              className="client-journey-contributions-list-contribution__link"
              to={`/contribution-view/${c.id}/sessions`}
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

ContributionsList.propTypes = {
  className: PropTypes.string,
  contributions: PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired,
  sortKeySelector: PropTypes.func || PropTypes.arrayOf(PropTypes.string),
  sortOrder: PropTypes.string,
};

ContributionsList.defaultProps = {
  className: '',
  sortKeySelector: c => c.sessionTime.unix(),
  sortOrder: 'asc',
};

export default ContributionsList;
