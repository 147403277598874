import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Loader from 'components/UI/Loader';
import { useParams } from 'react-router-dom';
import ContributionTableAdmin from './ContributionTableAdmin';

const StyledAdminContributionWrapper = styled.div`
  padding: 30px 10px 30px 60px;
`;

const ContributionsAdminContainer = ({ contributions, loading }) => {
  const { type } = useParams();

  if (loading) {
    return <Loader />;
  }

  const contributionsList = contributions && contributions[type];

  if (!contributionsList || !contributionsList.length) {
    return <p>No contributions for {type}</p>;
  }

  return (
    <StyledAdminContributionWrapper>
      <ContributionTableAdmin rows={contributionsList} />
    </StyledAdminContributionWrapper>
  );
};

ContributionsAdminContainer.propTypes = {
  contributions: PropTypes.shape({
    review: PropTypes.array,
    updated: PropTypes.array,
    approved: PropTypes.array,
    rejected: PropTypes.array,
  }),
  loading: PropTypes.bool.isRequired,
};

ContributionsAdminContainer.defaultProps = {
  contributions: {},
};

const mapStateToProps = ({ contributions: { adminContributions, loading } }) => ({
  contributions: adminContributions,
  loading,
});

export default connect(mapStateToProps)(ContributionsAdminContainer);
