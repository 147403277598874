import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '@mdi/react';
import { mdiCheckDecagram } from '@mdi/js';

import { colors } from '../../utils/styles';
import { makeStyles } from '@material-ui/core';

const StyledLi = styled.li`
  font-size: 14px;
  color: ${colors.advantagesColor};

  & > .li-icon {
    margin-right: 6px;
  }
`;

const useStyles = makeStyles(theme => ({
  styledLiMedia: {
    [theme.breakpoints.up(2000)]: {
      fontSize: 18,
    },
    [theme.breakpoints.down(1500)]: {
      fontSize: 11,
    },
  },
}));

const AdvantagesItem = ({ label, padding = '0px' }) => {
  const classes = useStyles();

  return (
    <StyledLi style={{ padding }} className={classes.styledLiMedia}>
      <Icon path={mdiCheckDecagram} size={0.7} color={colors.darkOceanBlue} className="li-icon" />
      {label}
    </StyledLi>
  );
};

AdvantagesItem.propTypes = {
  label: PropTypes.string.isRequired,
  padding: PropTypes.string,
};

export default AdvantagesItem;
