import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import * as R from 'ramda';
import { useTheme, useMediaQuery } from '@material-ui/core';
import instagramIcon from 'assets/instagram.svg';
import linkedinIcon from 'assets/linkedin.svg';
import youtubeIcon from 'assets/youtube.svg';
import webIcon from 'assets/web.svg';
import facebookIcon from 'assets/facebook.svg';

import {
  setCohealerInfoForClient,
  clearCohealerInfoForClient,
  setCohealerIcon,
  clearCohealerIcon,
} from 'actions/cohealerInfo';
import { useHttp, useShallowEqualSelector } from 'hooks';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond, LabelText } from 'components/UI/Text/TextStyles';
import { CoachItem } from './CoachItem';

const Image = styled.img`
  background-color: #f5f5f5;
  width: 100%;
  max-height: 360px;
  object-fit: contain;
  display: block;
`;
const StyledLabelText = styled(LabelText)`
  font-weight: 500;
`;
const StyledImg = styled.img`
  margin-right: 5px;
  margin-bottom: 5px;
`;
const SocialGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  border-top: 1px solid #e7e7e7;
  margin-top: 20px !important;
`;
const StyledCardBody = styled(CardBody)`
  padding: 16px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ mobileView }) => mobileView && `padding: 8px;`}
`;

const AboutMainInfoBlock = ({
  id,
  userId,
  previewContentUrls,
  serviceProviderName,
  contributionPartners,
  socialUrls: { instagramUrl, linkedInUrl, youtubeUrl, facebookUrl, websiteUrl },
}) => {
  const dispatch = useDispatch();
  const { request } = useHttp();
  const avatarUrl = useShallowEqualSelector(state => state?.cohealerInfo?.avatarUrl);

  useEffect(() => {
    request(`/Contribution/GetCohealerInfoForClient/${userId}`, 'GET')
      .then(R.compose(dispatch, setCohealerInfoForClient))
      .catch(console.dir);

    return R.compose(dispatch, clearCohealerInfoForClient);
  }, [userId, dispatch, request]);

  useEffect(() => {
    request(`/User/GetCohealerIcon/${id}`, 'GET').then(R.compose(dispatch, setCohealerIcon)).catch(console.dir);

    return R.compose(dispatch, clearCohealerIcon);
  }, [id, dispatch, request]);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const socialLinks = [
    {
      title: 'Website',
      url: websiteUrl,
      icon: webIcon,
    },
    {
      title: 'Instagram',
      url: instagramUrl,
      icon: instagramIcon,
    },
    {
      title: 'LinkedIn',
      url: linkedInUrl,
      icon: linkedinIcon,
    },
    {
      title: 'Facebook',
      url: facebookUrl,
      icon: facebookIcon,
    },
    {
      title: 'Youtube',
      url: youtubeUrl,
      icon: youtubeIcon,
    },
  ];
  const isAnyLinkExist = !!socialLinks.filter(sl => !!sl.url).length;

  return (
    <Grid container spacing={4}>
      <Grid item md={6} sm={12} xs={12}>
        <Card maxHeight>
          <Image src={previewContentUrls[0]} />
        </Card>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <Card maxHeight>
          <CardHeader mobileView={mobileView}>
            <PageTitleSecond mobileView={mobileView}>Meet Your Coach</PageTitleSecond>
          </CardHeader>
          <StyledCardBody mobileView={mobileView}>
            <Grid container spacing={2}>
              <CoachItem
                serviceProviderName={serviceProviderName}
                avatarUrl={avatarUrl}
                userId={userId}
                mobileView={mobileView}
              />
              {contributionPartners &&
                contributionPartners.map(({ firstName, lastName, avatarUrl, userId }) => (
                  <CoachItem
                    serviceProviderName={null}
                    firstName={firstName}
                    lastName={lastName}
                    avatarUrl={avatarUrl}
                    userId={userId}
                    mobileView={mobileView}
                  />
                ))}
            </Grid>
            {isAnyLinkExist && (
              <SocialGrid container spacing={2}>
                {socialLinks.map(({ url, icon, title }) => {
                  return url ? (
                    <Grid item key={title}>
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        <StyledImg src={icon} alt={title} />
                        <StyledLabelText mobileView={mobileView}>{title}</StyledLabelText>
                      </a>
                    </Grid>
                  ) : null;
                })}
              </SocialGrid>
            )}
          </StyledCardBody>
        </Card>
      </Grid>
    </Grid>
  );
};

AboutMainInfoBlock.propTypes = {
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  previewContentUrls: PropTypes.arrayOf(PropTypes.string),
  serviceProviderName: PropTypes.string,
  contributionPartners: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      avatarUrl: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ).isRequired,
  socialUrls: PropTypes.shape({
    instagramUrl: PropTypes.string,
    linkedInUrl: PropTypes.string,
    youtubeUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    websiteUrl: PropTypes.string,
  }),
};

AboutMainInfoBlock.defaultProps = {
  previewContentUrls: [],
  serviceProviderName: 'No name',
  socialUrls: {},
};

export default AboutMainInfoBlock;
