import React from 'react';

import List, { Item } from './List';
import {
  AllConversationsUnreadMessagesCount,
  CohealersConversationsUnreadMessagesCount,
  DiscussionsConversationsUnreadMessagesCount,
} from './conversations/UnreadMessagesCount';

function ClientMenu() {
  return (
    <List>
      <Item id="client-menu-my-journey-link" to="/dashboard" title="My Journey" />
      <Item id="client-menu-conversations-link" title="Conversations" isList>
        <List>
          <Item
            id="client-menu-all-conversations-link"
            to="/conversations/all"
            title="All"
            endAdornment={<AllConversationsUnreadMessagesCount />}
          />
          <Item
            id="client-menu-coaches-conversations-link"
            to="/conversations/cohealers"
            title="Coaches"
            endAdornment={<CohealersConversationsUnreadMessagesCount />}
          />
          <Item
            id="client-menu-group-conversations-link"
            to="/conversations/discussions"
            title="Group Discussions"
            endAdornment={<DiscussionsConversationsUnreadMessagesCount />}
          />
        </List>
      </Item>
    </List>
  );
}

export default ClientMenu;
