import React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import { colors, toRem } from '../../../../../utils/styles';
import { Field } from 'formik';
import Button from '../../../../../components/FormUI/Button';
import { Link } from 'react-router-dom';

export const StyledContainer = styled(Grid)`
  border-radius: 23px;
  background: ${colors.white};
  box-shadow: 0 9px 24px 14px rgba(0, 0, 0, 0.02);
  margin-bottom: 20px;
  padding: 20px 20px 25px 20px;
`;

export const StyledMetaData = styled(Grid).attrs({
  direction: 'row',
  justify: 'flex-end',
})`
  margin-top: 14px;
`;
export const StyledName = styled.span`
  font-size: ${toRem(14)};
  font-weight: 900;
  display: flex;
  align-items: flex-end;
`;
export const StyledCommentName = styled.span`
  font-size: ${toRem(14)};
  font-weight: 900;
`;
export const StyledDate = styled.div`
  color: #525458;
`;
export const StyledClickDropDown = styled.span`
  margin-left: 20px;
`;
export const StyledMessage = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;
export const StyledText = styled.div`
  margin: 15px 0;
  color: #525458;
  font-weight: 500;
  word-break: break-word;
`;
export const StyledLikeInfo = styled.div`
  margin-top: 16px;
  color: #060606;
  cursor: pointer;
`;

export const AvatarComponent = styled(Avatar)`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        font-size: 0.7rem;
      `
    );
  }}
`;

export const StyledContainerComment = styled(Grid)`
  ${({ additionalHeight }) => {
    return `height: calc(0 + ${additionalHeight}px);`;
  }}
  flex-wrap: nowrap !important;
`;

export const AvatarComponentComment = styled(Avatar)`
  width: 40px;
  height: 40px;
  margin: 15px;

  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        font-size: 0.7rem;
      `
    );
  }}
`;
export const StyledInputWrapper = styled(Grid)`
  ${({ additionalHeight }) => {
    return `height: calc(46px + ${additionalHeight}px);`;
  }}

  border-radius: 46px;
  background: ${colors.lightGray};
  width: calc(100% - 65px) !important;
  margin-top: 15px;
`;
export const StyledTextArea = styled(Field)`
  border: none;
  border-radius: 40px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.24;
  letter-spacing: 0.25px;
  resize: none;
  padding: 0.85rem;
  font-family: 'Avenir';
  font-weight: normal;
  outline: none;
  background: ${colors.lightGray};
  width: 100%;
  height: 100%;
`;
export const StyledButton = styled(Button)`
  height: 36px;
  width: 36px;
  margin-top: 19px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100px;
`;
export const StyledTextAreaWrap = styled(Grid)`
  display: flex;
  align-items: flex-end;
`;
export const StyledCommentsToggle = styled.div`
  color: #a9a9a9;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const StyledCommentWrap = styled.div`
  margin-bottom: 15px;
  word-break: break-word;
`;
export const StyledAttachmentsWrap = styled.div`
  margin: 0 -15px;
`;
export const StyleTextLink = styled.a`
  color: ${colors.darkOceanBlue};
  font-weight: 600;
  &:hover {
    color: ${colors.darkOceanBlue};
  }
`;
export const StyledProfileLink = styled(Link)`
  &:hover {
    text-decoration: none;
    color: ${colors.darkOceanBlue};
  }
`;
export const StyledRemoveComment = styled.span`
  cursor: pointer;
`;
export const StyledTextPost = styled.p`
  min-height: 1rem;
  margin-bottom: 0;
`;
export const StyledTextError = styled.span`
  color: ${colors.fadedPurple} !important;
  text-align: left;
  font-size: 1rem;
  letter-spacing: 0.1px;
  padding-bottom: 10px;
  padding-left: 30px;
  ${({ mobileView }) => {
    return (
      mobileView &&
      ` 
         font-size: .8rem;
         padding-left: 20px;`
    );
  }}
`;
