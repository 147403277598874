import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik, Form, FieldArray } from 'formik';

import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import { PAYMENT_OPTIONS, TOOLTIP } from 'constants.js';
import styled from 'styled-components';
import { formatMoney } from 'utils/datesAndMoney';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { ContributionStatus } from 'helpers/constants';
import { membershipPaymentValidationSchema } from '../../../contributionValidations';
import { StyledHeader, BlueCheckbox } from './styledComponents';
import { durationItems, sessionNumbersItems, FORM_FIELDS } from './constants';
import { useRouter } from '../../../../../hooks';
import { colors } from '../../../../../utils/styles';

const StyledText = styled.div`
  font-size: 0.95rem;
  margin-top: 12px;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;
const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: -15px;
  top: calc(50% - 5px);
  transform: translateY(-50%);
`;
const StyledTooltipWrap = styled.span`
  position: relative;
  padding-right: 15px;
  display: inline-block;
`;
const Membership = ({ contribution, saveToStorageHandler, children }) => {
  const formatPackageSubscriptionPrice = ({ membershipPackage: { duration, period, cost } }) =>
    `Price for ${duration} months with ${period.toLowerCase()} payments: ${formatMoney(cost)}$`;
  const handleFormKeyDown = e => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };
  const { isDev, query } = useRouter();
  const isFeeDisabled = !!query.id && contribution?.status !== ContributionStatus.unfinished;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        [FORM_FIELDS.PAYMENT_OPTIONS]: contribution?.paymentInfo?.paymentOptions || [PAYMENT_OPTIONS.PER_MONTH],
        [FORM_FIELDS.PER_DAY_COST]: contribution?.paymentInfo?.membershipInfo?.costs?.DailyMembership || '',
        [FORM_FIELDS.PER_WEEK_COST]: contribution?.paymentInfo?.membershipInfo?.costs?.WeeklyMembership || '',
        [FORM_FIELDS.PER_MONTH_COST]: contribution?.paymentInfo?.membershipInfo?.costs?.MonthlyMembership || '',
        [FORM_FIELDS.PER_YEAR_COST]: contribution?.paymentInfo?.membershipInfo?.costs?.YearlyMembership || '',
        [FORM_FIELDS.MEMBERSHIP_PACKAGE]: contribution?.paymentInfo?.membershipInfo?.membershipPackage || {},
        coachPaysStripeFee: contribution?.paymentInfo?.coachPaysStripeFee,
      }}
      validationSchema={membershipPaymentValidationSchema}
      onSubmit={saveToStorageHandler}
    >
      {formProps => {
        const renderCardBody = (
          <Form onKeyDown={handleFormKeyDown}>
            <Grid container spacing={4} alignItems="flex-start">
              <Grid item container sm={3} xs={12} spacing={1}>
                {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeader>Price Per Day</StyledHeader>
                    </Grid>
                    <Grid item xs={12}>
                      <Input type="text" startAdornment="$" name={FORM_FIELDS.PER_DAY_COST} fullWidth />
                    </Grid>
                  </>
                )}
                {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeader>Price Per Week</StyledHeader>
                    </Grid>
                    <Grid item xs={12}>
                      <Input type="text" startAdornment="$" name={FORM_FIELDS.PER_WEEK_COST} fullWidth />
                    </Grid>
                  </>
                )}

                {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeader>Price Per Month</StyledHeader>
                    </Grid>
                    <Grid item xs={12}>
                      <Input type="text" startAdornment="$" name={FORM_FIELDS.PER_MONTH_COST} fullWidth />
                    </Grid>
                  </>
                )}
                {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeader>Price Per Year</StyledHeader>
                    </Grid>
                    <Grid item xs={12}>
                      <Input type="number" startAdornment="$" name={FORM_FIELDS.PER_YEAR_COST} fullWidth />
                    </Grid>
                  </>
                )}
                {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeader>Price Per Package</StyledHeader>
                    </Grid>
                    <Grid item xs={12}>
                      <Input type="number" startAdornment="$" name={FORM_FIELDS.MEMBERSHIP_PACKAGE_COST} fullWidth />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item container sm={6} xs={12} spacing={1}>
                <Grid item xs={12}>
                  <StyledHeader>Payment Options</StyledHeader>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <FieldArray name={FORM_FIELDS.PAYMENT_OPTIONS}>
                      {({ push, remove, form }) => {
                        const changeHandler = ({ target: { value, checked } }) =>
                          checked ? push(value) : remove(form.values.paymentOptions.indexOf(value));

                        return (
                          <FormControl error={!!formProps.errors.paymentOptions}>
                            {formProps.errors.paymentOptions && (
                              <FormLabel component="legend">Pick at least one option</FormLabel>
                            )}
                            {isDev && (
                              <>
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    name={FORM_FIELDS.PAYMENT_OPTIONS}
                                    control={<BlueCheckbox color="primary" />}
                                    label="Price Per Day"
                                    value={PAYMENT_OPTIONS.PER_DAY}
                                    onChange={changeHandler}
                                    checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY)}
                                    classes={{ root: 'label' }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    name={FORM_FIELDS.PAYMENT_OPTIONS}
                                    control={<BlueCheckbox color="primary" />}
                                    label="Price Per Week"
                                    value={PAYMENT_OPTIONS.PER_WEEK}
                                    onChange={changeHandler}
                                    checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK)}
                                    classes={{ root: 'label' }}
                                  />
                                </Grid>
                              </>
                            )}

                            <Grid item xs={12}>
                              <StyledTooltipWrap>
                                <FormControlLabel
                                  name={FORM_FIELDS.PAYMENT_OPTIONS}
                                  control={<BlueCheckbox color="primary" />}
                                  label="Price Per Month"
                                  value={PAYMENT_OPTIONS.PER_MONTH}
                                  onChange={changeHandler}
                                  checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH)}
                                />
                                <StyledTooltip
                                  title="Create a monthly membership program where your clients are charged monthly."
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <InfoIcon htmlColor={colors.lightBrown} />
                                </StyledTooltip>
                              </StyledTooltipWrap>
                            </Grid>
                            <Grid item xs={12}>
                              <StyledTooltipWrap>
                                <FormControlLabel
                                  name={FORM_FIELDS.PAYMENT_OPTIONS}
                                  control={<BlueCheckbox color="primary" />}
                                  label="Price Per Year"
                                  value={PAYMENT_OPTIONS.PER_YEAR}
                                  onChange={changeHandler}
                                  checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR)}
                                />
                                <StyledTooltip
                                  title="Create a monthly membership program where your clients are charged annually."
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <InfoIcon htmlColor={colors.lightBrown} />
                                </StyledTooltip>
                              </StyledTooltipWrap>
                            </Grid>
                          </FormControl>
                        );
                      }}
                    </FieldArray>

                    <FieldArray name={FORM_FIELDS.PAYMENT_OPTIONS}>
                      {({ push, remove, form }) => {
                        const changeHandler = ({ target: { value, checked } }) => {
                          if (checked) {
                            if (value === PAYMENT_OPTIONS.PACKAGE) {
                              form.setFieldValue(FORM_FIELDS.MEMBERSHIP_PACKAGE_DURATION, 3);
                              form.setFieldValue(FORM_FIELDS.MEMBERSHIP_PACKAGE_PERIOD, 'Monthly');
                              form.setFieldValue(FORM_FIELDS.MEMBERSHIP_PACKAGE_COST, '');
                            }
                            push(value);
                          } else {
                            const idx = form.values.paymentOptions.indexOf(value);
                            remove(idx);
                          }
                        };

                        // TODO: uncomment when BE will be ready
                        return (
                          <>
                            {/* <Grid item xs={12}> */}
                            {/*  <FormControlLabel */}
                            {/*    name={FORM_FIELDS.PAYMENT_OPTIONS} */}
                            {/*    control={<BlueCheckbox color="primary" />} */}
                            {/*    label="Package" */}
                            {/*    value={PAYMENT_OPTIONS.PACKAGE} */}
                            {/*    onChange={changeHandler} */}
                            {/*    checked={form.values.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE)} */}
                            {/*  /> */}
                            {/* </Grid> */}
                            <Grid item container spacing={2} xs={12}>
                              <Grid item xs={6}>
                                {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && (
                                  <Select
                                    fullWidth
                                    label="Duration"
                                    items={durationItems()}
                                    name={FORM_FIELDS.MEMBERSHIP_PACKAGE_DURATION}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && (
                                  <Select
                                    fullWidth
                                    label="Billing Frequency"
                                    items={sessionNumbersItems()}
                                    name={FORM_FIELDS.MEMBERSHIP_PACKAGE_PERIOD}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </>
                        );
                      }}
                    </FieldArray>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTooltipWrap>
                      <FormControlLabel
                        name="coachPaysStripeFee"
                        control={<BlueCheckbox color="primary" />}
                        label="Coach Pays for Processing Fee"
                        checked={formProps.values.coachPaysStripeFee}
                        onChange={formProps.handleChange('coachPaysStripeFee')}
                        disabled={isFeeDisabled}
                      />
                      <StyledTooltip
                        title="If checked, the Stripe credit card processing fee will be passed onto your Clients, resulting in a 3-4% surcharge on top of your base pricing. For example, if your price is $100, the total purchase price will be $103.00-$104.00). If unchecked, your client will be charged a flat fee and all Stripe fees will be deducted from your gross sales. For example, if your price is $100, Stripe will take 3-4% off the total purchase price ($3.00-$4.00) and you will earn $96.00-$97.00, less any additional Cohere fees."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon htmlColor={colors.lightBrown} />
                      </StyledTooltip>
                    </StyledTooltipWrap>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container sm={3} xs={12} spacing={1}>
                <Grid item xs={12}>
                  {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && (
                    <StyledText>
                      Price per month subscription: ${formatMoney(formProps.values[FORM_FIELDS.PER_MONTH_COST])} Per
                      Month
                    </StyledText>
                  )}
                  {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && (
                    <StyledText>
                      Price per year subscription: ${formatMoney(formProps.values[FORM_FIELDS.PER_YEAR_COST])} Per Year
                    </StyledText>
                  )}
                  {formProps.values.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && (
                    <StyledText>{formatPackageSubscriptionPrice(formProps.values)}</StyledText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );

        return children(formProps, renderCardBody);
      }}
    </Formik>
  );
};

Membership.propTypes = {
  contribution: PropTypes.shape(),
  saveToStorageHandler: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

Membership.defaultProps = {
  contribution: null,
};

export default Membership;
