import React, { useState, useEffect, useMemo } from 'react';
import Button from 'components/FormUI/Button';
import { useHttp, useAccount } from 'hooks';
import { useStripe } from '@stripe/react-stripe-js';
import { redirectInNewTab } from 'services/links';

const BillingPage = () => {
  const { request } = useHttp();
  const stripe = useStripe();
  const { user } = useAccount();
  const [declinedSubs, setDeclinedSubs] = useState({});
  useEffect(() => {
    request(`/Contribution/GetAllBoughtByUserId/${user.id}`)
      .then(({ clientDeclinedSubscriptions }) => {
        setDeclinedSubs(clientDeclinedSubscriptions);
      })
      .catch(console.dir);
  }, []);

  const onClick = () => {
    request('/subscription/CreateCheckoutSession', 'POST')
      .then(checkoutSessionId =>
        stripe.redirectToCheckout({
          sessionId: checkoutSessionId,
        }),
      )
      .catch(console.dir);
  };

  var showUpdatePaymentsDetailsButton = useMemo(() => {
    return declinedSubs && declinedSubs.length > 0;
  }, [declinedSubs]);

  const openCustomerPortal = () => {
    request('/api/Payment/CreateCustomerPortalLink', 'POST')
      .then(customerPortalLink => redirectInNewTab(customerPortalLink))
      .catch(console.dir);
  };
  return (
    <>
      {showUpdatePaymentsDetailsButton && (
        <>
          <p>There was a problem processing your payment. Please update your payment information.</p>
          <Button onClick={onClick}>Update payment information</Button>
        </>
      )}
      {!showUpdatePaymentsDetailsButton && (
        <>
          <p>Your payment information is securely stored on Stripe. You can update your payment information here.</p>
          <Button onClick={openCustomerPortal}>Update payment method</Button>
        </>
      )}
    </>
  );
};

export default BillingPage;
