import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { FieldArray } from 'formik';
import * as R from 'ramda';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme, useMediaQuery } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Session from '../../Session';
import Button from 'components/FormUI/Button';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { toRem, colors } from 'utils/styles';
import { useShallowEqualSelector } from 'hooks';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { getNextCurrentSessionTime } from 'utils/getSessionTime';
import { TOOLTIP, TIMEZONES } from 'constants.js';

const StyledTimeZoneLabel = styled.p`
  display: inline-block;
  font-size: ${toRem(13)};
  background: ${colors.darkOceanBlue};
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
`;

const PARTICIPANTS_NUMBER = {
  MIN: 2,
  MAX: 10,
};

const Sessions = ({ enrollment, sessions, errors, touched, providerName }) => {
  const [sessionCount, setSessionCount] = useState(R.inc(sessions.length));
  const user = useShallowEqualSelector(state => state?.account?.user);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const sessionsCount = sessions.length - 1;

  return (
    <Grid item xs={12}>
      <Card mobileView={mobileView}>
        <CardHeader mobileView={mobileView}>
          <PageTitleSecond mobileView={mobileView}>Sessions</PageTitleSecond>
          <NavLink to="/account/profile">
            <StyledTimeZoneLabel>{TIMEZONES[user.timeZoneId]}</StyledTimeZoneLabel>
          </NavLink>
          <Tooltip
            title="You can edit contribution sessions, update times and even add additional sessions after the contribution is created."
            arrow
            className="ml-2"
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          >
            <InfoIcon htmlColor={colors.lightBrown} />
          </Tooltip>
        </CardHeader>
        <CardBody mobileView={mobileView}>
          {' '}
          <FieldArray name="sessions">
            {({ push, remove, form }) => {
              const addHandler = data => {
                const lastSession = sessions[sessionsCount];
                const lastSessionTime =
                  lastSession && lastSession.sessionTimes.length > 0
                    ? lastSession.sessionTimes[lastSession.sessionTimes.length - 1]
                    : {};
                push({
                  ...data,
                  title: `Session ${sessionCount}`,
                  minParticipantsNumber: lastSession ? lastSession.minParticipantsNumber : PARTICIPANTS_NUMBER.MIN,
                  maxParticipantsNumber: lastSession ? lastSession.maxParticipantsNumber : PARTICIPANTS_NUMBER.MAX,
                  dateAvailable: data.isPrerecorded
                    ? getNextCurrentSessionTime(false, lastSessionTime.startTime || enrollment.toDate).startTime
                    : null,
                  sessionTimes: [getNextCurrentSessionTime(false, lastSessionTime.startTime || enrollment.toDate)],
                });

                setSessionCount(R.inc);
              };

              const removeHandler = id => {
                remove(id);
                setSessionCount(R.dec);
              };
              return (
                <>
                  {sessions.map((session, index) => {
                    const key = `${index}`;
                    return (
                      <Session
                        key={key}
                        index={index}
                        session={session}
                        providerName={providerName}
                        isPrerecorded={session.isPrerecorded}
                        timesCount={session?.sessionTimes?.length || 1}
                        onDelete={removeHandler}
                        form={form}
                      />
                    );
                  })}

                  <Button type="button" className="mr-3" onClick={() => addHandler({ isPrerecorded: false })} autoWidth>
                    Create More Sessions
                  </Button>
                  {/*<Button*/}
                  {/*  type="button"*/}
                  {/*  className="mt-2"*/}
                  {/*  onClick={() =>*/}
                  {/*    addHandler({*/}
                  {/*      isPrerecorded: true,*/}
                  {/*    })*/}
                  {/*  }*/}
                  {/*  autoWidth*/}
                  {/*>*/}
                  {/*  Add Self-Paced Recording*/}
                  {/*</Button>*/}
                </>
              );
            }}
          </FieldArray>
          {errors.sessions && touched.sessions && typeof errors.sessions === 'string' && (
            <CommonErrorMessage message={errors.sessions} />
          )}
        </CardBody>
      </Card>
    </Grid>
  );
};

Sessions.propTypes = {
  enrollment: PropTypes.shape({
    toDate: PropTypes.string,
    fromDate: PropTypes.string,
    anyTime: PropTypes.bool,
  }).isRequired,
  sessions: PropTypes.arrayOf(),
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
};

export default Sessions;
