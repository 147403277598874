import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { isEmptyOrNil } from 'utils/utils';
import CardSession from './CardSession/CardSession';
import CardSales from './CardSales/CardSales';

import './Dashboard.scss';

const renderCards = (cards, type) =>
  cards.map(card => (
    <Grid
      key={card.id}
      item
      xs={12}
      classes={{
        root: 'grid_vertical_item',
      }}
    >
      {type === 'sessions' ? <CardSession card={card} /> : <CardSales card={card} />}
    </Grid>
  ));

const renderSalesCards = cards => {
  const sum = cards.reduce((acc, curr) => acc + curr.netIncomeAmount, 0);
  const firstCard = {
    contributionType: 'Total Earned Revenue',
    netIncomeAmount: sum,
    moreLink: '/account/payment',
  };

  return renderCards([firstCard, ...cards], 'sales');
};

const Dashboard = ({ sessions, incomes }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const cardSize = mobileView ? 12 : 6;
  return (
    <Grid
      container
      justify="center"
      classes={{
        root: 'grid',
      }}
      spacing={2}
    >
      <Grid
        container
        item
        xs={cardSize}
        classes={{
          root: 'grid_vertical',
        }}
      >
        <div className="column-title-container">
          <span className="column-title-container__text">Upcoming Sessions</span>
          <Link className="column-title-container__view-all" to="contributions/all">
            View All
          </Link>
        </div>
        {isEmptyOrNil(sessions) ? (
          <div className="mt-2">
            Your scheduled sessions will appear here once you create a group course or a client books a 1:1 sessions
            with you
          </div>
        ) : (
          renderCards(sessions, 'sessions')
        )}
      </Grid>
      <Grid
        container
        item
        xs={cardSize}
        classes={{
          root: 'grid_vertical',
        }}
      >
        <div className="column-title-container">
          <span className="column-title-container__text">Sales</span>
        </div>
        {renderSalesCards(incomes)}
      </Grid>
    </Grid>
  );
};

Dashboard.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  incomes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Dashboard;
