import React, { useState } from 'react'

import { useHttp, useAccount } from 'hooks'
import { useTheme, useMediaQuery, makeStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

import Button from 'components/FormUI/Button'

import styled from 'styled-components'

import '../Banner.scss'
import './Email.scss'

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`

const useStyles = makeStyles((theme) => ({
  resendEmailButton: {
    width: '10rem',
  },
}))

const Email = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const { request } = useHttp()
  const { user } = useAccount()
  const theme = useTheme()
  const classes = useStyles()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const BannerButton = mobileView ? StyledButton : Button

  const onResendEmail = () => {
    request('Account/RequestEmailConfirmation', 'POST').then(() => setTooltipOpen(true))
  }

  return (
    !user.isEmailConfirmed && (
      <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
        <p className="banner-container__text banner-container__email">{`Please verify ${user.email}`}</p>
        <Tooltip
          className="cursor-pointer"
          title="Email has been sent"
          open={tooltipOpen}
          arrow
          onClose={() => {
            setTooltipOpen(false)
          }}
        >
          <span>
            <BannerButton
              variant="primary"
              className={`banner-container__button ${classes.resendEmailButton}`}
              autoWidth
              onClick={onResendEmail}
            >
              Resend email
            </BannerButton>
          </span>
        </Tooltip>
      </div>
    )
  )
}

export default Email
