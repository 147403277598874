const copyContributionLinkToClipboard = ({ contributionId, code }) => {
  const dummy = document.createElement('input');
  const { href } = window.location;
  const textArray = href.split('/');
  const textArrayCorrect = textArray.length === 7 ? textArray.slice(0, -1) : textArray;
  const text = textArrayCorrect.slice(0, contributionId ? -2 : -1).join('/');
  document.body.appendChild(dummy);

  const contributionLabel = contributionId ? `/contribution-view/${contributionId}` : '';
  const codeLabel = code ? `/${code}` : '';

  dummy.value = `${text}${contributionLabel}${codeLabel}/about`;
  dummy.focus();
  dummy.select();
  dummy.setSelectionRange(0, 99999);

  // It is work for premium feature copying
  setTimeout(() => {
    document.execCommand('copy');
  }, 150);

  setTimeout(() => {
    document.body.removeChild(dummy);
  }, 300);
};

export default copyContributionLinkToClipboard;
