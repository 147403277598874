import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { cohereAcademyIsEnableSelector } from 'selectors/user';

import SmallLoader from 'components/UI/SmallLoader';
import {
  useStyles,
  StyledContainer,
  PreviewImage,
  StyledTitle,
  StyledAuthor,
  StyledTheme,
  StyledRating,
  EnrollButton,
  DisabledEnrollButton,
  AvatarComponent,
  ExpiresLabel,
} from './style';

const getInitialSymbol = R.compose(R.toUpper, R.head);

const AdvertismentCard = ({
  img,
  title,
  userId,
  author,
  theme,
  handleEnrollOnclick,
  isActive,
  handleLeaveOnclick,
  isShowLoader,
  AvatarUrl,
  isShowEnrollButton,
  verticalView,
}) => {
  const classes = useStyles();
  const isEnable = useSelector(cohereAcademyIsEnableSelector);
  const fullname = author.split(' ');

  const contributionViewCardStyle = isShowEnrollButton && classes.contributionView;
  const membershipCardStyle = verticalView && classes.academyMembershipVerticalMedia;

  const styledContainerViews = membershipCardStyle ?? contributionViewCardStyle;

  return (
    <StyledContainer className={styledContainerViews}>
      <Link to={`/cohealer-about/${userId}/about`}>
        <AvatarComponent className="mx-2" alt={`${fullname[0]} ${fullname[1]}`} src={AvatarUrl}>
          {`${fullname[0] && getInitialSymbol(fullname[0])}${fullname[1] && getInitialSymbol(fullname[1])}`}
        </AvatarComponent>
      </Link>

      <Grid container className={classes.cardBox}>
        <Grid item xs={12} className={!isShowEnrollButton ? classes.topLevel : classes.topLevelDashboard}>
          <PreviewImage src={img} />
        </Grid>
        <Grid item xs={12} className={!isShowEnrollButton ? classes.midLevel : classes.midLevelDashboard}>
          <StyledTitle>{title}</StyledTitle>
          <StyledAuthor>{author}</StyledAuthor>
          {!isShowEnrollButton && <StyledTheme>{theme}</StyledTheme>}
          {!isShowEnrollButton && (
            <StyledRating>
              <Grid container spacing={1}>
                <Grid container item xs={12} justify="flex-end">
                  <button type="button">Featured</button>
                </Grid>
              </Grid>
            </StyledRating>
          )}
        </Grid>
        {!isShowEnrollButton && (
          <Grid container item xs={12} className={classes.bottomLevel} justify="center" alignItems="center">
            {isEnable && !isShowLoader?.status && <DisabledEnrollButton>Enroll</DisabledEnrollButton>}
            {!isEnable && !isShowLoader?.status && !isActive && (
              <EnrollButton onClick={handleEnrollOnclick}>Enroll</EnrollButton>
            )}

            {isActive && !isShowLoader?.status && !isEnable && (
              <EnrollButton onClick={handleLeaveOnclick}>Leave</EnrollButton>
            )}
            {isShowLoader?.status && (
              <div>
                <ExpiresLabel>
                  Please wait a minute while we verify your account is eligable to join the Academy.
                </ExpiresLabel>
                <SmallLoader size={20} border={2} />
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </StyledContainer>
  );
};

export default AdvertismentCard;
