import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal';
import { connect } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { colors } from 'utils/styles';

import { useRouter, useHttp } from 'hooks';
import useContribution from 'pages/ContributionView/hooks/useContribution';

import Button from 'components/FormUI/Button';

import { List, Item } from './SidebarList';
import { ROUTES, TOOLTIP } from '../../constants.js';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin: 0 2rem 45px;
`;

const SetAsCompletedLink = styled.a`
  align-self: center;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      color: #ccc;
    `}
`;

function CreateContributionMenu({ archivingAllowed, saveHandler = () => {} }) {
  const { query, history } = useRouter();
  const { id } = useContribution();
  const [asCompletedModal, setAsCompletedModal] = useState(false);
  const { request } = useHttp();
  const location = useLocation();

  const onSubmitHandler = id => {
    request('/Contribution/SetAsCompleted', 'POST', {
      contributionId: id,
    })
      .then(() => {
        setAsCompletedModal(false);
      })
      .then(() => history.push('/'))
      .catch(console.dir);
  };

  if (query.id) {
    return (
      <>
        <Container>
          <List>
            <Item
              id="basic-info"
              title="1. Basic Information"
              to={`/edit-contribution/${query.id}/basic`}
              onClick={event =>
                saveHandler(event, {
                  to: `/edit-contribution/${query.id}/basic`,
                })
              }
            />
            <Item
              title="2. Contribution Details"
              to={`/edit-contribution/${query.id}/details`}
              onClick={event =>
                saveHandler(event, {
                  to: `/edit-contribution/${query.id}/details`,
                })
              }
            />
            <Item
              title="3. Sessions"
              to={`/edit-contribution/${query.id}/sessions`}
              onClick={event =>
                saveHandler(event, {
                  to: `/edit-contribution/${query.id}/sessions`,
                })
              }
            />
            <Item
              title="4. Pricing"
              to={`/edit-contribution/${query.id}/payment`}
              onClick={event =>
                saveHandler(event, {
                  to: `/edit-contribution/${query.id}/payment`,
                })
              }
            />
            <Item
              title="5. Review and Submit"
              to={`/edit-contribution/${query.id}/review`}
              onClick={event =>
                saveHandler(event, {
                  to: `/edit-contribution/${query.id}/review`,
                })
              }
            />
          </List>
          <ButtonContainer>
            <Link
              to={`/contribution-view/${query.id}/about`}
              onClick={event =>
                saveHandler(event, {
                  to: `/contribution-view/${query.id}/about`,
                })
              }
            >
              <Button autoWidth>Save and Exit</Button>
            </Link>
          </ButtonContainer>
          <ButtonContainer>
            <SetAsCompletedLink
              disabled={!archivingAllowed}
              href=""
              onClick={event => {
                archivingAllowed && setAsCompletedModal(true);
                event.preventDefault();
                return false;
              }}
            >
              Archive Contribution
            </SetAsCompletedLink>
            {!archivingAllowed && (
              <Tooltip
                title="You are allowed to archive if you have no outstanding sessions with clients or have no unused package sessions with clients . Once archived, this will appear in the “Archived” section under “My Contributions” on your dashboard. If you want to re-launch this service, you will need to create a new Contribution."
                arrow
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <InfoIcon htmlColor={colors.lightBrown} />
              </Tooltip>
            )}
          </ButtonContainer>
        </Container>
        <Modal
          isOpen={asCompletedModal}
          title="Archive this Contribution"
          submitTitle="Yes I’m Sure. Archive my Contribution."
          onSubmit={() => {
            onSubmitHandler(query.id);
          }}
          onCancel={() => {
            setAsCompletedModal(false);
          }}
        >
          <p>
            Alert: Once you archive a Contribution, this cannot be undone. You are allowed to archive if you have no
            outstanding sessions with clients or have no unused package sessions with clients . Once archived, this will
            appear in the “Archived” section under “My Contributions” on your dashboard. If you want to re-launch this
            service, you will need to create a new Contribution.
          </p>
        </Modal>
      </>
    );
  }

  return (
    <Container>
      <List>
        <Item
          title="1. Basic Information"
          id="create-contribution-basic-info-link"
          to={ROUTES.CREATE_CONTRIBUTION_BASIC}
          onClick={event => saveHandler(event, { to: ROUTES.CREATE_CONTRIBUTION_BASIC })}
        />
        <Item
          title="2. Contribution Details"
          id="create-contribution-details-link"
          to={location.pathname.includes('/basic') && !id ? null : ROUTES.CREATE_CONTRIBUTION_DETAILS}
          onClick={event => saveHandler(event, { to: ROUTES.CREATE_CONTRIBUTION_DETAILS })}
        />
        <Item
          title="3. Sessions"
          id="contribution-create-sessions-link"
          to={location.pathname.includes('/basic') && !id ? null : ROUTES.CREATE_CONTRIBUTION_SESSIONS}
          onClick={event => saveHandler(event, { to: ROUTES.CREATE_CONTRIBUTION_SESSIONS })}
        />
        <Item
          title="4. Pricing"
          id="create-contribution-pricing-link"
          to={location.pathname.includes('/basic') && !id ? null : ROUTES.CREATE_CONTRIBUTION_PAYMENT}
          onClick={event => saveHandler(event, { to: ROUTES.CREATE_CONTRIBUTION_PAYMENT })}
        />
        <Item
          title="5. Review and Submit"
          id="create-contribution-review-and-submit-link"
          to={location.pathname.includes('/basic') && !id ? null : ROUTES.CREATE_CONTRIBUTION_REVIEW}
          onClick={event => saveHandler(event, { to: ROUTES.CREATE_CONTRIBUTION_SAVE })}
        />
      </List>
      <ButtonContainer>
        <Link to="/dashboard" onClick={event => saveHandler(event, { to: '/dashboard' })}>
          <Button autoWidth>Save and Exit</Button>
        </Link>
      </ButtonContainer>
    </Container>
  );
}

CreateContributionMenu.propTypes = {
  saveHandler: PropTypes.func,
};

CreateContributionMenu.defaultProps = {
  saveHandler: undefined,
};

const mapStateToProps = ({ contributions }) => ({
  archivingAllowed: contributions?.activeContribution?.archivingAllowed,
});

export default connect(mapStateToProps)(CreateContributionMenu);
