import React, { useRef, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';

import { Grid } from '@material-ui/core';

import usePaidTier from 'hooks/usePaidTier';
import useAccount from 'hooks/useAccount';

import {
  createPaidTierOptionsSession,
  upgradePaidTierOptions,
  hidePaidTierOptionBanner,
} from 'services/paidTierOptions.service';

import { setActivePaidTierPlan } from 'actions/paidTierOptions';

import { PAIDTIER_STATUSES } from 'constants.js';
import OnePreviewLogo from 'assets/one_plan_view_academy.png';

import AdvantagesItem from 'components/PlanCard/AdvantagesItem';
import UpgradeButton from 'components/UI/UpgradeButton';
import {
  PaidTierExtended,
  PlanColumn,
  PriceField,
  PlanCardContainer,
  FeeField,
  useStyles,
  AcademyColumn,
  CohereAcademyTitle,
  CohereAcademyP,
  ExpiresLabel,
  AdvantagesList,
} from '../Billing.styles';

import Dictionary from '../dictionary.json';

const monthCount = 12;

const advantages = [
  'Annual plan includes Cohere Academy Live Programs',
  'Sell & Deliver Coaching',
  'Create Live Group Programs, Memberships and 1:1 Coaching Services',
  'Professional Client Membership Portal',
  'Receive Payments',
  'Build Recurring Revenue',
  'Easy 1:1 and Group Scheduling',
  'Integrated Live Video',
  'Host Self-Paced Pre-Recorded Content',
  'Automated Reminders & Calendar Invites',
  'Session Recordings',
  'Community Features',
  'Integrated Chat',
  'Sales Dashboard & Bookkeeping',
];

const academyAdvantages = [
  'Full access to monthly LIVE business coaching sessions.',
  'Learn how to start & scale your coaching business.',
  'Create and sell high-ticket offerings.',
  'Build community with other coaches & service providers.',
  'Join live workshops with guest speakers.',
  'Get coached from other leading coaches such as Scott Oldford & more on how to start and scale your business.',
];

const PaidTierBigView = ({ modalsApi }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const containerRef = useRef(null);

  const stripe = useStripe();
  const { user } = useAccount();

  const {
    paidTierPlans,
    isLaunchPlan,
    isImpactPlan,
    activePaidTierPlanEndDateTime,
    isAnnualPlan,
    activePaidTierPlanStatus,
    activePaidTierCurrentPaymentPeriod,
    getActivePaidTier,
  } = usePaidTier();

  const { title, description } = Dictionary.cohereAcademy;

  const impactPlan = paidTierPlans.find(el => el.displayName === 'Impact');
  const paymentPeriodLabel = activePaidTierCurrentPaymentPeriod === 'Annually' ? 'annual' : 'monthly';

  const currentButtonClass = isImpactPlan
    ? `${classes.selectPlanButton} ${classes.enable}`
    : `${classes.selectPlanButton} ${classes.disable}`;

  const buttonLabel = isImpactPlan ? 'Cancel' : 'Upgrade and Join';
  const formatDate = new Date(activePaidTierPlanEndDateTime).toLocaleDateString('en-US');
  const isCanceled = activePaidTierPlanStatus === PAIDTIER_STATUSES.CANCEL;
  const nextOption = isCanceled ? 'cancels' : 'renews';

  const fetchActivePaidTierPlanAndUpdateStore = () => {
    return getActivePaidTier().then(data => {
      dispatch(setActivePaidTierPlan(data));
      return data;
    });
  };

  const handleUpgrade = () => {
    const period = 'Annually';

    if (isLaunchPlan) {
      createPaidTierOptionsSession(impactPlan.id, period, user.accountId).then(({ data }) => {
        stripe.redirectToCheckout({
          sessionId: data,
        });
      });
    } else {
      upgradePaidTierOptions(user.accountId, impactPlan.id, period)
        .then(() => {
          fetchActivePaidTierPlanAndUpdateStore().then(data => {
            modalsApi.upgrade_success.setIsShowSuccessUpgradeModal({
              isShow: true,
              label: `Plan will upgrade to ${data.nextPaidTierOption?.displayName} when your current plan expires.`,
            });
          });
        })
        .catch(err => {
          console.dir(err);
          if (activePaidTierPlanStatus !== PAIDTIER_STATUSES.ACTIVE) {
            modalsApi.upgrade_impossible.setImpossibleToUpgradeModal({
              isShow: true,
              label: `You are currently subscribed to an annual plan. Your plan will renew on (${new Date(
                activePaidTierPlanEndDateTime,
              ).toLocaleDateString(
                'en-US',
              )}). If you wish to upgrade and cancel your annual plan, please email us at support@cohere.live.`,
            });
          }
        });
    }
  };

  const handleCancel = () => {
    modalsApi.warning_before_cancellation.setIsShowBeforeCancellationWarning({
      isShow: true,
      label: `Are you sure you wish to cancel your subscription?`,
      userId: user.accountId,
      itemId: impactPlan?.id,
      activePlanTitle: impactPlan?.displayName,
    });
  };

  const handleOnClick = isImpactPlan ? handleCancel : handleUpgrade;

  useEffect(() => {
    setTimeout(() => {
      containerRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }, 350);
  }, []);

  return (
    <PlanCardContainer ref={containerRef}>
      <PaidTierExtended className={classes.paidTierExtendedMedia}>
        <PlanColumn>
          <div>
            <h4>{impactPlan?.displayName}</h4>
            <PriceField>
              ${impactPlan?.pricePerYear / monthCount} <span>/ month (${impactPlan?.pricePerYear} annual)</span>
            </PriceField>

            <FeeField>{impactPlan?.fee}% Fee</FeeField>
          </div>
          <AdvantagesList>
            {advantages.map(el => {
              return <AdvantagesItem label={el} key={el} />;
            })}
          </AdvantagesList>
        </PlanColumn>
        <div className={classes.academyColumn}>
          <div className={classes.previewAcademyContainerPlan}>
            <img src={OnePreviewLogo} alt="" className={classes.academyImage} />
          </div>
          <div>
            <div>
              <CohereAcademyTitle>{title}</CohereAcademyTitle>
              <CohereAcademyP>{description}</CohereAcademyP>
            </div>
            <AdvantagesList>
              {academyAdvantages.map(el => {
                return <AdvantagesItem label={el} key={el} />;
              })}
            </AdvantagesList>
          </div>
        </div>
      </PaidTierExtended>

      {!isLaunchPlan && (
        <Grid container justify="center">
          <div>
            <ExpiresLabel className={classes.expiresLabelMedia}>
              Your {paymentPeriodLabel} {impactPlan?.displayName} {nextOption} on {formatDate}.
            </ExpiresLabel>
          </div>
        </Grid>
      )}
      {!isCanceled && (
        <Grid container justify="center">
          <UpgradeButton buttonLabel={buttonLabel} style={currentButtonClass} onclick={handleOnClick} />
        </Grid>
      )}
    </PlanCardContainer>
  );
};

export default PaidTierBigView;
