import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useRouteMatch, Link } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux';

import { toRem } from 'utils/styles';
import TypeOfCourse from 'pages/ContributionView/components/TypeOfCourse';
import PurchaseModalsAndButtons from 'pages/ContributionView/components/PurchaseModalsAndButtons/PurchaseModalsAndButtons';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { ContributionStatus, UserRoles } from 'helpers/constants';
import Button from 'components/FormUI/Button';
import Can from 'components/Auth/Can';

import CohereIcon from 'assets/icon.png';

const WrapperButtons = styled.div`
  display: flex;
  margin-top: 16px;
`;
const HeaderIcon = styled.img`
  margin-right: 20px;
  vertical-align: middle;
  object-fit: contain;
  width: 45px;
  height: auto;
`;

const StyledWrapper = styled.div`
  padding: 16px;
`;

const PageTitle = styled.h1`
  font-family: 'Nexa';
  color: rgba(0, 0, 0, 0.87);
  font-size: ${toRem(20)};
  font-weight: 500;
  margin: 0;
`;

const isPathForContribution = path => path === '/contribution-view/:id';
const isPathForCohealerAbout = path => path === '/cohealer-about/:cohealerId';

function HeaderMobile({ title, contribution, isCustomPrevButton, onSendMessage, roleName }) {
  const {
    path,
    params: { id },
  } = useRouteMatch();
  const theme = useTheme();
  const { status } = useContribution();
  const xsMobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isFinalized = status === ContributionStatus.rejected;

  return (
    <StyledWrapper>
      {isCustomPrevButton ? (
        <div className="d-flex align-items-center">
          <HeaderIcon src={CohereIcon} />
          <span className="h3 font-weight-light">{title}</span>
        </div>
      ) : (
        <PageTitle mobileView={xsMobileView}>
          {Boolean(Boolean(isPathForContribution(path) && contribution?.type) || isPathForCohealerAbout(path)) && (
            <>
              <HeaderIcon src={CohereIcon} />
            </>
          )}{' '}
          {title}
        </PageTitle>
      )}
      <WrapperButtons>
        {isPathForContribution(path) && <TypeOfCourse handleUrlModal contribution={contribution} />}
        {isPathForContribution(path) && <PurchaseModalsAndButtons />}
        {!isFinalized && !onSendMessage && (
          <Can
            roleName={UserRoles.cohealer}
            yes={() => (
              <Link to={`/edit-contribution/${id}/basic`}>
                <Button mobileView={mobileView} autoWidth>
                  Edit Contribution
                </Button>
              </Link>
            )}
          />
        )}
        {onSendMessage && (
          <Can
            roleName={roleName}
            yes={() => (
              <Button mobileView={mobileView} autoWidth onClick={onSendMessage}>
                Send a Message
              </Button>
            )}
          />
        )}
      </WrapperButtons>
    </StyledWrapper>
  );
}

HeaderMobile.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  contribution: PropTypes.shape(),
  isCustomPrevButton: PropTypes.bool,
};

HeaderMobile.defaultProps = {
  contribution: null,
  isCustomPrevButton: false,
};

const mapStateToProps = ({ account, page, contributions }) => ({
  loading: account?.isLoading,
  title: page?.header,
  contribution: contributions.activeContribution,
});

export default connect(mapStateToProps)(HeaderMobile);
