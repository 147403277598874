import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useAccount, useRouter } from 'hooks';
import { useSelector } from 'react-redux';
import { UserRoles } from 'helpers/constants';
import { ContributionClients, ContributionDiscussion } from '../pages';
import ContributionSocialContainer from '../pages/ContributionView/ContributionSocial';

const ContributionCommunityRouter = () => {
  const {
    match: {
      path,
      url,
      params: { id },
    },
  } = useRouter();
  const { currentRole } = useAccount();
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const isContributionPurchased = activeContribution.isPurchased;
  const canAccess = currentRole === UserRoles.cohealer || (currentRole === UserRoles.client && isContributionPurchased);
  if (!canAccess) {
    return <Redirect to={`/contribution-view/${id}/about`} />;
  }
  return (
    <Switch>
      <Route exact path={`${path}/social`} component={ContributionSocialContainer} />
      <Route exact path={`${path}/conversations`} component={ContributionDiscussion} />
      <Route exact path={`${path}/clients`} component={ContributionClients} />
      <Redirect to={`${url}/social`} />
    </Switch>
  );
};

export default ContributionCommunityRouter;
