import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { isNil } from 'lodash';
import * as Yup from 'yup';
import styled from 'styled-components';
import { mdiAccountOutline, mdiHeart, mdiRun, mdiTabletIpad } from '@mdi/js';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import ExamplePDF from 'assets/cohere_template_example.pdf';

import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import MainContainer from 'components/Containers/MainContainer';
import * as contributionActions from 'actions/contributions';
import { ContributionType, ContributionStatus } from 'helpers/constants';
import Select from 'components/FormUI/Select';
import Input from 'components/FormUI/Input';
import TextArea from 'components/FormUI/TextArea';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { useRouter } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { PageTitleSecond, BodyText } from 'components/UI/Text/TextStyles';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import Buttons from './components/Buttons';
import SamplePfd from './sample.pdf';
import { colors } from '../../utils/styles/styles';
import { TOOLTIP } from '../../constants';

const ExampleLink = styled.a`
  margin-left: auto;
  padding-left: 10px;
  white-space: nowrap;

  ${({ mobileView }) => mobileView && `margin-top: 10px; white-space: normal;`}
`;

const useStyles = makeStyles({
  checkboxLabel: {
    fontSize: 13.7,
    fontFamily: 'Avenir',
  },

  detailsHeader: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
  },

  header: ({ mobileView }) =>
    mobileView
      ? {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingBottom: '10px',
        }
      : {},
});

const SocialGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const DetailsGrid = styled(Grid)`
  position: relative;
`;

const TextAreaWrapper = styled(Grid)`
  ${({ isCollapsed }) =>
    !isCollapsed &&
    `
      position: absolute;
      z-index: 2;
      background: white;
      box-shadow: 0 2px 8px 1px ${colors.shadow};
    `}
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: -4px;
  top: calc(50% - 5px);
  transform: translateY(-50%);
`;
const StyledTooltipWrap = styled.span`
  position: relative;
  padding-right: 7px;
  display: inline-block;
`;

function ContributionDetails({ user, contribution, saveContribution, saveContributionToLS }) {
  const TEXT_AREA_SIZE = {
    SMALL: 6,
    HUGE: 12,
  };

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ mobileView });
  const { query, history } = useRouter();
  const contributionFormRef = useRef();
  const isEditing = Boolean(query.id);
  const { onSave: onSaveContribution } = useSaveContribution(isEditing);
  const [redirectTo, setRedirectTo] = useState(undefined);

  const [whoIAmCollapsed, setWhoIAmCollapsed] = useState(true);
  const handleResizeWhoIAm = useCallback(() => setWhoIAmCollapsed(prevState => !prevState), []);
  const [purposeCollapsed, setPurposeCollapsed] = useState(true);
  const handleResizePurpose = useCallback(() => setPurposeCollapsed(prevState => !prevState), []);
  const [whatWeDoCollapsed, setWhatWeDoCollapsed] = useState(true);
  const handleResizeWhatWeDo = useCallback(() => setWhatWeDoCollapsed(prevState => !prevState), []);
  const [preparationCollapsed, setPreparationCollapsed] = useState(true);
  const handlePreparationResize = useCallback(() => setPreparationCollapsed(prevState => !prevState), []);

  const saveHandler = isEditing ? saveContribution : saveContributionToLS;

  const saveToStorageHandler = useCallback(
    (values, formikHelpers, to = '') => {
      const data = {
        ...values,
        languageCodes: values.languageCodes,
        minAge: String(values.minAge),
      };

      const newContribution = {
        ...contribution,
        ...data,
      };
      saveHandler(newContribution);
      onSaveContribution(newContribution);
      history.push(to || redirectTo);
    },
    [contribution, history, saveHandler, onSaveContribution, redirectTo],
  );
  const isInvitationDisabled = !!query.id && contribution?.status !== ContributionStatus.unfinished;

  const handleFormKeyDown = e => {
    if (e.target.tagName !== 'TEXTAREA' && (e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  if (!contribution) {
    return null;
  }

  return (
    <Formik
      innerRef={contributionFormRef}
      enableReinitialize
      initialValues={{
        whoIAmLabel: contribution?.whoIAmLabel || 'Bio',
        whoIAm: contribution?.whoIAm || '',
        whatWeDoLabel: contribution?.whatWeDoLabel || 'What to Expect',
        whatWeDo: contribution?.whatWeDo || '',
        purposeLabel: contribution?.purposeLabel || 'The Purpose',
        purpose: contribution?.purpose || '',
        preparationLabel: contribution?.preparationLabel || 'How to Prepare',
        preparation: contribution?.preparation || '',
        languageCodes: contribution?.languageCodes || ['En'],
        minAge: contribution?.minAge?.toString() || '18+',
        gender: contribution?.gender || 'NoRequirement',
        isSpeakingLanguageRequired: isNil(contribution?.isSpeakingLanguageRequired)
          ? false
          : contribution?.isSpeakingLanguageRequired,
        invitationOnly: isNil(contribution?.invitationOnly) ? true : contribution?.invitationOnly,
        arePublicPostsAllowed: isNil(contribution?.arePublicPostsAllowed) ? true : contribution?.arePublicPostsAllowed,
        areClientPublicPostsAllowed: isNil(contribution?.areClientPublicPostsAllowed)
          ? true
          : contribution?.areClientPublicPostsAllowed,
        instagramUrl: contribution?.instagramUrl || '',
        linkedInUrl: contribution?.linkedInUrl || '',
        youtubeUrl: contribution?.youtubeUrl || '',
        facebookUrl: contribution?.facebookUrl || '',
        websiteUrl: contribution?.websiteUrl || '',
      }}
      validationSchema={Yup.object().shape({
        whoIAm: Yup.string()
          .strict(true)
          .min(0)
          .max(500, 'Cannot exceed 500 characters')
          .required('This is a required field'),
        whoIAmLabel: Yup.string()
          .strict(true)
          .min(2, 'Must be at least 2 characters')
          .max(50, 'Cannot exceed 500 characters')
          .required('This is a required field'),
        whatWeDo: Yup.string()
          .strict(true)
          .min(0)
          .max(500, 'Cannot exceed 500 characters')
          .required('This is a required field'),
        whatWeDoLabel: Yup.string()
          .strict(true)
          .min(2, 'Must be at least 2 characters')
          .max(50, 'Cannot exceed 500 characters')
          .required('This is a required field'),
        purpose: Yup.string()
          .strict(true)
          .min(0)
          .max(500, 'Cannot exceed 500 characters')
          .required('This is a required field'),
        purposeLabel: Yup.string()
          .strict(true)
          .min(2, 'Must be at least 2 characters')
          .max(50, 'Cannot exceed 500 characters')
          .required('This is a required field'),
        preparation: Yup.string()
          .strict(true)
          .min(0)
          .max(500, 'Cannot exceed 500 characters')
          .required('This is a required field'),
        preparationLabel: Yup.string()
          .strict(true)
          .min(2, 'Must be at least 2 characters')
          .max(50, 'Cannot exceed 500 characters')
          .required('This is a required field'),
        languageCodes: Yup.string().required('This is a required field'),
        minAge: Yup.string().required('This is a required field'),
        gender: Yup.mixed().oneOf(['NoRequirement', 'Male', 'Female', 'NonBinary']),
        instagramUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('instagram.com', 'This link is not related to Instagram'),
        linkedInUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('linkedin.com', 'This link is not related to LinkedIn'),
        youtubeUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('youtube.com|youtu.be', 'This link is not related to Youtube'),
        facebookUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('facebook.com', 'This link is not related to Facebook'),
        websiteUrl: Yup.string().max(100, 'Cannot exceed 100 characters'),
      })}
      onSubmit={saveToStorageHandler}
    >
      {formProps => {
        const saveData = (event, to, withValidation = false) => {
          if (isEditing || withValidation) {
            formProps.handleSubmit(event);
          } else {
            saveToStorageHandler(formProps.values, {}, to);
          }
        };

        return (
          <MainContainer
            sidebarProps={{
              saveHandler: (event, { to }) => {
                setRedirectTo(to);
                saveData(event, to);
              },
            }}
          >
            <StyledMainSection mobileView={mobileView}>
              <Card mobileView={mobileView}>
                <CardHeader className={classes.header} mobileView={mobileView}>
                  <PageTitleSecond className={classes.detailsHeader} mobileView={mobileView}>
                    2. Details
                  </PageTitleSecond>
                  <BodyText mobileView={mobileView}>
                    Use this section to inform your client on the high-level purpose and details of your Contribution.
                    {contribution.type === ContributionType.contributionOneToOne &&
                      ' If you plan to offer this as a package of sessions, feel free to include relevant information.'}
                  </BodyText>
                  {!isEditing && (
                    <ExampleLink href={ExamplePDF} mobileView={mobileView} target="_blank">
                      PDF Template
                    </ExampleLink>
                  )}
                </CardHeader>
                <CardBody mobileView={mobileView}>
                  <Form id="create-contribution-form" onKeyDown={handleFormKeyDown}>
                    <DetailsGrid container justify="center" spacing={4}>
                      <TextAreaWrapper
                        container
                        item
                        isCollapsed={whoIAmCollapsed}
                        md={whoIAmCollapsed ? TEXT_AREA_SIZE.SMALL : TEXT_AREA_SIZE.HUGE}
                        xs={12}
                      >
                        <TextArea
                          image={mdiAccountOutline}
                          label={formProps.values.whoIAmLabel}
                          isLabelEditable
                          labelName="whoIAmLabel"
                          placeholder="This section is used to share a short bio. This is an opportunity to include your professional title, background, experience and your mission."
                          name="whoIAm"
                          rows="5"
                          fullWidth
                          counter={500}
                          helperTextPosition="right"
                          onClickResize={handleResizeWhoIAm}
                          isCollapsed={whoIAmCollapsed}
                          id="contribution-details-bio-textarea"
                        />
                      </TextAreaWrapper>
                      <TextAreaWrapper
                        container
                        item
                        isCollapsed={purposeCollapsed}
                        md={purposeCollapsed ? TEXT_AREA_SIZE.SMALL : TEXT_AREA_SIZE.HUGE}
                        xs={12}
                      >
                        <TextArea
                          image={mdiHeart}
                          label={formProps.values.purposeLabel}
                          isLabelEditable
                          labelName="purposeLabel"
                          placeholder="This section is used to describe your client's ideal outcome and the goal for working together. Feel free to describe the mission of this specific program."
                          name="purpose"
                          rows="5"
                          fullWidth
                          counter={500}
                          helperTextPosition="right"
                          onClickResize={handleResizePurpose}
                          isCollapsed={purposeCollapsed}
                          id="contribution-details-purpose-textarea"
                        />
                      </TextAreaWrapper>
                      <TextAreaWrapper
                        container
                        item
                        isCollapsed={whatWeDoCollapsed}
                        md={whatWeDoCollapsed ? TEXT_AREA_SIZE.SMALL : TEXT_AREA_SIZE.HUGE}
                        xs={12}
                      >
                        <TextArea
                          image={mdiRun}
                          label={formProps.values.whatWeDoLabel}
                          isLabelEditable
                          labelName="whatWeDoLabel"
                          placeholder="This section is used to summerize the duration(s) of your sessions, subjects covered as well as any relevant information that will help clients understand what to expect and how they will reach their ideal outcome by working with you."
                          name="whatWeDo"
                          rows="5"
                          fullWidth
                          counter={500}
                          helperTextPosition="right"
                          onClickResize={handleResizeWhatWeDo}
                          isCollapsed={whatWeDoCollapsed}
                          id="contribution-details-expect-textarea"
                        />
                      </TextAreaWrapper>
                      <TextAreaWrapper
                        container
                        item
                        isCollapsed={preparationCollapsed}
                        md={preparationCollapsed ? TEXT_AREA_SIZE.SMALL : TEXT_AREA_SIZE.HUGE}
                        xs={12}
                      >
                        <TextArea
                          image={mdiTabletIpad}
                          label={formProps.values.preparationLabel}
                          isLabelEditable
                          labelName="preparationLabel"
                          placeholder="This section is used to share with your client how they can show up prepared, present and ready for sessions. Consider mentioning whether you will be providing worksheets or materials for them to review or complete prior to each session. "
                          name="preparation"
                          rows="5"
                          fullWidth
                          counter={500}
                          helperTextPosition="right"
                          onClickResize={handlePreparationResize}
                          isCollapsed={preparationCollapsed}
                          id="contribution-details-prepare-textarea"
                        />
                      </TextAreaWrapper>
                    </DetailsGrid>

                    <Grid container spacing={4}>
                      <Grid item md={3} sm={6} xs={12}>
                        <Select
                          label="Language(s)"
                          name="languageCodes"
                          fullWidth
                          multiple
                          items={[
                            { title: 'English', value: 'En' },
                            { title: 'Arabic', value: 'Ar' },
                            { title: 'Chinese', value: 'Zh' },
                            { title: 'French', value: 'Fr' },
                            { title: 'German', value: 'De' },
                            { title: 'Hindi', value: 'Hi' },
                            { title: 'Indonesian', value: 'Id' },
                            { title: 'Italian', value: 'It' },
                            { title: 'Japanese', value: 'Ja' },
                            { title: 'Korean', value: 'Ko' },
                            { title: 'Portuguese', value: 'Pt' },
                            { title: 'Russian', value: 'Ru' },
                            { title: 'Spanish', value: 'Es' },
                            { title: 'Swedish', value: 'Sv' },
                            { title: 'Vietnamese', value: 'Vi' },
                          ]}
                        />
                        <FormControlLabel
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                          checked={formProps.values.isSpeakingLanguageRequired}
                          onChange={formProps.handleChange('isSpeakingLanguageRequired')}
                          control={<Checkbox color="primary" />}
                          label="Speaking this language is required"
                          name="isSpeakingLanguageRequired"
                        />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <Input label="Age Requirement" type="text" name="minAge" fullWidth />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <Select
                          label="Gender Requirement"
                          name="gender"
                          fullWidth
                          items={[
                            { title: 'No requirement', value: 'NoRequirement' },
                            { title: 'Male', value: 'Male' },
                            { title: 'Female', value: 'Female' },
                            { title: 'Non-binary', value: 'NonBinary' },
                          ]}
                        />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <Input label="Website (optional)" type="text" name="websiteUrl" fullWidth />
                      </Grid>
                    </Grid>

                    <SocialGrid container spacing={4}>
                      <Grid item md={3} sm={6} xs={12}>
                        <Input label="Instagram (optional)" type="text" name="instagramUrl" fullWidth />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <Input label="LinkedIn (optional)" type="text" name="linkedInUrl" fullWidth />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <Input label="Youtube (optional)" type="text" name="youtubeUrl" fullWidth />
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <Input label="Facebook (optional)" type="text" name="facebookUrl" fullWidth />
                      </Grid>
                    </SocialGrid>
                    <Grid container spacing={4}>
                      <Grid container item md={3} sm={6} xs={12}>
                        <StyledTooltipWrap>
                          <FormControlLabel
                            checked={formProps.values.invitationOnly}
                            onChange={formProps.handleChange('invitationOnly')}
                            control={<Checkbox color="primary" />}
                            label="Invitation Only"
                            name="invitationOnly"
                            disabled={isInvitationDisabled}
                          />
                          <StyledTooltip
                            title="If checked, you receive payouts faster (in 2-3 business days). If unchecked, when we have our marketplace open, your contributions will not appear on the search engine of our platform however your customers will always be able to purchase via an invite link or email."
                            arrow
                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          >
                            <InfoIcon htmlColor={colors.lightBrown} />
                          </StyledTooltip>
                        </StyledTooltipWrap>
                      </Grid>
                      <Grid container item md={3} sm={6} xs={12}>
                        <StyledTooltipWrap>
                          <FormControlLabel
                            checked={formProps.values.arePublicPostsAllowed}
                            onChange={formProps.handleChange('arePublicPostsAllowed')}
                            control={<Checkbox color="primary" />}
                            label="Enable Client Posts"
                            name="arePublicPostsAllowed"
                          />
                          <StyledTooltip
                            title="By checking this box, you agree to enable clients posts including images, videos and/or thoughts on the 'community' tab of this contribution, which are viewable by other clients who also purchase this service."
                            arrow
                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          >
                            <InfoIcon htmlColor={colors.lightBrown} />
                          </StyledTooltip>
                        </StyledTooltipWrap>
                      </Grid>
                    </Grid>
                  </Form>
                </CardBody>
              </Card>

              <Buttons
                backButtonTitle="Back"
                backClickHandler={event => {
                  const to = query.id ? `/edit-contribution/${query.id}/basic` : '/create-contribution/basic';
                  setRedirectTo(to);
                  saveData(event, to);
                }}
                onNextClickHandler={event => {
                  const to = query.id ? `/edit-contribution/${query.id}/sessions` : '/create-contribution/sessions';
                  setRedirectTo(to);
                  saveData(event, to, true);
                }}
                nextButtonTitle="Save and Next"
                formId="create-contribution-form"
              />
            </StyledMainSection>
          </MainContainer>
        );
      }}
    </Formik>
  );
}

ContributionDetails.propTypes = {
  contribution: PropTypes.shape(),
  user: PropTypes.shape({
    bio: PropTypes.string,
  }).isRequired,
  saveContribution: PropTypes.func.isRequired,
  saveContributionToLS: PropTypes.func.isRequired,
};

ContributionDetails.defaultProps = {
  contribution: null,
};

const mapStateToProps = ({ account, contributions }) => ({
  contribution: contributions.activeContribution,
  user: account?.user,
});

const actions = {
  saveContribution: contributionActions.saveContribution,
  saveContributionToLS: contributionActions.saveDraftContribution,
};

export default connect(mapStateToProps, actions)(ContributionDetails);
