import moment from 'moment';
import * as R from 'ramda';
import htmlParser from 'html-react-parser';

export const isNotNil = R.complement(R.isNil);
export const isNotEmpty = R.complement(R.isEmpty);
export const isEmptyOrNil = R.anyPass([R.isNil, R.isEmpty]);

/**
 * Converts passed value to `String` type (if needed)
 */
export const toString = R.cond([
  [R.isNil, R.always('')],
  [R.is(String), R.identity],
  [R.T, R.toString],
]);

export const downloadFile = (text, documentName, contentType) => {
  const blob = new Blob([text], {
    type: contentType,
  });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = documentName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const parseTimeFrom12hTo24h = time => moment(time, ['h:mm A']).format('HH:mm');

export const wrapLink = link =>
  link.indexOf('http://') === 0 || link.indexOf('https://') === 0 ? link : `https://${link}`;

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const replaceBreakToBr = text => (text ? text.replace(/\n(.*?)/g, '<br />') : '');

export const wrapText = text => htmlParser(text);
