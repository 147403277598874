import useShallowEqualSelector from './useShallowEqualSelector';

const useAccount = () => {
  const user = useShallowEqualSelector(state => state?.account?.user || {});
  const currentRole = useShallowEqualSelector(state => state?.account?.currentRole);
  const roles = useShallowEqualSelector(state => state?.account?.roles);

  return { user, currentRole, roles };
};

export default useAccount;
