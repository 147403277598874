import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Form } from 'formik';

import EnrollmentDates from './components/EnrollmentDates';
import Sessions from './components/Sessions';
import LiveVideo from './components/LiveVideo';

const MembershipForm = ({ values, errors, touched, setFieldValue }) => {
  return (
    <Form>
      <Grid container spacing={4}>
        <EnrollmentDates enrollment={values.enrollment} />

        <Sessions
          values={values}
          enrollment={values.enrollment}
          providerName={values.liveVideoServiceProvider.providerName}
          sessions={values.sessions}
          errors={errors}
          touched={touched}
        />

        <LiveVideo liveVideoServiceProvider={values.liveVideoServiceProvider} setFieldValue={setFieldValue} />
      </Grid>
    </Form>
  );
};

MembershipForm.propTypes = {
  values: PropTypes.shape({
    sessions: PropTypes.arrayOf(),
    enrollment: PropTypes.shape({
      toDate: PropTypes.string,
      fromDate: PropTypes.string,
      anyTime: PropTypes.bool,
    }).isRequired,
    liveVideoServiceProvider: PropTypes.shape(),
  }).isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
};

export default MembershipForm;
