import { useCallback, useEffect, useMemo, useReducer } from 'react';

import { updateMembers, clearMembers, addMember, updateMember, removeMember } from './actions';
import membersReducer, { initialMembers } from './reducer';
import useAccount from '../../useAccount';

const useChatClientChannelMembers = channel => {
  const { user } = useAccount();

  const [members, dispatchMemberAction] = useReducer(membersReducer, initialMembers);

  const membersArray = useMemo(() => Object.values(members), [members]);
  const myMember = useMemo(() => membersArray.find(m => m.identity === user.email), [membersArray, user.email]);
  const typingMembersArray = useMemo(() => membersArray.filter(m => m.isTyping), [membersArray]);

  const getMemberBySid = useCallback(memberSid => members[memberSid], [members]);

  useEffect(() => {
    (async function getChannelMembersAsync() {
      if (!channel) {
        dispatchMemberAction(clearMembers());

        return;
      }

      try {
        const channelMembers = await channel.getMembers();

        dispatchMemberAction(updateMembers(channelMembers));
      } catch {
        dispatchMemberAction(updateMembers([]));
      }
    })();
  }, [channel]);
  useEffect(() => {
    if (channel) {
      const onMemberJoined = m => dispatchMemberAction(addMember(m));
      const onMemberUpdated = ({ member }) => dispatchMemberAction(updateMember(member));
      const onMemberLeft = m => dispatchMemberAction(removeMember(m.sid));

      channel.on('memberJoined', onMemberJoined);
      channel.on('memberUpdated', onMemberUpdated);
      channel.on('memberLeft', onMemberLeft);

      const onTypingStarted = m => dispatchMemberAction(updateMember(m));
      const onTypingEnded = m => dispatchMemberAction(updateMember(m));

      channel.on('typingStarted', onTypingStarted);
      channel.on('typingEnded', onTypingEnded);

      return () => {
        channel.off('memberJoined', onMemberJoined);
        channel.off('memberUpdated', onMemberUpdated);
        channel.off('memberLeft', onMemberLeft);

        channel.off('typingStarted', onTypingStarted);
        channel.off('typingEnded', onTypingEnded);
      };
    }
  }, [channel]);

  return {
    members: membersArray,
    myMember,
    typingMembers: typingMembersArray,
    getMemberBySid,
  };
};

export default useChatClientChannelMembers;
