import React from 'react'
import PropTypes from 'prop-types'
import MuiTextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

import { colors } from 'utils/styles'

const useStyles = makeStyles(() => ({
  labelRoot: {
    fontFamily: 'Avenir',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    fontSize: `${14 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
    transform: `translate(0, ${33 / 16}rem) scale(1)`,
  },
  labelShrink: {
    transform: 'translate(0, 4px)',
  },
  labelFocused: {
    color: 'inherit !important',
  },
  labelFormControl: {
    transform: `translate(0, ${32 / 16}rem) scale(1)`,
  },
  labelError: {
    color: 'inherit !important',
  },
  inputRoot: {
    fontFamily: 'Avenir',
    lineHeight: `${24 / 16}rem`,
    letterSpacing: '0.15px',
    fontSize: `${18 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
  },
  inputFormControl: {
    'label + &': {
      marginTop: 26,
    },
  },
  inputUnderline: {
    '&:after': {
      borderBottom: `2px solid #6486a2`,
    },
    '&:before': {
      borderColor: '#e7e7e7',
    },
  },
  inputError: {
    '&:after': {
      borderBottomColor: `${colors.fadedPurple} !important`,
    },
  },
  helperTextRoot: {
    textAlign: 'right',
    fontFamily: 'Avenir',
    lineHeight: `${24 / 16}rem`,
    letterSpacing: '0.08px',
    fontSize: `${12 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'normal',
  },
  helperTextError: {
    color: `${colors.fadedPurple} !important`,
    textAlign: 'left',
    fontSize: '1rem',
    letterSpacing: '0.1px',
  },
}))

function TextField(props) {
  const { endAdornment, startAdornment, helperText, inputComponent, mask, ...rest } = props
  const classes = useStyles()

  return (
    <MuiTextField
      InputLabelProps={{
        classes: {
          root: classes.labelRoot,
          focused: classes.labelFocused,
          shrink: classes.labelShrink,
          error: classes.labelError,
        },
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          formControl: classes.inputFormControl,
          underline: classes.inputUnderline,
          error: classes.inputError,
        },
        endAdornment,
        startAdornment,
        inputComponent,
        inputProps: {
          mask,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperTextRoot,
          error: classes.helperTextError,
        },
      }}
      helperText={helperText}
      {...rest}
    />
  )
}

TextField.propTypes = {
  fullWidth: PropTypes.bool,
  endAdornment: PropTypes.node,
  startAdornment: PropTypes.node,
  label: PropTypes.string,
  helperText: PropTypes.string,
  inputComponent: PropTypes.elementType,
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

TextField.defaultProps = {
  fullWidth: false,
  endAdornment: null,
  startAdornment: null,
  label: null,
  helperText: null,
  inputComponent: 'input',
  mask: null,
}

export default TextField
