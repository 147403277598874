import React, { useState } from 'react';

import usePaidTier from 'hooks/usePaidTier';
import useRouter from 'hooks/useRouter';

import { ROUTES } from 'constants.js';

import DefaultBillingView from './DefaultBillingView';
import OnePlanBillingView from './OnePlanBillingView';

const ConditionalRenderBillingPage = () => {
  const { isScalePlan } = usePaidTier();
  const { pathname } = useRouter();

  const isDefaultBillingView = pathname.includes(ROUTES.ACCOUNT_BILLING_EXTENDED);

  const [isShowBeforeCancellationWarning, setIsShowBeforeCancellationWarning] = useState({
    isShow: false,
    label: '',
    isConfirm: false,
    userId: null,
    itemId: null,
  });
  const [isShowSuccessCancellationModal, setIsShowSuccessCancellationModal] = useState({
    isShow: false,
    label: '',
  });
  const [impossibleToUpgradeModal, setImpossibleToUpgradeModal] = useState({
    isShow: false,
    label: '',
  });
  const [isShowSuccessUpgradeModal, setIsShowSuccessUpgradeModal] = useState({
    isShow: false,
    label: '',
  });

  const MODALS_API = {
    warning_before_cancellation: {
      isShowBeforeCancellationWarning,
      setIsShowBeforeCancellationWarning,
    },
    cancelletion_success: {
      isShowSuccessCancellationModal,
      setIsShowSuccessCancellationModal,
    },
    upgrade_impossible: {
      impossibleToUpgradeModal,
      setImpossibleToUpgradeModal,
    },
    upgrade_success: {
      isShowSuccessUpgradeModal,
      setIsShowSuccessUpgradeModal,
    },
  };

  if (isScalePlan || isDefaultBillingView) {
    return <DefaultBillingView modalsApi={MODALS_API} />;
  }

  return <OnePlanBillingView modalsApi={MODALS_API} />;
};

export default ConditionalRenderBillingPage;
