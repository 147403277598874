import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import * as contributionActions from 'actions/contributions';
import Button from 'components/FormUI/Button';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { useRouter } from 'hooks';
import { ContributionType } from 'helpers/constants';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { useTheme, useMediaQuery } from '@material-ui/core';
import MainContainer from 'components/Containers/MainContainer';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import { useSaveContribution } from 'hooks/useSaveContribution';

import OneOnOne from './components/OneOnOne';
import LiveCourses from './components/LiveCourses';
import Membership from './components/Membership/index';
import { PAYMENT_OPTIONS, TOOLTIP } from 'constants.js';
import InfoIcon from '@material-ui/icons/Info.js';
import { colors } from '../../../utils/styles';
import Tooltip from '@material-ui/core/Tooltip';

const StyledButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

function Payment({ contribution, saveContribution, saveContributionToLS }) {
  const PAYMENT_FORM_MAPPING = {
    [ContributionType.contributionOneToOne]: OneOnOne,
    [ContributionType.contributionCourse]: LiveCourses,
    [ContributionType.contributionMembership]: Membership,
  };

  const { query, history } = useRouter();
  const isEditing = Boolean(query.id);
  const { onSave: onSaveContribution } = useSaveContribution(isEditing);
  const [redirectTo, setRedirectTo] = useState(undefined);

  const saveHandler = isEditing ? saveContribution : saveContributionToLS;

  const saveToStorageHandler = useCallback(
    (
      {
        packageDiscount,
        packageSessionDiscountPercentage,
        packageSessionNumbers,
        monthlySessionSubscriptionInfo,
        membershipPackage,
        ...values
      },
      formikHelpers,
      to,
    ) => {
      const data = {
        paymentInfo: {
          ...values,
          paymentOptions: values.paymentOptions || [],
          packageSessionDiscountPercentage,
          packageSessionNumbers,
          monthlySessionSubscriptionInfo,
        },
      };

      if (contribution.type === ContributionType.contributionMembership) {
        data.paymentInfo.membershipInfo = {
          costs: {},
          membershipPackage,
        };
        if (values.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY)) {
          data.paymentInfo.membershipInfo.costs[PAYMENT_OPTIONS.PER_DAY] = values.perDayCost;
        }
        if (values.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK)) {
          data.paymentInfo.membershipInfo.costs[PAYMENT_OPTIONS.PER_WEEK] = values.perWeekCost;
        }
        if (values.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH)) {
          data.paymentInfo.membershipInfo.costs[PAYMENT_OPTIONS.PER_MONTH] = values.perMonthCost;
        }
        if (values.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR)) {
          data.paymentInfo.membershipInfo.costs[PAYMENT_OPTIONS.PER_YEAR] = values.perYearCost;
        }
      }

      const newContribution = {
        ...contribution,
        ...data,
      };
      saveHandler(newContribution);
      onSaveContribution(newContribution);

      history.push(to || redirectTo);
    },
    [contribution, history, saveHandler, redirectTo, onSaveContribution],
  );

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const Course = PAYMENT_FORM_MAPPING[contribution?.type];

  return (
    <Course contribution={contribution} saveToStorageHandler={saveToStorageHandler}>
      {(formProps, renderCardBody) => {
        const saveData = (event, to, withValidation = false) => {
          if (isEditing || withValidation) {
            formProps.handleSubmit(event);
          } else {
            saveToStorageHandler(formProps.values, formProps, to);
          }
        };
        return (
          <MainContainer
            sidebarProps={{
              saveHandler: (event, { to }) => {
                setRedirectTo(to);
                saveData(event, to);
              },
            }}
          >
            <StyledMainSection mobileView={mobileView}>
              <Card mobileView={mobileView}>
                <CardHeader mobileView={mobileView}>
                  <PageTitleSecond mobileView={mobileView}>4. Pricing</PageTitleSecond>
                </CardHeader>
                <CardBody mobileView={mobileView}>{renderCardBody}</CardBody>
              </Card>

              <StyledButtonContainer>
                <Button
                  invert
                  autoWidth
                  type="button"
                  onClick={event => {
                    saveToStorageHandler(formProps.values);
                    const to = query.id ? `/edit-contribution/${query.id}/sessions` : '/create-contribution/sessions';
                    setRedirectTo(to);
                    saveData(event, to);
                  }}
                >
                  Back
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  autoWidth
                  type="submit"
                  form="create-contribution-form"
                  onClick={event => {
                    const to = query.id ? `/edit-contribution/${query.id}/review` : '/create-contribution/review';
                    setRedirectTo(to);
                    saveData(event, to, true);
                  }}
                >
                  Save and Next
                </Button>
              </StyledButtonContainer>
            </StyledMainSection>
          </MainContainer>
        );
      }}
    </Course>
  );
}

Payment.propTypes = {
  contribution: PropTypes.shape(),
  saveContribution: PropTypes.func.isRequired,
  saveContributionToLS: PropTypes.func.isRequired,
};

Payment.defaultProps = {
  contribution: null,
};

const mapStateToProps = ({ contributions }) => ({
  contribution: contributions.activeContribution,
});

const actions = {
  saveContribution: contributionActions.saveContribution,
  saveContributionToLS: contributionActions.saveDraftContribution,
};

export default connect(mapStateToProps, actions)(Payment);
