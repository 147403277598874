import React from 'react';
import styled from 'styled-components';
import { Link, Switch, Route, NavLink } from 'react-router-dom';

import { colors, devices } from 'utils/styles';

import Can from 'components/Auth/Can';
import Logo from 'components/FormUI/Logo';
import Button from 'components/FormUI/Button';

import { UserRoles } from 'helpers/constants';

import SidebarDropDown from './SidebarDropDown';
import CreateContributionMenu from './CreateContributionMenu';
import { ROUTES } from '../../constants';
import { useAccount } from '../../hooks';

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 270px;
  min-height: 100vh;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3), 2px 0 4px 0 rgba(157, 157, 157, 0.3);
  z-index: 10;

  /* @media screen and (${devices.laptop}) {
    display: block;
  } */
`;

const SidebarHeader = styled.div`
  margin: 45px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SidebarTerms = styled.div`
  margin: 60px 0 10px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 13px;
  align-items: center;
  cursor: pointer;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledHeaderTitle = styled.div`
  margin: 0 auto;
  font-family: Avenir;
  font-size: 22.3px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
`;

const StyledAffiliateLink = styled(NavLink)`
  color: ${colors.gray};
  font-weight: initial;
`;

const StyledTermsLink = styled.a`
  color: ${colors.gray};
  font-weight: initial;
`;

function Sidebar(props) {
  // const [termsModal, setTermsModal] = useState(false);
  const { currentRole } = useAccount();

  return (
    <SidebarContainer>
      <div>
        <SidebarHeader>
          <Link to="/dashboard">
            <Logo />
          </Link>

          <Can
            roleName={UserRoles.cohealer}
            yes={() => (
              <Link to="/create-contribution/basic">
                <Button autoWidth>Create a Contribution</Button>
              </Link>
            )}
          />

          <Can roleName={UserRoles.admin} yes={() => <StyledHeaderTitle>Admin</StyledHeaderTitle>} />
        </SidebarHeader>
      </div>
      <Switch>
        <Route path="/create-contribution">
          <CreateContributionMenu {...props} />
        </Route>
        <Route path="/edit-contribution/:id">
          <CreateContributionMenu {...props} />
        </Route>
        <Route component={SidebarDropDown}>
          <SidebarDropDown {...props} />
        </Route>
      </Switch>
      <SidebarTerms
      // onClick={() => {
      //   setTermsModal(true);
      // }}
      >
        {currentRole === UserRoles.cohealer && (
          <StyledAffiliateLink to={ROUTES.ACCOUNT_AFFILIATE}>Affiliate Program</StyledAffiliateLink>
        )}
        <StyledTermsLink rel="noopener noreferer" href="https://www.cohere.live/privacy-policy" target="_blank">
          Cohere Terms and Privacy
        </StyledTermsLink>
      </SidebarTerms>
      {/* <Modal
        title="Terms and Privacy"
        isOpen={termsModal}
        hiddenCancel
        submitTitle="Exit"
        onSubmit={() => {
          setTermsModal(false);
        }}
        onCancel={() => {
          setTermsModal(false);
        }}
      >
        <FlexDiv>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/CoherePrivacyPolicy-1.pdf"
          >
            Cohere Privacy Policy
          </a>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/TermsofUse.pdf"
          >
            Terms of Use
          </a>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/UserAgreement.pdf"
          >
            User Agreement
          </a>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/CohereFeeAuthorizationAgreement.pdf"
          >
            Cohere Fee Authorization Agreement
          </a>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/CohereOne-to-OneandGroupCoursePaymentAgreement.pdf"
          >
            Cohere One-to-One and Group Course Payment Agreement
          </a>
        </FlexDiv>
      </Modal> */}
    </SidebarContainer>
  );
}

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
