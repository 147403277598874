import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
// import { Translate } from "react-localize-redux";
import { connect } from 'react-redux';

import Input from 'components/FormUI/Input';
import Button from 'components/FormUI/Button';
import Logo from 'components/FormUI/Logo';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { Card } from 'components/UI/Card';
import { getLoginValidationSchema } from 'utils/validation';
import * as userActions from 'actions/user';
import { devices } from 'utils/styles';
import { EN_MESSAGES } from 'constants.js';

const CONTAINER_WIDTH = 520;

const Container = styled.div`
  max-width: ${CONTAINER_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  box-sizing: border-box;

  @media screen and (${devices.mobile}) {
    padding: 40px 100px;
  }
`;

const WelcomeMessage = styled.h1`
  font-size: 1.675rem;
  text-align: center;
  font-weight: 500;
  line-height: normal;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
  margin-top: 0.75;
`;

const WelcomeSubMessage = styled.h4`
  font-size: 1.125rem;
  text-align: center;
  font-weight: 500;
  line-height: 1.35;
  margin-top: 1.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  width: 100%;
  text-align: center;

  & > * + * {
    margin-top: 2.5rem;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.17;
  letter-spacing: 0.4px;
  font-size: 0.875rem;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
  ${({ bold }) =>
    bold &&
    css`
      font-size: 1rem;
      font-weight: 500;
    `}
`;

const useStyles = makeStyles(() => ({
  input: {
    '& + &': {
      marginTop: '2rem',
    },
  },
}));

function SignInExtendedPage({ login, loading, error }) {
  const classes = useStyles();

  const submitHandler = useCallback(
    ({ email, password }) => {
      login(email, password);
    },
    [login],
  );

  return (
    <>
      <Card>
        <Container>
          <Logo />
          <WelcomeMessage>
            {/* <Translate id="auth.login" /> */}
            {EN_MESSAGES.auth.login}
          </WelcomeMessage>
          <WelcomeSubMessage>Welcome back to Cohere</WelcomeSubMessage>

          <Formik
            initialValues={{ email: '', password: '' }}
            // validationSchema={getLoginValidationSchema(Translate)}
            validationSchema={getLoginValidationSchema()}
            onSubmit={submitHandler}
          >
            <Form>
              <Input className={classes.input} label="Email" type="email" name="email" fullWidth />
              <Input className={classes.input} label="Password" type="password" name="password" fullWidth />
              <ButtonsContainer>
                <Button type="submit" disabled={loading}>
                  Login
                </Button>
                {error?.message && <CommonErrorMessage message={error.message} />}
                <StyledLink to="/auth/restore-pass">I forgot my password</StyledLink>
              </ButtonsContainer>
            </Form>
          </Formik>
        </Container>
      </Card>
      <br />
      <StyledLink bold to="/auth/signup/type">
        Create an account
      </StyledLink>
    </>
  );
}

SignInExtendedPage.propTypes = {
  login: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

SignInExtendedPage.defaultProps = {
  error: null,
};

const mapStateToProps = ({ account }) => ({
  user: account.user || {},
  loading: account.isLoading,
  error: account.error,
});

const actions = {
  login: userActions.login,
};

export default connect(mapStateToProps, actions)(SignInExtendedPage);
