import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TIMEZONES } from 'constants.js';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import orderBy from 'lodash/orderBy';
import { toRem } from 'utils/styles';

import { useAccount } from 'hooks';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import SessionTime from './SessionTime';
import { BottomPanel } from './BottomPanel/BottomPanel';

import './Session.scss';
import { UserRoles } from 'helpers/constants';

const StyledTimeZoneLabel = styled.p`
  display: inline-block;
  font-size: ${toRem(13)};
  background: #116582;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  vertical-align: middle;
  margin-bottom: 3px;
  font-weight: bold;
`;

const Session = ({
  number,
  session,
  session: {
    id: sessionId,
    title,
    isCompleted,
    sessionTimes,
    maxParticipantsNumber,
    attachments,
    isPrerecorded,
    dateAvailable,
  },
  contributionId,
  contributionTimeZoneId,
  isPurchased,
  renderSessionTimeActions,
}) => {
  const [expanded, setExpanded] = useState(true);

  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  return (
    <ExpansionPanel
      className="course-session"
      expanded={expanded}
      onChange={(_, newExpanded) => setExpanded(newExpanded)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div className="course-session-summary">
          <h3 className="course-session-summary__header">
            Session&nbsp;
            <span className="course-session-summary__number-badge">{number}</span>
          </h3>
          <p className="course-session-summary__title">
            {title}
            {!expanded && ` (${sessionTimes.length})`}
          </p>
          <p className="course-session-summary__status">{isCompleted ? 'Completed' : 'Upcoming'}</p>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className="course-session-details">
          <h4 className="course-session-details__header">
            Session Times
            {!isPrerecorded && (
              <NavLink to="/account/profile">
                <StyledTimeZoneLabel>{TIMEZONES[contributionTimeZoneId]}</StyledTimeZoneLabel>
              </NavLink>
            )}
          </h4>
          <div>
            <div className="course-session-details-session-times">
              {orderBy(sessionTimes, ['startTime'], ['asc']).map((st, i) => (
                <SessionTime
                  key={st.id}
                  isPrerecorded={isPrerecorded}
                  dateAvailable={dateAvailable}
                  className="course-session-details-session-times__item"
                  number={i + 1}
                  maxParticipantsCount={maxParticipantsNumber}
                  sessionTime={st}
                  isCoach={isCoach}
                  renderActions={sessionTime => renderSessionTimeActions(session, sessionTime)}
                />
              ))}
            </div>
            {(isPurchased || isCoach) && (
              <BottomPanel
                title={title}
                contributionId={contributionId}
                sessionId={sessionId}
                attachments={attachments}
                isPrerecorded={isPrerecorded}
              />
            )}
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

Session.propTypes = {
  number: PropTypes.number.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    maxParticipantsNumber: PropTypes.number,
    isCompleted: PropTypes.bool,
    sessionTimes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  contributionId: PropTypes.string.isRequired,
  isPurchased: PropTypes.bool.isRequired,
  renderSessionTimeActions: PropTypes.func.isRequired,
};

export default Session;
