import React from 'react';
import PropTypes from 'prop-types';

import { ContributionType } from 'helpers/constants';
import Button from 'components/FormUI/Button';
import { useHttp, useVideoChatActions } from 'hooks';

const LaunchSessionTime = ({
  user,
  contribution: { id, type, chat, liveVideoServiceProvider },
  session,
  sessionTime,
}) => {
  const { request, loading } = useHttp();
  const { startVideoChat } = useVideoChatActions();

  const chatId =
    type === ContributionType.contributionOneToOne && chat?.cohealerPeerChatSids
      ? chat?.cohealerPeerChatSids[sessionTime.participantId]
      : chat?.sid;

  const onLaunchSession = ({}) => {
    request('/Video/CreateRoomAndGetToken', 'POST', {
      contributionId: id,
      classId: sessionTime.id,
      identityName: `${user.firstName} ${user.lastName}`,
    }).then(({ room: { sid: roomId }, token }) =>
      startVideoChat({
        contributionId: id,
        type,
        sessionId: session?.id,
        classId: sessionTime.id,
        title: session?.title || sessionTime?.title,
        roomId,
        chatId,
        token,
        deleteRoomOnVideoEnd: true,
      }),
    );
  };

  return (
    <>
      <Button autoWidth onClick={onLaunchSession} disabled={loading || sessionTime.isCompleted}>
        Launch Session
      </Button>
    </>
  );
};

LaunchSessionTime.defaultProps = {
  session: {},
};

LaunchSessionTime.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    chat: PropTypes.shape({
      sid: PropTypes.string,
    }),
  }).isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
  }),
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
    isCompleted: PropTypes.bool,
  }).isRequired,
};

export default LaunchSessionTime;
