import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';

import { getAllPostsForContributionAsync, deletePost } from 'services/community.service';

export const ALL_POSTS_CONTRIBUTION_REQUEST = 'ALL_POSTS_CONTRIBUTION_REQUEST';
export const ALL_POSTS_CONTRIBUTION_SUCCESS = 'ALL_POSTS_CONTRIBUTION_SUCCESS';
export const ALL_POSTS_CONTRIBUTION_ERROR = 'ALL_POSTS_CONTRIBUTION_ERROR';
export const BY_PAGE_POSTS_CONTRIBUTION_REQUEST = 'BY_PAGE_POSTS_CONTRIBUTION_REQUEST';
export const BY_PAGE_POSTS_CONTRIBUTION_SUCCESS = 'BY_PAGE_POSTS_CONTRIBUTION_SUCCESS';
export const BY_PAGE_POSTS_CONTRIBUTION_ERROR = 'BY_PAGE_POSTS_CONTRIBUTION_ERROR';

export const fetchAllPostsForContributionActions = {
  request: createAction(ALL_POSTS_CONTRIBUTION_REQUEST),
  success: createAction(ALL_POSTS_CONTRIBUTION_SUCCESS),
  error: createAction(ALL_POSTS_CONTRIBUTION_ERROR),
};
export const fetchByPagePostsForContributionActions = {
  request: createAction(BY_PAGE_POSTS_CONTRIBUTION_REQUEST),
  success: createAction(BY_PAGE_POSTS_CONTRIBUTION_SUCCESS),
  error: createAction(BY_PAGE_POSTS_CONTRIBUTION_ERROR),
};

export const getAllPostsForContribution = data => async dispatch => {
  dispatch(fetchAllPostsForContributionActions.request());
  try {
    const response = await getAllPostsForContributionAsync(data);
    dispatch(fetchAllPostsForContributionActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchAllPostsForContributionActions.error, dispatch)(error);
  }
};
export const getByPagePostsForContribution = data => async dispatch => {
  dispatch(fetchByPagePostsForContributionActions.request());
  try {
    const response = await getAllPostsForContributionAsync(data);
    dispatch(fetchByPagePostsForContributionActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchByPagePostsForContributionActions.error, dispatch)(error);
  }
};
export const deletePostById = id => async dispatch => {
  // dispatch(fetchAllPostsForContributionActions.request())
  try {
    const response = await deletePost(id);
    // dispatch(fetchAllPostsForContributionActions.success(response))
  } catch (error) {
    // pipe(get('response'), get('data'), fetchAllPostsForContributionActions.error, dispatch)(error)
  }
};
