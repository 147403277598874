import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid';

import { useShallowEqualSelector, useEndVideoChat, useAccount } from 'hooks';
import * as contributionActions from 'actions/contributions';

import Banner from 'components/Banner/Banner';

import { UserRoles } from 'helpers/constants';

import AboutMainInfoBlock from './components/AboutMainInfoBlock/AboutMainInfoBlock';
import AboutGeneralInfoBlock from './components/AboutGeneralInfoBlock';
import AboutDetailsBlock from './components/AboutDetailsBlock';
import EnrollmentBlock from './components/EnrollmentBlock';
import AboutPaymentBlock from './components/AboutPaymentBlock';

function AboutContribution({ contribution, getClientContribution, getCohealerContribution, currentRole }) {
  const {
    id,
    previewContentUrls,
    categories,
    serviceProviderName,
    gender,
    minAge,
    languageCodes,
    whoIAm,
    whatWeDo,
    purpose,
    preparation,
    earnedRevenue,
    paymentInfo,
    enrollment,
    closestClassForBanner,
    contributionPartners,
    instagramUrl,
    linkedInUrl,
    youtubeUrl,
    facebookUrl,
    websiteUrl,
    whoIAmLabel,
    whatWeDoLabel,
    purposeLabel,
    preparationLabel,
  } = contribution;
  const urls = {
    instagramUrl,
    linkedInUrl,
    youtubeUrl,
    facebookUrl,
    websiteUrl,
  };

  const { userId } = useShallowEqualSelector(state => state?.contributions?.activeContribution || '');
  const { user } = useAccount();

  const refreshContribution = useCallback(() => {
    if (currentRole === UserRoles.client) {
      getClientContribution(id);
    } else if (currentRole === UserRoles.cohealer || currentRole === UserRoles.admin) {
      getCohealerContribution(id);
    }
  }, [id, currentRole, getClientContribution, getCohealerContribution]);

  const { Popup } = useEndVideoChat(refreshContribution);
  const isEnrollmentVisible = enrollment && !enrollment.anyTime;

  return (
    !isEmpty(contribution) && (
      <>
        <Banner type="closestSession" closestClassForBanner={closestClassForBanner} />
        <Grid container spacing={4} style={{ width: '100%', margin: '0' }}>
          <Grid item xs={12}>
            <AboutMainInfoBlock
              id={id}
              user={user}
              userId={userId}
              previewContentUrls={previewContentUrls}
              serviceProviderName={serviceProviderName}
              contributionPartners={contributionPartners}
              socialUrls={urls}
            />
          </Grid>
          <Grid item xs={12}>
            <AboutGeneralInfoBlock
              contribution={contribution}
              categories={categories}
              gender={gender}
              minAge={minAge}
              languageCodes={languageCodes}
            />
          </Grid>
          <Grid item xs={12}>
            <AboutDetailsBlock
              whoIAm={whoIAm}
              whatWeDo={whatWeDo}
              purpose={purpose}
              preparation={preparation}
              whoIAmLabel={whoIAmLabel}
              whatWeDoLabel={whatWeDoLabel}
              purposeLabel={purposeLabel}
              preparationLabel={preparationLabel}
            />
          </Grid>
          {isEnrollmentVisible && (
            <Grid item xs={12}>
              <EnrollmentBlock enrollment={enrollment} />
            </Grid>
          )}
          <Grid item xs={12}>
            <AboutPaymentBlock contribution={contribution} earnedRevenue={earnedRevenue} paymentInfo={paymentInfo} />
          </Grid>
        </Grid>
        {currentRole === UserRoles.cohealer && <Popup />}
      </>
    )
  );
}

const mapStateToProps = ({ contributions: { activeContribution, loading }, account }) => ({
  contribution: activeContribution || {},
  loading,
  currentRole: account?.currentRole,
});

const actions = {
  getClientContribution: contributionActions.fetchClientContribution,
  getCohealerContribution: contributionActions.fetchCohealerContribution,
};

AboutContribution.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
  contribution: PropTypes.objectOf(PropTypes.any),
  getClientContribution: PropTypes.func.isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
  currentRole: PropTypes.string.isRequired,
};

AboutContribution.defaultProps = {
  contribution: {},
};

export default connect(mapStateToProps, actions)(AboutContribution);
