import { combineReducers } from 'redux';

import { LOG_OUT } from 'actions/user';
import { removeItem } from 'services/localStorage.service';
import userReducer from './user.reducer';
import alertsReducer from './alerts.reducer';
import contributionsReducer from './contributions.reducer';
import upcomingCreatedWithType from './upcomingCreatedWithType';
import pageReducer from './page.reducer';
import chatReducer from './chat.reducer';
import videoChatReducer from './videoChat.reducer';
import cohealerInfo from './cohealerInfo';
import dashboardSessions from './dashboardSessions';
import dashboardIncome from './dashboardIncome';
import upcomingCreated from './upcomingCreated';
import archived from './archived';
import userNotes from './userNotes';
import payout from './payout';
import calendars from './calendars';
import bankAccounts from './bankAccounts';
import cohealerIncome from './cohealerIncome';
import testVideoChart from './testVideoChart';
import oneToOneData from './oneToOneData.reducer';
import audioOutputDevices from './audioOutputDevices.reducer';
import paidTierOptions from './paidTier.reducer';
import communityPosts from './community';

const mainReducer = combineReducers({
  account: userReducer,
  alerts: alertsReducer,
  contributions: contributionsReducer,
  paidTierOptions,
  upcomingCreatedWithType,
  page: pageReducer,
  chat: chatReducer,
  videoChat: videoChatReducer,
  cohealerInfo,
  dashboardSessions,
  dashboardIncome,
  upcomingCreated,
  archived,
  userNotes,
  payout,
  bankAccounts,
  cohealerIncome,
  communityPosts,
  testVideoChart,
  oneToOneData,
  calendars,
  audioOutputDevices,
});

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    removeItem('persist:root');
  }

  return mainReducer(state, action);
};

export default rootReducer;
