import React, { useCallback, useLayoutEffect } from 'react'

import VideoChat from 'components/UI/VideoChat'
import { useAccount, useHttp, useVideoChat, useVideoChatActions } from 'hooks'
import * as videoService from 'services/video.service'
import { RESPONSE_CODES } from '../../constants'

const VideoChatContainer = () => {
  const { contributionId, roomId, chatId, token, deleteRoomOnVideoEnd, creatorId } = useVideoChat()
  const { endVideoChat } = useVideoChatActions()

  const { request } = useHttp()
  const { user } = useAccount()

  useLayoutEffect(() => {
    if (token) {
      document.body.classList.add('no-scroll')

      return () => {
        document.body.classList.remove('no-scroll')
      }
    }
  }, [token])

  const onVideoChatEnd = useCallback(
    (room, error) => {
      if (deleteRoomOnVideoEnd) {
        if (error?.code !== RESPONSE_CODES.TWILIO.IDENTITY_DUPLICATION) {
          videoService
            .deleteVideochatRoom({
              contributionId,
              roomId,
            })
            .catch((err) => console.error(err))
        }
      }
      endVideoChat()
    },
    [endVideoChat, deleteRoomOnVideoEnd, user.id, request, contributionId, roomId, creatorId]
  )

  if (!token) {
    return null
  }

  return <VideoChat token={token} chatId={chatId} onEnd={onVideoChatEnd} />
}

export default VideoChatContainer
