import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme, useMediaQuery } from '@material-ui/core';

import Modal from 'components/UI/Modal';
import copyContributionLinkToClipboard from 'utils/copyContributionLinkToClipboard';
import { createAccessCode } from 'services/contributions.service';
import ShareModalView from './Content';

const ShareModalContainer = ({
  isOpen,
  onModalClose,
  onSubmit,
  emailsError,
  setEmailsError,
  contributionId,
  id,
  type,
}) => {
  const [emails, setEmails] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [premiumTooltipOpen, setPremiumTooltipOpen] = useState(false);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCopyToClipboard = useCallback(
    event => {
      event.preventDefault();
      copyContributionLinkToClipboard({ contributionId });
      setTooltipOpen(true);
    },
    [contributionId],
  );

  const handlePremiumCopyToClipboard = useCallback(
    event => {
      event.preventDefault();
      createAccessCode(id).then(data => {
        copyContributionLinkToClipboard({ contributionId, code: data.code });
      });
      setPremiumTooltipOpen(true);
    },
    [contributionId],
  );

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();

      onSubmit(emails);
    },
    [onSubmit, emails],
  );

  return (
    <>
      <Modal
        title="Share contribution"
        isOpen={isOpen}
        onCancel={onModalClose}
        onSubmit={handleSubmit}
        submitTitle={mobileView ? 'Send' : 'Send Invitations'}
      >
        <ShareModalView
          emails={emails}
          contributionId={contributionId}
          type={type}
          onSetEmails={setEmails}
          onCopyToClipboard={handleCopyToClipboard}
          onPremiumCopyToClipboard={handlePremiumCopyToClipboard}
          emailsError={emailsError}
          setEmailsError={setEmailsError}
          mobileView={mobileView}
          tooltipOpen={tooltipOpen}
          setTooltipOpen={setTooltipOpen}
          premiumTooltipOpen={premiumTooltipOpen}
          setPremiumTooltipOpen={setPremiumTooltipOpen}
        />
      </Modal>
    </>
  );
};

ShareModalContainer.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  emailsError: PropTypes.shape({
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  setEmailsError: PropTypes.func.isRequired,
  contributionId: PropTypes.string,
};

ShareModalContainer.defaultProps = {
  contributionId: '',
  id: null,
};

export default ShareModalContainer;
