import React, { useState, useCallback, useEffect, useMemo } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';

import { connect } from 'react-redux';
import * as calendarsActions from 'actions/calendars';
import { useHttp } from 'hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { getDomainIcon } from 'utils/calendar';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Loader from 'components/UI/Loader';

import Button from 'components/FormUI/Button';
import styled from 'styled-components';
import AddCalendarModal from 'components/Modals/AddCalendarModal/AddCalendarModal';
import { RemoveCalendarModal } from 'components/Modals/AddCalendarModal/RemoveCalendar';

const BoldTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: ({ mobileView }) => (mobileView ? '16px' : '17.8px'),
    fontWeight: 500,
    letterSpacing: '0.15px',
  },
})(TableCell);

const NarrowTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
  },
})(TableCell);

const StyledButton = styled(Button)`
  margin-right: 0px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const CalendarInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EmptyCalendarWrapper = styled.div`
  padding: 16px;
`;

const Calendars = ({
  calendars,
  addCalendar,
  calendarSignInCallback,
  fetchCalendars,
  removeCalendar,
  getAcсountsForConflictsCheck,
  getAcсountForSendReminders,
}) => {
  const query = new URLSearchParams(useLocation().search);
  const code = useMemo(() => query.get('code'), [query]);
  const contributionId = useMemo(() => query.get('state'), [query]);
  const [isAddCalendarModalOpen, setIsAddCalendarModalOpen] = useState(false);
  const [removedCalendarEmail, setRemovedCalendarEmail] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { loading } = useHttp();
  const history = useHistory();

  useEffect(() => {
    if (code) {
      calendarSignInCallback(code);
    }
    if (contributionId === 'create') {
      history.push({
        pathname: '/create-contribution/sessions',
        state: { calendarModalOpen: true },
      });
    } else if (contributionId) {
      history.push({
        pathname: `/edit-contribution/${contributionId}/sessions`,
        state: { calendarModalOpen: true },
      });
    }
  }, []);

  useEffect(() => {
    fetchCalendars();
    getAcсountsForConflictsCheck();
    getAcсountForSendReminders();
  }, [fetchCalendars, getAcсountsForConflictsCheck, getAcсountForSendReminders]);

  const handleModalSubmit = useCallback(async () => {
    setIsAddCalendarModalOpen(false);
  }, []);

  if (loading) {
    return <Loader />;
  }

  const handleOpenAddCalendarModal = () => {
    setIsAddCalendarModalOpen(true);
  };

  const handleCloseAddCalendarModal = () => {
    setIsAddCalendarModalOpen(false);
  };

  const onRemoveCalendarModalOpen = email => {
    setRemovedCalendarEmail(email);
  };

  const onRemovecalendarSubmit = () => {
    removeCalendar(removedCalendarEmail);
    setRemovedCalendarEmail(null);
  };

  const handleCloseRemoveCalendarModal = () => {
    setRemovedCalendarEmail(null);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell mobileView={mobileView}>
                Calendar Connections
                <div>
                  <StyledButton mobileView={mobileView} autoWidth onClick={handleOpenAddCalendarModal}>
                    Add my calendar
                  </StyledButton>
                </div>
              </BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calendars &&
              calendars.map(({ provider, emailAddress, isCheckConflictsEnabled, isEventRemindersEnabled }, i) => {
                const key = `${i}${emailAddress}`;
                const Icon = getDomainIcon(null, provider);
                return (
                  <TableRow key={key} className="row">
                    <NarrowTableCell component="th" scope="row">
                      <CalendarInfoWrapper>
                        <Icon />
                        <span className="ml-2">{emailAddress}</span>
                      </CalendarInfoWrapper>

                      <StyledCloseIcon onClick={() => onRemoveCalendarModalOpen(emailAddress)} />
                    </NarrowTableCell>
                  </TableRow>
                );
              })}
            {!calendars ||
              (!calendars.length && <EmptyCalendarWrapper>There are no calendars yet.</EmptyCalendarWrapper>)}
          </TableBody>
        </Table>
      </TableContainer>
      {isAddCalendarModalOpen && (
        <AddCalendarModal
          isOpen
          onModalClose={handleCloseAddCalendarModal}
          onSubmit={handleModalSubmit}
          addCalendar={addCalendar}
          calendars={calendars}
        />
      )}
      {removedCalendarEmail && (
        <RemoveCalendarModal
          isOpen={removedCalendarEmail}
          onCancel={handleCloseRemoveCalendarModal}
          onSubmit={onRemovecalendarSubmit}
        />
      )}
    </>
  );
};

Calendars.propTypes = {};

const mapStateToProps = ({ calendars }) => ({
  calendars: calendars.calendarsAccounts,
});

const actions = {
  fetchCalendars: calendarsActions.fetchCalendars,
  addCalendar: calendarsActions.addCalendar,
  removeCalendar: calendarsActions.removeCalendar,
  calendarSignInCallback: calendarsActions.calendarSignInCallback,
  getAcсountsForConflictsCheck: calendarsActions.getAcсountsForConflictsCheck,
  getAcсountForSendReminders: calendarsActions.getAcсountForSendReminders,
};

export default connect(mapStateToProps, actions)(Calendars);
