import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/FormUI/Button'
import { useHttp } from 'hooks'

const UnbookSessionTime = ({ contribution, session, sessionTime, onSessionTimeUnbooked, mobileView }) => {
  const { request, loading } = useHttp()

  const onUnbookSession = () => {
    request('/Contribution/RevokeBookSessionTime', 'POST', {
      contributionId: contribution.id,
      sessionId: session.id,
      sessionTimeId: sessionTime.id,
    }).then(() => onSessionTimeUnbooked())
  }

  return (
    <Button autoWidth onClick={onUnbookSession} disabled={loading} mobileView={mobileView}>
      Unbook Session Time
    </Button>
  )
}

UnbookSessionTime.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
  }),
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onSessionTimeUnbooked: PropTypes.func.isRequired,
  mobileView: PropTypes.bool,
}

export default UnbookSessionTime
