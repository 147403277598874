import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import SendIcon from '@material-ui/icons/Send'

import TextField from 'components/UI/TextField'
import UploadAttachment from './UploadAttachment'

import './MessageInput.scss'

const MessageInput = ({ onSendTextMessage, onSendMediaMessage, onTyping }) => {
  const [messageText, setMessageText] = useState('')
  const [isSendingMessage, setIsSendingMessage] = useState(false)
  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false)

  const sendTextMessageAsync = async () => {
    if (!messageText || isSendingMessage) {
      return
    }

    setIsSendingMessage(true)
    const messageSent = await onSendTextMessage(messageText)
    if (messageSent) {
      setMessageText('')
    }
    setIsSendingMessage(false)
  }

  const sendMediaMessageAsync = async (file) => {
    if (isUploadingAttachment) {
      return
    }

    setIsUploadingAttachment(true)
    await onSendMediaMessage(file)
    setIsUploadingAttachment(false)
  }

  return (
    <div className="chat-message-input">
      <TextField
        placeholder="Type a message"
        multiline
        rowsMax={6}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()

            sendTextMessageAsync()
          } else {
            onTyping()
          }
        }}
        value={messageText}
        onChange={(e) => setMessageText(e.target.value)}
      />
      <UploadAttachment
        className="chat-message-input__upload-attachment"
        isUploadingAttachment={isUploadingAttachment}
        onUploadAttachment={sendMediaMessageAsync}
      />
      <IconButton
        className="chat-message-input__send"
        color="inherit"
        onClick={sendTextMessageAsync}
        disabled={!messageText || isSendingMessage}
      >
        <SendIcon />
      </IconButton>
    </div>
  )
}

MessageInput.propTypes = {
  onSendTextMessage: PropTypes.func.isRequired,
  onSendMediaMessage: PropTypes.func.isRequired,
  onTyping: PropTypes.func.isRequired,
}

export default MessageInput
