import { useHttp } from 'hooks';
import { useState } from 'react';

const useVideoPlayer = () => {
  const [link, setLink] = useState(null);
  const { request, error, loading } = useHttp();

  const getVideoLink = async ({ contributionId, roomId }) => {
    setLink(null);

    const videoLink = await request('/Video/GetPresignedUrl', 'POST', {
      contributionId,
      roomId,
    });

    setLink(videoLink);
  };

  return { loading, link, error, getVideoLink };
};

export default useVideoPlayer;
