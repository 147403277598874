import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

import chatService from 'services/chat.service'
import {
  useChatClientChannelAttributes,
  useChatClientChannelUtilities,
  useChatClientChannelMembers,
  useChatClientChannelMessages,
  useRouter,
} from 'hooks'
import DateFilter from '../../DateFilter'
import MessageType from '../../../MessageType'
import checkIfChannelSatisfiesFilters from './checkIfChannelSatisfiesFilters'
import GeneralInfo from './GeneralInfo'
import MoreOptions from './MoreOptions'

import './Chat.scss'
import { TOOLTIP } from '../../../../../../constants.js'

const Chat = ({
  className,
  channel,
  onClick,
  linkProvider,
  showFavoriteOnly,
  searchFilter,
  dateFilter,
  selected,
  onSelectedChange,
}) => {
  const { history } = useRouter()

  const [isFocused, setIsFocused] = useState(false)
  const onFocused = () => setIsFocused(true)
  const onLostFocus = () => setIsFocused(false)

  const { members, myMember, typingMembers } = useChatClientChannelMembers(channel)
  const { messages } = useChatClientChannelMessages(channel)
  const { title, iconUrl } = useChatClientChannelAttributes(channel, members, myMember)
  const { readAllMessages, unreadAllMessages } = useChatClientChannelUtilities(channel)

  const hasLastMessage = messages.length > 0
  const lastMessage = hasLastMessage ? messages[messages.length - 1] : null
  const lastMessageTimestamp = hasLastMessage ? moment(lastMessage.timestamp) : null
  const isLastMessageReadByMe = hasLastMessage
    ? !!myMember && myMember.lastConsumedMessageIndex !== null && lastMessage.index <= myMember.lastConsumedMessageIndex
    : true
  const onToggleReadMessages = () => {
    if (isLastMessageReadByMe) {
      unreadAllMessages()
    } else {
      readAllMessages()
    }
  }

  const typingMemberNames = typingMembers.map((m) => m.attributes.Name)

  const isFavorite = !!myMember && !!myMember.attributes.IsFavorite
  const onToggleIsFavorite = () => chatService.markChatAsFavoriteAsync(channel?.sid, !isFavorite)

  const channelData = {
    title,
    hasLastMessage,
    lastMessage,
    lastMessageTimestamp,
    isFavorite,
  }
  const filters = { searchFilter, dateFilter, showFavoriteOnly }
  if (!checkIfChannelSatisfiesFilters(channelData, filters)) {
    return null
  }

  const isActive = isFocused || selected

  return (
    <div
      className={classNames(className, 'chats-list-chat', { 'chats-list-chat--active': isActive })}
      onPointerOver={onFocused}
      onPointerLeave={onLostFocus}
      onClick={() => {
        if (onClick) {
          onClick(channel?.sid)
        }
        if (linkProvider) {
          history.push(linkProvider(channel?.sid))
        }
      }}
    >
      {hasLastMessage && (
        <Tooltip
          title={isLastMessageReadByMe ? 'Mark as unread' : 'Mark as read'}
          placement="top"
          enterTouchDelay={TOOLTIP.ENTER_DELAY}
          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
        >
          <div
            className={classNames('chats-list-chat__read-indicator', {
              'chats-list-chat__read-indicator--unread': !isLastMessageReadByMe,
            })}
            onClick={(e) => {
              e.stopPropagation()
              onToggleReadMessages()
            }}
          />
        </Tooltip>
      )}
      <GeneralInfo
        className="chats-list-chat__general-info"
        title={title}
        iconUrl={iconUrl}
        isActive={isActive}
        selected={selected}
        onSelectedChange={onSelectedChange}
      />
      <div className="chats-list-chat__content chats-list-chat-content">
        {(hasLastMessage || typingMemberNames.length > 0) && (
          <>
            {typingMemberNames.length > 0 ? (
              <p className="chats-list-chat-content__typing-members">
                {typingMemberNames.join(', ')} {typingMemberNames.length > 1 ? 'are' : 'is'} typing...
              </p>
            ) : (
              <p
                className={classNames('chats-list-chat-content__last-message', {
                  'chats-list-chat-content__last-message--type--media': lastMessage.type === MessageType.media,
                })}
              >
                {lastMessage.type === MessageType.media ? 'Attachment' : lastMessage.body}
              </p>
            )}
          </>
        )}
        <IconButton
          className={classNames('chats-list-chat-content__favorite', {
            'chats-list-chat-content__favorite--favorite': isFavorite,
          })}
          onClick={(e) => {
            e.stopPropagation()
            onToggleIsFavorite()
          }}
          color="inherit"
        >
          {isFavorite ? <StarIcon /> : <StarBorderIcon />}
        </IconButton>
        {hasLastMessage && (
          <p className="chats-list-chat-content__last-message-timestamp">
            {lastMessageTimestamp.format(lastMessageTimestamp.isSame(moment(), 'day') ? 'h:mm A' : 'MMM Do')}
          </p>
        )}
        <MoreOptions
          className="chats-list-chat-content__more-options"
          hasLastMessage={hasLastMessage}
          isLastMessageReadByMe={isLastMessageReadByMe}
          onToggleReadMessages={onToggleReadMessages}
          isFavorite={isFavorite}
          onToggleIsFavorite={onToggleIsFavorite}
          onClosed={onLostFocus}
        />
      </div>
    </div>
  )
}

Chat.propTypes = {
  className: PropTypes.string.isRequired,
  channel: PropTypes.shape({
    sid: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  linkProvider: PropTypes.func,
  showFavoriteOnly: PropTypes.bool.isRequired,
  searchFilter: PropTypes.string.isRequired,
  dateFilter: PropTypes.oneOf([DateFilter.thisWeek, DateFilter.all]).isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectedChange: PropTypes.func.isRequired,
}

Chat.defaultProps = {
  onClick: null,
  linkProvider: null,
}

export default Chat
