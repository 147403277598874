import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Modal from 'components/UI/Modal'
import { useHttp } from 'hooks'

export const ModalTermsAndConditions = ({ showTerms, onCancel }) => {
  const [documents, setDocuments] = useState([])
  const { request } = useHttp()

  useEffect(() => {
    request('/ReferenceData/GetAgreements').then(setDocuments)
  }, [request])

  return (
    <Modal isOpen={showTerms} onCancel={onCancel} title="Terms and Conditions" disableConfirm>
      <div className="d-flex flex-column">
        {documents.map(({ id, fileNameWithExtension, fileUrl }) => (
          <a key={id} href={fileUrl} target="_blank" rel="noopener noreferrer" className="mb-1">
            {fileNameWithExtension}
          </a>
        ))}
      </div>
    </Modal>
  )
}

ModalTermsAndConditions.propTypes = {
  showTerms: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}
