import { IconGoogle } from 'components/Icons/services/IconGoogle';
import { IconOutlook } from 'components/Icons/services/IconOutlook';
import { IconYahoo } from 'components/Icons/services/IconYahoo';

export const getDomainIcon = (email, byDomain) => {
  const domain = byDomain || email.replace(/.*@/, '').split('.')[0];
  let Icon = () => '';
  switch (domain) {
    case 'gmail':
      Icon = IconGoogle;
      break;
    case 'outlook':
      Icon = IconOutlook;
      break;
    case 'yahoo':
      Icon = IconYahoo;
      break;
    default:
      Icon = () => '';
  }
  return Icon;
};
