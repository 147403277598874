import React from 'react';
import PropTypes from 'prop-types';

import { formatMoney } from 'utils/datesAndMoney';
import { getPaymentOptionsView } from 'utils/getPaymentOptionsView';
import { PAYMENT_OPTIONS } from '../../../constants';
import { ContributionType } from 'helpers/constants';

const renderPricePerMonthlySessionSubscription = (cost, summary, monthlySessionSubscriptionInfo) => {
  return (
    <>
      <div className="mt-2">
        Per Month - Includes {monthlySessionSubscriptionInfo.sessionCount} Sessions Monthly,{' '}
        {monthlySessionSubscriptionInfo.duration} Months Total
      </div>
      <div>
        ${formatMoney(monthlySessionSubscriptionInfo.monthlyPrice * monthlySessionSubscriptionInfo.duration)} USD
      </div>
    </>
  );
};

const renderFullPaymentDiscount = (cost, summary) => {
  const costWithDiscount = summary?.price - summary?.platformFee;

  if (cost === costWithDiscount) {
    return null;
  }

  return (
    <>
      <div className="mt-2">Full Payment Discount</div>
      <div>${formatMoney(summary?.price - summary?.platformFee)} USD</div>
    </>
  );
};

const renderMembershipPayments = ({ paymentOptions, membershipInfo }) => (
  <>
    {paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && membershipInfo && (
      <>
        <div>Monthly subscription</div>
        <div>${formatMoney(membershipInfo?.costs?.MonthlyMembership)} USD</div>
      </>
    )}
    {paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && membershipInfo && (
      <>
        <div>Yearly subscription</div>
        <div>${formatMoney(membershipInfo?.costs?.YearlyMembership)} USD</div>
      </>
    )}
    {paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && membershipInfo && (
      <>
        <div>Packages</div>
        <div>
          ${formatMoney(membershipInfo?.membershipPackage?.cost)} USD for
          {membershipInfo?.membershipPackage?.duration} months
        </div>
      </>
    )}
  </>
);

const renderOneToOnePayments = ({
  paymentOptions,
  cost,
  packageSessionNumbers,
  summary,
  monthlySessionSubscriptionInfo,
}) => (
  <>
    {paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) && (
      <>
        <div>Per Session</div>
        <div>${formatMoney(cost)} USD</div>
      </>
    )}
    {paymentOptions.includes(PAYMENT_OPTIONS.SESSIONS_PACKAGE) && (
      <>
        <div className="mt-2">Per {packageSessionNumbers} Session Package</div>
        <div>
          $
          {formatMoney(
            summary[PAYMENT_OPTIONS.SESSIONS_PACKAGE]?.price - summary[PAYMENT_OPTIONS.SESSIONS_PACKAGE]?.platformFee,
          )}{' '}
          USD
        </div>
      </>
    )}
    {paymentOptions.includes(PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION) &&
      renderPricePerMonthlySessionSubscription(
        cost,
        summary[PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION],
        monthlySessionSubscriptionInfo,
      )}
  </>
);

const renderLiveCoursePayments = ({ paymentOptions, cost, summary, splitNumbers, splitPeriod }) => (
  <>
    {paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) && (
      <>
        <div>Price Per Course</div>
        <div>${formatMoney(cost)} USD</div>
        {renderFullPaymentDiscount(cost, summary[PAYMENT_OPTIONS.ENTIRE_COURSE])}
      </>
    )}
    {paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS) && (
      <>
        <div className="mt-2">
          Split: {splitNumbers} Payments, Billed {splitPeriod}
        </div>
        <div>${formatMoney(cost / splitNumbers)} USD</div>
      </>
    )}
  </>
);

export const TotalCostBlock = ({ summary, paymentInfo, type }) => {
  const PAYMENTS_RENDER_MAPPING = {
    [ContributionType.contributionMembership]: renderMembershipPayments,
    [ContributionType.contributionOneToOne]: renderOneToOnePayments,
    [ContributionType.contributionCourse]: renderLiveCoursePayments,
  };

  return PAYMENTS_RENDER_MAPPING[type] && PAYMENTS_RENDER_MAPPING[type]({ ...paymentInfo, summary });
};

export const PaymentOptions = ({ paymentOptions }) => {
  if (!paymentOptions || !paymentOptions.length) {
    return 'Non-Refundable';
  }

  return (
    <>
      {paymentOptions.map(opt => (
        <div key={opt}>{getPaymentOptionsView(opt)}</div>
      ))}
      Non-Refundable
    </>
  );
};

export const BreakdownBlock = () => {
  return <div>None</div>;
};

export const RevenueBlock = ({ earnedRevenue }) => <div>${formatMoney(earnedRevenue)} USD</div>;

TotalCostBlock.propTypes = {
  cost: PropTypes.number.isRequired,
};

PaymentOptions.propTypes = {
  paymentOptions: PropTypes.arrayOf(PropTypes.string),
};

RevenueBlock.defaultProps = {
  earnedRevenue: null,
};

RevenueBlock.propTypes = {
  earnedRevenue: PropTypes.number,
};

PaymentOptions.defaultProps = {
  paymentOptions: [],
};
