const CHANGE_HEADER = 'CHANGE_HEADER';
const TOGGLE_MOBILE_SIDEBAR = 'TOGGLE_MOBILE_SIDEBAR';

export const constants = {
  CHANGE_HEADER,
  TOGGLE_MOBILE_SIDEBAR,
};

export const changeHeader = title => ({
  type: CHANGE_HEADER,
  payload: title,
});

export const toggleMobileSidebar = () => ({
  type: TOGGLE_MOBILE_SIDEBAR,
});
