import React from 'react'
import PropTypes from 'prop-types'

import { MessageMediaType } from '../useMessage'
import ImageMedia from './ImageMedia'
import DocumentMedia from './DocumentMedia'

const MediaComponents = {
  [MessageMediaType.image]: ImageMedia,
  [MessageMediaType.document]: DocumentMedia,
}

const MediaContainer = ({ type, size, fileName, url }) => {
  const MediaComponent = MediaComponents[type]

  return <MediaComponent size={size} fileName={fileName} url={url} />
}

MediaContainer.propTypes = {
  type: PropTypes.oneOf([MessageMediaType.image, MessageMediaType.document]).isRequired,
  size: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  url: PropTypes.string,
}

MediaContainer.defaultProps = {
  url: null,
}

export default MediaContainer
