import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { purchaseAcademyContribution } from 'services/paidTierOptions.service';
import { cancelMembership } from 'services/purchase.service';

import usePaidTier from 'hooks/usePaidTier';

import { PlanColumnGrid, useStyles } from '../Billing.styles';

import AdvertismentCard from 'components/AdvertismentCard';

const CohereAcademy = ({ setIsShowAcademyWarning }) => {
  const { academyContributions, activeAcademyContributions, updateActiveContributionsState } = usePaidTier();
  const classes = useStyles();

  const [isShowLoader, setIsShowLoader] = useState({
    id: null,
    status: false,
  });

  useEffect(() => {
    setIsShowLoader({
      id: null,
      status: false,
    });
  }, [activeAcademyContributions]);

  const enrollMembershipOnclick = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    if (isShowLoader.id) {
      return;
    }

    setIsShowLoader({
      id,
      status: true,
    });

    purchaseAcademyContribution(id)
      .then(() => {
        updateActiveContributionsState();

        setTimeout(() => {
          updateActiveContributionsState().then(() => {
            setIsShowLoader({
              id: null,
              status: false,
            });
          });
        }, 2000);
      })
      .catch(e => {
        setIsShowAcademyWarning(true);
        setIsShowLoader({
          id: null,
          status: false,
        });
      });
  };

  const leaveMembershipOnclick = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    if (isShowLoader.id) {
      return;
    }

    setIsShowLoader({
      id,
      status: true,
    });

    cancelMembership(id).then(() => {
      updateActiveContributionsState().then(() => {
        setIsShowLoader({
          id: null,
          status: false,
        });
      });
    });
  };

  return (
    <PlanColumnGrid>
      {academyContributions.map(item => {
        return (
          <Link to={`/contribution-view/${item.id}/about`} className={classes.cohereAcademyLink} key={item.id}>
            <AdvertismentCard
              key={item.id}
              userId={item.userId}
              img={item.image}
              title={item.title}
              author={item.serviceProviderName}
              theme={item?.categories[0]}
              handleEnrollOnclick={event => enrollMembershipOnclick(event, item.id)}
              isActive={activeAcademyContributions.some(el => el.id === item.id)}
              handleLeaveOnclick={event => leaveMembershipOnclick(event, item.id)}
              isShowLoader={null}
              AvatarUrl={item.avatarUrl}
              verticalView
            />
          </Link>
        );
      })}
    </PlanColumnGrid>
  );
};

export default CohereAcademy;
