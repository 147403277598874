import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'

import getInitials from 'utils/getInitials'

import Participants from './Participants'

import './ChatHeader.scss'

const ChatHeader = ({ compact, title, iconUrl, members }) => (
  <div className="chat-header">
    <div className="chat-header-info">
      <Avatar src={iconUrl}>{getInitials(title)}</Avatar>
      <p className="chat-header-info__title">{title}</p>
    </div>
    {!compact && <Participants members={members} />}
  </div>
)

ChatHeader.propTypes = {
  compact: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default ChatHeader
