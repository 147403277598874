import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Popover } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';

import Logo from 'components/FormUI/Logo';
import Input from 'components/FormUI/Input';
import MaskedInput from 'components/FormUI/MaskedInput';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import Button from 'components/FormUI/Button';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { Card, CardBody } from 'components/UI/Card';
import { getSignUpClientValidationSchema } from 'utils/validation';
import * as userActions from 'actions/user';
import { toRem } from 'utils/styles';
import Select from 'components/FormUI/Select';
import { colors } from '../../utils/styles/styles.js';
import { UserRoles } from 'helpers/constants';
import * as accountPreferences from 'services/accountPreferences.service';

const WelcomeMessage = styled.h1`
  font-size: ${toRem(22.3)};
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 1.5rem;
`;

const AgreementContainer = styled.div`
  text-align: center;
  margin: 1.5rem 0;
  line-height: 1.28;
  letter-spacing: 0.25px;
  font-weight: 500;
  font-size: 0.9375rem;
  width: 100%;

  a {
    color: inherit;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: 2.5rem;
  text-align: center;
  width: 100%;

  & > * + * {
    margin-top: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  line-height: 1.17;
  letter-spacing: 0.4px;
  font-size: 0.875rem;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledTerms = styled.a`
  color: ${colors.darkOceanBlue} !important;
`;

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
    fontSize: 14,
  },
  paper: {
    padding: theme.spacing(1),
    transform: 'translateY(-10px) !important',
    maxWidth: 500,
  },
  container: {
    maxWidth: 520,
  },
  link: {
    marginTop: '1rem',
  },
}));

function SignUpClientSecondScreen({ loading, user, continueRegister, error }) {
  const classes = useStyles();
  const [showTerms, setShowTerms] = useState(false);
  const [anchorEl] = useState(null);
  const registerHandler = useCallback(
    values => {
      const data = {
        ...values,
        accountId: user.accountId,
        BusinessType: 'Coaching',
        BirthDate: moment.utc(values.BirthDate).format(),
      };
      continueRegister({ fields: data, userView: UserRoles.client });
    },
    [continueRegister, user.accountId],
  );

  return (
    <>
      <Card>
        <CardBody>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            classes={{
              container: classes.container,
            }}
          >
            <Grid container item>
              <Logo />
            </Grid>
            <Grid item>
              <WelcomeMessage>Register on Cohere</WelcomeMessage>
            </Grid>

            <Formik
              initialValues={{
                FirstName: '',
                LastName: '',
                BirthDate: '',
                HasAgreedToTerms: false,
              }}
              validationSchema={getSignUpClientValidationSchema()}
              onSubmit={registerHandler}
            >
              {({ values }) => (
                <Form id="signUpFormTwo">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Input label="First Name" type="text" name="FirstName" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <Input label="Last Name" type="text" name="LastName" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        label="Date of Birth"
                        mask="date"
                        type="text"
                        name="BirthDate"
                        inputComponent={MaskedInput}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        label="Timezone"
                        name="TimeZoneId"
                        fullWidth
                        items={[
                          {
                            value: 'America/Los_Angeles',
                            title: 'Pacific Standard Time',
                          },
                          {
                            value: 'America/Denver',
                            title: 'Mountain Standard Time',
                          },
                          {
                            value: 'America/Chicago',
                            title: 'Central Standard Time',
                          },
                          {
                            value: 'America/New_York',
                            title: 'Eastern Standard Time',
                          },
                          {
                            value: 'America/Anchorage',
                            title: 'Alaskan Standard Time',
                          },
                          {
                            value: 'America/St_Johns',
                            title: 'Newfoundland Standard Time',
                          },
                          {
                            value: 'Pacific/Honolulu',
                            title: 'Hawaiian Standard Time',
                          },
                          {
                            value: 'America/Phoenix',
                            title: 'Arizona Standard Time',
                          },
                          {
                            value: 'Australia/Sydney',
                            title: 'Australia/Sydney',
                          },
                          {
                            value: 'Canada/Atlantic',
                            title: 'Canada/Atlantic',
                          },
                          { value: 'Canada/Central', title: 'Canada/Central' },
                          { value: 'Canada/Eastern', title: 'Canada/Eastern' },
                          {
                            value: 'Canada/Mountain',
                            title: 'Canada/Mountain',
                          },
                          {
                            value: 'Canada/Newfoundland',
                            title: 'Canada/Newfoundland',
                          },
                          { value: 'Canada/Pacific', title: 'Canada/Pacific' },
                          {
                            value: 'Canada/Saskatchewan',
                            title: 'Canada/Saskatchewan',
                          },
                          { value: 'Canada/Yukon', title: 'Canada/Yukon' },
                          {
                            value: 'Europe/Amsterdam',
                            title: 'Europe/Amsterdam',
                          },
                          { value: 'Europe/Andorra', title: 'Europe/Andorra' },
                          { value: 'Europe/Athens', title: 'Europe/Athens' },
                          { value: 'Europe/Belfast', title: 'Europe/Belfast' },
                          {
                            value: 'Europe/Belgrade',
                            title: 'Europe/Belgrade',
                          },
                          { value: 'Europe/Berlin', title: 'Europe/Berlin' },
                          {
                            value: 'Europe/Bratislava',
                            title: 'Europe/Bratislava',
                          },
                          {
                            value: 'Europe/Brussels',
                            title: 'Europe/Brussels',
                          },
                          {
                            value: 'Europe/Bucharest',
                            title: 'Europe/Bucharest',
                          },
                          {
                            value: 'Europe/Budapest',
                            title: 'Europe/Budapest',
                          },
                          {
                            value: 'Europe/Busingen',
                            title: 'Europe/Busingen',
                          },
                          {
                            value: 'Europe/Chisinau',
                            title: 'Europe/Chisinau',
                          },
                          {
                            value: 'Europe/Copenhagen',
                            title: 'Europe/Copenhagen',
                          },
                          { value: 'Europe/Dublin', title: 'Europe/Dublin' },
                          {
                            value: 'Europe/Gibraltar',
                            title: 'Europe/Gibraltar',
                          },
                          {
                            value: 'Europe/Guernsey',
                            title: 'Europe/Guernsey',
                          },
                          {
                            value: 'Europe/Helsinki',
                            title: 'Europe/Helsinki',
                          },
                          {
                            value: 'Europe/Isle_of_Man',
                            title: 'Europe/Isle of Man',
                          },
                          {
                            value: 'Europe/Istanbul',
                            title: 'Europe/Istanbul',
                          },
                          { value: 'Europe/Jersey', title: 'Europe/Jersey' },
                          { value: 'Europe/Kaliningrad', title: 'Europe/Kiev' },
                          { value: 'Europe/Lisbon', title: 'Europe/Lisbon' },
                          {
                            value: 'Europe/Ljubljana',
                            title: 'Europe/Ljubljana',
                          },
                          { value: 'Europe/London', title: 'Europe/London' },
                          {
                            value: 'Europe/Luxembourg',
                            title: 'Europe/Luxembourg',
                          },
                          { value: 'Europe/Madrid', title: 'Europe/Madrid' },
                          { value: 'Europe/Malta', title: 'Europe/Malta' },
                          {
                            value: 'Europe/Mariehamn',
                            title: 'Europe/Mariehamn',
                          },
                          { value: 'Europe/Minsk', title: 'Europe/Minsk' },
                          { value: 'Europe/Monaco', title: 'Europe/Monaco' },
                          { value: 'Europe/Moscow', title: 'Europe/Moscow' },
                          { value: 'Europe/Nicosia', title: 'Europe/Nicosia' },
                          { value: 'Europe/Oslo', title: 'Europe/Oslo' },
                          { value: 'Europe/Paris', title: 'Europe/Paris' },
                          {
                            value: 'Europe/Podgorica',
                            title: 'Europe/Podgorica',
                          },
                          { value: 'Europe/Prague', title: 'Europe/Prague' },
                          { value: 'Europe/Riga', title: 'Europe/Riga' },
                          { value: 'Europe/Rome', title: 'Europe/Rome' },
                          { value: 'Europe/Samara', title: 'Europe/Samara' },
                          {
                            value: 'Europe/San_Marino',
                            title: 'Europe/San_Marino',
                          },
                          {
                            value: 'Europe/Sarajevo',
                            title: 'Europe/Sarajevo',
                          },
                          {
                            value: 'Europe/Simferopol',
                            title: 'Europe/Simferopol',
                          },
                          { value: 'Europe/Skopje', title: 'Europe/Skopje' },
                          { value: 'Europe/Sofia', title: 'Europe/Sofia' },
                          {
                            value: 'Europe/Stockholm',
                            title: 'Europe/Stockholm',
                          },
                          { value: 'Europe/Tallinn', title: 'Europe/Tallinn' },
                          { value: 'Europe/Tirane', title: 'Europe/Tirane' },
                          {
                            value: 'Europe/Tiraspol',
                            title: 'Europe/Tiraspol',
                          },
                          {
                            value: 'Europe/Uzhgorod',
                            title: 'Europe/Uzhgorod',
                          },
                          { value: 'Europe/Vaduz', title: 'Europe/Vaduz' },
                          { value: 'Europe/Vatican', title: 'Europe/Vatican' },
                          { value: 'Europe/Vienna', title: 'Europe/Vienna' },
                          { value: 'Europe/Vilnius', title: 'Europe/Vilnius' },
                          {
                            value: 'Europe/Volgograd',
                            title: 'Europe/Volgograd',
                          },
                          { value: 'Europe/Warsaw', title: 'Europe/Warsaw' },
                          { value: 'Europe/Zagreb', title: 'Europe/Zagreb' },
                          {
                            value: 'Europe/Zaporozhye',
                            title: 'Europe/Zaporozhye',
                          },
                          { value: 'Europe/Zurich', title: 'Europe/Zurich' },
                          { value: 'Hongkong', title: 'Hongkong' },
                          { value: 'Iceland', title: 'Iceland' },
                          { value: 'Israel', title: 'Israel' },
                          { value: 'Japan', title: 'Japan' },
                          {
                            value: 'Mexico/BajaNorte',
                            title: 'Mexico/Pacific',
                          },
                          { value: 'Mexico/BajaSur', title: 'Mexico/Mountain' },
                          { value: 'Mexico/General', title: 'Mexico/Central' },
                          { value: 'Navajo', title: 'Navajo' },
                          { value: 'NZ', title: 'NZ' },
                          { value: 'NZ-CHAT', title: 'NZ-CHAT' },
                          { value: 'Singapore', title: 'Singapore' },
                        ]}
                      />
                    </Grid>
                  </Grid>

                  <Grid container>
                    <AgreementContainer>
                      <Field as={Checkbox} name="HasAgreedToTerms" type="checkbox" color="primary" />I agree to the{' '}
                      <StyledTerms
                        href="/"
                        onClick={e => {
                          e.preventDefault();
                          setShowTerms(true);
                        }}
                      >
                        Terms and Conditions
                      </StyledTerms>
                    </AgreementContainer>
                  </Grid>

                  {error?.message && <CommonErrorMessage message={error.message} />}

                  <Grid container>
                    <ButtonsContainer>
                      <Button disabled={!values.HasAgreedToTerms || loading} type="submit">
                        Join Cohere
                      </Button>
                    </ButtonsContainer>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </CardBody>
      </Card>

      <Popover
        elevation={2}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        Are you an inspiring coach, teacher, or mentor in the field of personal, professional, or spiritual development?
        Then this platform is for you! Cohere helps you scale your businesses using a single platform. Your customers
        can purchase and participate in your live courses, work with you 1:1, and join your community of existing
        clients and customers.
      </Popover>

      <div className={classes.link}>
        <StyledLink to="/auth/signup/continue">Are you a service provider? Click here</StyledLink>
      </div>

      <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />
    </>
  );
}

SignUpClientSecondScreen.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    accountId: PropTypes.string,
  }).isRequired,
  continueRegister: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

SignUpClientSecondScreen.defaultProps = {
  error: null,
};

const mapStateToProps = ({ account }) => ({
  user: account?.user || {},
  loading: account?.isLoading,
  error: account?.error || {},
});

const actions = {
  continueRegister: userActions.continueRegister,
};

export default connect(mapStateToProps, actions)(SignUpClientSecondScreen);
