import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useChatClientChannels, ChatClientChannelConnectionStatus } from 'hooks'
import { LabelText } from 'components/UI/Text/TextStyles'
import ChatsList from './ChatsList'

import './ChatsList.scss'

const ChatsListContainer = ({ channelIds, onChannelClick, channelLinkProvider, isContributionPage }) => {
  const {
    connectionStatus,
    channels,
    channelsForContribution,
    hasMoreChannels,
    isLoadingMoreChannels,
    loadMoreChannels,
  } = useChatClientChannels(channelIds)

  const onLoadMoreChannels = () => {
    if (hasMoreChannels && !isLoadingMoreChannels) {
      loadMoreChannels()
    }
  }

  return (
    <div className="chats-list-container">
      {(connectionStatus === ChatClientChannelConnectionStatus.pending ||
        connectionStatus === ChatClientChannelConnectionStatus.connecting) && <CircularProgress />}
      {connectionStatus === ChatClientChannelConnectionStatus.connectionError && (
        <LabelText>Could not connect to chat.</LabelText>
      )}
      {connectionStatus === ChatClientChannelConnectionStatus.connected && (
        <ChatsList
          channels={isContributionPage ? channelsForContribution : channels}
          onChannelClick={onChannelClick}
          channelLinkProvider={channelLinkProvider}
          isLoadingMoreChannels={isLoadingMoreChannels}
          onLoadMoreChannels={onLoadMoreChannels}
        />
      )}
    </div>
  )
}

ChatsListContainer.propTypes = {
  channelIds: PropTypes.arrayOf(PropTypes.string),
  onChannelClick: PropTypes.func,
  channelLinkProvider: PropTypes.func,
  isContributionPage: PropTypes.bool,
}

ChatsListContainer.defaultProps = {
  channelIds: null,
  onChannelClick: null,
  channelLinkProvider: null,
  isContributionPage: false,
}

export default ChatsListContainer
