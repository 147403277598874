import React from 'react'
import PropTypes from 'prop-types'
import MUISelect from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input'
import { Icon } from '@mdi/react'
import { mdiChevronDown } from '@mdi/js'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import { colors } from 'utils/styles'

const StyledInput = withStyles(() => ({
  root: {
    fontFamily: 'Avenir',
    lineHeight: `${24 / 16}rem`,
    letterSpacing: '0.15px',
    fontSize: `${18 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
  },
  formControl: {
    'label + &': {
      marginTop: ({ labelTop }) => labelTop || 24,
    },
  },
  underline: {
    '&:after': {
      borderBottom: `2px solid #6486a2`,
    },
    '&:before': {
      borderColor: '#e7e7e7',
    },
  },
  error: {
    '&:after': {
      borderBottomColor: `${colors.fadedPurple} !important`,
    },
  },
}))(Input)

const StyledMenuItem = withStyles(() => ({
  root: {
    fontFamily: 'Avenir',
    fontSize: `${14 / 16}rem`,
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
    borderBottom: '1px solid #f2f2f2',
  },
  selected: {
    backgroundColor: 'transparent !important',
  },
}))(MenuItem)

const useStyles = makeStyles(() => ({
  labelRoot: {
    fontFamily: 'Avenir',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    fontSize: `${14 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
    transform: `translate(0, ${33 / 16}rem) scale(1)`,
  },
  labelShrink: {
    transform: 'translate(0, 4px)',
  },
  labelFocused: {
    color: 'inherit !important',
  },
  labelFormControl: {
    transform: `translate(0, ${32 / 16}rem) scale(1)`,
  },
  labelError: {
    color: 'inherit !important',
  },
  helperTextRoot: {
    textAlign: 'right',
    fontFamily: 'Avenir',
    lineHeight: `${24 / 16}rem`,
    letterSpacing: '0.08px',
    fontSize: `${12 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'normal',
  },
  helperTextError: {
    color: `${colors.fadedPurple} !important`,
    textAlign: 'left',
    fontSize: '1rem',
    letterSpacing: '0.1px',
  },
  menuListPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

function getStyles(value, values) {
  return {
    fontWeight: values.includes(value) ? 'bold' : 'normal',
  }
}

const ArrowIcon = (props) => <Icon path={mdiChevronDown} size={1} {...props} />

function Select(props) {
  const {
    className,
    value,
    onChange,
    helperText,
    error,
    items,
    label,
    fullWidth,
    disabled,
    multiple,
    labelTop,
    errorHeight,
    ...rest
  } = props
  const classes = useStyles()

  return (
    <FormControl className={className} disabled={disabled} fullWidth={fullWidth} error={Boolean(error)}>
      <InputLabel
        classes={{
          root: classes.labelRoot,
          focused: classes.labelFocused,
          shrink: classes.labelShrink,
          error: classes.labelError,
        }}
      >
        {label}
      </InputLabel>
      <MUISelect
        IconComponent={ArrowIcon}
        input={<StyledInput labelTop={labelTop} />}
        MenuProps={{
          disableAutoFocusItem: true,
          MenuListProps: {
            classes: {
              padding: classes.menuListPadding,
            },
          },
        }}
        disabled={disabled}
        multiple={multiple}
        value={value}
        onChange={onChange}
        {...rest}
      >
        {items.map(({ title: itemTitle, value: itemValue }, i) => (
          <StyledMenuItem
            /* eslint-disable-next-line */
            key={i}
            value={itemValue}
            style={multiple ? getStyles(itemValue, value) : {}}
          >
            {itemTitle}
          </StyledMenuItem>
        ))}
      </MUISelect>
      <FormHelperText
        classes={{
          root: classes.helperTextRoot,
          error: classes.helperTextError,
        }}
        style={{
          minHeight: errorHeight,
        }}
      >
        {error || helperText}
      </FormHelperText>
    </FormControl>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.any,
    })
  ).isRequired,
  label: PropTypes.string,
  labelTop: PropTypes.string,
  multiple: PropTypes.bool,
  errorHeight: PropTypes.string,
}

Select.defaultProps = {
  className: '',
  fullWidth: false,
  helperText: null,
  error: null,
  disabled: false,
  label: null,
  multiple: false,
  labelTop: null,
  errorHeight: null,
}

export default Select
