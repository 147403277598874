import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTheme, useMediaQuery } from '@material-ui/core';
import { useRouter, useShallowEqualSelector } from 'hooks';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import ContributionHeaderView, { StyledNavLink } from 'components/UI/ContributionHeaderView';
import { ContributionType, UserRoles, ContributionStatus } from 'helpers/constants';

import * as S from './styled';
import { useSelector } from 'react-redux';

const HeaderTabs = [
  { name: 'About', id: 'contribution-view-about-link' },
  { name: 'Sessions', id: 'contribution-view-sessions-link' },
  { name: 'Community', id: 'contribution-view-community-link' },
  { name: 'Personal Notes', id: 'contribution-view-personal-notes-link' },
  { name: 'Team', id: 'contribution-view-team-link' },
  { name: 'Invite Clients', id: 'contribution-view-invite-clients-link' },
];

const ContributionHeader = ({ canAccess, onShareButtonClick, status }) => {
  const {
    match: { url },
  } = useRouter();
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const urlWithCode = isInviteToJoin ? `${url}/${isInviteToJoin}` : url;
  const userRole = useShallowEqualSelector(state => state.account.currentRole);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { type } = useContribution();

  const isCohealer = useMemo(() => userRole === UserRoles.cohealer, [userRole]);
  const isTeamTabVisible = useMemo(
    () =>
      (type === ContributionType.contributionCourse || type === ContributionType.contributionMembership) && isCohealer,
    [isCohealer, type],
  );

  const teamTab = useMemo(
    () => (
      <StyledNavLink mobileView={mobileView} id={HeaderTabs[4].id} to={`${urlWithCode}/team`}>
        {HeaderTabs[4].name}
      </StyledNavLink>
    ),
    [mobileView, url],
  );
  const inviteClients = useMemo(
    () => (
      <S.StyledShareButton onClick={onShareButtonClick} disabled={status !== ContributionStatus.approved}>
        <S.StyledShareIcon />
        {HeaderTabs[5].name}
      </S.StyledShareButton>
    ),
    [onShareButtonClick, status],
  );
  return (
    <ContributionHeaderView mobileView>
      <StyledNavLink mobileView={mobileView} id={HeaderTabs[0].id} to={`${urlWithCode}/about`}>
        {HeaderTabs[0].name}
      </StyledNavLink>
      <StyledNavLink mobileView={mobileView} id={HeaderTabs[1].id} to={`${urlWithCode}/sessions`}>
        {HeaderTabs[1].name}
      </StyledNavLink>
      {canAccess && (
        <StyledNavLink mobileView={mobileView} id={HeaderTabs[2].id} to={`${urlWithCode}/community/social`}>
          {HeaderTabs[2].name}
        </StyledNavLink>
      )}
      {canAccess && (
        <StyledNavLink mobileView={mobileView} id={HeaderTabs[3].id} to={`${urlWithCode}/notes`}>
          {HeaderTabs[3].name}
        </StyledNavLink>
      )}
      {isTeamTabVisible && teamTab}
      {isCohealer && inviteClients}
    </ContributionHeaderView>
  );
};

ContributionHeader.propTypes = {
  canAccess: PropTypes.bool.isRequired,
  onShareButtonClick: PropTypes.func,
  status: PropTypes.string,
};

ContributionHeader.defaultProps = {
  onShareButtonClick: () => {},
  status: '',
};

export default ContributionHeader;
