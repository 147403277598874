import { get } from 'lodash/fp';
import axiosInstance from '../utils/axiosInstance';

const getAllPostsForContributionAsync = id => axiosInstance.get(`/Post/GetAll/${id}`).then(get('data'));
const post = data => axiosInstance.post(`/Post`, data).then(get('data'));
const deletePost = id => axiosInstance.delete(`/Post/${id}`).then(get('data'));
const editPost = data => axiosInstance.put('/Post', data).then(get('data'));
const removeAttachment = data => axiosInstance.delete(`/Post/Attachment/${data}`).then(get('data'));
const addLike = data => axiosInstance.post('/Like', data).then(get('data'));
const removeLike = id => axiosInstance.delete(`/Like/${id}`).then(get('data'));
const addComment = data => axiosInstance.post('/Comment', data).then(get('data'));
const removeComment = id => axiosInstance.delete(`/Comment/${id}`).then(get('data'));

export {
  getAllPostsForContributionAsync,
  post,
  deletePost,
  editPost,
  addLike,
  removeLike,
  removeAttachment,
  addComment,
  removeComment,
};
