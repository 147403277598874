import React, { useEffect, useState, useCallback } from 'react';

import Loader from 'components/UI/Loader';
import PopupComp from 'components/Popup/Popup';

import useHttp from './http.hook';
import usePrevious from './common/usePrevious';
import useVideoChat from './useVideoChat';
import useAccount from './useAccount';

const useEndVideoChat = refreshList => {
  const INITIAL_STATE_VALUE = {
    isShowPopup: false,
    contributionId: null,
    classId: null,
  };
  const [valuesForPopup, setValuesForPopup] = useState(INITIAL_STATE_VALUE);

  const { request, loading } = useHttp();
  const { user } = useAccount();
  const { token, contributionId, classId, creatorId } = useVideoChat();
  const prevSession = usePrevious({ token, contributionId, classId, creatorId }) || {};

  const onShowPopup = useCallback(
    (selectedContributionId, selectedClassId) => {
      setValuesForPopup({
        isShowPopup: true,
        contributionId: selectedContributionId,
        classId: selectedClassId,
      });
    },
    [setValuesForPopup],
  );

  const continueLaunchPopup = useCallback(() => {
    if (!prevSession.creatorId) {
      onShowPopup(prevSession.contributionId, prevSession.classId);
      return;
    }

    if (prevSession.creatorId === user.id) {
      onShowPopup(prevSession.contributionId, prevSession.classId);
    }
  }, [onShowPopup, prevSession.classId, prevSession.contributionId, user.id, prevSession.creatorId]);

  useEffect(() => {
    if (prevSession.token && !token) {
      continueLaunchPopup();
    }
  }, [token, prevSession.token, prevSession.contributionId, prevSession.classId, continueLaunchPopup]);

  const handleSessionComplete = useCallback(async () => {
    try {
      await request('/Contribution/SetClassAsCompleted', 'POST', {
        contributionId: valuesForPopup.contributionId,
        classId: valuesForPopup.classId,
      });
      await refreshList();
    } catch (err) {
      console.dir(err);
    }
  }, [valuesForPopup.contributionId, valuesForPopup.classId, request, refreshList]);

  const handleCompleteFromPopup = useCallback(async () => {
    handleSessionComplete();
    setValuesForPopup(INITIAL_STATE_VALUE);
  }, [handleSessionComplete, setValuesForPopup, INITIAL_STATE_VALUE]);

  const handleClosePopup = useCallback(() => {
    setValuesForPopup(INITIAL_STATE_VALUE);
  }, [setValuesForPopup, INITIAL_STATE_VALUE]);

  const Popup = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <PopupComp
        title="Was this session completed?"
        text="PLEASE NOTE, once you mark as complete you will not be able to undo this. If you click no, you can start and finish this session later"
        open={valuesForPopup.isShowPopup}
        onCloseText="No"
        onClose={handleClosePopup}
        onSubmit={handleCompleteFromPopup}
      />
    );
  };

  return {
    Popup,
    onShowPopup,
  };
};

export default useEndVideoChat;
