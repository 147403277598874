import withStyles from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

import { colors } from 'utils/styles/styles';

const StyledHeader = styled.div`
  font-size: 1.2rem;
  margin-top: 27px;
  font-weight: 600;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

const BlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: colors.darkOceanBlue,
    },
  },
  checked: {},
})(Checkbox);

export { StyledHeader, BlueCheckbox };
