import React from 'react'
import PropTypes from 'prop-types'
import { LabelText } from 'components/UI/Text/TextStyles'
import { Avatar, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'

export const CoachItem = ({ serviceProviderName, firstName, lastName, avatarUrl, userId, mobileView }) => (
  <Grid item>
    <Link to={`/cohealer-about/${userId}/about`}>
      <Grid item container alignItems="center">
        <Avatar src={avatarUrl || 'https://via.placeholder.com/40'} className="mx-2" />
        <LabelText mobileView={mobileView}>{serviceProviderName || `${firstName} ${lastName}`}</LabelText>
      </Grid>
    </Link>
  </Grid>
)

CoachItem.propTypes = {
  userId: PropTypes.string.isRequired,
  serviceProviderName: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  mobileView: PropTypes.bool.isRequired,
}

CoachItem.defaultProps = {
  serviceProviderName: 'No name',
}
