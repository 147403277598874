import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { SuccessfulPurchaseModal } from 'components/Modals/SuccessfulPurchaseModal';
import {
  fetchClientContribution,
  fetchContributionActions,
  setInviteCode,
  updateSubscriptionInfo,
} from 'actions/contributions';
import Button from 'components/FormUI/Button';
import Can from 'components/Auth/Can';
import { ContributionType, UserRoles, PaymentStatus } from 'helpers/constants';
import { useAccount, useRouter } from 'hooks';
import { setPaidTierContribution, setActivePaidTierContributions } from 'actions/paidTierOptions';
import * as accountPreferences from 'services/accountPreferences.service';
import { getAcademy, purchaseAcademyContribution } from 'services/paidTierOptions.service';
import * as userService from 'services/user.service';
import { roleChange } from 'actions/user';
import { getBoughtByType, getClientContribution, joinContribution } from 'services/contributions.service';
import usePaidTier from 'hooks/usePaidTier';
import isNil from 'lodash/isNil';
import * as contributionActions from 'actions/contributions';
import SmallLoader from 'components/UI/SmallLoader';
import PurchaseModal from './PurchaseModal';
import useContribution from '../hooks/useContribution';
import ProceedModal from './ProceedModal';
import PurchaseMembershipModal from './PurchaseMembershipModal';
import { MEMBERSHIP_STATUSES, ROUTES } from '../../../constants';
import { InfoModal } from '../../../components/Modals/InfoModal';

const titles = {
  [ContributionType.contributionCourse]: {
    button: 'Purchase',
    confirm: 'Proceed Purchasing',
    proceedSubscription: 'Proceed Subscription Payment',
    join: 'Join',
  },
  [ContributionType.contributionOneToOne]: {
    button: 'Book Single Session',
  },
  [ContributionType.contributionMembership]: {
    button: 'Purchase',
    join: 'Join',
  },
  [ContributionType.contributionLocalEvent]: {
    button: '',
  },
};

const ButtonWrapper = styled.div`
  margin-left: 20px;

  button {
    min-width: 128px;
    max-width: 150px;
  }
`;

const purchaseMembershipStatus = {
  default: 'default',
  inProcess: 'process',
  success: 'success',
};

const TypeOfCourse = ({ contribution, handleUrlModal }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    params: { code },
  } = useRouteMatch();
  const dispatch = useDispatch();

  const { academyContributions, activeAcademyContributions, updateContibutionViewState } = usePaidTier();

  const { location, push } = useRouter();
  const history = useHistory();

  const { id, type, purchaseStatus, isPurchased } = useContribution();
  const { user, currentRole } = useAccount();
  const {
    paymentInfo: { paymentOptions },
  } = useContribution();

  const [academyMembershipSubscribing, setAcademyMembershipSubscribing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isOpenSuccessPurchaseModal, setSuccessPurchaseModal] = useState(false);
  const [purchaseMembershipModalOpen, setPurchaseMembershipModalOpen] = useState(false);
  const [infoModalType, setInfoModalType] = useState(null);
  const [isUpgrade, setIsUpgrade] = useState(false);
  // const [isInviteToJoin, setIsInviteToJoin] = useState(true);

  const isMembership = contribution.type === ContributionType.contributionMembership;
  const isCoach = currentRole === UserRoles.cohealer;
  const isCanPurchaseMembership = isMembership && !isCoach && !contribution.subscriptionStatus;
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  useEffect(() => {
    if (!isNil(code)) {
      dispatch(contributionActions.setInviteCode(code));
    } else {
      dispatch(contributionActions.setInviteCode(null));
    }
  }, [code]);

  const isCanUpgradeMembership =
    isMembership &&
    !isCoach &&
    contribution?.subscriptionStatus &&
    contribution?.subscriptionStatus?.status !== MEMBERSHIP_STATUSES.UPGRADE &&
    contribution?.subscriptionStatus?.status === MEMBERSHIP_STATUSES.ACTIVE &&
    paymentOptions.length !== 1;

  const handleUpgradeMembershipClick = useCallback(
    nextPaymentOption => {
      dispatch(
        updateSubscriptionInfo({
          ...contribution?.subscriptionStatus,
          nextPaymentOption,
          status: MEMBERSHIP_STATUSES.UPGRADE,
        }),
      );
      setPurchaseMembershipModalOpen(false);
    },
    [infoModalType, purchaseMembershipModalOpen],
  );

  const handleUpgradeSubscription = useCallback(() => {
    setIsUpgrade(true);
    setPurchaseMembershipModalOpen(true);
  }, [isUpgrade, purchaseMembershipModalOpen, setIsUpgrade, setPurchaseMembershipModalOpen]);

  const handleInfoModalClose = useCallback(() => setInfoModalType(null), [infoModalType]);

  const closeModalHandler = useCallback(() => {
    setShowModal(false);
    setShowConfirmModal(false);
  }, []);

  const toggleSuccessPurchaseModal = () => setSuccessPurchaseModal(prevState => !prevState);

  const submitModalHandler = useCallback(() => {
    setShowModal(false);
    setShowConfirmModal(false);
    toggleSuccessPurchaseModal();
    dispatch(fetchClientContribution(id));

    history.push({
      pathname: `/contribution-view/${id}/sessions`,
    });
  }, [dispatch, id]);

  const showOnlyProseedModal = () => {
    setShowModal(false);
    setShowConfirmModal(true);
  };

  let purchaseButton = null;
  const handleShowModal = () => {
    if (isInviteToJoin) {
      if (isEmpty(user)) {
        setShowModal(true);
      } else {
        joinContribution({ id, accessCode: isInviteToJoin }).then(() => {
          setShowModal(true);
          dispatch(
            fetchContributionActions.success({ ...contribution, isPurchased: true, purchaseStatus: 'succeeded' }),
          );
        });
      }
    } else {
      setShowModal(true);
    }
  };
  if (
    [PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased, PaymentStatus.proceedSubscription].includes(
      purchaseStatus,
    )
  ) {
    const purchaseButtonTitle =
      purchaseStatus === PaymentStatus.proceedSubscription
        ? titles[type].proceedSubscription
        : isInviteToJoin
        ? titles[type].join
        : titles[type].button;
    purchaseButton = (
      <Button mobileView={mobileView} onClick={handleShowModal}>
        {purchaseButtonTitle}
      </Button>
    );
  }

  if ([PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(purchaseStatus)) {
    purchaseButton = (
      <Button mobileView={mobileView} onClick={() => setShowConfirmModal(true)}>
        {titles[type].confirm}
      </Button>
    );
  }

  if (
    type === ContributionType.contributionOneToOne &&
    [PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased].includes(purchaseStatus)
  ) {
    purchaseButton = <Button mobileView={mobileView}>{titles[type].button}</Button>;
  }

  if (
    type === ContributionType.contributionOneToOne &&
    [PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(purchaseStatus)
  ) {
    purchaseButton = <Button mobileView={mobileView}>{titles[type].confirm}</Button>;
  }

  if (type === ContributionType.contributionOneToOne) {
    const isActiveSessionPath = location.pathname.includes('session');
    purchaseButton = isActiveSessionPath ? (
      <a href="#contribution-view-calendar">{purchaseButton}</a>
    ) : (
      <Link to={`/contribution-view/${id}/sessions`}>{purchaseButton}</Link>
    );
  }

  const redirectToPaidTierPage = () => {
    Promise.all([
      userService.switchFromClientToCoach({}),
      accountPreferences.setAccountPreferences({ userView: UserRoles.cohealer }),
    ]).then(([auth, preferences]) => {
      push(ROUTES.DASHBOARD);
      dispatch(roleChange(preferences.userView));
      push(ROUTES.ACCOUNT_COACH_BILLING);
    });
  };

  const purchaseMembershipFromAcademy = contributionId => {
    setAcademyMembershipSubscribing(true);
    purchaseAcademyContribution(contributionId)
      .then(() => {
        updateContibutionViewState(contributionId);

        setTimeout(() => {
          updateContibutionViewState(contributionId).then(() => {
            setAcademyMembershipSubscribing(false);
          });
        }, 3000);
      })
      .catch(e => {
        setAcademyMembershipSubscribing(false);
        redirectToPaidTierPage();
      });
  };

  useEffect(() => {
    setAcademyMembershipSubscribing(true);
    updateContibutionViewState(id).then(() => {
      setAcademyMembershipSubscribing(false);
    });
  }, []);

  const isActiveAcademyMembership = useMemo(() => {
    return activeAcademyContributions.find(item => item?.id === id);
  }, [id, activeAcademyContributions]);

  const isCurrentContributionFromAcademy = useMemo(() => {
    return academyContributions.find(item => item?.id === id);
  }, [id, academyContributions]);

  const showSubscribeForAcademyMembership = useMemo(() => {
    return !isActiveAcademyMembership && isCurrentContributionFromAcademy;
  }, [isActiveAcademyMembership, isCurrentContributionFromAcademy]);
  return (
    <>
      {isCanPurchaseMembership && !isCurrentContributionFromAcademy && !isInviteToJoin && (
        <ButtonWrapper>
          <Button mobileView={mobileView} onClick={() => setPurchaseMembershipModalOpen(true)}>
            Subscribe
          </Button>
        </ButtonWrapper>
      )}

      {showSubscribeForAcademyMembership && !academyMembershipSubscribing && (
        <ButtonWrapper>
          <Button mobileView={mobileView} onClick={() => purchaseMembershipFromAcademy(id)}>
            Subscribe
          </Button>
        </ButtonWrapper>
      )}

      {!isPurchased && isCanPurchaseMembership && !isCurrentContributionFromAcademy && isInviteToJoin && (
        <ButtonWrapper>
          <Button mobileView={mobileView} onClick={handleShowModal}>
            Join
          </Button>
        </ButtonWrapper>
      )}

      {academyMembershipSubscribing && isCurrentContributionFromAcademy && <SmallLoader size={20} border={2} />}

      {/* {!isActiveAcademyMembership && isCurrentContributionFromAcademy && isInviteToJoin && ( */}
      {/*  <ButtonWrapper> */}
      {/*    <Button mobileView={mobileView} onClick={handleShowModal}> */}
      {/*      Join */}
      {/*    </Button> */}
      {/*  </ButtonWrapper> */}
      {/* )} */}

      {/* TODO https://cohereonline.atlassian.net/browse/CMP1-2207 */}
      {/* {isCanUpgradeMembership && ( */}
      {/*  <ButtonWrapper> */}
      {/*    <Button mobileView={mobileView} onClick={handleUpgradeSubscription}> */}
      {/*      Upgrade Subscription */}
      {/*    </Button> */}
      {/*  </ButtonWrapper> */}
      {/* )} */}

      {infoModalType && <InfoModal type={infoModalType} handleClose={handleInfoModalClose()} />}
      {purchaseMembershipModalOpen && (
        <PurchaseMembershipModal
          isOpen={purchaseMembershipModalOpen}
          onClose={() => setPurchaseMembershipModalOpen(false)}
          isUpgrade={isUpgrade}
          handleUrlModal={handleUrlModal}
          currentSubscription={contribution?.subscriptionStatus?.paymentOption}
          onUpgrade={handleUpgradeMembershipClick}
          contribution={contribution}
        />
      )}

      {(type === ContributionType.contributionCourse ||
        contribution?.paymentInfo?.paymentOptions?.includes('PerSession')) && (
        <>
          {!isEmpty(user) ? (
            <Can roleName="Client" yes={() => <ButtonWrapper>{purchaseButton}</ButtonWrapper>} />
          ) : (
            <ButtonWrapper>{purchaseButton}</ButtonWrapper>
          )}
        </>
      )}

      {showModal && (
        <PurchaseModal
          isInviteToJoin={isInviteToJoin}
          isOpen={showModal}
          onClose={closeModalHandler}
          onSubmit={submitModalHandler}
          showOnlyProseedModal={showOnlyProseedModal}
        />
      )}

      {showConfirmModal && (
        <ProceedModal isOpen={showConfirmModal} onClose={closeModalHandler} onSubmit={submitModalHandler} />
      )}

      {isOpenSuccessPurchaseModal && (
        <SuccessfulPurchaseModal
          isOpen={isOpenSuccessPurchaseModal}
          handleOpen={toggleSuccessPurchaseModal}
          handleClose={toggleSuccessPurchaseModal}
        />
      )}
    </>
  );
};
TypeOfCourse.propTypes = {
  contribution: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    paymentInfo: PropTypes.shape({
      splitNumbers: PropTypes.number,
      splitPeriod: PropTypes.string,
      paymentOptions: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

TypeOfCourse.defaultProps = {};

export default TypeOfCourse;
