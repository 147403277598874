import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import usePaidTier from 'hooks/usePaidTier';
import { PAIDTIER_STATUSES } from '../../constants';

import AdvantagesItem from './AdvantagesItem';
import FavBookIcon from './FavBookIcon';
import UpgradeButton from '../UI/UpgradeButton';
import { useStyles, StyledContainer, TitleField, PriceField, FeeField, AdvantagesList, ExpiresLabel } from './styles';

const Card = ({
  id,
  title,
  price,
  fee,
  advantages,
  expires = undefined,
  canBeUpgraded,
  isActive,
  buttonLabel,
  popular,
  onclick,
  isModalView,
  isDefaultPlan,
  planStatus,
  paymentPeriod,
}) => {
  const classes = useStyles();
  const { isAnnualPlan } = usePaidTier();

  const currentButtonClass = isActive
    ? `${classes.selectPlanButton} ${classes.enable}`
    : `${classes.selectPlanButton} ${classes.disable}`;

  const isUpgradedOrCanceled = planStatus === PAIDTIER_STATUSES.UPGRADE || planStatus === PAIDTIER_STATUSES.CANCEL;

  const hideCancelButton = isActive && isUpgradedOrCanceled;

  const formatedDate = new Date(expires).toLocaleDateString('en-US');

  const isAnnual = paymentPeriod === 'Annually';
  const paymentPeriodLabel = isAnnual ? 'annual' : 'monthly';

  const isCancel = planStatus === 'Cancel';

  return (
    <StyledContainer>
      {popular && (
        <div className={classes.favIconContainer}>
          <div className={classes.icon}>
            <FavBookIcon />
          </div>
          <p>Most popular</p>
        </div>
      )}

      <Grid container className={classes.cardBox}>
        <Grid item xs={12} className={classes.topLevel}>
          <TitleField className={classes.titleMedia}>{title}</TitleField>
          <PriceField className={classes.priceMedia}>
            ${price} <span>/ month {isAnnualPlan && `($${price * 12} annual)`}</span>
          </PriceField>
          <FeeField className={classes.feeMedia}>{fee}% Fee</FeeField>
        </Grid>
        <Grid item xs={12} className={classes.midLevel}>
          <AdvantagesList>
            {advantages.map(label => (
              <AdvantagesItem key={label} label={label} />
            ))}
          </AdvantagesList>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classes.bottomLevel}
          justify="center"
          alignContent="flex-end"
          direction="row"
        >
          {isDefaultPlan ? (
            <Grid item>{isActive ? <ExpiresLabel>Current plan</ExpiresLabel> : null}</Grid>
          ) : (
            <Grid item>
              {expires && isActive && isCancel && (
                <ExpiresLabel>
                  {' '}
                  Your {paymentPeriodLabel} {title} cancels on {formatedDate}.
                </ExpiresLabel>
              )}
              {expires && isActive && !isCancel && (
                <ExpiresLabel>
                  Your {paymentPeriodLabel} {title} renews on {formatedDate}.
                </ExpiresLabel>
              )}
              {!hideCancelButton && (
                <div className={classes.upgradeButtonWrapper}>
                  <UpgradeButton style={currentButtonClass} onclick={onclick} buttonLabel={buttonLabel} />
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  fee: PropTypes.number.isRequired,
  advantages: PropTypes.arrayOf(PropTypes.string).isRequired,
  expires: PropTypes.string,
  buttonLabel: PropTypes.string,
  canBeUpgraded: PropTypes.bool.isRequired,
  isDefaultPlan: PropTypes.bool.isRequired,
  isModalView: PropTypes.bool.isRequired,
  planStatus: PropTypes.string.isRequired,
};

export default Card;
