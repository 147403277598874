import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';

import { setItem, removeItem, clearStorage } from 'services/localStorage.service';
// import { authError } from "utils/request";
import {
  loginUser,
  restorePassword,
  newPassword,
  registerUser,
  continueRegisterUser,
  editUserProfile,
  getUserProfile,
} from 'services/user.service';
import * as accountPreferences from 'services/accountPreferences.service';
import { getRolesFromToken, getCurrentRole } from 'utils/auth';
import moment from 'moment';
import { redirectTo } from 'services/links';
import { isNull } from 'lodash/lang';
import { ROUTES } from '../constants.js';
import { isNil } from 'ramda';

export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export const LOG_OUT = 'LOG_OUT';

export const RESTORE_PASS_REQUEST = 'RESTORE_PASS_REQUEST';
export const RESTORE_PASS_SUCCESS = 'RESTORE_PASS_SUCCESS';
export const RESTORE_PASS_FAILURE = 'RESTORE_PASS_FAILURE';

export const NEW_PASS_REQUEST = 'NEW_PASS_REQUEST';
export const NEW_PASS_SUCCESS = 'NEW_PASS_SUCCESS';
export const NEW_PASS_FAILURE = 'NEW_PASS_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const CONTINUE_REGISTER_REQUEST = 'CONTINUE_REGISTER_REQUEST';
export const CONTINUE_REGISTER_SUCCESS = 'CONTINUE_REGISTER_SUCCESS';
export const CONTINUE_REGISTER_FAILURE = 'CONTINUE_REGISTER_FAILURE';

export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE';

export const CHANGE_CURRENT_ROLE = 'CHANGE_CURRENT_ROLE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const CLEAR_USER_ERRORS = 'CLEAR_USER_ERRORS';
export const DISABLE_SHOW_CLIENT_MODALS = 'DISABLE_SHOW_CLIENT_MODALS';
export const SET_PROFILE_AVATAR = 'SET_PROFILE_AVATAR';

export const GET_ACCOUNT = 'GET_ACCOUNT';

export const HIDE_TEST_VIDEO_BUTTON = 'HIDE_TEST_VIDEO_BUTTON';

export const GETTING_STARTED_TOGGLE = 'GETTING_STARTED_TOGGLE';

export const getStartedToggle = value => ({
  type: GETTING_STARTED_TOGGLE,
  payload: value,
});

export const disableShowCLientModals = () => ({
  type: DISABLE_SHOW_CLIENT_MODALS,
});

export const logOut = () => {
  removeItem('token');
  clearStorage();
  return { type: LOG_OUT };
};

const handleAuthenticate = actionType => ({ account, user }) => {
  const roles = getRolesFromToken(account.oAuthToken);

  setItem('token', account.oAuthToken);

  return accountPreferences.getAccountPreferences().then(({ userView }) => {
    const currentRole = userView || getCurrentRole(roles);
    if (!userView) {
      accountPreferences.setAccountPreferences({ userView: currentRole });
    }
    return {
      type: actionType,
      payload: {
        account,
        user: {
          ...user,
          accountId: account.id,
        },
        roles,
        currentRole,
      },
    };
  });
};
const checkIsHasUser = data => {
  if (isNil(data.payload.user?.oAuthToken)) {
    return redirectTo('/signup/client/continue');
  }
  return data;
};

const loginRequest = () => ({ type: LOG_IN_REQUEST });
const loginSuccess = handleAuthenticate(LOG_IN_SUCCESS);
const loginFailure = err => ({ type: LOG_IN_FAILURE, payload: err });

export const login = (email, password, isRoleAdding) => dispatch => {
  dispatch(loginRequest());

  return loginUser(email, password)
    .then(loginSuccess)
    .then(dispatch)
    .then(checkIsHasUser)
    .then(() => isRoleAdding && redirectTo(ROUTES.DASHBOARD))
    .catch(pipe(get('response'), get('data'), loginFailure, dispatch));
};

const restorePassRequest = () => ({ type: RESTORE_PASS_REQUEST });
const restorePassSuccess = payload => ({
  type: RESTORE_PASS_SUCCESS,
  message: payload,
});
const restorePassFailure = err => ({
  type: RESTORE_PASS_FAILURE,
  payload: err,
});

export const restorePass = email => dispatch => {
  dispatch(restorePassRequest());

  return restorePassword(email)
    .then(restorePassSuccess)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), restorePassFailure, dispatch));
};

const newPassRequest = () => ({ type: NEW_PASS_REQUEST });
const newPassSuccess = () => ({
  type: NEW_PASS_SUCCESS,
});
const newPassFailure = err => ({
  type: NEW_PASS_FAILURE,
  payload: err,
});

export const newPass = data => dispatch => {
  dispatch(newPassRequest());

  return newPassword(data)
    .then(newPassSuccess)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), newPassFailure, dispatch));
};

/* registration 1st step */
const registerRequest = () => ({ type: REGISTER_REQUEST });
const registerSuccess = account => {
  setItem('token', account.oAuthToken);

  const acc = {
    ...account,
    accountId: account.id,
    id: null,
  };

  return { type: REGISTER_SUCCESS, payload: acc };
};
const registerFailure = err => ({
  type: REGISTER_FAILURE,
  payload: err,
});

export const register = (email, password, inviteCode, onSuccess, userView) => dispatch => {
  dispatch(registerRequest());
  return registerUser(email, password, inviteCode, userView)
    .then(account => registerSuccess(account))
    .then(dispatch)
    .then(onSuccess)
    .catch(pipe(get('response'), get('data'), registerFailure, dispatch));
};
/* end registration 1st step */

/* registration 2st step */
const continueRegisterRequest = () => ({
  type: CONTINUE_REGISTER_REQUEST,
});
const continueRegisterSuccess = handleAuthenticate(CONTINUE_REGISTER_SUCCESS);
const continueRegisterFailure = err => ({
  type: CONTINUE_REGISTER_FAILURE,
  payload: err,
});

export const continueRegister = ({ fields, userView }) => dispatch => {
  dispatch(continueRegisterRequest());
  accountPreferences.setAccountPreferences({ userView });
  return continueRegisterUser(fields)
    .then(continueRegisterSuccess)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), continueRegisterFailure, dispatch));
};

export const completeRegister = ({
  Email,
  Password,
  BirthDate,
  FirstName,
  LastName,
  TimeZoneId,
  userView,
}) => dispatch => {
  dispatch(registerRequest());

  const onSuccess = accountId => {
    dispatch(continueRegisterRequest());
    const fields = {
      FirstName,
      HasAgreedToTerms: true,
      LastName,
      accountId,
      BirthDate: moment.utc(BirthDate).format(),
      TimeZoneId,
    };
    accountPreferences.setAccountPreferences({ userView });
    return continueRegisterUser(fields)
      .then(continueRegisterSuccess)
      .then(dispatch)
      .catch(pipe(get('response'), get('data'), continueRegisterFailure, dispatch));
  };
  return registerUser(Email, Password, null, userView)
    .then(account => registerSuccess(account))
    .then(action => {
      dispatch(action);
      return action.payload.accountId;
    })
    .then(onSuccess)
    .catch(pipe(get('response'), get('data'), registerFailure, dispatch));
};
/* end registration 1st step */

/* edit profile */
const editRequest = () => ({ type: EDIT_PROFILE_REQUEST });
const editSuccess = handleAuthenticate(EDIT_PROFILE_SUCCESS);
const editFailure = err => ({
  type: EDIT_PROFILE_FAILURE,
  payload: err,
});

export const editProfile = (id, fields) => dispatch => {
  dispatch(editRequest());

  return (
    editUserProfile(id, fields)
      .then(editSuccess)
      .then(dispatch)
      // .catch(authError(dispatch, logOut))
      .catch(pipe(get('response'), get('data'), editFailure, dispatch))
  );
};
/* end edit profile */

/* role changing */
export const roleChange = role => ({
  type: CHANGE_CURRENT_ROLE,
  payload: role,
});
/* end role changing */

/* clear errors */
export const clearUserErrors = () => ({ type: CLEAR_USER_ERRORS });
/* end edit profile */

const getUserRequest = () => ({ type: GET_USER_REQUEST });
const getUserSuccess = user => ({ type: GET_USER_SUCCESS, payload: user });
const getUserFailure = err => ({
  type: GET_USER_FAILURE,
  payload: err,
});
export const getProfile = id => dispatch => {
  dispatch(getUserRequest());

  return getUserProfile(id)
    .then(getUserSuccess)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), getUserFailure, dispatch));
};

export const setProfileAvatar = createAction(SET_PROFILE_AVATAR);

export const getAccount = createAction(GET_ACCOUNT);

export const hideTestButton = () => ({ type: HIDE_TEST_VIDEO_BUTTON });
