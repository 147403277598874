import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';

import {
  getCalendars,
  addCalendarAccount,
  removeCalendarAccount,
  signInCallback,
  getAcсountsToCheckConflicts,
  putMailsForConfictCheckRequest,
  putMailForCalendarRemindersRequest,
  getAccountsToSendReminders,
  getBusyTimeRequest,
} from 'services/calendars.service';

export const GET_CALENDARS_REQUEST = 'GET_CALENDARS_REQUEST';
export const GET_CALENDARS_SUCCESS = 'GET_CALENDARS_SUCCESS';
export const GET_CALENDARS_ERROR = 'GET_CALENDARS_ERROR';
export const ADD_CALENDAR_ACCOUNT_REQUEST = 'ADD_CALENDAR_ACCOUNT_REQUEST';
export const ADD_CALENDAR_ACCOUNT_SUCCESS = 'ADD_CALENDAR_ACCOUNT_SUCCESS';
export const ADD_CALENDAR_ACCOUNT_ERROR = 'ADD_CALENDAR_ACCOUNT_ERROR';
export const REMOVE_CALENDAR_ACCOUNT_REQUEST = 'REMOVE_CALENDAR_ACCOUNT_REQUEST';
export const REMOVE_CALENDAR_ACCOUNT_SUCCESS = 'REMOVE_CALENDAR_ACCOUNT_SUCCESS';
export const REMOVE_CALENDAR_ACCOUNT_ERROR = 'REMOVE_CALENDAR_ACCOUNT_ERROR';
export const CALENDAR_SIGN_IN_CALLBACK_REQUEST = 'CALENDAR_SIGN_IN_CALLBACK_REQUEST';
export const CALENDAR_SIGN_IN_CALLBACK_SUCCESS = 'CALENDAR_SIGN_IN_CALLBACK_SUCCESS';
export const CALENDAR_SIGN_IN_CALLBACK_ERROR = 'CALENDAR_SIGN_IN_CALLBACK_ERROR';
export const GET_ACCOUNTS_FOR_CONFLICTS_CHECK_REQUEST = 'GET_ACCOUNTS_FOR_CONFLICTS_CHECK_REQUEST';
export const GET_ACCOUNTS_FOR_CONFLICTS_CHECK_SUCCESS = 'GET_ACCOUNTS_FOR_CONFLICTS_CHECK_SUCCESS';
export const GET_ACCOUNTS_FOR_CONFLICTS_CHECK_ERROR = 'GET_ACCOUNTS_FOR_CONFLICTS_CHECK_ERROR';

export const GET_ACCOUNT_FOR_SEND_REMINDERS_REQUEST = 'GET_ACCOUNT_FOR_SEND_REMINDERS_REQUEST';
export const GET_ACCOUNT_FOR_SEND_REMINDERS_SUCCESS = 'GET_ACCOUNT_FOR_SEND_REMINDERS_SUCCESS';
export const GET_ACCOUNT_FOR_SEND_REMINDERS_ERROR = 'GET_ACCOUNT_FOR_SEND_REMINDERS_ERROR';

export const PUT_MAILS_FOR_CONFLICT_CHECK_REQUEST = 'PUT_MAILS_FOR_CONFLICT_CHECK_REQUEST';
export const PUT_MAILS_FOR_CONFLICT_CHECK_SUCCESS = 'PUT_MAILS_FOR_CONFLICT_CHECK_SUCCESS';
export const PUT_MAILS_FOR_CONFLICT_CHECK_ERROR = 'PUT_MAILS_FOR_CONFLICT_CHECK_ERROR';

export const PUT_MAIL_FOR_CALENDAR_REMINDER_REQUEST = 'PUT_MAIL_FOR_CALENDAR_REMINDER_REQUEST';
export const PUT_MAIL_FOR_CALENDAR_REMINDER_SUCCESS = 'PUT_MAIL_FOR_CALENDAR_REMINDER_SUCCESS';
export const PUT_MAIL_FOR_CALENDAR_REMINDER_ERROR = 'PUT_MAIL_FOR_CALENDAR_REMINDER_ERROR';

export const GET_BUSY_TIME_REQUEST = 'GET_BUSY_TIME_REQUEST';
export const GET_BUSY_TIME_SUCCESS = 'GET_BUSY_TIME_SUCCESS';
export const GET_BUSY_TIME_ERROR = 'GET_BUSY_TIME_ERROR';

export const fetchCalendarsActions = {
  request: createAction(GET_CALENDARS_REQUEST),
  success: createAction(GET_CALENDARS_SUCCESS),
  error: createAction(GET_CALENDARS_ERROR),
};

export const addCalendarAccountActions = {
  request: createAction(ADD_CALENDAR_ACCOUNT_REQUEST),
  success: createAction(ADD_CALENDAR_ACCOUNT_SUCCESS),
  error: createAction(ADD_CALENDAR_ACCOUNT_ERROR),
};

export const removeCalendarAccountActions = {
  request: createAction(REMOVE_CALENDAR_ACCOUNT_REQUEST),
  success: createAction(REMOVE_CALENDAR_ACCOUNT_SUCCESS),
  error: createAction(REMOVE_CALENDAR_ACCOUNT_ERROR),
};

export const calendarSignInCallbackActions = {
  request: createAction(CALENDAR_SIGN_IN_CALLBACK_REQUEST),
  success: createAction(CALENDAR_SIGN_IN_CALLBACK_SUCCESS),
  error: createAction(CALENDAR_SIGN_IN_CALLBACK_ERROR),
};

export const acсountsForConflictsCheckActions = {
  request: createAction(GET_ACCOUNTS_FOR_CONFLICTS_CHECK_REQUEST),
  success: createAction(GET_ACCOUNTS_FOR_CONFLICTS_CHECK_SUCCESS),
  error: createAction(GET_ACCOUNTS_FOR_CONFLICTS_CHECK_ERROR),
};

export const acсountForSendRemindersActions = {
  request: createAction(GET_ACCOUNT_FOR_SEND_REMINDERS_REQUEST),
  success: createAction(GET_ACCOUNT_FOR_SEND_REMINDERS_SUCCESS),
  error: createAction(GET_ACCOUNT_FOR_SEND_REMINDERS_ERROR),
};

export const putMailsForConfictCheckActions = {
  request: createAction(PUT_MAILS_FOR_CONFLICT_CHECK_REQUEST),
  success: createAction(PUT_MAILS_FOR_CONFLICT_CHECK_SUCCESS),
  error: createAction(PUT_MAILS_FOR_CONFLICT_CHECK_ERROR),
};

export const putMailForCalendarRemindersActions = {
  request: createAction(PUT_MAIL_FOR_CALENDAR_REMINDER_REQUEST),
  success: createAction(PUT_MAIL_FOR_CALENDAR_REMINDER_SUCCESS),
  error: createAction(PUT_MAIL_FOR_CALENDAR_REMINDER_ERROR),
};

export const getBusytimeActions = {
  request: createAction(GET_BUSY_TIME_REQUEST),
  success: createAction(GET_BUSY_TIME_SUCCESS),
  error: createAction(GET_BUSY_TIME_ERROR),
};

export const fetchCalendars = () => async dispatch => {
  dispatch(fetchCalendarsActions.request());

  try {
    const response = await getCalendars();
    dispatch(fetchCalendarsActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchCalendarsActions.error, dispatch)(error);
  }
};

export const addCalendar = ({ contributionId, isCreate }) => async dispatch => {
  dispatch(addCalendarAccountActions.request());

  try {
    const response = await addCalendarAccount({ contributionId, isCreate });
    dispatch(addCalendarAccountActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), addCalendarAccountActions.error, dispatch)(error);
  }
};

export const calendarSignInCallback = code => async dispatch => {
  dispatch(calendarSignInCallbackActions.request());

  try {
    const response = await signInCallback(code);
    dispatch(calendarSignInCallbackActions.success(response));
    const getCalendarListResponse = await getCalendars();
    dispatch(fetchCalendarsActions.success(getCalendarListResponse));
  } catch (error) {
    pipe(get('response'), get('data'), calendarSignInCallbackActions.error, dispatch)(error);
  }
};

export const getAcсountsForConflictsCheck = () => async dispatch => {
  dispatch(acсountsForConflictsCheckActions.request());

  try {
    const response = await getAcсountsToCheckConflicts();
    dispatch(acсountsForConflictsCheckActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), acсountsForConflictsCheckActions.error, dispatch)(error);
  }
};

export const getAcсountForSendReminders = () => async dispatch => {
  dispatch(acсountForSendRemindersActions.request());

  try {
    const response = await getAccountsToSendReminders();
    dispatch(acсountForSendRemindersActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), acсountForSendRemindersActions.error, dispatch)(error);
  }
};

export const removeCalendar = email => async dispatch => {
  dispatch(removeCalendarAccountActions.request());

  try {
    await removeCalendarAccount(email);
    dispatch(removeCalendarAccountActions.success(email));
  } catch (error) {
    pipe(get('response'), get('data'), removeCalendarAccountActions.error, dispatch)(error);
  }
};

export const putMailsForConfictCheck = (emailsArr, startTime, endTime) => async dispatch => {
  dispatch(putMailsForConfictCheckActions.request());

  try {
    const response = await putMailsForConfictCheckRequest(emailsArr);
    dispatch(putMailsForConfictCheckActions.success(response));
    const responseConflictsAccounts = await getAcсountsToCheckConflicts();
    dispatch(acсountsForConflictsCheckActions.success(responseConflictsAccounts));
    dispatch(getBusytimeActions.request(startTime, endTime));
    const responseBusytimes = await getBusyTimeRequest(startTime, endTime);
    dispatch(getBusytimeActions.success(responseBusytimes));
  } catch (error) {
    pipe(get('response'), get('data'), putMailsForConfictCheckActions.error, dispatch)(error);
  }
};

export const putMailForCalendarReminders = email => async dispatch => {
  dispatch(putMailForCalendarRemindersActions.request());

  try {
    const response = await putMailForCalendarRemindersRequest(email);
    dispatch(putMailForCalendarRemindersActions.success(response));
    const responseAccountForReminders = await getAccountsToSendReminders();
    dispatch(acсountForSendRemindersActions.success(responseAccountForReminders));
  } catch (error) {
    pipe(get('response'), get('data'), putMailForCalendarRemindersActions.error, dispatch)(error);
  }
};

export const getBusyTime = (startTime, endTime) => async dispatch => {
  dispatch(getBusytimeActions.request(startTime, endTime));

  try {
    const response = await getBusyTimeRequest(startTime, endTime);
    dispatch(getBusytimeActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), getBusytimeActions.error, dispatch)(error);
  }
};
