import React from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import TextField from 'components/UI/TextField'

const SearchFilter = ({ className, searchFilter, onSearchFilterChange }) => (
  <TextField
    className={className}
    placeholder="Search"
    value={searchFilter}
    onChange={(e) => onSearchFilterChange(e.target.value)}
    onFocus={(e) => e.stopPropagation()}
    startAdornment={
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    }
    endAdornment={
      !!searchFilter && (
        <InputAdornment position="end">
          <IconButton onClick={() => onSearchFilterChange('')} onMouseDown={(e) => e.preventDefault()}>
            <CloseIcon />
          </IconButton>
        </InputAdornment>
      )
    }
  />
)

SearchFilter.propTypes = {
  className: PropTypes.string.isRequired,
  searchFilter: PropTypes.string.isRequired,
  onSearchFilterChange: PropTypes.func.isRequired,
}

export default SearchFilter
