import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/FormUI/Button'
import { useHttp } from 'hooks'

const BookSessionTime = ({ contribution, session, sessionTime, onSessionTimeBooked, mobileView }) => {
  const { request, loading } = useHttp()

  const onBookSession = () => {
    request('/Contribution/BookSessionTime', 'POST', {
      contributionId: contribution.id,
      sessionId: session.id,
      sessionTimeId: sessionTime.id,
    }).then(() => onSessionTimeBooked())
  }

  return (
    <Button autoWidth onClick={onBookSession} disabled={loading} mobileView={mobileView}>
      Book Session Time
    </Button>
  )
}

BookSessionTime.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
  }),
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onSessionTimeBooked: PropTypes.func.isRequired,
  mobileView: PropTypes.bool,
}

export default BookSessionTime
