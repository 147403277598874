import { useState, useEffect } from 'react';

import useHttp from './http.hook';

function usePreferences() {
  const { request } = useHttp();
  const [preferences, setPreferences] = useState([]);

  useEffect(() => {
    request('/referencedata/preferences')
      .then(setPreferences)
      /* eslint-disable-next-line */
      .catch(console.dir);
  }, [request]);

  return { preferences };
}

export default usePreferences;
