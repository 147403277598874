import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import {
  mdiDotsVertical,
  mdiHeart,
  mdiMessage,
  mdiBookmark,
  mdiPinOutline,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPinOffOutline,
} from '@mdi/js';
import Linkify from 'react-linkify';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SendIcon from '@material-ui/icons/Send';
import map from 'lodash/map';
import some from 'lodash/some';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import { UserRoles } from 'helpers/constants';
import { colors } from 'utils/styles';
import { addComment, addLike, editPost, removeComment, removeLike } from 'services/community.service';
import EditModal from '../EditModal/EditModal';
import { Attachments } from '../Attachments/Attachments';
import {
  AvatarComponent,
  StyledClickDropDown,
  StyledCommentName,
  StyledContainer,
  StyledDate,
  StyledLikeInfo,
  StyledMessage,
  StyledMetaData,
  StyledName,
  StyledText,
  StyledContainerComment,
  AvatarComponentComment,
  StyledInputWrapper,
  StyledTextAreaWrap,
  StyledCommentsToggle,
  StyledCommentWrap,
  StyledAttachmentsWrap,
  StyledButton,
  StyleTextLink,
  StyledProfileLink,
  StyledRemoveComment,
  StyledTextError,
} from './Post.styled';
import useContribution from '../../../hooks/useContribution';
import * as actions from '../../../../../actions/community';
import Icon from '../../../../../components/UI/Icon';
import ClickDropDown, { StyledDropDownItem } from '../../../../../components/UI/ClickDropDown';
import { useAccount } from '../../../../../hooks';
import { COUNT_POSTS_BY_PAGE } from '../../../../../constants';
import useDiffDate from '../../hooks/useDiffDate';
import { StyledTextArea } from '../CreatePost/CreatePost.styled';
import LinkPreview from '../LinkPreview/LinkPreview';

const ROW_HEIGHT = 20;

const Post = props => {
  const { post } = props;
  const theme = useTheme();
  const [showAllLikes, setShowAllLikes] = useState(false);
  // TODO move to redux
  const [item, setItem] = useState(post);
  const [isMyLike, setIsMyLike] = useState(false);
  const [likeCount, setLikeCount] = useState(item?.likes?.length);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showAllComments, setShowAllComments] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [rows, setRows] = useState(0);
  const [commentPostPending, setCommentPostPending] = useState(false);
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const contribution = useContribution();
  const { user } = useAccount();
  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const isMyPost = user.id === item?.userInfo?.id;
  const { createDataDiff } = useDiffDate(item.createTime, user?.timeZoneId);

  const handleTogglePin = () => {
    editPost({
      ...item,
      userId: item.userInfo.id,
      isPinned: !item.isPinned,
    }).then(() => {
      dispatch(actions.getAllPostsForContribution(`${contribution.id}/1/${COUNT_POSTS_BY_PAGE}`));
    });
  };
  const handleClickEdit = useCallback(() => {
    setModalOpened(true);
  }, [setModalOpened]);
  const handleModalClose = useCallback(() => {
    setModalOpened(false);
  }, [setModalOpened]);

  useEffect(() => {
    setIsMyLike(some(item.likes, { userInfo: { id: user.id } }));
  }, [item.likes, user.id]);

  const handleToggleLike = () => {
    if (isMyLike) {
      const currentLike = find(item.likes, { userInfo: { id: user.id } });
      removeLike(currentLike?.id).then(() => {
        const newLikes = filter(item.likes, like => like.id !== currentLike.id);
        setItem({ ...item, likes: [...newLikes] });
        setIsMyLike(false);
        setLikeCount(likeCount - 1);
      });
    } else {
      addLike({
        postId: item.id,
      }).then(data => {
        setItem({ ...item, likes: [...item.likes, data] });
        setIsMyLike(true);
        setLikeCount(likeCount + 1);
      });
    }
  };
  const handleClickDelete = () => {
    dispatch(actions.deletePostById(item.id)).then(() => {
      dispatch(actions.getAllPostsForContribution(`${contribution.id}/1/${COUNT_POSTS_BY_PAGE}`));
    });
  };
  const handleToggleCommentInput = () => {
    setShowCommentInput(!showCommentInput);
  };

  const handleCommentCreate = (e, { resetForm }) => {
    setCommentPostPending(true);
    addComment({
      text: e.text,
      postId: item.id,
    }).then(data => {
      resetForm({});
      setRows(0);
      setItem({ ...item, comments: [...item.comments, data] });
      setCommentPostPending(false);
    });
  };
  const handleKeyUp = useCallback(
    ({ target: { value } }) => {
      setRows((value.match(/\n/g) || []).length);
    },
    [rows, setRows],
  );
  const handleToggleComments = ({ target: { value } }) => {
    setShowAllComments(!showAllComments);
  };
  const handleToggleShowLikes = () => {
    setShowAllLikes(!showAllLikes);
  };
  const handleRemoveComment = selectComment => {
    removeComment(selectComment.id).then(() => {
      const newCommentsList = filter(item.comments, comments => comments.id !== selectComment.id);
      setItem({ ...item, comments: newCommentsList });
    });
  };
  return (
    <StyledContainer direction="row" xs={12}>
      <Grid container direction="row" justify="space-between">
        <StyledProfileLink to={`/cohealer-about/${item.userInfo.id}/about`}>
          <Box component="div" display="flex">
            <AvatarComponent
              alt={`${item.userInfo.firstName}${item.userInfo.lastName}`}
              src={item.userInfo.avatarUrl}
              mobileView={mobileView}
            >
              {item.userInfo.firstName[0]}
              {item.userInfo.lastName[0]}
            </AvatarComponent>
            <Box component="div">
              <StyledName>
                {item.userInfo.firstName} {item.userInfo.lastName}
                {item.isBubbled && <EmojiObjectsIcon style={{ color: colors.gold }} className="ml-1" />}
              </StyledName>
              <StyledDate>{createDataDiff}</StyledDate>
            </Box>
          </Box>
        </StyledProfileLink>
        <Box display="flex">
          {item?.userInfo?.isCohealer && (
            <span>
              <Icon color={colors.darkOceanBlue} path={mdiBookmark} right="5" valign="top" />
              Coach
            </span>
          )}
          {item?.isPinned && <Icon path={mdiPinOutline} left="20" top="3" size={0.9} />}
          {(isMyPost || isCoach) && (
            <StyledClickDropDown>
              <ClickDropDown icon={mdiDotsVertical}>
                {isCoach && (
                  <StyledDropDownItem onClick={handleTogglePin}>
                    {item?.isPinned ? (
                      <>
                        <Icon color="#a9a9a9" path={mdiPinOffOutline} right="5" size={0.9} />
                        Unpin at the top
                      </>
                    ) : (
                      <>
                        <Icon color="#a9a9a9" path={mdiPinOutline} right="5" size={0.9} />
                        Pin to top
                      </>
                    )}
                  </StyledDropDownItem>
                )}
                {isMyPost && (
                  <StyledDropDownItem onClick={handleClickEdit}>
                    <Icon color="#a9a9a9" path={mdiPencilOutline} right="5" size={0.9} />
                    Edit
                  </StyledDropDownItem>
                )}
                {(isMyPost || isCoach) && (
                  <StyledDropDownItem onClick={handleClickDelete}>
                    <Icon color="#a9a9a9" path={mdiDeleteOutline} right="5" size={0.9} />
                    Delete
                  </StyledDropDownItem>
                )}
              </ClickDropDown>
            </StyledClickDropDown>
          )}
        </Box>
      </Grid>
      <StyledText>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <StyleTextLink target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </StyleTextLink>
          )}
        >
          {item.text.split('\n').map(str => (
            <>
              {str} <br />
            </>
          ))}
        </Linkify>
      </StyledText>
      <StyledAttachmentsWrap>
        <Attachments attachments={item.attachments} />
      </StyledAttachmentsWrap>
      {!isEmpty(item?.links) && map(item?.links, (item, index) => <LinkPreview key={index} item={item} />)}
      {item.likes.length > 1 && (
        <StyledLikeInfo onClick={handleToggleShowLikes}>
          {showAllLikes
            ? map(
                item.likes,
                (like, index) =>
                  `${like.userInfo?.firstName} ${like.userInfo?.lastName}${
                    index + 1 >= item.likes.length ? '.' : ','
                  }  `,
              )
            : `${item.likes[0]?.userInfo?.firstName} ${item.likes[0]?.userInfo?.lastName} and ${
                item.likes.length - 1
              } liked this`}
        </StyledLikeInfo>
      )}

      {item.likes.length === 1 && (
        <StyledLikeInfo onClick={handleToggleShowLikes}>
          {item.likes[0]?.userInfo?.firstName} {item.likes[0]?.userInfo?.lastName} liked this
        </StyledLikeInfo>
      )}

      <StyledMetaData container>
        <StyledMessage onClick={handleToggleCommentInput}>
          <Icon color="#a9a9a9" path={mdiMessage} right="5" valign="top" />
          <span>Reply</span>
        </StyledMessage>
        <div>
          <Icon
            onClick={handleToggleLike}
            color={isMyLike ? '#116582' : '#a9a9a9'}
            path={mdiHeart}
            right="5"
            valign="top"
          />
          <span>{likeCount}</span>
        </div>
      </StyledMetaData>
      {item.comments.length > 1 && (
        <StyledCommentsToggle onClick={handleToggleComments}>
          {showAllComments ? 'Hide comments' : `View all ${item.comments.length} comments`}
        </StyledCommentsToggle>
      )}
      <div>
        {showAllComments ? (
          map(item.comments, comment => {
            return (
              <StyledCommentWrap>
                {isCoach && (
                  <StyledRemoveComment onClick={() => handleRemoveComment(comment)}>
                    <Icon color="#a9a9a9" path={mdiDeleteOutline} right="5" top="-4" size={1} />
                  </StyledRemoveComment>
                )}
                <StyledCommentName>
                  {comment?.userInfo?.firstName} {comment?.userInfo?.lastName}{' '}
                </StyledCommentName>
                {comment.text.split('\n').map(str => (
                  <>
                    {str} <br />
                  </>
                ))}
              </StyledCommentWrap>
            );
          })
        ) : (
          <StyledCommentWrap>
            {item.comments[0] && isCoach && (
              <StyledRemoveComment onClick={() => handleRemoveComment(item.comments[0])}>
                <Icon color="#a9a9a9" path={mdiDeleteOutline} right="5" top="-4" size={1} />
              </StyledRemoveComment>
            )}
            <StyledCommentName>
              {item.comments[0]?.userInfo?.firstName} {item.comments[0]?.userInfo?.lastName}{' '}
            </StyledCommentName>
            {item.comments[0]?.text.split('\n').map(str => (
              <>
                {str} <br />
              </>
            ))}
          </StyledCommentWrap>
        )}
      </div>
      {showCommentInput && (
        <Formik
          enableReinitialize
          initialValues={{
            text: '',
          }}
          validateOnMount
          isInitialValid={false}
          onSubmit={handleCommentCreate}
          validationSchema={Yup.object().shape({
            text: Yup.string()
              .strict(true)
              .min(0)
              .max(500, 'Cannot exceed 500 characters')
              .required('This is a required field'),
          })}
          id="post-comment-form"
        >
          {formProps => {
            return (
              <StyledContainerComment additionalHeight={rows * ROW_HEIGHT} container direction="row">
                <AvatarComponentComment
                  alt={`${user.firstName} ${user.lastName}`}
                  src={user.avatarUrl}
                  mobileView={mobileView}
                >
                  {`${user.firstName && user.firstName[0]}${user.lastName && user.lastName[0]}`}
                </AvatarComponentComment>
                <Grid container direction="column">
                  <Grid container direction="row">
                    <StyledInputWrapper
                      container
                      direction="row"
                      justify="space-between"
                      additionalHeight={rows * ROW_HEIGHT}
                    >
                      <StyledTextAreaWrap item xs={12} className="ml-3" style={{ height: '100%' }}>
                        <StyledTextArea
                          placeholder="What’s on your mind..."
                          component="textarea"
                          name="text"
                          fullWidth
                          onKeyUp={handleKeyUp}
                        />
                      </StyledTextAreaWrap>
                    </StyledInputWrapper>
                    <StyledButton
                      disabled={commentPostPending || !formProps.isValid}
                      autoWidth
                      type="submit"
                      id="post-create-form"
                      onClick={formProps.handleSubmit}
                    >
                      {commentPostPending ? (
                        <CircularProgress
                          size={36}
                          className="chat-messages-history-list__load-more-messages-progress"
                        />
                      ) : (
                        <SendIcon fontSize="small" style={{ color: '#fff' }} className="smoked-gary-color hoverable" />
                      )}
                    </StyledButton>
                  </Grid>
                  {formProps.errors.text && formProps.dirty && (
                    <StyledTextError mobileView={mobileView}>{formProps.errors.text}</StyledTextError>
                  )}
                </Grid>
              </StyledContainerComment>
            );
          }}
        </Formik>
      )}
      {modalOpened && <EditModal isOpen={modalOpened} onModalClose={handleModalClose} item={item} />}
    </StyledContainer>
  );
};
Post.propTypes = {
  item: PropTypes.shape({}),
};

export default Post;
