import React from 'react';
import styled from 'styled-components';

import logo from 'assets/logo.png';

const CohereLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  width: 10rem;
  height: 90px;
`;

const Logo = () => {
  return <CohereLogo src={logo} />;
};

export default Logo;
