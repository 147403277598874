import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useAccount } from 'hooks';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { uniqBy } from 'lodash';
import { TIMEZONES } from 'constants.js';
import { colors } from 'utils/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { LabelText, SimpleText } from 'components/UI/Text/TextStyles';
import Modal from 'components/UI/Modal';
import Loader from 'components/UI/Loader';
import { getTimePeriodsForAvailability, DATE_FORMATS } from 'utils/datesAndMoney';
import { getClientContributionTimes, getCoachContributionTimes } from 'services/contributions.service';

const StyledLink = styled(Link)`
  color: ${colors.lightBrown};
`;
const StyledSimpleText = styled(SimpleText)`
  margin: 25px 10px 25px 0px;
  display: inline-block;
`;
const StyledLabelText = styled(LabelText)`
  margin: 25px 0;
  display: inline-block;
`;
const StyledTimeSlots = styled.div`
  display: inline-block;
  width: 240px;
  height: 54px;
  border: 1px solid ${colors.stroke};
  border-radius: 8px;
  margin: 5px;

  &:hover {
    cursor: pointer;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
      width: 145px;
  `}

  ${({ isSelected }) =>
    isSelected &&
    `
    border-color: ${colors.lightBrown};
    color: ${colors.lightBrown};
  `}

  ${({ isBooked }) =>
    isBooked &&
    `
    color: ${colors.stroke};
    
    &:hover {
      cursor: default;
    }
  `}
`;
const StyledTimeSlotValue = styled.div`
  margin: 15px 0;
  text-align: center;
`;

const SlotsModal = React.memo(
  ({
    isOpen,
    onClose,
    onSubmit,
    selectedDate,
    isCoach,
    contributionId,
    contributionTimeZoneId,
    serviceProviderName,
    duration,
    title,
    isSelectable,
  }) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
    const request = isCoach ? getCoachContributionTimes : getClientContributionTimes;
    const [slots, setSlots] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const formattedTime = selectedSlot
      ? `${moment(selectedSlot.start).format(DATE_FORMATS.DATE_TIME)} - ${moment(selectedSlot.end).format(
          DATE_FORMATS.TIME_12,
        )}`
      : '';

    useEffect(() => {
      Promise.all([request(contributionId, 0), request(contributionId, 30)]).then(([zeroSlots, halfSlots]) => {
        const formattedSlots = zeroSlots
          .concat(halfSlots)
          .filter(slot => moment(slot.startTime).isSame(selectedDate, 'day'))
          .sort((a, b) => moment(a.startTime).diff(moment(b.startTime), 'minutes'));
        const events = getTimePeriodsForAvailability({
          availabilityPeriods: uniqBy(formattedSlots, 'id'),
          duration,
          serviceProviderName,
        });
        setSlots(events);
        setLoading(false);
        const firstFreeSlot = events.find(s => !s.isBooked);
        setSelectedSlot(firstFreeSlot);
      });
    }, []);
    const buttons = [];
    const { user } = useAccount();
    const titleText = isSelectable
      ? 'Select your session time'
      : 'These are the session times your clients can self-book';

    return (
      <>
        <Modal
          title={titleText}
          isOpen={isOpen}
          onCancel={onClose}
          submitTitle="Confirm"
          cancelTitle="Cancel"
          buttons={buttons}
          onSubmit={() => onSubmit(selectedSlot)}
          hiddenCancel={!isSelectable}
          disableConfirm={!isSelectable}
        >
          {isSelectable && (
            <StyledLabelText>
              Please select your session time for {title} with {serviceProviderName}
            </StyledLabelText>
          )}
          {loading && <Loader relative withoutTopOffset />}
          <div>
            {slots.map(slot => {
              return (
                <StyledTimeSlots
                  mobileView={mobileView}
                  key={slot.id}
                  isBooked={slot.isBooked}
                  onClick={() => !slot.isBooked && setSelectedSlot(slot)}
                  isSelected={selectedSlot && selectedSlot.id === slot.id}
                >
                  <StyledTimeSlotValue>{moment(slot.start).format(DATE_FORMATS.TIME_12)}</StyledTimeSlotValue>
                </StyledTimeSlots>
              );
            })}
          </div>
          <StyledSimpleText>{formattedTime}</StyledSimpleText>
          <StyledSimpleText>{TIMEZONES[user?.timeZoneId || contributionTimeZoneId]}</StyledSimpleText>
          {user?.timeZoneId && <StyledLink to="/account/profile">Update My Timezone</StyledLink>}
        </Modal>
      </>
    );
  },
);

SlotsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
  isCoach: PropTypes.bool,
  contributionId: PropTypes.string.isRequired,
  contributionTimeZoneId: PropTypes.string,
  serviceProviderName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  isSelectable: PropTypes.bool,
};

SlotsModal.defaultProps = {
  isCoach: false,
  isSelectable: true,
};

export default SlotsModal;
