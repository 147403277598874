export const getPaymentOptionsView = paymentOption => {
  const optionsCollection = {
    EntireCourse: 'Entire Course',
    PerSession: 'Per Session',
    MonthlyMembership: 'Monthly Membership',
    SplitPayments: 'Split Payments',
    SessionsPackage: 'Sessions Package',
    MonthlySessionSubscription: 'Monthly Subscription',
    MembershipPackage: 'Membership package',
    YearlyMembership: 'Yearly Membership',
  };

  return optionsCollection[paymentOption] || 'Unknown';
};
