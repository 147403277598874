import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const getCalendars = () => axiosInstance.get('/ExternalCalendar/GetAllExternalCalendarAccounts').then(get('data'));

const addCalendarAccount = ({ contributionId, isCreate }) => {
  let params = '';

  if (contributionId) {
    params = `?contributionId=${contributionId}`;
  }

  if (isCreate) {
    params = `?isCreated=true`;
  }

  return axiosInstance
    .get(`/ExternalCalendar/AddExternalCalendarAccount${params}`)
    .then(response => {
      if (response.data) {
        // const re = "https://dev.cohere.live";
        // const preparedLocalLink = response.data.replace(
        //   re,
        //   "http://localhost:3000"
        // );
        window.location.replace(response.data);
      }
    })
    .catch(error => console.log(error));
};

const removeCalendarAccount = email =>
  axiosInstance.delete(`/ExternalCalendar/RemoveExternalCalendarAccount?emailAddress=${email}`).then(get('data'));

const getAcсountsToCheckConflicts = () =>
  axiosInstance.get(`/ExternalCalendar/GetExternalCalendarAccountsToCheckConflicts`).then(get('data'));

const signInCallback = code =>
  axiosInstance.get(`/ExternalCalendar/ExternalCalendarSignInCallback?code=${code}`).then(get('data'));

const getAccountsToSendReminders = () =>
  axiosInstance.get(`/ExternalCalendar/GetExternalCalendarAccountToSendReminders`).then(get('data'));

const putMailForCalendarRemindersRequest = email =>
  axiosInstance
    .put(`/ExternalCalendar/EnableEventRemindersForExternalCalendar?emailAddress=${email}`)
    .then(get('data'));

const putMailsForConfictCheckRequest = emailsArr =>
  axiosInstance.put('/ExternalCalendar/EnableConflictsCheckForExternalCalendars', emailsArr).then(get('data'));

const getBusyTimeRequest = (startTime, endTime) =>
  axiosInstance.get(`/ExternalCalendar/GetBusyTime?startTime=${startTime}&endTime=${endTime}`).then(get('data'));

export {
  getCalendars,
  addCalendarAccount,
  removeCalendarAccount,
  getAcсountsToCheckConflicts,
  getAccountsToSendReminders,
  putMailForCalendarRemindersRequest,
  putMailsForConfictCheckRequest,
  getBusyTimeRequest,
  signInCallback,
};
