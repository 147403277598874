import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { useAccount } from 'hooks';

import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import { formatMoney } from 'utils/datesAndMoney';
import { colors } from 'utils/styles';
import { toggleAffiliateEnrollment, getEnrollmentStatus } from 'services/affiliate.service';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import Button from '../../../../../components/FormUI/Button';
import InvitationCoaches from '../Invitation/InvitationCoaches';

import './AffiliateSummaryCard.scss';

const StyledAffiliateDescription = styled.div`
  text-align: center;
`;

const StyledEnrollmentButton = styled(Button)`
  min-width: 100%;
  margin-bottom: 30px;
`;

const StyledGetPaidButton = styled(Button)`
  margin-top: 30px;
  min-width: 100%;
`;

const StyledButtonsGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

const StyledLink = styled.a`
  color: ${colors.darkOceanBlue};
`;

const AffiliateSummaryCard = ({ incomes, onGetPaidClick }) => {
  const { user } = useAccount();
  const [isEnrolled, setIsEnrolled] = useState(true);
  const [showTerms, setShowTerms] = useState(false);
  useEffect(() => {
    getEnrollmentStatus().then(enrollmentStatus => setIsEnrolled(enrollmentStatus));
  }, [setIsEnrolled]);

  const handlerEnrollClick = useCallback(() => {
    toggleAffiliateEnrollment().then(() => setIsEnrolled(prevIsEnrolled => !prevIsEnrolled));
  }, [setIsEnrolled]);
  const displayGetPaid = user.payoutsEnabled && isEnrolled;
  const titleText = `You are ${isEnrolled ? '' : 'un'}enrolled in the Cohere Affiliate program.`;
  const referredCoaches = `${incomes?.referralsCount} Coach${incomes?.referralsCount === 1 ? '' : 'es'} Referred`;
  const referredCoachesWithSales = `${incomes?.referralsWithSalesCount} Coach${
    incomes?.referralsWithSalesCount === 1 ? '' : 'es'
  } with Sales`;

  return (
    <Grid item xs={10} classes={{ root: 'card-container' }}>
      <Card maxHeight>
        <CardHeader>
          <Grid container item md={12} xs={12}>
            <Grid container item md={9} xs={12}>
              <PageTitleSecond>{titleText}</PageTitleSecond>
            </Grid>
            <Grid container item md={3} xs={12} justify="center" alignContent="center">
              <InvitationCoaches />
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody className="card-body-container">
          <Grid container item md={12} xs={12}>
            <Grid container item md={9} xs={12}>
              {isEnrolled && incomes && (
                <>
                  <Grid container item md={12} xs={12}>
                    <div>{referredCoaches}</div>
                  </Grid>
                  <Grid container item md={12} xs={12}>
                    <div>{referredCoachesWithSales}</div>
                  </Grid>
                  <Grid container item md={12} xs={12}>
                    <div>${formatMoney(incomes.totalRevenue)} USD Affiliate Revenue Earned</div>
                  </Grid>
                  <Grid container item md={12} xs={12}>
                    <div>${formatMoney(incomes.paidOutRevenue)} USD Cash Paid Out</div>
                  </Grid>
                  <Grid container item md={12} xs={12}>
                    <div>${formatMoney(incomes.inEscrowRevenue)} USD Available in 60 Days or Less</div>
                  </Grid>
                  <Grid container item md={12} xs={12}>
                    <div>${formatMoney(incomes.availableToPayoutRevenue)} USD Cash Available</div>
                  </Grid>
                </>
              )}
            </Grid>
            <StyledButtonsGrid container item md={3} xs={12}>
              <Grid container item md={12} xs={12} justify="center" alignContent="center">
                <StyledEnrollmentButton onClick={handlerEnrollClick}>
                  {isEnrolled ? 'Unenroll' : 'Enroll'}
                </StyledEnrollmentButton>
              </Grid>
              <Grid container item md={12} xs={12} justify="center" alignContent="center">
                <StyledAffiliateDescription>Earn up to $500 Per Coach Referral.</StyledAffiliateDescription>
              </Grid>
              <Grid container item md={12} xs={12} justify="center" alignContent="center">
                <StyledAffiliateDescription>Terms: 50% Revenue Split for 12 months.</StyledAffiliateDescription>
              </Grid>
              <Grid container item md={12} xs={12} justify="center" alignContent="center">
                <StyledLink
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    setShowTerms(true);
                  }}
                >
                  Terms and Conditions
                </StyledLink>
              </Grid>
              {displayGetPaid && (
                <Grid container item md={12} xs={12} justify="center" alignContent="center">
                  <StyledGetPaidButton onClick={onGetPaidClick}>Get Paid</StyledGetPaidButton>
                </Grid>
              )}
            </StyledButtonsGrid>
          </Grid>
        </CardBody>
      </Card>
      <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />
    </Grid>
  );
};

AffiliateSummaryCard.propTypes = {
  incomes: PropTypes.shape({
    referralsCount: PropTypes.number,
    referralsWithSalesCount: PropTypes.number,
    availableToPayoutRevenue: PropTypes.number,
    totalRevenue: PropTypes.number,
    inEscrowRevenue: PropTypes.number,
    paidOutRevenue: PropTypes.number,
  }),
  onGetPaidClick: PropTypes.func,
};

export default AffiliateSummaryCard;
