import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

export const addPublicFile = data =>
  axiosInstance
    .post('/Content/AddPublicFile', data, {
      'Content-Type': 'multipart/form-data',
    })
    .then(get('data'));

export const addFileToSession = (data, handleUpdateProgress) =>
  axiosInstance
    .post('/Content/AddAttachmentToExisted', data, {
      'Content-Type': 'multipart/form-data',
      onUploadProgress: progressEvent => {
        handleUpdateProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
      },
    })
    .then(get('data'));
