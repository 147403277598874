import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'components/UI/Loader';
import * as R from 'ramda';

import NoteModal from 'pages/ContributionView/components/NoteModal/NoteModalContainer';
import { IconAttach } from 'components/Icons/IconAttach';
import { useSessionAttachments } from 'hooks/useSessionAttachments';
import { isEmptyOrNil } from 'utils/utils';

import './BottomPanel.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

const StyledProgressWrap = styled(Box)`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
`;

export const BottomPanel = ({ sessionId, contributionId, attachments }) => {
  const [modalOpened, setModalOpened] = useState(false);

  const {
    progress,
    isFileUpload,
    loading,
    isCoach,
    handleUploadFile,
    handleDownloadFile,
    handleRemoveFile,
  } = useSessionAttachments(sessionId, contributionId, true);

  if (loading) {
    return <Loader />;
  }

  const handleOpenModal = () => {
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
  };

  return (
    <div className="session-attachments position-relative mt-4">
      <span className="h4">Attachments</span>
      <span tabIndex="0" role="button" className="h4 notes" onClick={handleOpenModal} onKeyPress={handleOpenModal}>
        <span>Personal Notes</span>
        <span className="ml-3 session-empty-panel-content">Open Notes</span>
      </span>
      <div className="mt-2 attached-file-list">
        {isEmptyOrNil(attachments) ? (
          <span className="session-empty-panel-content">None</span>
        ) : (
          attachments.map(
            ({ id: documentId, contentType, documentKeyWithExtension, documentOriginalNameWithExtension }, idx) => (
              <div key={documentId} className="d-flex align-items-center">
                <Button
                  classes={{
                    root: 'text-lowercase regular-tex download-attach',
                  }}
                  onClick={handleDownloadFile}
                  disableRipple
                  data-document-id={documentId}
                  data-document-name={documentOriginalNameWithExtension}
                  data-content-type={contentType}
                >
                  <span>{`${R.inc(idx)}.`}</span>
                  <span className="ml-2">{documentOriginalNameWithExtension}</span>
                </Button>
                {isCoach && (
                  <CloseIcon
                    fontSize="small"
                    className="cursor-pointer"
                    onClick={handleRemoveFile}
                    data-document-id={documentId}
                    data-document-extension={documentKeyWithExtension}
                  />
                )}
              </div>
            ),
          )
        )}
      </div>
      {isCoach && (
        <>
          <input
            accept="*"
            className="d-none"
            id={`attach-file-${sessionId}`}
            type="file"
            onChange={handleUploadFile}
            multiple
          />
          <label htmlFor={`attach-file-${sessionId}`}>
            <Button
              classes={{
                root: 'position-absolute text-capitalize attach-button',
              }}
              disableRipple
              component="span"
            >
              {isFileUpload ? (
                <StyledProgressWrap position="relative">
                  <CircularProgressWithLabel value={progress} />
                </StyledProgressWrap>
              ) : (
                <IconAttach className="mr-3" />
              )}

              <span className="regular-text">Attach a File</span>
            </Button>
          </label>
        </>
      )}
      {modalOpened && (
        <NoteModal
          noteProps={{
            classId: sessionId,
            contributionId,
          }}
          isOpen={modalOpened}
          onModalClose={handleCloseModal}
          onConfirm={handleCloseModal}
        />
      )}
    </div>
  );
};

BottomPanel.propTypes = {
  number: PropTypes.number.isRequired,
  sessionId: PropTypes.string.isRequired,
  contributionId: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
