import React, { useState, useCallback, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { useTheme, useMediaQuery } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'

import * as bankAccountsService from 'services/bankAccounts.service'
import { redirectTo } from 'services/links'
import { addPublicFile } from 'services/content.service'
import * as contributionActions from 'actions/contributions'

import { IconAttach } from 'components/Icons/IconAttach'
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions'
import { StyledMainSection } from 'components/Containers/CreateContributionContainer'
import MainContainer from 'components/Containers/MainContainer'
import Button from 'components/FormUI/Button'
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage'
import { PageTitleSecond, BodyOrLeftText } from 'components/UI/Text/TextStyles'
import { Card, CardBody, CardHeader } from 'components/UI/Card'

import { useRouter, useHttp, useAccount } from 'hooks'
import { useSaveContribution } from 'hooks/useSaveContribution'

import {
  StyleLabel,
  StyledButtonAsLink,
  StyledLink,
  StyledAttachTermsButton,
  StyledButtonContainer,
  StyledCrossIcon,
  StyledFileName,
  HiddenFileInput,
  StyledCustomTerms,
} from './style'
import Modal from '../../components/UI/Modal.js'

const ACCEPTABLE_FILE_TYPES =
  'application/pdf,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation'

function ReviewAndSubmit({ user, saveContributionToLS, error, loading, contribution }) {
  const { history, query } = useRouter()
  const { request } = useHttp()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    user: { transfersEnabled },
  } = useAccount()

  const [agree, setAgree] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [createdContribId, setCreatedContribId] = useState(null)
  const [documents, setDocuments] = useState({
    termsOfUse: null,
    payment: null,
  })
  const [fileUrl, setFileUrl] = useState(contribution?.customToS)
  const fileInput = React.createRef()
  const uploadFileHandler = useCallback(
    (e) => {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)

      addPublicFile(formData)
        .then((url) => {
          setFileUrl(url)
        })
        .catch(console.dir)
    },
    [setFileUrl]
  )
  const handleResetCustomToS = useCallback(() => {
    setFileUrl(null)
  }, [setFileUrl])
  const handleAttachFileClick = () => {
    if (fileInput) {
      fileInput.current.click()
    }
  }

  const [connectStripeModal, setConnectStripeModal] = useState(false)
  const handleConnectStripeModalClose = useCallback(() => setConnectStripeModal(false), [connectStripeModal])

  useEffect(() => {
    request('/ReferenceData/GetAgreements').then((response) => {
      const termsOfUse = response.find((doc) => doc.agreementType === 'TermsOfUse')
      const payment = response.find((doc) => doc.agreementType === 'Payment')
      setDocuments({ termsOfUse, payment })
    })
  }, [request])

  const redirect = useCallback(
    (action, savedContribution) => {
      if (action.type === contributionActions.UPDATE_CONTRIBUTION_SUCCESS) {
        setCreatedContribId(savedContribution.id)
      }

      if (action.type === contributionActions.CREATE_CONTRIBUTION_SUCCESS) {
        saveContributionToLS({})
        setCreatedContribId(savedContribution.id)
      }
    },
    [createdContribId]
  )

  const { onSave } = useSaveContribution(true, agree, { fileUrl })
  const onSubmit = () => {
    transfersEnabled ? onSave(undefined, redirect) : setConnectStripeModal(true)
  }

  const backClickHandler = useCallback(() => {
    if (query.id) {
      history.push(`/edit-contribution/${query.id}/payment`)
    } else {
      history.push('/create-contribution/payment')
    }
  }, [history, query.id])

  const handleConnectStripeModalSubmit = useCallback(
    () =>
      bankAccountsService
        .verifyStripeAccount()
        .then(({ link }) => redirectTo(link))
        .catch((err) => console.dir(err)),
    []
  )
  if (createdContribId) {
    return <Redirect to={`/contribution-view/${createdContribId}/about`} />
  }

  return (
    <MainContainer>
      <StyledMainSection mobileView={mobileView}>
        <Card mobileView={mobileView}>
          <CardHeader mobileView={mobileView}>
            <PageTitleSecond mobileView={mobileView}>5. Review and Submit</PageTitleSecond>
          </CardHeader>
          <CardBody>
            <BodyOrLeftText>
              {user.firstName}, Great work on creating this Contribution! Upon clicking submit, your Contribution will
              be reviewed as quickly as possible. The majority of Contributions are approved within 24 hours. Should
              your Contribution require any changes to meet our standards, we’ll let you know. To save time or avoid
              delays, please make sure your Contribution meets the permitted use requirements specified in the&nbsp;
              <a href={documents.termsOfUse?.fileUrl}>Terms of Use</a>.
            </BodyOrLeftText>
            <BodyOrLeftText>
              Make sure you are a verified user by completing the verification step&nbsp;
              <StyledButtonAsLink type="button" onClick={handleConnectStripeModalSubmit}>
                here
              </StyledButtonAsLink>
              . Prior to being able to transfer revenue to your bank, you will need to verify your bank account is
              connected (
              <StyledLink href="/account/payment" target="_blank" rel="noopener noreferrer">
                click here to confirm
              </StyledLink>
              ) to avoid any delays in transferring revenue. If you have any questions or need to review the fee
              structure, please do not hesitate to review the&nbsp;
              <StyledLink href={documents.payment?.fileUrl} target="_blank" rel="noopener noreferrer">
                One-to-One, Programs and Courses Payment Agreement
              </StyledLink>
              .
            </BodyOrLeftText>

            <StyleLabel mobileView={mobileView}>
              <Checkbox
                id="create-contribution-agreement"
                color="primary"
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
              />
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  setShowTerms(true)
                }}
              >
                I agree to the Terms of Service
              </a>
            </StyleLabel>

            <StyledCustomTerms>
              <div>
                Optional: You can also attach your own terms unique for your business here. Your clients will agree to
                these terms at checkout.
                <StyledAttachTermsButton
                  invert
                  mobileView={mobileView}
                  type="button"
                  onClick={handleAttachFileClick}
                  disableRipple
                  component="span"
                >
                  <IconAttach className="mr-3" />
                  Attach Your Terms (Optional)
                </StyledAttachTermsButton>
                <HiddenFileInput onChange={uploadFileHandler} ref={fileInput} accept={ACCEPTABLE_FILE_TYPES} />
              </div>

              {fileUrl && (
                <StyledFileName>
                  <div>My Terms of Use</div>
                  <StyledCrossIcon onClick={handleResetCustomToS} />
                </StyledFileName>
              )}
            </StyledCustomTerms>

            {error?.message && <CommonErrorMessage message={error.message} />}
          </CardBody>
        </Card>

        <StyledButtonContainer>
          <Button invert autoWidth type="button" onClick={backClickHandler}>
            Back
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button autoWidth onClick={onSubmit} disabled={loading || !agree}>
            Submit
          </Button>
        </StyledButtonContainer>
      </StyledMainSection>
      <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />
      <Modal
        isOpen={connectStripeModal}
        onCancel={handleConnectStripeModalClose}
        onSubmit={handleConnectStripeModalSubmit}
        hiddenCancel
        title="Account Verification Required"
        submitTitle="Verify"
      >
        Please verify your information for your Contribution to be approved.
      </Modal>
    </MainContainer>
  )
}

ReviewAndSubmit.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  saveContributionToLS: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
}

ReviewAndSubmit.defaultProps = {
  error: null,
}

const mapStateToProps = ({ account, contributions }) => ({
  user: account?.user,
  error: contributions?.error,
  loading: contributions?.loading,
  contribution: contributions.activeContribution,
})

const actions = {
  saveContributionToLS: contributionActions.saveDraftContribution,
}

export default connect(mapStateToProps, actions)(ReviewAndSubmit)
