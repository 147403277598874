import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/FormUI/Button';

const StyledButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: ${({ backButtonTitle }) => (backButtonTitle ? 'space-between' : 'flex-end')};
`;

const Buttons = ({ formId, disabled, backButtonTitle, nextButtonTitle, backClickHandler, onNextClickHandler }) => {
  return (
    <StyledButtonContainer backButtonTitle={backButtonTitle}>
      {backButtonTitle && (
        <>
          <Button invert autoWidth type="button" onClick={backClickHandler}>
            {backButtonTitle}
          </Button>
        </>
      )}

      <Button autoWidth type="submit" form={formId} disabled={disabled} onClick={onNextClickHandler}>
        {nextButtonTitle}
      </Button>
    </StyledButtonContainer>
  );
};

Buttons.propTypes = {
  disabled: PropTypes.bool,
  formId: PropTypes.string.isRequired,
  backButtonTitle: PropTypes.string,
  nextButtonTitle: PropTypes.string,
  backClickHandler: PropTypes.func,
  onNextClickHandler: PropTypes.func,
};

Buttons.defaultProps = {
  disabled: false,
  backButtonTitle: null,
  nextButtonTitle: null,
  backClickHandler: () => {},
  onNextClickHandler: undefined,
};

export default Buttons;
