import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Popup from 'components/Popup/Popup';

import { LabelText, PageSubtitle } from 'components/UI/Text/TextStyles';
import { FormikScrollToError } from 'components/FormikScrollToError';
import { useAccount } from 'hooks';
import { formatMoney } from 'utils/datesAndMoney';
import styled from 'styled-components';
import { AccountForm } from './AccountForm/AccountForm';
import { PAYMENT_OPTIONS } from '../../../../constants';
import { colors } from '../../../../utils/styles';

const StyledLink = styled.a`
  color: ${colors.darkOceanBlue};
  font-weight: 600;
`;

const PAYMENT_MAPPING = {
  [PAYMENT_OPTIONS.PER_YEAR]: 'year',
  [PAYMENT_OPTIONS.PER_MONTH]: 'month',
};

const PAYMENT_COST_MAPPING = {
  [PAYMENT_OPTIONS.PER_YEAR]: paymentInfo => paymentInfo?.membershipInfo?.costs?.YearlyMembership,
  [PAYMENT_OPTIONS.PER_MONTH]: paymentInfo => paymentInfo?.membershipInfo?.costs?.MonthlyMembership,
};

export const PurchaseModalForm = ({
  typeOfPayment,
  setTypeOfPayment,
  summary,
  handleChange,
  setShowTerms,
  errors,
  touched,
  step,
  onCheckoutSessionExpired,
  currentSubscription,
  paymentInfo,
  contribution,
}) => {
  const theme = useTheme();
  const [sessionTimeExpired, setSessionTimeExpired] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { user } = useAccount();

  const {
    paymentInfo: { paymentOptions, membershipInfo },
    customToS,
    serviceProviderName,
  } = contribution;

  const priceWithoutFee = `Price per ${PAYMENT_MAPPING[typeOfPayment]}: $${formatMoney(
    PAYMENT_COST_MAPPING[typeOfPayment](paymentInfo),
  )}`;

  return (
    <>
      <FormikScrollToError>
        <Form id="credit-card">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LabelText mobileView={mobileView}>Payment Summary</LabelText>

              <RadioGroup value={typeOfPayment} onChange={e => setTypeOfPayment(e.target.value)}>
                {paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) && (
                  <FormControlLabel
                    disabled={currentSubscription === PAYMENT_OPTIONS.PER_DAY}
                    value={PAYMENT_OPTIONS.PER_DAY}
                    control={<Radio color="primary" />}
                    label="Daily subscription"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) && (
                  <FormControlLabel
                    disabled={currentSubscription === PAYMENT_OPTIONS.PER_WEEK}
                    value={PAYMENT_OPTIONS.PER_WEEK}
                    control={<Radio color="primary" />}
                    label="Weekly subscription"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && (
                  <FormControlLabel
                    disabled={currentSubscription === PAYMENT_OPTIONS.PER_MONTH}
                    value={PAYMENT_OPTIONS.PER_MONTH}
                    control={<Radio color="primary" />}
                    label="Monthly subscription"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && (
                  <FormControlLabel
                    disabled={currentSubscription === PAYMENT_OPTIONS.PER_YEAR}
                    value={PAYMENT_OPTIONS.PER_YEAR}
                    control={<Radio color="primary" />}
                    label="Yearly subscription"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && (
                  <FormControlLabel
                    disabled={currentSubscription === PAYMENT_OPTIONS.PACKAGE}
                    value={PAYMENT_OPTIONS.PACKAGE}
                    control={<Radio color="primary" />}
                    label={`${membershipInfo.membershipPackage.duration} months subscription with ${membershipInfo.membershipPackage.period} payment`}
                  />
                )}
              </RadioGroup>
            </Grid>
            <Grid item xs={6}>
              <div>
                {summary && (
                  <>
                    <PageSubtitle>{priceWithoutFee}</PageSubtitle>
                    {summary.platformFee > 0 && (
                      <PageSubtitle>Processing fee: ${formatMoney(summary.platformFee)}</PageSubtitle>
                    )}
                    <PageSubtitle>Price due now: ${formatMoney(summary.dueNow)}</PageSubtitle>
                  </>
                )}
              </div>
            </Grid>
            {isEmpty(user) && <AccountForm handleChange={handleChange} errors={errors} touched={touched} step={step} />}
          </Grid>
          <Grid item>
            <span className="mr-1">By clicking Reserve, I agree to the</span>
            <StyledLink
              href="/"
              onClick={e => {
                e.preventDefault();
                setShowTerms(true);
              }}
            >
              Terms and Conditions.
            </StyledLink>
          </Grid>
          {customToS && (
            <Grid item>
              <span className="mr-1">By clicking Reserve, I also agree to {serviceProviderName}'s</span>
              <StyledLink href={customToS}>Terms and Conditions.</StyledLink>
            </Grid>
          )}
        </Form>
      </FormikScrollToError>
      <Popup
        open={sessionTimeExpired}
        title="The checkout time has expired"
        text="The checkout time has expired. Please select your session time again to complete registration."
        onCloseText="Select Session"
        onClose={onCheckoutSessionExpired}
      />
    </>
  );
};

PurchaseModalForm.propTypes = {
  typeOfPayment: PropTypes.string.isRequired,
  setTypeOfPayment: PropTypes.func.isRequired,
  summary: PropTypes.shape({
    price: PropTypes.number,
    platformFee: PropTypes.number,
    dueNow: PropTypes.number,
    dueLater: PropTypes.number,
  }),
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
  step: PropTypes.string.isRequired,
  monthlySessionSubscriptionInfo: PropTypes.shape({
    sessionCount: PropTypes.number,
    duration: PropTypes.number,
    monthlyPrice: PropTypes.number,
  }),
};

PurchaseModalForm.defaultProps = {
  summary: undefined,
  errors: undefined,
  error: undefined,
  paymentIntentCreated: undefined,
  sessionLifeTimeSeconds: undefined,
};
