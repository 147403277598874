import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import VideoButton from 'components/UI/ListButtons/VideoButton'
import EditButton from 'components/UI/ListButtons/EditButton'

import './Card.scss'

const types = {
  ContributionCourse: 'Live Course',
  ContributionOneToOne: '1:1',
  ContributionMembership: 'Membership',
}

const CardContainer = ({
  children,
  isSession,
  isSales,
  id,
  moreLink,
  title,
  subTitle,
  type,
  onLaunchSession,
  hasClosestSession,
}) => {
  return (
    <Card
      classes={{
        root: 'card bs-nobel',
      }}
    >
      <CardHeader
        action={
          onLaunchSession ? (
            <>
              <VideoButton disabled={!hasClosestSession} handleClick={onLaunchSession} />
              <Link to={`/edit-contribution/${id}`}>
                <EditButton />
              </Link>
            </>
          ) : (
            moreLink && (
              <Link to={moreLink}>
                <IconButton aria-label="settings">
                  <MoreHorizIcon />
                </IconButton>
              </Link>
            )
          )
        }
        classes={{
          root: classNames({ 'card__header-sales': isSales }),
          title: 'card__title-name',
          subheader: classNames({ 'card__title-session': isSession }),
          action: classNames('card__action', { 'card__action-sales': isSales }),
        }}
        title={
          !id ? (
            title
          ) : (
            <Link className="card__title-link" to={`/contribution-view/${id}/sessions`}>
              {title}
            </Link>
          )
        }
        subheader={
          <Link className="card__title-link" to={`/contribution-view/${id}/sessions`}>
            {subTitle && `${subTitle}, ${hasClosestSession ? types[type] : ''}`}
          </Link>
        }
      />
      <CardContent
        classes={{
          root: classNames('card__content', { 'card__content-sales': isSales }),
        }}
      >
        {children}
      </CardContent>
    </Card>
  )
}

CardContainer.defaultProps = {
  isSession: false,
  isSales: false,
  hasClosestSession: false,
  id: undefined,
  moreLink: undefined,
  title: '',
  subTitle: '',
  type: '',
  onLaunchSession: undefined,
}

CardContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isSession: PropTypes.bool,
  isSales: PropTypes.bool,
  hasClosestSession: PropTypes.bool,
  id: PropTypes.string,
  moreLink: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  type: PropTypes.string,
  onLaunchSession: PropTypes.func,
}

export default CardContainer
