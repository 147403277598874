import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { isNil, sortBy } from 'lodash';
import * as Yup from 'yup';
import { useTheme, useMediaQuery, Box } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { ImageCompressor } from 'image-compressor';

import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import MainContainer from 'components/Containers/MainContainer';
import * as contributionActions from 'actions/contributions';
import * as pageActions from 'actions/page';
import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import Button from 'components/FormUI/Button';
import Loader from 'components/UI/Loader';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { useHttp, usePreferences, useRouter, useAccount } from 'hooks';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { toRem, colors } from 'utils/styles';
import { dataURLtoFile } from 'utils/utils';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { ContributionType } from 'helpers/constants';
import { Switch } from '../../components/switch/style';
import Buttons from './components/Buttons';
import { TOOLTIP } from '../../constants';

const StyledTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  ${({ mobileView }) => mobileView && `font-size: 16px`}
`;

const StyledExplainText = styled.div`
  font-size: ${toRem(16)};
  line-height: 1.25;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;

const StyledButtonClicked = styled.button`
  background-color: white;
  padding: 16px;
  font-size: ${toRem(18)};
  font-weight: 700;
  border: solid 1px #dadada;
  border-radius: 4px;
  line-height: 1.33;
  letter-spacing: 0.12px;
  width: 100%;
  color: black;
  cursor: pointer;
  outline: none;

  &:disabled {
    background-color: #ededed;
    color: #9b9b9b;
    border: none;
    cursor: initial;
  }

  ${({ active }) =>
    active &&
    css`
      border: solid 1px ${colors.darkOceanBlue};
      background: ${colors.darkOceanBlue};
      color: white;
      outline: none !important;
    `}
`;

const Image = styled.img`
  background-color: #f5f5f5;
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  border: solid 1px #dadada;
`;

const ImagePlaceholder = styled.div`
  position: relative;
  background-color: #f5f5f5;
  width: 100%;
  min-height: 200px;

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${colors.fadedPurple};
    `}
`;

const FlexImagePlaceholder = styled(ImagePlaceholder)`
  display: flex;
`;

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

const useStyles = makeStyles({
  row: {
    marginBottom: 20,
  },
  radioGroupRoot: {
    flexDirection: 'row',
  },
});

const StyledSelect = styled(Select)`
  width: calc(100% - 33px);
`;
const StyledSwitchWrap = styled(Box)`
  display: flex;
  align-items: center;
`;
const StyledSwitch = styled(Switch)`
  margin: 0 10px 0 0;
`;

const BasicInformation = () => {
  const dispatch = useDispatch();
  const { user } = useAccount();
  const saveContribution = useCallback((...args) => dispatch(contributionActions.saveContribution(...args)), [
    dispatch,
  ]);
  const saveContributionToLS = useCallback((...args) => dispatch(contributionActions.saveDraftContribution(...args)), [
    dispatch,
  ]);
  const changeHeader = useCallback((...args) => dispatch(pageActions.changeHeader(...args)), [dispatch]);
  const contributionFormRef = useRef();
  const [redirectTo, setRedirectTo] = useState(undefined);
  const [imageLoading, setImageLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const { contribution, loading } = useSelector(({ contributions }) => ({
    contribution: contributions.activeContribution,
    loading: contributions.loading,
  }));

  const { request, loading: hookLoading } = useHttp();
  const { preferences } = usePreferences();
  const { history, query } = useRouter();
  const classes = useStyles();
  const { type: prevContributionType } = useContribution();
  const fileInput = React.createRef();

  const isEditing = !!query.id;
  const saveHandler = isEditing ? saveContribution : saveContributionToLS;
  const { onSave: onSaveContribution } = useSaveContribution(isEditing);

  const contributionClickHandler = useCallback(
    (type, setFieldValue) => () => {
      setFieldValue('type', type);
    },
    [],
  );
  const uploadFileHandler = useCallback(
    (setFieldValue, setImageLoading) => e => {
      setImageLoading(true);
      const imageCompressor = new ImageCompressor();
      const compressorSettings = {
        toWidth: 640,
        toHeight: 350,
        mimeType: 'image/png',
        mode: 'strict',
        quality: 0.6,
      };
      const formData = new FormData();

      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      if (!files || !files[0]) {
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const imageSrc = reader.result;
        imageCompressor.run(imageSrc, compressorSettings, compressedSrc => {
          formData.append('file', dataURLtoFile(compressedSrc));
          request('/content/addpublicimage', 'POST', formData, {
            'Content-Type': 'multipart/form-data',
          })
            .then(image => {
              setFieldValue('file', image);
            })
            .catch(console.dir)
            .finally(() => setImageLoading(false));
        });
      };
      reader.readAsDataURL(files[0]);
    },
    [request],
  );

  const submitHandler = useCallback(
    values => {
      setErrors([]);
      const data = {
        ...values,
        isLive: values.isLive === 'live' && values.type === ContributionType.contributionCourse,
        categories: values.categories,
        previewContentUrls: [values.file],
        userId: user.id,
      };

      if ([ContributionType.contributionOneToOne, ContributionType.contributionLocalEvent].includes(values.type)) {
        data.isLive = true;
      }

      delete data.file;

      changeHeader(data.title);

      const newContribution = {
        ...contribution,
        ...data,
      };

      if (!isEditing && newContribution.sessions && prevContributionType !== newContribution.type) {
        delete newContribution.sessions;
      }
      onSaveContribution(newContribution);
      if (isEditing) {
        return history.push(redirectTo);
      }

      saveHandler(newContribution)
        .then(() => {
          history.push(redirectTo);
        })
        .catch(err => {
          setErrors(err?.response?.data?.errors);
        });
    },
    [
      contribution,
      changeHeader,
      saveHandler,
      onSaveContribution,
      redirectTo,
      history,
      isEditing,
      prevContributionType,
      setErrors,
    ],
  );
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Formik
      innerRef={contributionFormRef}
      enableReinitialize
      initialValues={{
        title: contribution?.title || '',
        categories: contribution?.categories || [],
        isLive: isNil(contribution?.isLive) ? 'live' : contribution.isLive === false ? 'self-paced' : 'live',
        type: contribution?.type || ContributionType.contributionCourse,
        file: (contribution?.previewContentUrls && contribution?.previewContentUrls[0]) || null,
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().strict(true).min(3).max(100).required('This is a required field'),
        categories: Yup.array().of(Yup.string()).min(1, 'Please add a category').required(),
        type: Yup.mixed()
          .oneOf([
            ContributionType.contributionCourse,
            ContributionType.contributionWebinar,
            ContributionType.contributionOneToOne,
            ContributionType.contributionLocalEvent,
            ContributionType.contributionMembership,
          ])
          .required('This is a required field'),
        isLive: Yup.mixed().oneOf(['live', 'self-paced']),
      })}
      onSubmit={submitHandler}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <MainContainer
          sidebarProps={{
            saveHandler: (event, { to }) => {
              setRedirectTo(to);

              if (isEditing) {
                handleSubmit(event);
              } else {
                submitHandler(values);
              }
            },
          }}
        >
          <StyledMainSection mobileView={mobileView}>
            <Card mobileView={mobileView}>
              <CardHeader mobileView={mobileView}>
                <PageTitleSecond mobileView={mobileView}>1. Basic Information</PageTitleSecond>
              </CardHeader>
              <CardBody mobileView={mobileView}>
                <Form id="create-contribution-form">
                  <Grid container alignItems="flex-start" spacing={6}>
                    <Grid item container md={6} xs={12}>
                      <Grid item xs={12} className={classes.row}>
                        <StyledTitle mobileView={mobileView}>Title</StyledTitle>
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          id="contribution-title-input"
                          label="Contribution Title"
                          name="title"
                          counter={50}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.row} container wrap="nowrap">
                        <StyledSelect
                          label="Category (Multiple Choice)"
                          name="categories"
                          multiple
                          disabled={loading || hookLoading}
                          items={sortBy(preferences, ['name']).map(({ name }) => ({
                            title: name,
                            value: name,
                          }))}
                        />
                        <Tooltip
                          title="We recommend up to 3 for a more cohesive look."
                          arrow
                          className="mt-4 ml-2"
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor={colors.lightBrown} />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} className={classes.row}>
                        <StyledTitle mobileView={mobileView}>
                          <span>Contribution Type </span>
                          <Tooltip
                            title="Cohere makes it easy to launch live online services called Contributions. These include live 1:1 services, live group courses, and memberships."
                            arrow
                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          >
                            <InfoIcon htmlColor={colors.lightBrown} />
                          </Tooltip>
                        </StyledTitle>
                      </Grid>

                      <Grid item container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <StyledButtonClicked
                            type="button"
                            disabled={isEditing}
                            active={values.type === ContributionType.contributionCourse}
                            onClick={contributionClickHandler(ContributionType.contributionCourse, setFieldValue)}
                            id="live-course-type-radio"
                          >
                            Live Course
                          </StyledButtonClicked>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <StyledButtonClicked
                            type="button"
                            disabled={isEditing}
                            active={values.type === ContributionType.contributionMembership}
                            onClick={contributionClickHandler(ContributionType.contributionMembership, setFieldValue)}
                          >
                            Membership
                          </StyledButtonClicked>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <StyledButtonClicked
                            type="button"
                            disabled={isEditing}
                            active={values.type === ContributionType.contributionOneToOne}
                            onClick={contributionClickHandler(ContributionType.contributionOneToOne, setFieldValue)}
                            id="one-to-one-type-radio"
                          >
                            1:1 Sessions
                          </StyledButtonClicked>
                        </Grid>
                        {/*<Grid item sm={6} xs={12}>*/}
                        {/*  <StyledButtonClicked*/}
                        {/*    disabled*/}
                        {/*    type="button"*/}
                        {/*    active={values.type === ContributionType.contributionWebinar}*/}
                        {/*    onClick={contributionClickHandler(ContributionType.contributionWebinar, setFieldValue)}*/}
                        {/*  >*/}
                        {/*    Webinar*/}
                        {/*  </StyledButtonClicked>*/}
                        {/*</Grid>*/}

                        {/* {values.type === ContributionType.contributionCourse && (
                              <Grid item xs={12}>
                                <RadioGroup
                                  className={classes.radioGroupRoot}
                                  name="isLive"
                                  value={values.isLive}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    value="live"
                                    control={<Radio color="primary" />}
                                    label="Group Course"
                                  />
                                  <FormControlLabel
                                    disabled
                                    value="self-paced"
                                    control={<Radio color="primary" />}
                                    label="Self-Paced"
                                  />
                                </RadioGroup>
                              </Grid>
                            )} */}
                        {/* <Grid item sm={6} xs={12}>
                              <StyledButtonClicked
                                disabled
                                type="button"
                                active={
                                  values.type ===
                                  ContributionType.contributionLocalEvent
                                }
                                onClick={contributionClickHandler(
                                  ContributionType.contributionLocalEvent,
                                  setFieldValue
                                )}
                              >
                                Local Event
                              </StyledButtonClicked>
                            </Grid> */}
                      </Grid>
                    </Grid>

                    <Grid item container md={6} xs={12}>
                      <Grid item xs={12} className={classes.row}>
                        <StyledTitle mobileView={mobileView}>
                          Display Image
                          <Tooltip
                            title="We recommend adding either a picture of yourself, creating an image with the title of your contribution using a free platform such as canva.com or simply adding your logo!"
                            arrow
                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          >
                            <InfoIcon htmlColor={colors.lightBrown} />
                          </Tooltip>
                        </StyledTitle>
                      </Grid>
                      <Grid item xs={12} className={classes.row}>
                        <StyledExplainText>
                          Your clients see this when they purchase and/or view your Contribution.
                        </StyledExplainText>
                      </Grid>
                      <Grid item xs={12} className={classes.row}>
                        <Button
                          invert
                          mobileView={mobileView}
                          type="button"
                          onClick={() => {
                            if (fileInput) {
                              fileInput.current.click();
                            }
                          }}
                        >
                          Attach an Image
                        </Button>
                        <HiddenFileInput
                          onChange={uploadFileHandler(setFieldValue, setImageLoading)}
                          ref={fileInput}
                          accept="image/png,image/gif,image/jpeg"
                        />
                      </Grid>
                      {!imageLoading && (
                        <Grid item xs={12}>
                          {values.file ? <Image src={values.file} /> : <ImagePlaceholder />}
                        </Grid>
                      )}
                      {imageLoading && (
                        <Grid item xs={12}>
                          <FlexImagePlaceholder>
                            <Loader relative withoutTopOffset flex={1} />
                          </FlexImagePlaceholder>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Form>

                {errors.map(({ errorMessage }) => (
                  <CommonErrorMessage key={errorMessage} message={errorMessage} />
                ))}
              </CardBody>
            </Card>

            <Buttons
              nextButtonTitle="Save and Next"
              formId="create-contribution-form"
              onNextClickHandler={event => {
                setRedirectTo(query.id ? `/edit-contribution/${query.id}/details` : '/create-contribution/details');
                if (isEditing) {
                  handleSubmit(event);
                }
              }}
            />
          </StyledMainSection>
        </MainContainer>
      )}
    </Formik>
  );
};

BasicInformation.propTypes = {};

BasicInformation.defaultProps = {};

export default BasicInformation;
