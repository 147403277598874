import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

const CustomEditIcon = () => (
  <IconButton color="primary" aria-label="edit picture" component="span">
    <EditIcon />
  </IconButton>
)

export default CustomEditIcon
