import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';

import { SuccessfulPurchaseModal } from 'components/Modals/SuccessfulPurchaseModal';
import * as contributionActions from 'actions/contributions';
import Button from 'components/FormUI/Button';
import { fetchClientContribution } from 'actions/contributions';
import { useAccount, useHttp } from 'hooks';
import { UserRoles, PaymentStatus } from 'helpers/constants';
import PurchaseModal from '../PurchaseModal';
import ProceedModal from '../ProceedModal';
import RefuseModal from '../RefuseModal';
import useContribution from '../../hooks/useContribution';

const StyledButton = styled(Button)`
  margin-left: 20px;
  min-width: 128px;
  max-width: 150px;
`;

const PurchaseModalsAndButtonsComponent = () => {
  const contribution = useSelector(s => s.contributions.activeContribution);
  const { paymentInfo, clientPackages } = contribution;
  const { user, currentRole } = useAccount();
  const { id, type, purchaseStatus } = useContribution();
  const { request } = useHttp();
  const dispatch = useDispatch();
  const [isPurchaseModalShowed, setPurchaseModalShowed] = useState(false);
  const [isProceedModalShowed, setProceedModalShowed] = useState(false);
  const [isPackage, setIsPackage] = useState(false);
  const [isMonthlySessionSubscription, setIsMonthlySessionSubscription] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRefuseModalShowed, setRefuseModalShowed] = useState(false);
  const [isOpenSuccessPurchaseModal, setSuccessPurchaseModal] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isNotLoggedUser, setIsNotLoggedUser] = useState(false);
  const [isSubmitPurchaseModalNow, setIsSubmitPurchaseModalNow] = useState(false);
  const isCoach = currentRole === UserRoles.cohealer;

  const isProceeded = [PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(purchaseStatus);

  const handlePayment = ({ status }) => {
    if ([PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased].includes(status)) {
      if (isNotLoggedUser) {
        setIsNotLoggedUser(false);
        return setIsSubmitPurchaseModalNow(true);
      }
      setPurchaseModalShowed(true);
    }

    if ([PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(status)) {
      setPurchaseModalShowed(false);
      setProceedModalShowed(true);
    }

    if (!status) {
      dispatch(fetchClientContribution(id));
    }
  };

  const handlePurchasePackage = async () => {
    setIsMonthlySessionSubscription(false);
    if (isEmpty(user)) {
      setIsNotLoggedUser(true);
      setPurchaseModalShowed(true);
      setIsPackage(true);
      return;
    }

    if (isProceeded) {
      setProceedModalShowed(true);
      return;
    }

    setIsPackage(true);
    try {
      const paymentDataResponse = await request('/api/purchase/one-to-one/package', 'POST', { contributionId: id });
      setPaymentData(paymentDataResponse);
      handlePayment(paymentDataResponse);
    } catch (e) {
      setErrorMessage(e.response.data.message);
      setRefuseModalShowed(true);
    }
  };

  const proseedHandlePurchase = useCallback(() => {
    handlePurchasePackage();
  }, [handlePurchasePackage]);

  const handlePurchaseProceedModalCancel = useCallback(
    (releasePaymentIntent = true) => {
      setPurchaseModalShowed(false);
      setProceedModalShowed(false);
      if (isPackage) {
        setIsPackage(false);
        if (releasePaymentIntent) {
          request('/api/purchase/cancleOneToOnePackagePayment', 'POST', {
            contributionId: id,
            created: paymentData?.created,
          }).catch(console.dir);
        }
      }
    },
    [id, paymentData, setPurchaseModalShowed, setProceedModalShowed, isPackage, setIsPackage],
  );

  const toggleSuccessPurchaseModal = () => setSuccessPurchaseModal(prevState => !prevState);

  const handlePurchaseProceedModalSubmit = () => {
    setPurchaseModalShowed(false);
    setProceedModalShowed(false);
    toggleSuccessPurchaseModal();
    if (isPackage) {
      setIsPackage(false);
    }
    setTimeout(() => {
      dispatch(fetchClientContribution(id));
    }, 2000);

    setTimeout(() => {
      dispatch(fetchClientContribution(id));
    }, 4000);
  };

  const handleRefuseModal = useCallback(() => {
    setRefuseModalShowed(false);
  }, [setRefuseModalShowed]);

  const handleMonthlySessionSubscription = useCallback(async () => {
    setIsPackage(false);
    if (isEmpty(user)) {
      setIsNotLoggedUser(true);
      setPurchaseModalShowed(true);
      setIsMonthlySessionSubscription(true);
      return;
    }

    if (isProceeded) {
      setProceedModalShowed(true);
      return;
    }

    setIsMonthlySessionSubscription(true);
    setPurchaseModalShowed(true);
  }, [isProceeded, id, handlePayment, user]);

  const allPurchasedPackagesAreCompleted =
    isEmpty(clientPackages) ||
    clientPackages.filter(item => !item.isMonthlySessionSubscription).every(item => item.isCompleted);

  const allPurchasedMonthlySessionSubscriptionAreCompleted =
    isEmpty(clientPackages) ||
    clientPackages
      .filter(item => item.isMonthlySessionSubscription)
      .every(item => item.ssMonthlySessionSubscriptionCompleted);

  const isPackagePurchaseEnabled = paymentInfo?.paymentOptions.includes('SessionsPackage');

  const isMonthlySessionSubscriptionEnabled = paymentInfo?.paymentOptions.includes('MonthlySessionSubscription');

  const isPackagePurchaseAllowed = isPackagePurchaseEnabled && allPurchasedPackagesAreCompleted;

  const isMonthlySessionSubscriptionPurchaseAllowed =
    isMonthlySessionSubscriptionEnabled && allPurchasedMonthlySessionSubscriptionAreCompleted;

  const showPackagePurchaseButton = isPackagePurchaseAllowed && allPurchasedMonthlySessionSubscriptionAreCompleted;

  const showMontlySessionSubscriptionPurchaseButton =
    isMonthlySessionSubscriptionPurchaseAllowed && allPurchasedPackagesAreCompleted;

  return (
    <>
      {!isCoach && (
        <>
          <div>
            {showPackagePurchaseButton && (
              <StyledButton autoWidth onClick={handlePurchasePackage} disabled={false} mobileView={mobileView}>
                {isProceeded ? 'Proceed Package Purchasing' : 'Purchase Package'}
              </StyledButton>
            )}
          </div>
          {showMontlySessionSubscriptionPurchaseButton && (
            <div>
              <StyledButton
                autoWidth
                onClick={handleMonthlySessionSubscription}
                disabled={false}
                mobileView={mobileView}
              >
                Purchase Subscription
              </StyledButton>
            </div>
          )}
        </>
      )}
      {isPurchaseModalShowed && (
        <PurchaseModal
          isOpen={isPurchaseModalShowed}
          onClose={handlePurchaseProceedModalCancel}
          onSubmit={handlePurchaseProceedModalSubmit}
          isPackage={isPackage}
          isMonthlySessionSubscription={isMonthlySessionSubscription}
          oneToOne
          paymentDataOneToOne={paymentData}
          proseedHandlePurchase={isNotLoggedUser && proseedHandlePurchase}
          submitNow={isSubmitPurchaseModalNow}
        />
      )}
      {isProceedModalShowed && (
        <ProceedModal
          isOpen={isProceedModalShowed}
          onClose={handlePurchaseProceedModalCancel}
          onSubmit={handlePurchaseProceedModalSubmit}
        />
      )}
      {isRefuseModalShowed && (
        <RefuseModal
          isOpen={isRefuseModalShowed}
          onClose={handleRefuseModal}
          onSubmit={handleRefuseModal}
          message={errorMessage}
        />
      )}
      {isOpenSuccessPurchaseModal && (
        <SuccessfulPurchaseModal
          isOpen={isOpenSuccessPurchaseModal}
          handleOpen={toggleSuccessPurchaseModal}
          handleClose={toggleSuccessPurchaseModal}
        />
      )}
    </>
  );
};

const actions = {
  getClientContribution: contributionActions.fetchClientContribution,
  getCohealerContribution: contributionActions.fetchCohealerContribution,
};

PurchaseModalsAndButtonsComponent.propTypes = {
  contribution: PropTypes.objectOf(PropTypes.any),
};

PurchaseModalsAndButtonsComponent.defaultProps = {
  contribution: {},
};

export default PurchaseModalsAndButtonsComponent;
