import React from 'react';

import { createCustomerPortalLink } from 'services/paidTierOptions.service';
import { redirectInNewTab } from 'services/links';
import { useStyles, PrimaryP, SecondaryP, UpdatePaymentMethodButton } from '../Billing.styles';

import Dictionary from '../dictionary.json';

const BillingDescription = () => {
  const { paymentButton, membershipOffers, about } = Dictionary.description;

  const classes = useStyles();

  const redirectToCustomerPortalLink = () => {
    createCustomerPortalLink().then(res => {
      redirectInNewTab(res);
    });
  };

  return (
    <div className={classes.descriptionContainer}>
      <div className={classes.descriptionBlock}>
        <PrimaryP className={classes.descriptionTitleMedia}>{membershipOffers}</PrimaryP>
        <SecondaryP className={classes.descriptionAboutMedia}>{about}</SecondaryP>
      </div>

      <div className={classes.updatePaymentMethod}>
        <div>
          <UpdatePaymentMethodButton className={classes.buttonMedia} onClick={redirectToCustomerPortalLink}>
            {paymentButton}
          </UpdatePaymentMethodButton>
        </div>
      </div>
    </div>
  );
};

export default BillingDescription;
