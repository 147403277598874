import React, { useState, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useTheme, useMediaQuery } from '@material-ui/core'
import moment from 'moment'

import { useEndVideoChat } from 'hooks'
import { getTimePeriodsForAvailability } from 'utils/datesAndMoney'
import { fetchCohealerContribution, rescheduleSession, fetchContributionTimes } from 'actions/contributions'
import Button from 'components/FormUI/Button'
import LaunchSessionTime from 'components/SessionButtons/LaunchSessionTime'
import Modal from 'components/UI/Modal'
import { BodyText } from 'components/UI/Text/TextStyles'
import TextField from 'components/UI/TextField'
import styled from 'styled-components'
import { TIMEZONES } from 'constants.js'

import LaunchRecordedSessionVideo from 'components/SessionButtons/LaunchRecordedSessionVideo'
import SessionsContainer from '../SessionsContainer/SessionsContainer'
import CalendarContributionView from '../../../components/CalendarContributionView/CalendarContributionView'
import RefuseModal from '../../../components/RefuseModal'
import LaunchSessionTimeModal from './LaunchSessionTimeModal'
import SlotsModal from '../../../components/SlotsModal.js'
import TabSelector from '../../../../../components/UI/TabSelector.js'
import { FILTER_TABS_OPTIONS, SESSIONS_TYPES } from '../../constants.js'

const StyledTextField = styled(TextField)`
  width: 100%;
`

const scrollToCalendar = (ref) => window.scrollTo(0, ref.current.offsetTop)

const CohealerOneToOneSessions = ({
  user,
  contribution,
  contribution: { id, title, availabilityTimes, durations, serviceProviderName, participants },
  contributionTimes,
  getCohealerContribution,
  rescheduleChosenSession,
  getContributionTimes,
}) => {
  const [isRefuseModalOpen, setIsRefuseModalOpen] = useState(false)
  const [selectedSessionTime, setSelectedSessionTime] = useState(null)
  const [rescheduleMode, setRescheduleMode] = useState(false)
  const [isCalendarRescheduleModalOpen, setCalendarRescheduleModal] = useState(false)
  const [calendarRescheduleModalStatus, setCalendarRescheduleModalStatus] = useState(null)
  const [currentRescheduleSession, setCurrentRescheduleSession] = useState(null)
  const [messageText, setMessageText] = useState('')
  const [rescheduleTo, setRescheduleTo] = useState(null)
  const calendarRef = useRef(null)
  const dateToShow = currentRescheduleSession?.startTime

  let clientFirstName = ''
  let clientLastName = ''
  if (currentRescheduleSession) {
    const { firstName, lastName } =
      (participants || []).find((client) => client.id === currentRescheduleSession.participantId) || {}
    clientFirstName = firstName
    clientLastName = lastName
  }

  const executeScroll = () => {
    scrollToCalendar(calendarRef)
  }

  const handleCalendarRescheduleSubmit = () => {
    const rescheduleData = {
      rescheduleFromId: currentRescheduleSession.id,
      rescheduleToId: rescheduleTo.id,
      contributionId: id,
      note: messageText,
    }
    rescheduleChosenSession(rescheduleData).then(() => {
      setCalendarRescheduleModal(false)
      setMessageText(null)
      setCalendarRescheduleModalStatus(rescheduleTo)
    })
  }

  const handleCalendarRescheduleCancel = () => {
    setCalendarRescheduleModal(false)
    setRescheduleTo(null)
  }

  const handleCalendarRescheduleStatusModalSubmit = () => {
    setCalendarRescheduleModalStatus(null)
    setCurrentRescheduleSession(null)
  }

  const handleRefreshList = useCallback(async () => {
    getCohealerContribution(id)
    getContributionTimes(id, true)
  }, [id, getCohealerContribution, getContributionTimes])
  const { Popup, onShowPopup } = useEndVideoChat(handleRefreshList)

  const duration = durations && durations.length ? durations[0] : 60 // temporary becauce there is null
  const events = getTimePeriodsForAvailability({
    availabilityPeriods: contributionTimes,
    duration,
    title,
    serviceProviderName,
  })

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))
  const [isSlotModalOpen, setSlotModalShowed] = useState(false)
  const [selectedFilterTab, setSelectedFilterTab] = useState(FILTER_TABS_OPTIONS[0].value)
  const [selectedDate, setSelectedDate] = useState(null)
  const closeModalHandler = useCallback(() => {
    setSlotModalShowed(false)
  }, [])
  const onCalendarDrillDownHandler = useCallback((day) => {
    setSelectedDate(moment(day))
    setSlotModalShowed(true)
  }, [])
  const onCalendarEventSelectHandler = useCallback((event) => {
    setSelectedDate(moment(moment(event.start)))
    setSlotModalShowed(true)
  }, [])
  const onSelectEvent = (event) => {
    const { start, isBooked, bookedTimeId, offset } = event
    const availableToReschedule = moment(start).isAfter(moment())
    if (rescheduleMode && availableToReschedule && !isBooked) {
      setRescheduleMode(false)
      setCalendarRescheduleModal(true)
      setRescheduleTo(event)
      setSlotModalShowed(false)
      return
    }
    if (!event.isBooked) {
      setIsRefuseModalOpen(true)
      return
    }

    setSelectedSessionTime({
      id: bookedTimeId,
      title: event.title,
      start: event.start,
      end: event.end,
      participantId: event.participantId,
      offset,
    })
  }

  return (
    <>
      <TabSelector options={FILTER_TABS_OPTIONS} selected={selectedFilterTab} onChange={setSelectedFilterTab} />
      <SessionsContainer
        executeCalendarScroll={executeScroll}
        setRescheduleMode={setRescheduleMode}
        setCurrentRescheduleSession={setCurrentRescheduleSession}
        contribution={contribution}
        completionFilter={selectedFilterTab}
        renderActions={(session) => {
          const isHasRecordings = session.recordingInfos.length > 0
          return (
            <>
              {!session.isCompleted && (
                <Button autoWidth onClick={() => onShowPopup(contribution.id, session.id)}>
                  Complete
                </Button>
              )}
              <LaunchSessionTime user={user} contribution={contribution} sessionTime={session} />
              {isHasRecordings && (
                <LaunchRecordedSessionVideo
                  contribution={contribution}
                  session={null}
                  sessionTime={session}
                  mobileView={mobileView}
                />
              )}
            </>
          )
        }}
      />
      <CalendarContributionView
        dateToShow={dateToShow}
        calendarRef={calendarRef}
        events={events}
        onDrillDown={onCalendarDrillDownHandler}
        onSelectEvent={onCalendarEventSelectHandler}
        timeZoneId={contribution.timeZoneId}
      />
      {isSlotModalOpen && (
        <SlotsModal
          isOpen={isSlotModalOpen}
          onClose={closeModalHandler}
          selectedDate={selectedDate}
          contributionId={id}
          isCoach
          title={title}
          serviceProviderName={serviceProviderName}
          duration={duration}
          onSubmit={onSelectEvent}
          isSelectable={rescheduleMode}
        />
      )}
      {isRefuseModalOpen && (
        <RefuseModal
          isOpen={isRefuseModalOpen}
          onClose={() => setIsRefuseModalOpen(false)}
          onSubmit={() => setIsRefuseModalOpen(false)}
          message=""
        />
      )}
      {selectedSessionTime && (
        <LaunchSessionTimeModal
          user={user}
          contribution={contribution}
          sessionTime={selectedSessionTime}
          open={!!selectedSessionTime}
          onOpenChange={(open) => setSelectedSessionTime((session) => (open ? session : null))}
        />
      )}
      {isCalendarRescheduleModalOpen && (
        <Modal
          isOpen={isCalendarRescheduleModalOpen}
          onCancel={handleCalendarRescheduleCancel}
          onSubmit={handleCalendarRescheduleSubmit}
          title="Confirm your new session time"
          hiddenCancel
          submitTitle="Confirm Reschedule"
        >
          <BodyText>
            By clicking Confirm, you will be updating the session time with {clientFirstName} {clientLastName}. It’s
            your responsibility to confirm with {clientFirstName} {clientLastName} that this new time also works for
            them.
          </BodyText>
          <StyledTextField
            placeholder="Optional. What is the reason for rescheduling? This information will be shared with your client"
            multiline
            rowsMax={6}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
        </Modal>
      )}
      {calendarRescheduleModalStatus && (
        <Modal
          isOpen={calendarRescheduleModalStatus}
          onCancel={handleCalendarRescheduleStatusModalSubmit}
          onSubmit={handleCalendarRescheduleStatusModalSubmit}
          title="Reschedule status"
          hiddenCancel
          submitTitle="Ok"
        >
          <BodyText>
            Your new session time with {clientFirstName} {clientLastName} on{' '}
            {moment(calendarRescheduleModalStatus?.start).format('LL [at] LT z')}
            {TIMEZONES[user?.timeZoneId]} is confirmed.
          </BodyText>
        </Modal>
      )}
      <Popup />
    </>
  )
}

CohealerOneToOneSessions.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    durations: PropTypes.arrayOf(PropTypes.number),
    serviceProviderName: PropTypes.string,
    participants: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  contributionTimes: PropTypes.arrayOf(PropTypes.object).isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
  rescheduleChosenSession: PropTypes.func.isRequired,
  getContributionTimes: PropTypes.func.isRequired,
}

const mapStateToProps = ({ contributions }) => ({
  contributionTimes: contributions?.contributionTimes,
})

const actions = {
  getCohealerContribution: fetchCohealerContribution,
  rescheduleChosenSession: rescheduleSession,
  getContributionTimes: fetchContributionTimes,
}

export default connect(mapStateToProps, actions)(CohealerOneToOneSessions)
