import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import moment from 'moment';
import styled from 'styled-components';

import DateTimePicker from 'components/FormUI/DateTimePicker';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import Checkbox from '@material-ui/core/Checkbox';
import { TOOLTIP } from '../../../../../constants.js';
import InfoIcon from '@material-ui/icons/Info.js';
import { colors } from '../../../../../utils/styles';
import Tooltip from '@material-ui/core/Tooltip';

const StyledCheckbox = styled(Checkbox)`
  padding-left: 0 !important;
`;

const StyledLabel = styled.div`
  font-weight: bold;
`;

const EnrollmentDates = ({ enrollment }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid item xs={12}>
      <Card mobileView={mobileView}>
        <CardHeader mobileView={mobileView}>
          <PageTitleSecond mobileView={mobileView}>Enrollment Dates</PageTitleSecond>
        </CardHeader>
        <CardBody mobileView={mobileView}>
          <Grid container className="mb-2">
            <Field as={StyledCheckbox} name="enrollment.anyTime" type="checkbox" color="primary" />
            <StyledLabel className="mt-2">Anytime</StyledLabel>
            <Tooltip
              title="Check this box to let your clients enroll anytime. Alternatively, uncheck this box to create a specific enrollment window for your membership."
              arrow
              className="ml-2 mt-2"
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <InfoIcon htmlColor={colors.lightBrown} />
            </Tooltip>
          </Grid>
          {!enrollment.anyTime && (
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <DateTimePicker
                  fullWidth
                  name="enrollment.fromDate"
                  type="date"
                  label="From"
                  minDate={moment().add(0, 'd')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <DateTimePicker
                  fullWidth
                  name="enrollment.toDate"
                  defaultHour={23}
                  defaultMinute={59}
                  defaultSecond={59}
                  defaultMillisecond={999}
                  type="date"
                  label="To"
                  minDate={moment().add(0, 'd')}
                />
              </Grid>
            </Grid>
          )}
        </CardBody>
      </Card>
    </Grid>
  );
};

EnrollmentDates.propTypes = {
  enrollment: PropTypes.shape({
    toDate: PropTypes.string,
    fromDate: PropTypes.string,
    anyTime: PropTypes.bool,
  }).isRequired,
};

export default EnrollmentDates;
