import React from 'react'
import Icon from '@mdi/react'
import { mdiBookmark } from '@mdi/js'
import { colors } from '../../utils/styles'

const FavBookIcon = () => {
  return <Icon path={mdiBookmark} title="Fav bookmarks" size={1} horizontal vertical rotate={180} color={colors.gold} />
}

export default FavBookIcon
