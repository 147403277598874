import { handleActions } from 'redux-actions';

import * as actions from 'actions/community';

const initialState = {
  loading: true,
  error: null,
  posts: [],
};

const communityPosts = handleActions(
  new Map([
    [
      actions.fetchAllPostsForContributionActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
        posts: [],
      }),
    ],
    [
      actions.fetchAllPostsForContributionActions.success,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: null,
          ...action.payload,
        };
      },
    ],
    [
      actions.fetchAllPostsForContributionActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        posts: [],
      }),
    ],
    [
      actions.fetchByPagePostsForContributionActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.fetchByPagePostsForContributionActions.success,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: null,
          ...action.payload,
          posts: [...state.posts, ...action.payload.posts],
        };
      },
    ],
    [
      actions.fetchByPagePostsForContributionActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        posts: [],
      }),
    ],
  ]),
  initialState,
);

export default communityPosts;
