import moment from 'moment';

const DATE_FORMATS = {
  DAY: 'MM/DD/YYYY',
  DATE_TIME: 'MMMM Do YYYY hh:mmA',
  DATE_TIME_LOCAL: 'YYYY-MM-DDTHH:mm:ss',
  TIME_24: 'HH:mm',
  TIME_12: 'hh:mmA',
  CONTRIBUTION_TIME: 'MMM Do YYYY, HH:mm',
  POST_TIME: 'DD/MM/YYYY HH:mm:ss',
};

function parseDate(dateString) {
  return moment.utc(dateString).format(DATE_FORMATS.DAY);
}

function UTCtoLocalDateTime(utcDateTime) {
  if (!moment.isMoment(utcDateTime)) {
    return '';
  }

  return utcDateTime.clone().add(utcDateTime.utcOffset(), 'm').toJSON().slice(0, -1);
}

function formatMoney(amount, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)), 10).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {}
}

const getTimePeriodForDay = ({ id, startDate, endDate, startTime, endTime, bookedTimes, color, title }) => {
  const resultPeriods = [];
  const timeFormat = 'YYYY-MM-DDTHH:mm:ss';
  const startFrom = startDate || startTime;
  let date = moment.isMoment(startFrom) ? startFrom.clone() : moment(startFrom);
  const startDateMoment = moment.isMoment(startDate) ? startDate.clone() : moment(startDate);
  const endDateMoment = moment.isMoment(endDate) ? endDate.clone() : moment(endDate);

  const startTimeMoment = moment.isMoment(startTime) ? startTime.clone() : moment(startTime);
  const endTimeMoment = moment.isMoment(endTime) ? endTime.clone() : moment(endTime);

  for (let i = 0; i <= endDateMoment.diff(startDateMoment, 'days'); i += 1) {
    const resultDayPeriod = {
      id: id || null,
      startTime: date
        .clone()
        .set({
          hour: startTimeMoment.get('hour'),
          minute: startTimeMoment.get('minute'),
          second: startTimeMoment.get('second'),
        })
        .format(timeFormat),
      endTime: date
        .clone()
        .set({
          hour: endTimeMoment.get('hour'),
          minute: endTimeMoment.get('minute'),
          second: endTimeMoment.get('second'),
        })
        .format(timeFormat),
      bookedTimes: id && bookedTimes.length ? bookedTimes : [],
      color,
      title,
    };

    resultPeriods.push(resultDayPeriod);
    date = date.add(1, 'days');
  }
  return resultPeriods;
};

const getTimePeriodsForSessionsCollection = sessionsCollection => {
  let availabilityTimes = [];

  sessionsCollection.forEach(time => {
    const period = getTimePeriodForDay(time);
    availabilityTimes = availabilityTimes.concat(period);
  });

  return availabilityTimes;
};

const tryGetBookedTimeForOneToOneSession = (sessionStartTime, bookedTimes) =>
  bookedTimes.find(
    ({ startTime }) => sessionStartTime.isSame(startTime, 'hour') && sessionStartTime.isSame(startTime, 'minute'),
  );

const getTimePeriodsForAvailability = ({ availabilityPeriods, title, serviceProviderName, duration }) => {
  if (!availabilityPeriods || !availabilityPeriods.length) {
    return [];
  }

  const resultSessions = [];

  availabilityPeriods.forEach(({ startTime, endTime, bookedTimes, id, color, offset, title: contributionTitle }) => {
    let date = moment.isMoment(startTime) ? startTime.clone() : moment(startTime);
    const endTimeMoment = moment.isMoment(endTime) ? endTime : moment(endTime);

    while (date < endTimeMoment) {
      const endPeriodTime = date.clone().add(duration, 'minutes');
      const bookedTime = tryGetBookedTimeForOneToOneSession(date, bookedTimes);

      const timeStart = moment(startTime).format('hh:mm A');
      const timeEnd = moment(endTime).format('hh:mm A');

      resultSessions.push({
        id,
        title: contributionTitle || title || `${timeStart}-${timeEnd}`,
        serviceProviderName,
        start: new Date(date),
        end: new Date(endPeriodTime),
        allDay: false,
        isBooked: bookedTime && bookedTime.isPurchaseConfirmed,
        bookedTimeId: bookedTime ? bookedTime.id : null,
        participantId: bookedTime ? bookedTime.participantId : null,
        color,
        offset,
      });
      date = endPeriodTime;
    }
  });

  return resultSessions;
};

const getTimePeriodsForSessions = sessions => {
  let resultSessions = [];

  sessions.forEach(({ title, durations, sessionTimes }) => {
    const duration = (Array.isArray(durations) ? durations[0] : durations) || 60;
    const availabilityPeriods = getTimePeriodsForSessionsCollection(sessionTimes);

    resultSessions = resultSessions.concat(
      getTimePeriodsForAvailability({
        availabilityPeriods,
        title,
        duration,
      }),
    );
  });
  return resultSessions;
};

const getDateForBook = date => {
  if (!date) {
    return '';
  }

  return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
};

export {
  DATE_FORMATS,
  parseDate,
  UTCtoLocalDateTime,
  formatMoney,
  getTimePeriodForDay,
  getTimePeriodsForSessions,
  getTimePeriodsForSessionsCollection,
  getTimePeriodsForAvailability,
  getDateForBook,
};
