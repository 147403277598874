// import React from "react";
import * as Yup from 'yup';
import moment from 'moment';

import { EN_MESSAGES, VALIDATION_REGEX } from 'constants.js';
import {
  ACCOUNT_FORM_FIELDS,
  PURCHASE_MODAL_STEPS,
} from 'pages/ContributionView/components/PurchaseModal/PurchaseModal.constants';

const MIN_STRING_LENGTH = 2;
const MAX_STRING_LENGTH = 100;
const MAX_LONG_STRING_LENGTH = 200;

// const getLoginValidationSchema = Translate =>
const getLoginValidationSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .strict(true)
      .trim()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, {
        // message: <Translate id="validation.password" />
        message: EN_MESSAGES.validation.password,
      })
      // .required(<Translate id="validation.required" />),
      .required(EN_MESSAGES.validation.required),
    email: Yup.string().email(EN_MESSAGES.validation.required).required(EN_MESSAGES.validation.required),
  });

// const restorePassExtendedValidationSchema = Translate =>
const restorePassExtendedValidationSchema = () =>
  Yup.object().shape({
    newPassword: Yup.string()
      .strict(true)
      .trim()
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, {
        // message: <Translate id="validation.password" />
        message: EN_MESSAGES.validation.password,
      })
      // .required(<Translate id="validation.required" />),
      .required(EN_MESSAGES.validation.required),
    email: Yup.string()
      // .email(<Translate id="validation.email.invalid" />)
      .email(EN_MESSAGES.validation.invalid)
      // .required(<Translate id="validation.required" />),
      .required(EN_MESSAGES.validation.required),
  });

// const restorePassValidationSchema = Translate =>
const restorePassValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      // .email(<Translate id="validation.email.invalid" />)
      .email(EN_MESSAGES.validation.invalid)
      // .required(<Translate id="validation.required" />),
      .required(EN_MESSAGES.validation.required),
  });

// const getSignUpValidationSchema = Translate =>
const getSignUpValidationSchema = () =>
  Yup.object().shape({
    FirstName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    LastName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    BirthDate: Yup.string().matches(VALIDATION_REGEX.DATE, 'Please use the following format: MM/DD/YYYY').required(),
    BusinessType: Yup.mixed().oneOf(['Coaching', 'Teaching', 'Mentoring', 'Consulting', 'Other']).required(),
    // Certification: Yup.string().max(MAX_LONG_STRING_LENGTH),
    TimeZoneId: Yup.mixed()
      .oneOf([
        'America/Los_Angeles',
        'America/Denver',
        'America/Chicago',
        'America/New_York',
        'America/Anchorage',
        'America/St_Johns',
        'Pacific/Honolulu',
        'America/Phoenix',
        'Australia/Sydney',
        'Canada/Atlantic',
        'Canada/Central',
        'Canada/Eastern',
        'Canada/Mountain',
        'Canada/Newfoundland',
        'Canada/Pacific',
        'Canada/Saskatchewan',
        'Canada/Yukon',
        'Europe/Amsterdam',
        'Europe/Andorra',
        'Europe/Athens',
        'Europe/Belfast',
        'Europe/Belgrade',
        'Europe/Berlin',
        'Europe/Bratislava',
        'Europe/Brussels',
        'Europe/Bucharest',
        'Europe/Budapest',
        'Europe/Busingen',
        'Europe/Chisinau',
        'Europe/Copenhagen',
        'Europe/Dublin',
        'Europe/Gibraltar',
        'Europe/Guernsey',
        'Europe/Helsinki',
        'Europe/Isle_of_Man',
        'Europe/Istanbul',
        'Europe/Jersey',
        'Europe/Kaliningrad',
        'Europe/Lisbon',
        'Europe/Ljubljana',
        'Europe/London',
        'Europe/Luxembourg',
        'Europe/Madrid',
        'Europe/Malta',
        'Europe/Mariehamn',
        'Europe/Minsk',
        'Europe/Monaco',
        'Europe/Moscow',
        'Europe/Nicosia',
        'Europe/Oslo',
        'Europe/Paris',
        'Europe/Podgorica',
        'Europe/Prague',
        'Europe/Riga',
        'Europe/Rome',
        'Europe/Samara',
        'Europe/San_Marino',
        'Europe/Sarajevo',
        'Europe/Simferopol',
        'Europe/Skopje',
        'Europe/Sofia',
        'Europe/Stockholm',
        'Europe/Tallinn',
        'Europe/Tirane',
        'Europe/Tiraspol',
        'Europe/Uzhgorod',
        'Europe/Vaduz',
        'Europe/Vatican',
        'Europe/Vienna',
        'Europe/Vilnius',
        'Europe/Volgograd',
        'Europe/Warsaw',
        'Europe/Zagreb',
        'Europe/Zaporozhye',
        'Europe/Zurich',
        'Hongkong',
        'Iceland',
        'Israel',
        'Japan',
        'Mexico/BajaNorte',
        'Mexico/BajaSur',
        'Mexico/General',
        'Navajo',
        'NZ',
        'NZ-CHAT',
        'Singapore',
      ])
      .required(),
    // Occupation: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    Bio: Yup.string().strict(true).max(500),
  });

const getSignUpClientValidationSchema = () =>
  Yup.object().shape({
    FirstName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    LastName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    BirthDate: Yup.date()
      .required()
      .test('Birth Date', 'At this time Cohere only allows 18+. Please come back when you are 18 or older!', value => {
        return moment().diff(moment(value), 'years') >= 18;
      }),
  });

const getClientProfileValidationSchema = () =>
  Yup.object().shape({
    FirstName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    LastName: Yup.string().strict(true).min(MIN_STRING_LENGTH).max(MAX_STRING_LENGTH).required(),
    BirthDate: Yup.date().required(),
    Bio: Yup.string().strict(true).max(500),
  });

const getPurchaseValidationSchema = step => {
  if (step === PURCHASE_MODAL_STEPS.memberLoggedIn) {
    return Yup.object().shape({});
  }
  if (step === PURCHASE_MODAL_STEPS.loggedIn) {
    return Yup.object().shape({
      Name: Yup.string().required(),
      // cardNumber: Yup.boolean().oneOf([true]).required(),
      // cardExpired: Yup.boolean().oneOf([true]).required(),
      // cardCVC: Yup.boolean().oneOf([true]).required()
    });
  }
  if (step === PURCHASE_MODAL_STEPS.init) {
    return Yup.object().shape({
      Name: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email(),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.memberInit) {
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email(),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.join) {
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email(),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.joinLogin) {
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email(),
      [ACCOUNT_FORM_FIELDS.password]: Yup.string()
        .strict(true)
        .trim()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, {
          message: EN_MESSAGES.validation.password,
        })
        .required(EN_MESSAGES.validation.required),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.login) {
    return Yup.object().shape({
      Name: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email(),
      [ACCOUNT_FORM_FIELDS.password]: Yup.string()
        .strict(true)
        .trim()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, {
          message: EN_MESSAGES.validation.password,
        })
        .required(EN_MESSAGES.validation.required),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.memberLogin) {
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email(),
      [ACCOUNT_FORM_FIELDS.password]: Yup.string()
        .strict(true)
        .trim()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, {
          message: EN_MESSAGES.validation.password,
        })
        .required(EN_MESSAGES.validation.required),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.memberCreate) {
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email(),
      [ACCOUNT_FORM_FIELDS.birthDate]: Yup.string()
        .required()
        .test(
          'Birth Date',
          'At this time Cohere only allows 18+. Please come back when you are 18 or older!',
          value => {
            return moment().diff(moment(value), 'years') >= 18;
          },
        ),
      [ACCOUNT_FORM_FIELDS.firstName]: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.lastName]: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.confirmEmail]: Yup.string()
        .required()
        .test('equal', 'email is not equal', function (value) {
          return this.parent[ACCOUNT_FORM_FIELDS.email] === value;
        }),
      [ACCOUNT_FORM_FIELDS.password]: Yup.string()
        .strict(true)
        .trim()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, {
          message: EN_MESSAGES.validation.password,
        })
        // check why select is showing message only after being clicked, and not when submitting.
        .required(EN_MESSAGES.validation.required),
      [ACCOUNT_FORM_FIELDS.timeZoneId]: Yup.mixed().required(),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.create) {
    return Yup.object().shape({
      Name: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email(),
      [ACCOUNT_FORM_FIELDS.birthDate]: Yup.string()
        .required()
        .test(
          'Birth Date',
          'At this time Cohere only allows 18+. Please come back when you are 18 or older!',
          value => {
            return moment().diff(moment(value), 'years') >= 18;
          },
        ),
      [ACCOUNT_FORM_FIELDS.firstName]: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.lastName]: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.confirmEmail]: Yup.string()
        .required()
        .test('equal', 'email is not equal', function (value) {
          return this.parent[ACCOUNT_FORM_FIELDS.email] === value;
        }),
      [ACCOUNT_FORM_FIELDS.password]: Yup.string()
        .strict(true)
        .trim()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, {
          message: EN_MESSAGES.validation.password,
        })
        // check why select is showing message only after being clicked, and not when submitting.
        .required(EN_MESSAGES.validation.required),
      [ACCOUNT_FORM_FIELDS.timeZoneId]: Yup.mixed().required(),
    });
  }
  if (step === PURCHASE_MODAL_STEPS.joinCreate) {
    return Yup.object().shape({
      [ACCOUNT_FORM_FIELDS.email]: Yup.string().required().email(),
      [ACCOUNT_FORM_FIELDS.birthDate]: Yup.string()
        .required()
        .test(
          'Birth Date',
          'At this time Cohere only allows 18+. Please come back when you are 18 or older!',
          value => {
            return moment().diff(moment(value), 'years') >= 18;
          },
        ),
      [ACCOUNT_FORM_FIELDS.firstName]: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.lastName]: Yup.string().required(),
      [ACCOUNT_FORM_FIELDS.confirmEmail]: Yup.string()
        .required()
        .test('equal', 'email is not equal', function (value) {
          return this.parent[ACCOUNT_FORM_FIELDS.email] === value;
        }),
      [ACCOUNT_FORM_FIELDS.password]: Yup.string()
        .strict(true)
        .trim()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, {
          message: EN_MESSAGES.validation.password,
        })
        // check why select is showing message only after being clicked, and not when submitting.
        .required(EN_MESSAGES.validation.required),
      [ACCOUNT_FORM_FIELDS.timeZoneId]: Yup.mixed().required(),
    });
  }
};

export {
  getLoginValidationSchema,
  restorePassExtendedValidationSchema,
  restorePassValidationSchema,
  getSignUpValidationSchema,
  getSignUpClientValidationSchema,
  getClientProfileValidationSchema,
  getPurchaseValidationSchema,
};
