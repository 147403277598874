import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const MoreOptions = ({
  className,
  hasLastMessage,
  isLastMessageReadByMe,
  onToggleReadMessages,
  isFavorite,
  onToggleIsFavorite,
  onClosed,
}) => {
  const anchorRef = useRef()
  const [isOpen, setIsOpen] = useState(false)

  const onClose = () => setIsOpen(false)

  return (
    <>
      <IconButton
        ref={anchorRef}
        className={className}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(true)
        }}
        color="inherit"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={onClose}
        onClick={(e) => e.stopPropagation()}
        onExited={onClosed}
      >
        {hasLastMessage && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              onToggleReadMessages()
              onClose()
            }}
          >
            {isLastMessageReadByMe ? 'Mark as unread' : 'Mark as read'}
          </MenuItem>
        )}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            onToggleIsFavorite()
            onClose()
          }}
        >
          {isFavorite ? 'Mark as regular' : 'Mark as favorite'}
        </MenuItem>
      </Menu>
    </>
  )
}

MoreOptions.propTypes = {
  className: PropTypes.string.isRequired,
  hasLastMessage: PropTypes.bool.isRequired,
  isLastMessageReadByMe: PropTypes.bool.isRequired,
  onToggleReadMessages: PropTypes.func.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  onToggleIsFavorite: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
}

export default MoreOptions
