import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';

import Can from 'components/Auth/Can';
import { UserRoles } from 'helpers/constants';
import { toRem, colors } from 'utils/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';

const StyledAccountHeaderContainer = styled(Tabs)`
  border-top: solid 1px #e7e7e7;
  border-bottom: solid 1px #e7e7e7;

  ${({ mobileViewLaptop }) => mobileViewLaptop && 'width: calc(100vw - 300px)'}
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: ${toRem(16)};
  line-height: 1;
  color: #4a4a4a;
  padding: 16px 32px;
  display: inline-block;
  position: relative;

  &.active {
    color: ${colors.darkOceanBlue};
  }

  &.active:after {
    content: '';
    width: 100%;
    height: 4px;
    background-color: ${colors.darkOceanBlue};
    position: absolute;
    bottom: 0;
    left: 0;
  }
  ${({ mobileView }) => mobileView && 'display: block'}
`;

function CohealerMenu() {
  const theme = useTheme();

  const mobileViewLaptop = useMediaQuery(theme.breakpoints.up(992));
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <StyledAccountHeaderContainer
      mobileView={mobileView}
      mobileViewLaptop={mobileViewLaptop}
      variant="scrollable"
      scrollButtons="auto"
    >
      <StyledNavLink mobileView={mobileView} to="/account/profile">
        Profile
      </StyledNavLink>

      <StyledNavLink mobileView={mobileView} to="/account/payment">
        My Sales
      </StyledNavLink>

      <StyledNavLink mobileView={mobileView} to="/account/billing/coach">
        Billing
      </StyledNavLink>

      <StyledNavLink mobileView={mobileView} to="/account/tax-info">
        Tax info
      </StyledNavLink>

      <StyledNavLink mobileView={mobileView} to="/account/notifications">
        Notifications
      </StyledNavLink>

      <StyledNavLink mobileView={mobileView} to="/account/video">
        Video
      </StyledNavLink>

      <StyledNavLink mobileView={mobileView} to="/account/calendars">
        Calendar Connections
      </StyledNavLink>

      <StyledNavLink mobileView={mobileView} to="/account/affiliate">
        Affiliate
      </StyledNavLink>
    </StyledAccountHeaderContainer>
  );
}

function ClientMenu() {
  const theme = useTheme();
  const mobileViewLaptop = useMediaQuery(theme.breakpoints.up(992));

  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <StyledAccountHeaderContainer
      mobileView={mobileView}
      mobileViewLaptop={mobileViewLaptop}
      variant="scrollable"
      scrollButtons="auto"
    >
      <StyledNavLink mobileView={mobileView} to="/account/profile">
        My Profile
      </StyledNavLink>
      <StyledNavLink mobileView={mobileView} to="/account/billing">
        Billing
      </StyledNavLink>
      <StyledNavLink mobileView={mobileView} to="/account/notifications">
        Notifications
      </StyledNavLink>
      <StyledNavLink mobileView={mobileView} to="/account/video">
        Video
      </StyledNavLink>
      <StyledNavLink mobileView={mobileView} to="/account/memberships">
        Memberships
      </StyledNavLink>
    </StyledAccountHeaderContainer>
  );
}

function AccountHeader() {
  return (
    <>
      <Can roleName={UserRoles.client} yes={ClientMenu} />
      <Can roleName={UserRoles.cohealer} yes={CohealerMenu} />
    </>
  );
}

export default AccountHeader;
