import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';

import * as calendarsActions from 'actions/calendars';
import { AddCalendarModalContent } from './AddCalendarModalContent';
import { ADD_CALENDAR_MODAL_FIELDS } from './AddCalendarModalConstants';

const AddCalendarModal = ({
  isOpen,
  onModalClose,
  onSubmit,
  accountsForConflictsCheck,
  accountForSendReminders,
  putMailsForConfictCheck,
  putMailForCalendarReminders,
  lastAddedMail,
  oneToOneStartDate,
  oneToOneEndDate,
  ...restProps
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const handleSubmit = (e, formProps) => {
    e.preventDefault();

    putMailsForConfictCheck(
      formProps.values.checkedCalendars,
      moment(oneToOneStartDate).format('YYYY-MM-DD'),
      moment(oneToOneEndDate).format('YYYY-MM-DD'),
    );
    putMailForCalendarReminders(formProps.values.calendar);
    onSubmit();
  };

  const preparedCheckedCalendars = accountsForConflictsCheck.map(account => account.emailAddress);

  return (
    <Formik
      initialValues={{
        [ADD_CALENDAR_MODAL_FIELDS.CHECKED_CALENDARS]: lastAddedMail ? [lastAddedMail] : preparedCheckedCalendars,
        [ADD_CALENDAR_MODAL_FIELDS.CHECKED_CALENDARS_ADD]: ['Add calendar'],
        [ADD_CALENDAR_MODAL_FIELDS.CALENDAR]: accountForSendReminders,
      }}
      // enableReinitialize
    >
      {formProps => (
        <Modal
          title="Add my calendar"
          isOpen={isOpen}
          onCancel={onModalClose}
          onSubmit={e => handleSubmit(e, formProps)}
          submitTitle={mobileView ? 'Add' : 'Add calendar'}
        >
          <AddCalendarModalContent
            {...restProps}
            setFieldValue={formProps.setFieldValue}
            lastAddedMail={lastAddedMail}
            values={formProps.values}
            preparedCheckedCalendars={preparedCheckedCalendars}
          />
        </Modal>
      )}
    </Formik>
  );
};

AddCalendarModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  oneToOneStartDate: PropTypes.shape(),
  oneToOneEndDate: PropTypes.shape(),
};

const mapStateToProps = ({ calendars, oneToOneData }) => ({
  accountsForConflictsCheck: calendars.accountsForConflictsCheck,
  accountForSendReminders: calendars.accountForSendReminders,
  oneToOneStartDate: oneToOneData?.startDay,
  oneToOneEndDate: oneToOneData?.endDay,
});

const actions = {
  putMailsForConfictCheck: calendarsActions.putMailsForConfictCheck,
  putMailForCalendarReminders: calendarsActions.putMailForCalendarReminders,
};

export default connect(mapStateToProps, actions)(AddCalendarModal);
