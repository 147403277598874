import * as yup from 'yup';

import { PAYMENT_OPTIONS } from 'constants.js';
import moment from 'moment';

const liveSessionsValidationSchema = yup.object().shape({
  enrollment: yup.object().shape({
    fromDate: yup.date().typeError('Field must not be empty').required(),
    toDate: yup
      .date()
      .typeError('Field must not be empty')
      .min(yup.ref('fromDate'), 'This time must be later than from date')
      .required(),
  }),
  sessions: yup
    .array()
    .of(
      yup.object().shape({
        minParticipantsNumber: yup
          .number()
          .min(1, 'Value should be at least ${min}')
          .max(yup.ref('maxParticipantsNumber'), 'Value should be less than max')
          .required('This is a required field'),
        maxParticipantsNumber: yup
          .number()
          // .max(100, 'Value should not exceed ${max}')
          .min(yup.ref('minParticipantsNumber'), 'Value should be more than min')
          .required('This is a required field'),
        title: yup
          .string()
          .strict(true)
          .min(2, 'Value should be longer than ${min} characters')
          .required('This is a required field'),
        sessionTimes: yup
          .array()
          .of(
            yup.object().shape({
              startTime: yup.date().required(),
              endTime: yup.date().min(yup.ref('startTime'), 'This time must be later than start time').required(),
            }),
          )
          .min(1, 'Please add a session time')
          .required(),
      }),
    )
    .min(1, 'Please add a session time')
    .required(),
});

const oneToOneSessionsValidationSchema = yup.object().shape({
  sessions: yup
    .array()
    .of(
      yup.object().shape({
        durations: yup.number().required('This is a required field'),
        sessionTimes: yup
          .array()
          .of(
            yup.object().shape({
              startDate: yup.date().required(),
              endDate: yup
                .date()
                .when('startDate', st => {
                  return yup.date().min(moment(st).subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss'));
                })
                .required(),
              startTime: yup.date().required(),
              endTime: yup.date().min(yup.ref('startTime'), 'This time must be later than start time').required(),
            }),
          )
          .min(0, 'Please add a session time'),
      }),
    )
    .min(1)
    .required(),
});

const membershipSessionsValidationSchema = yup.object().shape({
  enrollment: yup.object().shape({
    anyTime: yup.bool(),
    fromDate: yup.date().typeError('Field must not be empty').required(),
    toDate: yup
      .date()
      .typeError('Field must not be empty')
      .min(yup.ref('fromDate'), 'This time must be later than from date')
      .required(),
  }),
  sessions: yup
    .array()
    .of(
      yup.object().shape({
        minParticipantsNumber: yup
          .number()
          .min(1, 'Value should be at least ${min}')
          .max(yup.ref('maxParticipantsNumber'), 'Value should be less than max')
          .required('This is a required field'),
        maxParticipantsNumber: yup
          .number()
          // .max(100, 'Value should not exceed ${max}')
          .min(yup.ref('minParticipantsNumber'), 'Value should be more than min')
          .required('This is a required field'),
        title: yup
          .string()
          .strict(true)
          .min(2, 'Value should be longer than ${min} characters')
          .required('This is a required field'),
        sessionTimes: yup
          .array()
          .of(
            yup.object().shape({
              startTime: yup.date().required(),
              endTime: yup.date().min(yup.ref('startTime'), 'This time must be later than start time').required(),
            }),
          )
          .min(1, 'Please add a session time')
          .required(),
      }),
    )
    .min(1, 'Please add a session time')
    .required(),
});
const membershipPaymentValidationSchema = yup.object().shape({
  paymentOptions: yup
    .array()
    .of(
      yup
        .mixed()
        .oneOf([
          PAYMENT_OPTIONS.PER_DAY,
          PAYMENT_OPTIONS.PER_WEEK,
          PAYMENT_OPTIONS.PER_MONTH,
          PAYMENT_OPTIONS.PER_YEAR,
          PAYMENT_OPTIONS.PACKAGE,
        ]),
    )
    .min(1),
  perMonthCost: yup.number().when('paymentOptions', {
    is: values => values.includes(PAYMENT_OPTIONS.PER_MONTH),
    then: yup
      .number()
      .min(1, 'Value should be more than 1')
      .integer('Please only enter numbers')
      .required('This is a required field'),
    otherwise: yup.number().nullable(),
  }),
  perYearCost: yup.number().when('paymentOptions', {
    is: values => values.includes(PAYMENT_OPTIONS.PER_YEAR),
    then: yup
      .number()
      .min(1, 'Value should be more than 1')
      .integer('Please only enter numbers')
      .required('This is a required field'),
    otherwise: yup.number().nullable(),
  }),
  membershipPackage: yup.object().when('paymentOptions', {
    is: values => values.includes(PAYMENT_OPTIONS.PACKAGE),
    then: yup
      .object()
      .required()
      .shape({
        duration: yup.number().required(),
        period: yup.string().required(),
        cost: yup
          .number()
          .min(1, 'Value should be more than 1')
          .integer('Please only enter numbers')
          .required('This is a required field'),
      }),
    otherwise: yup.object().nullable(),
  }),
});

export {
  liveSessionsValidationSchema,
  oneToOneSessionsValidationSchema,
  membershipSessionsValidationSchema,
  membershipPaymentValidationSchema,
};
