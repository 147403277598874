import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';

import { useHttp, useVideoChat, useShallowEqualSelector } from 'hooks';
import { ContributionType } from 'helpers/constants';
import { setUserNote, clearUserNote } from 'actions/userNotes';
import NoteModalView from 'pages/ContributionView/components/NoteModal/NoteModalView';

import Button from 'components/FormUI/Button';

import './NotesPanel.scss';

const NotesPanel = ({ className, onEnabledChange }) => {
  const dispatch = useDispatch();
  const { request, loading } = useHttp();
  const { contributionId, classId, sessionId, title, type } = useVideoChat();

  const stateNote = useShallowEqualSelector(state => state?.userNotes?.note);

  const classIdToRequest = type === ContributionType.contributionCourse ? sessionId : classId;

  const handleSubmit = useCallback(
    ({ note }) => {
      const noteRequest = () => {
        return R.isEmpty(stateNote)
          ? request('/api/Note', 'POST', {
              contributionId,
              classId: classIdToRequest,
              title,
              textContent: note,
            })
          : request('/api/Note', 'PUT', {
              id: stateNote?.id,
              contributionId,
              classId: classIdToRequest,
              title: stateNote?.title || title,
              textContent: note,
            });
      };

      noteRequest().then(R.compose(dispatch, setUserNote)).catch(console.dir);
    },
    [dispatch, contributionId, classIdToRequest, title, stateNote, request],
  );

  useEffect(() => {
    request(`/api/Note/${contributionId}/${classIdToRequest}`)
      .then(R.compose(dispatch, setUserNote))
      .catch(console.dir);

    return R.compose(dispatch, clearUserNote);
  }, [dispatch, request, contributionId, classIdToRequest]);

  return (
    <div className={classNames(className, 'video-chat-notes-panel')}>
      <p className="video-chat-notes-panel__title">Add a Note</p>
      <NoteModalView note={stateNote} rows={20} onSubmit={handleSubmit} />
      <div className="video-chat-notes-panel__buttons">
        <Button disabled={loading} onClick={() => onEnabledChange(false)} invert autoWidth>
          Cancel
        </Button>
        <Button disabled={loading} form="note-form" type="submit" autoWidth>
          {`${R.isEmpty(stateNote) ? 'Save' : 'Update'} Note`}
        </Button>
      </div>
    </div>
  );
};

NotesPanel.propTypes = {
  className: PropTypes.string.isRequired,
  onEnabledChange: PropTypes.func.isRequired,
};

export default NotesPanel;
