import React from 'react';
import PropTypes from 'prop-types';

import { formatMoney } from 'utils/datesAndMoney';
import Card from 'components/Card/Card';

import './CardSales.scss';

const CardSales = ({ card: { contributionType, netIncomeAmount, moreLink } }) => (
  <Card title={contributionType} moreLink={moreLink} isSales>
    <div className="side side_left">
      <span className="text_bold incomed-amount">{`$${formatMoney(netIncomeAmount)}`}</span>
    </div>
  </Card>
);

CardSales.propTypes = {
  card: PropTypes.shape().isRequired,
};

export default CardSales;
