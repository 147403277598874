import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { colors } from '../../utils/styles';

export const useStyles = makeStyles(theme => ({
  cardBox: {
    height: '100%',
  },
  favIconContainer: {
    position: 'absolute',
    top: -5,
    right: 7,
  },
  icon: {
    marginLeft: '43%',
  },
  topLevel: {
    height: '40%',
    [theme.breakpoints.up(2000)]: {
      height: '45%',
    },
  },
  midLevel: {
    height: '35%',
  },
  bottomLevel: {
    height: '25%',
  },
  selectPlanButton: {
    border: 'none',
    borderRadius: 6,
    padding: '8px 38px',
  },
  enable: {
    color: colors.textMain,
    background: colors.backgroundLightGrey,
    transition: 'all 0.25s',
    '&:hover': {
      background: colors.backgroundGrey,
    },
  },
  disable: {
    color: colors.white,
    background: colors.darkOceanBlue,
    transition: 'all 0.25s',
    '&:hover': {
      background: colors.darkBlue,
    },
  },
  titleMedia: {
    [theme.breakpoints.up(2000)]: {
      fontSize: 30,
    },
  },
  priceMedia: {
    [theme.breakpoints.up(2000)]: {
      fontSize: 30,
    },
    [theme.breakpoints.down(600)]: {
      '&>span': {
        fontSize: 13,
      },
    },
  },
  feeMedia: {
    [theme.breakpoints.up(2000)]: {
      fontSize: 24,
    },
  },
  upgradeButtonWrapper: {
    display: 'grid',
    justifyContent: 'center',
  },
}));

export const StyledContainer = styled.div`
  height: 400px;

  width: 100%;

  padding: 18px 30px 31px 25px;
  position: relative;
  background: ${colors.white};

  border: 1px solid ${colors.border};
  border-radius: 8px;
`;

export const TitleField = styled.p`
  margin: 0px;
  padding-bottom: 4px;

  font-size: 22px;
  font-weight: bolder;
`;

export const PriceField = styled.p`
  margin: 0px;
  padding-bottom: 4px;

  font-size: 24px;
  font-weight: bolder;

  & > span {
    font-size: 16px;
    font-weight: normal;
    color: ${colors.textSecondary};
  }
`;

export const FeeField = styled.p`
  margin: 0px;
  padding-bottom: 4px;
`;

export const AdvantagesList = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 5px 0px 5px 0px;
`;

export const ExpiresLabel = styled.p`
  color: ${colors.textSecondary};
  font-size: 14px;
  text-align: center;
`;
