import React, { useCallback } from 'react'

import * as bankAccountsService from 'services/bankAccounts.service'
import { redirectInNewTab } from 'services/links'
import { StyledTitle, StyledButton } from '../GettingStarted.styles'
import styled from 'styled-components'

const StyledImage = styled.img`
  margin-top: 20px;
  height: 60%;

  ${({ mobileView }) =>
    mobileView &&
    `
    height: auto;
    width: 80%;
  `}
`

const Verification = ({ mobileView }) => {
  const handleConnectStripeModalSubmit = useCallback(
    () =>
      bankAccountsService
        .verifyStripeAccount()
        .then(({ link }) => redirectInNewTab(link))
        .catch((err) => {
          console.dir(err)
        }),
    []
  )

  return (
    <>
      <StyledImage src="/get-started-verify.png" mobileView={mobileView} />

      <StyledTitle mobileView={mobileView}>
        Verify your information. This allows you to create services and receive payments.
      </StyledTitle>

      <StyledButton onClick={handleConnectStripeModalSubmit} variant="primary">
        Verify Info
      </StyledButton>
    </>
  )
}

export default Verification
