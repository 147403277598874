import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as contributionActions from 'actions/contributions';
import useRouter from './useRouter';

export const useSaveContribution = (canSave, hasAgreedContributionTerms, customToS) => {
  const dispatch = useDispatch();
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const user = useSelector(state => state.account?.user);
  const { query } = useRouter();
  const [saving, setSaving] = useState(false);
  const CONTRIBUTION_STATUSES = {
    DRAFT: 'Draft',
  };

  const onSave = useCallback(
    (contribution, redirect) => {
      if (!canSave) {
        return;
      }

      setSaving(true);
      const data = {
        ...(contribution || activeContribution),
        userId: user.id,
        customToS: customToS ? customToS.fileUrl : (contribution || activeContribution).customToS,
      };

      data.hasAgreedContributionTerms =
        hasAgreedContributionTerms === undefined && data.status !== CONTRIBUTION_STATUSES.DRAFT
          ? true
          : hasAgreedContributionTerms;

      const redirectByAction = action => {
        if (redirect) {
          redirect(action, data);
        }
        setSaving(false);
      };

      if (query.id) {
        delete data.serviceProviderName;
        delete data.timeZoneId;
        delete data.participants;

        dispatch(contributionActions.updateContribution(query.id, data)).then(redirectByAction);
      } else {
        dispatch(contributionActions.createContribution(data)).then(redirectByAction);
      }
    },
    [dispatch, activeContribution, query, setSaving, user.id, hasAgreedContributionTerms, canSave],
  );

  return { onSave, saving };
};
