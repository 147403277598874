import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MicOffIcon from '@material-ui/icons/MicOff'
import NetworkQualityIndicator from './NetworkQualityIndicator'

import ParticipantVideoTrackType from './ParticipantVideoTrackType'
import ParticipantDisplayMode from './ParticipantDisplayMode'
import getActualVideoTrack from './getActualVideoTrack'
import { AudioLevelIndicator } from '../../Toolbar/devices/AudioLevelIndicator'
import './Participant.scss'

const Participant = React.forwardRef(
  (
    {
      className,
      identity,
      audioTrack,
      isAudioEnabled,
      audioVolume,
      videoTrack,
      isVideoEnabled,
      isVideoMirrored,
      screenTrack,
      isScreenEnabled,
      preferredVideoTrack,
      dominant,
      displayMode,
      audioMediaStreamTrack,
      selectedAudioOutputDevice,
      networkQualityIndicator,
    },
    ref
  ) => {
    const {
      actualVideoTrack,
      isActualVideoTrackEnabled,
      isActualVideoTrackMirrored,
      actualVideoTrackType,
    } = getActualVideoTrack(
      { videoTrack, isVideoEnabled, isVideoMirrored },
      { screenTrack, isScreenEnabled },
      preferredVideoTrack
    )

    const audioTrackRef = useRef()
    useEffect(() => {
      if (ParticipantDisplayMode.hasFlag(displayMode, ParticipantDisplayMode.audio) && audioTrack) {
        const audioNode = audioTrackRef.current

        if (audioNode) {
          audioTrack.attach(audioNode)

          return () => {
            audioTrack.detach(audioNode)
          }
        }
      }
    }, [displayMode, audioTrack])

    useEffect(() => {
      const audioNode = audioTrackRef.current

      if (audioNode) {
        audioNode.volume = audioVolume
      }

      if (audioNode && selectedAudioOutputDevice && selectedAudioOutputDevice.id) {
        audioNode
          .setSinkId(selectedAudioOutputDevice.id)
          .then(() => {
            console.log('Audio is being played on ' + audioNode.sinkId)
          })
          .catch(console.dir)
      }
    })

    const videoTrackRef = useRef()
    useEffect(() => {
      if (ParticipantDisplayMode.hasFlag(displayMode, ParticipantDisplayMode.video) && actualVideoTrack) {
        const videoNode = videoTrackRef.current

        if (videoNode) {
          actualVideoTrack.attach(videoNode)

          return () => {
            actualVideoTrack.detach(videoNode)
          }
        }
      }
    }, [displayMode, actualVideoTrack])

    return (
      <div
        ref={ref}
        className={classNames(className, 'video-chat-participant', { 'video-chat-participant--dominant': dominant })}
      >
        {ParticipantDisplayMode.hasFlag(displayMode, ParticipantDisplayMode.audio) && audioTrack && (
          <audio ref={audioTrackRef} autoPlay />
        )}
        {ParticipantDisplayMode.hasFlag(displayMode, ParticipantDisplayMode.video) && actualVideoTrack && (
          <video
            ref={videoTrackRef}
            className={classNames('video-chat-participant__video', {
              'video-chat-participant__video--mirrored': isActualVideoTrackMirrored,
              'video-chat-participant__video--type--camera': actualVideoTrackType === ParticipantVideoTrackType.camera,
              'video-chat-participant__video--type--screen':
                actualVideoTrackType === ParticipantVideoTrackType.screenShare,
            })}
            autoPlay
          />
        )}
        {!isActualVideoTrackEnabled && (
          <p className="video-chat-participant__identity video-chat-participant__no-video-identity" title={identity}>
            {identity}
          </p>
        )}
        <div className="video-chat-participant__toolbar video-chat-participant-toolbar">
          {!isAudioEnabled && <MicOffIcon className="video-chat-participant-toolbar__muted-mic" color="error" />}
          {isAudioEnabled && audioMediaStreamTrack && audioTrack && (
            <AudioLevelIndicator audioMediaStreamTrack={audioMediaStreamTrack} localAudioTrack={audioTrack} />
          )}
          {isActualVideoTrackEnabled && (
            <p className="video-chat-participant__identity video-chat-participant-toolbar__identity" title={identity}>
              {identity}
            </p>
          )}
        </div>
        <NetworkQualityIndicator level={networkQualityIndicator} />
      </div>
    )
  }
)

const trackPropType = PropTypes.shape({
  attach: PropTypes.func,
  detach: PropTypes.func,
})

Participant.propTypes = {
  className: PropTypes.string.isRequired,
  identity: PropTypes.string.isRequired,
  audioTrack: trackPropType,
  isAudioEnabled: PropTypes.bool.isRequired,
  audioVolume: PropTypes.number.isRequired,
  videoTrack: trackPropType,
  isVideoEnabled: PropTypes.bool.isRequired,
  isVideoMirrored: PropTypes.bool.isRequired,
  screenTrack: trackPropType,
  isScreenEnabled: PropTypes.bool.isRequired,
  preferredVideoTrack: PropTypes.oneOf([ParticipantVideoTrackType.camera, ParticipantVideoTrackType.screenShare]),
  dominant: PropTypes.bool,
  displayMode: PropTypes.oneOf([
    ParticipantDisplayMode.audio,
    ParticipantDisplayMode.video,
    ParticipantDisplayMode.audioAndVideo,
  ]),
  audioMediaStreamTrack: PropTypes.shape({}),
  selectedAudioOutputDevice: PropTypes.shape({}),
}

Participant.defaultProps = {
  audioTrack: null,
  videoTrack: null,
  screenTrack: null,
  preferredVideoTrack: ParticipantVideoTrackType.camera,
  dominant: false,
  displayMode: ParticipantDisplayMode.audioAndVideo,
  audioMediaStreamTrack: undefined,
}

export default Participant
