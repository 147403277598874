import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { DATE_FORMATS } from '../../../../utils/datesAndMoney';

const useDiffDate = (createTime, timeZoneId) => {
  const [createDataDiff, setCreateDataDiff] = useState(null);
  useEffect(() => {
    const admission = moment().format(DATE_FORMATS.POST_TIME);
    const discharge = moment(createTime).format(DATE_FORMATS.POST_TIME);
    const ms = moment(admission, DATE_FORMATS.POST_TIME).diff(moment(discharge, DATE_FORMATS.POST_TIME));
    const d = moment.duration(ms);
    const day = Math.floor(d.asDays());
    const hours = Math.floor(d.asHours());
    const minutes = moment.utc(ms).format('m');
    if (day === 0) {
      setCreateDataDiff(`${hours}h ${minutes}m`);
    } else {
      setCreateDataDiff(moment(createTime).tz(timeZoneId).format(DATE_FORMATS.DATE_TIME));
    }
    if (day === 0 && hours === 0) {
      setCreateDataDiff(`${minutes}m`);
    }
    if (day === 0 && hours === 0 && minutes === '0') {
      setCreateDataDiff('just now');
    }
  }, [createTime, setCreateDataDiff]);
  return { createDataDiff };
};

export default useDiffDate;
