import moment from 'moment';

import { parseTimeFrom12hTo24h } from 'utils/utils';
import { DATE_FORMATS } from './datesAndMoney';

export const getCurrentSessionTime = (isOneToOne, currentDate = moment()) => {
  const startDate = currentDate.clone().add(1, 'd').format(DATE_FORMATS.DATE_TIME_LOCAL);
  const endDate = currentDate.clone().add(2, 'd').format(DATE_FORMATS.DATE_TIME_LOCAL);

  return {
    startTime: moment(startDate).hour(9).minute(0).format(DATE_FORMATS.DATE_TIME_LOCAL),
    endTime: moment(isOneToOne ? endDate : startDate)
      .hour(10)
      .minute(0)
      .format(DATE_FORMATS.DATE_TIME_LOCAL),
    startDate,
    endDate,
  };
};

export const getNextCurrentSessionTime = (isOneToOne, initialTime) => {
  const time = moment(initialTime);
  const defaultStartTime = time.format('hh:mm');
  const defaultEndTime = time.clone().add(1, 'h').format('hh:mm');
  const defaultMeridiem = time.format('a');
  const parsedStartTimeTo24h = parseTimeFrom12hTo24h(`${defaultStartTime} ${defaultMeridiem}`);
  const parsedEndTimeTo24h = parseTimeFrom12hTo24h(`${defaultEndTime} ${defaultMeridiem}`);
  const startDateMoment = time.clone().add(1, 'd');
  const endDateMoment = startDateMoment.clone().add(1, 'd');
  const startDate = startDateMoment.startOf('minute').format();
  const endDate = endDateMoment.startOf('minute').format();
  const formStartTime = moment(`${moment(startDate).format('YYYY-MM-DD')} ${parsedStartTimeTo24h}`).format(
    'YYYY-MM-DDTHH:mm:ss',
  );
  const formEndTime = moment(`${moment(endDate).format('YYYY-MM-DD')} ${parsedEndTimeTo24h}`).format(
    'YYYY-MM-DDTHH:mm:ss',
  );

  return {
    startDate,
    endDate,
    startTime: isOneToOne ? moment(formStartTime).add(1, 'h').format('YYYY-MM-DDTHH:mm:ss') : formStartTime,
    endTime: isOneToOne
      ? moment(formEndTime).add(1, 'h').format('YYYY-MM-DDTHH:mm:ss')
      : moment(formStartTime).add(1, 'h').format('YYYY-MM-DDTHH:mm:ss'),
  };
};
