import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import { SignUpSecondPage, SignInPage } from 'pages'
import * as userActions from 'actions/user'
import SignUpContainer from '../Containers/SignUpContainer'
import LogOut from './LogOut'
import RoleSwitch from './RoleSwitch'
import AppContent from './AppContent'
import { ROUTES } from '../../constants'

function Routes({ clearUserErrors }) {
  const history = useHistory()

  useEffect(() => {
    clearUserErrors()
  }, [clearUserErrors])

  useEffect(() => {
    history.listen(() => {
      clearUserErrors()
    })
  }, [history, clearUserErrors])
  return (
    <Switch>
      <Route path="/auth" component={SignUpContainer} />
      <Route path="/logout" component={LogOut} />
      <Route path={ROUTES.ROLE_SWITCH} component={RoleSwitch} />
      <Route exact path={ROUTES.ADD_ROLE} component={SignUpSecondPage} />
      <Route exact path={ROUTES.SUBMIT_ADD_ROLE} component={SignInPage} />
      <Route component={AppContent} />
    </Switch>
  )
}

Routes.propTypes = {
  clearUserErrors: PropTypes.func.isRequired,
}

Routes.defaultProps = {}

const mapStateToProps = ({ account }) => ({
  userId: account?.user?.id,
  error: account?.error,
})

const actions = {
  clearUserErrors: userActions.clearUserErrors,
}

export default connect(mapStateToProps, actions)(Routes)
