import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import ShareModal from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import { shareViaEmail } from 'services/contributions.service';
import ContributionViewMainContainer from './ContributionViewMainContainer';

function ContributionViewContainer({
  match: {
    params: { id },
  },
}) {
  const [modalOpened, setModalOpened] = useState(false);
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });

  const handleModalClose = useCallback(() => {
    setEmailsError({
      isError: false,
      errorMessage: null,
    });
    setModalOpened(false);
  }, [setModalOpened]);

  const handleShareButtonClick = useCallback(e => {
    e.preventDefault();

    setModalOpened(true);
  }, []);

  const handleModalSubmit = useCallback(
    emails => {
      shareViaEmail({ contributionId: id, emailAddresses: emails })
        .then(() => {
          handleModalClose();
        })
        .catch(err => {
          setEmailsError({
            isError: true,
            errorMessage: err?.response?.data?.message,
          });
        });
    },
    [handleModalClose, id],
  );

  return (
    <ContributionViewMainContainer handleShareButtonClick={handleShareButtonClick}>
      {modalOpened && (
        <ShareModal
          isOpen={modalOpened}
          onSubmit={handleModalSubmit}
          onModalClose={handleModalClose}
          emailsError={emailsError}
          setEmailsError={setEmailsError}
          id={id}
        />
      )}
    </ContributionViewMainContainer>
  );
}

ContributionViewContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
};

export default ContributionViewContainer;
