import React from 'react';
import { useDispatch } from 'react-redux';

import { startTestVideoChat } from 'actions/testVideoChart';
import Button from 'components/FormUI/Button';

export const Video = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(startTestVideoChat());
  };

  return (
    <div>
      <p>
        The “Test Video” button will open a test session room that will allow you to confirm video works with your
        Computer or Tablet. You will also be able to familiarize yourself with how to change the default microphone
        and/or camera with your setup.
      </p>
      <p>
        <b>Note: if you plan to screen share, we highly recommend you use the browser Chrome.</b> If you use Mac OS, you
        will need to allow Chrome access to screen recording. You can do this by going to System Preferences &gt;
        Security & Privacy &gt; Privacy &gt; Screen Recording. Check the option for your browser (e.g. if you use
        Chrome, select Chrome).
      </p>
      <p>
        TIP: When screen sharing, we recommend selecting “Application Window” and not “Your Entire Screen”. This way
        your clients will only view a PDF, PowerPoint, or any other applications that you wish to share. If you decide
        to share “Your Entire Screen” please note clients will be able to view your browser and any private notes.
      </p>
      <Button onClick={onClick}>Test video</Button>
    </div>
  );
};
