import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { useHttp, useAccount } from 'hooks';
import * as actions from 'actions/contributions';
import { downloadFile } from 'utils/utils';
import { UserRoles } from 'helpers/constants';
import * as contentService from 'services/content.service';

/**
 * Max size limit on backend is 26mb
 */
const MAX_FILES_SIZE_IN_BYTES = 5368706371;

const isFilesSizeLimitOrLess = R.compose(sum => sum <= MAX_FILES_SIZE_IN_BYTES, R.sum, R.map(R.prop('size')));

export const useSessionAttachments = (sessionId, contributionId, isOneToOne) => {
  const dispatch = useDispatch();
  const { request, loading } = useHttp();
  const { currentRole } = useAccount();
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [progress, setProgress] = useState(null);
  const handleIsFileLoad = () => {
    setIsFileUpload(false);
    setProgress(null);
  };

  const handleUpdateProgress = progressData => {
    setProgress(progressData);
  };

  const handleUploadFile = useCallback(
    ({ target: { files } }) => {
      const formData = new FormData();

      if (isFilesSizeLimitOrLess(files)) {
        R.values(files).forEach(file => formData.append('file', file));
        formData.append('sessionId', sessionId);
        formData.append('contributionId', contributionId);
        setIsFileUpload(true);
        contentService
          .addFileToSession(formData, handleUpdateProgress)
          .then(
            R.compose(dispatch, isOneToOne ? actions.updateOneToOneSessionAttachments : actions.addSessionAttachments),
          )
          .then(handleIsFileLoad)
          .catch(data => {
            handleIsFileLoad();
            console.dir(data);
          });
      }
    },
    [contributionId, dispatch, request, sessionId],
  );

  const handleDownloadFile = ({
    currentTarget: {
      dataset: { documentId, documentName, contentType },
    },
  }) => {
    request(
      `/Content/DownloadAttachment?DocumentId=${documentId}&ContributionId=${contributionId}&SessionId=${sessionId}`,
      'GET',
      null,
      {},
      'blob',
    )
      .then(res => downloadFile(res, documentName, contentType))
      .catch(console.dir);
  };

  const handleRemoveFile = ({
    currentTarget: {
      dataset: { documentId, documentExtension },
    },
  }) => {
    request('/Content/DeleteAttachment', 'POST', {
      documentKeyWithExtension: documentExtension,
      documentId,
      contributionId,
      sessionId,
    })
      .then(() => {
        R.compose(
          dispatch,
          isOneToOne ? actions.updateOneToOneSessionAttachments : actions.updateSessionAttachments,
        )({ sessionId, documentId });
      })
      .catch(console.dir);
  };

  return {
    isFileUpload,
    progress,
    loading,
    isCoach: currentRole === UserRoles.cohealer,
    handleUploadFile,
    handleDownloadFile,
    handleRemoveFile,
  };
};
