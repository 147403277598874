import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHeader, useEndVideoChat } from 'hooks';
import { fetchUpcomingCreated } from 'actions/contributions';

import Loader from 'components/UI/Loader';
import Banner from 'components/Banner/Banner';
import ContributionsTable from 'components/Tables/Contributions';

const AllCoursesPage = ({ contributions, closestClassForBanner, getContributions, loading, userId }) => {
  useHeader('My Contributions');

  const renderList = useCallback(async () => {
    getContributions(userId);
  }, [getContributions, userId]);

  const { Popup } = useEndVideoChat(renderList);

  useEffect(() => {
    renderList();
  }, [renderList]);

  if (loading) {
    return <Loader />;
  }

  if (!contributions || !contributions.length) {
    return <div>There are no contributions yet.</div>;
  }

  return (
    <>
      <Banner type="closestSession" closestClassForBanner={closestClassForBanner} />
      <ContributionsTable rows={contributions} />
      <Popup />
    </>
  );
};

const mapStateToProps = ({ upcomingCreated: { loading, error, upcomingCreated }, account }) => ({
  userId: account?.user?.id,
  loading,
  error,
  contributions: upcomingCreated?.contributionsForTable || [],
  closestClassForBanner: upcomingCreated?.closestClassForBanner || {},
});

const actions = {
  getContributions: fetchUpcomingCreated,
};

AllCoursesPage.propTypes = {
  userId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  contributions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getContributions: PropTypes.func.isRequired,
  closestClassForBanner: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, actions)(AllCoursesPage);
