import { ContributionType } from 'helpers/constants';
import { ROUTES } from '../constants';

const getBackPages = (type, isClient) => {
  if (isClient) {
    return {
      title: 'My Journey',
      link: ROUTES.DASHBOARD,
    };
  }

  switch (type) {
    case ContributionType.contributionCourse:
      return {
        title: 'Group Courses',
        link: ROUTES.CONTRIBUTIONS_GROUP_COURSES,
      };
    case ContributionType.contributionWebinar:
      return {
        title: 'Webinars',
        link: ROUTES.CONTRIBUTIONS,
      };
    case ContributionType.contributionOneToOne:
      return {
        title: '1:1 Sessions',
        link: ROUTES.CONTRIBUTIONS_1_TO_1,
      };
    case ContributionType.contributionLocalEvent:
      return {
        title: 'Local Events',
        link: ROUTES.CONTRIBUTIONS,
      };
    case ContributionType.contributionMembership:
      return {
        title: 'Membership',
        link: ROUTES.CONTRIBUTIONS,
      };
    default:
      return '';
  }
};

export default getBackPages;
