import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';
import { wrapLink } from '../utils/utils';

const getContributions = id => axiosInstance.get(`/Contribution/GetAllCreatedByUserId/${id}`).then(get('data'));

const getUpcomingCreated = id => axiosInstance.get(`/Contribution/GetUpcomingCreatedByUserId/${id}`).then(get('data'));

const getUpcomingCreatedWithType = (id, type) =>
  axiosInstance.get(`/Contribution/GetUpcomingCreatedByUserId/${id}/${type}`).then(get('data'));

const getArchived = id => axiosInstance.get(`/Contribution/GetArchivedCreatedByUserId/${id}`).then(get('data'));

const getContribution = id => axiosInstance.get(`/Contribution/GetContribById/${id}`).then(get('data'));

const getCohealerContribution = id => axiosInstance.get(`/Contribution/GetCohealerContribById/${id}`).then(get('data'));

const getClientContribution = id => axiosInstance.get(`/Contribution/GetClientContribById/${id}`).then(get('data'));

const getCoachContributionTimes = (id, offset = 0) =>
  axiosInstance
    .post(`/Contribution/${id}/GetSlots`, null, {
      params: { offset },
      headers: { 'Content-Type': 'application/json' },
    })
    .then(get('data'));

const getClientContributionTimes = (id, offset = 0) =>
  axiosInstance
    .post(`/Contribution/${id}/GetClientSlots`, null, {
      params: { offset },
      headers: { 'Content-Type': 'application/json' },
    })
    .then(get('data'));

const getCoachEditContributionTimes = (id, oneToOneSessionDataUi) =>
  axiosInstance.post(`/Contribution/${id}/GetSlots`, oneToOneSessionDataUi).then(get('data'));

const calculateSlots = oneToOneSessionDataUi =>
  axiosInstance.post('/Contribution/CalculateSlots', oneToOneSessionDataUi).then(get('data'));

const createContribution = data => axiosInstance.post(`/contribution`, data).then(get('data'));

const updateContribution = (id, data) => axiosInstance.put(`/contribution/${id}`, data).then(get('data'));

const getContributionsAdmin = () => axiosInstance.get('/Contribution/GetForAdmin').then(get('data'));

const purchaseCourse = data => axiosInstance.post('/api/purchase', data).then(get('data'));

const rescheduleSession = data => axiosInstance.post(`/Contribution/RescheduleOneToOneBooking`, data).then(get('data'));

const rescheduleSessionForClient = data =>
  axiosInstance.post(`/Contribution/ClientOneToOneRescheduling`, data).then(get('data'));

const getLastDraftContribution = data =>
  axiosInstance
    .get(`/Contribution/Draft`, data)
    .then(get('data'))
    .catch(() => ({}));

const createDraftContribution = data => axiosInstance.post(`/Contribution/Draft`, data).then(get('data'));

const updateDraftContribution = (id, data) => axiosInstance.put(`/Contribution/Draft`, data).then(get('data'));

const deleteDraftContribution = (id, data) => axiosInstance.delete(`/Contribution/Draft/${id}`, data).then(get('data'));

const submitDraftContribution = ({ timeZoneId, ...body }) =>
  updateDraftContribution(null, {
    ...body,
    instagramUrl: body.instagramUrl ? wrapLink(body.instagramUrl) : '',
    linkedInUrl: body.linkedInUrl ? wrapLink(body.linkedInUrl) : '',
    youtubeUrl: body.youtubeUrl ? wrapLink(body.youtubeUrl) : '',
    facebookUrl: body.facebookUrl ? wrapLink(body.facebookUrl) : '',
    websiteUrl: body.websiteUrl ? wrapLink(body.websiteUrl) : '',
  })
    .then(() => axiosInstance.post(`/Contribution/Draft/${body.id}/Submit`))
    .then(get('data'));

const shareViaEmail = data => axiosInstance.post(`/Contribution/ShareViaEmail`, data).then(get('data'));

const getAllBoughtByUserId = userId =>
  axiosInstance.get(`/Contribution/GetAllBoughtByUserId/${userId}`).then(get('data'));

const getClientPurchases = clientUserId =>
  axiosInstance.get(`/Contribution/GetClientPurchases/${clientUserId}`).then(get('data'));

const bookSessionTime = data => axiosInstance.post(`/Contribution/BookSessionTime`, data).then(get('data'));

const unbookSessionTime = data => axiosInstance.post(`/Contribution/RevokeBookSessionTime`, data).then(get('data'));

const getBoughtByType = type => axiosInstance.get(`/Contribution/GetBoughtByType/${type}`).then(get('data'));

const joinContribution = data =>
  axiosInstance.post(`/ContributionAccess/${data.id}/join`, { accessCode: data.accessCode }).then(get('data'));

const deleteClient = (contributionId, userId) =>
  axiosInstance.delete(`/ContributionAccess/${contributionId}/${userId}`).then(get('data'));

const createAccessCode = data => axiosInstance.post(`/ContributionAccess/${data}/CreateAccessCode`).then(get('data'));

export {
  getClientPurchases,
  getContributions,
  getUpcomingCreated,
  getUpcomingCreatedWithType,
  getArchived,
  getContribution,
  updateContribution,
  createContribution,
  getContributionsAdmin,
  purchaseCourse,
  getCohealerContribution,
  getClientContribution,
  rescheduleSession,
  rescheduleSessionForClient,
  getCoachContributionTimes,
  getClientContributionTimes,
  getCoachEditContributionTimes,
  calculateSlots,
  getLastDraftContribution,
  createDraftContribution,
  updateDraftContribution,
  deleteDraftContribution,
  submitDraftContribution,
  shareViaEmail,
  getAllBoughtByUserId,
  bookSessionTime,
  unbookSessionTime,
  getBoughtByType,
  joinContribution,
  deleteClient,
  createAccessCode,
};
