import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { useAccount, useRouter } from 'hooks';
import * as userService from 'services/user.service';
import * as accountPreferences from 'services/accountPreferences.service';
import * as userActions from 'actions/user';
import { UserRoles } from 'helpers/constants';
import { ROUTES } from '../../constants';

function RoleSwitch() {
  const dispatch = useDispatch();
  const { roles, currentRole } = useAccount();
  const {
    location: { state },
  } = useRouter();
  const path = state ? state.path : null;
  const [redirectTo, setRedirectTo] = useState(null);
  const isCoachAndClientRolesAvailable = roles.includes(UserRoles.client) && roles.includes(UserRoles.cohealer);

  useEffect(() => {
    if (isCoachAndClientRolesAvailable && currentRole === UserRoles.cohealer) {
      Promise.all([
        userService.switchFromCoachToClient(),
        accountPreferences.setAccountPreferences({ userView: UserRoles.client }),
      ])
        .then(([auth, preferences]) => {
          dispatch(userActions.roleChange(preferences.userView));
          setRedirectTo(path || ROUTES.DASHBOARD);
        })
        .catch(() => setRedirectTo(ROUTES.DASHBOARD));
    } else if (isCoachAndClientRolesAvailable && currentRole === UserRoles.client) {
      Promise.all([
        userService.switchFromClientToCoach({}),
        accountPreferences.setAccountPreferences({ userView: UserRoles.cohealer }),
      ])
        .then(([auth, preferences]) => {
          dispatch(userActions.roleChange(preferences.userView));
          setRedirectTo(path || ROUTES.DASHBOARD);
        })
        .catch(() => setRedirectTo(path || ROUTES.DASHBOARD));
    } else if (!isCoachAndClientRolesAvailable && currentRole === UserRoles.client) {
      setRedirectTo(ROUTES.ADD_ROLE);
    }
  }, [setRedirectTo]);

  return redirectTo ? <Redirect to={redirectTo} /> : null;
}

export default RoleSwitch;
