import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as R from 'ramda';
import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';

import VideoButton from 'components/UI/ListButtons/VideoButton';
import ChatButton from 'components/UI/ListButtons/ChatButton';
import { TIMEZONES } from 'constants.js';
import { useRouter } from 'hooks';
import { useRecordModal } from 'hooks/useSessionRecordModal';

const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})(TableCell);

const DATE_FORMAT = 'MMM Do YYYY';
const TIME_FORMAT = 'h:mm a';

const serializeTimeZone = R.compose(R.join(''), R.map(R.head), R.split(' '));

export const Session = ({
  contributionId,
  contributionName,
  title,
  chatSid,
  startTime,
  timezoneId,
  onLaunchSession,
  type,
  classGroupId,
  classId,
  liveVideoServiceProvider,
}) => {
  const { history } = useRouter();
  const onSubmit = () => {
    onLaunchSession(contributionId, type, classGroupId, classId, chatSid, title, { RecordParticipantsOnConnect: true });
  };
  const onCancel = () => {
    onLaunchSession(contributionId, type, classGroupId, classId, chatSid, title, {
      RecordParticipantsOnConnect: false,
    });
  };
  const modalOptions = {
    onSubmit,
    onCancel,
    classId,
    contributionType: type,
    contributionId,
    liveVideoServiceProvider,
  };
  const { RecordModal, launchRecodModal } = useRecordModal(modalOptions);

  return (
    <>
      <TableRow>
        <NarrowTableCell component="th" scope="row">
          <Link to={`/contribution-view/${contributionId}/about`}>{contributionName}</Link>
        </NarrowTableCell>
        <NarrowTableCell>
          <VideoButton handleClick={launchRecodModal} />
          {title}
        </NarrowTableCell>
        <NarrowTableCell>{moment(startTime).format(DATE_FORMAT)}</NarrowTableCell>
        <NarrowTableCell>{`${moment(startTime).format(TIME_FORMAT)} ${serializeTimeZone(
          TIMEZONES[timezoneId],
        )}`}</NarrowTableCell>
        <NarrowTableCell>
          <ChatButton
            handleClick={() =>
              chatSid &&
              history.push(`/conversations/all`, {
                preferredConversationId: chatSid,
              })
            }
          />
        </NarrowTableCell>
      </TableRow>
      <RecordModal />
    </>
  );
};

Session.propTypes = {
  contributionId: PropTypes.string.isRequired,
  contributionName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  chatSid: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  timezoneId: PropTypes.string.isRequired,
  onLaunchSession: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  classGroupId: PropTypes.string.isRequired,
  classId: PropTypes.string.isRequired,
};
