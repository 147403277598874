import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { mdiHeart, mdiTabletIpad, mdiRun, mdiAccountOutline } from '@mdi/js';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { replaceBreakToBr, wrapText } from 'utils/utils';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import TextBlock from './TextBlock';

const AboutDetailsBlock = ({
  whoIAm,
  whatWeDo,
  purpose,
  preparation,
  whoIAmLabel,
  whatWeDoLabel,
  purposeLabel,
  preparationLabel,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Card>
      <CardHeader mobileView={mobileView}>
        <PageTitleSecond mobileView={mobileView}>Details</PageTitleSecond>
      </CardHeader>
      <CardBody mobileView={mobileView}>
        <Grid container spacing={4}>
          <Grid item md={6} sm={12} xs={12}>
            <TextBlock
              image={mdiAccountOutline}
              text={wrapText(replaceBreakToBr(whoIAm))}
              title={whoIAmLabel}
              mobileView={mobileView}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <TextBlock
              image={mdiHeart}
              text={wrapText(replaceBreakToBr(purpose))}
              title={purposeLabel}
              mobileView={mobileView}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <TextBlock
              image={mdiRun}
              text={wrapText(replaceBreakToBr(whatWeDo))}
              title={whatWeDoLabel}
              mobileView={mobileView}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <TextBlock
              image={mdiTabletIpad}
              title={preparationLabel}
              text={wrapText(replaceBreakToBr(preparation))}
              mobileView={mobileView}
            />
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

AboutDetailsBlock.propTypes = {
  whoIAm: PropTypes.string,
  whatWeDo: PropTypes.string,
  purpose: PropTypes.string,
  preparation: PropTypes.string,
  whoIAmLabel: PropTypes.string,
  whatWeDoLabel: PropTypes.string,
  purposeLabel: PropTypes.string,
  preparationLabel: PropTypes.string,
};

AboutDetailsBlock.defaultProps = {
  whoIAm: 'None',
  whatWeDo: 'None',
  purpose: 'None',
  preparation: 'None',
  whoIAmLabel: 'Bio',
  whatWeDoLabel: 'What to Expect',
  purposeLabel: 'The Purpose',
  preparationLabel: 'How to Prepare',
};

export default AboutDetailsBlock;
