import React, { useCallback, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import moment from 'moment';
import * as yup from 'yup';
import * as R from 'ramda';
import { useTheme, useMediaQuery } from '@material-ui/core';
import lodash from 'lodash';

import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import MainContainer from 'components/Containers/MainContainer';
import * as contributionActions from 'actions/contributions';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { UTCtoLocalDateTime, getTimePeriodsForSessionsCollection } from 'utils/datesAndMoney';
import { useRouter } from 'hooks';
import { ContributionType } from 'helpers/constants';
import { getCurrentSessionTime } from 'utils/getSessionTime';
import Buttons from './components/Buttons';
import LiveForm from './components/LiveForm';
import MembershipForm from './components/MembershipForm/index';
import { OneToOneForm } from './components/OneToOneForm';
import { prepareSessions, generateOneToOneSlots } from './utils';
import { useOtherEvents } from './hooks/useOtherEvents';
import { checkSessionDuration } from './hooks/useCheckSessionDuration';
import {
  liveSessionsValidationSchema,
  oneToOneSessionsValidationSchema,
  membershipSessionsValidationSchema,
} from './contributionValidations';

const serializeTime = R.compose(R.head, R.split('+'));

function Sessions({ contribution, saveContribution, saveContributionToLS, oneToOneData }) {
  const { history, query } = useRouter();
  const contributionFormRef = useRef();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isEditing = Boolean(query.id);
  const { onSave: onSaveContribution } = useSaveContribution(isEditing);
  const [redirectTo, setRedirectTo] = useState(undefined);
  const { otherEvents } = useOtherEvents(contribution?.type === ContributionType.contributionOneToOne);

  const initialValuesLive = useMemo(() => {
    const currentDate = moment();
    return {
      sessions: contribution?.sessions || [
        {
          maxParticipantsNumber: 20,
          minParticipantsNumber: 2,
          sessionTimes: [getCurrentSessionTime(false, currentDate.clone().add(1, 'd'))],
          title: 'Session 1',
        },
      ],
      enrollment: {
        fromDate: contribution?.enrollment?.fromDate || UTCtoLocalDateTime(currentDate.clone().add(0, 'd')),
        toDate: contribution?.enrollment?.toDate || UTCtoLocalDateTime(currentDate.clone().add(1, 'd')),
      },
      liveVideoServiceProvider: contribution?.liveVideoServiceProvider || {
        providerName: 'Cohere',
      },
    };
  }, [contribution]);
  const initialValuesOneToOne = useMemo(
    () => ({
      liveVideoServiceProvider: contribution?.liveVideoServiceProvider || {
        providerName: 'Cohere',
      },
      sessions: contribution?.availabilityTimes
        ? prepareSessions(contribution)
        : [
            {
              durations: [60],
              maxParticipantsNumber: 1,
              minParticipantsNumber: 1,
              sessionTimes: [getCurrentSessionTime(true)],
            },
          ],
    }),
    [contribution],
  );
  const initialValuesMembership = useMemo(() => {
    const currentDate = moment();
    return {
      sessions: contribution?.sessions || [
        {
          maxParticipantsNumber: 20,
          minParticipantsNumber: 2,
          sessionTimes: [getCurrentSessionTime(false, currentDate.clone().add(1, 'd'))],
          title: 'Session 1',
        },
      ],
      enrollment: {
        anyTime: contribution?.enrollment?.anyTime || true,
        fromDate: contribution?.enrollment?.fromDate || UTCtoLocalDateTime(currentDate.clone().add(0, 'd')),
        toDate: contribution?.enrollment?.toDate || UTCtoLocalDateTime(currentDate.clone().add(1, 'd')),
      },
      liveVideoServiceProvider: contribution?.liveVideoServiceProvider || {
        providerName: 'Cohere',
      },
    };
  }, [contribution]);

  const saveHandler = isEditing ? saveContribution : saveContributionToLS;

  const submitLiveHandler = useCallback(
    (values, formikHelpers, to = '') => {
      const data = {
        ...values,
      };
      data.sessions.forEach(session => {
        session.name = 'Session';

        session.sessionTimes.forEach(time => {
          time.startTime = serializeTime(time.startTime);
          time.endTime = serializeTime(time.endTime);
        });
      });

      data.enrollment.fromDate = UTCtoLocalDateTime(
        moment(data.enrollment.fromDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
      );

      data.enrollment.toDate = UTCtoLocalDateTime(
        moment(data.enrollment.toDate).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        }),
      );

      const newContribution = {
        ...contribution,
        ...data,
      };

      saveHandler(newContribution);
      onSaveContribution(newContribution);

      history.push(to || redirectTo);
    },
    [contribution, history, saveHandler, redirectTo, onSaveContribution],
  );

  const submitMembershipHandler = useCallback(
    (values, formikHelpers, to = '') => {
      const data = {
        ...values,
      };
      data.sessions.forEach(session => {
        session.name = 'Session';

        session.sessionTimes.forEach(time => {
          time.startTime = serializeTime(time.startTime);
          time.endTime = serializeTime(time.endTime);
        });
      });

      data.enrollment.fromDate = UTCtoLocalDateTime(
        moment(data.enrollment.fromDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
      );

      data.enrollment.toDate = UTCtoLocalDateTime(
        moment(data.enrollment.toDate).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        }),
      );

      const newContribution = {
        ...contribution,
        ...data,
      };

      saveHandler(newContribution);
      onSaveContribution(newContribution);

      history.push(to || redirectTo);
    },
    [contribution, history, saveHandler, redirectTo, onSaveContribution],
  );

  const submitOneToOneHandler = (values, formikHelpers, to = '', withoutValidation = false) => {
    if (!withoutValidation && checkSessionDuration(oneToOneData.selectedWeeks, oneToOneData.sessionDuration)) {
      return;
    }

    const data = {
      ...values,
    };
    let slotsId = null;

    data.availabilityTimes = [];
    data.durations = Array.isArray(data.sessions[0].durations)
      ? data.sessions[0].durations
      : [data.sessions[0].durations];

    data.sessions.forEach(session => {
      session.name = '1:1 Session';

      if (session.minParticipantsNumber !== 1) {
        session.minParticipantsNumber = 1;
      }

      if (session.maxParticipantsNumber !== 1) {
        session.maxParticipantsNumber = 1;
      }

      if (session.durations && !Array.isArray(session.durations)) {
        session.durations = [session.durations];
      }

      if (getTimePeriodsForSessionsCollection(session.sessionTimes).length) {
        slotsId = getTimePeriodsForSessionsCollection(session.sessionTimes)[0].id;
      }

      const { slots } = generateOneToOneSlots(oneToOneData, session.sessionTimes || [], otherEvents, []);

      data.availabilityTimes = data.availabilityTimes.concat(slots);

      data.oneToOneSessionDataUi = oneToOneData;

      data.durations = [oneToOneData.sessionDuration];

      data.availabilityTimesForUi = session.sessionTimes.map(R.omit(['startDate', 'endDate']));
    });

    const newContribution = {
      ...contribution,
      ...data,
    };

    saveHandler(newContribution);
    onSaveContribution(newContribution);

    history.push(to || redirectTo);
  };

  if (!contribution) return null;

  let Form = null;
  const renderButtons = (submitHandler = () => {}) => (
    <Buttons
      backButtonTitle="Back"
      backClickHandler={event => {
        const to = query.id ? `/edit-contribution/${query.id}/details` : '/create-contribution/details';
        setRedirectTo(to);
        submitHandler(event, to);
      }}
      onNextClickHandler={event => {
        const to = query.id ? `/edit-contribution/${query.id}/payment` : '/create-contribution/payment';
        setRedirectTo(to);
        submitHandler(event, to, true);
      }}
      nextButtonTitle="Save and Next"
      formId="create-contribution-form"
    />
  );

  if (contribution.type === ContributionType.contributionCourse) {
    Form = (
      <Formik
        innerRef={contributionFormRef}
        enableReinitialize
        initialValues={initialValuesLive}
        validationSchema={liveSessionsValidationSchema}
        onSubmit={submitLiveHandler}
      >
        {formProps => {
          const saveData = (event, to, withValidation = false) => {
            if (isEditing || withValidation) {
              formProps.handleSubmit(event);
            } else {
              submitLiveHandler(formProps.values, formProps, to);
            }
          };
          return (
            <MainContainer
              sidebarProps={{
                saveHandler: (event, { to }) => {
                  setRedirectTo(to);
                  saveData(event, to);
                },
              }}
            >
              <StyledMainSection mobileView={mobileView}>
                <LiveForm {...formProps} />
                {renderButtons(saveData)}
              </StyledMainSection>
            </MainContainer>
          );
        }}
      </Formik>
    );
  }

  if (contribution.type === ContributionType.contributionOneToOne) {
    const alreadyBooked = contribution?.availabilityTimes
      ? contribution.availabilityTimes.some(availabilityTime => Boolean(availabilityTime.bookedTimes.length))
      : false;

    Form = (
      <Formik
        innerRef={contributionFormRef}
        enableReinitialize
        initialValues={initialValuesOneToOne}
        validationSchema={oneToOneSessionsValidationSchema}
        onSubmit={submitOneToOneHandler}
      >
        {formProps => {
          const saveData = (event, to, withValidation = false) => {
            if (isEditing || withValidation) {
              formProps.handleSubmit(event);
            } else {
              submitOneToOneHandler(formProps.values, formProps, to, true);
            }
          };

          return (
            <MainContainer
              sidebarProps={{
                saveHandler: (event, { to }) => {
                  setRedirectTo(to);
                  saveData(event);
                },
              }}
            >
              <StyledMainSection mobileView={mobileView}>
                <OneToOneForm {...formProps} otherEvents={otherEvents} alreadyBooked={alreadyBooked} />
                {renderButtons(saveData)}
              </StyledMainSection>
            </MainContainer>
          );
        }}
      </Formik>
    );
  }

  if (contribution.type === ContributionType.contributionMembership) {
    Form = (
      <Formik
        innerRef={contributionFormRef}
        enableReinitialize
        initialValues={initialValuesMembership}
        validationSchema={membershipSessionsValidationSchema}
        onSubmit={submitMembershipHandler}
      >
        {formProps => {
          const saveData = (event, to, withValidation = false) => {
            if (isEditing || withValidation) {
              formProps.handleSubmit(event);
            } else {
              submitMembershipHandler(formProps.values, formProps, to);
            }
          };
          return (
            <MainContainer
              sidebarProps={{
                saveHandler: (event, { to }) => {
                  setRedirectTo(to);
                  saveData(event, to);
                },
              }}
            >
              <StyledMainSection mobileView={mobileView}>
                <MembershipForm {...formProps} />
                {renderButtons(saveData)}
              </StyledMainSection>
            </MainContainer>
          );
        }}
      </Formik>
    );
  }

  return Form || renderButtons();
}

Sessions.propTypes = {
  contribution: PropTypes.shape(),
  saveContribution: PropTypes.func.isRequired,
  saveContributionToLS: PropTypes.func.isRequired,
};

Sessions.defaultProps = {
  contribution: null,
};

const mapStateToProps = ({ contributions, oneToOneData, calendars }) => ({
  contribution: contributions.activeContribution,
  editContributionTimes: contributions.editContributionTimes,
  oneToOneData,
  // busyTimes: calendars.busyTimes
});

const actions = {
  saveContribution: contributionActions.saveContribution,
  saveContributionToLS: contributionActions.saveDraftContribution,
};

export default connect(mapStateToProps, actions)(Sessions);
