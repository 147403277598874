import React from 'react';
import styled from 'styled-components';
import { toRem, colors, devices } from 'utils/styles';
import { NavLink } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { useRouter } from 'hooks';
import { JOURNEY_ROUTES } from 'helpers/constants';

const StyledClientJourneyHeaderContainer = styled.div`
  padding: 0 16px;
  margin: 0 auto;

  ${({ mobileView }) => mobileView && `padding-left: 0px;`}
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: ${toRem(16)};
  line-height: 1;
  font-weight: 900;
  color: ${colors.darkGray};
  padding: 16px;
  display: inline-block;
  position: relative;

  &.active {
    color: ${colors.darkOceanBlue};
  }

  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        padding: 16px 15px;
      `
    );
  }}

  ${({ firstLink }) => firstLink && `padding-left: 16px;`}
`;

const ClientJourneyHeader = ({ subUrl }) => {
  const { match, pathname } = useRouter();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <StyledClientJourneyHeaderContainer mobileView={mobileView}>
      <StyledNavLink to={`${match.url}/${subUrl}/upcoming`} mobileView={mobileView} firstLink>
        Upcoming
      </StyledNavLink>
      <StyledNavLink to={`${match.url}/${subUrl}/past`} mobileView={mobileView}>
        Past
      </StyledNavLink>
      {!pathname.includes(JOURNEY_ROUTES.SESSIONS) && (
        <StyledNavLink to={`${match.url}/${subUrl}/all`} mobileView={mobileView}>
          All Purchases
        </StyledNavLink>
      )}
    </StyledClientJourneyHeaderContainer>
  );
};

export default ClientJourneyHeader;
