import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const getBankAccounts = data => axiosInstance.get('/api/Payment/list-bank-accounts', data).then(get('data'));

const verifyStripeAccount = () =>
  axiosInstance.post('api/Payment/generate-account-verification-link').then(get('data'));

const getExchangeAccessToken = data => axiosInstance.post('/api/payment/exchange', data).then(get('data'));

const fetchStripeToken = data => axiosInstance.post('/api/payment/fetch-stripe-token', data).then(get('data'));

const attachExternalAccount = data =>
  axiosInstance.post('/api/Payment/attach-external-account', data).then(get('data'));

export { getBankAccounts, verifyStripeAccount, getExchangeAccessToken, fetchStripeToken, attachExternalAccount };
