import React from 'react'
import PropTypes from 'prop-types'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

import ChatHeader from './ChatHeader'
import MessagesHistory from './MessagesHistory'
import MessageInput from './MessageInput'

const Chat = ({
  compact,
  title,
  iconUrl,
  members,
  myMember,
  typingMembers,
  memberProvider,
  messages,
  isLoadingMoreMessages,
  onLoadMoreMessages,
  onReadMessage,
  sendTextMessage,
  sendMediaMessage,
  onTyping,
}) => (
  <ExpansionPanel expanded className="chat">
    <ExpansionPanelSummary className="chat__header">
      <ChatHeader compact={compact} title={title} iconUrl={iconUrl} members={members} />
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className="chat__content chat-content">
      <MessagesHistory
        className="chat-content__messages-history"
        members={members}
        myMember={myMember}
        memberProvider={memberProvider}
        messages={messages}
        isLoadingMoreMessages={isLoadingMoreMessages}
        onLoadMoreMessages={onLoadMoreMessages}
        onReadMessage={onReadMessage}
        typingMembers={typingMembers}
      />
      <MessageInput onSendTextMessage={sendTextMessage} onSendMediaMessage={sendMediaMessage} onTyping={onTyping} />
    </ExpansionPanelDetails>
  </ExpansionPanel>
)

Chat.propTypes = {
  compact: PropTypes.bool,
  title: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  myMember: PropTypes.shape({
    sid: PropTypes.string,
  }).isRequired,
  typingMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  memberProvider: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoadingMoreMessages: PropTypes.bool.isRequired,
  onLoadMoreMessages: PropTypes.func.isRequired,
  onReadMessage: PropTypes.func.isRequired,
  sendTextMessage: PropTypes.func.isRequired,
  sendMediaMessage: PropTypes.func.isRequired,
  onTyping: PropTypes.func.isRequired,
}

Chat.defaultProps = {
  compact: false,
}

export default Chat
