import { ContributionType } from 'helpers/constants';

export const PURCHASE_MODAL_TITLES = {
  [ContributionType.contributionCourse]: {
    button: 'Purchase',
    modal: 'Purchase Group Course',
    submit: 'Reserve',
    join: (contributionName, isLogin) => (isLogin ? 'You’re in!' : `Join ${contributionName}`),
  },
  [ContributionType.contributionMembership]: {
    button: 'Purchase',
    modal: 'Purchase Membership',
    submit: 'Reserve',
    join: (contributionName, isLogin) => (isLogin ? 'You’re in!' : `Join ${contributionName}`),
  },
  [ContributionType.contributionOneToOne]: {
    button: 'Reserve 1:1 Session',
    modal: 'Reserve 1:1 Session',
    submit: 'Reserve',
  },
  [ContributionType.contributionLocalEvent]: {
    button: '',
    modal: '',
    submit: '',
  },
};

export const PURCHASE_MODAL_STEPS = {
  loggedIn: 'loggedIn',
  memberLoggedIn: 'memberLoggedIn',
  init: 'init',
  memberInit: 'memberInit',
  login: 'login',
  memberLogin: 'memberLogin',
  create: 'create',
  memberCreate: 'memberCreate',
  join: 'join',
  joinLogin: 'joinLogin',
  joinCreate: 'joinCreate',
};

export const ACCOUNT_FORM_HEADERS = {
  loggedIn: '',
  init: 'Login or Create Your Account',
  join: 'Login or Create Your Account',
  login: 'Login to Your Account',
  create: 'Create Your Account',
};

export const ACCOUNT_FORM_FIELDS = {
  confirmEmail: 'ConfirmEmail',
  email: 'Email',
  password: 'Password',
  birthDate: 'BirthDate',
  firstName: 'FirstName',
  lastName: 'LastName',
  timeZoneId: 'TimeZoneId',
};

export const RESTORE_PASS = 'restore-pass';
