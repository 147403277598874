import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Icon } from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

const StyledLi = styled.li`
  padding: 10px 10px 10px 0;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  justify-content: space-between;

  ${({ isList }) => isList && `cursor: pointer;`}
  ${({ disabled }) => disabled && `pointer-events: none;`}
`;

const StyledUl = styled.ul`
  list-style: none;
  padding-left: 2rem;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  font-size: 1.1125rem;
  font-weight: 500;
  line-height: 1.35;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);

  &.active {
    font-weight: 800;
  }

  &:hover {
    text-decoration: underline;
  }

  ${StyledUl} > ${StyledUl} & {
    font-weight: normal;
  }
`;

function List({ children }) {
  return <StyledUl>{children}</StyledUl>;
}

List.propTypes = {
  children: PropTypes.node.isRequired,
};

function Item({ children, isList, to, title, disabled, onClick, id }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <StyledLi disabled={disabled} onClick={() => setShow(prev => !prev)} isList={isList}>
        <StyledLink id={id} disabled={disabled} to={to || {}} onClick={onClick}>
          {title}
        </StyledLink>
        {isList && <Icon size={1} path={show ? mdiChevronUp : mdiChevronDown} />}
      </StyledLi>
      {isList && show && children}
    </>
  );
}

Item.propTypes = {
  id: PropTypes.string,
  children: PropTypes.element,
  isList: PropTypes.bool,
  to: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Item.defaultProps = {
  id: '',
  children: null,
  isList: false,
  to: null,
  disabled: false,
  onClick: undefined,
};

export { List, Item };
