import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const getOnToOnePaymentInfo = (id, paymentOption) =>
  axiosInstance.post(`/api/purchase/one-to-one/${id}/${paymentOption}`).then(get('data'));

const getCoursePaymentInfo = (id, paymentOption) =>
  axiosInstance.post(`/api/purchase/course/${id}/${paymentOption}`).then(get('data'));

const getMembershipPaymentInfo = (id, paymentOption) =>
  axiosInstance.post(`/api/purchase/membership/${id}/${paymentOption}`).then(get('data'));

const purchaseMembershipSubscription = data => axiosInstance.post(`/api/purchase/membership`, data).then(get('data'));

const cancelMembership = id => axiosInstance.post(`/api/purchase/cancel/membership/${id}`).then(get('data'));

const upgradeMembership = data => axiosInstance.post(`/api/purchase/upgrade/membership`, data).then(get('data'));

const getCoaches = () => axiosInstance.get(`/api/purchase/ListMyCoaches`).then(get('data'));

export {
  getOnToOnePaymentInfo,
  getCoursePaymentInfo,
  getMembershipPaymentInfo,
  purchaseMembershipSubscription,
  getCoaches,
  cancelMembership,
  upgradeMembership,
};
