import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHeader, useEndVideoChat } from 'hooks';
import { ContributionType } from 'helpers/constants';
import { fetchUpcomingCreatedWithType } from 'actions/contributions';

import Loader from 'components/UI/Loader';
import Banner from 'components/Banner/Banner';
import ContributionsTable from 'components/Tables/Contributions';
import SessionsTable from 'components/Tables/Sessions';

import './LiveCoursesPage.scss';

const LiveCoursesPageContainer = ({
  userId,
  loading,
  error,
  contributions,
  closestClassForBanner,
  sessions,
  getContributions,
}) => {
  useHeader('Group Courses');

  const renderList = useCallback(async () => {
    getContributions(userId, ContributionType.contributionCourse);
  }, [getContributions, userId]);

  const { Popup } = useEndVideoChat(renderList);

  useEffect(() => {
    renderList();
  }, [renderList]);

  if (loading) {
    return <Loader />;
  }

  if (!contributions || !contributions.length) {
    return <div>There are no courses yet.</div>;
  }

  return (
    <div className="tables-container">
      <div>
        <Banner type="closestSession" closestClassForBanner={closestClassForBanner} />
        <h2 className="table__title">My Contributions</h2>
        <ContributionsTable rows={contributions} refreshList={renderList} />
      </div>
      <div>
        <h2 className="table__title">Upcoming Sessions</h2>
        <SessionsTable rows={sessions} refreshList={renderList} />
      </div>
      <Popup />
    </div>
  );
};

const mapStateToProps = ({ upcomingCreatedWithType: { loading, error, upcomingCreatedWithType }, account }) => ({
  userId: account?.user?.id,
  loading,
  error,
  contributions: upcomingCreatedWithType?.contributions || [],
  sessions: upcomingCreatedWithType?.upcomingSessions || [],
  closestClassForBanner: upcomingCreatedWithType?.closestClassForBanner || {},
});

const actions = {
  getContributions: fetchUpcomingCreatedWithType,
};

LiveCoursesPageContainer.propTypes = {
  userId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  contributions: PropTypes.arrayOf(PropTypes.object).isRequired,
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getContributions: PropTypes.func.isRequired,
  closestClassForBanner: PropTypes.shape({}).isRequired,
};

LiveCoursesPageContainer.defaultProps = {
  error: null,
};

export default connect(mapStateToProps, actions)(LiveCoursesPageContainer);
