import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { UserRoles } from 'helpers/constants';
import {
  AllConversationsPage,
  ClientsConversationsPage,
  CohealersConversationsPage,
  DiscussionsConversationsPage,
} from 'pages';
import { useHeader } from 'hooks';
import { ProtectedRoute } from '../Routing';
import MainContainer from './MainContainer';

const StyledMainSection = styled.div`
  padding: 55px 55px 35px;
  height: 100%;

  ${({ mobileView }) => mobileView && `padding: 16px;`}
`;

const ConversationsContainer = ({ match: { path, url } }) => {
  useHeader('Conversations');
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MainContainer>
      <StyledMainSection mobileView={mobileView}>
        <Switch>
          <Route path={`${path}/all`} component={AllConversationsPage} />
          <ProtectedRoute roles={[UserRoles.cohealer]} path={`${path}/clients`} component={ClientsConversationsPage} />
          <ProtectedRoute
            roles={[UserRoles.client]}
            path={`${path}/cohealers`}
            component={CohealersConversationsPage}
          />
          <Route path={`${path}/discussions`} component={DiscussionsConversationsPage} />
          <Redirect to={`${url}/all`} />
        </Switch>
      </StyledMainSection>
    </MainContainer>
  );
};

ConversationsContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default ConversationsContainer;
