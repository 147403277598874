import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { CoachItem } from '../../../../../../ContributionView/components/AboutMainInfoBlock/CoachItem'

const CoachesList = ({ coaches }) => {
  return (
    <Grid container spacing={2}>
      {coaches.map(({ firstName, lastName, avatarUrl, id }) => (
        <Grid key={id} container spacing={2} md={6} sm={12} xs={12} className="mb-2">
          <CoachItem
            serviceProviderName={null}
            firstName={firstName}
            lastName={lastName}
            avatarUrl={avatarUrl}
            userId={id}
          />
        </Grid>
      ))}
    </Grid>
  )
}

CoachesList.propTypes = {
  coaches: PropTypes.array.isRequired,
}

export default CoachesList
