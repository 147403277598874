import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHeader, useEndVideoChat } from 'hooks';
import { ContributionType } from 'helpers/constants';
import { fetchUpcomingCreatedWithType } from 'actions/contributions';

import Banner from 'components/Banner/Banner';
import Loader from 'components/UI/Loader';
import ContributionsTable from 'components/Tables/Contributions';
import SessionsTable from 'components/Tables/Sessions';
import { useAccount } from '../../hooks';

const MembershipsPageContainer = () => {
  useHeader('Memberships');
  const dispatch = useDispatch();
  const { user } = useAccount();
  const isLoading = useSelector(({ upcomingCreatedWithType: { loading } }) => loading);
  const contributions = useSelector(
    ({ upcomingCreatedWithType: { upcomingCreatedWithType } }) => upcomingCreatedWithType?.contributions || [],
  );
  const sessions = useSelector(
    ({ upcomingCreatedWithType: { upcomingCreatedWithType } }) => upcomingCreatedWithType?.upcomingSessions || [],
  );
  const closestClassForBanner = useSelector(
    ({ upcomingCreatedWithType: { upcomingCreatedWithType } }) => upcomingCreatedWithType?.closestClassForBanner || {},
  );

  const renderList = useCallback(async () => {
    dispatch(fetchUpcomingCreatedWithType(user.id, ContributionType.contributionMembership));
  }, [user.id]);

  const { Popup } = useEndVideoChat(renderList);

  useEffect(() => {
    renderList();
  }, [renderList]);

  if (isLoading) {
    return <Loader />;
  }

  if (!contributions?.length) {
    return <div>There are no sessions yet.</div>;
  }

  return (
    <div className="tables-container">
      <div>
        <Banner type="closestSession" closestClassForBanner={closestClassForBanner} />
        <h2 className="table__title">My Contributions</h2>
        <ContributionsTable rows={contributions} refreshList={renderList} />
      </div>
      <div>
        <h2 className="table__title">Upcoming Sessions</h2>
        {!sessions?.length ? (
          <div>There are no sessions yet.</div>
        ) : (
          <SessionsTable rows={sessions} refreshList={renderList} />
        )}
      </div>
      <Popup />
    </div>
  );
};

export default MembershipsPageContainer;
