import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/UI/Icon';
import { toRem } from 'utils/styles';

const StyledDetailsTextBlockHeader = styled.div`
  margin-bottom: 14px;
`;

const StyledDetailsTextBlockHeaderText = styled.span`
  font-size: ${toRem(18)};
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.12px;
  line-height: 26px;

  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const StyledDetailsTextBlockMain = styled.div`
  font-size: ${toRem(16)};
  line-height: 22px;
  hyphens: auto;
`;

const TextBlock = ({ image, title, text, mobileView }) => {
  return (
    <>
      <StyledDetailsTextBlockHeader>
        {Boolean(image) && <Icon path={image} right="5" valign="top" />}
        <StyledDetailsTextBlockHeaderText mobileView={mobileView}>{title}</StyledDetailsTextBlockHeaderText>
      </StyledDetailsTextBlockHeader>
      <StyledDetailsTextBlockMain lang="en">{text}</StyledDetailsTextBlockMain>
    </>
  );
};

TextBlock.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  mobileView: PropTypes.bool,
};

TextBlock.defaultProps = {
  text: 'None',
  image: null,
  mobileView: false,
};

export default TextBlock;
