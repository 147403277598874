import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';

import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import { formatMoney } from 'utils/datesAndMoney';
import { colors } from 'utils/styles';

import './LiveCourses.scss';
import InfoIcon from '@material-ui/icons/Info.js';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../../../constants.js';
import { ContributionStatus } from 'helpers/constants';
import useRouter from 'hooks/useRouter';

const StyledHeader = styled.div`
  font-size: 1.2rem;
  margin-top: 27px;
  font-weight: 600;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

const StyledText = styled.div`
  font-size: 0.95rem;
  margin-top: 12px;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;
const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: -4px;
  top: calc(50% - 5px);
  transform: translateY(-50%);
`;
const StyledTooltipWrap = styled.span`
  position: relative;
  padding-right: 7px;
  display: inline-block;
`;

const BlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: colors.darkOceanBlue,
    },
  },
  checked: {},
})(Checkbox);

const MAX_SPLIT_NUMBERS = 12;

function renderSplitCost(values) {
  return formatMoney(values.cost / values.splitNumbers);
}

function splitNumbersOptions() {
  return Array.from({ length: MAX_SPLIT_NUMBERS - 1 }, (_, index) => {
    const value = index + 2;
    return { title: `${value} Payments`, value };
  });
}

const LiveCourses = ({ contribution, saveToStorageHandler, children }) => {
  const { query } = useRouter();
  const isFeeDisabled = !!query.id && contribution?.status !== ContributionStatus.unfinished;
  const isPurchasedWithSplitPayment = false;
  const formatTotalPrice = ({ cost, packageSessionDiscountPercentage }) =>
    formatMoney((cost * (100 - packageSessionDiscountPercentage)) / 100);
  const isEditing = !!query.id;
  const handleFormKeyDown = e => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        cost: contribution?.paymentInfo?.cost || '',
        paymentOptions:
          contribution?.paymentInfo?.paymentOptions &&
          !contribution?.paymentInfo?.paymentOptions.some(
            option => option === 'SessionsPackage' || option === 'PerSession',
          )
            ? contribution?.paymentInfo?.paymentOptions
            : ['EntireCourse'],
        splitNumbers: contribution?.paymentInfo?.splitNumbers || 3,
        splitPeriod: contribution?.paymentInfo?.splitPeriod || 'Monthly',
        packageDiscount: !!contribution?.paymentInfo?.packageSessionDiscountPercentage,
        packageSessionDiscountPercentage: contribution?.paymentInfo?.packageSessionDiscountPercentage
          ? contribution?.paymentInfo?.packageSessionDiscountPercentage
          : '',
        coachPaysStripeFee: contribution?.paymentInfo?.coachPaysStripeFee,
      }}
      validationSchema={Yup.object().shape({
        cost: Yup.number().min(1).integer('Please only enter numbers').required('This is a required field'),
        splitNumbers: Yup.number()
          .min(2)
          .max(MAX_SPLIT_NUMBERS)
          .integer('please only enter numbers')
          .required('This is a required field'),
        paymentOptions: Yup.array()
          .of(Yup.mixed().oneOf(['EntireCourse', 'SplitPayments']))
          .min(1),
        coachPaysStripeFee: Yup.bool(),
      })}
      onSubmit={saveToStorageHandler}
    >
      {formProps => {
        const renderCardBody = (
          <Form id="create-contribution-form" onKeyDown={handleFormKeyDown}>
            <Grid container spacing={4} alignItems="flex-start">
              <Grid item container sm={3} xs={12} spacing={1}>
                <Grid item xs={12}>
                  <StyledHeader>Price</StyledHeader>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    startAdornment="$"
                    type="text"
                    name="cost"
                    fullWidth
                    onChange={({ target: { value } }) => {
                      if (!/\D/.test(value)) {
                        formProps.setFieldValue('cost', value);
                      }
                    }}
                    label="Total Price ($)"
                    disabled={isPurchasedWithSplitPayment}
                  />
                </Grid>
              </Grid>
              <Grid item container sm={6} xs={12} spacing={1}>
                <Grid item xs={12}>
                  <StyledHeader>Payment Options</StyledHeader>
                </Grid>

                <FieldArray name="paymentOptions">
                  {({ push, remove, form }) => {
                    const changeHandler = ({ target: { value, checked } }) => {
                      if (checked) {
                        push(value);
                      } else {
                        const idx = form.values.paymentOptions.indexOf(value);
                        if (value === 'EntireCourse') {
                          form.setFieldValue('packageDiscount', false);
                          form.setFieldValue('packageSessionDiscountPercentage', '');
                        }
                        remove(idx);
                      }
                    };
                    return (
                      <Grid item xs={12}>
                        <FormControl error={!!formProps.errors.paymentOptions} fullWidth>
                          {formProps.errors.paymentOptions && (
                            <FormLabel component="legend">Pick at least one option</FormLabel>
                          )}
                          <Grid item container xs={12}>
                            <Grid container item xs={12}>
                              <StyledTooltipWrap>
                                <FormControlLabel
                                  name="paymentOptions"
                                  control={<BlueCheckbox color="primary" />}
                                  label="Full Payment"
                                  value="EntireCourse"
                                  onChange={changeHandler}
                                  disabled={isPurchasedWithSplitPayment}
                                  checked={form.values.paymentOptions.includes('EntireCourse')}
                                />
                                <StyledTooltip
                                  title="Pay in full for all sessions"
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <InfoIcon htmlColor={colors.lightBrown} />
                                </StyledTooltip>
                              </StyledTooltipWrap>
                            </Grid>
                            {formProps.values.paymentOptions.includes('EntireCourse') && (
                              <Grid container item xs={12}>
                                <Grid item sm={8} xs={12}>
                                  <Field name="packageDiscount">
                                    {() => {
                                      const changePackageDiscount = ({ target: { checked } }) => {
                                        if (checked) {
                                          form.setFieldValue('packageSessionDiscountPercentage', 10);
                                        } else {
                                          form.setFieldValue('packageSessionDiscountPercentage', '');
                                        }
                                        form.setFieldValue('packageDiscount', !form.values.packageDiscount);
                                      };

                                      return (
                                        <>
                                          <StyledTooltipWrap>
                                            <FormControl>
                                              <FormControlLabel
                                                name="packageDiscount"
                                                control={<BlueCheckbox color="primary" />}
                                                label="Full Payment Discount"
                                                onChange={changePackageDiscount}
                                                checked={form.values.packageDiscount}
                                                disabled={isPurchasedWithSplitPayment}
                                              />
                                            </FormControl>
                                            <StyledTooltip
                                              title="An incentive to have clients pay in full"
                                              arrow
                                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                            >
                                              <InfoIcon htmlColor={colors.lightBrown} />
                                            </StyledTooltip>
                                          </StyledTooltipWrap>
                                        </>
                                      );
                                    }}
                                  </Field>
                                </Grid>
                                <Grid item sm={4} xs={12} classes={{ item: 'discount' }}>
                                  {formProps.values.packageDiscount ? (
                                    <Input
                                      type="text"
                                      name="packageSessionDiscountPercentage"
                                      label="Full Payment Discount %"
                                      onChange={({ target: { value } }) => {
                                        if (!/\D/.test(value)) {
                                          formProps.setFieldValue('packageSessionDiscountPercentage', value);
                                        }
                                      }}
                                      disabled={isPurchasedWithSplitPayment}
                                    />
                                  ) : (
                                    <div />
                                  )}
                                </Grid>
                              </Grid>
                            )}
                            <Grid item xs={4}>
                              <StyledTooltipWrap>
                                <FormControlLabel
                                  name="paymentOptions"
                                  control={<BlueCheckbox color="primary" />}
                                  label="Split Payments"
                                  value="SplitPayments"
                                  onChange={changeHandler}
                                  disabled={isEditing}
                                  checked={form.values.paymentOptions.includes('SplitPayments')}
                                />
                                <StyledTooltip
                                  title="For automatic recurring payments that allow your clients to pay for the program, overtime (monthly, weekly or daily)"
                                  arrow
                                  className="mb-2"
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <InfoIcon htmlColor={colors.lightBrown} />
                                </StyledTooltip>
                              </StyledTooltipWrap>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Grid>
                    );
                  }}
                </FieldArray>

                <Grid item container xs={12} spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <Select
                      label="Number of Payments"
                      name="splitNumbers"
                      fullWidth
                      disabled={
                        !formProps.values.paymentOptions.includes('SplitPayments') || isPurchasedWithSplitPayment
                      }
                      items={splitNumbersOptions()}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Select
                      label="Split Period"
                      name="splitPeriod"
                      fullWidth
                      disabled={
                        !formProps.values.paymentOptions.includes('SplitPayments') || isPurchasedWithSplitPayment
                      }
                      items={[
                        { title: 'Monthly', value: 'Monthly' },
                        { title: 'Weekly', value: 'Weekly' },
                        { title: 'Daily', value: 'Daily' },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={8} xs={12}>
                  <StyledTooltipWrap>
                    <FormControlLabel
                      name="coachPaysStripeFee"
                      control={<BlueCheckbox color="primary" />}
                      label="Coach Pays for Processing Fee"
                      checked={formProps.values.coachPaysStripeFee}
                      onChange={formProps.handleChange('coachPaysStripeFee')}
                      disabled={isFeeDisabled}
                    />
                    <StyledTooltip
                      title="If checked, the Stripe credit card processing fee will be passed onto your Clients, resulting in a 3-4% surcharge on top of your base pricing. For example, if your price is $100, the total purchase price will be $103.00-$104.00). If unchecked, your client will be charged a flat fee and all Stripe fees will be deducted from your gross sales. For example, if your price is $100, Stripe will take 3-4% off the total purchase price ($3.00-$4.00) and you will earn $96.00-$97.00, less any additional Cohere fees."
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      className="mb-2"
                    >
                      <InfoIcon htmlColor={colors.lightBrown} />
                    </StyledTooltip>
                  </StyledTooltipWrap>
                </Grid>
              </Grid>
              <Grid item container sm={3} xs={12} spacing={1}>
                <Grid item xs={12}>
                  <StyledText>Price per Course: ${formatTotalPrice(formProps.values)}</StyledText>
                  {formProps.values.paymentOptions.includes('SplitPayments') && (
                    <StyledText>Price per Split Payment: ${renderSplitCost(formProps.values)}</StyledText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );

        return children(formProps, renderCardBody);
      }}
    </Formik>
  );
};

LiveCourses.propTypes = {
  contribution: PropTypes.shape(),
  saveToStorageHandler: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

LiveCourses.defaultProps = {
  contribution: null,
};

export default LiveCourses;
