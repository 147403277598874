import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'

import { useHttp, useAccount, useVideoChatActions } from 'hooks'
import { useTheme, useMediaQuery } from '@material-ui/core'
import Popup from 'components/Popup/Popup'
import { Contribution } from './Contribution'

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell)

const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: (props) => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer)

const ContributionsTable = ({ rows, isArchived }) => {
  const [popupText, setPopupText] = useState(null)
  const { request } = useHttp()
  const { user } = useAccount()
  const { startVideoChat } = useVideoChatActions()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const onLaunchSession = (
    contributionId,
    type,
    classGroupId,
    classId,
    chatSid,
    title,
    { RecordParticipantsOnConnect }
  ) => {
    request('/Video/CreateRoomAndGetToken', 'POST', {
      contributionId,
      classId,
      RecordParticipantsOnConnect,
      identityName: `${user.firstName} ${user.lastName}`,
    }).then(({ room: { sid: roomId }, token }) =>
      startVideoChat({
        contributionId,
        type,
        sessionId: classGroupId,
        classId,
        title,
        roomId,
        chatId: chatSid,
        token,
        deleteRoomOnVideoEnd: true,
      })
    )
  }

  const handleClosePopup = () => {
    setPopupText(null)
  }

  return (
    <>
      <StyledTableContainer component={Paper} mobileView={mobileView}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell>Contribution</BoldTableCell>
              <BoldTableCell>Next Session</BoldTableCell>
              <BoldTableCell align="center">Invite</BoldTableCell>
              <BoldTableCell align="center">Clients Served</BoldTableCell>
              <BoldTableCell align="center">Revenue Earned</BoldTableCell>
              <BoldTableCell>Chat</BoldTableCell>
              <BoldTableCell>Edit</BoldTableCell>
              <BoldTableCell>Status</BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Contribution key={row.id} {...row} setPopupText={setPopupText} onLaunchSession={onLaunchSession} />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <Popup title="Rejected reason" text={popupText} open={popupText} onCloseText="OK" onClose={handleClosePopup} />
    </>
  )
}

ContributionsTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  isArchived: PropTypes.bool,
}

ContributionsTable.defaultProps = {
  rows: [],
  isArchived: false,
}

export default ContributionsTable
