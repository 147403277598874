import React from 'react';

import { ContributionType } from 'helpers/constants';
import { useAccount } from 'hooks';
import useContribution from '../hooks/useContribution';
import CourseSessions from './CourseSessions';
import OneToOneSessions from './OneToOneSessions';

const SESSIONS_MAPPING = {
  [ContributionType.contributionCourse]: (user, currentRole, contribution) => (
    <CourseSessions user={user} currentRole={currentRole} contribution={contribution} />
  ),
  [ContributionType.contributionOneToOne]: (user, currentRole, contribution) => (
    <OneToOneSessions user={user} currentRole={currentRole} contribution={contribution} />
  ),
  [ContributionType.contributionMembership]: (user, currentRole, contribution) => (
    <CourseSessions user={user} currentRole={currentRole} contribution={contribution} />
  ),
};

const ContributionSessionsContainer = () => {
  const { user, currentRole } = useAccount();
  const contribution = useContribution();

  return SESSIONS_MAPPING[contribution.type] ? (
    SESSIONS_MAPPING[contribution.type](user, currentRole, contribution)
  ) : (
    <div>No sessions yet.</div>
  );
};

export default ContributionSessionsContainer;
