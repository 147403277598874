import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';

import { PaymentStatus } from 'helpers/constants';
import useContribution from '../../../../hooks/useContribution';

import './SessionTime.scss';

const SessionTime = ({
  className,
  number,
  maxParticipantsCount,
  sessionTime,
  sessionTime: { startTime, endTime, participantsIds, isCompleted },
  isCoach,
  renderActions,
  isPrerecorded,
  dateAvailable,
}) => {
  const startTimeMoment = moment(startTime);
  const endTimeMoment = moment(endTime);
  const duration = humanizeDuration(endTimeMoment.diff(startTimeMoment));
  const { purchaseStatus } = useContribution();
  const isSeatsVisible = purchaseStatus !== PaymentStatus.unpurchased && !isCoach;
  return (
    <div className={classNames(className, 'course-session-time')}>
      <div className="course-session-times-block-wrapper">
        <div className="course-session-time__number">{number}.</div>
        <div className="course-session-times-wrapper">
          <div className="course-session-time__wrapper">
            {isPrerecorded ? (
              <>
                <p className="course-session-time__label">Date</p>
                <p className="course-session-time__value">{dateAvailable.format('MMM Do YYYY')}</p>
              </>
            ) : (
              <>
                <p className="course-session-time__label">Date</p>
                <p className="course-session-time__value">{startTimeMoment.format('MMM Do YYYY')}</p>
              </>
            )}
          </div>
          {!isPrerecorded && (
            <div className="course-session-time__wrapper">
              <p className="course-session-time__label">Start Time</p>
              <p className="course-session-time__value">{startTimeMoment.format('h:mm a')}</p>
            </div>
          )}
          {!isPrerecorded && (
            <div className="course-session-time__wrapper">
              <p className="course-session-time__label">End Time</p>
              <p className="course-session-time__value">{endTimeMoment.format('h:mm a')}</p>
            </div>
          )}

          <div className="course-session-time__wrapper">
            <p className="course-session-time__label">Duration</p>
            <p className="course-session-time__value">{duration}</p>
          </div>
          {isSeatsVisible && (
            <div className="course-session-time__wrapper">
              <p className="course-session-time__label">Seats Available</p>
              <p className="course-session-time__value">
                {maxParticipantsCount - participantsIds.length}/{maxParticipantsCount}
              </p>
            </div>
          )}
          {isCoach && (
            <div className="course-session-time__wrapper">
              <p className="course-session-time__label">Status</p>
              <p className="course-session-time__value">{isCompleted ? 'Completed' : 'Upcoming'}</p>
            </div>
          )}
        </div>
      </div>
      <div className="course-session-time__actions">{renderActions(sessionTime)}</div>
    </div>
  );
};

SessionTime.propTypes = {
  className: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  maxParticipantsCount: PropTypes.number.isRequired,
  sessionTime: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    participantsIds: PropTypes.arrayOf(PropTypes.string),
    isCompleted: PropTypes.bool,
  }).isRequired,
  isCoach: PropTypes.bool.isRequired,
  renderActions: PropTypes.func.isRequired,
};

SessionTime.defaultProps = {
  isPrerecorded: false,
};

export default SessionTime;
