export const userSelector = s => s.account.user;

export const loadingSelector = s => s.account.isLoading;

export const errorSelector = s => s.account.error;

export const temporaryDataSelector = s => s.account.temporaryDataForSwtichingRole;

export const isPaidTierAnnualSelector = s => s.account.isPaidTierOptionsMonthly;

export const cohereAcademyIsEnableSelector = s => s.account.cohereAcademyIsEnable;
