import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';

import avatarPlaceholder from 'assets/placeholder.jpg';
import Loader from 'components/UI/Loader';
import { useHttp } from 'hooks';
import * as userActions from 'actions/user';

import './ProfileAvatar.scss';

export const ProfileAvatar = ({ avatarUrl, className }) => {
  const dispatch = useDispatch();
  const { request, loading } = useHttp();

  const handleUploadFile = useCallback(
    ({ target: { files } }) => {
      const formData = new FormData();

      formData.append('file', R.head(files));

      request('/Content/AddAvatar', 'POST', formData, {
        'Content-Type': 'multipart/form-data',
      })
        .then(R.compose(dispatch, userActions.setProfileAvatar))
        .catch(console.dir);
    },
    [dispatch, request],
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <p className="profile-avatar-text">Add Photo</p>
      <div
        style={{
          backgroundImage: `url(${avatarUrl || avatarPlaceholder})`,
        }}
        className={classnames('mr-4 mb-4 br-50 profile-avatar', className)}
      >
        <input
          type="file"
          accept="image/*"
          className="w-100 h-100 cursor-pointer profile-input-avatar"
          onChange={handleUploadFile}
        />
      </div>
    </div>
  );
};

ProfileAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  className: PropTypes.string,
};

ProfileAvatar.defaultProps = {
  avatarUrl: avatarPlaceholder,
  className: null,
};
