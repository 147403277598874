import React from 'react'

export const TaxInfo = () => (
  <div>
    <p>
      For tax purposes, we will email you a 1099 that corresponds to the revenue you have earned on Cohere. Please fill
      out this attached W-9 form and email to support@cohere.live before December 31st to avoid delays. You will only
      have to fill this out once.
    </p>
    <p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
        className="text-primary"
      >
        W-9 FORM
      </a>
    </p>
    <p>
      <div>With gratitude,</div>
      <div>The Cohere Team.</div>
    </p>
  </div>
)
