import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { colors } from 'utils/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import Select from 'components/FormUI/Select';
import Input from 'components/FormUI/Input';
import InfoIcon from '@material-ui/icons/Info';
import { TOOLTIP, LiveVideoProvider } from 'constants.js';

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;

const LiveVideo = ({ liveVideoServiceProvider, setFieldValue }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid item xs={12}>
      <Card mobileView={mobileView}>
        <CardHeader mobileView={mobileView}>
          <PageTitleSecond mobileView={mobileView}>Live Video</PageTitleSecond>
        </CardHeader>
        <CardBody mobileView={mobileView}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <Select
                label="Live Video Service Provider"
                name="liveVideoServiceProvider.providerName"
                onChange={({ target }) => {
                  setFieldValue(target.name, target.value);
                }}
                fullWidth
                items={[LiveVideoProvider.Cohere, LiveVideoProvider.Custom]}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Input
                hidden={
                  liveVideoServiceProvider && liveVideoServiceProvider.providerName === LiveVideoProvider.Cohere.value
                }
                placeholder="Enter Private Link"
                name="liveVideoServiceProvider.customLink"
                onChange={({ target }) => {
                  setFieldValue(target.name, target.value, false);
                }}
                fullWidth
                label={
                  <div>
                    Use Your Private Room link instead (Zoom or Others)
                    <Tooltip
                      title="Cohere provides a built-in rich & streamlined video experience for you and your clients.
                          The Cohere video experiences saves your in-video chat threads, private session notes, and automates video recordings.
                          If you instead wish to use another video provider without these features,
                          you can add your private room link below and we’ll make sure your clients show up there instead.
                          When you and your clients access this Contribution,
                          the session launch button will open your platform of choice and we’ll also update calendar invites for both you and your clients."
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <StyledInfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                    </Tooltip>
                  </div>
                }
              />
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </Grid>
  );
};

LiveVideo.propTypes = {
  liveVideoServiceProvider: PropTypes.shape().isRequired,
};

export default LiveVideo;
