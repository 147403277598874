import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { useTheme, useMediaQuery } from '@material-ui/core';

import Button from 'components/FormUI/Button';
import { useHttp, useAccount, useVideoChatActions } from 'hooks';
import { useRecordModal } from 'hooks/useSessionRecordModal';

import '../Banner.scss';
import { LiveVideoProvider } from 'constants.js';
import { openInNewWindow } from 'services/links';
import { UserRoles, ContributionType } from 'helpers/constants';

const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`;

const ClosestSession = ({
  closestClassForBanner,
  closestClassForBanner: {
    contributionId,
    contributionType,
    classGroupId,
    classId,
    title,
    minutesLeft,
    isRunning,
    chatSid,
    contributionLiveVideoServiceProvider,
  },
}) => {
  const { request } = useHttp();
  const { user, currentRole } = useAccount();
  const { startVideoChat } = useVideoChatActions();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const BannerButton = mobileView ? StyledButton : Button;
  const isCoach = currentRole === UserRoles.cohealer;

  const isCustomLiveVideoServiceProvider = useMemo(() => {
    return (
      contributionLiveVideoServiceProvider &&
      contributionLiveVideoServiceProvider.providerName != LiveVideoProvider.Cohere.value
    );
  }, [contributionLiveVideoServiceProvider]);

  const onLaunchSession = useCallback(
    ({ RecordParticipantsOnConnect }) => {
      request('/Video/CreateRoomAndGetToken', 'POST', {
        contributionId,
        classId,
        RecordParticipantsOnConnect,
        identityName: user?.lastName ? `${user.firstName} ${user.lastName}` : undefined,
      }).then(({ room: { sid: roomId }, token }) =>
        startVideoChat({
          contributionId,
          type: contributionType,
          sessionId: classGroupId,
          classId,
          title,
          roomId,
          chatId: chatSid,
          token,
          deleteRoomOnVideoEnd: true,
        }),
      );
    },
    [
      user,
      request,
      startVideoChat,
      contributionId,
      contributionType,
      classGroupId,
      classId,
      title,
      chatSid,
      isCustomLiveVideoServiceProvider,
    ],
  );

  const onJoinSession = () => {
    if (!isRunning) {
      return;
    }
    if (isCustomLiveVideoServiceProvider) {
      openInNewWindow(contributionLiveVideoServiceProvider.customLink);
    } else {
      request('/Video/GetClientToken', 'POST', {
        contributionId,
        classId,
        identityName: `${user.firstName} ${user.lastName}`,
      }).then(({ token }) => startVideoChat({ contributionId, chatId: chatSid, token }));
    }
  };

  const onSubmit = () => {
    onLaunchSession({ RecordParticipantsOnConnect: true });
  };
  const onCancel = () => {
    onLaunchSession({ RecordParticipantsOnConnect: false });
  };
  const modalOptions = {
    onSubmit,
    onCancel,
    classId,
    contributionType,
    contributionId,
    liveVideoServiceProvider: contributionLiveVideoServiceProvider,
  };
  const { RecordModal, launchRecodModal } = useRecordModal(modalOptions);

  return (
    !isEmpty(closestClassForBanner) && (
      <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
        <p className="banner-container__text">{`${
          contributionType === ContributionType.contributionOneToOne ? 'Session with ' : ''
        }${title} is starting in ${minutesLeft}
        minutes.`}</p>
        {isCoach ? (
          <Button variant="primary" className="banner-container__button" autoWidth onClick={launchRecodModal}>
            Start Session
          </Button>
        ) : (
          <BannerButton
            variant={isRunning ? '' : 'primary'}
            className="banner-container__button"
            autoWidth
            onClick={onJoinSession}
          >
            {isRunning ? 'Join Session' : 'Stand By'}
          </BannerButton>
        )}
        <RecordModal />
      </div>
    )
  );
};

ClosestSession.defaultProps = {
  closestClassForBanner: {},
};

ClosestSession.propTypes = {
  closestClassForBanner: PropTypes.shape({
    contributionId: PropTypes.string,
    type: PropTypes.string,
    classGroupId: PropTypes.string,
    classId: PropTypes.string,
    title: PropTypes.string,
    minutesLeft: PropTypes.number,
    contributionType: PropTypes.string,
    isRunning: PropTypes.bool,
    chatSid: PropTypes.string,
  }),
};

export default ClosestSession;
