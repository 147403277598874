import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/FormUI/Button'
import { useHttp } from 'hooks'

const BookSessionTime = ({ onSubmit }) => {
  const { loading } = useHttp()

  return (
    <Button autoWidth onClick={onSubmit} disabled={loading}>
      Book Session Time
    </Button>
  )
}

BookSessionTime.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default BookSessionTime
