import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import ShareIcon from '@material-ui/icons/Share';
export const StyledShareButton = styled(Button)`
  float: right;
  margin-top: 5px !important;
  text-transform: none !important;
  cursor: pointer;
`;

export const StyledShareIcon = styled(ShareIcon)`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;
