import React from 'react';
import PropTypes from 'prop-types';

import { UserRoles } from 'helpers/constants';
import CohealerCourseSessions from './CohealerCourseSessions';
import ClientCourseSessions from './ClientCourseSessions';

const CourseSessionsComponents = {
  [UserRoles.cohealer]: CohealerCourseSessions,
  [UserRoles.client]: ClientCourseSessions,
  [UserRoles.admin]: () => <div>In development</div>,
};

const CourseSessionsContainer = ({ currentRole, ...restProps }) => {
  const CourseSessionsComponent = CourseSessionsComponents[currentRole] || ClientCourseSessions;

  return <CourseSessionsComponent {...restProps} />;
};

CourseSessionsContainer.propTypes = {
  currentRole: PropTypes.string.isRequired,
};

export default CourseSessionsContainer;
