import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { PageTitleSecond, PageSubtitle } from 'components/UI/Text/TextStyles';
import { toRem } from 'utils/styles';
import { IconUserFriends } from 'components/Icons/IconUserFriends';
import { IconUserHeadset } from 'components/Icons/IconUserHeadset';
import { IconUsersClass } from 'components/Icons/IconUsersClass';
import BannerContainer from './BannerContainer/BannerContainer';

const WrapperInfoScreen = styled.div`
  padding: 30px 55px;
  ${({ mobileView }) => mobileView && `padding: 16px;`}
`;

const StyledContainer = styled.div`
  background-color: white;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
  padding: 40px 24px;
`;

const StyledCardContainder = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px -16px;

  ${({ mobileView }) => mobileView && `flex-direction: column`}
`;

const StyledCard = styled.div`
  display: block;
  margin-left: 16px;
  margin-right: 16px;
  flex: 1 1 0;
`;

const StyledCardHeader = styled.h3`
  font-size: ${toRem(15.4)};
  font-weight: 900;
  line-height: 1.56;
  letter-spacing: 0.1px;
  text-align: center;
  color: black;
  margin-bottom: 0;
`;

const StyledCardDescription = styled.p`
  font-size: ${toRem(14)};
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: 0.22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
`;

function IntroScreen({ user, closestClassForBanner }) {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const imagesSide = mobileView ? '150px' : '180px';

  return (
    <WrapperInfoScreen mobileView={mobileView}>
      <BannerContainer closestClassForBanner={closestClassForBanner} />
      <StyledContainer>
        <PageTitleSecond mobileView={mobileView}>Welcome to your journey, {user.firstName}.</PageTitleSecond>
        <PageSubtitle>Request an invite from your Coach to register for:</PageSubtitle>

        <StyledCardContainder mobileView={mobileView}>
          <StyledCard>
            <IconUsersClass
              className="py-4"
              style={{
                maxWidth: imagesSide,
                mxHeight: imagesSide,
                color: '#116582',
              }}
            />
            <StyledCardHeader>Live Group Courses</StyledCardHeader>
            <StyledCardDescription>
              Book sessions, join live sessions, group chat, create private notes, download course materials, create
              friendships, and more
            </StyledCardDescription>
          </StyledCard>
          <StyledCard>
            <IconUserFriends
              className="py-4"
              style={{
                maxWidth: imagesSide,
                maxHeight: imagesSide,
                color: '#116582',
                paddingBottom: '10px',
              }}
            />
            <StyledCardHeader>Live 1:1 Sessions</StyledCardHeader>
            <StyledCardDescription>
              Schedule 1:1 sessions with your coach and enjoy the features of group courses like chat, private notes,
              and more
            </StyledCardDescription>
          </StyledCard>
          <StyledCard>
            <IconUserHeadset
              className="py-4"
              style={{
                maxWidth: imagesSide,
                maxHeight: imagesSide,
                color: '#116582',
              }}
            />
            <StyledCardHeader>Live Webinars</StyledCardHeader>
            <StyledCardDescription>
              Join free live webinars from your favorite coaches (coming soon).
            </StyledCardDescription>
          </StyledCard>
        </StyledCardContainder>
      </StyledContainer>
    </WrapperInfoScreen>
  );
}

IntroScreen.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
  }).isRequired,
  closestClassForBanner: PropTypes.shape({
    contributionId: PropTypes.string.isRequired,
    classId: PropTypes.string.isRequired,
    contributionTitle: PropTypes.string.isRequired,
    minutesLeft: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ account }) => ({
  user: account?.user || {},
});

export default connect(mapStateToProps)(IntroScreen);
