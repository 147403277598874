import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';

import { useAccount, useHttp, usePartnerCoach } from 'hooks';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { useTheme, useMediaQuery, Tooltip, Avatar } from '@material-ui/core';
import Loader from 'components/UI/Loader';
import { ContributionStatus } from 'helpers/constants';

import Button from 'components/FormUI/Button';
import Popup from 'components/Popup/Popup';
import { colors } from 'utils/styles';

import AddNewPartnerModalContainer from '../components/AddNewPartnerModal/AddNewPartnerModalContainer';
import { TOOLTIP } from '../../../constants';

const BoldTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: ({ mobileView }) => (mobileView ? '16px' : '17.8px'),
    fontWeight: 500,
    letterSpacing: '0.15px',
  },
})(TableCell);

const NarrowTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
  },
})(TableCell);

const AvatarComponent = styled(Avatar)`
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 24px;
        height: 24px;
        font-size: 0.7rem;
      `
    );
  }}
`;

const StyledButton = styled(Button)`
  margin-right: 0px;
`;
const StyledInfoIcon = styled(InfoIcon)`
  margin-left: 20px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const PartnerInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContributionTeam = ({ match: { params } }) => {
  const [isAddPartnerModalOpen, setIsAddPartnerModalOpen] = useState(false);
  const [removePartnerId, setRemovePartnerId] = useState(false);
  const [isConfirmRemoveModalOpen, setIsConfirmRemoveModalOpen] = useState(false);
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });
  const { status, userId: contributionUserId } = useContribution();
  const { user } = useAccount();

  const { loading: loadingPartner, partnerCoaches, updatePartnerCoaches } = usePartnerCoach(params.id);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { request, loading } = useHttp();

  const handleModalSubmit = useCallback(
    async emails => {
      if (emailsError.isError) {
        return;
      }

      try {
        const data = {
          contributionId: params.id,
          emails,
        };
        await request('/PartnerCoach/Invite', 'POST', data);
        setIsAddPartnerModalOpen(false);
      } catch (err) {
        setEmailsError({ isError: true, errorMessage: err?.response?.data });
      }
    },
    [params.id, emailsError],
  );

  const onRemovePartner = async () => {
    try {
      await request(`/PartnerCoach/${params.id}/${removePartnerId}`, 'DELETE');
      const UpdatedPartnerCoaches = partnerCoaches.filter(partner => partner.userId !== removePartnerId);
      updatePartnerCoaches(UpdatedPartnerCoaches);
      setIsAddPartnerModalOpen(false);
    } catch (err) {
      console.dir(err);
    }
  };

  if (loading || loadingPartner) {
    return <Loader />;
  }

  if (
    status === ContributionStatus.rejected ||
    status === ContributionStatus.inReview ||
    status === ContributionStatus.revised
  ) {
    return <div>You can not add partners to unapproved contributions</div>;
  }

  const handleOpenAddPartnerModal = () => {
    setIsAddPartnerModalOpen(true);
  };

  const handleCloseAddPartnerModal = () => {
    setEmailsError({
      isError: false,
      errorMessage: null,
    });
    setIsAddPartnerModalOpen(false);
  };

  const showConfirmRemove = userId => {
    setIsConfirmRemoveModalOpen(true);
    setRemovePartnerId(userId);
  };

  const closeConfirmRemove = () => {
    setIsConfirmRemoveModalOpen(false);
  };

  const submitConfirmRemove = () => {
    onRemovePartner();
    setIsConfirmRemoveModalOpen(false);
  };

  const createdByCurrentUser = contributionUserId === user.id;
  const tooltipText =
    'Cohere lets you add partner coaches.What they can do: Your partner coach will be able to edit the course, launch and join sessions, communicate with clients, see revenue numbers, and will show up on the sales page. You can remove them at anytime. What they can’t do: They will not be able to transfer revenue to their bank. They can not add other coaches.';

  return (
    <>
      <TableContainer component={Paper}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell mobileView={mobileView}>
                Team
                <div>
                  {createdByCurrentUser && (
                    <StyledButton mobileView={mobileView} autoWidth onClick={handleOpenAddPartnerModal}>
                      Add team member
                    </StyledButton>
                  )}
                  <Tooltip title={tooltipText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY}>
                    <StyledInfoIcon htmlColor={colors.lightBrown} />
                  </Tooltip>
                </div>
              </BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partnerCoaches.map(({ firstName, lastName, avatarUrl, userId }) => (
              <TableRow key={userId} className="row">
                <NarrowTableCell component="th" scope="row">
                  <PartnerInfoWrapper>
                    <AvatarComponent
                      alt="Coach name"
                      src={avatarUrl || 'https://via.placeholder.com/40'}
                      mobileView={mobileView}
                    />
                    <span className="ml-2">{`${firstName} ${lastName}`}</span>
                  </PartnerInfoWrapper>

                  {createdByCurrentUser && <StyledCloseIcon onClick={() => showConfirmRemove(userId)} />}
                </NarrowTableCell>
              </TableRow>
            ))}
            {(!partnerCoaches || !partnerCoaches.length) && (
              <TableRow className="row">
                <NarrowTableCell component="th" scope="row">
                  There are no team yet
                </NarrowTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isAddPartnerModalOpen && (
        <AddNewPartnerModalContainer
          isOpen={isAddPartnerModalOpen}
          onModalClose={handleCloseAddPartnerModal}
          onSubmit={handleModalSubmit}
          emailsError={emailsError}
          setEmailsError={setEmailsError}
        />
      )}
      {isConfirmRemoveModalOpen && (
        <Popup
          title="Remove confirmation"
          text="Do you really want do remove this partner?"
          open={isConfirmRemoveModalOpen}
          onCloseText="No"
          onClose={closeConfirmRemove}
          onSubmit={submitConfirmRemove}
        />
      )}
    </>
  );
};

ContributionTeam.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
};

export default ContributionTeam;
