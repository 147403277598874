import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import ClientJourneyHeader from './ClientJourneyHeader';
import BannerContainer from '../BannerContainer/BannerContainer';

import './ClientJourney.scss';
import ClosestContributionBannerContainer from '../ClosestContributionBannerContainer/ClosestContributionBannerContainer';
import SessionsHeader from './Sessions/SessionsHeader';
import ClientJourneyRouter from 'routes/ClientJourneyRouter';

import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import { ContributionType, JOURNEY_ROUTES } from 'helpers/constants';

const ClientJourney = ({
  upcomingContributions,
  pastContributions,
  clientDeclinedSubscriptions,
  hasContributions,
  coaches,
}) => {
  const [subUrl, setSubUrl] = useState(JOURNEY_ROUTES.SESSIONS);
  const isSessionsHeaderVisible = subUrl !== JOURNEY_ROUTES.COACHES;

  const filter = (contributions, type) =>
    Object.keys(contributions).reduce((acc, key) => {
      acc[key] = contributions[key].filter(contrib => contrib.type === type);
      return acc;
    }, {});

  const membershipsUpcoming = useMemo(() => filter(upcomingContributions, ContributionType.contributionMembership), [
    upcomingContributions,
  ]);
  const membershipsPast = useMemo(() => filter(pastContributions, ContributionType.contributionMembership), [
    pastContributions,
  ]);

  const oneToOneUpcoming = useMemo(() => filter(upcomingContributions, ContributionType.contributionOneToOne), [
    upcomingContributions,
  ]);
  const oneToOnePast = useMemo(() => filter(pastContributions, ContributionType.contributionOneToOne), [
    pastContributions,
  ]);

  const liveUpcoming = useMemo(() => filter(upcomingContributions, ContributionType.contributionCourse), [
    upcomingContributions,
  ]);
  const livePast = useMemo(() => filter(pastContributions, ContributionType.contributionMembership), [
    pastContributions,
  ]);

  return (
    <div className="client-journey">
      <ClientJourneyHeader tabClick={setSubUrl} />
      <div className="client-journey__content">
        {isSessionsHeaderVisible && <SessionsHeader subUrl={subUrl} />}

        <ClosestContributionBannerContainer />
        <BannerContainer
          clientDeclinedSubscriptions={clientDeclinedSubscriptions}
          hasContributions={hasContributions}
        />
        <ClientJourneyRouter
          upcomingContributions={upcomingContributions}
          pastContributions={pastContributions}
          membershipsUpcoming={membershipsUpcoming}
          membershipsPast={membershipsPast}
          oneToOneUpcoming={oneToOneUpcoming}
          oneToOnePast={oneToOnePast}
          liveUpcoming={liveUpcoming}
          livePast={livePast}
          coaches={coaches}
        />
      </div>
    </div>
  );
};

const contributionsPropType = PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired;

ClientJourney.propTypes = {
  coaches: PropTypes.array.isRequired,
  upcomingContributions: PropTypes.shape({
    otherIncompleted: contributionsPropType,
    today: contributionsPropType,
    thisWeek: contributionsPropType,
    thisMonth: contributionsPropType,
    nextMonth: contributionsPropType,
    thisYear: contributionsPropType,
    afterThisYear: contributionsPropType,
  }).isRequired,
  pastContributions: PropTypes.shape({
    priorYears: contributionsPropType,
    thisYear: contributionsPropType,
    lastMonth: contributionsPropType,
    thisMonth: contributionsPropType,
    thisWeek: contributionsPropType,
  }).isRequired,
  clientDeclinedSubscriptions: PropTypes.shape({}),
};

export default ClientJourney;
