import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useHttp, useAccount } from 'hooks';
import { toRem, colors } from 'utils/styles';
import { usePlaidLink } from 'react-plaid-link';

import * as bankAccountsActions from 'actions/bankAccounts';
import Loader from 'components/UI/Loader';
import Grid from '@material-ui/core/Grid';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import Button from 'components/FormUI/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import '../../Payment.scss';
import styled from 'styled-components';
import AddBankAccountPopup from './AddBankAccountPopup';

import BankAccountActionsOptionsButton from './BankAccountActionsOptionsButton';

const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);
const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);

const StyledBadge = styled.span`
  background-color: ${colors.darkOceanBlue};
  color: white;
  padding: 4px 12px;
  font-size: ${toRem(11)};
  font-weight: 900;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  border-radius: 10px;
  vertical-align: middle;
`;

const BankAccountCard = ({ getBankAccounts, bankAccounts, accountsLoading }) => {
  const { request, loading } = useHttp();
  const { user } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (user.payoutsEnabled) {
      getBankAccounts();
    }
  }, [user.payoutsEnabled, getBankAccounts]);

  const fetchStripeToken = useCallback(
    (accessToken, accountId) =>
      request(
        '/api/payment/fetch-stripe-token',
        'POST',
        { accessToken, accountId },
        {
          'Content-Type': 'application/json',
        },
      ),
    [request],
  );

  const attachExternalAccount = useCallback(
    stripeToken =>
      request(
        '/api/Payment/attach-external-account',
        'POST',
        { stripeToken },
        {
          'Content-Type': 'application/json',
        },
      ),
    [request],
  );

  const onSuccess = useCallback(
    (token, metadata) => {
      request(
        '/api/payment/exchange',
        'POST',
        { publicToken: token },
        {
          'Content-Type': 'application/json',
        },
      ).then(({ accessToken }) => {
        fetchStripeToken(accessToken, metadata.account_id).then(({ stripeToken }) =>
          attachExternalAccount(stripeToken),
        );
      });
    },
    [request, fetchStripeToken, attachExternalAccount],
  );

  const config = {
    clientName: 'Cohere Inc.',
    env: process.env.REACT_APP_PLAID_ENVIRONMENT,
    product: ['auth', 'transactions'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  const [addStripeBankAccountDataPopup, setAddStripeBankAccountDataPopup] = useState(false);

  const remove = useCallback(
    bankAccountId => {
      request(
        'api/Payment/remove-bank-account',
        'POST',
        {
          bankAccountId,
        },
        {
          'Content-Type': 'application/json',
        },
      )
        .then(e => getBankAccounts())
        .catch(console.dir);
    },
    [request, getBankAccounts],
  );

  const setAsDefault = useCallback(
    bankAccountId => {
      request(
        'api/Payment/set-bank-account-as-default',
        'POST',
        {
          bankAccountId,
        },
        {
          'Content-Type': 'application/json',
        },
      )
        .then(e => getBankAccounts())
        .catch(console.dir);
    },
    [request],
  );

  return (
    <>
      {loading && <Loader />}
      <Grid item xs={10} classes={{ root: 'card-container' }}>
        <Card maxHeight>
          <CardHeader>
            <PageTitleSecond style={{ flexGrow: 1 }}>Connect a bank account</PageTitleSecond>
            {!isEmpty(bankAccounts) && (
              <>
                <Button
                  autoWidth
                  variant="primary"
                  style={{ marginLeft: '10px' }}
                  onClick={() => setAddStripeBankAccountDataPopup(true)}
                >
                  Manual Connect
                </Button>
                <Button style={{ marginLeft: '10px' }} disabled={!ready} autoWidth variant="primary" onClick={open}>
                  Automatic Connect
                </Button>
              </>
            )}
          </CardHeader>
          <CardBody className="card-body-container card-body-container__mobile">
            {!accountsLoading && (
              <>
                {isEmpty(bankAccounts) && (
                  <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <div style={{ flexGrow: 1, alignSelf: 'center' }}>
                      Connect your bank account to transfer vailable funds.
                    </div>
                    <Button
                      autoWidth
                      variant="primary"
                      style={{ marginLeft: '10px' }}
                      onClick={() => setAddStripeBankAccountDataPopup(true)}
                    >
                      Manual Connect
                    </Button>
                    <Button autoWidth variant="primary" style={{ marginLeft: '10px' }} onClick={() => open()}>
                      Automatic Connect
                    </Button>
                  </div>
                )}
                {!isEmpty(bankAccounts) && (
                  <StyledTableContainer component={Paper} mobileView={mobileView}>
                    <Table className="" aria-label="contributions table">
                      <TableHead>
                        <TableRow>
                          <BoldTableCell>Bank Name</BoldTableCell>
                          <BoldTableCell>Account Number</BoldTableCell>
                          <BoldTableCell></BoldTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bankAccounts.map(bankAccount => {
                          return (
                            <TableRow>
                              <TableCell>
                                {bankAccount.bankName}{' '}
                                {bankAccount.isDefaultForCurrency && (
                                  <StyledBadge style={{ marginLeft: '10px' }}>Default</StyledBadge>
                                )}
                              </TableCell>
                              <TableCell>*{bankAccount.last4}</TableCell>
                              <TableCell>
                                {!bankAccount.isDefaultForCurrency && (
                                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <BankAccountActionsOptionsButton
                                      onSetAsDefault={() => setAsDefault(bankAccount.id)}
                                      onRemove={() => remove(bankAccount.id)}
                                    ></BankAccountActionsOptionsButton>
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                )}
                <AddBankAccountPopup
                  addStripeBankAccountDataPopup={addStripeBankAccountDataPopup}
                  onCancel={async () => {
                    await getBankAccounts();
                    setAddStripeBankAccountDataPopup(false);
                  }}
                ></AddBankAccountPopup>
              </>
            )}
          </CardBody>
        </Card>
      </Grid>
    </>
  );
};

BankAccountCard.propTypes = {
  bankAccounts: PropTypes.arrayOf({
    bankName: PropTypes.string,
    last4: PropTypes.number,
  }).isRequired,
  accountsLoading: PropTypes.bool.isRequired,
  getBankAccounts: PropTypes.func.isRequired,
};

const mapStateToProps = ({ bankAccounts }) => ({
  bankAccounts: bankAccounts?.bankAccounts,
  accountsLoading: bankAccounts?.loading,
});

const actions = {
  getBankAccounts: bankAccountsActions.fetchBalance,
};

export default connect(mapStateToProps, actions)(BankAccountCard);
