import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      height: 100%;
    `}
`;

const StyledHeader = styled.div`
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  padding: 0 24px;

  ${({ mobileView }) => mobileView && `padding: 16px;`}
`;

const StyledBody = styled.div`
  padding: 24px 24px;

  ${({ mobileView }) => mobileView && `padding: 16px;`}
`;

function Card({ children, ...rest }) {
  return <StyledContainer {...rest}>{children}</StyledContainer>;
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.bool,
};

Card.defaultProps = {
  maxHeight: false,
};

export { Card, StyledHeader as CardHeader, StyledBody as CardBody };
