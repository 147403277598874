import axios from 'axios';
import http from 'http';
import https from 'https';

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || '';

const axiosInstance = axios.create({
  baseURL: API_DOMAIN,
  httpAgent: new http.Agent({ keepAlive: true }),
  httpsAgent: new https.Agent({ keepAlive: true }),
});

export default axiosInstance;
