import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colors, toRem } from '../../../utils/styles';
import { useRouter } from '../../../hooks';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ContributionCommunityRouter from '../../../routes/ContributionCommunityRouter.jsx';
import { UserRoles, ContributionType } from 'helpers/constants';
import { connect } from 'react-redux';

const StyledClientJourneyHeaderContainer = styled.div`
  padding: 0 16px;
  margin: 0 auto;

  ${({ mobileView }) => mobileView && `padding-left: 0px;`}
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: ${toRem(16)};
  line-height: 1;
  font-weight: 900;
  color: ${colors.darkGray};
  padding: 16px;
  display: inline-block;
  position: relative;

  &.active {
    color: ${colors.darkOceanBlue};
  }

  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        padding: 16px 15px;
      `
    );
  }}

  ${({ firstLink }) => firstLink && `padding-left: 16px;`}
`;

const ContributionCommunity = ({ contribution, currentRole }) => {
  const {
    match: { url },
  } = useRouter();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isContributionPurchased = contribution.isPurchased;
  const canAccessClients =
    (contribution.type === ContributionType.contributionCourse ||
      contribution.type === ContributionType.contributionMembership) &&
    (currentRole === UserRoles.cohealer || (currentRole === UserRoles.client && isContributionPurchased));

  return (
    <>
      <StyledClientJourneyHeaderContainer mobileView={mobileView}>
        <StyledNavLink to={`${url}/social`} mobileView={mobileView}>
          Social
        </StyledNavLink>
        <StyledNavLink to={`${url}/conversations`} mobileView={mobileView} firstLink>
          Chat
        </StyledNavLink>
        {canAccessClients && (
          <StyledNavLink to={`${url}/clients`} mobileView={mobileView} firstLink>
            Clients
          </StyledNavLink>
        )}
      </StyledClientJourneyHeaderContainer>
      <ContributionCommunityRouter />
    </>
  );
};

ContributionCommunity.propTypes = {};

const mapStateToProps = ({ contributions, account }) => ({
  contribution: contributions?.activeContribution,
  currentRole: account?.currentRole,
});
export default connect(mapStateToProps, null)(ContributionCommunity);
