import React from 'react';
import PropTypes from 'prop-types';

import { LabelText } from 'components/UI/Text/TextStyles';
import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import ContributionsList from '../../ContributionsList';

const PastContributions = ({ contributions }) => {
  if (contributions.length === 0) {
    return <LabelText>You have no prior sessions.</LabelText>;
  }

  return <ContributionsList contributions={contributions} sortOrder="desc" />;
};

PastContributions.propTypes = {
  contributions: PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired,
};

export default PastContributions;
