import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { LabelText } from 'components/UI/Text/TextStyles';
import { ClientJourneyContribution } from 'services/dataContracts/contributions';
import ContributionsList from './ContributionsList';

import './UpcomingContributions.scss';

const checkIfHasContributions = contributions => contributions?.length > 0;

const UpcomingContributions = ({
  contributions: { otherIncompleted, today, thisWeek, thisMonth, nextMonth, thisYear, afterThisYear, notBooked },
}) => {
  const hasOtherIncompletedContributions = checkIfHasContributions([...notBooked, otherIncompleted]);
  const hasBookSession = notBooked.length > 0 || otherIncompleted.length > 0;
  const hasTodayContributions = checkIfHasContributions(today);
  const hasThisWeekContributions = checkIfHasContributions(thisWeek);
  const hasThisMonthContributions = checkIfHasContributions(thisMonth);
  const hasNextMonthContributions = checkIfHasContributions(nextMonth);
  const hasThisYearContributions = checkIfHasContributions(thisYear);
  const hasAfterThisYearContributions = checkIfHasContributions(afterThisYear);
  const hasAnyContributions =
    hasOtherIncompletedContributions ||
    hasTodayContributions ||
    hasThisWeekContributions ||
    hasThisMonthContributions ||
    hasNextMonthContributions ||
    hasThisYearContributions ||
    hasAfterThisYearContributions;

  if (!hasAnyContributions) {
    return <LabelText>You have no upcoming sessions.</LabelText>;
  }
  return (
    <div className="upcoming-contributions-list-container">
      {hasOtherIncompletedContributions && hasBookSession && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="Book Session"
          contributions={[...notBooked, ...otherIncompleted]}
          sortKeySelector={['title']}
        />
      )}
      {hasTodayContributions && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="Today"
          contributions={today}
        />
      )}
      {hasThisWeekContributions && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="Later This Week"
          contributions={thisWeek}
        />
      )}
      {hasThisMonthContributions && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="This Month"
          contributions={thisMonth}
        />
      )}
      {hasNextMonthContributions && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="Next Month"
          contributions={nextMonth}
        />
      )}
      {hasThisYearContributions && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="This Year"
          contributions={thisYear}
        />
      )}
      {hasAfterThisYearContributions && (
        <ContributionsList
          className="upcoming-contributions-list-container__contributions"
          title="Next Year"
          contributions={afterThisYear}
        />
      )}
    </div>
  );
};

const contributionsPropType = PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired;

UpcomingContributions.propTypes = {
  contributions: PropTypes.shape({
    otherIncompleted: contributionsPropType,
    today: contributionsPropType,
    thisWeek: contributionsPropType,
    thisMonth: contributionsPropType,
    nextMonth: contributionsPropType,
    thisYear: contributionsPropType,
    afterThisYear: contributionsPropType,
    notBooked: contributionsPropType,
  }).isRequired,
};

export default UpcomingContributions;
