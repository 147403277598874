import React from 'react'
import Grid from '@material-ui/core/Grid'

import Post from './Post/Post'
import map from 'lodash/map'

const PostList = ({ items }) => {
  const activeItems = items.filter((item) => !item.isDraft)

  return (
    <Grid container>
      {map(activeItems, (item) => {
        return <Post post={item} key={item.id} />
      })}
    </Grid>
  )
}

export default PostList
