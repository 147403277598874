import React from 'react'
import PropTypes from 'prop-types'

import { LabelText } from 'components/UI/Text/TextStyles'
import Button from 'components/FormUI/Button'
import { useVideoChat } from '../hooks'
import VideoChatConnectionStatus from '../VideoChatConnectionStatus'
import VideoChat from './VideoChat'

import './VideoChat.scss'

const VideoChatContainer = ({ token, chatId, onEnd }) => {
  const { connectionStatus, room, participants, dominantSpeakerParticipant, screenShareParticipants } = useVideoChat(
    token,
    onEnd
  )

  return (
    <div className="video-chat-container">
      {(connectionStatus === VideoChatConnectionStatus.pending ||
        connectionStatus === VideoChatConnectionStatus.connecting) && <LabelText>Connecting...</LabelText>}
      {connectionStatus === VideoChatConnectionStatus.connected && (
        <VideoChat
          className="video-chat-container__video-chat"
          chatId={chatId}
          room={room}
          participants={participants}
          dominantSpeakerParticipant={dominantSpeakerParticipant}
          screenShareParticipants={screenShareParticipants}
          onLeaveChat={onEnd}
        />
      )}
      {connectionStatus === VideoChatConnectionStatus.connectionError && (
        <div className="video-chat-container-connection-error">
          <LabelText>Connecting to video call failed.</LabelText>
          <Button className="video-chat-container-connection-error__close" autoWidth onClick={onEnd}>
            Close
          </Button>
        </div>
      )}
    </div>
  )
}

VideoChatContainer.propTypes = {
  chatId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onEnd: PropTypes.func.isRequired,
}

export default VideoChatContainer
