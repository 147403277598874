import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import * as cohealerIncomeActions from 'actions/cohealerIncome';
import { formatMoney } from 'utils/datesAndMoney';

import Grid from '@material-ui/core/Grid';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { downloadFile } from 'utils/utils';

import './SalesSummaryCard.scss';
import '../../Payment.scss';
import { colors } from 'utils/styles';
import { useHttp } from 'hooks';
import { Link } from 'react-router-dom';
import { TOOLTIP } from '../../../../../constants.js';

const renderName = (title, tooltipText) => (
  <div>
    <span>{title} </span>
    <Tooltip title={tooltipText} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY} leaveTouchDelay={TOOLTIP.LEAVE_DELAY}>
      <InfoIcon htmlColor={colors.lightBrown} />
    </Tooltip>
  </div>
);

const tableList = ({ grossIncomeAmount, netIncomeAmount, escrowIncomeAmount, pendingIncomeAmount }) => [
  {
    name: renderName(
      'Gross Sales',
      'This number is the total amount of sales you have made on Cohere. If you are a partner coach, sales related information appears on the primary coach account.',
    ),
    value: `$${formatMoney(grossIncomeAmount)} USD`,
  },
  {
    name: renderName(
      'Earned Revenue',
      'This is your gross sales minus Cohere fees. If you are a partner coach, sales related information appears on the primary coach account.',
    ),
    value: `$${formatMoney(netIncomeAmount)} USD`,
  },
  {
    name: renderName(
      'In Escrow',
      'This is revenue earned but still in escrow. For invite only contributions, funds are made available as soon as the initial payment processing is completed. For Contributions open to the public, funds are usually available for bank transfers four business days after a completed 1:1 session or after the first session of a group course.',
    ),
    value: `$${formatMoney(escrowIncomeAmount)} USD`,
  },
  {
    name: renderName(
      'Pending',
      'If your Clients are using split payments to pay you, this is the amount of revenue contractually due to you but has not been charged yet due to split payments.',
    ),
    value: `$${formatMoney(pendingIncomeAmount)} USD`,
  },
];

const SalesSummaryCard = ({ incomes, loading, getIncomes }) => {
  const { request } = useHttp();

  useEffect(() => {
    getIncomes();
  }, [getIncomes]);

  const onClick = () => {
    request('/api/cohealer-income/sales-history', 'GET', null, {}, 'blob')
      .then(res => downloadFile(res, 'Cohere financial activity.csv'))
      .catch(console.dir);
  };

  return (
    <Grid item xs={10} classes={{ root: 'card-container' }}>
      <Card maxHeight>
        <CardHeader>
          <Grid container item md={7} xs={12} classes={{ root: 'rows' }}>
            <Grid container item md={6} xs={12}>
              <PageTitleSecond>Sales Summary</PageTitleSecond>
            </Grid>
            <Grid container item md={6} xs={12} justify="center" alignContent="center">
              <Link onClick={onClick}>Download sales history</Link>
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody className="card-body-container">
          {!isEmpty(incomes) && !loading && (
            <Grid container alignItems="center">
              {tableList(incomes).map(item => (
                <Grid container item md={7} xs={12} classes={{ root: 'rows' }}>
                  <Grid container item md={6} xs={12}>
                    {item.name}
                  </Grid>
                  <Grid container item md={6} xs={12} justify="center">
                    {item.value}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </CardBody>
      </Card>
    </Grid>
  );
};

SalesSummaryCard.propTypes = {
  incomes: PropTypes.shape({
    grossIncomeAmount: PropTypes.number,
    netIncomeAmount: PropTypes.number,
    escrowIncomeAmount: PropTypes.number,
    pendingIncomeAmount: PropTypes.number,
  }),
  loading: PropTypes.bool,
  getIncomes: PropTypes.func.isRequired,
};

SalesSummaryCard.defaultProps = {
  incomes: [],
  loading: false,
};

const mapStateToProps = ({ cohealerIncome }) => ({
  incomes: cohealerIncome?.incomes,
  loading: cohealerIncome?.loading,
});

const actions = {
  getIncomes: cohealerIncomeActions.fetchCohealerIncome,
};

export default connect(mapStateToProps, actions)(SalesSummaryCard);
