import moment from 'moment';

class ClientJourneyContribution {
  id;
  title;
  type;
  userId;
  serviceProviderName;
  likesNumber;
  rating;
  sessionTime;
  numberCompletedSessions;
  purchaseDateTime;
  totalNumberSessions;
  previewContentUrls;

  constructor({
    id,
    title,
    type,
    userId,
    serviceProviderName,
    likesNumber,
    rating,
    sessionTime,
    numberCompletedSessions,
    purchaseDateTime,
    totalNumberSessions,
    previewContentUrls,
  }) {
    this.id = id;
    this.title = title;
    this.type = type;
    this.userId = userId;
    this.serviceProviderName = serviceProviderName;
    this.likesNumber = likesNumber;
    this.rating = rating;
    this.sessionTime = sessionTime && moment(sessionTime);
    this.numberCompletedSessions = numberCompletedSessions;
    this.purchaseDateTime = purchaseDateTime && moment(purchaseDateTime);
    this.totalNumberSessions = totalNumberSessions;
    this.previewContentUrls = previewContentUrls;
  }
}

export default ClientJourneyContribution;
