import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import getCourseType from 'utils/getCourseType';

import ViewContributionButton from './components/ViewContributionButton';

const ContributionTableAdmin = ({ rows }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="admin table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Coherer Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ id, title, type, serviceProviderName, updateTime }) => (
            <TableRow>
              <TableCell>{title}</TableCell>
              <TableCell>{getCourseType(type)}</TableCell>
              <TableCell>{serviceProviderName}</TableCell>
              <TableCell>{moment(updateTime).format('YYYY/MM/DD hh:mm A')}</TableCell>
              <TableCell align="right">
                <Link to={`/admin/contribution-view/${id}`}>
                  <ViewContributionButton />
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ContributionTableAdmin.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
};

ContributionTableAdmin.defaultProps = {
  rows: [],
};

export default ContributionTableAdmin;
