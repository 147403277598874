import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes, { resetWarningCache } from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { getPurchaseValidationSchema } from 'utils/validation';
import { useAccount, useRouter } from 'hooks';
import * as purchaseService from 'services/purchase.service';

import Modal from 'components/UI/Modal';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import { UserRoles } from 'helpers/constants';
import {
  PURCHASE_MODAL_STEPS,
  PURCHASE_MODAL_TITLES,
  ACCOUNT_FORM_FIELDS,
  PAYMENTS_STATUS,
} from './PurchaseModal.constants';
import { PurchaseModalForm } from './PurchaseModalForm';
import { SuccessfulPurchaseModal } from '../../../../components/Modals/SuccessfulPurchaseModal';
import { FailedPurchaseModal } from '../../../../components/Modals/FailedPurchaseModal';
import { useIsEmailExist } from '../../../../utils/useIsEmailExist';
import {
  completeRegister,
  CONTINUE_REGISTER_FAILURE,
  LOG_IN_FAILURE,
  login,
  REGISTER_FAILURE,
} from '../../../../actions/user.js';
import Loader from '../../../../components/UI/Loader';

const PurchaseMembershipModal = ({
  isOpen,
  onClose,
  isUpgrade,
  currentSubscription,
  onUpgrade,
  handleUrlModal,
  contribution,
}) => {
  const [showTerms, setShowTerms] = useState(false);
  const {
    query: { payment },
    history,
  } = useRouter();
  const dispatch = useDispatch();
  const clearUrlSearch = () => {
    history.replace({
      search: '',
    });
  };

  const [loading, setLoading] = useState(false);
  const [isSuccessPurchaseModalOpen, setIsSuccessPurchaseModalOpen] = useState(
    handleUrlModal && payment === PAYMENTS_STATUS.SUCCESS,
  );
  const [isFailedPurchaseModalOpen, setIsFailedPurchaseModalOpen] = useState(
    handleUrlModal && payment === PAYMENTS_STATUS.FAILED,
  );
  const handleSuccessModalClose = useCallback(() => {
    setIsSuccessPurchaseModalOpen(false);
    clearUrlSearch();
  }, []);
  const handleFailedModalClose = useCallback(() => {
    setIsFailedPurchaseModalOpen(false);
    clearUrlSearch();
  }, []);

  const isLoadingAccount = useSelector(({ account }) => account.isLoading);
  const errorAccount = useSelector(({ account }) => account.error?.message);
  const errorContribution = useSelector(({ contributions }) => contributions.error?.message);

  const { user } = useAccount();
  const {
    type,
    paymentInfo: { paymentOptions },
    id,
  } = contribution;
  const [step, setStep] = useState(() => {
    if (!isEmpty(user)) return PURCHASE_MODAL_STEPS.memberLoggedIn;
    return PURCHASE_MODAL_STEPS.memberInit;
  });

  const formRef = useRef(null);

  const stripe = useStripe();
  const [typeOfPayment, setTypeOfPayment] = useState(
    paymentOptions.filter(option => option !== currentSubscription)[0],
  );
  useEffect(() => setTypeOfPayment(paymentOptions.filter(option => option !== currentSubscription)[0]), [
    currentSubscription,
  ]);

  const [summary, setSummary] = useState(null);
  const { checkEmail, isLoadingEmail } = useIsEmailExist();

  const purchaseMembership = useCallback(() => {
    setLoading(true);
    formRef.current.setTouched({});
    const data = { paymentOption: typeOfPayment, contributionId: id };
    if (isUpgrade) {
      purchaseService
        .upgradeMembership(data)
        .then(() => {
          onUpgrade(typeOfPayment);
          setLoading(false);
        })
        .catch(console.dir);
    } else {
      purchaseService
        .purchaseMembershipSubscription(data)
        .then(token => {
          return stripe.redirectToCheckout({
            sessionId: token,
          });
        })
        .catch(console.dir);
    }
  }, [isUpgrade, typeOfPayment, contribution.id, stripe]);

  const handleSubmit = useCallback(() => {
    const {
      current: { values },
    } = formRef;

    if (!isEmpty(user)) {
      return purchaseMembership();
    }

    if (step === PURCHASE_MODAL_STEPS.memberInit) {
      checkEmail(values[ACCOUNT_FORM_FIELDS.email]).then(isExistEmail =>
        setStep(isExistEmail ? PURCHASE_MODAL_STEPS.memberLogin : PURCHASE_MODAL_STEPS.memberCreate),
      );
    } else if (step === PURCHASE_MODAL_STEPS.memberLogin) {
      dispatch(login(values[ACCOUNT_FORM_FIELDS.email], values[ACCOUNT_FORM_FIELDS.password])).then(loginAction => {
        if (loginAction?.type !== LOG_IN_FAILURE) {
          purchaseMembership();
        }
      });
    } else if (step === PURCHASE_MODAL_STEPS.memberCreate) {
      dispatch(completeRegister({ ...values, userView: UserRoles.client })).then(registerAction => {
        if (registerAction?.type !== REGISTER_FAILURE && registerAction?.type !== CONTINUE_REGISTER_FAILURE) {
          purchaseMembership();
        }
      });
    }
  }, [step, setStep, typeOfPayment, contribution.id, stripe, isUpgrade]);

  useEffect(() => {
    purchaseService.getMembershipPaymentInfo(contribution.id, typeOfPayment).then(setSummary).catch(console.dir);
  }, [typeOfPayment, contribution.id]);

  const getSubmitButtonTitle = () => {
    if (isEmpty(user) && step === PURCHASE_MODAL_STEPS.memberInit) return 'Next';
    return PURCHASE_MODAL_TITLES[type].submit;
  };
  return (
    <>
      {loading && <Loader />}
      <Modal
        title="Purchase Membership"
        isOpen={isOpen}
        onCancel={onClose}
        submitTitle={getSubmitButtonTitle()}
        loading={isLoadingAccount}
        form="credit-card"
        helperText={
          isEmpty(user) &&
          step !== PURCHASE_MODAL_STEPS.memberInit &&
          (errorAccount || errorContribution || 'Please fill out account information')
        }
      >
        <Formik
          initialValues={{
            Name: '',
            cardNumber: false,
            cardExpired: false,
            cardCVC: false,
            [ACCOUNT_FORM_FIELDS.confirmEmail]: '',
            [ACCOUNT_FORM_FIELDS.email]: '',
            [ACCOUNT_FORM_FIELDS.password]: '',
            [ACCOUNT_FORM_FIELDS.birthDate]: '',
            [ACCOUNT_FORM_FIELDS.firstName]: '',
            [ACCOUNT_FORM_FIELDS.lastName]: '',
          }}
          validationSchema={getPurchaseValidationSchema(step)}
          innerRef={formRef}
          onSubmit={handleSubmit}
        >
          {({ handleChange, errors, touched }) => (
            <PurchaseModalForm
              typeOfPayment={typeOfPayment}
              setTypeOfPayment={setTypeOfPayment}
              summary={summary}
              handleChange={handleChange}
              errors={errors}
              setShowTerms={setShowTerms}
              touched={touched}
              step={step}
              currentSubscription={currentSubscription}
              onCheckoutSessionExpired={() => onClose(false)}
              paymentInfo={contribution.paymentInfo}
              contribution={contribution}
            />
          )}
        </Formik>

        <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />
      </Modal>
      <SuccessfulPurchaseModal isOpen={isSuccessPurchaseModalOpen} handleClose={handleSuccessModalClose} />
      <FailedPurchaseModal isOpen={isFailedPurchaseModalOpen} handleClose={handleFailedModalClose} />
    </>
  );
};

PurchaseMembershipModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isPackage: PropTypes.bool,
  isUpgrade: PropTypes.bool,
  isMonthlySessionSubscription: PropTypes.bool,
  showOnlyProseedModal: PropTypes.func,
  currentSubscription: PropTypes.string,
};

PurchaseMembershipModal.defaultProps = {
  isPackage: false,
  isMonthlySessionSubscription: false,
  showOnlyProseedModal: null,
};

export default PurchaseMembershipModal;
