import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  useChatClientChannel,
  ChatClientChannelConnectionStatus,
  useChatClientChannelAttributes,
  useChatClientChannelUtilities,
  useChatClientChannelMembers,
  useChatClientChannelMessages,
} from 'hooks'
import { LabelText } from 'components/UI/Text/TextStyles'
import Chat from './Chat'

import './Chat.scss'

const ChatContainer = ({ channelId, ...restProps }) => {
  const { connectionStatus, channel } = useChatClientChannel(channelId)
  const { members, myMember, typingMembers, getMemberBySid } = useChatClientChannelMembers(channel)
  const { messages, hasMoreMessages, isLoadingMoreMessages, loadMoreMessages } = useChatClientChannelMessages(channel)
  const { title, iconUrl } = useChatClientChannelAttributes(channel, members, myMember)
  const { sendTextMessage, sendMediaMessage, readMessage, onTyping } = useChatClientChannelUtilities(channel)

  const membersReady = !!myMember
  const chatReady = connectionStatus === ChatClientChannelConnectionStatus.connected && membersReady

  const onLoadMoreMessages = () => {
    if (hasMoreMessages && !isLoadingMoreMessages) {
      loadMoreMessages()
    }
  }

  return (
    <div className="chat-container">
      {(connectionStatus === ChatClientChannelConnectionStatus.pending ||
        connectionStatus === ChatClientChannelConnectionStatus.connecting ||
        (connectionStatus === ChatClientChannelConnectionStatus.connected && !membersReady)) && <CircularProgress />}
      {connectionStatus === ChatClientChannelConnectionStatus.connectionError && (
        <LabelText>Could not connect to chat.</LabelText>
      )}
      {chatReady && (
        <Chat
          {...restProps}
          title={title}
          iconUrl={iconUrl}
          members={members}
          myMember={myMember}
          typingMembers={typingMembers}
          memberProvider={getMemberBySid}
          messages={messages}
          isLoadingMoreMessages={isLoadingMoreMessages}
          onLoadMoreMessages={onLoadMoreMessages}
          onReadMessage={readMessage}
          sendTextMessage={sendTextMessage}
          sendMediaMessage={sendMediaMessage}
          onTyping={onTyping}
        />
      )}
    </div>
  )
}

ChatContainer.propTypes = {
  channelId: PropTypes.string.isRequired,
}

export default ChatContainer
