import React from 'react';
import styled from 'styled-components';
import { toRem, colors, devices } from 'utils/styles';
import { NavLink } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { useRouter } from 'hooks';
import { JOURNEY_ROUTES } from 'helpers/constants';

const StyledClientJourneyHeaderWrapper = styled.div`
  background-color: ${colors.white};
  border: solid 1px ${colors.darkWhite};
  padding: 0;
`;

const StyledClientJourneyHeaderContainer = styled.div`
  padding: 0 16px;
  margin: 0 auto;

  @media screen and (${devices.desktop}) {
    padding: 0 60px;
  }

  ${({ mobileView }) => mobileView && `padding-left: 0px;`}
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: ${toRem(16)};
  line-height: 1;
  font-weight: 900;
  color: ${colors.darkGray};
  padding: 16px 32px;
  display: inline-block;
  position: relative;

  &.active {
    color: ${colors.darkOceanBlue};
  }

  &.active:after {
    content: '';
    width: 100%;
    height: 4px;
    background-color: ${colors.darkOceanBlue};
    position: absolute;
    bottom: 0;
    left: 0;
  }

  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        padding: 16px 15px;
      `
    );
  }}

  ${({ firstLink }) => firstLink && `padding-left: 16px;`}
`;

const ClientJourneyHeader = ({ tabClick }) => {
  const {
    match: { url },
  } = useRouter();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <StyledClientJourneyHeaderWrapper>
      <StyledClientJourneyHeaderContainer mobileView={mobileView}>
        <StyledNavLink
          to={`${url}/${JOURNEY_ROUTES.SESSIONS}`}
          onClick={() => tabClick(JOURNEY_ROUTES.SESSIONS)}
          mobileView={mobileView}
          firstLink
        >
          Sessions
        </StyledNavLink>
        <StyledNavLink
          to={`${url}/${JOURNEY_ROUTES.COACHES}`}
          onClick={() => tabClick(JOURNEY_ROUTES.COACHES)}
          mobileView={mobileView}
        >
          Coaches
        </StyledNavLink>
        <StyledNavLink
          to={`${url}/${JOURNEY_ROUTES.MEMBERSHIP}`}
          onClick={() => tabClick(JOURNEY_ROUTES.MEMBERSHIP)}
          mobileView={mobileView}
        >
          Memberships
        </StyledNavLink>
        <StyledNavLink
          to={`${url}/${JOURNEY_ROUTES.ONE_TO_ONE}`}
          onClick={() => tabClick(JOURNEY_ROUTES.ONE_TO_ONE)}
          mobileView={mobileView}
        >
          1:1s
        </StyledNavLink>
        <StyledNavLink
          to={`${url}/${JOURNEY_ROUTES.LIVE}`}
          onClick={() => tabClick(JOURNEY_ROUTES.LIVE)}
          mobileView={mobileView}
        >
          Live Courses
        </StyledNavLink>
      </StyledClientJourneyHeaderContainer>
    </StyledClientJourneyHeaderWrapper>
  );
};

export default ClientJourneyHeader;
