import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useHttp, useAccount } from 'hooks';
import * as payoutActions from 'actions/payout';
import { formatMoney } from 'utils/datesAndMoney';

import Loader from 'components/UI/Loader';
import Grid from '@material-ui/core/Grid';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import Button from 'components/FormUI/Button';

import '../../Payment.scss';

const BalanceCard = ({ balance: { amount, currency }, getBalance }) => {
  const { request, loading } = useHttp();
  const { user } = useAccount();

  const onGetBalance = useCallback(() => {
    getBalance();
  }, [getBalance]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  if (loading) {
    return <Loader />;
  }

  const onGetPaid = () => {
    request('/api/Payout/get-paid/full', 'POST').then(onGetBalance);
  };

  return (
    <Grid item xs={10} classes={{ root: 'card-container' }}>
      <Card maxHeight>
        <CardHeader>
          <PageTitleSecond>Balance</PageTitleSecond>
        </CardHeader>
        <CardBody className="card-body-container">
          {(!!amount || amount === 0) && (
            <>
              <p className="card-body-container__text">
                Your balance is:
                <span className="card-body-container__text-amount">{` $${formatMoney(amount)} ${currency}`}</span>
              </p>
              {user.payoutsEnabled && (
                <Button disabled={amount === 0} autoWidth variant="primary" onClick={onGetPaid}>
                  Get Paid
                </Button>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </Grid>
  );
};

BalanceCard.propTypes = {
  balance: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  getBalance: PropTypes.func.isRequired,
};

BalanceCard.defaultProps = {
  balance: {},
};

const mapStateToProps = ({ payout }) => ({
  balance: payout?.balance,
});

const actions = {
  getBalance: payoutActions.fetchBalance,
};

export default connect(mapStateToProps, actions)(BalanceCard);
