import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTheme, useMediaQuery } from '@material-ui/core'

import Button from 'components/FormUI/Button'
import { Chat, Notes } from '../VideoChat/VideoChat/Toolbar'
import { ChatPanel, NotesPanel, SidePanelType } from '../VideoChat/VideoChat/sidePanels'
import { CloseVideoButton } from '../VideoChat/VideoChat/sidePanels/CloseVideoButton'

import './VideoPlayer.scss'
import { LabelText } from '../Text/TextStyles'

const VideoPlayer = ({ onCloseVideo, chatId, error, loading, link }) => {
  const [sidePanelType, setSidePanelType] = useState(null)
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const videoPlayerClassname = mobileView ? 'video-player-mobile' : 'video-player'

  const onSidePanelEnabledChange = (newSidePanelType, enabled) => setSidePanelType(enabled ? newSidePanelType : null)

  return (
    <div className="video-player-container">
      {loading && <LabelText>Loading video...</LabelText>}
      {error && (
        <div className="video-chat-container-connection-error">
          <LabelText>Loading video failed.</LabelText>
          <br />
          <Button className="video-chat-container-connection-error__close" autoWidth onClick={onCloseVideo}>
            Close
          </Button>
        </div>
      )}
      {!error && !loading && link && (
        <div className={classNames('video-player-container__video-player', `${videoPlayerClassname}`)}>
          <div className="video-player__content">
            <div className="video-player__content-container">
              <div className="video-player__content-inner">
                <video className="video-player__content-video" playsinline controls muted>
                  <source src={link} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <div className={`${videoPlayerClassname}__footer ${videoPlayerClassname}-footer`}>
            <div className={`${videoPlayerClassname}-footer-toolbar`}>
              <Chat
                enabled={sidePanelType === SidePanelType.chat}
                onEnabledChange={(enabled) => onSidePanelEnabledChange(SidePanelType.chat, enabled)}
              />
              <Notes
                enabled={sidePanelType === SidePanelType.notes}
                onEnabledChange={(enabled) => onSidePanelEnabledChange(SidePanelType.notes, enabled)}
              />
              <CloseVideoButton onCloseVideo={onCloseVideo} />
            </div>
          </div>

          {sidePanelType === SidePanelType.chat && (
            <ChatPanel className={`${videoPlayerClassname}__side-panel`} chatId={chatId} />
          )}
          {sidePanelType === SidePanelType.notes && (
            <NotesPanel
              className={`${videoPlayerClassname}__side-panel`}
              onEnabledChange={(enabled) => onSidePanelEnabledChange(SidePanelType.notes, enabled)}
            />
          )}
        </div>
      )}
    </div>
  )
}

VideoPlayer.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  link: PropTypes.bool.isRequired,
  onCloseVideo: PropTypes.bool.isRequired,
  chatId: PropTypes.string.isRequired,
}

export default VideoPlayer
