import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './TimePickerField.scss';

function TimePickerField(props) {
  const { onChange, ...restProps } = props;

  return (
    <TimePicker
      className="time-picker"
      onChange={onChange}
      popupClassName="time-picker__pop-up"
      onFocus={e => {
        e.target.blur();
      }}
      {...restProps}
    />
  );
}

TimePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

TimePickerField.defaultProps = {};

export default TimePickerField;
