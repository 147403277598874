import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { colors } from 'utils/styles';

const StyledButton = styled.button`
  position: relative;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 2px;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  min-width: 18rem;
  transition: background 0.2s;
  font-family: 'Avenir';

  ${({ autoWidth }) =>
    autoWidth &&
    css`
      min-width: auto;
    `}

  ${({ variant, backgroundColor }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
    return variant === 'primary'
      ? css`
          background-color: ${colors.darkOceanBlue};
        `
      : css`
          background-color: ${colors.lightBrown};
        `;
  }}

  ${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

  &:hover {
    ${({ variant, backgroundColor }) => {
      if (backgroundColor) {
        return css`
          background-color: ${darken(0.05, backgroundColor)};
        `;
      }
      return variant === 'primary'
        ? css`
            background-color: ${darken(0.05, colors.darkOceanBlue)};
          `
        : css`
            background-color: ${darken(0.05, colors.lightBrown)};
          `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        background-color: ${darken(0.05, 'white')};
      `}
  }

  :disabled {
    background-color: #ededed;
    color: #9b9b9b;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;

const Button = ({ children, ...attrs }) => {
  return <StyledButton {...attrs}>{children}</StyledButton>;
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  backgroundColor: PropTypes.string,
  autoWidth: PropTypes.bool,
  invert: PropTypes.bool,
  mobileView: PropTypes.bool,
};

Button.defaultProps = {
  children: 'Default button',
  onClick: () => {},
  disabled: false,
  active: false,
  variant: 'secondary',
  backgroundColor: null,
  autoWidth: false,
  invert: false,
  mobileView: false,
};

export default Button;
