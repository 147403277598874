import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'formik';

import Icon from 'components/UI/Icon';
import EditButton from 'components/UI/ListButtons/EditButton';
import Input from 'components/FormUI/Input';
import CollapseIcon from 'assets/ic-collapse-arrows.svg';
import ExpandIcon from 'assets/ic-expand-arrows.svg';

import { colors } from 'utils/styles';

const useStyles = makeStyles(() => ({
  labelRoot: {
    fontFamily: 'Avenir',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    fontSize: `${14 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
  },
}));

const StyledInputLabel = styled(InputLabel)`
  margin-top: 10px;
`;

const StyledTextArea = styled.textarea`
  border: solid 1px #e4e4e4;
  border-radius: 3px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.27;
  letter-spacing: 0.25px;
  resize: none;
  padding: 0.85rem;
  font-family: 'Avenir';
  font-weight: normal;
  outline: none;

  ::-webkit-input-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  }
  ::-moz-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  } /* Firefox 19+ */
  :-moz-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  } /* Firefox 18- */
  :-ms-input-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${colors.fadedPurple};
    `}

  ${({ isCollapsed }) =>
    !isCollapsed &&
    `
    height: 360px;
  `}
`;

const HelperText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  font-weight: normal;
  line-height: 2rem;
  letter-spacing: 0.08px;
  font-family: 'Avenir';
  text-align: ${({ align }) => align || 'right'};
`;

const StyledErrorHelper = styled(HelperText)`
  text-align: left;
  color: ${colors.fadedPurple};
  font-size: 1rem;
  text-align: left;
  letter-spacing: 0.1px;
`;

const TextFieldContainer = styled.div`
  display: inline-flex;
  border: 0;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledIcon = styled.img`
  position: absolute;
  right: 7px;
  top: 55px;
  width: 12px;
  height: 12px;

  &:hover {
    cursor: pointer;
  }
`;

function TextArea({
  image,
  fullWidth,
  helperText,
  optional,
  placeholder,
  helperTextPosition,
  counter,
  label,
  isLabelEditable,
  labelName,
  onClickResize,
  isCollapsed,
  ...props
}) {
  const classes = useStyles();
  const [field, meta] = useField(props);
  let privateHelperText = helperText || (optional ? 'optional' : '');
  const privateHelperTextPosition = optional ? 'right' : helperTextPosition || 'left';

  if (counter) {
    privateHelperText = `${field?.value?.length}/${counter}`;
  }
  const [isEditLabelMode, setIsEditLabelMode] = useState(false);
  const editLabelToggle = useCallback(() => setIsEditLabelMode(labelMode => !labelMode), [setIsEditLabelMode]);

  return (
    <TextFieldContainer fullWidth={fullWidth}>
      <StyledLabel>
        {Boolean(image) && <Icon path={image} right="5" valign="top" />}
        {Boolean(label) && !isEditLabelMode && (
          <StyledInputLabel
            classes={{
              root: classes.labelRoot,
            }}
          >
            {label}
          </StyledInputLabel>
        )}
        {isEditLabelMode && <Input type="text" name={labelName} value={label} />}
        {isLabelEditable && (
          <div onClick={editLabelToggle}>
            <EditButton />
          </div>
        )}
      </StyledLabel>

      <StyledTextArea
        placeholder={placeholder}
        error={meta.touched && meta.error}
        isCollapsed={isCollapsed}
        {...field}
        {...props}
      />
      {onClickResize && <StyledIcon src={isCollapsed ? ExpandIcon : CollapseIcon} onClick={onClickResize} />}

      {meta.touched && meta.error ? (
        <StyledErrorHelper>{meta.error}</StyledErrorHelper>
      ) : (
        privateHelperText && <HelperText align={privateHelperTextPosition}>{privateHelperText}</HelperText>
      )}
    </TextFieldContainer>
  );
}

TextArea.propTypes = {
  image: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  optional: PropTypes.bool,
  helperTextPosition: PropTypes.oneOf(['left', 'right']),
  counter: PropTypes.number,
  label: PropTypes.string,
  isLabelEditable: PropTypes.bool,
  labelName: PropTypes.string,
  onClickResize: PropTypes.func,
  isCollapsed: PropTypes.bool,
};

TextArea.defaultProps = {
  image: null,
  placeholder: null,
  fullWidth: false,
  helperText: null,
  optional: false,
  helperTextPosition: 'left',
  counter: null,
  label: '',
  isLabelEditable: false,
  labelName: '',
  isCollapsed: true,
};

export default TextArea;
