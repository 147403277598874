import { get } from 'lodash/fp';

import axiosInstance from 'utils/axiosInstance';

const ChatType = {
  directWithClients: 'DirectWithClients',
  directWithCohealers: 'DirectWithCohealers',
  groupDiscussion: 'GroupDiscussion',
};

const getChatTokenAsync = () => axiosInstance.get('/Chat/GetToken').then(get('data'));

const markChatAsFavoriteAsync = (chatId, isFavorite) =>
  axiosInstance.post(isFavorite ? `/Chat/SetAsFavorite/${chatId}` : `/Chat/SetAsRegular/${chatId}`).then(get('data'));

const getChatsByTypeAsync = (type = ChatType.groupDiscussion) =>
  axiosInstance.get(`/Chat/GetChatsByType?type=${type}`).then(get('data'));

const getPersonalChatWithAsync = userId =>
  axiosInstance.post(`/Chat/CreatePersonalChatWith/${userId}`).then(get('data'));

export default {
  getChatTokenAsync,
  markChatAsFavoriteAsync,
  getChatsByTypeAsync,
  getPersonalChatWithAsync,
};
export { ChatType };
