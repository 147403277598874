import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
import { Link } from 'react-router-dom';
import { toRem } from 'utils/styles';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  font-family: 'Avenir';
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;

  ${({ mobileView }) => mobileView && `font-size: 0.9rem`}
`;

const StyledDropDowmContainer = styled.div`
  position: relative;
`;

const StyledItemsContainer = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
`;

function ClickDropDown({ title, children, mobileView, id, icon = mdiChevronDown }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const setShowToFalse = () => setShow(false);
    window.addEventListener('click', setShowToFalse);

    return () => {
      window.removeEventListener('click', setShowToFalse);
    };
  }, []);

  const clickHandler = useCallback(e => {
    e.stopPropagation();
    setShow(prev => !prev);
  }, []);

  return (
    <StyledDropDowmContainer>
      <StyledButton mobileView={mobileView} onClick={clickHandler} id={id}>
        {title}
        <Icon size={1} path={icon} style={{ verticalAlign: 'bottom' }} />
      </StyledButton>
      {show && <StyledItemsContainer>{children}</StyledItemsContainer>}
    </StyledDropDowmContainer>
  );
}

ClickDropDown.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  mobileView: PropTypes.bool,
  icon: PropTypes.string,
};

export const StyledDropDownItem = styled(Link)`
  font-size: ${toRem(16)};
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  background-color: white;
  padding: 8px;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 1px solid #f0f0f0;
  min-width: 100px;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

export default ClickDropDown;
