import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Popup from 'components/Popup/Popup';

import { LabelText, PageSubtitle, PageTitleSecond } from 'components/UI/Text/TextStyles';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { FormikScrollToError } from 'components/FormikScrollToError';
import { useAccount } from 'hooks';
import { formatMoney } from 'utils/datesAndMoney';
import CreditCard from './CreditCard';
import { AccountForm } from './AccountForm/AccountForm';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment';
import { PAYMENT_OPTIONS } from '../../../../constants.js';
import { ContributionType } from 'helpers/constants';

export const PurchaseModalForm = ({
  typeOfPayment,
  setTypeOfPayment,
  loading,
  summary,
  isPackage,
  isMonthlySessionSubscription,
  handleChange,
  errors,
  touched,
  error,
  setShowTerms,
  step,
  paymentIntentCreated,
  sessionLifeTimeSeconds,
  onCheckoutSessionExpired,
  isInviteToJoin,
}) => {
  const theme = useTheme();
  const [sessionTimeExpired, setSessionTimeExpired] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { user } = useAccount();

  const contribution = useContribution();
  const {
    paymentInfo: {
      paymentOptions,
      splitNumbers,
      splitPeriod,
      packageSessionNumbers,
      monthlySessionSubscriptionInfo,
      membershipInfo,
    },
    type,
    title,
  } = contribution;
  const showDueLater = type !== ContributionType.contributionMembership;

  return (
    <>
      <FormikScrollToError>
        <Form id="credit-card-form">
          <Grid container spacing={2}>
            {!isInviteToJoin && (
              <>
                <Grid item md={5} xs={12}>
                  <LabelText mobileView={mobileView}>Payment Summary</LabelText>

                  <RadioGroup value={typeOfPayment} onChange={e => setTypeOfPayment(e.target.value)}>
                    {paymentOptions.includes('EntireCourse') && (
                      <FormControlLabel
                        disabled={loading}
                        value="EntireCourse"
                        control={<Radio color="primary" />}
                        label="Paid in Full"
                      />
                    )}
                    {paymentOptions.includes('SplitPayments') && (
                      <FormControlLabel
                        disabled={false || loading}
                        value="SplitPayments"
                        control={<Radio color="primary" />}
                        label={`Split: ${splitNumbers} Payments, Billed ${splitPeriod}`}
                      />
                    )}
                    {paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && (
                      <FormControlLabel
                        disabled={loading}
                        value={PAYMENT_OPTIONS.PER_MONTH}
                        control={<Radio color="primary" />}
                        label="Monthly subscription"
                      />
                    )}
                    {paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && (
                      <FormControlLabel
                        disabled={loading}
                        value={PAYMENT_OPTIONS.PER_YEAR}
                        control={<Radio color="primary" />}
                        label="Yearly subscription"
                      />
                    )}
                    {paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && (
                      <FormControlLabel
                        disabled={loading}
                        value={PAYMENT_OPTIONS.PACKAGE}
                        control={<Radio color="primary" />}
                        label={`${membershipInfo.membershipPackage.duration} months subscription with ${membershipInfo.membershipPackage.period} payment`}
                      />
                    )}
                  </RadioGroup>

                  <div>
                    {summary && !isMonthlySessionSubscription && (
                      <>
                        <PageSubtitle>
                          {paymentOptions.includes('PerSession')
                            ? isPackage
                              ? `Price per ${packageSessionNumbers} Sessions Package`
                              : 'Price per Session'
                            : 'Price per Course'}
                          : ${formatMoney(summary.price - summary.platformFee)}
                        </PageSubtitle>
                        <PageSubtitle>Processing Fee: ${formatMoney(summary.platformFee)}</PageSubtitle>
                        <PageSubtitle>Due Now: ${formatMoney(summary.dueNow)}</PageSubtitle>
                        {showDueLater && <PageSubtitle>Due Later: ${formatMoney(summary.dueLater)}</PageSubtitle>}
                        {paymentIntentCreated && sessionLifeTimeSeconds && (
                          <PageSubtitle>
                            Checkout time left:{' '}
                            <Countdown
                              date={moment(paymentIntentCreated).add(sessionLifeTimeSeconds, 'seconds')}
                              onComplete={() => {
                                setSessionTimeExpired(true);
                              }}
                              renderer={props => (
                                <>
                                  {zeroPad(props.minutes)}:{zeroPad(props.seconds)}
                                </>
                              )}
                            />
                          </PageSubtitle>
                        )}
                      </>
                    )}
                    {summary && isMonthlySessionSubscription && (
                      <>
                        <PageSubtitle>
                          {`Total Price - Includes ${monthlySessionSubscriptionInfo.sessionCount} Session(s) Monthly, ${monthlySessionSubscriptionInfo.duration} Months Total`}
                          : ${formatMoney(summary.price - summary.platformFee)}
                        </PageSubtitle>
                        <PageSubtitle>Processing Fee: ${formatMoney(summary.platformFee)}</PageSubtitle>
                        <PageSubtitle>Due Now: ${formatMoney(summary.dueNow)}</PageSubtitle>
                        <PageSubtitle>Due Later: ${formatMoney(summary.dueLater)}</PageSubtitle>
                        {paymentIntentCreated && sessionLifeTimeSeconds && (
                          <PageSubtitle>
                            Checkout time left:{' '}
                            <Countdown
                              date={moment(paymentIntentCreated).add(sessionLifeTimeSeconds, 'seconds')}
                              onComplete={() => {
                                setSessionTimeExpired(true);
                              }}
                              renderer={props => (
                                <>
                                  {zeroPad(props.minutes)}:{zeroPad(props.seconds)}
                                </>
                              )}
                            />
                          </PageSubtitle>
                        )}
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item md={7} xs={12}>
                  <CreditCard
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    setShowTerms={setShowTerms}
                  />
                  {error && <CommonErrorMessage message={error} />}
                </Grid>
              </>
            )}
            {!isEmpty(user) && isInviteToJoin && (
              <Grid item xs={12}>
                <PageTitleSecond>You joined {title} successfully</PageTitleSecond>
              </Grid>
            )}
            {isEmpty(user) && <AccountForm handleChange={handleChange} errors={errors} touched={touched} step={step} />}
          </Grid>
        </Form>
      </FormikScrollToError>
      <Popup
        open={sessionTimeExpired}
        title="The checkout time has expired"
        text="The checkout time has expired. Please select your session time again to complete registration."
        onCloseText="Select Session"
        onClose={onCheckoutSessionExpired}
      />
    </>
  );
};

PurchaseModalForm.propTypes = {
  typeOfPayment: PropTypes.string.isRequired,
  setTypeOfPayment: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  summary: PropTypes.shape({
    price: PropTypes.number,
    platformFee: PropTypes.number,
    dueNow: PropTypes.number,
    dueLater: PropTypes.number,
  }),
  isPackage: PropTypes.bool.isRequired,
  isMonthlySessionSubscription: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})),
  touched: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
  setShowTerms: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
  paymentIntentCreated: PropTypes.string,
  sessionLifeTimeSeconds: PropTypes.number,
  monthlySessionSubscriptionInfo: PropTypes.shape({
    sessionCount: PropTypes.number,
    duration: PropTypes.number,
    monthlyPrice: PropTypes.number,
  }),
};

PurchaseModalForm.defaultProps = {
  summary: undefined,
  isInviteToJoin: null,
  errors: undefined,
  error: undefined,
  paymentIntentCreated: undefined,
  sessionLifeTimeSeconds: undefined,
};
