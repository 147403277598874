import React from 'react';

import { useRouter } from 'hooks';
import ConversationsList from './ConversationsList';

const AllConversationsList = () => {
  const {
    location: { state },
  } = useRouter();
  const preferredConversationId = state ? state.preferredConversationId : null;

  return <ConversationsList preferredConversationId={preferredConversationId} />;
};

export default AllConversationsList;
