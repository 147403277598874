import React from 'react'
import PropTypes from 'prop-types'
import PopupComp from 'components/Popup/Popup'

export const RemoveCalendarModal = ({ isOpen, onCancel, onSubmit }) => {
  return (
    <PopupComp
      title="Remove calendar"
      text="Do you want to remove this calendar?"
      open={isOpen}
      onCloseText="No"
      onClose={onCancel}
      onSubmit={onSubmit}
    />
  )
}

RemoveCalendarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
