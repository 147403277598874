import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import Session from './Session'

const CourseSessions = ({
  contribution: { sessions, id: contributionId, isPurchased, timeZoneId },
  renderSessionTimeActions,
}) => {
  return (
    <div>
      {!isEmpty(sessions) &&
        orderBy(sessions, ['startTime'], ['asc']).map((s, i) => (
          <Session
            key={s.id}
            number={i + 1}
            session={s}
            contributionId={contributionId}
            contributionTimeZoneId={timeZoneId}
            isPurchased={isPurchased}
            renderSessionTimeActions={renderSessionTimeActions}
          />
        ))}
    </div>
  )
}

CourseSessions.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
    isPurchased: PropTypes.bool,
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }).isRequired,
  renderSessionTimeActions: PropTypes.func.isRequired,
}

export default CourseSessions
