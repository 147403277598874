import { useCallback, useState } from 'react';
import * as R from 'ramda';
import { useHttp } from '../../../hooks';

export const useVideoDurationParse = () => {
  const [videoDuration, setVideoDuration] = useState('0h 0m');
  const { request, loading, progress } = useHttp();
  const [videoSrc, setVideoSrc] = useState(null);

  const convertDuration = useCallback((files, form, contribution, index) => {
    if (files.length >= 1) {
      const formData = new FormData();
      setVideoSrc(URL.createObjectURL(files[0]));

      const video = document.createElement('video');

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        const hours = parseInt(video.duration / 3600);
        const minutes = parseInt((video.duration % 3600) / 60);
        setVideoDuration(`${hours}h ${minutes}m`);

        formData.append('file', R.head(files));
        formData.append('contributionId', contribution.id);
        formData.append('duration', `${hours}h ${minutes}m`);
        request('/Content/AddRecordedAttachmentOnContribution', 'POST', formData, {
          'Content-Type': 'multipart/form-data',
        })
          .then(data => {
            form.setFieldValue(`sessions[${index}].prerecordedSession`, data);
          })
          .catch(err => {});
      };
      video.src = URL.createObjectURL(files[0]);
    }
  }, []);

  return { convertDuration, videoDuration, loading, progress, videoSrc };
};
