import React from 'react'

import List, { Item } from './List'
import {
  AllConversationsUnreadMessagesCount,
  ClientsConversationsUnreadMessagesCount,
  DiscussionsConversationsUnreadMessagesCount,
} from './conversations/UnreadMessagesCount'

function CohealerMenu() {
  return (
    <List>
      <Item to="/dashboard" id="coach-menu-dashboard-link" title="Dashboard" />
      <Item title="Contributions" id="coach-menu-contributions-link" isList>
        <List>
          <Item to="/contributions/all" id="coach-menu-contributions-all-link" title="All" />
          <Item
            to="/contributions/group-courses"
            id="coach-menu-contributions-group-courses-link"
            title="Group Courses"
          />
          <Item to="/contributions/1-to-1-sessions" id="coach-menu-contributions-1-to-1-link" title="1:1 Sessions" />
          <Item to="/contributions/memberships" title="Memberships" />
          <Item to="/contributions/archived" id="coach-menu-contributions-archived-link" title="Archived" />
        </List>
      </Item>
      <Item title="Conversations" id="coach-menu-conversations-link" isList>
        <List>
          <Item
            id="coach-menu-conversations-all-link"
            to="/conversations/all"
            title="All"
            endAdornment={<AllConversationsUnreadMessagesCount />}
          />
          <Item
            id="coach-menu-conversations-clients-link"
            to="/conversations/clients"
            title="Clients"
            endAdornment={<ClientsConversationsUnreadMessagesCount />}
          />
          <Item
            id="coach-menu-conversations-group-link"
            to="/conversations/discussions"
            title="Group Discussions"
            endAdornment={<DiscussionsConversationsUnreadMessagesCount />}
          />
        </List>
      </Item>
    </List>
  )
}

export default CohealerMenu
