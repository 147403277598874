import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledTitle } from '../GettingStarted.styles';

const StyledImage = styled.img`
  margin-top: 20px;
  height: 70%;

  ${({ mobileView }) =>
    mobileView &&
    `
    width: 80%;
    height: auto;
  `}
`;

const Invitation = ({ mobileView }) => {
  return (
    <>
      <StyledImage src="/get-started-invite.png" mobileView={mobileView} />

      <StyledTitle mobileView={mobileView}>
        After you’ve created a Contribution, invite clients, makes sales and earn revenue.
      </StyledTitle>
    </>
  );
};

Invitation.propTypes = {
  mobileView: PropTypes.bool,
};

Invitation.defaultProps = {
  mobileView: false,
};

export default Invitation;
