import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const inviteByEmail = data => axiosInstance.post(`/Affiliate/InviteByEmail`, data).then(get('data'));

const getAffiliateName = inviteCode =>
  axiosInstance.get(`/Affiliate/GetAffiliateName?inviteCode=${inviteCode}`).then(get('data'));

const getAffiliateSummary = () => axiosInstance.get(`/Affiliate/AffiliateRevenueSummary`).then(get('data'));

const toggleAffiliateEnrollment = () => axiosInstance.post(`/Affiliate/ToggleEnrollmentStatus`).then(get('data'));

const getEnrollmentStatus = () => axiosInstance.get(`/Affiliate/IsEnrolled`).then(get('data'));

const getPaidFull = () => axiosInstance.post(`/Affiliate/GetPaid/Full`).then(get('data'));

export {
  inviteByEmail,
  getAffiliateName,
  getAffiliateSummary,
  toggleAffiliateEnrollment,
  getEnrollmentStatus,
  getPaidFull,
};
