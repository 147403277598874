import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';

import { mdiApps, mdiMessageProcessing, mdiTimerOutline, mdiGenderMale, mdiAccountOutline } from '@mdi/js';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { getLanguages } from 'utils/transformerLanguages';
import { Card, CardBody } from 'components/UI/Card';
import { LabelText } from 'components/UI/Text/TextStyles';
import Icon from 'components/UI/Icon';

const useStyles = makeStyles(theme => ({
  gridItem: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const genderDisplay = {
  NoRequirement: 'No Requirement',
  Male: 'For Men',
  Female: 'For Women',
  NonBinary: 'Everyone Welcome',
};

const getSessionsCount = ({ availabilityTimes = [], clientPackages }) => {
  let newArray = [];

  availabilityTimes.forEach(time => {
    time.bookedTimes.forEach(booked => {
      if (booked.participantId && booked.isPurchaseConfirmed) {
        newArray = [...newArray, booked];
      }
    });
  });

  return newArray.length + (clientPackages && clientPackages.length ? sumBy(clientPackages, 'freeSessionNumbers') : 0);
};

const AboutGeneralInfoBlock = ({ contribution = {}, categories = [], gender, minAge, languageCodes = [] }) => {
  const classes = useStyles();

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Card>
      <CardBody mobileView={mobileView}>
        <Grid container spacing={2} justify="space-between">
          <Grid item className={classes.gridItem}>
            <Icon path={mdiApps} right="4" />
            <LabelText mobileView={mobileView}>
              <span>{categories.join(', ')}</span>
            </LabelText>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Icon path={mdiTimerOutline} right="4" />
            <LabelText mobileView={mobileView}>{`${
              !isEmpty(contribution?.sessions) ? contribution.sessions.length : getSessionsCount(contribution)
            } Sessions`}</LabelText>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Icon path={mdiMessageProcessing} right="4" />
            <LabelText mobileView={mobileView}>{getLanguages(languageCodes).join(', ')}</LabelText>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Icon path={mdiAccountOutline} right="4" />
            <LabelText mobileView={mobileView}>{minAge}</LabelText>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Icon path={mdiGenderMale} right="4" />
            <LabelText mobileView={mobileView}>{genderDisplay[gender]}</LabelText>
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

AboutGeneralInfoBlock.propTypes = {
  contribution: PropTypes.shape({}),
  categories: PropTypes.arrayOf(PropTypes.string),
  gender: PropTypes.string.isRequired,
  minAge: PropTypes.number.isRequired,
  languageCodes: PropTypes.arrayOf(PropTypes.string),
};

AboutGeneralInfoBlock.defaultProps = {
  contribution: {},
  categories: [],
  languageCodes: [],
};

export default AboutGeneralInfoBlock;
