import styled from 'styled-components';
import { toRem } from 'utils/styles';

const PageTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Nexa;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.675rem;
  font-weight: 500;
  margin: 0px;
  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const PageTitleText = styled.h1`
  font-family: 'Nexa';
  color: rgba(0, 0, 0, 0.87);
  font-size: ${toRem(26.8)};
  font-weight: 500;
  display: inline-block;
  margin: 0px;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const PageTitleSecond = styled.h1`
  font-size: ${toRem(22.3)};
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: ${toRem(18)};
    margin: 0px;
  `}
`;

const SimpleText = styled.span`
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.15px;
`;

const PageSubtitle = styled.h3`
  font-size: ${toRem(16)};
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: #000000;
`;

const LabelText = styled.span`
  font-size: ${toRem(18)};
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.12px;
  color: #000000;

  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const BodyOrLeftText = styled.p`
  font-size: ${toRem(16)};
  line-height: 1.25;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;

const BodyText = styled.p`
  font-size: ${toRem(16)};
  line-height: 1.25;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;

  ${({ mobileView }) => mobileView && `margin-top: 10px`}
`;

export { PageTitle, PageTitleSecond, PageSubtitle, BodyOrLeftText, BodyText, LabelText, PageTitleText, SimpleText };
