import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Avatar from '@material-ui/core/Avatar'
import Linkify from 'react-linkify'
import { SecureLink } from 'react-secure-link'
import getInitials from 'utils/getInitials'
import useMessage from './useMessage'
import MessageType from '../../../MessageType'
import Media from './Media'

import './Message.scss'

const Message = ({ className, message, memberProvider, isUnread, onRead }) => {
  const {
    index,
    author: { avatarUrl, displayName },
    date,
    type,
    text,
    media,
  } = useMessage(message, memberProvider)

  const messageRef = useRef()
  useEffect(() => {
    const onIntersected = ([entry]) => {
      if (entry.isIntersecting) {
        onRead(index)
      }
    }
    const options = { threshold: 0.7 }

    const messageObserver = new IntersectionObserver(onIntersected, options)
    messageObserver.observe(messageRef.current)

    return () => {
      messageObserver.disconnect()
    }
  }, [isUnread, onRead, index])

  return (
    <div ref={messageRef} className={classNames(className, 'chat-message')}>
      <Avatar className="chat-message__author-avatar" src={avatarUrl}>
        {getInitials(displayName)}
      </Avatar>
      <p className="chat-message__info chat-message-info">
        <span className="chat-message-info__author">{displayName}</span>
        &nbsp;
        <span className="chat-message-info__time">{date.format('hh:mm A')}</span>
      </p>
      <div className="chat-message__content chat-message-content">
        {type === MessageType.text && (
          <p className="chat-message-content__text">
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <SecureLink target="_blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </SecureLink>
              )}
            >
              {text}
            </Linkify>
          </p>
        )}
        {type === MessageType.media && (
          <Media type={media.type} size={media.sizeInBytes} fileName={media.fileName} url={media.contentUrl} />
        )}
      </div>
    </div>
  )
}

Message.propTypes = {
  className: PropTypes.string.isRequired,
  message: PropTypes.shape({}).isRequired,
  memberProvider: PropTypes.func.isRequired,
  isUnread: PropTypes.bool.isRequired,
  onRead: PropTypes.func.isRequired,
}

export default Message
