import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '../../../../../utils/styles';
import { CircularProgress } from '@material-ui/core';

const StyledImage = styled.img`
  max-width: 100%;
  border-radius: 8px;
  max-height: 100%;
`;
const StyledVideo = styled.video`
  max-width: 100%;
  border-radius: 8px;
  max-height: 100%;
`;
const StyledWrap = styled.div`
  ${({ typeFile }) => {
    if (typeFile) {
      return `
    width: 100%;
    padding-right: 20px;`;
    }
  }}
  min-height: 40px;
  min-width: 40px;
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
`;
const StyledCloseIconWrap = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const StyledFileLink = styled.a`
  color: ${colors.darkOceanBlue};
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: -6px;
  padding: 6px 40px 6px 0;

  &:hover {
    color: ${colors.darkOceanBlue};
  }
`;
const StyledCircularProgress = styled(CircularProgress)`
  display: block;
`;
const StyledAttachmentsContainer = styled.div`
  padding: 0 15px;
`;

export const Attachments = ({ attachments, removeAttachment, pending }) => {
  const handleRemoveAttachment = index => {
    removeAttachment(index);
  };
  const Wrapper = ({ children, index, type }) => {
    return (
      <StyledWrap typeFile={type === 'file'}>
        {removeAttachment && (
          <StyledCloseIconWrap
            onClick={() => {
              handleRemoveAttachment(index);
            }}
          >
            <CloseIcon fontSize="small" />
          </StyledCloseIconWrap>
        )}
        {children}
      </StyledWrap>
    );
  };

  return (
    <>
      {pending && (
        <StyledAttachmentsContainer>
          <StyledCircularProgress />
        </StyledAttachmentsContainer>
      )}
      {!isEmpty(attachments) && (
        <StyledAttachmentsContainer>
          {map(attachments, (item, index) => {
            switch (item.type) {
              case 'image/png':
              case 'image/jpeg':
                return (
                  <Wrapper index={index}>
                    <StyledImage src={item.s3Link} alt={item.fileName} />
                  </Wrapper>
                );
              case 'video/mpeg':
              case 'video/mp4':
                return (
                  <Wrapper index={index}>
                    <StyledVideo controls="controls">
                      <source src={item.s3Link} />
                    </StyledVideo>
                  </Wrapper>
                );
              default:
                return (
                  <Wrapper index={index} type="file">
                    <StyledFileLink href={item.s3Link} download target="_blank">
                      <DescriptionOutlinedIcon style={{ fontSize: 40 }} />
                      {item.fileName}
                    </StyledFileLink>
                  </Wrapper>
                );
            }
          })}
        </StyledAttachmentsContainer>
      )}
    </>
  );
};
