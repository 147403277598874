export const getLanguages = languageCodes => {
  const codesNames = {
    En: 'English',
    Ar: 'Arabic',
    Zh: 'Chinese',
    Fr: 'French',
    De: 'German',
    Hi: 'Hindi',
    Id: 'Indonesian',
    It: 'Italian',
    Ja: 'Japanese',
    Ko: 'Korean',
    Pt: 'Portuguese',
    Ru: 'Russian',
    Es: 'Spanish',
    Sv: 'Swedish',
    Vi: 'Vietnamese',
  };

  return languageCodes.map(lng => codesNames[lng]);
};
