import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

import SortOrder from './SortOrder'
import DateFilter from './DateFilter'
import ChatsListHeader, { SelectedChatsState, SelectedChatsReadState } from './ChatsListHeader'
import ChatChannelsList from './ChatChannelsList'

const checkIfChannelRead = (channel) => {
  if (!channel.lastMessage) {
    return true
  }
  const { index } = channel.lastMessage

  return channel.lastConsumedMessageIndex !== null && channel.lastConsumedMessageIndex >= index
}

const ChatsList = ({ channels, ...restProps }) => {
  const [selectedChannels, setSelectedChannels] = useState([])
  const [showFavoriteOnly, setShowFavoriteOnly] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [sortOrder, setSortOrder] = useState(SortOrder.newestFirst)
  const [dateFilter, setDateFilter] = useState(DateFilter.all)

  const getSelectedChatsState = () => {
    if (selectedChannels.length === 0) {
      return SelectedChatsState.none
    }

    return channels.length === selectedChannels.length ? SelectedChatsState.all : SelectedChatsState.some
  }
  const onSelectedChatsStateChange = (selectedState) => {
    switch (selectedState) {
      case SelectedChatsState.all:
        setSelectedChannels(channels)
        break
      case SelectedChatsState.some:
        throw new Error(`${selectedState} is not supported`)
      case SelectedChatsState.none:
        setSelectedChannels([])
        break
      default:
        throw new Error(`Unknown selected chats state: ${selectedState}.`)
    }
  }

  const getSelectedChatsReadState = () => {
    if (selectedChannels.length === 0) {
      return SelectedChatsReadState.none
    }

    return selectedChannels.every(checkIfChannelRead)
      ? SelectedChatsReadState.allRead
      : SelectedChatsReadState.someUnread
  }
  const onReadSelectedMessages = () => selectedChannels.forEach((c) => c.setAllMessagesConsumed())
  const onUnreadSelectedMessages = () => selectedChannels.forEach((c) => c.setNoMessagesConsumed())

  return (
    <ExpansionPanel expanded className="chats-list">
      <ExpansionPanelSummary className="chats-list__header">
        <ChatsListHeader
          selectedState={getSelectedChatsState()}
          onSelectedStateChange={onSelectedChatsStateChange}
          selectedChatsReadState={getSelectedChatsReadState()}
          onReadSelectedChats={onReadSelectedMessages}
          onUnreadSelectedChats={onUnreadSelectedMessages}
          showFavoriteOnly={showFavoriteOnly}
          onShowFavoriteOnlyChange={setShowFavoriteOnly}
          searchFilter={searchFilter}
          onSearchFilterChange={setSearchFilter}
          sortOrder={sortOrder}
          onSortOrderChange={setSortOrder}
          dateFilter={dateFilter}
          onDateFilterChange={setDateFilter}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="chats-list__content chats-list-content">
        <ChatChannelsList
          {...restProps}
          className="chats-list-content__channels-list"
          channels={channels}
          showFavoriteOnly={showFavoriteOnly}
          searchFilter={searchFilter}
          sortOrder={sortOrder}
          dateFilter={dateFilter}
          selectedChannels={selectedChannels}
          onSelectedChannelsChange={setSelectedChannels}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

ChatsList.propTypes = {
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      sid: PropTypes.string,
    })
  ).isRequired,
}

export default ChatsList
