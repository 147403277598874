import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { checkToken } from 'utils/request';
import { checkRoles } from 'utils/routing';
import { useRouter } from 'hooks/index';

const ProtectedRoute = ({
  component: Component,
  authorized,
  onboardingStatus,
  children,
  roles: permittedRoles,
  userRoles,
  account,

  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={p => {
        if (!authorized) {
          return (
            <Redirect
              to={{
                pathname: '/auth/signin',
                state: { from: p.location },
              }}
            />
          );
        }

        if (onboardingStatus === 'Registered') {
          return rest.path === '/auth/signup/continue' || rest.path === '/auth/signup/client/continue' ? (
            <Component {...p} />
          ) : (
            <Redirect
              to={{
                pathname: '/auth/signup/client/continue',
                state: { from: p.location },
              }}
            />
          );
        }

        if (Boolean(permittedRoles.length) && !checkRoles(userRoles, permittedRoles)) {
          return <Redirect to="/dashboard" />;
        }

        if (onboardingStatus === 'InfoAdded') {
          return rest.path === '/auth/signup/continue' || rest.path === '/auth/signup/client/continue' ? (
            <Redirect to="/dashboard" />
          ) : (
            <Component {...p} />
          );
        }
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.node, PropTypes.elementType]),
  authorized: PropTypes.bool.isRequired,
  onboardingStatus: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ProtectedRoute.defaultProps = {
  children: null,
  component: null,
  roles: [],
};

const mapStateToProps = ({ account }) => ({
  authorized: checkToken(account?.token),
  onboardingStatus: (account.user && account.user.onboardingStatus) || '',
  userRoles: account?.roles,
  account: account,
});

export default connect(mapStateToProps)(ProtectedRoute);
