import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { useEndVideoChat } from 'hooks';
import { fetchCohealerContribution } from 'actions/contributions';
import Button from 'components/FormUI/Button';
import LaunchSessionTime from 'components/SessionButtons/LaunchSessionTime';

import LaunchRecordedSessionVideo from 'components/SessionButtons/LaunchRecordedSessionVideo';
import CourseSessions from '../CourseSessions';

const CohealerCourseSessions = ({ user, contribution, getCohealerContribution }) => {
  const handleRefreshList = useCallback(async () => {
    getCohealerContribution(contribution.id);
  }, [contribution.id, getCohealerContribution]);
  const { Popup, onShowPopup } = useEndVideoChat(handleRefreshList);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <CourseSessions
        contribution={contribution}
        renderSessionTimeActions={(session, sessionTime) => {
          const isHasRecordings = sessionTime.recordingInfos.length > 0;
          return (
            <>
              {!sessionTime.isCompleted && (
                <Button autoWidth onClick={() => onShowPopup(contribution.id, sessionTime.id)}>
                  Complete
                </Button>
              )}
              {!session?.isPrerecorded && (
                <LaunchSessionTime
                  user={user}
                  contribution={contribution}
                  session={session}
                  sessionTime={sessionTime}
                />
              )}
              {isHasRecordings && (
                <LaunchRecordedSessionVideo
                  contribution={contribution}
                  session={session}
                  sessionTime={sessionTime}
                  mobileView={mobileView}
                />
              )}
            </>
          );
        }}
      />
      <Popup />
    </>
  );
};

CohealerCourseSessions.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
};

const actions = {
  getCohealerContribution: fetchCohealerContribution,
};

export default connect(null, actions)(CohealerCourseSessions);
