import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'formik';
import { DateTimePicker as MaterialDateTimePicker, TimePicker, DatePicker } from '@material-ui/pickers';

import { colors } from 'utils/styles';

const useStyles = makeStyles(() => ({
  labelRoot: {
    fontFamily: 'Avenir',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    fontSize: `${14 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
    transform: `translate(0, ${33 / 16}rem) scale(1)`,
  },
  labelShrink: {
    transform: 'translate(0, 4px)',
  },
  labelFocused: {
    color: 'inherit !important',
  },
  labelFormControl: {
    transform: `translate(0, ${32 / 16}rem) scale(1)`,
  },
  labelError: {
    color: 'inherit !important',
  },
  inputRoot: {
    fontFamily: 'Avenir',
    lineHeight: `${24 / 16}rem`,
    letterSpacing: '0.15px',
    fontSize: `${18 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
  },
  inputFormControl: {
    'label + &': {
      marginTop: 26,
    },
  },
  inputUnderline: {
    '&:after': {
      borderBottom: `2px solid #6486a2`,
    },
    '&:before': {
      borderColor: '#e7e7e7',
    },
  },
  inputError: {
    '&:after': {
      borderBottomColor: `${colors.fadedPurple} !important`,
    },
  },
  helperTextRoot: {
    textAlign: 'right',
    fontFamily: 'Avenir',
    lineHeight: `${24 / 16}rem`,
    letterSpacing: '0.08px',
    fontSize: `${12 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 'normal',
  },
  helperTextError: {
    color: `${colors.fadedPurple} !important`,
    textAlign: 'left',
    fontSize: '1rem',
    letterSpacing: '0.1px',
  },
}));

const COMPONENTS = {
  date: DatePicker,
  time: TimePicker,
  datetime: MaterialDateTimePicker,
};

function DateTimePicker(props) {
  const { helperText, name, type, step, ...rest } = props;
  const classes = useStyles();
  const [field, meta, helpers] = useField(props);
  const { defaultHour, defaultMinute, defaultSecond, defaultMillisecond } = props;
  const Component = COMPONENTS[type];

  return (
    <Component
      {...field}
      error={Boolean(meta.touched && meta.error)}
      helperText={(meta.touched && meta.error) || helperText}
      minutesStep={step}
      onError={err => {
        if (err !== meta.error && err !== '') {
          helpers.setError(err);
        }
      }}
      onChange={date => {
        setDefaultTimePart(date);
        helpers.setValue(date);
      }}
      InputLabelProps={{
        classes: {
          root: classes.labelRoot,
          focused: classes.labelFocused,
          shrink: classes.labelShrink,
          error: classes.labelError,
        },
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          formControl: classes.inputFormControl,
          underline: classes.inputUnderline,
          error: classes.inputError,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperTextRoot,
          error: classes.helperTextError,
        },
      }}
      {...rest}
    />
  );

  function setDefaultTimePart(date) {
    if (defaultHour) {
      date.set('hour', defaultHour);
    }
    if (defaultMinute) {
      date.set('minute', defaultMinute);
    }
    if (defaultSecond) {
      date.set('second', defaultSecond);
    }
    if (defaultMillisecond) {
      date.set('millisecond', defaultMillisecond);
    }
  }
}

DateTimePicker.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['date', 'time', 'datetime']),
  step: PropTypes.number,
  disablePast: PropTypes.bool,
};

DateTimePicker.defaultProps = {
  fullWidth: false,
  label: null,
  helperText: null,
  type: 'datetime',
  step: null,
  disablePast: true,
};

export default DateTimePicker;
