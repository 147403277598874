import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Backdrop, Fade, makeStyles, Grid } from '@material-ui/core';
import { OnePlanViewContainer, useStyles, PlansTitle } from 'pages/Account/CohealerBilling/Billing.styles';
import { hidePaidTierOptionBanner } from '../../services/paidTierOptions.service';
import { updateUser } from '../../actions/update-user';
import { userSelector } from '../../selectors/user';

import { colors } from '../../utils/styles';
import Dictionary from '../../pages/Account/CohealerBilling/dictionary.json';

import { PrimaryP, SecondaryP } from '../../pages/Account/CohealerBilling/Billing.styles';

import ConditionRenderBillingPage from 'pages/Account/CohealerBilling/ConditionalRenderBillingPage';

const useModalStyles = makeStyles(theme => ({
  modal: {
    display: 'grid',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  background: {
    height: '92vh',
    width: '85%',
    background: colors.white,
    padding: '30px 75px 0px 75px',
    position: 'relative',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    outline: 'none',
    [theme.breakpoints.down(600)]: {
      padding: '30px 20px 0px 20px',
    },
    [theme.breakpoints.down(1150)]: {
      height: '80vh',
      padding: '30px 30px 0px 35px',
      width: '95vw',
    },
    [theme.breakpoints.down(750)]: {
      height: '80vh',
      width: '95vw',
    },
  },
  descriptionBlock: {
    textAlign: 'center',
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 15,
    },
  },
  descriptionTitleMedia: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      textAlign: 'center',
    },
  },
  descriptionAboutMedia: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      textAlign: 'center',
    },
  },
  closeButton: {
    position: 'absolute',
    top: 15,
    right: 15,
    border: 'none',
    background: 'rgba(0,0,0,0)',
    fontSize: 16,
    fontWeight: 'bold',
  },
}));

const SelectingPlanModal = () => {
  const classes = useModalStyles();

  const { membershipOffers, about } = Dictionary.description;

  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const [isOpen, setIsOpen] = useState(true);

  const labelSize = 12;

  const handleClose = () => {
    hidePaidTierOptionBanner();

    const userWithHiddenPaidTierOptionBanner = { ...user, paidTierOptionBannerHidden: true };
    dispatch(updateUser(userWithHiddenPaidTierOptionBanner));
    setIsOpen(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.background}>
          <button type="button" className={classes.closeButton} onClick={handleClose}>
            X
          </button>

          <Grid item xs={labelSize} className={classes.descriptionBlock}>
            <PrimaryP className={classes.descriptionTitleMedia}>{membershipOffers}</PrimaryP>
            <SecondaryP className={classes.descriptionAboutMedia}>{about}</SecondaryP>
          </Grid>
          <ConditionRenderBillingPage />
        </div>
      </Fade>
    </Modal>
  );
};

export default SelectingPlanModal;
