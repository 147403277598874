import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTheme, useMediaQuery } from '@material-ui/core';

import TextArea from 'components/FormUI/TextArea';

import './NoteModalView.scss';

const NoteModalView = ({ note, rows, onSubmit }) => {
  const handleSubmit = useCallback(onSubmit, [onSubmit]);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={`note-view${mobileView ? '-mobile' : ''}`}>
      <Formik
        enableReinitialize
        initialValues={{
          note: note?.textContent || '',
        }}
        validationSchema={Yup.object().shape({
          note: Yup.string().required('This is a required field'),
        })}
        onSubmit={handleSubmit}
      >
        <Form id="note-form">
          <h5 className="note-view__title">Note</h5>
          <TextArea
            style={mobileView ? { height: '350px' } : {}}
            placeholder="Please input text"
            name="note"
            rows={rows}
            fullWidth
          />
        </Form>
      </Formik>
    </div>
  );
};

NoteModalView.defaultProps = {
  rows: 8,
};

NoteModalView.propTypes = {
  note: PropTypes.shape({}).isRequired,
  rows: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};

export default NoteModalView;
