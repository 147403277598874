import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';
import moment from 'moment';

import * as contributionService from 'services/contributions.service';
import { ContributionType } from 'helpers/constants';
import { getCurrentSessionTime } from 'utils/getSessionTime';
import { RESPONSE_CODES, PAYMENT_OPTIONS } from '../constants';
import { redirectTo } from 'services/links';

const CONTRIBUTION_STATUSES = {
  DRAFT: 'Draft',
};

export const CREATE_CONTRIBUTION_REQUEST = 'CREATE_CONTRIBUTION_REQUEST';
export const CREATE_CONTRIBUTION_SUCCESS = 'CREATE_CONTRIBUTION_SUCCESS';
export const CREATE_CONTRIBUTION_ERROR = 'CREATE_CONTRIBUTION_ERROR';

export const FETCH_CONTRIBUTION_REQUEST = 'FETCH_CONTRIBUTION_REQUEST';
export const FETCH_CONTRIBUTION_SUCCESS = 'FETCH_CONTRIBUTION_SUCCESS';
export const FETCH_CONTRIBUTION_ERROR = 'FETCH_CONTRIBUTION_ERROR';

export const UPDATE_CONTRIBUTION_REQUEST = 'UPDATE_CONTRIBUTION_REQUEST';
export const UPDATE_CONTRIBUTION_SUCCESS = 'UPDATE_CONTRIBUTION_SUCCESS';
export const UPDATE_CONTRIBUTION_ERROR = 'UPDATE_CONTRIBUTION_ERROR';

export const FETCH_CONTRIBUTIONS_REQUEST = 'FETCH_CONTRIBUTIONS_REQUEST';
export const FETCH_CONTRIBUTIONS_SUCCESS = 'FETCH_CONTRIBUTIONS_SUCCESS';
export const FETCH_CONTRIBUTIONS_ERROR = 'FETCH_CONTRIBUTIONS_ERROR';

export const FETCH_UPCOMING_CREATED_REQUEST = 'FETCH_UPCOMING_CREATED_REQUEST';
export const FETCH_UPCOMING_CREATED_SUCCESS = 'FETCH_UPCOMING_CREATED_SUCCESS';
export const FETCH_UPCOMING_CREATED_ERROR = 'FETCH_UPCOMING_CREATED_ERROR';

export const FETCH_UPCOMING_CREATED_WITH_TYPE_REQUEST = 'FETCH_UPCOMING_CREATED_WITH_TYPE_REQUEST';
export const FETCH_UPCOMING_CREATED_WITH_TYPE_SUCCESS = 'FETCH_UPCOMING_CREATED_WITH_TYPE_SUCCESS';
export const FETCH_UPCOMING_CREATED_WITH_TYPE_ERROR = 'FETCH_UPCOMING_CREATED_WITH_TYPE_ERROR';

export const FETCH_ARCHIVED_REQUEST = 'FETCH_ARCHIVED_REQUEST';
export const FETCH_ARCHIVED_SUCCESS = 'FETCH_ARCHIVED_SUCCESS';
export const FETCH_ARCHIVED_ERROR = 'FETCH_ARCHIVED_ERROR';

export const RESCHEDULE_SESSION_REQUEST = 'RESCHEDULE_SESSION_REQUEST';
export const RESCHEDULE_SESSION_SUCCESS = 'RESCHEDULE_SESSION_SUCCESS';
export const RESCHEDULE_SESSION_ERROR = 'RESCHEDULE_SESSION_ERROR';

export const GET_CONTRIBUTION_FROM_LS = 'GET_CONTRIBUTION_FROM_LS';
export const SAVE_CONTRIBUTION_TO_LS = 'SAVE_CONTRIBUTION_TO_LS';
export const RESET_CONTRIBUTION = 'RESET_CONTRIBUTION';
export const SAVE_CONTRIBUTION = 'SAVE_CONTRIBUTION';

export const FETCH_CONTRIBUTION_TIMES_REQUEST = 'FETCH_CONTRIBUTION_TIMES_REQUEST';
export const FETCH_CONTRIBUTION_TIMES_SUCCESS = 'FETCH_CONTRIBUTION_TIMES_SUCCESS';
export const FETCH_CONTRIBUTION_TIMES_ERROR = 'FETCH_CONTRIBUTION_TIMES_ERROR';

export const CALCULATE_CREATE_SLOTS_REQUEST = 'CALCULATE_CREATE_SLOTS_REQUEST';
export const CALCULATE_CREATE_SLOTS_SUCCESS = 'CALCULATE_CREATE_SLOTS_SUCCESS';
export const CALCULATE_CREATE_SLOTS_ERROR = 'CALCULATE_CREATE_SLOTS_ERROR';

export const FETCH_EDIT_CONTRIBUTION_TIMES_REQUEST = 'FETCH_EDIT_CONTRIBUTION_TIMES_REQUEST';
export const FETCH_EDIT_CONTRIBUTION_TIMES_SUCCESS = 'FETCH_EDIT_CONTRIBUTION_TIMES_SUCCESS';
export const FETCH_EDIT_CONTRIBUTION_TIMES_ERROR = 'FETCH_EDIT_CONTRIBUTION_TIMES_ERROR';

export const FETCH_CONTRIBUTIONS_ADMIN_REQUEST = 'FETCH_CONTRIBUTIONS_ADMIN_REQUEST';
export const FETCH_CONTRIBUTIONS_ADMIN_SUCCESS = 'FETCH_CONTRIBUTIONS_ADMIN_SUCCESS';
export const FETCH_CONTRIBUTIONS_ADMIN_ERROR = 'FETCH_CONTRIBUTIONS_ADMIN_ERROR';

export const SET_INVITE_CODE = 'SET_INVITE_CODE';

export const getContributionFromLS = createAction(GET_CONTRIBUTION_FROM_LS);
export const saveContributionToLS = createAction(SAVE_CONTRIBUTION_TO_LS);
export const resetContribution = createAction(RESET_CONTRIBUTION);
export const saveContribution = createAction(SAVE_CONTRIBUTION);
export const updateSessionAttachments = createAction('UPDATE_SESSION_ATTACHMENTS');
export const addSessionAttachments = createAction('ADD_SESSION_ATTACHMENTS');
export const updateOneToOneSessionAttachments = createAction('UPDATE_ONE_TO_ONE_SESSION_ATTACHMENTS');
export const updateSubscriptionInfo = createAction('UPDATE_SUBSCRIPTION_INFO');

export const createContributionActions = {
  request: createAction(CREATE_CONTRIBUTION_REQUEST),
  success: createAction(CREATE_CONTRIBUTION_SUCCESS),
  error: createAction(CREATE_CONTRIBUTION_ERROR),
};

export const fetchContributionActions = {
  request: createAction(FETCH_CONTRIBUTION_REQUEST),
  success: createAction(FETCH_CONTRIBUTION_SUCCESS),
  error: createAction(FETCH_CONTRIBUTION_ERROR),
};

export const fetchContributionTimesActions = {
  request: createAction(FETCH_CONTRIBUTION_TIMES_REQUEST),
  success: createAction(FETCH_CONTRIBUTION_TIMES_SUCCESS),
  error: createAction(FETCH_CONTRIBUTION_TIMES_ERROR),
};
export const calculateCreateSlotsActions = {
  request: createAction(CALCULATE_CREATE_SLOTS_REQUEST),
  success: createAction(CALCULATE_CREATE_SLOTS_SUCCESS),
  error: createAction(CALCULATE_CREATE_SLOTS_ERROR),
};

export const fetchEditContributionTimesActions = {
  request: createAction(FETCH_EDIT_CONTRIBUTION_TIMES_REQUEST),
  success: createAction(FETCH_EDIT_CONTRIBUTION_TIMES_SUCCESS),
  error: createAction(FETCH_EDIT_CONTRIBUTION_TIMES_ERROR),
};

export const updateContributionActions = {
  request: createAction(UPDATE_CONTRIBUTION_REQUEST),
  success: createAction(UPDATE_CONTRIBUTION_SUCCESS),
  error: createAction(UPDATE_CONTRIBUTION_ERROR),
};

export const fetchContributionsActions = {
  request: createAction(FETCH_CONTRIBUTIONS_REQUEST),
  success: createAction(FETCH_CONTRIBUTIONS_SUCCESS),
  error: createAction(FETCH_CONTRIBUTIONS_ERROR),
};

export const fetchUpcomingCreatedActions = {
  request: createAction(FETCH_UPCOMING_CREATED_REQUEST),
  success: createAction(FETCH_UPCOMING_CREATED_SUCCESS),
  error: createAction(FETCH_UPCOMING_CREATED_ERROR),
};

export const fetchUpcomingCreatedWithTypeActions = {
  request: createAction(FETCH_UPCOMING_CREATED_WITH_TYPE_REQUEST),
  success: createAction(FETCH_UPCOMING_CREATED_WITH_TYPE_SUCCESS),
  error: createAction(FETCH_UPCOMING_CREATED_WITH_TYPE_ERROR),
};

export const fetchArchivedActions = {
  request: createAction(FETCH_ARCHIVED_REQUEST),
  success: createAction(FETCH_ARCHIVED_SUCCESS),
  error: createAction(FETCH_ARCHIVED_ERROR),
};

export const fetchContributionAdminActions = {
  request: createAction(FETCH_CONTRIBUTIONS_ADMIN_REQUEST),
  success: createAction(FETCH_CONTRIBUTIONS_ADMIN_SUCCESS),
  error: createAction(FETCH_CONTRIBUTIONS_ADMIN_ERROR),
};

export const rescheduleSessionActions = {
  request: createAction(RESCHEDULE_SESSION_REQUEST),
  success: createAction(RESCHEDULE_SESSION_SUCCESS),
  error: createAction(RESCHEDULE_SESSION_ERROR),
};

export const fetchContributions = id => async dispatch => {
  dispatch(fetchContributionsActions.request());

  try {
    const response = await contributionService.getContributions(id);
    dispatch(fetchContributionsActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchContributionsActions.error, dispatch)(error);
  }
};

export const fetchContributionTimes = (id, isCoach) => async dispatch => {
  dispatch(fetchContributionTimesActions.request());
  const { getCoachContributionTimes, getClientContributionTimes } = contributionService;
  const request = isCoach ? getCoachContributionTimes : getClientContributionTimes;

  try {
    const response = await request(id);
    dispatch(fetchContributionTimesActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchContributionTimesActions.error, dispatch)(error);
  }
};

export const fetchEditContributionTimes = (id, oneToOneSessionDataUi) => async dispatch => {
  dispatch(fetchEditContributionTimesActions.request());
  const { getCoachEditContributionTimes } = contributionService;

  try {
    const response = await getCoachEditContributionTimes(id, oneToOneSessionDataUi);
    dispatch(fetchEditContributionTimesActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchEditContributionTimesActions.error, dispatch)(error);
  }
};

export const calculateCreateSlots = oneToOneSessionDataUi => async dispatch => {
  dispatch(calculateCreateSlotsActions.request());

  try {
    const response = await contributionService.calculateSlots(oneToOneSessionDataUi);
    dispatch(calculateCreateSlotsActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), calculateCreateSlotsActions.error, dispatch)(error);
  }
};
export const fetchUpcomingCreated = id => async dispatch => {
  dispatch(fetchUpcomingCreatedActions.request());

  try {
    const response = await contributionService.getUpcomingCreated(id);
    dispatch(fetchUpcomingCreatedActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchUpcomingCreatedActions.error, dispatch)(error);
  }
};

export const fetchUpcomingCreatedWithType = (id, type) => async dispatch => {
  dispatch(fetchUpcomingCreatedWithTypeActions.request());

  try {
    const response = await contributionService.getUpcomingCreatedWithType(id, type);
    dispatch(fetchUpcomingCreatedWithTypeActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchUpcomingCreatedWithTypeActions.error, dispatch)(error);
  }
};

export const fetchArchived = (id, type) => async dispatch => {
  dispatch(fetchArchivedActions.request());

  try {
    const response = await contributionService.getArchived(id, type);
    dispatch(fetchArchivedActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchArchivedActions.error, dispatch)(error);
  }
};

export const fetchContribution = id => dispatch => {
  dispatch(fetchContributionActions.request());

  return contributionService
    .getContribution(id)
    .then(fetchContributionActions.success)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), fetchContributionActions.error, dispatch));
};

export const fetchCohealerContribution = id => dispatch => {
  dispatch(fetchContributionActions.request());

  return contributionService
    .getCohealerContribution(id)
    .then(fetchContributionActions.success)
    .then(dispatch)
    .catch(err => {
      // eslint-disable-next-line no-undef
      if (err?.response?.status === RESPONSE_CODES.CLIENT.NOT_ALLOWED) {
        return Promise.reject(err);
      }
      dispatch(fetchContributionActions.error(err?.response?.data));
      redirectTo('/');
    });
};

export const fetchClientContribution = id => dispatch => {
  dispatch(fetchContributionActions.request());

  return contributionService
    .getClientContribution(id)
    .then(fetchContributionActions.success)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), fetchContributionActions.error, dispatch));
};

export const getLastDraftContribution = () => dispatch => {
  return contributionService.getLastDraftContribution().then(getContributionFromLS).then(dispatch);
};

export const saveDraftContribution = data => dispatch => {
  const { status, timeZoneId, ...body } = data;
  const sessions = [
    {
      maxParticipantsNumber: 20,
      minParticipantsNumber: 2,
      sessionTimes: [getCurrentSessionTime(false, moment().add(1, 'd'))],
      title: 'Session 1',
      isPrerecorded: false,
    },
  ];

  if (data.id) {
    if (body.type === ContributionType.contributionMembership) {
      const isNeedChangePaymentOptions =
        !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) &&
        !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) &&
        !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) &&
        !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) &&
        !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE);
      if (!body?.sessions?.length) {
        body.sessions = sessions;
      }
      if (isNeedChangePaymentOptions) {
        body.paymentInfo.paymentOptions = [PAYMENT_OPTIONS.PER_MONTH];
      }
    }
    if (body.type === ContributionType.contributionCourse) {
      const isNeedChangePaymentOptions =
        !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) &&
        !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS);
      if (isNeedChangePaymentOptions) {
        body.paymentInfo.paymentOptions = [PAYMENT_OPTIONS.ENTIRE_COURSE];
      }
      if (!body?.sessions?.length) {
        body.sessions = sessions;
      }
    }
    if (body.type === ContributionType.contributionOneToOne) {
      const isNeedChangePaymentOptions =
        !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) &&
        !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.SESSIONS_PACKAGE) &&
        !body.paymentInfo.paymentOptions.includes(PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION);
      if (isNeedChangePaymentOptions) {
        body.paymentInfo.paymentOptions = [PAYMENT_OPTIONS.PER_SESSION];
      }
      if (!body?.durations?.length) {
        body.durations = [60];
      }
      body.sessions = [];
    }

    return contributionService.updateDraftContribution(null, body).then(saveContributionToLS).then(dispatch);
  } else {
    body.status = status;
    body.timeZoneId = timeZoneId;
    body.paymentInfo = { paymentOptions: [] };
    body.languageCodes = ['En'];
    body.invitationOnly = true;
    body.arePublicPostsAllowed = true;
    body.areClientPublicPostsAllowed = true;
    if (body.type === ContributionType.contributionOneToOne) {
      body.durations = [60];
      body.paymentInfo.paymentOptions.push('PerSession');
      body.paymentInfo.packageSessionNumbers = 5;
    } else if (body.type === ContributionType.contributionCourse) {
      body.paymentInfo.paymentOptions.push(PAYMENT_OPTIONS.ENTIRE_COURSE);
    } else if (body.type === ContributionType.contributionMembership) {
      body.paymentInfo.paymentOptions.push(PAYMENT_OPTIONS.PER_MONTH);
    }
    body.sessions = sessions;

    return contributionService.createDraftContribution(body).then(saveContributionToLS).then(dispatch);
  }
};

export const updateContribution = (id, data) => dispatch => {
  dispatch(updateContributionActions.request());

  let request =
    data.status === CONTRIBUTION_STATUSES.DRAFT
      ? contributionService.updateDraftContribution
      : contributionService.updateContribution;

  if (data.hasAgreedContributionTerms && data.status === CONTRIBUTION_STATUSES.DRAFT) {
    delete data.status;

    return contributionService
      .submitDraftContribution(data)
      .then(updateContributionActions.success)
      .then(dispatch)
      .catch(pipe(get('response'), get('data'), updateContributionActions.error, dispatch));
  }
  delete data.status;

  return request(id, data)
    .then(updateContributionActions.success)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), updateContributionActions.error, dispatch));
};

export const createContribution = data => dispatch => {
  dispatch(createContributionActions.request());
  const request =
    data.status === CONTRIBUTION_STATUSES.DRAFT
      ? contributionService.submitDraftContribution
      : contributionService.createContribution;

  delete data.status;

  return request(data)
    .then(createContributionActions.success)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), createContributionActions.error, dispatch));
};

export const fetchContributionAdmin = () => async dispatch => {
  dispatch(fetchContributionAdminActions.request());

  try {
    const response = await contributionService.getContributionsAdmin(); //
    dispatch(fetchContributionAdminActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchContributionActions.error, dispatch)(error);
  }
};

export const rescheduleSession = data => dispatch => {
  dispatch(rescheduleSessionActions.request());

  return contributionService
    .rescheduleSession(data)
    .then(rescheduleSessionActions.success)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), rescheduleSessionActions.error, dispatch));
};

export const rescheduleSessionForClient = data => dispatch => {
  dispatch(rescheduleSessionActions.request());

  return contributionService
    .rescheduleSessionForClient(data)
    .then(rescheduleSessionActions.success)
    .then(dispatch)
    .catch(pipe(get('response'), get('data'), rescheduleSessionActions.error, dispatch));
};

export const setInviteCode = code => ({
  type: SET_INVITE_CODE,
  payload: code,
});
