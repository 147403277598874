import React from 'react'
import PropTypes from 'prop-types'

import { Chat } from 'components/UI/chats'

const ClientOneToOneDiscussion = ({
  contribution: {
    chat: { sid },
  },
}) => <Chat channelId={sid} />

ClientOneToOneDiscussion.propTypes = {
  contribution: PropTypes.shape({
    chat: PropTypes.shape({
      sid: PropTypes.string,
    }),
  }).isRequired,
}

export default ClientOneToOneDiscussion
