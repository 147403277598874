import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

import * as userActions from 'actions/user'

function LogOut() {
  const dispatch = useDispatch()
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    dispatch(userActions.logOut())
    setRedirect(true)
  }, [dispatch])

  return redirect ? <Redirect to="/auth/signin" /> : null
}

export default LogOut
