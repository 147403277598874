import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { Avatar, useMediaQuery, useTheme } from '@material-ui/core';

import PlaceholderImage from '../../../../assets/placeholder.jpg';
import { colors } from '../../../../utils/styles';
import * as userService from 'services/user.service';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DATE_FORMATS } from '../../../../utils/datesAndMoney.js';
import { MEMBERSHIP_STATUSES, PAYMENT_OPTIONS } from '../../../../constants.js';
import { activeCohereAcademyContributionsSelector } from 'selectors/paidTier';
import Button from '../../../../components/FormUI/Button';
import { UserRoles, ContributionType } from 'helpers/constants';
import { useAccount } from '../../../../hooks';
import PurchaseMembershipModal from '../../../ContributionView/components/PurchaseMembershipModal';

const StyledImage = styled.img`
  width: 125px;
  height: 70px;
  border-radius: 4px;
`;

const StyledBlock = styled(Grid)`
  border: 1px solid ${colors.darkWhite};
  border-radius: 4px;
  background: ${colors.white};
  padding: 15px;
  margin: 5px 0;
`;

const StyledTitle = styled.div`
  font-weight: bold;
`;

const StyledRenewText = styled.div`
  font-size: 12px;
  color: ${colors.smokedGray};
`;
const StyledButton = styled(Button)`
  margin-top: 8px;
`;
const StyledContent = styled(Grid)`
  text-align: end;
`;

const CancelButton = styled.div`
  font-size: 12px;
  color: ${colors.gray};
  margin-top: 5px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const MembershipItem = ({ membership, onCancelSubscription }) => {
  const {
    previewContentUrls,
    title,
    userId,
    serviceProviderName,
    subscriptionStatus,
    id,
    paymentInfo: { paymentOptions },
  } = membership;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [isStatusUpgrade, setIsStatusUpgrade] = useState(false);
  const [purchaseMembershipModalOpen, setPurchaseMembershipModalOpen] = useState(false);

  const { user, currentRole } = useAccount();

  const [cohealerIcon, setCohealerIcon] = useState(null);
  const isCanCancelMembership =
    subscriptionStatus?.status !== MEMBERSHIP_STATUSES.UPGRADE &&
    subscriptionStatus?.status !== MEMBERSHIP_STATUSES.CANCEL;
  const isMembership = membership.type === ContributionType.contributionMembership;
  const isCoach = currentRole === UserRoles.cohealer;
  const isCanUpgradeMembership =
    !isStatusUpgrade &&
    isMembership &&
    !isCoach &&
    subscriptionStatus?.paymentOption !== PAYMENT_OPTIONS.TRIAL &&
    membership?.subscriptionStatus &&
    membership?.subscriptionStatus?.status !== MEMBERSHIP_STATUSES.UPGRADE &&
    membership?.subscriptionStatus?.status === MEMBERSHIP_STATUSES.ACTIVE &&
    paymentOptions.length !== 1;

  const academyMemberships = useSelector(activeCohereAcademyContributionsSelector);
  const isAcademyMembership = academyMemberships.find(el => el.id === id);

  useEffect(() => {
    userService
      .getCohealerIcon(id)
      .then(icon => setCohealerIcon(icon))
      .catch(console.error);
  }, []);

  const formatRenew = () => {
    const daysDiff = moment(subscriptionStatus?.endPeriod).diff(moment(), 'day');
    const status = subscriptionStatus?.status === MEMBERSHIP_STATUSES.CANCEL ? 'Ends' : 'Renews';
    return `${status} in ${daysDiff} day${daysDiff > 1 ? 's' : ''} ${moment(subscriptionStatus?.endPeriod).format(
      DATE_FORMATS.DAY,
    )}`;
  };
  const serviceProviderIconMock = serviceProviderName.split(' ').map(name => name[0]);

  const handleUpgradeSubscription = useCallback(() => {
    setIsUpgrade(true);
    setPurchaseMembershipModalOpen(true);
  }, [isUpgrade, purchaseMembershipModalOpen, setIsUpgrade, setPurchaseMembershipModalOpen]);

  const handleUpgradeMembershipClick = useCallback(() => {
    setIsStatusUpgrade(true);
    setPurchaseMembershipModalOpen(false);
  }, [purchaseMembershipModalOpen]);
  return (
    <StyledBlock xs={12} direction="row" container justify="space-between">
      <Grid direction="row" container xs={6}>
        <StyledImage src={previewContentUrls[0] || PlaceholderImage} alt={title} />
        <Grid className="ml-2">
          <Link to={`/contribution-view/${id}/about`}>
            <StyledTitle>{title}</StyledTitle>
          </Link>
          <Grid direction="row" container className="mt-1">
            <Avatar className="mx-2" alt={serviceProviderName} src={cohealerIcon}>
              {serviceProviderIconMock}
            </Avatar>
            <Link to={`/cohealer-about/${userId}/about`}>
              <StyledTitle>{serviceProviderName}</StyledTitle>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <StyledContent>
        {subscriptionStatus?.paymentOption !== PAYMENT_OPTIONS.TRIAL && !isAcademyMembership && (
          <StyledRenewText>{formatRenew()}</StyledRenewText>
        )}

        {!isStatusUpgrade && isCanCancelMembership && !isAcademyMembership && (
          <CancelButton onClick={() => onCancelSubscription(id)}>Cancel subscription</CancelButton>
        )}
        {isCanUpgradeMembership && subscriptionStatus.paymentOption !== PAYMENT_OPTIONS.PER_YEAR && (
          <StyledButton mobileView={mobileView} onClick={handleUpgradeSubscription}>
            Upgrade Subscription
          </StyledButton>
        )}
      </StyledContent>
      {membership && purchaseMembershipModalOpen && (
        <PurchaseMembershipModal
          isOpen={purchaseMembershipModalOpen}
          onClose={() => setPurchaseMembershipModalOpen(false)}
          isUpgrade={isUpgrade}
          handleUrlModal={true}
          currentSubscription={membership?.subscriptionStatus?.paymentOption}
          contribution={membership}
          onUpgrade={handleUpgradeMembershipClick}
        />
      )}
    </StyledBlock>
  );
};

export default MembershipItem;
