import React, { useCallback, useState, useRef } from 'react';
import { useHttp } from 'hooks';
import Modal from 'components/UI/Modal';
import * as Yup from 'yup';
import Select from 'components/FormUI/Select';
import Input from 'components/FormUI/Input';
import { Formik, Form } from 'formik';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import Grid from '@material-ui/core/Grid';
import { useStripe } from '@stripe/react-stripe-js';

const AddBankAccountPopup = ({ addStripeBankAccountDataPopup, onCancel }) => {
  const stripe = useStripe();
  const [errors, setErrors] = useState(null);
  const { request } = useHttp();

  const attachExternalAccount = useCallback(
    stripeToken =>
      request(
        '/api/Payment/attach-external-account',
        'POST',
        { stripeToken },
        {
          'Content-Type': 'application/json',
        },
      ),
    [request],
  );

  //use this form https://gist.github.com/adamjstevenson/bb1698728ba3b36c8038ebe2796a0253/stargazers
  return (
    <Modal
      isOpen={addStripeBankAccountDataPopup}
      onCancel={async () => await onCancel()}
      form="bank-account-form"
      title="Bank Account Details"
      footer={
        <Grid item style={{ fontWeight: 600 }}>
          We take your security and privacy seriously.
          <br />
          Your information is protected by data encryption and is securely stored.
        </Grid>
      }
    >
      <>
        {errors && <CommonErrorMessage message={errors}></CommonErrorMessage>}
        <Formik
          initialValues={{
            accountNumber: '',
            country: 'US',
            accountHolderType: 'individual',
            routingNumber: '',
            currency: 'USD',
          }}
          validationSchema={Yup.object().shape({
            accountNumber: Yup.string().required('This is a required field'),
            routingNumber: Yup.string()
              .required('This is a required field')
              .length(9, 'Routing number must have 9 digits'),
          })}
          onSubmit={async (values, actions) => {
            setErrors(null);
            var requestData = {
              ...values,
              routing_number: values.routingNumber,
              account_number: values.accountNumber,
              account_holder_type: values.accountHolderType,
              account_holder_name: values.accountHolderName,
            };
            var createTokenResult = await stripe.createToken('bank_account', requestData);
            if (createTokenResult?.error) {
              setErrors(createTokenResult?.error.message);
            } else {
              try {
                await attachExternalAccount(createTokenResult.token.id);
              } catch (error) {
                setErrors(error.response.data.message || error.message);
                return;
              }
              await onCancel();
            }
          }}
        >
          <Form id="bank-account-form">
            <Grid container>
              <Grid item xs={12}>
                <Input label="Account Number" type="text" name="accountNumber" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Input label="Routing Number" type="text" name="routingNumber" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Input label="Account Holder Name" type="text" name="accountHolderName" fullWidth />
              </Grid>
              <Grid item xs={12} style={{ marginTop: '26px' }}>
                <Select
                  label="Account Holder Type"
                  name="accountHolderType"
                  fullWidth
                  items={[
                    { title: 'Individual', value: 'individual' },
                    { title: 'Company', value: 'company' },
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="currency"
                  label="Currency"
                  fullWidth
                  items={[
                    { title: 'USD', value: 'USD' },
                    { title: 'CAD', value: 'CAD' },
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Country"
                  name="country"
                  fullWidth
                  items={[
                    { title: 'United States', value: 'US' },
                    { title: 'Canada', value: 'CA' },
                  ]}
                />
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </>
    </Modal>
  );
};

export default AddBankAccountPopup;
