import React, { useCallback, useState, useEffect } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import Modal from 'components/UI/Modal';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import * as R from 'ramda';
import isEmpty from 'lodash/isEmpty';
import { useTheme, useMediaQuery } from '@material-ui/core';
import * as pageActions from 'actions/page';

import { StylesProvider, makeStyles } from '@material-ui/core/styles';
import Button from 'components/FormUI/Button';

import usePaidTier from 'hooks/usePaidTier';
import CohereIcon from 'assets/icon.png';
import BackButton from 'components/UI/BackButton';
import TypeOfCourse from 'pages/ContributionView/components/TypeOfCourse';
import PurchaseModalsAndButtons from 'pages/ContributionView/components/PurchaseModalsAndButtons/PurchaseModalsAndButtons';
import BurgerMenu from 'assets/BurgerMenu.svg';
import { useAccount, useRouter } from 'hooks';
import { UserRoles } from 'helpers/constants';
import * as userActions from 'actions/user';
import ClickDropDown, { StyledDropDownItem } from './ClickDropDown';
import { ROUTES, TOOLTIP, PAID_TIER_BUTTON_LABEL } from '../../constants';
import { PageTitle, PageTitleText } from './Text/TextStyles';
import GettingStartedGuide from '../App/GettingStarted';
import { colors } from '../../utils/styles';
import UpgradeButton from './UpgradeButton';

const StyledHaderContainer = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
  padding: 30px 55px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ noShadow, mobileView }) => {
    return noShadow && `box-shadow: none;`, mobileView && `padding: 15px 20px`;
  }};
`;

const StyledUserSectionContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ tabletView }) => tabletView && `margin-right: -20px;`};
`;

const HeaderIcon = styled.img`
  margin-right: 20px;
  vertical-align: middle;
  object-fit: contain;
  width: 45px;
  height: auto;
`;

const TitleAndMobileMenuWrapper = styled.div`
  display: flex;
  ${({ mobileView }) => mobileView && `align-items: baseline;`};
`;

const MobileMenuWrapper = styled.div`
  margin-right: 12px;
  &:hover {
    cursor: pointer;
  }
`;

const AvatarComponent = styled(Avatar)`
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 24px;
        height: 24px;
        font-size: 0.7rem;
      `
    );
  }}
`;

const StyledIcon = styled(Icon)`
  margin-left: 20px;
`;

const MockBlock = styled.div`
  width: 200px;
`;

const useStyles = makeStyles(theme => ({
  upgradeButton: {
    border: 'none',
    borderRadius: 6,
    width: 125,
    height: 33,
    marginRight: 15,
    color: colors.white,
    fontWeight: 500,
    background: colors.darkOceanBlue,
    transition: 'all 0.25s',
    '&:hover': {
      background: colors.darkBlue,
      textDecoration: 'none',
      color: colors.white,
    },
    [theme.breakpoints.down('sm')]: {
      width: 85,
      marginRight: 0,
      fontSize: 12,
      padding: '0px',
      '&>svg': {
        width: '1rem !important',
        height: '1rem !important',
      },
    },
  },
}));

const isPathForContribution = path => path === '/contribution-view/:id';
const isPathForCohealerAbout = path => path === '/cohealer-about/:cohealerId';

const getInitialSymbol = R.compose(R.toUpper, R.head);

function Header({ title, noShadow, user, contribution, isCustomPrevButton, backButtonText, children, view }) {
  const classes = useStyles();

  const { path } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useRouter();

  const { isLaunchPlan } = usePaidTier();

  const dispatch = useDispatch();
  const theme = useTheme();
  const { currentRole } = useAccount();
  const isMobileSidebarNeeded = useMediaQuery(theme.breakpoints.down('sm'));
  const xsView = useMediaQuery(theme.breakpoints.down('xs'));
  const tabletView = useMediaQuery(theme.breakpoints.down('md'));
  const mobileView = view || xsView;
  const [supportPopup, setSupportPopup] = useState(false);
  const toggleMobilesidebar = () => {
    dispatch(pageActions.toggleMobileSidebar());
  };
  const reloginTitle = `${currentRole === UserRoles.client ? 'Coach' : 'Client'} View`;
  const [isGetStartedOpen, setIsGetStartedOpen] = useState(false);
  const handeleCloseGetStartedGuide = useCallback(() => setIsGetStartedOpen(false), [isGetStartedOpen]);
  const handeleOpenGetStartedGuide = useCallback(() => {
    setSupportPopup(false);
    setIsGetStartedOpen(true);
  }, [isGetStartedOpen]);
  const modalButtons =
    currentRole === UserRoles.cohealer
      ? [{ buttonTitle: 'Get Started Guide', onClick: handeleOpenGetStartedGuide }]
      : [];

  const isDashboardOrAccountPage = useCallback(() => {
    return pathname.includes(ROUTES.ACCOUNT) || ROUTES.DASHBOARD === pathname;
  }, [pathname]);

  return (
    <StyledHaderContainer noShadow={noShadow} mobileView={isMobileSidebarNeeded}>
      <Helmet>
        <title>{title} - Cohere</title>
      </Helmet>

      <TitleAndMobileMenuWrapper mobileView={mobileView}>
        {isMobileSidebarNeeded &&
          !(isPathForContribution(path) && contribution?.type) &&
          !isPathForCohealerAbout(path) && (
            <MobileMenuWrapper onClick={toggleMobilesidebar}>
              <img alt="burger-menu" src={BurgerMenu} />
            </MobileMenuWrapper>
          )}
        {isEmpty(user) && !tabletView && <MockBlock />}

        {isCustomPrevButton ? (
          <div className="d-flex align-items-center">
            <ArrowBackIosIcon fontSize="small" />
            <div
              className="h6 mr-3 cursor-pointer"
              onClick={history.goBack}
              onKeyPress={null}
              role="button"
              tabIndex={0}
            >
              {backButtonText}
            </div>
            {!mobileView && (
              <>
                <HeaderIcon src={CohereIcon} />
                <span className="h3 font-weight-light">{title}</span>
              </>
            )}
          </div>
        ) : (
          <PageTitle mobileView={mobileView}>
            {isPathForContribution(path) && contribution?.type && (
              <>
                <BackButton type={contribution.type} />
                {!isMobileSidebarNeeded && <HeaderIcon src={CohereIcon} />}
              </>
            )}
            <PageTitleText mobileView={mobileView}>
              {(!(isPathForContribution(path) && contribution?.type) || !isMobileSidebarNeeded) && title}
            </PageTitleText>
          </PageTitle>
        )}
      </TitleAndMobileMenuWrapper>
      <StyledUserSectionContainer tabletView={tabletView}>
        {!isMobileSidebarNeeded && isPathForContribution(path) && (
          <TypeOfCourse contribution={contribution} handleUrlModal />
        )}
        {!isMobileSidebarNeeded && isPathForContribution(path) && (
          <PurchaseModalsAndButtons contribution={contribution} />
        )}

        {currentRole === UserRoles.cohealer && isLaunchPlan && isDashboardOrAccountPage() && (
          <Link to="/account/billing/coach">
            <UpgradeButton buttonLabel={PAID_TIER_BUTTON_LABEL.upgrade} style={classes.upgradeButton} starIcon />
          </Link>
        )}
        {!isEmpty(user) ? (
          <>
            {children && <div style={{ marginRight: '20px' }}>{children}</div>}
            <Tooltip
              className="cursor-pointer"
              title="Help"
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <StyledIcon
                path={mdiHelpCircleOutline}
                size={mobileView ? 0.9 : 1.25}
                onClick={() => setSupportPopup(true)}
              />
            </Tooltip>
            <StylesProvider injectFirst>
              <AvatarComponent
                className="mx-2"
                alt={`${user.firstName} ${user.lastName}`}
                src={user.avatarUrl}
                mobileView={mobileView}
              >
                {`${user.firstName && getInitialSymbol(user.firstName)}${
                  user.lastName && getInitialSymbol(user.lastName)
                }`}
              </AvatarComponent>
            </StylesProvider>
            <ClickDropDown id="header-user-dropdown" title={user.firstName} mobileView={mobileView}>
              <StyledDropDownItem to="/account/profile">Account</StyledDropDownItem>
              <StyledDropDownItem to={ROUTES.ROLE_SWITCH}>{reloginTitle}</StyledDropDownItem>
              <StyledDropDownItem to="/logout">Logout</StyledDropDownItem>
            </ClickDropDown>
            <Modal
              title="We are here for you!"
              isOpen={supportPopup}
              submitTitle="Contact Support"
              onCancel={() => {
                setSupportPopup(false);
              }}
              onSubmit={() => {
                setSupportPopup(false);
                window.location.href = 'mailto:support@cohere.live';
              }}
              buttons={modalButtons}
            >
              <p>
                Have a service question? Tech problem? If so, we always have your back! Our service representatives
                respond within 2 business days. When you click the button below it will provide an email address to
                contact us.
              </p>

              <p>
                In the subject of your email, please write the type of issue or question. For example: “Tech Issue,”
                “Service Question,” or “Product Feedback.” If you have a tech issue, please also attach screen shots in
                the body of your email if possible.
              </p>

              <p>We are committed to ensuring that you have a great experience! Thank you for your partnership.</p>

              <p>The Cohere Team.</p>
            </Modal>
          </>
        ) : (
          !tabletView && <MockBlock />
        )}
      </StyledUserSectionContainer>
      <GettingStartedGuide isOpen={isGetStartedOpen} closeHandler={handeleCloseGetStartedGuide} />
    </StyledHaderContainer>
  );
}

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  noShadow: PropTypes.bool,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape(),
  isCustomPrevButton: PropTypes.bool,
  backButtonText: PropTypes.string,
  children: PropTypes.node,
};

Header.defaultProps = {
  noShadow: false,
  contribution: null,
  isCustomPrevButton: false,
  backButtonText: '',
  children: null,
};

const actions = {
  getStartedToggle: userActions.getStartedToggle,
};

const mapStateToProps = ({ account, page, contributions }) => ({
  user: account?.user || {},
  loading: account?.isLoading,
  title: page?.header,
  contribution: contributions.activeContribution,
});

export default connect(mapStateToProps, actions)(Header);
