import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useTheme, useMediaQuery } from '@material-ui/core'

import { fetchClientContribution } from 'actions/contributions'
import { useVideoChat } from 'hooks'
import JoinSessionTime from 'components/SessionButtons/JoinSessionTime'
import LaunchRecordedSessionVideo from 'components/SessionButtons/LaunchRecordedSessionVideo'

import CourseSessions from '../CourseSessions'
import BookSessionTime from './BookSessionTime'
import UnbookSessionTime from './UnbookSessionTime'

import './ClientCourseSessions.scss'

const checkIfSessionTimeBooked = (sessionTime, userId) => {
  const { participantsIds } = sessionTime

  if (!participantsIds || participantsIds.length === 0) {
    return false
  }

  return participantsIds.includes(userId)
}

const ClientCourseSessions = ({ user, contribution, contribution: { id, isPurchased } }) => {
  const dispatch = useDispatch()
  const { token } = useVideoChat()

  const refreshContribution = useCallback(() => dispatch(fetchClientContribution(id)), [dispatch, id])

  useEffect(() => {
    const sessionsRefresher = setInterval(() => {
      return !token && dispatch(fetchClientContribution(id))
    }, 10000)

    return () => {
      clearInterval(sessionsRefresher)
    }
  }, [token, dispatch, id])

  useEffect(() => {
    if (token) {
      return () => refreshContribution()
    }
  }, [token, refreshContribution])

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <CourseSessions
      contribution={contribution}
      renderSessionTimeActions={(session, sessionTime) => {
        if (!isPurchased) {
          return null
        }
        const { maxParticipantsNumber, sessionTimes } = session
        const { participantsIds, videoRoomInfo } = sessionTime

        const isOtherSessionTimeBooked = sessionTimes
          .filter((st) => st.id !== sessionTime.id)
          .some((st) => checkIfSessionTimeBooked(st, user.id))
        const isBooked = checkIfSessionTimeBooked(sessionTime, user.id)
        const hasAvailableSeats = participantsIds.length < maxParticipantsNumber
        const isLaunched = !!videoRoomInfo && videoRoomInfo.isRunning
        const isHasRecordings = sessionTime.recordingInfos.length > 0
        return (
          <>
            {!sessionTime.isCompleted && (
              <div className="client-course-session-time-actions">
                {!isBooked && !isOtherSessionTimeBooked && hasAvailableSeats && (
                  <BookSessionTime
                    contribution={contribution}
                    session={session}
                    sessionTime={sessionTime}
                    onSessionTimeBooked={refreshContribution}
                    mobileView={mobileView}
                  />
                )}
                {isBooked && !session.isCompleted && (
                  <>
                    <UnbookSessionTime
                      contribution={contribution}
                      session={session}
                      sessionTime={sessionTime}
                      onSessionTimeUnbooked={refreshContribution}
                      mobileView={mobileView}
                    />
                    <JoinSessionTime
                      user={user}
                      contribution={contribution}
                      session={session}
                      sessionTime={sessionTime}
                      isLaunched={isLaunched}
                      liveVideoServiceProvider={contribution?.liveVideoServiceProvider}
                      mobileView={mobileView}
                    />
                  </>
                )}
              </div>
            )}
            {isHasRecordings && (
              <LaunchRecordedSessionVideo
                contribution={contribution}
                session={session}
                sessionTime={sessionTime}
                mobileView={mobileView}
              />
            )}
          </>
        )
      }}
    />
  )
}

ClientCourseSessions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    isPurchased: PropTypes.bool,
  }).isRequired,
}

export default ClientCourseSessions
