import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
// import { LocalizeProvider } from "react-localize-redux";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import 'utils/api'
import { colors } from 'utils/styles'
import { configureStore } from 'store/configureStore'
// import Localize from "./Localize";
import Routes from './Routes'

const { store, persistor } = configureStore()
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Avenir',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Helvetica',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),
  },
  palette: {
    primary: {
      main: colors.darkOceanBlue,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 992,
      lg: 1200,
      xl: 1920,
    },
  },
})

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN)

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {/* <LocalizeProvider> */}
        <Elements stripe={stripePromise}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {/* <Localize /> */}
              <Router>
                <Routes />
              </Router>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Elements>
        {/* </LocalizeProvider> */}
      </PersistGate>
    </Provider>
  )
}

export default App
