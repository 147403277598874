import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

import { toRem, devices } from 'utils/styles';
import Button from 'components/FormUI/Button';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import Portal from './Portal';

const StyledModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 10;

  ${({ mobileView }) => mobileView && `padding: 20px 0;`}
`;

const StyledModalContainer = styled.div`
  background-color: #fff;
  min-height: 200px;
  min-width: 100px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  s @media screen and (${devices.mobile}) {
    min-width: 400px;
  }
`;

const StyledModalHeader = styled.div`
  padding: 10px 20px;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
`;

const StyledModalTitle = styled.span`
  font-size: ${toRem(22)};
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.19px;
  color: rgba(0, 0, 0, 0.87);
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const StyledModalBody = styled.div`
  padding: 10px 20px;
  flex: 1;
  max-height: 70vh;
  /* overflow-y: scroll; */
  overflow: auto;
`;

const StyledModalFooter = styled.div`
  border-top: 1px solid #e7e7e7;
  padding: 10px 20px;

  & button + button {
    margin-left: 10px;
  }
`;

const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const Modal = React.memo(
  ({
    title,
    isOpen,
    onCancel,
    onSubmit,
    children,
    cancelTitle,
    submitTitle,
    disableConfirm,
    disableFooter,
    loading,
    form,
    buttons,
    hiddenCancel,
    helperText,
    mobileView,
    disableSubmitClick,
    footer,
    cancelInvert,
  }) => {
    const overlayCancelHandler = useCallback(() => {
      if (!loading) {
        onCancel();
      }
    }, [onCancel, loading]);
    return (
      <>
        {isOpen && (
          <Portal>
            <StyledModalOverlay onClick={overlayCancelHandler} mobileView={mobileView}>
              <StyledModalContainer onClick={e => e.stopPropagation()}>
                <StyledModalHeader>
                  <StyledModalTitle>{title}</StyledModalTitle>
                  <StyledCloseIcon onClick={onCancel} />
                </StyledModalHeader>
                <StyledModalBody>{children}</StyledModalBody>
                {!disableFooter && (
                  <StyledModalFooter>
                    {helperText && <CommonErrorMessage message={helperText} align="right" />}
                    <StyledFooterContainer>
                      {footer}
                      <div>
                        {buttons.map(({ buttonTitle, ...rest }) => (
                          <Button key={buttonTitle} {...rest}>
                            {buttonTitle}
                          </Button>
                        ))}
                      </div>
                      <div>
                        {!hiddenCancel && (
                          <Button disabled={loading} onClick={onCancel} invert={cancelInvert} autoWidth>
                            {cancelTitle}
                          </Button>
                        )}
                        {!disableConfirm && (
                          <Button
                            disabled={disableSubmitClick || loading}
                            onClick={onSubmit}
                            form={form}
                            type={form ? 'submit' : 'button'}
                            autoWidth
                          >
                            {submitTitle}
                          </Button>
                        )}
                      </div>
                    </StyledFooterContainer>
                  </StyledModalFooter>
                )}
              </StyledModalContainer>
            </StyledModalOverlay>
          </Portal>
        )}
      </>
    );
  },
);

Modal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  cancelTitle: PropTypes.string,
  submitTitle: PropTypes.string,
  disableConfirm: PropTypes.bool,
  loading: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonTitle: PropTypes.string,
    }),
  ),
  form: PropTypes.string,
  hiddenCancel: PropTypes.bool,
  helperText: PropTypes.string,
  mobileView: PropTypes.bool,
  disableSubmitClick: PropTypes.bool,
  disableFooter: PropTypes.bool,
};

Modal.defaultProps = {
  title: '',
  isOpen: false,
  onCancel: () => {},
  onSubmit: () => {},
  children: null,
  cancelTitle: 'Cancel',
  submitTitle: 'OK',
  disableConfirm: false,
  cancelInvert: true,
  loading: false,
  buttons: [],
  form: null,
  hiddenCancel: false,
  helperText: '',
  mobileView: false,
  disableSubmitClick: false,
  disableFooter: false,
};

export default Modal;
