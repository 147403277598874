import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import './Popup.scss'

const Popup = ({ title, text, open, onCloseText, onClose, onSubmit }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    classes={{
      paper: 'dialog-container',
    }}
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
    </DialogContent>
    <DialogActions>
      {onSubmit && <Button onClick={onSubmit}>Yes</Button>}
      <Button onClick={onClose}>{onCloseText}</Button>
    </DialogActions>
  </Dialog>
)

Popup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  open: PropTypes.bool,
  onCloseText: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

Popup.defaultProps = {
  title: '',
  text: '',
  open: false,
  onCloseText: '',
  onClose: undefined,
  onSubmit: undefined,
}

export default Popup
