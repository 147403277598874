import React from 'react';
import { useDropzone } from 'react-dropzone';

import styled from 'styled-components';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import { colors } from 'utils/styles';

const getColor = props => {
  if (props.isDragAccept) {
    return colors.darkOceanBlue;
  }
  if (props.isDragReject) {
    return '#eeeeee';
  }
  if (props.isDragActive) {
    return colors.darkOceanBlue;
  }
  return '#bdbdbd';
};

const Container = styled.div`
  cursor: pointer;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 200px;
  margin-top: 20px;
`;

const StyledIconWrap = styled.span`
  font-size: 60px;
  transition: color 0.24s ease-in-out;
  color: ${props => getColor(props)};
`;

const Dropzone = ({ multiple, action, type, icon, progress, placeholder, loading }) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop: files => action(files),
    accept: type,
    multiple,
    disabled: loading,
  });

  return (
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      {loading ? (
        <CircularProgressWithLabel value={progress} />
      ) : (
        <>
          <input {...getInputProps()} />
          <StyledIconWrap {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>{icon}</StyledIconWrap>
          <p>{placeholder}</p>
        </>
      )}
    </Container>
  );
};

Dropzone.defaultProps = {
  multiple: false,
  progress: 0,
};

export default Dropzone;
