import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';

import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType, getClientContribution } from 'services/contributions.service';

import * as paidTier from 'selectors/paidTier';
import { isPaidTierAnnualSelector } from 'selectors/user';

import { ContributionType } from 'helpers/constants';
import useShallowEqualSelector from './useShallowEqualSelector';

const PAID_TIER_TITLES = {
  launch: 'Launch',
  impact: 'Impact',
  scale: 'Scale',
};

const usePaidTier = () => {
  const dispatch = useDispatch();

  const [isLoader, setIsLoader] = useState({
    paidTierPlans: false,
    activePaidTierPlan: false,
    academyMemberships: false,
    activeAcademyMemberships: false,
  });

  const isAnnualPlan = useShallowEqualSelector(isPaidTierAnnualSelector);

  const paidTierPlans = useShallowEqualSelector(paidTier.paidTierPlansSelector);
  const activePaidTierPlan = useShallowEqualSelector(paidTier.activePaidTierPlanSelector);
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const activePaidTierNextPlan = useShallowEqualSelector(paidTier.activePaidTierNextPlanSelector);
  const activePaidTierPlanStatus = useShallowEqualSelector(paidTier.activePaidTierStatusSelector);
  const activePaidTierPlanEndDateTime = useShallowEqualSelector(paidTier.activePaidTierEndDateTimeSelector);
  const activePaidTierCurrentPaymentPeriod = useShallowEqualSelector(
    paidTier.activePaidTierCurrentPaymentPeriodSelector,
  );
  const activePaidTierNextPaymentPeriod = useShallowEqualSelector(paidTier.activePaidTierNextPaymentPeriodSelector);

  const academyContributions = useSelector(paidTier.cohereAcademyContributionsSelector);
  const activeAcademyContributions = useSelector(paidTier.activeCohereAcademyContributionsSelector);

  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;
  const isImpactPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.impact;
  const isScalePlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.scale;

  const getAllPaidTiers = useCallback(() => paidTierService.getPaidTierOptions(), []);
  const getActivePaidTier = useCallback(() => paidTierService.getCurrentPlan(), []);

  // Correct behavior - 100%
  const filterMembership = useCallback(
    res => {
      const filteredMemberships = [];

      academyContributions.forEach(item => {
        const matchedMembership = res.find(el => el.id === item.id);

        if (matchedMembership && matchedMembership.isPurchased) {
          filteredMemberships.push(item);
        }
        return true;
      });

      return filteredMemberships;
    },
    [academyContributions],
  );

  // Correct behavior - 100%
  const filterMembershipById = useCallback(
    res => {
      const activeMembershipsArray = activeAcademyContributions;

      const matchedMembershipFromAll = academyContributions.find(membership => membership.id === res.id);

      if (!matchedMembershipFromAll) {
        return activeMembershipsArray;
      }

      const matchedMembershipFromActive = activeMembershipsArray.find(membership => membership.id === res.id);

      if (matchedMembershipFromActive) {
        return activeMembershipsArray;
      }

      activeMembershipsArray.push(matchedMembershipFromAll);

      return activeMembershipsArray;
    },
    [activeAcademyContributions],
  );

  const updateActiveContributionsState = useCallback(() => {
    return getBoughtByType(ContributionType.contributionMembership)
      .then(filterMembership)
      .then(matchedMemberships => {
        dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
      });
  }, [filterMembership]);

  const updateContibutionViewState = useCallback(
    contributionId => {
      return getClientContribution(contributionId)
        .then(filterMembershipById)
        .then(matchedMemberships => {
          dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
        });
    },
    [filterMembershipById],
  );

  useEffect(() => {
    if (paidTierPlans.length) {
      return;
    }
    setIsLoader(s => ({ ...s, paidTierPlans: true }));

    getAllPaidTiers().then(data => {
      dispatch(paidTierActions.setPaidTierPlans(data));
      setIsLoader(s => ({ ...s, paidTierPlans: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activePaidTierPlan: true }));

    getActivePaidTier().then(data => {
      dispatch(paidTierActions.setActivePaidTierPlan(data));
      setIsLoader(s => ({ ...s, activePaidTierPlan: false }));
    });
  }, []);

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  useEffect(() => {
    setIsLoader(s => ({ ...s, academyMemberships: true }));

    paidTierService.getAcademy().then(data => {
      dispatch(paidTierActions.setPaidTierContribution(data));
      setIsLoader(s => ({ ...s, academyMemberships: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activeAcademyMemberships: true }));

    updateActiveContributionsState().then(() => {
      setIsLoader(s => ({ ...s, activeAcademyMemberships: false }));
    });
  }, []);

  return {
    isAnnualPlan,
    paidTierPlans,
    activePaidTierPlan,
    academyContributions,
    activeAcademyContributions,
    activePaidTierCurrentPlan,
    activePaidTierNextPlan,
    activePaidTierPlanStatus,
    activePaidTierPlanEndDateTime,
    activePaidTierCurrentPaymentPeriod,
    activePaidTierNextPaymentPeriod,
    isLaunchPlan,
    isImpactPlan,
    isScalePlan,
    getAllPaidTiers,
    getActivePaidTier,
    filterMembership,
    isLoader: Object.values(isLoader).includes(true),
    setIsLoader,
    updateActiveContributionsState,
    updateContibutionViewState,
  };
};

export default usePaidTier;
