import React from 'react';
import { Link } from 'react-router-dom';
import AcademyCard from 'components/AdvertismentCard';
import PlaceholderImage from 'assets/placeholder.jpg';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  rootAcademyList: {
    display: 'flex',
    gap: 15,
    flexWrap: 'wrap',
    [theme.breakpoints.down(500)]: {
      justifyContent: 'center',
    },
  },
  cohereAcademyLink: {
    textDecoration: 'none',
    padding: 15,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const AcademyContributionsList = ({ contributions }) => {
  const classes = useStyles();
  return (
    <div className={classes.rootAcademyList}>
      {contributions.map(item => {
        return (
          <Link
            to={`/contribution-view/${item.contributionId}/about`}
            key={item.id}
            className={classes.cohereAcademyLink}
          >
            <AcademyCard
              key={item.id}
              img={item.previewContentUrls[0] || PlaceholderImage}
              title={item.title}
              userId={item.authorUserId}
              author={`By: ${item.serviceProviderName}`}
              isShowEnrollButton
              AvatarUrl={item.authorAvatarUrl}
            />
          </Link>
        );
      })}
    </div>
  );
};

export default AcademyContributionsList;
