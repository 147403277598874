import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { Form, FieldArray } from 'formik';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Select from 'components/FormUI/Select';
import Input from 'components/FormUI/Input';
import Button from 'components/FormUI/Button';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { toRem, colors } from 'utils/styles';
import { TIMEZONES, LiveVideoProvider } from 'constants.js';
import { useShallowEqualSelector, useHttp, useRouter } from 'hooks';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { getDomainIcon } from 'utils/calendar';
import AddCalendarModal from 'components/Modals/AddCalendarModal/AddCalendarModal';
import { RemoveCalendarModal } from 'components/Modals/AddCalendarModal/RemoveCalendar';
import * as calendarsActions from 'actions/calendars';
import * as contributionActions from 'actions/contributions';
import CloseIcon from '@material-ui/icons/Close';
import Calendar from './Calendar';
import Session from './Session';
import { cloneDeep } from 'lodash';
import { TOOLTIP } from '../../../constants.js';

const StyledTimeZoneLabel = styled.p`
  display: inline-block;
  font-size: ${toRem(13)};
  background: #116582;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  margin: ${({ mobileView }) => (mobileView ? '10px 0' : '0 10px')};
  min-width: 180px;
`;
const StyledCardHeader = styled(CardHeader)`
  display: flex;
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
`;
const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StyledFullWidthFlexContainer = styled(StyledFlexContainer)`
  width: 100%;
`;
const StyledCalendarTabContainer = styled(StyledFullWidthFlexContainer)`
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  align-items: ${({ mobileView }) => (mobileView ? 'flex-start' : 'center')};
  justify-content: flex-end;
  overflow: hidden;
`;
const StyledCalendar = styled(StyledFlexContainer)`
  width: ${({ mobileView }) => (mobileView ? '120px' : '140px')};
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 6px;
  margin-right: 2px;
`;
const StyledCalendarsContainer = styled(StyledFlexContainer)`
  width: ${({ mobileView }) => (mobileView ? '290px' : '320px')};
  justify-content: ${({ onlyChild }) => (onlyChild ? 'flex-end' : 'flex-start')};
  overflow-x: auto;
  padding: ${({ mobileView }) => (mobileView ? '10px 0' : '10px 5px')};
  margin-left: ${({ mobileView }) => (mobileView ? '0' : '10px')};
`;

const StyledEmail = styled.span`
  white-space: nowrap;
  width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;
const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;

const OneToOneFormComponent = ({
  values,
  oneToOneData,
  otherEvents,
  alreadyBooked,
  calendars,
  fetchCalendars,
  addCalendar,
  accountsForConflictsCheck,
  getAcсountsForConflictsCheck,
  getAcсountForSendReminders,
  contribution,
  removeCalendar,
  lastAddedMail,
  loadingLastEmail,
  getEditContributionTimes,
  loadingTimes,
  calculateSlots,
  setFieldValue,
}) => {
  const user = useShallowEqualSelector(state => state?.account?.user);
  const [isAddCalendarModalOpen, setIsAddCalendarModalOpen] = useState(false);
  const [removedCalendarEmail, setRemovedCalendarEmail] = useState(false);
  const { query, pathname } = useRouter();
  const isCreate = pathname === '/create-contribution/sessions';

  const location = useLocation();

  const handleModalSubmit = useCallback(async () => {
    setIsAddCalendarModalOpen(false);
  }, []);

  const handleOpenAddCalendarModal = e => {
    e.preventDefault();
    setIsAddCalendarModalOpen(true);
  };

  const handleCloseAddCalendarModal = () => {
    setIsAddCalendarModalOpen(false);
  };

  const onRemoveCalendar = email => {
    setRemovedCalendarEmail(email);
  };

  const onRemovecalendarSubmit = () => {
    removeCalendar(removedCalendarEmail);
    setRemovedCalendarEmail(null);
  };

  const handleCloseRemoveCalendarModal = () => {
    setRemovedCalendarEmail(null);
  };

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  useEffect(() => {
    fetchCalendars();
    getAcсountsForConflictsCheck();
    getAcсountForSendReminders();
    if (location.state?.calendarModalOpen) {
      setIsAddCalendarModalOpen(true);
      const state = { ...history.location.state };
      delete state.calendarModalOpen;
      history.replace({ ...history.location, state });
    }
  }, []);

  useEffect(() => {
    if (contribution?.oneToOneSessionDataUi) {
      const { oneToOneSessionDataUi, id } = contribution;

      if (id) {
        getEditContributionTimes(id, oneToOneSessionDataUi, true);
      } else {
        calculateSlots(oneToOneSessionDataUi);
      }
    }
  }, [getEditContributionTimes, contribution?.oneToOneSessionDataUi, contribution?.id]);

  const preparedValues = cloneDeep(values);

  if (oneToOneData.preparedSlots) {
    preparedValues.sessions = values.sessions.map(session => {
      return { ...session, sessionTimes: [...oneToOneData.preparedSlots] };
    });
  }

  var otherEventsAvailabilityTimesIds = [];
  if (otherEvents && otherEvents.length > 0 && otherEvents[0].sessionTimes) {
    otherEventsAvailabilityTimesIds = otherEvents[0].sessionTimes.filter(e => e.eventId).map(e => e.eventId);
  }
  preparedValues.sessions.forEach(
    st => (st.sessionTimes = st.sessionTimes.filter(e => !otherEventsAvailabilityTimesIds.some(j => j == e.id))),
  );
  preparedValues.sessions = preparedValues.sessions ? [...preparedValues.sessions, ...otherEvents] : otherEvents;

  return (
    <Form id="create-contribution-form">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card mobileView={mobileView}>
            <StyledCardHeader mobileView={mobileView}>
              <StyledFullWidthFlexContainer>
                <PageTitleSecond mobileView={mobileView}>3. Session Booking Availability</PageTitleSecond>
                <NavLink to="/account/profile">
                  <StyledTimeZoneLabel>{TIMEZONES[user.timeZoneId]}</StyledTimeZoneLabel>
                </NavLink>
              </StyledFullWidthFlexContainer>

              <StyledCalendarTabContainer mobileView={mobileView}>
                <StyledCalendarsContainer
                  onlyChild={calendars && calendars.length === 1}
                  className={'no-scroll-container'}
                  mobileView={mobileView}
                >
                  {accountsForConflictsCheck &&
                    accountsForConflictsCheck.map(
                      ({ provider, emailAddress, isCheckConflictsEnabled, isEventRemindersEnabled }, i) => {
                        const key = `${i}${emailAddress}`;
                        const Icon = getDomainIcon(null, provider);
                        return (
                          <StyledCalendar key={key}>
                            <Icon />
                            <StyledEmail className="ml-2">{emailAddress}</StyledEmail>
                            <StyledCloseIcon onClick={() => onRemoveCalendar(emailAddress)} />
                          </StyledCalendar>
                        );
                      },
                    )}
                </StyledCalendarsContainer>
                <StyledFlexContainer>
                  <StyledButton mobileView={mobileView} autoWidth onClick={handleOpenAddCalendarModal}>
                    Add my calendar
                  </StyledButton>
                  <StyledTooltip
                    title="Adding your calendar(s) prevents double booking. Cohere will automatically show clients ONLY the times you are available by cross-referencing your personal calendars."
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <InfoIcon htmlColor={colors.lightBrown} />
                  </StyledTooltip>
                </StyledFlexContainer>
              </StyledCalendarTabContainer>
            </StyledCardHeader>
            <CardBody mobileView={mobileView}>
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                  <Select
                    label="Live Video Service Provider"
                    name="liveVideoServiceProvider.providerName"
                    onChange={({ target }) => {
                      setFieldValue(target.name, target.value);
                    }}
                    fullWidth
                    items={[LiveVideoProvider.Cohere, LiveVideoProvider.Custom]}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Input
                    hidden={
                      values.liveVideoServiceProvider &&
                      values.liveVideoServiceProvider.providerName === LiveVideoProvider.Cohere.value
                    }
                    placeholder="Enter Private Link"
                    name="liveVideoServiceProvider.customLink"
                    onChange={({ target }) => {
                      setFieldValue(target.name, target.value, false);
                    }}
                    fullWidth
                    style={{ flexGrow: 1 }}
                    label={
                      <div>
                        Use Your Private Room link instead (Zoom or Others)
                        <Tooltip
                          title="Cohere provides a built-in rich & streamlined video experience for you and your clients.
                          The Cohere video experiences saves your in-video chat threads, private session notes, and automates video recordings.
                          If you instead wish to use another video provider without these features,
                          you can add your private room link below and we’ll make sure your clients show up there instead.
                          When you and your clients access this Contribution,
                          the session launch button will open your platform of choice and we’ll also update calendar invites for both you and your clients."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <StyledInfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                        </Tooltip>
                      </div>
                    }
                  />
                </Grid>
              </Grid>
              <FieldArray name="sessions">
                {({ push, remove }) => {
                  const addHandler = () => {
                    push({
                      sessionTimes: [],
                      durations: values?.sessions[0]?.durations || [60],
                      minParticipantsNumber: 1,
                      maxParticipantsNumber: 1,
                      title: '',
                    });
                  };

                  const removeHandler = id => {
                    remove(id);
                  };

                  return (
                    <>
                      {values.sessions.map((session, index) => (
                        <Session
                          /* eslint-disable-next-line */
                          key={index}
                          index={index}
                          timesCount={session?.sessionTimes?.length || 0}
                          oneToOne
                          onDelete={removeHandler}
                          otherEvents={otherEvents}
                          alreadyBooked={alreadyBooked}
                        />
                      ))}

                      {values?.sessions?.length === 0 && (
                        <Button type="button" onClick={addHandler} autoWidth>
                          Add Availabillity
                        </Button>
                      )}
                    </>
                  );
                }}
              </FieldArray>
              <Calendar values={preparedValues} loadingTimes={loadingTimes} />
            </CardBody>
          </Card>
        </Grid>
      </Grid>
      {isAddCalendarModalOpen && (
        <AddCalendarModal
          isOpen
          onModalClose={handleCloseAddCalendarModal}
          onSubmit={handleModalSubmit}
          calendars={calendars}
          addCalendar={addCalendar}
          contributionId={query.id}
          isCreate={isCreate}
          lastAddedMail={lastAddedMail}
          loadingLastEmail={loadingLastEmail}
        />
      )}
      {removedCalendarEmail && (
        <RemoveCalendarModal
          isOpen={removedCalendarEmail}
          onCancel={handleCloseRemoveCalendarModal}
          onSubmit={onRemovecalendarSubmit}
        />
      )}
    </Form>
  );
};

OneToOneFormComponent.propTypes = {
  values: PropTypes.shape({
    sessions: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  otherEvents: PropTypes.arrayOf(PropTypes.shape()),
  alreadyBooked: PropTypes.bool,
};

OneToOneFormComponent.defaultProps = {
  otherEvents: [],
  alreadyBooked: false,
};

const mapStateToProps = ({ oneToOneData, contributions, calendars }) => ({
  oneToOneData,
  calendars: calendars.calendarsAccounts,
  lastAddedMail: calendars.lastAddedEmail,
  loadingLastEmail: calendars.loadingLastEmail,
  accountsForConflictsCheck: calendars.accountsForConflictsCheck,
  contribution: contributions.activeContribution,
  loadingTimes: contributions.loadingTimes,
});
const actions = {
  fetchCalendars: calendarsActions.fetchCalendars,
  addCalendar: calendarsActions.addCalendar,
  getAcсountsForConflictsCheck: calendarsActions.getAcсountsForConflictsCheck,
  getAcсountForSendReminders: calendarsActions.getAcсountForSendReminders,
  removeCalendar: calendarsActions.removeCalendar,
  getEditContributionTimes: contributionActions.fetchEditContributionTimes,
  calculateSlots: contributionActions.calculateCreateSlots,
};

export const OneToOneForm = connect(mapStateToProps, actions)(OneToOneFormComponent);
