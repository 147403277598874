export const LANGUAGE_CODES = {
  en: 'English',
  ar: 'Arabic',
  zh: 'Chinese',
  fr: 'French',
  de: 'German',
  hi: 'Hindi',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  pt: 'Portuguese',
  ru: 'Russian',
  es: 'Spanish',
  sv: 'Swedish',
  vi: 'Vietnamese',
};

export const PROFILE_CATEGORY_DESCRIPTIONS = {
  Interests:
    "These selections will help us tailor your experience. Tap once for things you're sort of interested in, twice for things you're really interested in, and a third time to remove it.",
  Curiosities:
    'These selections will help us tailor your experience. Tap once for things you might want to learn more about, twice for things you  really want to learn more about, and a third time to remove it.',
  Experiences:
    "These selections will help us tailor your experience. Tap once for things you're beginner or intermediate at, twice for things you're a pro at, and a third time to remove it.",
};

export const TIMEZONES = {
  'America/Los_Angeles': 'Pacific Standard Time',
  'America/Denver': 'Mountain Standard Time',
  'America/Chicago': 'Central Standard Time',
  'America/New_York': 'Eastern Standard Time',
  'America/Anchorage': 'Alaskan Standard Time',
  'America/St_Johns': 'Newfoundland Standard Time',
  'Pacific/Honolulu': 'Hawaiian Standard Time',
  'America/Phoenix': 'Arizona Standard Time',
  'Australia/Sydney': 'Australia/Sydney',
  'Canada/Atlantic': 'Canada/Atlantic',
  'Canada/Central': 'Canada/Central',
  'Canada/Eastern': 'Canada/Eastern',
  'Canada/Mountain': 'Canada/Mountain',
  'Canada/Newfoundland': 'Canada/Newfoundland',
  'Canada/Pacific': 'Canada/Pacific',
  'Canada/Saskatchewan': 'Canada/Saskatchewan',
  'Canada/Yukon': 'Canada/Yukon',
  'Europe/Amsterdam': 'Europe/Amsterdam',
  'Europe/Andorra': 'Europe/Andorra',
  'Europe/Athens': 'Europe/Athens',
  'Europe/Belfast': 'Europe/Belfast',
  'Europe/Belgrade': 'Europe/Belgrade',
  'Europe/Berlin': 'Europe/Berlin',
  'Europe/Bratislava': 'Europe/Bratislava',
  'Europe/Brussels': 'Europe/Brussels',
  'Europe/Bucharest': 'Europe/Bucharest',
  'Europe/Budapest': 'Europe/Budapest',
  'Europe/Busingen': 'Europe/Busingen',
  'Europe/Chisinau': 'Europe/Chisinau',
  'Europe/Copenhagen': 'Europe/Copenhagen',
  'Europe/Dublin': 'Europe/Dublin',
  'Europe/Gibraltar': 'Europe/Gibraltar',
  'Europe/Guernsey': 'Europe/Guernsey',
  'Europe/Helsinki': 'Europe/Helsinki',
  'Europe/Isle_of_Man': 'Europe/Isle of Man',
  'Europe/Istanbul': 'Europe/Istanbul',
  'Europe/Jersey': 'Europe/Jersey',
  'Europe/Kaliningrad': 'Europe/Kiev',
  'Europe/Lisbon': 'Europe/Lisbon',
  'Europe/Ljubljana': 'Europe/Ljubljana',
  'Europe/London': 'Europe/London',
  'Europe/Luxembourg': 'Europe/Luxembourg',
  'Europe/Madrid': 'Europe/Madrid',
  'Europe/Malta': 'Europe/Malta',
  'Europe/Mariehamn': 'Europe/Mariehamn',
  'Europe/Minsk': 'Europe/Minsk',
  'Europe/Monaco': 'Europe/Monaco',
  'Europe/Moscow': 'Europe/Moscow',
  'Europe/Nicosia': 'Europe/Nicosia',
  'Europe/Oslo': 'Europe/Oslo',
  'Europe/Paris': 'Europe/Paris',
  'Europe/Podgorica': 'Europe/Podgorica',
  'Europe/Prague': 'Europe/Prague',
  'Europe/Riga': 'Europe/Riga',
  'Europe/Rome': 'Europe/Rome',
  'Europe/Samara': 'Europe/Samara',
  'Europe/San_Marino': 'Europe/San_Marino',
  'Europe/Sarajevo': 'Europe/Sarajevo',
  'Europe/Simferopol': 'Europe/Simferopol',
  'Europe/Skopje': 'Europe/Skopje',
  'Europe/Sofia': 'Europe/Sofia',
  'Europe/Stockholm': 'Europe/Stockholm',
  'Europe/Tallinn': 'Europe/Tallinn',
  'Europe/Tirane': 'Europe/Tirane',
  'Europe/Tiraspol': 'Europe/Tiraspol',
  'Europe/Uzhgorod': 'Europe/Uzhgorod',
  'Europe/Vaduz': 'Europe/Vaduz',
  'Europe/Vatican': 'Europe/Vatican',
  'Europe/Vienna': 'Europe/Vienna',
  'Europe/Vilnius': 'Europe/Vilnius',
  'Europe/Volgograd': 'Europe/Volgograd',
  'Europe/Warsaw': 'Europe/Warsaw',
  'Europe/Zagreb': 'Europe/Zagreb',
  'Europe/Zaporozhye': 'Europe/Zaporozhye',
  'Europe/Zurich': 'Europe/Zurich',
  Hongkong: 'Hongkong',
  Iceland: 'Iceland',
  Israel: 'Israel',
  Japan: 'Japan',
  'Mexico/BajaNorte': 'Mexico/Pacific',
  'Mexico/BajaSur': 'Mexico/Mountain',
  'Mexico/General': 'Mexico/Central',
  Navajo: 'Navajo',
  NZ: 'NZ',
  'NZ-CHAT': 'NZ-CHAT',
  Singapore: 'Singapore',
};

export const EN_MESSAGES = {
  validation: {
    required: 'Required',
    email: {
      invalid: 'Invalid email',
      exists: 'User with this email already exists',
    },
    password:
      'Password must be at least 8 characters and contain following: upper case (A-Z), lower case (a-z) and number (0-9)',
  },
  auth: {
    login: 'Login',
  },
};

export const LOCAL_TRACK_PRIORITIES = {
  high: 'high',
  standard: 'standard',
  low: 'low',
};

export const RECORDING_VIDEO_STATUS = {
  roomInProgress: 0,
  inProgress: 1,
  available: 2,
};

export const LiveVideoProvider = {
  Cohere: { title: 'Cohere', value: 'Cohere' },
  Custom: { title: 'Other Video Platform Link', value: 'Custom' },
};

export const ROUTES = {
  CREATE_CONTRIBUTION_BASIC: '/create-contribution/basic',
  CREATE_CONTRIBUTION_DETAILS: '/create-contribution/details',
  CREATE_CONTRIBUTION_SESSIONS: '/create-contribution/sessions',
  CREATE_CONTRIBUTION_PAYMENT: '/create-contribution/payment',
  CREATE_CONTRIBUTION_REVIEW: '/create-contribution/review',
  CREATE_CONTRIBUTION_SAVE: '/create-contribution/saveHandler',

  ROLE_SWITCH: '/role/switch',
  ADD_ROLE: '/role/add',
  SUBMIT_ADD_ROLE: '/role/submit',

  DASHBOARD: '/dashboard',

  CONTRIBUTIONS: '/contributions/',
  CONTRIBUTIONS_1_TO_1: '/contributions/1-to-1-sessions',
  CONTRIBUTIONS_GROUP_COURSES: '/contributions/group-courses',

  ACCOUNT: '/account',
  ACCOUNT_VIDEO: '/account/video',
  ACCOUNT_AFFILIATE: '/account/affiliate',
  ACCOUNT_BILLING: '/account/billing',
  ACCOUNT_COACH_BILLING: '/account/billing/coach',
  ACCOUNT_BILLING_EXTENDED: '/account/billing/coach/extended',
  ACCOUNT_MEMBERSHIPS: '/account/memberships',
};

export const RESPONSE_CODES = {
  CLIENT: {
    NOT_ALLOWED: 403,
  },
  TWILIO: {
    IDENTITY_DUPLICATION: 53205,
  },
  BACKEND: {
    SUCCESS: 200,
  },
};

export const INPUT_NUMBERS_VALIDATION_REGEX = /\D/;

export const TOOLTIP = {
  ENTER_DELAY: 0,
  LEAVE_DELAY: 10000,
};

export const PAYMENT_OPTIONS = {
  PER_DAY: 'DailyMembership',
  TRIAL: 'Trial',
  PER_WEEK: 'WeeklyMembership',
  PER_MONTH: 'MonthlyMembership',
  PER_YEAR: 'YearlyMembership',
  PACKAGE: 'MembershipPackage',

  ENTIRE_COURSE: 'EntireCourse',
  SPLIT_PAYMENTS: 'SplitPayments',

  PER_SESSION: 'PerSession',
  SESSIONS_PACKAGE: 'SessionsPackage',
  MONTHLY_SESSION_SUBSCRIPTION: 'MonthlySessionSubscription',
};

export const MEMBERSHIP_STATUSES = {
  ACTIVE: 'active',
  CANCEL: 'cancel',
  UPGRADE: 'update',
};

export const PAIDTIER_STATUSES = {
  ACTIVE: 'Active',
  CANCEL: 'Cancel',
  UPGRADE: 'Upgraded',
};

export const VALIDATION_REGEX = {
  DATE: /\d{2}\/\d{2}\/\d{4}/,
};

export const LOCAL_STORAGE_TOKENS = {
  modalWasShown: 'MODAL_PLANS_WAS_SHOWN',
};

export const PAID_TIER_BUTTON_LABEL = {
  downgrade: 'Downgrade',
  cancel: 'Cancel',
  upgrade: 'Upgrade',
};

export const ENV = {
  DEV: 'development',
  PROD: 'production',
};
export const COUNT_POSTS_BY_PAGE = 5;
export const DAILY_BY_SEND_DATA = 1500;
