import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useRouter } from 'hooks';
import { ClientJourneyContribution } from 'services/dataContracts/contributions';

import CoachesList from 'pages/Dashboard/components/ClientDashboard/ClientJourney/Sessions/CoachesList';
import { JOURNEY_ROUTES } from 'helpers/constants';
import SessionsRouter from './SessionsRouter';

const ClientJourneyRouter = ({
  upcomingContributions,
  pastContributions,
  membershipsUpcoming,
  membershipsPast,
  oneToOneUpcoming,
  oneToOnePast,
  liveUpcoming,
  livePast,
  coaches,
}) => {
  const {
    match: { path, url },
  } = useRouter();

  return (
    <Switch>
      <Route
        path={`${path}/${JOURNEY_ROUTES.SESSIONS}`}
        component={() => (
          <SessionsRouter upcomingContributions={upcomingContributions} pastContributions={pastContributions} />
        )}
      />
      <Route path={`${path}/${JOURNEY_ROUTES.COACHES}`} component={() => <CoachesList coaches={coaches} />} />
      <Route
        path={`${path}/${JOURNEY_ROUTES.MEMBERSHIP}`}
        component={() => (
          <SessionsRouter upcomingContributions={membershipsUpcoming} pastContributions={membershipsPast} />
        )}
      />
      <Route
        path={`${path}/${JOURNEY_ROUTES.ONE_TO_ONE}`}
        component={() => <SessionsRouter upcomingContributions={oneToOneUpcoming} pastContributions={oneToOnePast} />}
      />
      <Route
        path={`${path}/${JOURNEY_ROUTES.LIVE}`}
        component={() => <SessionsRouter upcomingContributions={liveUpcoming} pastContributions={livePast} />}
      />
      <Redirect to={`${url}/${JOURNEY_ROUTES.SESSIONS}`} />
    </Switch>
  );
};

const contributionsPropType = PropTypes.arrayOf(PropTypes.instanceOf(ClientJourneyContribution)).isRequired;

const contributionsListPropType = PropTypes.shape({
  otherIncompleted: contributionsPropType,
  today: contributionsPropType,
  thisWeek: contributionsPropType,
  thisMonth: contributionsPropType,
  nextMonth: contributionsPropType,
  thisYear: contributionsPropType,
  afterThisYear: contributionsPropType,
});

ClientJourneyRouter.propTypes = {
  coaches: PropTypes.array.isRequired,
  upcomingContributions: contributionsListPropType.isRequired,
  pastContributions: contributionsListPropType.isRequired,
  membershipsUpcoming: contributionsListPropType.isRequired,
  membershipsPast: contributionsListPropType.isRequired,
  oneToOneUpcoming: contributionsListPropType.isRequired,
  oneToOnePast: contributionsListPropType.isRequired,
  liveUpcoming: contributionsListPropType.isRequired,
  livePast: contributionsListPropType.isRequired,
};

export default ClientJourneyRouter;
