import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import InputEmailsComponent from 'components/UI/InputEmailsComponent';
import { ContributionType } from 'helpers/constants';
import usePaidTier from 'hooks/usePaidTier';

import { createAccessCode } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import * as S from './styled';
import { LinkWrapp } from './styled';

const BUTTON_TITLE = 'Copy To Clipboard';
const TOOLTIP_TITLE = 'Copied to clipboard';

const ShareModalView = ({
  onCopyToClipboard,
  onPremiumCopyToClipboard,
  emails,
  onSetEmails,
  emailsError,
  setEmailsError,
  mobileView,
  tooltipOpen,
  setTooltipOpen,
  premiumTooltipOpen,
  setPremiumTooltipOpen,
  type,
}) => {
  const contributionType = useSelector(state => state.contributions?.activeContribution?.type);
  const isLiveGroupOrMembership = useMemo(
    () =>
      type ||
      contributionType === ContributionType.contributionMembership ||
      type ||
      contributionType === ContributionType.contributionCourse,
    [contributionType, type],
  );
  const { id } = useContribution();

  const { href } = window.location;
  const textArray = href.split('/');
  const textArrayCorrect = textArray.length === 7 ? textArray.slice(0, -1) : textArray;
  const text = textArrayCorrect.slice(0, id ? -2 : -1).join('/');
  const { isScalePlan } = usePaidTier();

  const onCloseTooltip = useCallback(() => setTooltipOpen(false), [setTooltipOpen]);
  const onClosePremiumTooltip = useCallback(() => setPremiumTooltipOpen(false), [setPremiumTooltipOpen]);

  return (
    <S.ShareModalMain mobileView={mobileView}>
      <S.ColumnFlexContainer>
        <S.Title>Invite to Purchase</S.Title>
        <Tooltip className="cursor-pointer" title={TOOLTIP_TITLE} open={tooltipOpen} onClose={onCloseTooltip} arrow>
          <S.CopyToClipboardBlockDefault onClick={onCopyToClipboard}>
            <S.ButtonIcon aria-label="close modal" component="span">
              <S.ShareIcon />
            </S.ButtonIcon>
            {BUTTON_TITLE}
          </S.CopyToClipboardBlockDefault>
        </Tooltip>
        <S.SendEmailBlock>
          <S.BlackIcon>
            <S.MailIcon />
          </S.BlackIcon>
          <InputEmailsComponent
            emails={emails}
            handleSetEmails={onSetEmails}
            emailsError={emailsError}
            setEmailsError={setEmailsError}
          />
        </S.SendEmailBlock>
        {isLiveGroupOrMembership && (
          <>
            <S.PremiumTitle hasPremiumPlan={isScalePlan}>Manually add clients (Scale Plan Feature)</S.PremiumTitle>
            <S.LinkWrapp hasPremiumPlan={isScalePlan} onClick={onPremiumCopyToClipboard}>
              <Tooltip
                className="cursor-pointer"
                title={TOOLTIP_TITLE}
                open={premiumTooltipOpen}
                onClose={onClosePremiumTooltip}
                arrow
              >
                <S.CopyToClipboardBlock hasPremiumPlan={isScalePlan}>
                  <S.ButtonIcon aria-label="close modal" component="span">
                    <S.ShareIcon hasPremiumPlan={isScalePlan} />
                  </S.ButtonIcon>
                  {BUTTON_TITLE}
                </S.CopyToClipboardBlock>
              </Tooltip>
            </S.LinkWrapp>
          </>
        )}
      </S.ColumnFlexContainer>
    </S.ShareModalMain>
  );
};

ShareModalView.propTypes = {
  onCopyToClipboard: PropTypes.func.isRequired,
  onPremiumCopyToClipboard: PropTypes.func.isRequired,
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSetEmails: PropTypes.func.isRequired,
  emailsError: PropTypes.shape({
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  setEmailsError: PropTypes.func.isRequired,
  setTooltipOpen: PropTypes.func.isRequired,
  setPremiumTooltipOpen: PropTypes.func.isRequired,
  mobileView: PropTypes.bool,
  tooltipOpen: PropTypes.bool,
  premiumTooltipOpen: PropTypes.bool,
};

ShareModalView.defaultProps = {
  mobileView: false,
  tooltipOpen: false,
  premiumTooltipOpen: false,
};

export default ShareModalView;
