import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import range from 'lodash/range';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';

import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import { formatMoney } from 'utils/datesAndMoney';
import { colors } from 'utils/styles';
import { INPUT_NUMBERS_VALIDATION_REGEX, TOOLTIP } from '../../../../constants';

import './OneOnOne.scss';
import InfoIcon from '@material-ui/icons/Info.js';
import Tooltip from '@material-ui/core/Tooltip';
import { ContributionStatus } from 'helpers/constants';
import useRouter from 'hooks/useRouter';

const DEFAULT_PACKAGE_SIZE = 5;

const StyledHeader = styled.div`
  font-size: 1.2rem;
  margin-top: 27px;
  font-weight: 600;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

const StyledText = styled.div`
  font-size: 0.95rem;
  margin-top: 12px;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;
const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: -4px;
  top: calc(50% - 5px);
  transform: translateY(-50%);
`;
const StyledTooltipWrap = styled.span`
  position: relative;
  padding-right: 7px;
  display: inline-block;
`;

const BlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: colors.darkOceanBlue,
    },
  },
  checked: {},
})(Checkbox);

function renderMonthlySessionSubscriptionPrice({ monthlySessionSubscriptionInfo }) {
  if (monthlySessionSubscriptionInfo) {
    return formatMoney(monthlySessionSubscriptionInfo.monthlyPrice * monthlySessionSubscriptionInfo.duration);
  }
  return formatMoney(0);
}

function renderPrice(values) {
  return formatMoney(+values.cost);
}

function sessionNumbersItems() {
  return range(1, 11).map(i => ({ title: `${i} session(s) per month`, value: i }));
}

function durationItems() {
  return range(2, 13).map(i => ({ title: `${i} month(s)`, value: i }));
}

const OneOnOne = ({ contribution, saveToStorageHandler, children }) => {
  const { query } = useRouter();
  const isFeeDisabled = !!query.id && contribution?.status !== ContributionStatus.unfinished;
  const isEditing = !!query.id;
  const formatPackagePrice = ({ cost, packageSessionDiscountPercentage, packageSessionNumbers }) =>
    formatMoney(((cost * (100 - packageSessionDiscountPercentage)) / 100) * packageSessionNumbers);

  const handleFormKeyDown = e => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        cost: contribution?.paymentInfo?.cost || '',
        packageCost: contribution?.paymentInfo?.cost * contribution?.paymentInfo?.packageSessionNumbers || '',
        paymentOptions:
          contribution?.paymentInfo?.paymentOptions &&
          !contribution?.paymentInfo?.paymentOptions.some(
            option => option === 'EntireCourse' || option === 'SplitPayments',
          )
            ? contribution?.paymentInfo?.paymentOptions
            : ['PerSession'],
        packageDiscount: !!contribution?.paymentInfo?.packageSessionDiscountPercentage,
        packageSessionNumbers: contribution?.paymentInfo?.packageSessionNumbers || '',
        packageSessionDiscountPercentage: contribution?.paymentInfo?.packageSessionDiscountPercentage
          ? contribution?.paymentInfo?.packageSessionDiscountPercentage
          : '',
        monthlySessionSubscriptionInfo: contribution?.paymentInfo?.monthlySessionSubscriptionInfo,
        coachPaysStripeFee: contribution?.paymentInfo?.coachPaysStripeFee,
      }}
      validationSchema={Yup.object().shape({
        cost: Yup.number().when('paymentOptions', {
          is: values => values.includes('PerSession') || values.includes('SessionsPackage'),
          then: Yup.number()
            .min(1, 'Value should be more than 1')
            .integer('Please only enter numbers')
            .required('This is a required field'),
          otherwise: Yup.number().nullable(),
        }),
        packageSessionNumbers: Yup.number(),
        packageSessionDiscountPercentage: Yup.number()
          .moreThan(0, 'Value should be more than 0')
          .max(100, 'Value should be less than 100'),
        paymentOptions: Yup.array()
          .of(Yup.mixed().oneOf(['PerSession', 'SessionsPackage', 'MonthlySessionSubscription']))
          .min(1),
        monthlySessionSubscriptionInfo: Yup.object().when('paymentOptions', {
          is: values => values.includes('MonthlySessionSubscription'),
          then: Yup.object()
            .required()
            .shape({
              sessionCount: Yup.number()
                .required()
                .min(1, 'Value should be more than 1')
                .max(10, 'Value should be less than 10'),
              duration: Yup.number()
                .required()
                .min(2, 'Value should be more than 2')
                .max(12, 'Value should be less than 12'),
              monthlyPrice: Yup.number()
                .min(1, 'Value should be more than 1')
                .integer('please only enter numbers')
                .required('This is a required field'),
            }),
          otherwise: Yup.object().nullable(),
        }),
        coachPaysStripeFee: Yup.bool(),
      })}
      onSubmit={saveToStorageHandler}
    >
      {formProps => {
        const renderCardBody = (
          <Form id="create-contribution-form" onKeyDown={handleFormKeyDown}>
            <Grid container spacing={4} alignItems="flex-start">
              <Grid item container sm={3} xs={12} spacing={1}>
                {formProps.values.paymentOptions.includes('PerSession') && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeader>Price Per Session</StyledHeader>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        type="text"
                        startAdornment="$"
                        name="cost"
                        onChange={({ target: { value } }) => {
                          if (!/\D/.test(value)) {
                            formProps.setFieldValue('cost', value);
                            formProps.setFieldValue('packageCost', value * formProps.values.packageSessionNumbers);
                          }
                        }}
                        onBlur={({ target: { value } }) => {
                          if (!value) {
                            formProps.setFieldValue('cost', 0);
                          }
                        }}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
                {formProps.values.paymentOptions.includes('SessionsPackage') && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeader>Price Per Package</StyledHeader>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        type="text"
                        startAdornment="$"
                        name="packageCost"
                        onChange={({ target: { value } }) => {
                          if (!/\D/.test(value)) {
                            if (formProps.values.packageSessionNumbers === 0) {
                              formProps.setFieldValue('packageCost', value);
                              return;
                            }
                            formProps.setFieldValue('packageCost', value);
                            formProps.setFieldValue('cost', value / formProps.values.packageSessionNumbers);
                          }
                        }}
                        onBlur={({ target: { value } }) => {
                          if (!value) {
                            formProps.setFieldValue('packageCost', 0);
                          }
                        }}
                        fullWidth
                      />
                    </Grid>
                  </>
                )}

                {formProps.values.paymentOptions.includes('MonthlySessionSubscription') && (
                  <>
                    <Grid item xs={12}>
                      <StyledHeader>Price Per Month</StyledHeader>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        type="text"
                        startAdornment="$"
                        name="monthlySessionSubscriptionInfo[monthlyPrice]"
                        fullWidth
                        onChange={({ target: { value } }) => {
                          if (!/\D/.test(value)) {
                            formProps.setFieldValue('monthlySessionSubscriptionInfo[monthlyPrice]', value);
                          }
                        }}
                        onBlur={({ target: { value } }) => {
                          if (!value) {
                            formProps.setFieldValue('monthlySessionSubscriptionInfo[monthlyPrice]', 0);
                          }
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item container sm={6} xs={12} spacing={1}>
                <Grid item xs={12}>
                  <StyledHeader>Payment Options</StyledHeader>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <FieldArray name="paymentOptions">
                      {({ push, remove, form }) => {
                        const changeHandler = ({ target: { value, checked } }) => {
                          if (checked) {
                            if (value === 'SessionsPackage') {
                              form.setFieldValue('packageSessionNumbers', DEFAULT_PACKAGE_SIZE);
                              formProps.setFieldValue('packageCost', form.values.cost * DEFAULT_PACKAGE_SIZE);
                            }
                            push(value);
                          } else {
                            const idx = form.values.paymentOptions.indexOf(value);
                            if (value === 'SessionsPackage') {
                              form.setFieldValue('packageSessionNumbers', '');
                              form.setFieldValue('packageDiscount', false);
                              form.setFieldValue('packageSessionDiscountPercentage', '');
                            }
                            remove(idx);
                          }
                        };

                        return (
                          <FormControl error={!!formProps.errors.paymentOptions} style={{ width: '100%' }}>
                            {formProps.errors.paymentOptions && (
                              <FormLabel component="legend">Pick at least one option</FormLabel>
                            )}
                            <Grid item xs={12}>
                              <StyledTooltipWrap>
                                <FormControlLabel
                                  name="paymentOptions"
                                  control={<BlueCheckbox color="primary" />}
                                  label="Pay Per Session"
                                  value="PerSession"
                                  onChange={changeHandler}
                                  checked={form.values.paymentOptions.includes('PerSession')}
                                />
                                <StyledTooltip
                                  title="Client price per single session"
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <InfoIcon htmlColor={colors.lightBrown} />
                                </StyledTooltip>
                              </StyledTooltipWrap>
                            </Grid>
                            <Grid item container spacing={2} xs={12}>
                              <Grid item>
                                <StyledTooltipWrap>
                                  <FormControlLabel
                                    name="paymentOptions"
                                    control={<BlueCheckbox color="primary" />}
                                    label="Session Packages"
                                    value="SessionsPackage"
                                    onChange={changeHandler}
                                    checked={form.values.paymentOptions.includes('SessionsPackage')}
                                  />
                                  <StyledTooltip
                                    title="Price for a package of sessions"
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              </Grid>
                              <Grid item classes={{ item: 'input_options input_sessions' }}>
                                {formProps.values.paymentOptions.includes('SessionsPackage') && (
                                  <Input
                                    type="text"
                                    name="packageSessionNumbers"
                                    label="Number of Sessions"
                                    onChange={({ target: { value } }) => {
                                      if (!INPUT_NUMBERS_VALIDATION_REGEX.test(value)) {
                                        formProps.setFieldValue('packageSessionNumbers', value);
                                        formProps.setFieldValue('packageCost', formProps.values.cost * value);
                                      }
                                    }}
                                    onBlur={({ target: { value } }) => {
                                      if (!value) {
                                        formProps.setFieldValue('packageSessionNumbers', 0);
                                      }
                                    }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </FormControl>
                        );
                      }}
                    </FieldArray>
                    {formProps.values.paymentOptions.includes('SessionsPackage') && (
                      <Field name="packageDiscount">
                        {({ form }) => {
                          const changeHandler = ({ target: { checked } }) => {
                            if (checked) {
                              form.setFieldValue('packageSessionDiscountPercentage', 10);
                            } else {
                              form.setFieldValue('packageSessionDiscountPercentage', '');
                            }
                            form.setFieldValue('packageDiscount', !form.values.packageDiscount);
                          };

                          return (
                            <Grid container xs={12} spacing={2}>
                              <Grid item>
                                <StyledTooltipWrap>
                                  <FormControl>
                                    <FormControlLabel
                                      name="packageDiscount"
                                      control={<BlueCheckbox color="primary" />}
                                      label="Package Discount"
                                      onChange={changeHandler}
                                      checked={form.values.packageDiscount}
                                    />
                                  </FormControl>
                                  <StyledTooltip
                                    title="Discount that is applied to the package price to incentivize clients to pay in full for the package"
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  >
                                    <InfoIcon htmlColor={colors.lightBrown} />
                                  </StyledTooltip>
                                </StyledTooltipWrap>
                              </Grid>
                              <Grid item classes={{ item: 'input_options input_sessions' }}>
                                {formProps.values.packageDiscount ? (
                                  <Input
                                    type="text"
                                    name="packageSessionDiscountPercentage"
                                    label="Package Discount %"
                                    onChange={({ target: { value } }) => {
                                      if (!/\D/.test(value)) {
                                        formProps.setFieldValue('packageSessionDiscountPercentage', value);
                                      }
                                    }}
                                  />
                                ) : (
                                  <div />
                                )}
                              </Grid>
                            </Grid>
                          );
                        }}
                      </Field>
                    )}
                    <FieldArray name="paymentOptions">
                      {({ push, remove, form }) => {
                        const changeHandler = ({ target: { value, checked } }) => {
                          if (checked) {
                            if (value === 'MonthlySessionSubscription') {
                              form.setFieldValue('monthlySessionSubscriptionInfo[sessionCount]', 4);
                              form.setFieldValue('monthlySessionSubscriptionInfo[duration]', 6);
                              form.setFieldValue('monthlySessionSubscriptionInfo[monthlyPrice]', '');
                            }
                            push(value);
                          } else {
                            const idx = form.values.paymentOptions.indexOf(value);

                            remove(idx);
                          }
                        };

                        return (
                          <FormControl style={{ width: '100%' }}>
                            <Grid item xs={12}>
                              <StyledTooltipWrap>
                                <FormControlLabel
                                  name="paymentOptions"
                                  disabled={isEditing}
                                  control={<BlueCheckbox color="primary" />}
                                  label="Monthly Subscription"
                                  value="MonthlySessionSubscription"
                                  onChange={changeHandler}
                                  checked={form.values.paymentOptions.includes('MonthlySessionSubscription')}
                                  classes={{ root: 'label' }}
                                />
                                <StyledTooltip
                                  title="For automatic recurring monthly payments & sessions with your clients"
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <InfoIcon htmlColor={colors.lightBrown} />
                                </StyledTooltip>
                              </StyledTooltipWrap>
                            </Grid>
                            <Grid item xs={12}>
                              <StyledTooltipWrap>
                                <FormControlLabel
                                  name="coachPaysStripeFee"
                                  control={<BlueCheckbox color="primary" />}
                                  label="Coach Pays for Processing Fee"
                                  checked={formProps.values.coachPaysStripeFee}
                                  onChange={formProps.handleChange('coachPaysStripeFee')}
                                  disabled={isFeeDisabled}
                                />
                                <StyledTooltip
                                  title="If checked, the Stripe credit card processing fee will be passed onto your Clients, resulting in a 3-4% surcharge on top of your base pricing. For example, if your price is $100, the total purchase price will be $103.00-$104.00). If unchecked, your client will be charged a flat fee and all Stripe fees will be deducted from your gross sales. For example, if your price is $100, Stripe will take 3-4% off the total purchase price ($3.00-$4.00) and you will earn $96.00-$97.00, less any additional Cohere fees."
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <InfoIcon htmlColor={colors.lightBrown} />
                                </StyledTooltip>
                              </StyledTooltipWrap>
                            </Grid>
                            <Grid item container spacing={2} xs={12}>
                              <Grid item xs={6}>
                                {formProps.values.paymentOptions.includes('MonthlySessionSubscription') && (
                                  <Select
                                    fullWidth
                                    label="Client Session Credits Per Month"
                                    items={sessionNumbersItems()}
                                    name="monthlySessionSubscriptionInfo[sessionCount]"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {formProps.values.paymentOptions.includes('MonthlySessionSubscription') && (
                                  <Select
                                    fullWidth
                                    label="Duration of Subscription"
                                    items={durationItems()}
                                    name="monthlySessionSubscriptionInfo[duration]"
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </FormControl>
                        );
                      }}
                    </FieldArray>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container sm={3} xs={12} spacing={1}>
                <Grid item xs={12}>
                  {formProps.values.paymentOptions.includes('PerSession') && (
                    <StyledText>Price per Session: ${renderPrice(formProps.values)}</StyledText>
                  )}
                  {formProps.values.paymentOptions.includes('SessionsPackage') && (
                    <StyledText>Price per Package: ${formatPackagePrice(formProps.values)}</StyledText>
                  )}
                  {formProps.values.paymentOptions.includes('MonthlySessionSubscription') && (
                    <StyledText>
                      Per Month - Includes {formProps.values.monthlySessionSubscriptionInfo.sessionCount} Sessions
                      Monthly, {formProps.values.monthlySessionSubscriptionInfo.duration} Months Total $
                      {renderMonthlySessionSubscriptionPrice(formProps.values)}
                    </StyledText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );

        return children(formProps, renderCardBody);
      }}
    </Formik>
  );
};

OneOnOne.propTypes = {
  contribution: PropTypes.shape(),
  saveToStorageHandler: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

OneOnOne.defaultProps = {
  contribution: null,
};

export default OneOnOne;
