import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TwilioChatClient from 'twilio-chat'

import { useChat, useChatActions, useOnDidMount } from 'hooks'
import chatService from 'services/chat.service'
import ChatClientContext from './ChatClientContext'
import ChatClientConnectionStatus from './ChatClientConnectionStatus'
import ChatClientConnectionError from './ChatClientConnectionError'
import { getTwilioChatErrorFrom, TwilioChatError } from './twilio/errors'

const ChatProvider = ({ children }) => {
  const { token } = useChat()
  const { startChat, updateChatToken, endChat } = useChatActions()

  const [connectionStatus, setConnectionStatus] = useState(ChatClientConnectionStatus.pending)
  const [chatClient, setChatClient] = useState(null)
  const [error, setError] = useState(null)
  useOnDidMount(() => {
    async function connectTwilioChatAsync(chatToken, shouldRefreshOnExpired = true) {
      let actualChatToken = chatToken

      if (!actualChatToken) {
        try {
          const getChatTokenResult = await chatService.getChatTokenAsync()
          actualChatToken = getChatTokenResult.token

          startChat({ token: actualChatToken })
        } catch {
          setError(ChatClientConnectionError.missingChatToken)
          setConnectionStatus(ChatClientConnectionStatus.connectionError)
          setChatClient(null)
          endChat()

          return
        }
      }

      try {
        setError(null)
        setConnectionStatus(ChatClientConnectionStatus.connecting)

        const connectedChatClient = await TwilioChatClient.create(actualChatToken)

        setChatClient(connectedChatClient)
        setConnectionStatus(ChatClientConnectionStatus.connected)
      } catch (e) {
        const twilioError = getTwilioChatErrorFrom(e)

        if (twilioError === TwilioChatError.tokenExpired || twilioError === TwilioChatError.invalidToken) {
          if (shouldRefreshOnExpired) {
            connectTwilioChatAsync(null, false)
          } else {
            setError(ChatClientConnectionError.unknown)
            setConnectionStatus(ChatClientConnectionStatus.connectionError)
            setChatClient(null)
            endChat()
          }

          return
        }

        setError(ChatClientConnectionError.unknown)
        setConnectionStatus(ChatClientConnectionStatus.connectionError)
        setChatClient(null)
      }
    }

    connectTwilioChatAsync(token)
  })
  useEffect(() => {
    if (chatClient) {
      const onRefreshToken = async () => {
        try {
          const { token: updatedChatToken } = await chatService.getChatTokenAsync()

          updateChatToken(updatedChatToken)
          await chatClient.updateToken(updatedChatToken)
        } catch {
          setError(ChatClientConnectionError.unknown)
          setConnectionStatus(ChatClientConnectionStatus.connectionError)
          setChatClient(null)
          endChat()
        }
      }

      chatClient.on('tokenAboutToExpire', onRefreshToken)
      chatClient.on('tokenExpired', onRefreshToken)

      return () => {
        chatClient.off('tokenAboutToExpire', onRefreshToken)
        chatClient.off('tokenExpired', onRefreshToken)
        chatClient.shutdown()
      }
    }
  }, [chatClient, updateChatToken, endChat])

  return (
    <ChatClientContext.Provider
      value={{
        connectionStatus,
        chatClient,
        error,
      }}
    >
      {children}
    </ChatClientContext.Provider>
  )
}

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ChatProvider
