import React from 'react'
import sumBy from 'lodash/sumBy'
import PropTypes from 'prop-types'
import range from 'lodash/range'
import orderBy from 'lodash/orderBy'
import BookSessionTime from './Session/BookButton/BookSessionTime'
import Session from './Session'
import { SESSIONS_TYPES } from '../../constants.js'

// import "./SessionsContainer.scss";

const renderSessions = (
  { availabilityTimes, clientPackages, id },
  renderActions,
  calendarEl,
  executeCalendarScroll,
  setRescheduleMode,
  setCurrentRescheduleSession,
  serviceProviderName,
  completionFilter
) => {
  const handleBook = () => {
    calendarEl.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }

  const realSessions = orderBy(availabilityTimes, ['startTime'], ['asc']).map((time) => {
    const userTimes = time.bookedTimes.filter((booked) => booked.participantId && booked.isPurchaseConfirmed)

    return userTimes.map((booked) => (
      <Session
        key={booked.id}
        number={booked.sessionIndex}
        session={booked}
        contributionId={id}
        renderActions={renderActions}
        executeCalendarScroll={executeCalendarScroll}
        setRescheduleMode={setRescheduleMode}
        setCurrentRescheduleSession={setCurrentRescheduleSession}
        serviceProviderName={serviceProviderName}
      />
    ))
  })

  if (clientPackages) {
    range(sumBy(clientPackages, 'freeSessionNumbers')).map((i) => {
      realSessions.push(
        <Session
          key={i}
          contributionId={id}
          setRescheduleMode={setRescheduleMode}
          executeCalendarScroll={executeCalendarScroll}
          setCurrentRescheduleSession={setCurrentRescheduleSession}
          renderActions={() => i === 0 && <BookSessionTime onSubmit={handleBook} />}
        />
      )
    })
  }
  return realSessions.filter((sessionComponent) => {
    if (!sessionComponent[0]?.props?.session?.isCompleted && completionFilter === SESSIONS_TYPES.COMPLETED) {
      return false
    }
    if (sessionComponent[0]?.props?.session?.isCompleted && completionFilter === SESSIONS_TYPES.UPCOMING) {
      return false
    }
    return true
  })
}

const SessionsContainer = ({
  contribution,
  renderActions,
  calendarEl,
  executeCalendarScroll,
  setRescheduleMode,
  setCurrentRescheduleSession,
  serviceProviderName,
  completionFilter,
}) => (
  <div>
    {renderSessions(
      contribution,
      renderActions,
      calendarEl,
      executeCalendarScroll,
      setRescheduleMode,
      setCurrentRescheduleSession,
      serviceProviderName,
      completionFilter
    )}
  </div>
)

SessionsContainer.defaultProps = {
  contribution: {
    id: null,
    availabilityTimes: [],
    clientPackages: [{}],
  },
  calendarEl: null,
  serviceProviderName: null,
}

SessionsContainer.propTypes = {
  contribution: PropTypes.shape({
    id: PropTypes.string,
    availabilityTimes: PropTypes.arrayOf(PropTypes.object),
    clientPackages: PropTypes.arrayOf(PropTypes.shape({ isCompleted: PropTypes.bool })),
    paymentInfo: PropTypes.shape({
      paymentOptions: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  calendarEl: PropTypes.shape({}),
  renderActions: PropTypes.func.isRequired,
  setRescheduleMode: PropTypes.func.isRequired,
  executeCalendarScroll: PropTypes.func.isRequired,
  setCurrentRescheduleSession: PropTypes.func.isRequired,
  serviceProviderName: PropTypes.string,
  completionFilter: PropTypes.string,
}

export default SessionsContainer
