import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import MarkunreadIcon from '@material-ui/icons/Markunread'
import DraftsIcon from '@material-ui/icons/Drafts'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { useTheme, useMediaQuery } from '@material-ui/core'

import Select from 'components/UI/Select'
import SelectedChatsState from './SelectedChatsState'
import SelectedChatsReadState from './SelectedChatsReadState'
import SearchFilter from './SearchFilter'
import SortOrderToggle from './SortOrderToggle'
import SortOrder from '../SortOrder'
import DateFilter from '../DateFilter'

import './ChatsListHeader.scss'
import { TOOLTIP } from '../../../../../constants.js'

const ChatsListHeader = ({
  selectedState,
  onSelectedStateChange,
  selectedChatsReadState,
  onReadSelectedChats,
  onUnreadSelectedChats,
  showFavoriteOnly,
  onShowFavoriteOnlyChange,
  searchFilter,
  onSearchFilterChange,
  sortOrder,
  onSortOrderChange,
  dateFilter,
  onDateFilterChange,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className="chats-list-header-wrapper">
      <div className="chats-list-header">
        <div className="chats-list-header__firstControlblock">
          <Checkbox
            className="chats-list-header__select"
            color="primary"
            checked={selectedState === SelectedChatsState.all || selectedState === SelectedChatsState.some}
            indeterminate={selectedState === SelectedChatsState.some}
            onChange={() => {
              const newSelectedState =
                selectedState === SelectedChatsState.some || selectedState === SelectedChatsState.none
                  ? SelectedChatsState.all
                  : SelectedChatsState.none

              onSelectedStateChange(newSelectedState)
            }}
          />
          {selectedChatsReadState !== SelectedChatsReadState.none && (
            <Tooltip
              title={selectedChatsReadState === SelectedChatsReadState.allRead ? 'Mark as unread' : 'Mark as read'}
              placement="top"
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <IconButton
                className="chats-list-header__mark-as-read"
                color="primary"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  if (selectedChatsReadState === SelectedChatsReadState.allRead) {
                    onUnreadSelectedChats()
                  } else {
                    onReadSelectedChats()
                  }
                }}
              >
                {selectedChatsReadState === SelectedChatsReadState.allRead ? <MarkunreadIcon /> : <DraftsIcon />}
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={showFavoriteOnly ? 'Show all' : 'Show favorites only'}
            placement="top"
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          >
            <IconButton
              className="chats-list-header__show-favorite"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => onShowFavoriteOnlyChange(!showFavoriteOnly)}
              color="inherit"
            >
              {showFavoriteOnly ? <StarBorderIcon /> : <StarIcon />}
            </IconButton>
          </Tooltip>
          {!isMobile && (
            <SearchFilter
              className="chats-list-header__search-filter"
              searchFilter={searchFilter}
              onSearchFilterChange={onSearchFilterChange}
            />
          )}
        </div>
        <div className="chats-list-header__toolbar chats-list-header-toolbar">
          <SortOrderToggle sortOrder={sortOrder} onSortOrderChange={onSortOrderChange} />
          <div className="chats-list-header-toolbar__separator" />
          <Select
            className="chats-list-header-toolbar__date-filter"
            items={[
              { title: 'All', value: DateFilter.all },
              { title: 'This week', value: DateFilter.thisWeek },
            ]}
            value={dateFilter}
            onChange={({ target }) => onDateFilterChange(target.value)}
          />
        </div>
      </div>
      {isMobile && (
        <SearchFilter
          className="search-filter-mobile"
          searchFilter={searchFilter}
          onSearchFilterChange={onSearchFilterChange}
        />
      )}
    </div>
  )
}

ChatsListHeader.propTypes = {
  selectedState: PropTypes.oneOf([SelectedChatsState.all, SelectedChatsState.some, SelectedChatsState.none]).isRequired,
  onSelectedStateChange: PropTypes.func.isRequired,
  selectedChatsReadState: PropTypes.oneOf([
    SelectedChatsReadState.allRead,
    SelectedChatsReadState.someUnread,
    SelectedChatsReadState.none,
  ]).isRequired,
  onReadSelectedChats: PropTypes.func.isRequired,
  onUnreadSelectedChats: PropTypes.func.isRequired,
  showFavoriteOnly: PropTypes.bool.isRequired,
  onShowFavoriteOnlyChange: PropTypes.func.isRequired,
  searchFilter: PropTypes.string.isRequired,
  onSearchFilterChange: PropTypes.func.isRequired,
  sortOrder: PropTypes.oneOf([SortOrder.newestFirst, SortOrder.oldestFirst]).isRequired,
  onSortOrderChange: PropTypes.func.isRequired,
  dateFilter: PropTypes.oneOf([DateFilter.thisWeek, DateFilter.all]).isRequired,
  onDateFilterChange: PropTypes.func.isRequired,
}

export default ChatsListHeader
