import React from 'react';
import Modal from 'components/UI/Modal';
import CreatePost from '../CreatePost/CreatePost';

const EditModal = ({ isOpen, onModalClose, item }) => {
  return (
    <Modal
      title="Edit post"
      isOpen={isOpen}
      onCancel={onModalClose}
      disableFooter={true}
      hiddenCancel={true}
      disableConfirm={true}
    >
      <CreatePost item={item} isEdit={true} onSubmit={onModalClose} />
    </Modal>
  );
};

export default EditModal;
