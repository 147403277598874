import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { SuccessfulPurchaseModal } from 'components/Modals/SuccessfulPurchaseModal';
import { fetchClientContribution, rescheduleSessionForClient } from 'actions/contributions';
import { PaymentStatus } from 'helpers/constants';
import { useHttp, useVideoChat } from 'hooks';
import { getTimePeriodsForAvailability } from 'utils/datesAndMoney';

import JoinSessionTime from 'components/SessionButtons/JoinSessionTime';
import Modal from 'components/UI/Modal';
import { BodyText } from 'components/UI/Text/TextStyles';
import TextField from 'components/UI/TextField';
import styled from 'styled-components';
import { TIMEZONES } from 'constants.js';

import LaunchRecordedSessionVideo from 'components/SessionButtons/LaunchRecordedSessionVideo';
import SessionsContainer from '../SessionsContainer/SessionsContainer';
import CalendarContributionView from '../../../components/CalendarContributionView/CalendarContributionView';
import BookModal from '../../../components/BookModal';
import PurchaseModal from '../../../components/PurchaseModal';
import ProceedModal from '../../../components/ProceedModal';
import RefuseModal from '../../../components/RefuseModal';
import JoinSessionTimeModal from './JoinSessionTimeModal';

import './ClientOneToOneSessions.scss';
import SlotsModal from '../../../components/SlotsModal';
import { FILTER_TABS_OPTIONS } from '../../constants';
import TabSelector from '../../../../../components/UI/TabSelector';

const scrollToCalendar = ref => window.scrollTo(0, ref.current.offsetTop);

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const ClientOneToOneSessions = ({
  user,
  contribution,
  contribution: { id, title, availabilityTimes = [], durations, serviceProviderName, paymentInfo, packagePurchases },
  contributionTimes,
  rescheduleChosenSession,
}) => {
  const dispatch = useDispatch();

  const [isBookModalShowed, setBookModalShowed] = useState(false);
  const [isPurchaseModalShowed, setPurchaseModalShowed] = useState(false);
  const [isProceedModalShowed, setProceedModalShowed] = useState(false);
  const [isRefuseModalShowed, setRefuseModalShowed] = useState(false);
  const [isOnlyPackageBookingAllowedModalShowed, setIsOnlyPackageBookingAllowedModalShowed] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [sessionPeriodSelected, setSessionPeriodSelected] = useState({});
  const [isPackage, setIsPackage] = useState(false);
  const [isOpenSuccessPurchaseModal, setSuccessPurchaseModal] = useState(false);
  const [isNotLoggedUser, setIsNotLoggedUser] = useState(false);
  const [isSubmitPurchaseModalNow, setIsSubmitPurchaseModalNow] = useState(false);
  const [rescheduleMode, setRescheduleMode] = useState(false);
  const [isCalendarRescheduleModalOpen, setCalendarRescheduleModal] = useState(false);
  const [currentRescheduleSession, setCurrentRescheduleSession] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [rescheduleTo, setRescheduleTo] = useState(null);
  const [calendarRescheduleModalStatus, setCalendarRescheduleModalStatus] = useState(null);

  const calendarEl = useRef(null);

  const { token } = useVideoChat();
  const { request } = useHttp();

  const calendarRef = useRef(null);
  const dateToShow = currentRescheduleSession?.startTime;
  const executeScroll = () => {
    scrollToCalendar(calendarRef);
  };

  const handleCalendarRescheduleSubmit = () => {
    const rescheduleData = {
      rescheduleFromId: currentRescheduleSession.id,
      rescheduleToId: rescheduleTo.id,
      contributionId: id,
      note: messageText,
      offset: rescheduleTo.offset,
    };
    rescheduleChosenSession(rescheduleData).then(() => {
      setCalendarRescheduleModal(false);
      setCurrentRescheduleSession(null);
      setMessageText(null);
      setCalendarRescheduleModalStatus(rescheduleTo);
    });
  };

  const handleCalendarRescheduleCancel = () => {
    setCalendarRescheduleModal(false);
    setRescheduleTo(null);
  };

  useEffect(() => {
    const sessionsRefresher = setInterval(() => {
      return !token && dispatch(fetchClientContribution(id));
    }, 10000);

    return () => {
      clearInterval(sessionsRefresher);
    };
  }, [token, dispatch, id]);

  const duration = durations && durations.length ? durations[0] : 60; // temporary becauce there is null
  const events = getTimePeriodsForAvailability({
    availabilityPeriods: contributionTimes,
    duration,
    title,
    serviceProviderName,
  }).map(e => {
    const { isBooked } = e;
    if (!isBooked) {
      return e;
    }
    const bookedTime = contributionTimes.find(at => at.id === e.id).bookedTimes.find(bt => bt.id === e.bookedTimeId);

    return {
      ...e,
      participantId: bookedTime.participantId,
      videoRoomInfo: bookedTime.videoRoomInfo,
    };
  });

  const toggleSuccessPurchaseModal = () => setSuccessPurchaseModal(prevState => !prevState);

  const handlePayment = useCallback(
    ({ status }) => {
      if ([PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased].includes(status)) {
        if (isNotLoggedUser) {
          setIsNotLoggedUser(false);
          return setIsSubmitPurchaseModalNow(true);
        }
        setPurchaseModalShowed(true);
      }

      if ([PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(status)) {
        setProceedModalShowed(true);
      }

      if (!status) {
        dispatch(fetchClientContribution(id));
      }
    },
    [setProceedModalShowed, dispatch, id, isNotLoggedUser],
  );

  const handleBookModalOpen = useCallback(
    event => {
      setSessionPeriodSelected(Object.assign(sessionPeriodSelected, event));
      setBookModalShowed(true);
    },
    [setBookModalShowed, setSessionPeriodSelected, sessionPeriodSelected],
  );

  const handleBookModalSubmit = useCallback(async () => {
    if (isEmpty(user)) {
      setIsNotLoggedUser(true);
      setPurchaseModalShowed(true);
      return;
    }

    setBookModalShowed(false);

    const { id: availiableId, offset, start, end } = sessionPeriodSelected;

    try {
      const reqData = {
        contributionId: id,
        AvailabilityTimeId: availiableId,
        offset,
      };
      const paymentDataResponse = await request('/api/purchase/one-to-one', 'POST', reqData);
      setPaymentData(paymentDataResponse);
      setBookModalShowed(false);
      handlePayment(paymentDataResponse);
    } catch (e) {
      setErrorMessage(e.response.data.message);
      setRefuseModalShowed(true);
    }
  }, [user, sessionPeriodSelected, id, request, handlePayment]);

  const proseedHandlePurchase = useCallback(() => {
    handleBookModalSubmit();
  }, [handleBookModalSubmit]);

  const handleBookModalCancel = useCallback(() => {
    setBookModalShowed(false);
  }, [setBookModalShowed]);

  const handlePurchaseProceedModalCancel = useCallback(
    (releasePaymentIntent = true) => {
      setPurchaseModalShowed(false);
      setProceedModalShowed(false);
      if (isPackage) {
        setIsPackage(false);
      }

      if (!isPackage && paymentData && paymentData.bookedTimeIds && paymentData.bookedTimeIds.length > 0) {
        if (releasePaymentIntent) {
          request('/api/purchase/cancelOneToOneReservation', 'POST', {
            contributionId: id,
            bookedTimeId: paymentData.bookedTimeIds[0],
            created: paymentData.created,
          }).catch(console.dir);
        }
      }
    },
    [id, paymentData, setPurchaseModalShowed, setProceedModalShowed, isPackage, setIsPackage],
  );

  const handlePurchaseProceedModalSubmit = useCallback(() => {
    setPurchaseModalShowed(false);
    setProceedModalShowed(false);
    toggleSuccessPurchaseModal();
    if (isPackage) {
      setIsPackage(false);
    }
    setTimeout(() => {
      dispatch(fetchClientContribution(id));
    }, 1500);
  }, [dispatch, isPackage, id]);

  const handleRefuseModal = useCallback(() => {
    setRefuseModalShowed(false);
  }, [setRefuseModalShowed]);

  const [selectedSessionTime, setSelectedSessionTime] = useState(null);

  const OnlyPackageBookingAllowedModalBody = useMemo(() => {
    return `${title} is available as a package of sessions. Please purchase a package prior to selecting your session time(s).`;
  }, [title]);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [isSlotModalOpen, setSlotModalShowed] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const closeModalHandler = useCallback(() => {
    setSlotModalShowed(false);
  }, []);
  const onCalendarDrillDownHandler = useCallback(day => {
    setSelectedDate(moment(day));
    setSlotModalShowed(true);
  }, []);
  const onCalendarEventSelectHandler = useCallback(event => {
    setSelectedDate(moment(moment(event.start)));
    setSlotModalShowed(true);
  }, []);
  const onSelectEvent = useCallback(
    event => {
      const { isBooked, participantId, videoRoomInfo, start, bookedTimeId, offset } = event;

      const availableToReschedule = moment(start).isAfter(moment().add(24, 'h'));
      if (rescheduleMode && availableToReschedule && !isBooked) {
        setRescheduleMode(false);
        setCalendarRescheduleModal(true);
        setSlotModalShowed(false);
        setRescheduleTo(event);
        return;
      }

      if (!isBooked) {
        const isSessionPurchaseAllowed =
          paymentInfo && paymentInfo.paymentOptions && paymentInfo.paymentOptions.includes('PerSession');
        const isPackageSessionsAvailable =
          packagePurchases && packagePurchases[0] && packagePurchases[0].freeSessionNumbers;
        if (isSessionPurchaseAllowed || isPackageSessionsAvailable) {
          handleBookModalOpen(event);
        } else {
          setIsOnlyPackageBookingAllowedModalShowed(true);
        }
        setSlotModalShowed(false);
        return;
      }
      const isBookedByMe = participantId === user.id;
      const isLaunched = !!videoRoomInfo && videoRoomInfo.isRunning;
      if (!isBookedByMe || !isLaunched) {
        setRefuseModalShowed(true);

        return;
      }

      setSelectedSessionTime({
        id: bookedTimeId,
        title: event.title,
        start: event.start,
        end: event.end,
        serviceProviderName: event.serviceProviderName,
        offset,
      });
    },
    [paymentInfo, packagePurchases],
  );
  const [selectedFilterTab, setSelectedFilterTab] = useState(FILTER_TABS_OPTIONS[0].value);

  return (
    <>
      <TabSelector options={FILTER_TABS_OPTIONS} selected={selectedFilterTab} onChange={setSelectedFilterTab} />
      <SessionsContainer
        contribution={contribution}
        calendarEl={calendarEl}
        executeCalendarScroll={executeScroll}
        setRescheduleMode={setRescheduleMode}
        setCurrentRescheduleSession={setCurrentRescheduleSession}
        serviceProviderName={serviceProviderName}
        completionFilter={selectedFilterTab}
        renderActions={session => {
          const { videoRoomInfo } = session;
          const isLaunched = !!videoRoomInfo && videoRoomInfo.isRunning;
          const isHasRecordings = session.recordingInfos.length > 0;
          return (
            <>
              {!session.isCompleted && (
                <div className="client-course-session-time-actions">
                  <JoinSessionTime
                    user={user}
                    contribution={contribution}
                    sessionTime={session}
                    isLaunched={isLaunched}
                  />
                </div>
              )}
              {isHasRecordings && (
                <LaunchRecordedSessionVideo
                  contribution={contribution}
                  session={null}
                  sessionTime={session}
                  mobileView={mobileView}
                />
              )}
            </>
          );
        }}
      />
      <div id="contribution-view-calendar" className="contribution-view-calendar" ref={calendarEl}>
        <CalendarContributionView
          events={events}
          onDrillDown={onCalendarDrillDownHandler}
          onSelectEvent={onCalendarEventSelectHandler}
          dateToShow={dateToShow}
          calendarRef={calendarRef}
          timeZoneId={contribution.timeZoneId}
        />
      </div>
      {isSlotModalOpen && (
        <SlotsModal
          isOpen={isSlotModalOpen}
          onClose={closeModalHandler}
          selectedDate={selectedDate}
          contributionId={id}
          contributionTimeZoneId={contribution.timeZoneId}
          title={title}
          serviceProviderName={serviceProviderName}
          duration={duration}
          onSubmit={onSelectEvent}
        />
      )}
      {isBookModalShowed && (
        <BookModal
          isOpen={isBookModalShowed}
          handleModalCancel={handleBookModalCancel}
          handleModalSubmit={handleBookModalSubmit}
          sessionInfo={sessionPeriodSelected}
          timeZoneId={contribution.timeZoneId}
        />
      )}

      {isPurchaseModalShowed && (
        <PurchaseModal
          isOpen={isPurchaseModalShowed}
          onClose={handlePurchaseProceedModalCancel}
          onSubmit={handlePurchaseProceedModalSubmit}
          isPackage={isPackage}
          oneToOne
          paymentDataOneToOne={paymentData}
          proseedHandlePurchase={isNotLoggedUser && proseedHandlePurchase}
          submitNow={isSubmitPurchaseModalNow}
        />
      )}

      {isProceedModalShowed && (
        <ProceedModal
          isOpen={isProceedModalShowed}
          onClose={handlePurchaseProceedModalCancel}
          onSubmit={handlePurchaseProceedModalSubmit}
        />
      )}

      {isRefuseModalShowed && (
        <RefuseModal
          isOpen={isRefuseModalShowed}
          onClose={handleRefuseModal}
          onSubmit={handleRefuseModal}
          message={errorMessage}
        />
      )}

      {isOnlyPackageBookingAllowedModalShowed && (
        <Modal
          hiddenCancel
          isOpen={isOnlyPackageBookingAllowedModalShowed}
          onCancel={() => setIsOnlyPackageBookingAllowedModalShowed(false)}
          onSubmit={() => setIsOnlyPackageBookingAllowedModalShowed(false)}
          title="Please purchase package"
        >
          <BodyText>{OnlyPackageBookingAllowedModalBody}</BodyText>
        </Modal>
      )}

      {selectedSessionTime && (
        <JoinSessionTimeModal
          user={user}
          contribution={contribution}
          sessionTime={selectedSessionTime}
          open={!!selectedSessionTime}
          onOpenChange={open => setSelectedSessionTime(session => (open ? session : null))}
        />
      )}

      {isOpenSuccessPurchaseModal && (
        <SuccessfulPurchaseModal
          isOpen={isOpenSuccessPurchaseModal}
          handleOpen={toggleSuccessPurchaseModal}
          handleClose={toggleSuccessPurchaseModal}
        />
      )}
      {isCalendarRescheduleModalOpen && (
        <Modal
          isOpen={isCalendarRescheduleModalOpen}
          onCancel={handleCalendarRescheduleCancel}
          onSubmit={handleCalendarRescheduleSubmit}
          title="Confirm your new session time"
          hiddenCancel
          submitTitle="Confirm Reschedule"
        >
          <BodyText>
            By clicking Confirm, you will be updating the session time with {serviceProviderName}. It’s your
            responsibility to confirm with {serviceProviderName} that this new time also works for them.
          </BodyText>
          <StyledTextField
            placeholder="Optional. What is the reason for rescheduling? This information will be shared with your coach"
            multiline
            rowsMax={6}
            value={messageText}
            onChange={e => setMessageText(e.target.value)}
          />
        </Modal>
      )}
      {calendarRescheduleModalStatus && (
        <Modal
          isOpen={calendarRescheduleModalStatus}
          onCancel={() => {
            setCalendarRescheduleModalStatus(null);
          }}
          onSubmit={() => {
            setCalendarRescheduleModalStatus(null);
          }}
          title="Reschedule status"
          hiddenCancel
          submitTitle="Ok"
        >
          <BodyText>
            Your new session time with {serviceProviderName} on{' '}
            {moment(calendarRescheduleModalStatus?.start).format('LL [at] LT z')}
            {TIMEZONES[user?.timeZoneId]} is confirmed.
          </BodyText>
        </Modal>
      )}
    </>
  );
};

ClientOneToOneSessions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    availabilityTimes: PropTypes.arrayOf(PropTypes.object),
    durations: PropTypes.arrayOf(PropTypes.number),
    serviceProviderName: PropTypes.string,
    purchaseStatus: PropTypes.string.isRequired,
  }).isRequired,
  rescheduleChosenSession: PropTypes.func.isRequired,
  contributionTimes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ contributions }) => ({
  contributionTimes: contributions?.contributionTimes,
});

const actions = {
  rescheduleChosenSession: rescheduleSessionForClient,
};

export default connect(mapStateToProps, actions)(ClientOneToOneSessions);
