import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import * as R from 'ramda';

import { colors } from 'utils/styles';
import { PageSubtitle } from 'components/UI/Text/TextStyles';
import { getTimePeriodsForSessions } from 'utils/datesAndMoney';
import Loader from 'components/UI/Loader';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';

const localizer = momentLocalizer(moment);
const allViews = R.compose(R.values, R.omit(['AGENDA', 'WORK_WEEK']))(Views);

const Calendar = ({ values, loadingTimes }) => {
  const eventsList = getTimePeriodsForSessions(values.sessions);

  return (
    <>
      <PageSubtitle>Client Session Availability</PageSubtitle>
      {loadingTimes && (
        <div className="custom-calendar" style={{ position: 'relative' }}>
          <div className="rbc-month-view">
            <div className="">
              <Loader relative />
            </div>
          </div>
        </div>
      )}
      {!loadingTimes && (
        <BigCalendar
          className="custom-calendar"
          localizer={localizer}
          events={eventsList}
          views={allViews}
          defaultView={Views.MONTH}
          step={30}
          showMultiDayTimes
          defaultDate={new Date()}
          startAccessor="start"
          endAccessor="end"
          formats={{ eventTimeRangeFormat: () => '' }}
          eventPropGetter={({ color }) => {
            const newStyle = {
              backgroundColor: color || colors.darkOceanBlue,
              minHeight: 24,
              fontSize: 14,
            };

            return {
              className: '',
              style: newStyle,
            };
          }}
        />
      )}
    </>
  );
};

Calendar.propTypes = {
  values: PropTypes.shape({
    sessions: PropTypes.arrayOf(PropTypes.object),
  }),
  loadingTimes: PropTypes.bool,
};

Calendar.defaultProps = {
  values: {},
  loadingTimes: false,
};

export default Calendar;
