import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { useHttp, useAccount } from 'hooks';

import Loader from 'components/UI/Loader';
import Popup from 'components/Popup/Popup';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import Button from 'components/FormUI/Button';
import * as userActions from 'actions/user';

import '../../Notifications.scss';

const SubcscribeCard = () => {
  const dispatch = useDispatch();
  const { user } = useAccount();
  const [isEnabled, setIsEnabled] = useState(!!user?.isEmailNotificationsEnabled);
  const [popupText, setPopupText] = useState(null);
  const { request, loading } = useHttp();

  if (loading) {
    return <Loader />;
  }

  const handleClosePopup = () => {
    setPopupText(null);
  };

  if (popupText) {
    return <Popup title="Error" text={popupText} open={!!popupText} onCloseText="OK" onClose={handleClosePopup} />;
  }

  const onSave = () => {
    const {
      accountId,
      email,
      password,
      securityAnswers,
      isEmailConfirmed,
      isPhoneConfirmed,
      isAccountLocked,
      isPushNotificationsEnabled,
    } = user;
    request(`/Account/${accountId}`, 'PUT', {
      password,
      email,
      securityAnswers,
      isEmailConfirmed,
      isPhoneConfirmed,
      isAccountLocked,
      isPushNotificationsEnabled,
      isEmailNotificationsEnabled: isEnabled,
      id: accountId,
    })
      .then(() => request(`/Account/${user.accountId}`).then(R.compose(dispatch, userActions.getAccount)))
      .catch(() => {
        setPopupText('Something went wrong, try again.');
      });
  };

  const onSelectedChange = checked => {
    setIsEnabled(checked);
  };

  return (
    <Grid item xs={10} classes={{ root: 'card-container' }}>
      <Card maxHeight>
        <CardHeader>
          <PageTitleSecond>Email Notifications</PageTitleSecond>
        </CardHeader>
        <CardBody className="card-body-container card-body-container__mobile">
          <FormControlLabel
            className="card-body-container__text"
            control={
              <Checkbox
                checked={isEnabled}
                onChange={({ target: { checked } }) => onSelectedChange(checked)}
                color="primary"
              />
            }
            label="Subscribe to Cohere email notifications"
          />
          <Button
            disabled={!!user?.isEmailNotificationsEnabled === isEnabled}
            autoWidth
            variant="primary"
            onClick={onSave}
          >
            Save
          </Button>
        </CardBody>
      </Card>
    </Grid>
  );
};

export default SubcscribeCard;
