import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Icon } from '@mdi/react'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import styled from 'styled-components'

import { StyledUl } from './List'

const StyledLi = styled.li`
  padding: 10px 10px 10px 0;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ isList }) => isList && `cursor: pointer;`}
`

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 1.1125rem;
  font-weight: 500;
  line-height: 1.35;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);

  &:hover {
    text-decoration: underline;
  }

  ${StyledUl} > ${StyledUl} & {
    font-weight: normal;
  }
`

const StyledEndAdornment = styled.div`
  margin: 0 auto 0 10px;
`

function Item({ children, endAdornment, isList, to, title }) {
  const [show, setShow] = useState(true)
  const linkProps = to ? { to } : { to: {} }

  return (
    <>
      <StyledLi onClick={() => setShow((prev) => !prev)} isList={isList}>
        <StyledLink {...linkProps}>{title}</StyledLink>
        {endAdornment && <StyledEndAdornment>{endAdornment}</StyledEndAdornment>}
        {isList && <Icon size={1} path={show ? mdiChevronUp : mdiChevronDown} />}
      </StyledLi>
      {isList && show && children}
    </>
  )
}

Item.propTypes = {
  children: PropTypes.element,
  endAdornment: PropTypes.node,
  isList: PropTypes.bool,
  to: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
}

Item.defaultProps = {
  children: null,
  endAdornment: null,
  isList: false,
  to: null,
}

export default Item
