import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useAccount } from 'hooks';
import Loader from 'components/UI/Loader';
import { createClientJourneyContributionFrom } from 'services/dataContracts/factories';
import * as contributionService from 'services/contributions.service';
import * as purchaseService from 'services/purchase.service';

import IntroScreenClient from './IntroScreenClient';
import ClientJourney from './ClientJourney';

const ClientDashboardContainer = () => {
  const { user } = useAccount();
  const [purchasedContributions, setPurchasedContributions] = useState(null);
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    Promise.all([contributionService.getAllBoughtByUserId(user.id), purchaseService.getCoaches()])
      .then(
        ([
          { upcoming, upcomingTotalCount, past, pastTotalCount, closestClassForBanner, clientDeclinedSubscriptions },
          myCoaches,
        ]) => {
          setCoaches(myCoaches);
          const now = moment();

          const checkIfContributionsStartToday = c => moment(c.sessionTime).isSame(now, 'day');
          const todayContributions = upcoming.thisWeek.filter(checkIfContributionsStartToday);
          const laterThisWeekContributions = upcoming.thisWeek.filter(c => !checkIfContributionsStartToday(c));
          const totalContributionsCount = upcomingTotalCount + pastTotalCount;

          setPurchasedContributions({
            hasContributions: totalContributionsCount > 0,
            upcoming: {
              notBooked: upcoming.notBooked.map(createClientJourneyContributionFrom),
              otherIncompleted: upcoming.otherIncompleted.map(createClientJourneyContributionFrom),
              today: todayContributions.map(createClientJourneyContributionFrom),
              thisWeek: laterThisWeekContributions.map(createClientJourneyContributionFrom),
              thisMonth: upcoming.thisMonth.map(createClientJourneyContributionFrom),
              nextMonth: upcoming.nextMonth.map(createClientJourneyContributionFrom),
              thisYear: upcoming.thisYear.map(createClientJourneyContributionFrom),
              afterThisYear: upcoming.afterThisYear.map(createClientJourneyContributionFrom),
            },
            past: {
              priorYears: past.priorYears.map(createClientJourneyContributionFrom),
              thisYear: past.thisYear.map(createClientJourneyContributionFrom),
              lastMonth: past.lastMonth.map(createClientJourneyContributionFrom),
              thisMonth: past.thisMonth.map(createClientJourneyContributionFrom),
              thisWeek: past.thisWeek.map(createClientJourneyContributionFrom),
            },
            closestClassForBanner,
            clientDeclinedSubscriptions,
          });
        },
      )
      .catch(() =>
        setPurchasedContributions({
          hasContributions: false,
          upcoming: null,
          past: null,
          closestClassForBanner: null,
          clientDeclinedSubscriptions: null,
        }),
      );
  }, [user.id]);

  if (!purchasedContributions) {
    return <Loader />;
  }

  if (!purchasedContributions.hasContributions) {
    return (
      <IntroScreenClient
        onExploreCohere={() => {}}
        closestClassForBanner={purchasedContributions.closestClassForBanner}
      />
    );
  }

  return (
    <ClientJourney
      upcomingContributions={purchasedContributions.upcoming}
      pastContributions={purchasedContributions.past}
      clientDeclinedSubscriptions={purchasedContributions.clientDeclinedSubscriptions}
      hasContributions={purchasedContributions.hasContributions}
      coaches={coaches}
    />
  );
};

export default ClientDashboardContainer;
