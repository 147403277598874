import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { useHttp, useShallowEqualSelector } from 'hooks';
import { setUserNote, clearUserNote } from 'actions/userNotes';
import Loader from 'components/UI/Loader';
import Modal from 'components/UI/Modal';
import NoteModalView from './NoteModalView';

const NoteModalContainer = ({ noteProps: { title, classId, contributionId }, isOpen, onModalClose, onConfirm }) => {
  const dispatch = useDispatch();
  const { request, loading } = useHttp();

  const stateNote = useShallowEqualSelector(state => state?.userNotes?.note);

  useEffect(() => {
    request(`/api/Note/${contributionId}/${classId}`).then(R.compose(dispatch, setUserNote)).catch(console.dir);

    return R.compose(dispatch, clearUserNote);
  }, [dispatch, request, contributionId, classId]);

  const handleSubmit = useCallback(
    ({ note }) => {
      const noteRequest = R.isEmpty(stateNote)
        ? request('/api/Note', 'POST', {
            contributionId,
            classId,
            title,
            textContent: note,
          })
        : request('/api/Note', 'PUT', {
            id: stateNote?.id,
            contributionId,
            classId,
            title: stateNote?.title || title,
            textContent: note,
          });

      noteRequest.then(R.compose(dispatch, setUserNote)).then(onConfirm);
    },
    [stateNote, request, contributionId, classId, title, dispatch, onConfirm],
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal
      title="Add a Note"
      isOpen={isOpen}
      form="note-form"
      onCancel={onModalClose}
      submitTitle={`${R.isEmpty(stateNote) ? 'Save' : 'Update'} Note`}
    >
      <NoteModalView note={stateNote} onSubmit={handleSubmit} />
    </Modal>
  );
};

NoteModalContainer.propTypes = {
  noteProps: PropTypes.shape({
    title: PropTypes.string,
    classId: PropTypes.string.isRequired,
    contributionId: PropTypes.string.isRequired,
  }).isRequired,
  onModalClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default NoteModalContainer;
