import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import TextBlock from './TextBlock';

const DATE_VIEW = 'MMMM Do YYYY';

const formatTime = date => {
  return moment(date).format(DATE_VIEW);
};

const EnrollmentBlock = ({ enrollment: { fromDate, toDate } }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Card mobileView={mobileView} maxHeight>
      <CardHeader mobileView={mobileView}>
        <PageTitleSecond mobileView={mobileView}>Enrollment Dates</PageTitleSecond>
      </CardHeader>
      <CardBody mobileView={mobileView}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <TextBlock mobileView={mobileView} image={null} title="From" text={formatTime(fromDate)} />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextBlock mobileView={mobileView} image={null} title="To" text={formatTime(toDate)} />
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

EnrollmentBlock.propTypes = {
  enrollment: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }).isRequired,
};

export default EnrollmentBlock;
