import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import VideoButton from 'components/UI/ListButtons/VideoButton';
import EditButton from 'components/UI/ListButtons/EditButton';
import ChatButton from 'components/UI/ListButtons/ChatButton';
import ViewButton from 'components/UI/ListButtons/ViewButton';
import { formatMoney } from 'utils/datesAndMoney';
import { getContributionStatusView } from 'utils/getContributionStatusView';
import { ContributionStatus } from 'helpers/constants';
import { useRouter } from 'hooks';
import { useRecordModal } from 'hooks/useSessionRecordModal';
import ShareModal from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import ShareIcon from 'assets/share-icon.svg';
import { shareViaEmail } from 'services/contributions.service';
import { TooltipWrapper } from '../UI/TooltipWrapper';

const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})(TableCell);
const StyledShareButton = styled(Button)`
  float: right;
  margin-top: 5px;
  text-transform: none;
`;

const Icon = styled.img`
  vertical-align: middle;
  object-fit: contain;
`;

const TooltipText = 'There are no clients for this contribution yet';

export const Contribution = ({
  id,
  type,
  closestSession,
  studentsNumber,
  title,
  isArchived,
  earnedRevenue,
  status,
  setPopupText,
  reasonDescription,
  onLaunchSession,
}) => {
  const { history } = useRouter();

  const onSubmit = () => {
    onLaunchSession(
      id,
      type,
      closestSession?.classGroupId,
      closestSession?.classId,
      closestSession?.chatSid,
      closestSession?.title,
      { RecordParticipantsOnConnect: true },
    );
  };
  const onCancel = () => {
    onLaunchSession(
      id,
      type,
      closestSession?.classGroupId,
      closestSession?.classId,
      closestSession?.chatSid,
      closestSession?.title,
      { RecordParticipantsOnConnect: false },
    );
  };
  const modalOptions = {
    onSubmit,
    onCancel,
    classId: closestSession?.classId,
    contributionType: type,
    contributionId: id,
    liveVideoServiceProvider: closestSession?.liveVideoServiceProvider,
  };
  const { RecordModal, launchRecodModal } = useRecordModal(modalOptions);
  const url =
    status === ContributionStatus.unfinished ? `/edit-contribution/${id}/basic` : `/contribution-view/${id}/about`;
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });
  const [modalOpened, setModalOpened] = useState(false);
  const handleModalOpen = useCallback(() => {
    setModalOpened(true);
  }, [setModalOpened]);
  const handleModalClose = useCallback(() => {
    setModalOpened(false);
  }, [setModalOpened]);
  const handleModalSubmit = useCallback(
    emails => {
      shareViaEmail({ contributionId: id, emailAddresses: emails })
        .then(() => {
          handleModalClose();
        })
        .catch(err => {
          setEmailsError({ isError: true, errorMessage: err?.response?.data?.message });
        });
    },
    [handleModalClose],
  );

  return (
    <>
      <TableRow>
        <NarrowTableCell component="th" scope="row">
          <Link to={url}>{title}</Link>
        </NarrowTableCell>
        {!isArchived && (
          <NarrowTableCell>
            <TooltipWrapper tooltipText={TooltipText} isShow={!closestSession}>
              <VideoButton handleClick={launchRecodModal} disabled={!closestSession} />
            </TooltipWrapper>
            {closestSession?.title}
          </NarrowTableCell>
        )}
        <NarrowTableCell align="center">
          <StyledShareButton startIcon={<Icon src={ShareIcon} />} onClick={handleModalOpen} />
        </NarrowTableCell>
        <NarrowTableCell align="center">{studentsNumber}</NarrowTableCell>
        <NarrowTableCell align="center">${formatMoney(earnedRevenue)}</NarrowTableCell>
        <NarrowTableCell>
          <TooltipWrapper tooltipText={TooltipText} isShow={!closestSession}>
            <ChatButton
              disabled={!closestSession}
              handleClick={() =>
                closestSession?.chatSid &&
                history.push(`/conversations/all`, {
                  preferredConversationId: closestSession.chatSid,
                })
              }
            />
          </TooltipWrapper>
        </NarrowTableCell>
        {!isArchived && (
          <NarrowTableCell>
            <Link to={`/edit-contribution/${id}`}>
              <EditButton />
            </Link>
          </NarrowTableCell>
        )}
        <NarrowTableCell>
          {getContributionStatusView(status)}
          {status === ContributionStatus.rejected && <ViewButton handleClick={() => setPopupText(reasonDescription)} />}
        </NarrowTableCell>
      </TableRow>
      {modalOpened && (
        <ShareModal
          isOpen={modalOpened}
          onSubmit={handleModalSubmit}
          onModalClose={handleModalClose}
          emailsError={emailsError}
          setEmailsError={setEmailsError}
          contributionId={id}
          type={type}
        />
      )}
      <RecordModal />
    </>
  );
};

Contribution.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  closestSession: PropTypes.shape({
    chatSid: PropTypes.string,
  }),
  studentsNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isArchived: PropTypes.bool,
  earnedRevenue: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  setPopupText: PropTypes.func.isRequired,
  reasonDescription: PropTypes.string,
  onLaunchSession: PropTypes.func.isRequired,
};

Contribution.defaultProps = {
  closestSession: undefined,
  isArchived: undefined,
  reasonDescription: undefined,
};
