import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from 'components/FormUI/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Formik, Form } from 'formik';
import ContributionViewMainContainer from 'components/Containers/ContributionViewMainContainer';
import Button from 'components/FormUI/Button';
import TextArea from 'components/FormUI/TextArea';
import { useHttp, useRouter } from 'hooks';
import { ContributionStatus } from 'helpers/constants';

const useStyles = makeStyles({
  checkboxLabel: {
    fontSize: 13.7,
    fontFamily: 'Avenir',
    padding: '0 9px',
  },
});

const StyledAppovePanel = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
  background-color: #ffffff;
  transition: bottom 0.25s ease-in-out;

  ${({ hidePanel }) =>
    hidePanel &&
    css`
      bottom: -110px;
    `}
`;

const StyledHideButton = styled(Button)`
  position: absolute;
  right: 20px;
  bottom: 120px;
  padding: 10px;
  border-radius: 50%;
  min-width: auto;
  outline: none !important;
  box-shadow: 0 4px 6px 0 rgba(157, 157, 157, 0.9);
`;

const StyledApproveContantWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

const StyledDenyBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 24px;
`;

const StyledApproveBlock = styled.div`
  margin: -24px 0;
  padding: 24px 0 24px 24px;
  border-left: 1px solid #e7e7e7;
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  text-align: right;
`;

const ContributionViewAdminPage = ({ adminReviewNotes, status }) => {
  const {
    params: { id },
  } = useRouteMatch();
  const [isPanelHidden, setPanelHidden] = useState(false);

  const { request } = useHttp();
  const classes = useStyles();
  const { history } = useRouter();

  const handleRequest = useCallback(
    async body => {
      await request(`/Contribution/ChangeStatus/${id}`, 'POST', body);
      history.push('/admin/contributions/review');
    },
    [id, history, request],
  );

  const handleDenySubmit = useCallback(
    values => {
      const { note, isResubmissionVal } = values;
      const status = isResubmissionVal ? 'ChangeRequired' : 'Rejected';
      const body = {
        status,
        description: note,
      };

      handleRequest(body);
    },
    [handleRequest],
  );

  const handleApproveSubmit = useCallback(
    e => {
      e.preventDefault();
      const body = {
        status: 'Approved',
      };

      handleRequest(body);
    },
    [handleRequest],
  );

  const hanldeTogglePanel = () => {
    setPanelHidden(isHidden => !isHidden);
  };

  return (
    <ContributionViewMainContainer>
      <StyledAppovePanel hidePanel={isPanelHidden}>
        <StyledHideButton type="button" variant="primary" onClick={hanldeTogglePanel}>
          {isPanelHidden ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </StyledHideButton>
        <StyledApproveContantWrapper>
          <Formik
            enableReinitialize
            initialValues={{
              note:
                adminReviewNotes?.length && status === ContributionStatus.rejected
                  ? adminReviewNotes[adminReviewNotes.length - 1]?.description
                  : '',
              isResubmissionVal: false,
            }}
            onSubmit={handleDenySubmit}
          >
            {({ values, handleChange }) => (
              <StyledDenyBlock>
                <StyledForm>
                  <Grid container alignItems="center">
                    <Grid item md={4} xs={12}>
                      <Button type="submit" variant="primary">
                        Deny
                      </Button>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <StyledFormControlLabel
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                        checked={values.isResubmissionVal}
                        onChange={handleChange('isResubmissionVal')}
                        control={<Checkbox color="primary" />}
                        label="Allow Resubmission"
                        name="isResubmissionVal"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextArea placeholder="Note" name="note" rows="1" fullWidth />
                    </Grid>
                  </Grid>
                </StyledForm>
              </StyledDenyBlock>
            )}
          </Formik>

          <form onSubmit={handleApproveSubmit}>
            <StyledApproveBlock>
              <Button type="submit" onClick={handleApproveSubmit}>
                Approve
              </Button>
            </StyledApproveBlock>
          </form>
        </StyledApproveContantWrapper>
      </StyledAppovePanel>
    </ContributionViewMainContainer>
  );
};

ContributionViewAdminPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = ({ contributions: { activeContribution } }) => ({
  adminReviewNotes: activeContribution?.adminReviewNotes,
  status: activeContribution?.status,
});

export default connect(mapStateToProps)(ContributionViewAdminPage);
