import React, { useCallback, Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { getIn } from 'formik'
import { Icon } from '@mdi/react'
import { mdiPlus, mdiMinusCircleOutline } from '@mdi/js'
import moment from 'moment'
import TimePickerField from 'components/FormUI/TimePickerField'
import Tooltip from '@material-ui/core/Tooltip'
import { useTheme, useMediaQuery } from '@material-ui/core'

import DateTimePicker from 'components/FormUI/DateTimePicker'
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage'
import { toRem } from 'utils/styles'
import { getNextCurrentSessionTime } from 'utils/getSessionTime'

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 10px;
`

const StyledLink = styled.span`
  width: 300px;
  max-width: 100%;
  display: flex;
  align-items: center;
  font-size: ${toRem(16)};
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const SessionTime = ({
  isPrerecorded,
  form,
  push,
  remove,
  sessionIdx,
  timesCount,
  computeDifference,
  isOneToOne,
  videoDuration,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState({})
  const { sessions } = form.values
  const error = getIn(form.errors, `sessions[${sessionIdx}]`)
  const showError = getIn(form.touched, `sessions[${sessionIdx}]`)?.sessionTimes && !!error
  const currentSession = sessions[sessionIdx]

  const addTimesHandler = useCallback(() => {
    const lastSessionTime =
      currentSession.sessionTimes.length > 0 ? currentSession.sessionTimes[currentSession.sessionTimes.length - 1] : {}
    const sessionTime = getNextCurrentSessionTime(isOneToOne, lastSessionTime.startTime)

    push(sessionTime)
  }, [isOneToOne, push, currentSession])

  const removeTimesHandler = useCallback((i) => () => remove(i), [remove])

  const theme = useTheme()
  const mdView = useMediaQuery(theme.breakpoints.down('md'))

  const handleTimeChange = useCallback(
    (value, sessionTimeIdx, timeType) => {
      if (value) {
        const { startTime } = form.values.sessions[sessionIdx].sessionTimes[sessionTimeIdx]
        const currentDate = moment(startTime).format('YYYY-MM-DD')
        const currentTime = moment(`${currentDate} ${value.format('HH:mm:ss')}`).format('YYYY-MM-DDTHH:mm:ss')
        form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].${timeType}`, currentTime)
        if (timeType === 'startTime') {
          const addedTime = 60
          const endTimeValue = value.add(addedTime, 'minutes')
          const newEndTime = moment(`${currentDate} ${endTimeValue.format('HH:mm:ss')}`).format('YYYY-MM-DDTHH:mm:ss')
          form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].endTime`, newEndTime)
        }
      }
    },
    [form, sessionIdx]
  )

  const handleDatePick = (choosedMomentDate, sessionTimeIdx, dateType, timeType) => {
    const choosedDate = choosedMomentDate.format()
    const currentSessionTime = form.values.sessions[sessionIdx].sessionTimes[sessionTimeIdx]
    const startTime = moment(
      `${choosedMomentDate.format('YYYY-MM-DD')} ${moment(currentSessionTime.startTime).format('hh:mm')}`
    ).format('YYYY-MM-DDTHH:mm:ss')
    const endTime = moment(
      `${choosedMomentDate.format('YYYY-MM-DD')} ${moment(currentSessionTime.endTime).format('hh:mm')}`
    ).format('YYYY-MM-DDTHH:mm:ss')

    if (isOneToOne) {
      form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].${dateType}`, choosedDate)

      form.setFieldValue(
        `sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].${timeType}`,
        timeType === 'startTime' ? startTime : endTime
      )
    } else {
      if (isPrerecorded) {
        form.setFieldValue(`sessions[${sessionIdx}].dateAvailable`, choosedDate)
      }
      form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].startDate`, choosedDate)

      form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].endDate`, choosedDate)

      form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].startTime`, startTime)

      form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].endTime`, endTime)
    }
  }

  return (
    <>
      {!!sessions[sessionIdx].sessionTimes?.length && (
        <Grid container spacing={4}>
          {sessions[sessionIdx].sessionTimes.map((sessionTime, i) => {
            const momentStartTime = moment(sessionTime.startTime)
            const momentEndTime = moment(sessionTime.endTime)

            return (
              /* eslint-disable-next-line */
              <Fragment key={i}>
                {isOneToOne ? (
                  <>
                    <Grid item sm={2} xs={12}>
                      <DateTimePicker
                        label="Start Day"
                        type="date"
                        fullWidth
                        name={`sessions[${sessionIdx}].sessionTimes[${i}].startDate`}
                        value={sessionTime.startDate}
                        onChange={(momentDate) => handleDatePick(momentDate, i, 'startDate', 'startTime')}
                        format={mdView ? 'MMM Do' : 'MMMM Do'}
                      />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                      <DateTimePicker
                        label="End Day"
                        type="date"
                        fullWidth
                        name={`sessions[${sessionIdx}].sessionTimes[${i}].endDate`}
                        value={sessionTime.endDate}
                        onChange={(momentDate) => handleDatePick(momentDate, i, 'endDate', 'endTime')}
                        format={mdView ? 'MMM Do' : 'MMMM Do'}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item sm={isPrerecorded ? 6 : 3} xs={12}>
                    <DateTimePicker
                      label={isPrerecorded ? 'Due Date' : 'Date'}
                      type="date"
                      fullWidth
                      name={`sessions[${sessionIdx}].sessionTimes[${i}].startDate`}
                      value={sessionTime.startTime}
                      onChange={(momentDate) => handleDatePick(momentDate, i, 'startDate')}
                    />
                  </Grid>
                )}
                {!isPrerecorded && (
                  <>
                    <Grid item sm={3} xs={12}>
                      <div className="d-flex">
                        <FormControl style={{ width: '100%' }}>
                          <InputLabel htmlFor={`start-time-${i}`} style={{ top: '-20px' }}>
                            From
                          </InputLabel>
                          <TimePickerField
                            onChange={(value) => handleTimeChange(value, i, 'startTime')}
                            name={`start-time-${i}`}
                            id={`tstart-time-${i}`}
                            classes={{
                              root: 'mt-4 mr-3',
                            }}
                            showSecond={false}
                            value={momentStartTime}
                            format="h:mm a"
                            use12Hours
                            minuteStep={15}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <div className="d-flex">
                        <FormControl style={{ width: '100%' }}>
                          <InputLabel htmlFor={`end-time-${i}`} style={{ top: '-20px' }}>
                            To
                          </InputLabel>
                          <TimePickerField
                            onChange={(value) => handleTimeChange(value, i, 'endTime')}
                            name={`end-time-${i}`}
                            id={`tend-time-${i}`}
                            classes={{
                              root: 'mt-4 mr-3',
                            }}
                            showSecond={false}
                            value={momentEndTime}
                            format="h:mm a"
                            use12Hours
                            minuteStep={15}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                  </>
                )}

                <Grid
                  item
                  sm={isOneToOne ? 2 : isPrerecorded ? 6 : 3}
                  xs={12}
                  style={{
                    textAlign: 'right',
                  }}
                  classes={{
                    root: 'd-flex align-items-center',
                  }}
                >
                  {!isOneToOne && !isPrerecorded && (
                    <>
                      Duration:&nbsp;
                      {computeDifference(
                        sessions[sessionIdx].sessionTimes[i].startTime,
                        sessions[sessionIdx].sessionTimes[i].endTime
                      )}
                    </>
                  )}
                  {!isOneToOne && isPrerecorded && <>Duration:&nbsp; {videoDuration}</>}
                  &nbsp;
                  {sessions[sessionIdx].sessionTimes.length > 1 && (
                    <Tooltip
                      className="cursor-pointer"
                      title="Completed session cannot be deleted"
                      open={!!tooltipOpen[i]}
                      arrow
                      onClose={() => {
                        setTooltipOpen({ ...tooltipOpen, [i]: false })
                      }}
                    >
                      <span>
                        <StyledIcon
                          onClick={
                            sessionTime.isCompleted
                              ? () => {
                                  setTooltipOpen({ ...tooltipOpen, [i]: true })
                                }
                              : removeTimesHandler(i)
                          }
                          path={mdiMinusCircleOutline}
                          size={1}
                        />
                      </span>
                    </Tooltip>
                  )}
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
      )}

      {!!sessions[sessionIdx].sessionTimes?.length && <br />}
      {!isPrerecorded && (
        <StyledLink onClick={addTimesHandler}>
          <Icon path={mdiPlus} size={1} />
          {isOneToOne ? 'Add Additional Booking Times' : 'Add Another Time For This Session'}
        </StyledLink>
      )}

      {showError && timesCount === 0 && (
        <>
          <br />
          <CommonErrorMessage align="left" message={error.sessionTimes} />
        </>
      )}
    </>
  )
}

SessionTime.propTypes = {
  form: PropTypes.any.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  isOneToOne: PropTypes.bool,
  sessionIdx: PropTypes.number.isRequired,
  timesCount: PropTypes.number.isRequired,
  computeDifference: PropTypes.func.isRequired,
}

SessionTime.defaultProps = {
  isOneToOne: false,
  isPrerecorded: false,
}
