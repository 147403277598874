import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { useAccount } from 'hooks';
import ShareIcon from 'assets/ic-share.svg';
import { inviteByEmail } from 'services/affiliate.service';
import * as bankAccountsService from 'services/bankAccounts.service';
import { redirectTo } from 'services/links';
import InviteCoachModalContainer from './InviteModal/InviteCoachModalContainer';

const StyledShareButton = styled(Button)`
  float: right;
  margin-top: 5px;
  text-transform: none !important;
`;

const Icon = styled.img`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;

const InvitationCoaches = () => {
  const {
    user: { transfersEnabled },
  } = useAccount();
  const [modalOpened, setModalOpened] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [connectStripeModal, setConnectStripeModal] = useState(false);
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });
  const handleModalSubmit = useCallback(
    emails => {
      inviteByEmail({ emailAddresses: emails })
        .then(() => {
          setModalOpened(false);
          setSuccessPopupOpen(true);
        })
        .catch(err => {
          setEmailsError({ isError: true, errorMessage: err?.response?.data?.message });
        });
    },
    [setModalOpened, setSuccessPopupOpen],
  );
  const handleSuccessPopupSubmit = useCallback(() => setSuccessPopupOpen(false), [setSuccessPopupOpen]);
  const handleConnectStripeModalClose = useCallback(() => setConnectStripeModal(false), [setConnectStripeModal]);
  const handleInviteModalOpen = useCallback(
    () => (transfersEnabled ? setModalOpened(true) : setConnectStripeModal(true)),
    [setConnectStripeModal, setModalOpened],
  );
  const handleConnectStripeModalSubmit = useCallback(
    () =>
      bankAccountsService
        .verifyStripeAccount()
        .then(({ link }) => redirectTo(link))
        .catch(err => {
          console.dir(err);
          setConnectStripeModal(false);
        }),
    [setConnectStripeModal],
  );

  return (
    <Grid item xs={6} alignItems="center" onClick={handleInviteModalOpen}>
      <StyledShareButton startIcon={<Icon src={ShareIcon} />}>Invite Coaches</StyledShareButton>

      <InviteCoachModalContainer
        isOpen={modalOpened}
        onModalClose={() => setModalOpened(false)}
        emailsError={emailsError}
        onSubmit={handleModalSubmit}
        setEmailsError={setEmailsError}
      />

      {successPopupOpen && (
        <Modal
          isOpen={successPopupOpen}
          onCancel={handleSuccessPopupSubmit}
          onSubmit={handleSuccessPopupSubmit}
          title="Invite Sent"
          hiddenCancel
          submitTitle="Ok"
        >
          Your referral link has been successfully sent.
        </Modal>
      )}
      {connectStripeModal && (
        <Modal
          isOpen={connectStripeModal}
          onCancel={handleConnectStripeModalClose}
          onSubmit={handleConnectStripeModalSubmit}
          hiddenCancel
          title="Account Verification Required"
          submitTitle="Verify"
        >
          Please verify your information in order to invite & receive affiliate revenue.
        </Modal>
      )}
    </Grid>
  );
};

export default InvitationCoaches;
