import { useEffect, useState } from 'react';
import moment from 'moment';

import MessageType from '../../../../MessageType';
import getMessageMediaType from './getMessageMediaType';

const MEDIA_CONTENT_URL_EXPIRATION_TIMEOUT = 290 * 1000;

const useMessage = (message, memberProvider) => {
  const [mediaContentUrl, setMediaContentUrl] = useState(null);
  const mediaSid = message.type === MessageType.media && message.media ? message?.media?.sid : null;
  useEffect(() => {
    if (message.media) {
      const updateContentUrl = () => message.media.getContentUrl().then(url => setMediaContentUrl(url));

      updateContentUrl();
      const updateContentUrlIntervalId = setInterval(updateContentUrl, MEDIA_CONTENT_URL_EXPIRATION_TIMEOUT);

      return () => clearInterval(updateContentUrlIntervalId);
    } else {
      setMediaContentUrl(null);
    }
  }, [mediaSid]);

  const author = memberProvider(message.memberSid);

  return {
    index: message.index,
    author: {
      avatarUrl: author.attributes.PreviewImage,
      displayName: author.attributes.Name,
    },
    date: moment(message.timestamp),
    type: message.type,
    text: message.type === MessageType.text ? message.body : null,
    media:
      message.type === MessageType.media && message.media
        ? {
            type: getMessageMediaType(message.media),
            sizeInBytes: message.media.size,
            fileName: message.media.filename,
            contentUrl: mediaContentUrl,
          }
        : null,
  };
};

export default useMessage;
