import styled from 'styled-components';
import CrossIcon from '@material-ui/icons/Clear';
import Button from 'components/FormUI/Button';
import { colors } from '../../utils/styles';

export const StyledButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

export const StyleLabel = styled.label`
  display: block;
  cursor: pointer;
  text-align: center;
  margin: 32px;
  ${({ mobileView }) => mobileView && 'margin: 0'};
  ${({ mobileView }) => mobileView && 'text-align: left'}
`;

export const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

export const StyledCustomTerms = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

export const StyledFileName = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`;

export const StyledCrossIcon = styled(CrossIcon)`
  margin-left: 5px;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledAttachTermsButton = styled(Button)`
  border: none;
`;

export const StyledLink = styled.a`
  color: ${colors.darkOceanBlue};
  font-weight: 600;

  &:hover {
    color: ${colors.darkOceanBlue};
  }
`;

export const StyledButtonAsLink = styled.button`
  color: ${colors.darkOceanBlue};
  font-weight: 600;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);

  &:hover {
    color: ${colors.darkOceanBlue};
    text-decoration: underline;
  }
`;
