import React from 'react';

import Grid from '@material-ui/core/Grid';

import SubcscribeCard from './components/SubcscribeCard/SubcscribeCard';

import './Notifications.scss';

function Notifications() {
  return (
    <Grid container direction="column" alignItems="center" spacing={3}>
      <SubcscribeCard />
    </Grid>
  );
}

export default Notifications;
