import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'utils/styles';

const OptionsList = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;
const Option = styled.div`
  margin-right: 15px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }

  ${({ isSelected }) =>
    isSelected &&
    ` 
    text-decoration: underline;
    color: ${colors.darkOceanBlue};
    font-weight: 800;
  `}
`;

const TabSelector = React.memo(({ options, selected, onChange }) => {
  return (
    <OptionsList>
      {options.map(option => {
        return (
          <Option key={option.value} onClick={() => onChange(option.value)} isSelected={selected === option.value}>
            {option.title}
          </Option>
        );
      })}
    </OptionsList>
  );
});

TabSelector.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

TabSelector.defaultProps = {};

export default TabSelector;
