import React, { useState } from 'react';
import PopupComp from 'components/Popup/Popup';
import Loader from 'components/UI/Loader';

import { ContributionType } from 'helpers/constants';
import { useAccount } from 'hooks';
import { LiveVideoProvider } from 'constants.js';
import { openInNewWindow } from 'services/links';
import { useVideoRoomStatus } from './useVideoRoomStatus';
import useVideoChatActions from './useVideoChatActions';

export const useRecordModal = ({
  onSubmit,
  onCancel,
  classId,
  contributionType,
  contributionId,
  liveVideoServiceProvider,
}) => {
  const [open, setOpen] = useState(false);
  const { user } = useAccount();
  const { loading, getRoomStatus } = useVideoRoomStatus();

  const { addVideoChatCreator } = useVideoChatActions();

  const handleCancel = () => {
    onCancel();
    setOpen(false);
  };
  const handleSubmit = () => {
    onSubmit();
    setOpen(false);
  };

  const continueLaunchRecodModal = async () => {
    const status = await getRoomStatus(classId, contributionId);
    if (!status) {
      return setOpen(true);
    }

    if (status.creatorId) {
      addVideoChatCreator({ creatorId: status.creatorId });
      return onCancel();
    }

    addVideoChatCreator({ creatorId: user.id });
    setOpen(true);
  };

  const launchRecodModal = () => {
    if (liveVideoServiceProvider && liveVideoServiceProvider.providerName != LiveVideoProvider.Cohere.value) {
      return openInNewWindow(liveVideoServiceProvider.customLink);
    }

    if (contributionType === ContributionType.contributionOneToOne) {
      return onCancel();
    }
    continueLaunchRecodModal();
  };

  const RecordModal = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <PopupComp
        title="Record this session?"
        text="Would you like this session to be recorded and viewable by you and your clients later?"
        open={open}
        onCloseText="No"
        onClose={handleCancel}
        onSubmit={handleSubmit}
      />
    );
  };

  return {
    RecordModal,
    launchRecodModal,
  };
};
