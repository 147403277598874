import React from 'react';
import styled from 'styled-components';
import ViewIcon from 'assets/ic-zoom-out-map.svg';

const StyledButton = styled.button`
  border: none;
  background: inherit;
  cursor: pointer;
`;

const Icon = styled.img`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;

const StyledTitle = styled.span`
  font-size: 14px;
  font-weight: 900;
`;

const ViewContributionButton = () => {
  return (
    <StyledButton>
      <Icon src={ViewIcon} />
      <StyledTitle>View</StyledTitle>
    </StyledButton>
  );
};

ViewContributionButton.propTypes = {};

export default ViewContributionButton;
