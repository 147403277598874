import React, { useCallback, Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { getIn } from 'formik'
import { Icon } from '@mdi/react'
import { mdiPlus, mdiMinusCircleOutline } from '@mdi/js'
import moment from 'moment'
import TimePickerField from 'components/FormUI/TimePickerField'
import { useTheme, useMediaQuery } from '@material-ui/core'
import * as calendarsActions from 'actions/calendars'

import Button from 'components/FormUI/Button'
import Loader from 'components/UI/Loader'
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage'
import { generateOneToOneSlots } from 'pages/CreateContribution/Sessions'
import { toRem } from 'utils/styles'
import * as oneToOneDataActions from 'actions/oneToOneData'
import * as contributionActions from 'actions/contributions'
import WeekdayPicker from './WeekdayPicker'

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 10px;
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
`

const StyledLink = styled.span`
  max-width: 260px;
  display: flex;
  align-items: center;
  font-size: ${toRem(16)};
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
const StyledDivContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ smView }) => (smView ? 'column' : 'row')};
  margin-bottom: ${({ smView }) => (smView ? '16px' : '0')};
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  margin-top: 10px;
`

const SessionTimeOneToOneComponent = ({
  form,
  push,
  remove,
  sessionIdx,
  timesCount,
  isOneToOne,
  oneToOneStartDate,
  oneToOneEndDate,
  addBookingTime,
  setOneToOneTime,
  selectedWeeks,
  removeBookingTime,
  sessionDuration,
  oneToOneData,
  setSlots,
  otherEvents,
  contribution,
  getBusyTime,
  busyTimes,
  loadingBusyTimes,
  editContributionTimes,
  getEditContributionTimes,
  calculatedSlots,
}) => {
  const { duration, startDay, endDay } = oneToOneData
  const { sessions } = form.values
  const error = getIn(form.errors, `sessions[${sessionIdx}]`)
  const showError = getIn(form.touched, `sessions[${sessionIdx}]`)?.sessionTimes && !!error

  const addTimesHandler = () => {
    addBookingTime(sessionDuration)
  }

  const removeTimesHandler = useCallback((i) => () => removeBookingTime(i), [removeBookingTime])

  const theme = useTheme()
  const smView = useMediaQuery(theme.breakpoints.down('sm'))

  const handleTimeChange = (value, sessionTimeIdx, timeType) => {
    if (value) {
      const currentDate = moment(oneToOneStartDate).format('YYYY-MM-DD')
      const currentTime = moment(`${currentDate} ${value.format('HH:mm:ss')}`).format('YYYY-MM-DDTHH:mm:ss[Z]')
      setOneToOneTime(sessionTimeIdx, currentTime, timeType)

      if (isOneToOne && timeType === 'startTime') {
        const endTimeValue = value.add(sessionDuration, 'minutes')
        const newEndTime = moment(`${currentDate} ${endTimeValue.format('HH:mm:ss')}`).format('YYYY-MM-DDTHH:mm:ss[Z]')
        setOneToOneTime(sessionTimeIdx, newEndTime, 'endTime')
      }
    }
  }

  return (
    <>
      {/* {loadingBusyTimes && <Loader />} */}
      {selectedWeeks.length && (
        <Grid container spacing={4}>
          {selectedWeeks.map((week, i) => {
            const momentStartTime = moment.utc(week.startTime)
            const momentEndTime = moment.utc(week.endTime)
            const key = i
            return (
              <Fragment key={key}>
                <Grid container style={{ padding: '16px 0' }}>
                  <StyledDivContainer smView={smView}>
                    <Grid sm={smView ? 12 : 6} xs={smView ? 12 : 6}>
                      <div className="d-flex" style={{ width: '100%', padding: '0 16px' }}>
                        <StyledFormControl>
                          <InputLabel style={{ color: 'black' }} shrink htmlFor={`available-time-${i}`}>
                            Add available times: What days and times can be booked?
                          </InputLabel>
                          <WeekdayPicker itemIndex={i} />
                        </StyledFormControl>
                      </div>
                    </Grid>
                    <Grid sm={smView ? 12 : 6} xs={smView ? 12 : 6}>
                      <Grid container direction="row" justify="space-around" alignItems="baseline">
                        <Grid item sm={4} xs={4}>
                          <div className="d-flex">
                            <StyledFormControl>
                              <InputLabel htmlFor={`start-time-${i}`} style={{ top: '-20px' }}>
                                From
                              </InputLabel>
                              <TimePickerField
                                onChange={(value) => handleTimeChange(value, i, 'startTime')}
                                name={`start-time-${i}`}
                                id={`tstart-time-${i}`}
                                classes={{
                                  root: 'mt-4 mr-3',
                                }}
                                showSecond={false}
                                value={momentStartTime}
                                format="h:mm a"
                                use12Hours
                                minuteStep={15}
                              />
                            </StyledFormControl>
                          </div>
                        </Grid>
                        <Grid item sm={4} xs={4}>
                          <div className="d-flex">
                            <StyledFormControl>
                              <InputLabel htmlFor={`end-time-${i}`} style={{ top: '-20px' }}>
                                To
                              </InputLabel>
                              <TimePickerField
                                onChange={(value) => handleTimeChange(value, i, 'endTime')}
                                name={`end-time-${i}`}
                                id={`tend-time-${i}`}
                                classes={{
                                  root: 'mt-4 mr-3',
                                }}
                                showSecond={false}
                                value={momentEndTime}
                                format="h:mm a"
                                use12Hours
                                minuteStep={15}
                              />
                            </StyledFormControl>
                          </div>
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          xs={2}
                          style={{
                            textAlign: 'right',
                          }}
                          classes={{
                            root: 'd-flex align-items-center',
                          }}
                        >
                          &nbsp;
                          {selectedWeeks.length > 1 && (
                            <StyledIcon onClick={removeTimesHandler(i)} path={mdiMinusCircleOutline} size={1} />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </StyledDivContainer>
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
      )}

      {!!sessions[sessionIdx].sessionTimes?.length && <br />}

      <StyledLink onClick={addTimesHandler}>
        <Icon path={mdiPlus} size={1} />
        Add Additional Booking Times
      </StyledLink>

      {showError && timesCount === 0 && (
        <>
          <br />
          <CommonErrorMessage align="left" message={error.sessionTimes} />
        </>
      )}
    </>
  )
}

SessionTimeOneToOneComponent.propTypes = {
  form: PropTypes.any.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  isOneToOne: PropTypes.bool,
  sessionIdx: PropTypes.number.isRequired,
  timesCount: PropTypes.number.isRequired,
  oneToOneStartDate: PropTypes.shape(),
  oneToOneEndDate: PropTypes.shape(),
  oneToDateRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

SessionTimeOneToOneComponent.defaultProps = {
  isOneToOne: false,
  oneToOneStartDate: null,
  oneToOneEndDate: null,
  oneToDateRange: null,
}

const mapStateToProps = ({ oneToOneData, contributions, calendars }) => ({
  oneToOneStartDate: oneToOneData?.startDay,
  oneToOneEndDate: oneToOneData?.endDay,
  sessionDuration: oneToOneData?.sessionDuration,
  selectedWeeks: oneToOneData?.selectedWeeks,
  oneToOneData: oneToOneData,
  contribution: contributions.activeContribution,
  editContributionTimes: contributions.editContributionTimes,
  calculatedSlots: contributions.calculatedSlots,
})

const actions = {
  addBookingTime: oneToOneDataActions.addBookingTime,
  setOneToOneTime: oneToOneDataActions?.setOneToOneTime,
  removeBookingTime: oneToOneDataActions?.removeBookingTime,
  setSlots: oneToOneDataActions?.setSlots,
}

export const SessionTimeOneToOne = connect(mapStateToProps, actions)(SessionTimeOneToOneComponent)
