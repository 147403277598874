import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { useIsEmailExist } from 'utils/useIsEmailExist';
import { getPurchaseValidationSchema } from 'utils/validation';
import { useAccount, useHttp } from 'hooks';
import { LabelText } from 'components/UI/Text/TextStyles';
import { If, mapTo } from 'utils/fp';
import { login, completeRegister, LOG_IN_FAILURE, REGISTER_FAILURE, CONTINUE_REGISTER_FAILURE } from 'actions/user';
import * as purchaseService from 'services/purchase.service';

import Modal from 'components/UI/Modal';
import { ContributionPurchaseType, ContributionType, PaymentStatus, UserRoles } from 'helpers/constants';
import { fetchClientContribution, FETCH_CONTRIBUTION_SUCCESS, fetchContributionActions } from 'actions/contributions';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import { joinContribution } from 'services/contributions.service';
import isNil from 'lodash/isNil';
import useContribution from '../../hooks/useContribution';
import { PURCHASE_MODAL_STEPS, PURCHASE_MODAL_TITLES, ACCOUNT_FORM_FIELDS } from './PurchaseModal.constants';
import { PurchaseModalForm } from './PurchaseModalForm';
import { PAYMENT_OPTIONS } from '../../../../constants';

const PurchaseModal = ({
  isOpen,
  onClose,
  onSubmit,
  oneToOne,
  paymentDataOneToOne,
  isPackage,
  isMonthlySessionSubscription,
  proseedHandlePurchase,
  submitNow,
  showOnlyProseedModal,
  isInviteToJoin,
}) => {
  const [showTerms, setShowTerms] = useState(false);
  const [isAlreadyPuchased, setIsAlreadyPuchased] = useState(false);

  const isLoadingAccount = useSelector(({ account }) => account.isLoading);
  const errorAccount = useSelector(({ account }) => account.error?.message);
  const errorContribution = useSelector(({ contributions }) => contributions.error?.message);
  const dispatch = useDispatch();

  const contribution = useContribution();
  const { user } = useAccount();
  const {
    type,
    id,
    title,
    paymentInfo: { paymentOptions },
  } = contribution;

  const modalTitle = isInviteToJoin
    ? PURCHASE_MODAL_TITLES[type].join(title, !isEmpty(user))
    : PURCHASE_MODAL_TITLES[type].modal;
  const [step, setStep] = useState(() => {
    if (!isNil(isInviteToJoin)) {
      return PURCHASE_MODAL_STEPS.join;
    }
    if (!isEmpty(user)) {
      return PURCHASE_MODAL_STEPS.loggedIn;
    }
    return PURCHASE_MODAL_STEPS.init;
  });
  const { checkEmail, isLoadingEmail } = useIsEmailExist();

  const formRef = useRef(null);

  const handleCloseAlreadyPurchased = () => {
    setIsAlreadyPuchased(false);
    onClose();
  };

  useEffect(() => {
    if (submitNow) {
      // eslint-disable-next-line no-unused-expressions
      formRef.current?.handleSubmit();
    }
  }, [submitNow, formRef]);

  const handleProceedPurchaseStatus = useCallback(async () => {
    const action = await fetchClientContribution(id)(dispatch);
    if (action.type === FETCH_CONTRIBUTION_SUCCESS) {
      const isProceedStatus = [PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(
        action.payload.purchaseStatus,
      );
      if (isProceedStatus) {
        return showOnlyProseedModal();
      }
    } else {
      return null;
    }
  }, [dispatch, id, showOnlyProseedModal]);

  const stepVerifier = useCallback(
    async values => {
      if (!isEmpty(user)) {
        return true;
      }
      if (step === PURCHASE_MODAL_STEPS.create) {
        const returnedRegisterAction = await completeRegister({ ...values, userView: UserRoles.client })(dispatch);
        if (
          returnedRegisterAction.type !== REGISTER_FAILURE &&
          returnedRegisterAction.type !== CONTINUE_REGISTER_FAILURE
        ) {
          return formRef.current?.handleSubmit();
        }
        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.joinCreate) {
        const returnedRegisterAction = await completeRegister({ ...values, userView: UserRoles.client })(dispatch);
        if (
          returnedRegisterAction.type !== REGISTER_FAILURE &&
          returnedRegisterAction.type !== CONTINUE_REGISTER_FAILURE
        ) {
          joinContribution({ id, accessCode: isInviteToJoin }).then(() => {
            dispatch(
              fetchContributionActions.success({
                ...contribution,
                isPurchased: true,
                purchaseStatus: 'succeeded',
              }),
            );
          });
        }
        return false;
      }

      if (step === PURCHASE_MODAL_STEPS.login) {
        const { Email, Password } = values;
        const returnedLoginAction = await login(Email, Password)(dispatch);
        if (returnedLoginAction.type !== LOG_IN_FAILURE) {
          if (!oneToOne) {
            handleProceedPurchaseStatus();
          }
          return formRef.current?.handleSubmit();
        }
        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.joinLogin) {
        const { Email, Password } = values;
        const returnedLoginAction = await login(
          Email,
          Password,
        )(dispatch).then(() => {
          joinContribution({ id, accessCode: isInviteToJoin }).then(() => {
            dispatch(
              fetchContributionActions.success({
                ...contribution,
                isPurchased: true,
                purchaseStatus: 'succeeded',
              }),
            );
          });
        });
        if (returnedLoginAction.type !== LOG_IN_FAILURE) {
          if (!oneToOne) {
            handleProceedPurchaseStatus();
          }
          return formRef.current?.handleSubmit();
        }
        return false;
      }

      const isExistEmail = await checkEmail(values[ACCOUNT_FORM_FIELDS.email]);
      if (isExistEmail) {
        if (isInviteToJoin) {
          setStep(PURCHASE_MODAL_STEPS.joinLogin);
        } else {
          setStep(PURCHASE_MODAL_STEPS.login);
        }
      } else if (isInviteToJoin) {
        setStep(PURCHASE_MODAL_STEPS.joinCreate);
      } else {
        setStep(PURCHASE_MODAL_STEPS.create);
      }
      return false;
    },
    [checkEmail, dispatch, handleProceedPurchaseStatus, oneToOne, step, user, id],
  );

  const stripe = useStripe();
  const elements = useElements();
  const { request, loading } = useHttp();
  const [typeOfPayment, setTypeOfPayment] = useState(
    oneToOne && paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION)
      ? PAYMENT_OPTIONS.PER_SESSION
      : paymentOptions.sort()[0],
  );
  const [summary, setSummary] = useState(null);
  const [name, setName] = useState(null);
  const [payment, setPayment] = useState(false);
  const [error, setError] = useState(null);

  const handlePayResponse = useCallback(
    response => {
      setPayment(false);

      if (response.error) {
        setError(response.error.message);
      } else {
        // for the 5/31/21 promotion only, open a new tab with thank you page for contribution [60ae7d7b744e7b6ff48a3f49] that was purchased
        // this is for analytics purposes
        // todo: remove code when no needed anymore
        const contributionId = '60ae7d7b744e7b6ff48a3f49';
        const url = `https://www.thenucleareffect.com/scale-ty/`;
        if (contribution?.id === contributionId) {
          // window.open(url);
          window.location.href = url;
        }
        onSubmit();
      }
    },
    [onSubmit],
  );

  // Payment method
  const payWith3DSecure = useCallback(
    (...args) => {
      setPayment(true);

      stripe
        .confirmCardPayment(...args)
        .then(handlePayResponse)
        .catch(console.dir);
    },
    [stripe, handlePayResponse],
  );

  // Entire payment flow: prepareEntire => payEntireCourse => confirm 3d secure
  const payEntireCourse = useCallback(
    data => {
      const { clientSecret } = data;
      const card = elements.getElement(CardNumberElement);

      payWith3DSecure(clientSecret, {
        payment_method: {
          card,
          billing_details: { name },
        },
      });
    },
    [elements, name, payWith3DSecure],
  );

  const prepareEntire = useCallback(
    data => {
      request('/api/purchase/course', 'POST', data)
        .then(payEntireCourse)
        .catch(e => {
          if (e.response.status === 400) {
            return setIsAlreadyPuchased(true);
          }
          console.dir(e);
        });
    },
    [request, payEntireCourse],
  );

  // Split payments flow: createTokenForSplitPayments => attachPaymentMethodByToken => subscribe => confirm 3d secure
  const subscribe = useCallback(
    ({ paymentMethodId }) => {
      const data = {
        paymentMethodId,
        paymentOptions: isMonthlySessionSubscription ? 'MonthlySessionSubscription' : 'SplitPayments',
        contributionId: contribution.id,
      };

      const purchaseType = oneToOne ? ContributionPurchaseType.oneToOne : ContributionPurchaseType.course;
      const paymentOption = isMonthlySessionSubscription ? '/monthly-session-subscription' : '';
      const paymentUrl = `/api/purchase/${purchaseType}${paymentOption}`;

      request(paymentUrl, 'POST', data)
        .then(res => payWith3DSecure(res.clientSecret))
        .catch(console.dir);
    },
    [request, contribution.id, payWith3DSecure, oneToOne, isMonthlySessionSubscription],
  );

  const attachPaymentMethodByToken = useCallback(
    cardToken => {
      request('/api/payment/attach-customer-payment-method-token', 'POST', {
        cardToken,
      })
        .then(subscribe)
        .catch(console.dir);
    },
    [request, subscribe],
  );

  const createTokenForSplitPayments = useCallback(() => {
    const card = elements.getElement(CardNumberElement);
    stripe.createToken(card).then(res => {
      if (!res.error) {
        attachPaymentMethodByToken(res.token.id);
      }
    });
  }, [stripe, elements, attachPaymentMethodByToken]);

  // Submitting form when user clicks on purchase button
  const handleSubmit = useCallback(
    async values => {
      formRef.current.setTouched({});
      const isCanProceed = await stepVerifier(values);
      if (!isCanProceed) {
        return;
      }

      if (proseedHandlePurchase) {
        return proseedHandlePurchase();
      }

      setName(values.Name);
      setError(null);
      if (oneToOne) {
        if (isMonthlySessionSubscription) {
          createTokenForSplitPayments();
          return;
        }

        payEntireCourse(paymentDataOneToOne);
        return;
      }

      If(typeOfPayment === 'EntireCourse')
        .then(
          mapTo({
            contributionId: contribution.id,
            paymentOptions: typeOfPayment,
          }),
        )
        .then(prepareEntire)
        .else(createTokenForSplitPayments);
    },
    [
      stepVerifier,
      proseedHandlePurchase,
      oneToOne,
      typeOfPayment,
      contribution.id,
      prepareEntire,
      createTokenForSplitPayments,
      payEntireCourse,
      paymentDataOneToOne,
      setName,
    ],
  );

  // Get summary for payment
  useEffect(() => {
    setError(null);
    let paymentType = `${isPackage ? 'SessionsPackage' : typeOfPayment}`;
    paymentType = `${isMonthlySessionSubscription ? 'MonthlySessionSubscription' : paymentType}`;

    const REQUEST_MAPPING = {
      [ContributionType.contributionOneToOne]: purchaseService.getOnToOnePaymentInfo,
      [ContributionType.contributionCourse]: purchaseService.getCoursePaymentInfo,
      [ContributionType.contributionMembership]: purchaseService.getMembershipPaymentInfo,
    };

    const getPaymentData = REQUEST_MAPPING[contribution.type];

    getPaymentData(contribution.id, paymentType).then(setSummary).catch(console.dir);
  }, [typeOfPayment, contribution.id, oneToOne, isPackage, isMonthlySessionSubscription]);

  const getSubmitButtonTitle = () => {
    if ((isEmpty(user) && step === PURCHASE_MODAL_STEPS.init) || step === PURCHASE_MODAL_STEPS.join) {
      return !isEmpty(user) && isInviteToJoin ? 'Ok' : 'Next';
    }
    return PURCHASE_MODAL_TITLES[type].submit;
  };
  return (
    <>
      <Modal
        cancelTitle={!isEmpty(user) && isInviteToJoin ? 'Ok' : 'Cancel'}
        cancelInvert={!isInviteToJoin}
        disableConfirm={!isEmpty(user) && isInviteToJoin}
        title={modalTitle}
        isOpen={isOpen}
        onCancel={onClose}
        submitTitle={getSubmitButtonTitle()}
        form="credit-card-form"
        loading={loading || payment || isLoadingEmail || isLoadingAccount}
        helperText={
          isEmpty(user) &&
          step !== PURCHASE_MODAL_STEPS.init &&
          step !== PURCHASE_MODAL_STEPS.join &&
          (errorAccount || errorContribution || 'Please fill out account information')
        }
      >
        <Formik
          initialValues={{
            Name: '',
            cardNumber: false,
            cardExpired: false,
            cardCVC: false,
            [ACCOUNT_FORM_FIELDS.confirmEmail]: '',
            [ACCOUNT_FORM_FIELDS.email]: '',
            [ACCOUNT_FORM_FIELDS.password]: '',
            [ACCOUNT_FORM_FIELDS.birthDate]: '',
            [ACCOUNT_FORM_FIELDS.firstName]: '',
            [ACCOUNT_FORM_FIELDS.lastName]: '',
          }}
          validationSchema={getPurchaseValidationSchema(step)}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ handleChange, errors, touched, setTouched }) => {
            return (
              <PurchaseModalForm
                isInviteToJoin={isInviteToJoin}
                setTouched={setTouched}
                typeOfPayment={typeOfPayment}
                setTypeOfPayment={setTypeOfPayment}
                loading={loading}
                summary={summary}
                isPackage={isPackage}
                isMonthlySessionSubscription={isMonthlySessionSubscription}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                error={error}
                setShowTerms={setShowTerms}
                step={step}
                paymentIntentCreated={paymentDataOneToOne?.created}
                sessionLifeTimeSeconds={paymentDataOneToOne?.sessionLifeTimeSeconds}
                onCheckoutSessionExpired={() => onClose(false)}
              />
            );
          }}
        </Formik>

        <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />
      </Modal>

      {isAlreadyPuchased && (
        <Modal
          isOpen
          onCancel={handleCloseAlreadyPurchased}
          onSubmit={handleCloseAlreadyPurchased}
          title="Purchased contribution"
          hiddenCancel
        >
          <LabelText>You have already purchased this contribution</LabelText>
        </Modal>
      )}
    </>
  );
};

PurchaseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  oneToOne: PropTypes.bool,
  paymentDataOneToOne: PropTypes.shape({
    clientSecret: PropTypes.string,
  }),
  isPackage: PropTypes.bool,
  isMonthlySessionSubscription: PropTypes.bool,
  proseedHandlePurchase: PropTypes.func,
  submitNow: PropTypes.bool.isRequired,
  showOnlyProseedModal: PropTypes.func,
};

PurchaseModal.defaultProps = {
  oneToOne: false,
  paymentDataOneToOne: null,
  isPackage: false,
  isMonthlySessionSubscription: false,
  proseedHandlePurchase: null,
  showOnlyProseedModal: null,
  isInviteToJoin: null,
};

export default PurchaseModal;
