import React from 'react';
import { Redirect } from 'react-router-dom';

import { ContributionType } from 'helpers/constants';
import { useAccount } from 'hooks';
import useContribution from '../hooks/useContribution';
import CourseClients from './CourseClients';

const ContributionClientsContainer = () => {
  const { user, currentRole } = useAccount();
  const contribution = useContribution();

  return contribution.type === ContributionType.contributionOneToOne ? (
    <Redirect to={`/contribution-view/${contribution.id}`} />
  ) : (
    <CourseClients user={user} currentRole={currentRole} contribution={contribution} />
  );
};

export default ContributionClientsContainer;
