import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';

const StyledNotice = styled.p`
  padding: 0;
`;

const RefuseModal = ({ isOpen, onClose, onSubmit, message }) => {
  return (
    <Modal title="Session Time Unavailable" isOpen={isOpen} onCancel={onClose} onSubmit={onSubmit} submitTitle="OK">
      <StyledNotice>{message}</StyledNotice>
    </Modal>
  );
};

RefuseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
};

RefuseModal.defaultProps = {
  message: `Sorry, but this time is not availiable. Please choose another time.`,
};

export default RefuseModal;
