import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
// import { Translate } from "react-localize-redux";
import { useDispatch, useSelector } from 'react-redux';

import Input from 'components/FormUI/Input';
import Button from 'components/FormUI/Button';
import Logo from 'components/FormUI/Logo';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { Card } from 'components/UI/Card';
import { getLoginValidationSchema } from 'utils/validation';
import * as userActions from 'actions/user';
import { devices } from 'utils/styles';
import { EN_MESSAGES } from 'constants.js';
import { ROUTES } from '../../constants';
import { useAccount } from '../../hooks';
import { UserRoles } from 'helpers/constants';
import * as userService from 'services/user.service';
import * as accountPreferences from 'services/accountPreferences.service';
import { login } from 'actions/user';
import { userSelector, loadingSelector, errorSelector, temporaryDataSelector } from 'selectors/user';
import { redirectTo } from 'services/links';
import { isNil } from 'ramda';

const CONTAINER_WIDTH = 520;

const StyledCard = styled(Card)`
  max-width: ${CONTAINER_WIDTH}px;
  margin: auto;
`;

const Container = styled.div`
  max-width: ${CONTAINER_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  box-sizing: border-box;

  @media screen and (${devices.mobile}) {
    padding: 40px 100px;
  }
`;

const WelcomeMessage = styled.h1`
  font-size: 1.675rem;
  text-align: center;
  font-weight: 500;
  line-height: normal;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
  margin-top: 0.75;
`;

const WelcomeSubMessage = styled.h4`
  font-size: 1.125rem;
  text-align: center;
  font-weight: 500;
  line-height: 1.35;
  margin-top: 1.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  width: 100%;
  text-align: center;

  & > * + * {
    margin-top: 2.5rem;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.17;
  letter-spacing: 0.4px;
  font-size: 0.875rem;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
`;

const useStyles = makeStyles(() => ({
  input: {
    '& + &': {
      marginTop: '2rem',
    },
  },
}));

function SignInPage() {
  const dispatch = useDispatch();
  const temporaryDataForSwtichingRole = useSelector(temporaryDataSelector);
  const user = useSelector(userSelector);
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);

  const classes = useStyles();
  const { pathname } = useLocation();
  const {
    user: { email },
    currentRole,
  } = useAccount();
  const account = useAccount();

  const isRoleAdding = pathname === ROUTES.SUBMIT_ADD_ROLE;
  const roleForAdd = currentRole === UserRoles.client ? UserRoles.cohealer : UserRoles.client;
  const welcomeMessage = isRoleAdding
    ? `To finish activating   your Coach account, please enter your password.`
    : 'Welcome back to Cohere';
  const submitButtonText = isRoleAdding ? 'Submit' : 'Login';

  const submitHandler = useCallback(
    ({ email, password }) => {
      if (isRoleAdding) {
        Promise.all([
          userService.switchFromClientToCoach(temporaryDataForSwtichingRole),
          accountPreferences.setAccountPreferences({
            userView: UserRoles.cohealer,
          }),
        ]).then(([auth, preferences]) => {
          dispatch(userActions.roleChange(preferences.userView));
          dispatch(login(email, password, isRoleAdding));
        });
      } else {
        dispatch(login(email, password, isRoleAdding));
      }
    },
    [login, isRoleAdding, user, account],
  );

  return (
    <>
      <StyledCard>
        <Container>
          <Logo />
          <WelcomeMessage>
            {/* <Translate id="auth.login" /> */}
            {!isRoleAdding && EN_MESSAGES.auth.login}
          </WelcomeMessage>
          <WelcomeSubMessage>{welcomeMessage}</WelcomeSubMessage>

          <Formik
            initialValues={{ email: email || '', password: '' }}
            // validationSchema={getLoginValidationSchema(Translate)}
            validationSchema={getLoginValidationSchema()}
            onSubmit={submitHandler}
          >
            <Form>
              <Input
                className={classes.input}
                label="Email"
                type="email"
                name="email"
                disabled={isRoleAdding}
                fullWidth
              />
              <Input className={classes.input} label="Password" type="password" name="password" fullWidth />
              <ButtonsContainer>
                <Button type="submit" disabled={loading}>
                  {submitButtonText}
                </Button>
                {error?.message && <CommonErrorMessage message={error.message} />}
                <StyledLink to="/auth/restore-pass">I forgot my password</StyledLink>
              </ButtonsContainer>
            </Form>
          </Formik>
        </Container>
      </StyledCard>
    </>
  );
}

export default SignInPage;
