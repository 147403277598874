import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from 'utils/styles';
import { replaceBreakToBr } from 'utils/utils';

const StyledErrorMessage = styled.div`
  color: ${colors.fadedPurple};
  text-align: ${({ align }) => align};
`;

function CommonErrorMessage({ message, align }) {
  return <StyledErrorMessage align={align} dangerouslySetInnerHTML={{ __html: replaceBreakToBr(message) }} />;
}

CommonErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['center', 'left', 'right']),
};

CommonErrorMessage.defaultProps = {
  align: 'center',
};

export default CommonErrorMessage;
