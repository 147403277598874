import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { UserRoles } from 'helpers/constants';
import { PublicRoute, CommonRoute, ProtectedRoute } from '../Routing';
import DashboardContainer from '../Containers/DashboardContainer';
import ContributionsContainer from '../Containers/ContributionsContainer';
import AccountContainer from '../Containers/AccountContainer';
import CreateContributionContainer from '../Containers/CreateContributionContainer';
import EditContributionContainer from '../Containers/EditContributionContainer';
import ContributionViewContainer from '../Containers/ContributionViewContainer';
import AdminContainer from '../Containers/AdminContainer';
import ConversationsContainer from '../Containers/ConversationsContainer';
import CohealerAbout from '../../pages/CohealerAbout/CohealerAbout';
import ConfirmEmail from './ConfirmEmail';
import RestorePassword from './RestorePassword';
import ChatProvider from './ChatProvider';
import VideoChat from './VideoChat';
import { TestVideoChat } from './TestVideoChat';
import { ModalsAndLoadersContainer } from './ModalsAndLoaders';

const AppContent = () => {
  return (
    <ChatProvider>
      <Switch>
        <ProtectedRoute
          roles={[UserRoles.cohealer, UserRoles.client]}
          path="/dashboard"
          component={DashboardContainer}
        />
        <ProtectedRoute roles={[UserRoles.cohealer]} path="/contributions" component={ContributionsContainer} />
        <ProtectedRoute roles={[UserRoles.cohealer, UserRoles.client]} path="/account" component={AccountContainer} />
        <ProtectedRoute
          roles={[UserRoles.cohealer]}
          path="/create-contribution"
          component={CreateContributionContainer}
        />
        <ProtectedRoute
          roles={[UserRoles.cohealer]}
          path="/edit-contribution/:id"
          component={EditContributionContainer}
        />
        <CommonRoute path="/contribution-view/:id" component={ContributionViewContainer} />
        <ProtectedRoute roles={[UserRoles.admin]} path="/admin" component={AdminContainer} />
        <ProtectedRoute
          roles={[UserRoles.cohealer, UserRoles.client]}
          path="/conversations"
          component={ConversationsContainer}
        />
        <ProtectedRoute roles={[UserRoles.cohealer, UserRoles.client]} path="/confirmEmail" component={ConfirmEmail} />
        <PublicRoute
          roles={[UserRoles.cohealer, UserRoles.client]}
          path="/restorePassword"
          component={RestorePassword}
        />
        <ProtectedRoute
          roles={[UserRoles.cohealer, UserRoles.client, UserRoles.admin]}
          path="/cohealer-about/:cohealerId"
          component={CohealerAbout}
        />
        <Redirect to="/dashboard" />
      </Switch>
      <VideoChat />
      <TestVideoChat />
      <ModalsAndLoadersContainer />
    </ChatProvider>
  );
};

export default AppContent;
