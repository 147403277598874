import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { Route, Redirect, Switch, Link, useRouteMatch } from 'react-router-dom';
import { useHeader, useRouter } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';

import useContribution from 'pages/ContributionView/hooks/useContribution';
import { UserRoles, ContributionType, ContributionStatus } from 'helpers/constants';
import {
  fetchClientContribution,
  fetchCohealerContribution,
  resetContribution,
  fetchContributionTimes,
} from 'actions/contributions';
import Loader from 'components/UI/Loader';
import Header from 'components/UI/Header';
import Modal from 'components/UI/Modal';
import HeaderMobile from 'components/UI/HeaderMobile';
import ContributionHeader from 'pages/ContributionView/components/ContributionHeader';
import Button from 'components/FormUI/Button';
import Can from 'components/Auth/Can';
import {
  AboutContribution,
  ContributionSessions,
  ContributionNotes,
  SecurityContribution,
  ContributionTeam,
  ContributionDiscussion,
} from 'pages';
import { devices } from 'utils/styles';
import { parseQuery } from 'utils/parseData';
import { LabelText } from 'components/UI/Text/TextStyles';
import SEO from 'components/Seo/Seo';
import { ROUTES } from '../../constants';
import ContributionCommunity from '../../pages/ContributionView/ContributionCommunity/ContributionCommunity';

const StyledMainContainer = styled.div`
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
`;

const StyledRightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledMainSection = styled.div`
  flex-grow: 1;
  padding: 16px 16px;
  padding: ${({ xsView, mobileView }) => (xsView && '0') || (mobileView && '8px')};
  @media screen and (${devices.desktop}) {
    padding: 24px 240px;
  }
`;

const ContributionViewMainContainer = ({
  children,
  handleShareButtonClick,
  contribution,
  getClientContribution,
  getCohealerContribution,
  error,
  currentRole,
  getContributionTimes,
}) => {
  useHeader(contribution?.title || '');
  const {
    path,
    url,
    params: { id },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const { status } = useContribution();
  const { history, location } = useRouter();

  useEffect(() => {
    if (currentRole === UserRoles.cohealer || currentRole === UserRoles.admin) {
      getCohealerContribution(id).catch(() => {
        history.push(ROUTES.ROLE_SWITCH, { path: location.pathname });
      });
    } else {
      getClientContribution(id);
    }
  }, [id, currentRole, getClientContribution, getCohealerContribution]);
  useEffect(() => {
    if (contribution?.type === ContributionType.contributionOneToOne) {
      if (currentRole === UserRoles.cohealer || currentRole === UserRoles.admin) {
        getContributionTimes(id, true);
      } else {
        getContributionTimes(id);
      }
    }
  }, [contribution]);
  useEffect(() => {
    return () => {
      dispatch(resetContribution());
    };
  }, [dispatch]);

  const [isShowPartnerModal, setIsShowPartnerModal] = useState(false);
  const [isAddedSuccessfully, setIsAddedSuccessfully] = useState(false);
  const [partnerContributionName, setPartnerContributionName] = useState(null);

  useEffect(() => {
    const parsedQuery = parseQuery(location.search);

    if (parsedQuery?.success) {
      const isSuccessfully = parsedQuery.success === 'true';
      setIsAddedSuccessfully(isSuccessfully);
      setPartnerContributionName(parsedQuery.contributionName);
      setIsShowPartnerModal(true);
      history.push(`${location.pathname}`);
    }
  }, [history, location.pathname, location.search]);

  const handleClosePartnerMadal = () => {
    setIsShowPartnerModal(false);
  };

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const xsView = useMediaQuery(theme.breakpoints.down('xs'));

  if (!contribution?.id) {
    return <Loader />;
  }

  const isFinalized = status === ContributionStatus.rejected;
  const isContributionPurchased = contribution.isPurchased;
  const canAccess = currentRole === UserRoles.cohealer || (currentRole === UserRoles.client && isContributionPurchased);
  return (
    <>
      <SEO
        description={contribution?.whoIAm}
        title={contribution?.title}
        thumbnails={contribution?.previewContentUrls}
      />
      <StyledMainContainer>
        <StyledRightSection>
          <Header>
            {!isFinalized && !mobileView && (
              <Can
                roleName={UserRoles.cohealer}
                yes={() => (
                  <Link to={`/edit-contribution/${id}/basic`}>
                    <Button autoWidth>Edit Contribution</Button>
                  </Link>
                )}
              />
            )}
          </Header>
          {mobileView && <HeaderMobile />}
          <ContributionHeader canAccess={canAccess} onShareButtonClick={handleShareButtonClick} status={status} />

          <StyledMainSection xsView={xsView} mobileView={mobileView}>
            <Switch>
              <Route path={`${path}/:code?/about`} exact component={AboutContribution} />
              <Route path={`${path}/:code?/sessions`} exact component={ContributionSessions} />
              <Route path={`${path}/:code?/community`} component={ContributionCommunity} />
              <Route path={`${path}/:code?/discussion`} component={ContributionDiscussion} />
              <Route path={`${path}/:code?/notes`} component={ContributionNotes} />
              <Route path={`${path}/:code?/team`} component={ContributionTeam} />
              <Route path={`${path}/:code?/security`} exact component={SecurityContribution} />
              <Redirect to={`${url}/about`} />
            </Switch>
          </StyledMainSection>
        </StyledRightSection>
        {children}
      </StyledMainContainer>

      {isShowPartnerModal && (
        <Modal
          isOpen={isShowPartnerModal}
          onSubmit={handleClosePartnerMadal}
          title={isAddedSuccessfully ? 'You were added successfully' : 'Adding failed'}
          hiddenCancel
        >
          {isAddedSuccessfully ? (
            <LabelText>{`You were added to ${partnerContributionName}`}</LabelText>
          ) : (
            <LabelText>Adding you as a partner coach failed</LabelText>
          )}
        </Modal>
      )}
    </>
  );
};

ContributionViewMainContainer.propTypes = {
  handleShareButtonClick: PropTypes.func,
  children: PropTypes.node,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  contribution: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    purchaseStatus: PropTypes.string,
    isPurchased: PropTypes.bool,
  }),
  getClientContribution: PropTypes.func.isRequired,
  getCohealerContribution: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentRole: PropTypes.string.isRequired,
};

ContributionViewMainContainer.defaultProps = {
  handleShareButtonClick: () => {},
  children: null,
  contribution: null,
  error: null,
};

const mapStateToProps = ({ contributions, account }) => ({
  contribution: contributions?.activeContribution,
  error: contributions?.error,
  loading: contributions?.loading,
  currentRole: account?.currentRole,
});

const actions = {
  getClientContribution: fetchClientContribution,
  getCohealerContribution: fetchCohealerContribution,
  getContributionTimes: fetchContributionTimes,
};

export default connect(mapStateToProps, actions)(ContributionViewMainContainer);
