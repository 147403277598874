import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledUl = styled.ul`
  list-style: none;
  padding-left: 2rem;
`

function List({ children }) {
  return <StyledUl>{children}</StyledUl>
}

List.propTypes = {
  children: PropTypes.node.isRequired,
}

export default List
export { StyledUl }
