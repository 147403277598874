import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/FormUI/Button';
import { useHttp, useVideoChatActions } from 'hooks';
import { LiveVideoProvider } from 'constants.js';
import { openInNewWindow } from 'services/links';

const JoinSessionTime = ({
  user,
  session,
  contribution: { id, type, chat, liveVideoServiceProvider },
  sessionTime,
  isLaunched,
  mobileView,
}) => {
  const { request, loading } = useHttp();
  const { startVideoChat } = useVideoChatActions();

  const isCustomLiveVideoServiceProvider = useMemo(() => {
    return liveVideoServiceProvider && liveVideoServiceProvider.providerName != LiveVideoProvider.Cohere.value;
  }, [liveVideoServiceProvider]);

  const onJoinSession = () => {
    if (isCustomLiveVideoServiceProvider) {
      openInNewWindow(liveVideoServiceProvider.customLink);
    } else {
      request('/Video/GetClientToken', 'POST', {
        contributionId: id,
        classId: sessionTime.id,
        identityName: `${user.firstName} ${user.lastName}`,
      }).then(({ token }) =>
        startVideoChat({
          contributionId: id,
          type,
          sessionId: session?.id,
          classId: sessionTime.id,
          title: session?.title,
          chatId: chat?.sid,
          token,
        }),
      );
    }
  };

  return (
    <Button
      autoWidth
      onClick={onJoinSession}
      disabled={loading || (!isLaunched && !isCustomLiveVideoServiceProvider)}
      mobileView={mobileView}
    >
      Join Session
    </Button>
  );
};

JoinSessionTime.defaultProps = {
  session: {},
};

JoinSessionTime.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    chat: PropTypes.shape({
      sid: PropTypes.string,
    }),
  }).isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
  }),
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
    isCompleted: PropTypes.bool,
  }).isRequired,
  isLaunched: PropTypes.bool.isRequired,
  mobileView: PropTypes.bool,
};

export default JoinSessionTime;
