import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import { TIMEZONES } from 'constants.js';
import { useMediaQuery } from '@material-ui/core';

const StyledMainBlock = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 35px 70px 70px;

  ${({ mobileView }) => mobileView && `padding: 35px 20px 20px;`}
`;

const StyledInfoBlock = styled.p`
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 1.1125rem;
  font-weight: 500;
  line-height: 1.35;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;

const sessionDateFormat = 'MMMM Do YYYY hh:mm A';
const sessionTimeFormat = 'hh:mm A';

const BookModalView = ({ session: { title, start, end, serviceProviderName }, timeZoneId }) => {
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <StyledMainBlock mobileView={mobileView}>
      <StyledInfoBlock>
        Please confirm your session time for {title} with {serviceProviderName}
      </StyledInfoBlock>
      <StyledInfoBlock>
        {moment(start).format(sessionDateFormat)} - {moment(end).format(sessionTimeFormat)} {TIMEZONES[timeZoneId]}{' '}
        <br />
        <StyledLink to="/account/profile">Update My Timezone</StyledLink>
      </StyledInfoBlock>
    </StyledMainBlock>
  );
};

BookModalView.propTypes = {
  session: PropTypes.shape({
    title: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    serviceProviderName: PropTypes.string,
  }),
  timeZoneId: PropTypes.string.isRequired,
};

BookModalView.defaultProps = {
  session: {},
};

export default BookModalView;
