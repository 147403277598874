import React from 'react';
import PropTypes from 'prop-types';

import { UserRoles } from 'helpers/constants';
import CohealerOneToOneSessions from './CohealerOneToOneSessions';
import ClientOneToOneSessions from './ClientOneToOneSessions';

const OneToOneSessionsComponents = {
  [UserRoles.cohealer]: CohealerOneToOneSessions,
  [UserRoles.client]: ClientOneToOneSessions,
};

const OneToOneSessionsContainer = ({ currentRole, ...restProps }) => {
  const OneToOneSessionsComponent = OneToOneSessionsComponents[currentRole] || ClientOneToOneSessions;

  return <OneToOneSessionsComponent {...restProps} />;
};

OneToOneSessionsContainer.propTypes = {
  currentRole: PropTypes.string.isRequired,
};

export default OneToOneSessionsContainer;
