import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Email from './BannerTypes/Email'
import BankInfo from './BannerTypes/BankInfo'
import ClosestSession from './BannerTypes/ClosestSession'
import TestVideo from './BannerTypes/TestVideo'
import SocialSecurityInfo from './BannerTypes/SocialSecurityInfo'
import ClientDeclinedSubscriptions from './BannerTypes/ClientDeclinedSubscriptions'
import CoachDeclinedSubscriptions from './BannerTypes/CoachDeclinedSubscriptions'

import './Banner.scss'

const Banner = ({ type, closestClassForBanner, clientDeclinedSubscriptions, coachDeclinedSubscriptions }) => {
  const getBanner = useCallback(() => {
    switch (type) {
      case 'email':
        return <Email />
      case 'bankInfo':
        return <BankInfo />
      case 'closestSession':
        return <ClosestSession closestClassForBanner={closestClassForBanner || {}} />
      case 'testVideo':
        return <TestVideo />
      case 'socialSecurityInfo':
        return <SocialSecurityInfo />
      case 'coachDeclinedSubscriptions':
        return <CoachDeclinedSubscriptions coachDeclinedSubscriptions={coachDeclinedSubscriptions} />
      case 'clientDeclinedSubscriptions':
        return <ClientDeclinedSubscriptions clientDeclinedSubscriptions={clientDeclinedSubscriptions} />
      default:
        return null
    }
  }, [type, closestClassForBanner])

  return getBanner(type)
}

Banner.defaultProps = {
  className: null,
  closestClassForBanner: {},
}

Banner.propTypes = {
  type: PropTypes.string.isRequired,
  closestClassForBanner: PropTypes.shape({
    contributionId: PropTypes.string,
    classId: PropTypes.string,
    contributionTitle: PropTypes.string,
    minutesLeft: PropTypes.number,
  }),
}

export default Banner
