import React from 'react'
import PropTypes from 'prop-types'

import IconButton from '@material-ui/core/IconButton'
import ChatIcon from '@material-ui/icons/Chat'

const CustomChatIcon = ({ handleClick, disabled }) => (
  <IconButton color="primary" aria-label="chat picture" component="span" onClick={handleClick} disabled={disabled}>
    <ChatIcon />
  </IconButton>
)

CustomChatIcon.propTypes = {
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
}

CustomChatIcon.defaultProps = {
  handleClick: undefined,
  disabled: false,
}

export default CustomChatIcon
