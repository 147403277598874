import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { Formik } from 'formik';
import * as Yup from 'yup';
import isNull from 'lodash/isNull';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import filter from 'lodash/filter';
import { UserRoles } from 'helpers/constants';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { editPost, post, removeAttachment } from 'services/community.service';
import { StyledInput, StyledSlider, Switch } from 'components/switch/style';
import { useAccount, useHttp } from '../../../../../hooks';
import useContribution from '../../../hooks/useContribution';
import * as actions from '../../../../../actions/community';
import { Attachments } from '../Attachments/Attachments';
import {
  StyledContainer,
  StyledWrapper,
  AvatarComponent,
  StyledInputWrapper,
  StyledTextArea,
  StyledButton,
  StyledTextAreaWrap,
  StyledButtonEditWrap,
  StyledLabel,
  StyledTextError,
  StyledTextAreaIcon,
  StyledAttachFileIcon,
  StyledPhotoCameraIcon,
  StyledEmojiObjectsIcon,
} from './CreatePost.styled';
import ButtonProgress from '../../../../../components/FormUI/ButtonProgress';
import { COUNT_POSTS_BY_PAGE, DAILY_BY_SEND_DATA, TOOLTIP } from '../../../../../constants';
import { colors } from '../../../../../utils/styles';

export const ROW_HEIGHT = 20;

const CreatePost = ({ item, isEdit, onSubmit }) => {
  const { user } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const contribution = useContribution();
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const [isPrivate, setIsPrivate] = useState(item?.isPrivate);
  const [rows, setRows] = useState(0);
  const [textareaValue, setTextareaValue] = useState(item?.text);
  // TODO move to redux
  const [draftPost, setDraftPost] = useState(null);
  const [bubbleIsMarked, setBubbleIsMarked] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [editPostPending, setEditPostPending] = useState(false);
  const dispatch = useDispatch();
  const { request, loading } = useHttp();
  const { currentRole } = useAccount();
  const timer = useRef(null);
  const formRef = useRef(null);
  const isClient = currentRole === UserRoles.client;

  useEffect(() => {
    setRows((textareaValue?.match(/\n/g) || []).length);
  }, [textareaValue]);
  useEffect(() => {
    if (item?.isDraft || isEdit) {
      setDraftPost(item);
      setBubbleIsMarked(item?.isBubbled);
      setAttachments(item?.attachments);
    } else {
      createDraftPost();
      setDraftPost(null);
      setBubbleIsMarked(false);
      setAttachments([]);
    }
  }, [item]);

  const handlePostCreate = (e, { resetForm }) => {
    setEditPostPending(true);
    const currentItem = item || draftPost;
    clearTimeout(timer.current);
    editPost({
      ...currentItem,
      text: e.text,
      isBubbled: bubbleIsMarked,
      isDraft: false,
      isPrivate,
      userId: user.id,
      attachments,
    })
      .then(() => {
        dispatch(actions.getAllPostsForContribution(`${contribution.id}/1/${COUNT_POSTS_BY_PAGE}`));
        resetForm({});
        setDraftPost(null);
        onSubmit(false);
        setIsPrivate(false);
        createDraftPost('', true);
        setEditPostPending(false);
        setTextareaValue('');
        setRows(0);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const createDraftPost = (value = '', isCreateDraft = false) => {
    if (isNull(draftPost) || isCreateDraft) {
      post({
        contributionId: contribution.id,
        text: value,
        isDraft: true,
        isPrivate,
        attachments,
      })
        .then(data => {
          setDraftPost(data);
          setIsPrivate(data.isDraft);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  const handleKeyUp = useCallback(
    ({ target: { value } }) => {
      if (!isEdit && !loading) {
        clearTimeout(timer.current);
        timer.current = setTimeout(function () {
          editPost({
            ...draftPost,
            text: value,
            isBubbled: bubbleIsMarked,
            isDraft: true,
            isPrivate,
            attachments,
          }).then(() => {});
        }, DAILY_BY_SEND_DATA);
      }
      setTextareaValue(value);
      setRows((value.match(/\n/g) || []).length);
    },
    [rows, setRows, draftPost, attachments, loading],
  );

  const handleToggleBubble = () => {
    if (isEdit) {
      setDraftPost({
        ...draftPost,
        isBubbled: !bubbleIsMarked,
      });
      setBubbleIsMarked(!bubbleIsMarked);
    } else {
      setEditPostPending(true);
      editPost({
        ...draftPost,
        isBubbled: !bubbleIsMarked,
        userId: user.id,
        attachments,
      })
        .then(data => {
          setDraftPost(data);
          setBubbleIsMarked(data.isBubbled);
          setEditPostPending(false);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const handleUploadFile = useCallback(
    ({ target: { files } }) => {
      if (files.length >= 1) {
        const formData = new FormData();
        formData.append('file', R.head(files));
        formData.append('postId', draftPost?.id);
        formData.append('fileName', files[0].name);
        request('/Post/Attachment', 'POST', formData, {
          'Content-Type': 'multipart/form-data',
        })
          .then(data => {
            setAttachments(data.attachments);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    [dispatch, request, draftPost, attachments],
  );
  if (!activeContribution.arePublicPostsAllowed && isClient) {
    return false;
  }
  const handleRemoveAttachment = index => {
    const linkId = attachments[index].id;
    const newAttachments = filter(attachments, attachment => attachment.id !== linkId);
    removeAttachment(`${draftPost?.id}/${linkId}`)
      .then(() => {
        setAttachments(newAttachments);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const handleSubmitMyForm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const handleTogglePrivate = () => {
    if (!isEdit) {
      setEditPostPending(true);
      editPost({
        ...draftPost,
        text: textareaValue,
        isBubbled: bubbleIsMarked,
        isDraft: true,
        isPrivate: !isPrivate,
        userId: user.id,
        attachments,
      }).then(() => {
        setEditPostPending(false);
      });
    }
    setIsPrivate(!isPrivate);
  };
  return (
    <>
      <StyledWrapper container direction="row" xs={12} boxShadow={isEdit}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            text: draftPost?.text || '',
          }}
          validateOnMount
          onSubmit={handlePostCreate}
          validationSchema={Yup.object().shape({
            text: Yup.string()
              .strict(true)
              .min(0)
              .max(500, 'Cannot exceed 500 characters')
              .required('This is a required field'),
          })}
          id="post-create-form"
        >
          {formProps => {
            return (
              <StyledContainer mobileView={mobileView} additionalHeight={rows * ROW_HEIGHT} container>
                <AvatarComponent
                  alt={`${user.firstName} ${user.lastName}`}
                  src={user.avatarUrl}
                  mobileView={mobileView}
                >
                  {`${user.firstName && user.firstName[0]}${user.lastName && user.lastName[0]}`}
                </AvatarComponent>
                <Grid container direction="column">
                  <Grid container direction="row">
                    <StyledInputWrapper
                      container
                      hasText={textareaValue?.length > 25}
                      direction="row"
                      justify="space-between"
                      additionalHeight={rows * ROW_HEIGHT}
                      additionalWidth={isEdit ? 0 : 65}
                      mobileView={mobileView}
                    >
                      <StyledTextAreaWrap item xs={12} mobileView={mobileView} style={{ height: '100%' }}>
                        {/* TODO replace to react-textarea-autosize */}
                        <StyledTextArea
                          placeholder="What’s on your mind..."
                          component="textarea"
                          name="text"
                          fullWidth
                          onKeyUp={handleKeyUp}
                          mobileView={mobileView}
                        />
                        <StyledTextAreaIcon>
                          <StyledEmojiObjectsIcon
                            mobileView={mobileView}
                            onClick={handleToggleBubble}
                            style={{ color: `${bubbleIsMarked ? colors.gold : colors.smokedGray}` }}
                            className="smoked-gary-color hoverable"
                          />
                        </StyledTextAreaIcon>
                        <StyledTextAreaIcon disabled={loading}>
                          <StyledLabel htmlFor={`attach-media${draftPost?.id || item?.id}`}>
                            <input
                              accept="image/jpeg,image/png,image/gif,video/mpeg,video/mp4"
                              className="d-none"
                              id={`attach-media${draftPost?.id || item?.id}`}
                              type="file"
                              onChange={handleUploadFile}
                            />
                            <StyledPhotoCameraIcon mobileView={mobileView} className="smoked-gary-color hoverable" />
                          </StyledLabel>
                        </StyledTextAreaIcon>
                        <StyledTextAreaIcon disabled={loading}>
                          <StyledLabel htmlFor={`attach-file${draftPost?.id || item?.id}`}>
                            <input
                              accept="*"
                              className="d-none"
                              id={`attach-file${draftPost?.id || item?.id}`}
                              type="file"
                              onChange={handleUploadFile}
                            />
                            <StyledAttachFileIcon mobileView={mobileView} className="smoked-gary-color hoverable" />
                          </StyledLabel>
                        </StyledTextAreaIcon>
                      </StyledTextAreaWrap>
                    </StyledInputWrapper>
                    {!isEdit && (
                      <StyledButton
                        disabled={editPostPending || textareaValue?.length <= 0}
                        autoWidth
                        type="submit"
                        id="post-create-form"
                        onClick={formProps.handleSubmit}
                      >
                        {editPostPending ? (
                          <CircularProgress size={36} />
                        ) : (
                          <SendIcon
                            fontSize="small"
                            style={{ color: '#fff' }}
                            className="smoked-gary-color hoverable"
                          />
                        )}
                      </StyledButton>
                    )}
                  </Grid>
                  {formProps.errors.text && formProps.values.text.length > 0 && formProps.dirty && (
                    <StyledTextError mobileView={mobileView}>{formProps.errors.text}</StyledTextError>
                  )}
                  {textareaValue && !isClient && (
                    <Grid container className="mt-3" justify="flex-end">
                      <div className="mr-2">
                        <Tooltip
                          title="When you turn off “Members Only” this community post will be public to anyone who has your contribution link. Your clients community posts will only be seen by members."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor={colors.lightBrown} />
                        </Tooltip>
                      </div>
                      <Switch className="switch">
                        <StyledInput type="checkbox" onClick={handleTogglePrivate} checked={isPrivate} />
                        <StyledSlider className="slider round" />
                      </Switch>

                      <div className="ml-2 mr-3">Members Only</div>
                    </Grid>
                  )}
                </Grid>
              </StyledContainer>
            );
          }}
        </Formik>
        <Attachments pending={loading} attachments={attachments} removeAttachment={handleRemoveAttachment} />
      </StyledWrapper>
      {isEdit && (
        <StyledButtonEditWrap>
          <ButtonProgress
            disabled={editPostPending || textareaValue.length <= 0}
            pending={editPostPending}
            autoWidth
            type="submit"
            id="post-create-form"
            onClick={handleSubmitMyForm}
          >
            Publish
          </ButtonProgress>
        </StyledButtonEditWrap>
      )}
    </>
  );
};
CreatePost.defaultProps = {
  onSubmit: () => {},
  isEdit: false,
};
export default CreatePost;
