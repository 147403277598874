import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Button from 'components/FormUI/Button'
import Select from 'components/FormUI/Select'
import Loader from 'components/UI/Loader'
import { AddCalendarModalBox } from './AddCalendarModalBox'
import { ADD_CALENDAR_MODAL_FIELDS } from './AddCalendarModalConstants'

const StyledLabel = styled.span`
  font-weight: 800;
`
const StyledLabelSecondary = styled.p`
  color: #a4a4a4;
  font-size: 12px;
  line-height: 14px;
`
const StyledContent = styled.p`
  margin-top: 12px;
`
const StyledRow = styled(Grid)`
  padding: 16px 0;
`
const StyledButton = styled(Button)`
  min-width: 95px;
  width: 100%;
`
const StyledLink = styled(Link)`
  font-size: 12px;
`

// const firstSelectValue = [{ title: "Add calendar", value: "Add calendar" }];

export const AddCalendarModalContent = ({
  addCalendar,
  calendars = [],
  contributionId,
  isCreate,
  lastAddedMail,
  loadingLastEmail,
  setFieldValue,
  preparedCheckedCalendars,
}) => {
  const preparedValues =
    calendars &&
    calendars.map((el) => ({
      title: el.emailAddress,
      value: el.emailAddress,
    }))
  // const renderAddToCalendarValue = (selected) => {
  // const Icon = getDomainIcon(selected);
  //   return <StyledValue>
  //      <Icon /> <span className="ml-2">{selected}</span>
  //    </StyledValue>
  //  }

  const onLinkClick = (e) => {
    e.preventDefault()
    addCalendar({ contributionId, isCreate })
  }

  let selectDefaultValue = null

  if (lastAddedMail) {
    const foundCalendar = calendars && calendars.find((el) => el.emailAddress === lastAddedMail)
    if (foundCalendar) {
      selectDefaultValue = {
        title: foundCalendar.emailAddress,
        value: foundCalendar.emailAddress,
      }
    }
  }

  useEffect(() => {
    if (!preparedCheckedCalendars?.length && lastAddedMail) {
      setFieldValue('checkedCalendars', [lastAddedMail])
    }
  }, [lastAddedMail])

  return (
    <Grid container xs={12}>
      {loadingLastEmail && <Loader />}
      <StyledRow container item xs={12}>
        <Grid item sm={6} xs={12}>
          <StyledLabel>Prevent Double Booking</StyledLabel>
          <StyledContent>
            Add your personal calendars to prevent schedule conflicts.
            <br />
            <StyledLabelSecondary>
              Nylas is a trusted partner of Cohere. They power our connected calendar features.
            </StyledLabelSecondary>
          </StyledContent>
        </Grid>
        <Grid item sm={6} xs={12}>
          <AddCalendarModalBox additionalField={<StyledLink onClick={onLinkClick}>Add another calendar</StyledLink>}>
            {calendars.length ? (
              <Select
                name={ADD_CALENDAR_MODAL_FIELDS.CHECKED_CALENDARS}
                fullWidth
                multiple
                items={preparedValues}
                defaultValue={[selectDefaultValue]}
              />
            ) : (
              <StyledButton onClick={onLinkClick}>Add first calendar</StyledButton>
            )}
          </AddCalendarModalBox>
        </Grid>
      </StyledRow>

      {/* To do in future release */}

      {/* <StyledRow container item xs={12}>
        <Grid item sm={6} xs={12}>
          <StyledLabel>Receive Calendar Invites</StyledLabel>
          <StyledContent>Add your primary calendar to receive confirmed client bookings and course sessions.</StyledContent>
        </Grid>
        <Grid item sm={6} xs={12}>
          <AddCalendarModalBox>
          {calendars.length ?
            <Select
              name={ADD_CALENDAR_MODAL_FIELDS.CALENDAR}
              fullWidth
              renderValue={renderAddToCalendarValue}
              items={preparedValues}
            />
            :
            <Select
            name={ADD_CALENDAR_MODAL_FIELDS.CHECKED_CALENDARS}
            fullWidth
            items={firstSelectValue}
            onChange={onLinkClick}
          />
        }
          </AddCalendarModalBox>
        </Grid>
      </StyledRow> */}
    </Grid>
  )
}
