import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import ContributionViewAdminPage from 'pages/Admin/ContributionViewAdminPage';
import AdminContibutionsContainer from './AdminContibutionsContainer';

const AdminContainer = ({ match: { path } }) => {
  return (
    <Switch>
      <Route exact path={`${path}/contributions/:type`} component={AdminContibutionsContainer} />
      <Route path={`${path}/contribution-view/:id`} component={ContributionViewAdminPage} />
      <Redirect to={`${path}/contributions/review`} />
    </Switch>
  );
};

AdminContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default AdminContainer;
