import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Icon } from '@mdi/react';
import { mdiEyeOff, mdiEye, mdiLoading } from '@mdi/js';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import queryString from 'querystring';

import Input from 'components/FormUI/Input';
import Button from 'components/FormUI/Button';
import Logo from 'components/FormUI/Logo';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { getAffiliateName } from 'services/affiliate.service';
import { Card } from 'components/UI/Card';
// import FacebookButton from "components/FacebookButton";
// import GoogleButton from "components/GoogleButton";
import { useRouter } from 'hooks';
import { getLoginValidationSchema } from 'utils/validation';
// import { Translate } from "react-localize-redux";
import { toRem, devices } from 'utils/styles';
import * as userActions from 'actions/user';
import { UserRoles } from 'helpers/constants';

const CONTAINER_WIDTH = 520;

const Container = styled.div`
  max-width: ${CONTAINER_WIDTH}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  box-sizing: border-box;

  @media screen and (${devices.mobile}) {
    padding: 50px 100px;
  }
`;

const WelcomeMessage = styled.h1`
  font-size: 1.675rem;
  text-align: center;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.5rem;
`;

const StyledWelcomeMessage = styled.h1`
  font-size: ${toRem(22.3)};
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
`;

const StyledSecondMessage = styled.h1`
  font-size: ${toRem(16)};
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: black;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.75rem;
  width: 100%;

  & > * + * {
    margin-top: 1.5rem;
  }
`;

const StyledLink = styled(Link)`
  margin-top: 1rem;
  text-align: center;
  text-decoration: none;
  line-height: 1.17;
  letter-spacing: 0.4px;
  font-size: 0.875rem;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledAffiliate = styled.div``;

const useStyles = makeStyles(() => ({
  input: {
    '& + &': {
      marginTop: '1rem',
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

function SignUpFirstScreen({ loading, register, error }) {
  const classes = useStyles();
  const { pathname, history } = useRouter();
  const [showPassword, setShowPassword] = useState(false);
  const [client, setClient] = useState(false);
  const [inviterName, setInviterName] = useState(undefined);
  const { search } = useLocation();
  const parsedSearch = queryString.parse(search.slice(1));
  useEffect(() => {
    if (parsedSearch.inviteCode) {
      getAffiliateName(parsedSearch.inviteCode).then(userName => setInviterName(userName));
    }
  }, [setInviterName]);
  useEffect(() => {
    if (pathname.includes('client')) {
      setClient(true);
    } else {
      setClient(false);
    }
  }, [pathname]);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(state => !state);
  }, [setShowPassword]);

  const registerHandler = useCallback(
    ({ email, password }) => {
      const onSuccess = () => {
        history.push(`${pathname}/continue`);
      };
      const userView = pathname.includes('client') ? UserRoles.client : UserRoles.cohealer;
      register(email, password, parsedSearch.inviteCode, onSuccess, userView);
    },
    [register, history, pathname],
  );

  return (
    <>
      <Card>
        <Container>
          <Logo />
          {inviterName && <StyledAffiliate>In partnership with {inviterName}</StyledAffiliate>}
          {client ? (
            <>
              <StyledWelcomeMessage>Transformation happens live</StyledWelcomeMessage>
              <StyledSecondMessage>Connect with coaches, teachers, and community</StyledSecondMessage>
            </>
          ) : (
            <WelcomeMessage>Welcome to Cohere. We’re excited to connect</WelcomeMessage>
          )}

          <Formik
            initialValues={{ email: '', password: '' }}
            // validationSchema={getLoginValidationSchema(Translate)}
            validationSchema={getLoginValidationSchema()}
            onSubmit={registerHandler}
          >
            {() => (
              <Form id="signUpForm">
                <Input className={classes.input} label="Email" type="email" name="email" fullWidth />
                <Input
                  className={classes.input}
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  endAdornment={
                    <InputAdornment>
                      <IconButton onClick={handleClickShowPassword}>
                        <Icon path={showPassword ? mdiEye : mdiEyeOff} size={1} />
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth
                />

                <ButtonsContainer>
                  {error.message && <CommonErrorMessage message={error.message} />}
                  <Button disabled={loading} type="submit">
                    {loading ? <Icon path={mdiLoading} size={1} spin={1} /> : 'Create account'}
                  </Button>
                  {/* <FacebookButton /> */}
                  {/* <GoogleButton /> */}
                </ButtonsContainer>
              </Form>
            )}
          </Formik>
        </Container>
      </Card>

      <div className={classes.link}>
        {client ? (
          <StyledLink to="/auth/signup">Are you a service provider? Click here</StyledLink>
        ) : (
          <StyledLink to="/auth/signup/client">Not a service provider? Click here</StyledLink>
        )}
        <StyledLink to="/auth/signin">Already have an account? Login here.</StyledLink>
      </div>
    </>
  );
}

SignUpFirstScreen.propTypes = {
  register: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

SignUpFirstScreen.defaultProps = {
  error: null,
};

const mapStateToProps = ({ account }) => ({
  loading: account?.isLoading,
  error: account?.error || {},
});

const actions = {
  register: userActions.register,
};

export default connect(mapStateToProps, actions)(SignUpFirstScreen);
