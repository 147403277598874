import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { StyledTitle, StyledButton } from '../GettingStarted.styles'
import { ROUTES } from 'constants.js'

const StyledImage = styled.img`
  margin-top: 20px;
  height: 60%;

  ${({ mobileView }) =>
    mobileView &&
    `
    width: 80%;
    height: auto;
  `}
`

const Services = ({ mobileView }) => {
  return (
    <>
      <StyledImage src="/get-started-creation.png" mobileView={mobileView} />

      <StyledTitle mobileView={mobileView}>
        Create and launch your services. On Cohere, services are called Contributions.
      </StyledTitle>

      <Link to={ROUTES.CREATE_CONTRIBUTION_BASIC} target="_blank">
        <StyledButton variant="primary">Create a Contribution</StyledButton>
      </Link>
    </>
  )
}

export default Services
