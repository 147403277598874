import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Form, FieldArray } from 'formik';
import moment from 'moment';
import * as R from 'ramda';
import Tooltip from '@material-ui/core/Tooltip';

import Button from 'components/FormUI/Button';
import DateTimePicker from 'components/FormUI/DateTimePicker';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { toRem, colors } from 'utils/styles';
import { useShallowEqualSelector } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { getNextCurrentSessionTime } from 'utils/getSessionTime';
import { TIMEZONES, LiveVideoProvider } from 'constants.js';
import Session from './Session';
import Select from 'components/FormUI/Select';
import Input from 'components/FormUI/Input';
import InfoIcon from '@material-ui/icons/Info';
import { TOOLTIP } from '../../../constants.js';
import Sessions from './MembershipForm/components/Sessions';

const StyledTimeZoneLabel = styled.p`
  display: inline-block;
  font-size: ${toRem(13)};
  background: #116582;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;

const LiveForm = ({ values, errors, touched, setFieldValue }) => {
  const [sessionCount, setSessionCount] = useState(R.inc(values?.sessions.length));
  const user = useShallowEqualSelector(state => state?.account?.user);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Form id="create-contribution-form">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card mobileView={mobileView}>
            <CardHeader mobileView={mobileView}>
              <PageTitleSecond mobileView={mobileView}>Enrollment Dates</PageTitleSecond>
            </CardHeader>
            <CardBody mobileView={mobileView}>
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                  <DateTimePicker
                    fullWidth
                    name="enrollment.fromDate"
                    type="date"
                    label="From"
                    minDate={moment().add(0, 'd')}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DateTimePicker
                    fullWidth
                    name="enrollment.toDate"
                    defaultHour={23}
                    defaultMinute={59}
                    defaultSecond={59}
                    defaultMillisecond={999}
                    type="date"
                    label="To"
                    minDate={moment().add(0, 'd')}
                  />
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>

        {/*<Grid item xs={12}>*/}
        {/*  <Card mobileView={mobileView}>*/}
        {/*    <CardHeader mobileView={mobileView}>*/}
        {/*      <PageTitleSecond mobileView={mobileView}>Sessions</PageTitleSecond>*/}
        {/*      <NavLink to="/account/profile">*/}
        {/*        <StyledTimeZoneLabel>{TIMEZONES[user.timeZoneId]}</StyledTimeZoneLabel>*/}
        {/*      </NavLink>*/}
        {/*      <Tooltip*/}
        {/*        title="You can edit contribution sessions, update times and even add additional sessions after the contribution is created."*/}
        {/*        arrow*/}
        {/*        className="ml-2"*/}
        {/*        enterTouchDelay={TOOLTIP.ENTER_DELAY}*/}
        {/*        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}*/}
        {/*      >*/}
        {/*        <InfoIcon htmlColor={colors.lightBrown} />*/}
        {/*      </Tooltip>*/}
        {/*    </CardHeader>*/}
        {/*    <CardBody mobileView={mobileView}>*/}
        {/*      {" "}*/}
        {/*      <FieldArray name="sessions">*/}
        {/*        {({ push, remove }) => {*/}
        {/*          const addHandler = () => {*/}
        {/*            const lastSession = values.sessions[values.sessions.length - 1];*/}
        {/*            const lastSessionTime =*/}
        {/*              lastSession && lastSession.sessionTimes.length > 0*/}
        {/*                ? lastSession.sessionTimes[lastSession.sessionTimes.length - 1]*/}
        {/*                : {};*/}
        {/*            push({*/}
        {/*              title: `Session ${sessionCount}`,*/}
        {/*              minParticipantsNumber: lastSession ? lastSession.minParticipantsNumber : 2,*/}
        {/*              maxParticipantsNumber: lastSession ? lastSession.maxParticipantsNumber : 10,*/}
        {/*              sessionTimes: [*/}
        {/*                getNextCurrentSessionTime(false, lastSessionTime.startTime || values.enrollment.toDate)*/}
        {/*              ]*/}
        {/*            });*/}

        {/*            setSessionCount(R.inc);*/}
        {/*          };*/}

        {/*          const removeHandler = (id) => {*/}
        {/*            remove(id);*/}
        {/*            setSessionCount(R.dec);*/}
        {/*          };*/}

        {/*          return (*/}
        {/*            <>*/}
        {/*              {values.sessions.map((session, index) => {*/}
        {/*                const key = `${index}`;*/}
        {/*                return (*/}
        {/*                  <Session*/}
        {/*                    key={key}*/}
        {/*                    index={index}*/}
        {/*                    timesCount={session?.sessionTimes?.length || 1}*/}
        {/*                    onDelete={removeHandler}*/}
        {/*                  />*/}
        {/*                );*/}
        {/*              })}*/}

        {/*              <Button type="button" onClick={addHandler} autoWidth>*/}
        {/*                Create More Sessions 11*/}
        {/*              </Button>*/}
        {/*            </>*/}
        {/*          );*/}
        {/*        }}*/}
        {/*      </FieldArray>*/}
        {/*      {errors.sessions && touched.sessions && typeof errors.sessions === "string" && (*/}
        {/*        <CommonErrorMessage message={errors.sessions} />*/}
        {/*      )}*/}
        {/*    </CardBody>*/}
        {/*  </Card>*/}
        {/*</Grid>*/}
        <Sessions
          values={values}
          enrollment={values.enrollment}
          providerName={values.liveVideoServiceProvider.providerName}
          sessions={values.sessions}
          errors={errors}
          touched={touched}
        />
        <Grid item xs={12}>
          <Card mobileView={mobileView}>
            <CardHeader mobileView={mobileView}>
              <PageTitleSecond mobileView={mobileView}>Live Video</PageTitleSecond>
            </CardHeader>
            <CardBody mobileView={mobileView}>
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                  <Select
                    label="Live Video Service Provider"
                    name="liveVideoServiceProvider.providerName"
                    onChange={({ target }) => {
                      setFieldValue(target.name, target.value);
                    }}
                    fullWidth
                    items={[LiveVideoProvider.Cohere, LiveVideoProvider.Custom]}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Input
                    hidden={
                      values.liveVideoServiceProvider &&
                      values.liveVideoServiceProvider.providerName === LiveVideoProvider.Cohere.value
                    }
                    placeholder="Enter Private Link"
                    name="liveVideoServiceProvider.customLink"
                    onChange={({ target }) => {
                      setFieldValue(target.name, target.value, false);
                    }}
                    fullWidth
                    style={{ flexGrow: 1 }}
                    label={
                      <div>
                        Use Your Private Room link instead (Zoom or Others)
                        <Tooltip
                          title="Cohere provides a built-in rich & streamlined video experience for you and your clients.
                          The Cohere video experiences saves your in-video chat threads, private session notes, and automates video recordings.
                          If you instead wish to use another video provider without these features,
                          you can add your private room link below and we’ll make sure your clients show up there instead.
                          When you and your clients access this Contribution,
                          the session launch button will open your platform of choice and we’ll also update calendar invites for both you and your clients."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <StyledInfoIcon fontSize="small" htmlColor={colors.lightBrown} />
                        </Tooltip>
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

LiveForm.propTypes = {
  values: PropTypes.shape({
    sessions: PropTypes.arrayOf(),
    enrollment: PropTypes.shape({
      toDate: PropTypes.string,
      fromDate: PropTypes.string,
    }).isRequired,
  }).isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
};

export default LiveForm;
