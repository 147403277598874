import React from 'react';

import { useHttp, useAccount } from 'hooks';
import { redirectTo } from 'services/links';

import Loader from 'components/UI/Loader';
import Grid from '@material-ui/core/Grid';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import Button from 'components/FormUI/Button';

import '../../Payment.scss';

const VerificationCard = () => {
  const { request, loading } = useHttp();
  const { user } = useAccount();

  if (loading) {
    return <Loader />;
  }

  const onVerificate = () => {
    request(`api/Payment/generate-account-verification-link`, 'POST').then(({ link }) => {
      redirectTo(link);
    });
  };

  return (
    <Grid item xs={10} classes={{ root: 'card-container' }}>
      <Card maxHeight>
        <CardHeader>
          <PageTitleSecond>Verification</PageTitleSecond>
        </CardHeader>
        <CardBody className="card-body-container">
          <p className="card-body-container__text">
            {!user.transfersEnabled
              ? 'Verify your information to create a Contribution and collect revenue.'
              : 'Your information has been verified!'}
          </p>
          {!user.transfersEnabled && (
            <Button autoWidth variant="primary" onClick={onVerificate}>
              Verify
            </Button>
          )}
        </CardBody>
      </Card>
    </Grid>
  );
};

export default VerificationCard;
