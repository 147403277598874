import { handleActions } from 'redux-actions';

import * as actions from 'actions/calendars';

const initialState = {
  loading: false,
  loadingBusyTimes: false,
  error: null,
  calendarsAccounts: [],
  accountsForConflictsCheck: [],
  accountForSendReminders: '',
  busyTimes: [],
  lastAddedEmail: null,
};

const calendars = handleActions(
  new Map([
    [
      actions.fetchCalendarsActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
        calendarsAccounts: [],
      }),
    ],
    [
      actions.fetchCalendarsActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        calendarsAccounts: action.payload,
      }),
    ],
    [
      actions.fetchCalendarsActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        calendarsAccounts: [],
      }),
    ],

    [
      actions.addCalendarAccountActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.addCalendarAccountActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
      }),
    ],
    [
      actions.addCalendarAccountActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],

    [
      actions.calendarSignInCallbackActions.request,
      state => ({
        ...state,
        error: null,
        loadingLastEmail: true,
      }),
    ],
    [
      actions.calendarSignInCallbackActions.success,
      (state, action) => ({
        ...state,
        lastAddedEmail: action.payload,
        loadingLastEmail: false,
        error: null,
      }),
    ],
    [
      actions.calendarSignInCallbackActions.error,
      (state, action) => ({
        ...state,
        loadingLastEmail: false,
        error: action.payload,
      }),
    ],

    [
      actions.acсountsForConflictsCheckActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.acсountsForConflictsCheckActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        accountsForConflictsCheck: action.payload,
      }),
    ],
    [
      actions.acсountsForConflictsCheckActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],

    [
      actions.acсountForSendRemindersActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.acсountForSendRemindersActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        accountForSendReminders: action.payload?.emailAddress,
      }),
    ],
    [
      actions.acсountForSendRemindersActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],

    [
      actions.removeCalendarAccountActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.removeCalendarAccountActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        calendarsAccounts: state.calendarsAccounts.filter(account => account.emailAddress !== action.payload),
        accountsForConflictsCheck: state.accountsForConflictsCheck.filter(
          account => account.emailAddress !== action.payload,
        ),
        accountForSendReminders: action.payload === state.accountForSendReminders ? '' : state.accountForSendReminders,
        busyTimes: [],
      }),
    ],
    [
      actions.removeCalendarAccountActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],

    [
      actions.getBusytimeActions.request,
      state => ({
        ...state,
        error: null,
        loadingBusyTimes: true,
      }),
    ],
    [
      actions.getBusytimeActions.success,
      (state, action) => ({
        ...state,
        loadingBusyTimes: false,
        error: null,
        busyTimes: action.payload,
      }),
    ],
    [
      actions.getBusytimeActions.error,
      (state, action) => ({
        ...state,
        loadingBusyTimes: false,
        error: action.payload,
      }),
    ],

    [
      actions.putMailsForConfictCheckActions.error,
      (state, action) => ({
        ...state,
        loadingBusyTimes: false,
      }),
    ],
  ]),
  initialState,
);

export default calendars;
