import { handleActions } from 'redux-actions';

import * as actions from 'actions/contributions';

const initialState = {
  loading: false,
  error: null,
  upcomingCreated: [],
};

const upcomingCreated = handleActions(
  new Map([
    [
      actions.fetchUpcomingCreatedActions.request,
      state => ({
        ...state,
        error: null,
        loading: true,
        upcomingCreated: null,
      }),
    ],
    [
      actions.fetchUpcomingCreatedActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        upcomingCreated: action.payload,
      }),
    ],
    [
      actions.fetchUpcomingCreatedActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        upcomingCreated: [],
      }),
    ],
  ]),
  initialState,
);

export default upcomingCreated;
