import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as pageActions from 'actions/page';
import Drawer from '@material-ui/core/Drawer';

import Sidebar from 'components/UI/Sidebar';
import Header from 'components/UI/Header';

const StyledMainContainer = styled.div`
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
`;

const StyledRightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`;

function MainContainer({ headerProps, children, sidebarProps }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobileSidebarNeeded = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileSidebarOpen = useSelector(state => state.page.isMobileSidebarOpen);
  const toggleMobilesidebar = () => {
    dispatch(pageActions.toggleMobileSidebar());
  };
  const sidebar = <Sidebar {...sidebarProps} />;

  return (
    <StyledMainContainer>
      {isMobileSidebarNeeded ? (
        <Drawer open={!isMobileSidebarOpen} onClose={toggleMobilesidebar}>
          {sidebar}
        </Drawer>
      ) : (
        sidebar
      )}
      <StyledRightSection>
        <Header {...headerProps} />
        {children}
      </StyledRightSection>
    </StyledMainContainer>
  );
}

MainContainer.propTypes = {
  headerProps: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  sidebarProps: PropTypes.shape({}),
};

MainContainer.defaultProps = {
  headerProps: {},
  sidebarProps: {},
};

export default MainContainer;
