import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal';
import copyContributionLinkToClipboard from 'utils/copyToClipboard';
import { useTheme, useMediaQuery } from '@material-ui/core';
import InviteCoachModalView from './InviteCoachModalView';

const InviteCoachModalContainer = ({ isOpen, onModalClose, onSubmit, emailsError, setEmailsError }) => {
  const [emails, setEmails] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCopyToClipboard = useCallback(e => {
    e.preventDefault();

    copyContributionLinkToClipboard();
    setTooltipOpen(true);
  }, []);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      onSubmit(emails);
    },
    [onSubmit, emails],
  );

  return (
    <Modal
      title="Invite Coach"
      isOpen={isOpen}
      onCancel={onModalClose}
      onSubmit={handleSubmit}
      submitTitle={mobileView ? 'Send' : 'Send Invitations'}
    >
      <InviteCoachModalView
        emails={emails}
        onSetEmails={setEmails}
        onCopyToClipboard={handleCopyToClipboard}
        emailsError={emailsError}
        setEmailsError={setEmailsError}
        mobileView={mobileView}
        tooltipOpen={tooltipOpen}
        setTooltipOpen={setTooltipOpen}
      />
    </Modal>
  );
};

InviteCoachModalContainer.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  emailsError: PropTypes.shape({
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  setEmailsError: PropTypes.func.isRequired,
};

export default InviteCoachModalContainer;
