import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const loginUser = (email, password) => axiosInstance.post('/auth/signin', { email, password }).then(get('data'));

const restorePassword = email => axiosInstance.get(`/Account/RestorePassword/${email}/RequestLink`).then(get('data'));

const newPassword = data => axiosInstance.post('/Account/RestorePassword', data).then(get('data'));

const finishGetStartedGuide = () => axiosInstance.post('/Account/FinishGettingStartedGuide').then(get('data'));

const registerUser = (email, password, inviteCode, userView) =>
  axiosInstance
    .post('/account', { email, password, invitedBy: inviteCode, accountPreferences: { userView } })
    .then(get('data'));

const continueRegisterUser = fields => axiosInstance.post('/user', fields).then(get('data'));

const editUserProfile = (id, fields) => axiosInstance.put(`/user/${id}`, fields).then(get('data'));

const getUserProfile = id => axiosInstance.get(`/user/${id}`).then(get('data'));

const switchFromCoachToClient = () => axiosInstance.post(`/User/SwitchFromCoachToClient`).then(get('data'));

const switchFromClientToCoach = data => axiosInstance.post(`/User/SwitchFromClientToCoach`, data).then(get('data'));

const getCohealerIcon = id => axiosInstance.get(`/User/GetCohealerIcon/${id}`).then(get('data'));

export {
  loginUser,
  restorePassword,
  newPassword,
  finishGetStartedGuide,
  registerUser,
  continueRegisterUser,
  editUserProfile,
  getUserProfile,
  switchFromCoachToClient,
  switchFromClientToCoach,
  getCohealerIcon,
};
