import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
// import { Translate } from "react-localize-redux";
import { Icon } from '@mdi/react';
import { mdiLoading } from '@mdi/js';
import moment from 'moment';
import { useTheme, useMediaQuery } from '@material-ui/core';

import { toRem } from 'utils/styles';
import { getSignUpValidationSchema } from 'utils/validation';
import { parseDate } from 'utils/datesAndMoney';
import Button from 'components/FormUI/Button';
import Select from 'components/FormUI/Select';
import TextArea from 'components/FormUI/TextArea';
import Input from 'components/FormUI/Input';
import MaskedInput from 'components/FormUI/MaskedInput';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { ProfileAvatar } from 'components/ProfileAvatar/ProfileAvatar';
import * as userActions from 'actions/user';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 550,
  },
  row: {
    '& + &': {
      marginTop: 20,
    },
  },
}));

const ButtonsContainer = styled.div`
  margin-top: ${toRem(24)};
`;

function ProfilePage({ user, editProfile, loading, error }) {
  const classes = useStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const initialValues = {
    FirstName: user.firstName,
    LastName: user.lastName,
    BirthDate: parseDate(user.birthDate),
    BusinessName: user.businessName,
    BusinessType: user.businessType,
    CustomBusinessType: user.customBusinessType || '',
    Certification: user.certification,
    Occupation: user.occupation,
    CustomerLabelPreference: user.customerLabelPreference || '',
    Bio: user.bio || '',
    TimeZoneId: user.timeZoneId || 'America/Los_Angeles',
    HasAgreedToTerms: true,
  };
  let savedValues = {};

  const editHandler = useCallback(
    values => {
      const userData = {
        ...values,
        id: user.id,
        accountId: user.accountId,
        avatarUrl: user.avatarUrl,
        BirthDate: moment.utc(values.BirthDate).format(),
      };

      if (!userData.CustomerLabelPreference) {
        delete userData.CustomerLabelPreference;
      }

      if (userData.BusinessType !== 'Other') {
        delete userData.CustomBusinessType;
      }

      editProfile(user.id, userData);
    },
    [editProfile, user.accountId, user.avatarUrl, user.id],
  );

  useEffect(() => {
    editHandler(savedValues);
  }, [user.avatarUrl]);

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      classes={{
        container: classes.container,
      }}
    >
      <Formik
        initialValues={initialValues}
        // validationSchema={getSignUpValidationSchema(Translate)}
        validationSchema={getSignUpValidationSchema()}
        onSubmit={editHandler}
      >
        {({ values }) => {
          savedValues = values;
          return (
            <Form id="signUpFormTwo">
              <div className="d-flex">
                <ProfileAvatar avatarUrl={user.avatarUrl} editHandler={editHandler} values={values} />
                <Grid container spacing={4} className={classes.row}>
                  <Grid item sm={4} xs={12}>
                    <Input label="First Name" type="text" name="FirstName" fullWidth />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Input label="Last Name" type="text" name="LastName" fullWidth />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Input
                      label="Date of Birth"
                      mask="date"
                      type="text"
                      name="BirthDate"
                      inputComponent={MaskedInput}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>

              <Grid container spacing={4} className={classes.row}>
                <Grid item sm={4} xs={12}>
                  <Input label="Business Name" type="text" name="BusinessName" helperText="optional" fullWidth />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Select
                    label="Business Type"
                    name="BusinessType"
                    fullWidth
                    items={[
                      { title: 'Coaching', value: 'Coaching' },
                      { title: 'Teaching', value: 'Teaching' },
                      { title: 'Mentoring', value: 'Mentoring' },
                      { title: 'Consulting', value: 'Consulting' },
                      { title: 'Other', value: 'Other' },
                    ]}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Input
                    label="Certification/Degree"
                    type="text"
                    name="Certification"
                    fullWidth
                    helperText="optional"
                  />
                </Grid>
              </Grid>

              {values.BusinessType === 'Other' && (
                <Grid container spacing={4} className={classes.row}>
                  <Grid item sm={12} xs={12}>
                    <Input label="Business Type" type="text" name="CustomBusinessType" fullWidth />
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={4} className={classes.row}>
                <Grid item sm={6} xs={12}>
                  <Input label="Occupation" type="text" name="Occupation" helperText="optional" fullWidth />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Select
                    label="Customer Label Preference"
                    name="CustomerLabelPreference"
                    fullWidth
                    helperText="optional"
                    items={[
                      { title: <em>None</em>, value: '' },
                      { title: 'Clients', value: 'Clients' },
                      { title: 'Students', value: 'Students' },
                      { title: 'Customers', value: 'Customers' },
                    ]}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4} className={classes.row}>
                <Grid item sm={12} xs={12}>
                  <Select
                    label="Timezone"
                    name="TimeZoneId"
                    fullWidth
                    items={[
                      {
                        value: 'America/Los_Angeles',
                        title: 'Pacific Standard Time',
                      },
                      {
                        value: 'America/Denver',
                        title: 'Mountain Standard Time',
                      },
                      {
                        value: 'America/Chicago',
                        title: 'Central Standard Time',
                      },
                      {
                        value: 'America/New_York',
                        title: 'Eastern Standard Time',
                      },
                      {
                        value: 'America/Anchorage',
                        title: 'Alaskan Standard Time',
                      },
                      {
                        value: 'America/St_Johns',
                        title: 'Newfoundland Standard Time',
                      },
                      {
                        value: 'Pacific/Honolulu',
                        title: 'Hawaiian Standard Time',
                      },
                      {
                        value: 'America/Phoenix',
                        title: 'Arizona Standard Time',
                      },
                      { value: 'Australia/Sydney', title: 'Australia/Sydney' },
                      { value: 'Canada/Atlantic', title: 'Canada/Atlantic' },
                      { value: 'Canada/Central', title: 'Canada/Central' },
                      { value: 'Canada/Eastern', title: 'Canada/Eastern' },
                      { value: 'Canada/Mountain', title: 'Canada/Mountain' },
                      {
                        value: 'Canada/Newfoundland',
                        title: 'Canada/Newfoundland',
                      },
                      { value: 'Canada/Pacific', title: 'Canada/Pacific' },
                      {
                        value: 'Canada/Saskatchewan',
                        title: 'Canada/Saskatchewan',
                      },
                      { value: 'Canada/Yukon', title: 'Canada/Yukon' },
                      { value: 'Europe/Amsterdam', title: 'Europe/Amsterdam' },
                      { value: 'Europe/Andorra', title: 'Europe/Andorra' },
                      { value: 'Europe/Athens', title: 'Europe/Athens' },
                      { value: 'Europe/Belfast', title: 'Europe/Belfast' },
                      { value: 'Europe/Belgrade', title: 'Europe/Belgrade' },
                      { value: 'Europe/Berlin', title: 'Europe/Berlin' },
                      { value: 'Europe/Bratislava', title: 'Europe/Bratislava' },
                      { value: 'Europe/Brussels', title: 'Europe/Brussels' },
                      { value: 'Europe/Bucharest', title: 'Europe/Bucharest' },
                      { value: 'Europe/Budapest', title: 'Europe/Budapest' },
                      { value: 'Europe/Busingen', title: 'Europe/Busingen' },
                      { value: 'Europe/Chisinau', title: 'Europe/Chisinau' },
                      { value: 'Europe/Copenhagen', title: 'Europe/Copenhagen' },
                      { value: 'Europe/Dublin', title: 'Europe/Dublin' },
                      { value: 'Europe/Gibraltar', title: 'Europe/Gibraltar' },
                      { value: 'Europe/Guernsey', title: 'Europe/Guernsey' },
                      { value: 'Europe/Helsinki', title: 'Europe/Helsinki' },
                      {
                        value: 'Europe/Isle_of_Man',
                        title: 'Europe/Isle of Man',
                      },
                      { value: 'Europe/Istanbul', title: 'Europe/Istanbul' },
                      { value: 'Europe/Jersey', title: 'Europe/Jersey' },
                      { value: 'Europe/Kaliningrad', title: 'Europe/Kiev' },
                      { value: 'Europe/Lisbon', title: 'Europe/Lisbon' },
                      { value: 'Europe/Ljubljana', title: 'Europe/Ljubljana' },
                      { value: 'Europe/London', title: 'Europe/London' },
                      { value: 'Europe/Luxembourg', title: 'Europe/Luxembourg' },
                      { value: 'Europe/Madrid', title: 'Europe/Madrid' },
                      { value: 'Europe/Malta', title: 'Europe/Malta' },
                      { value: 'Europe/Mariehamn', title: 'Europe/Mariehamn' },
                      { value: 'Europe/Minsk', title: 'Europe/Minsk' },
                      { value: 'Europe/Monaco', title: 'Europe/Monaco' },
                      { value: 'Europe/Moscow', title: 'Europe/Moscow' },
                      { value: 'Europe/Nicosia', title: 'Europe/Nicosia' },
                      { value: 'Europe/Oslo', title: 'Europe/Oslo' },
                      { value: 'Europe/Paris', title: 'Europe/Paris' },
                      { value: 'Europe/Podgorica', title: 'Europe/Podgorica' },
                      { value: 'Europe/Prague', title: 'Europe/Prague' },
                      { value: 'Europe/Riga', title: 'Europe/Riga' },
                      { value: 'Europe/Rome', title: 'Europe/Rome' },
                      { value: 'Europe/Samara', title: 'Europe/Samara' },
                      { value: 'Europe/San_Marino', title: 'Europe/San_Marino' },
                      { value: 'Europe/Sarajevo', title: 'Europe/Sarajevo' },
                      { value: 'Europe/Simferopol', title: 'Europe/Simferopol' },
                      { value: 'Europe/Skopje', title: 'Europe/Skopje' },
                      { value: 'Europe/Sofia', title: 'Europe/Sofia' },
                      { value: 'Europe/Stockholm', title: 'Europe/Stockholm' },
                      { value: 'Europe/Tallinn', title: 'Europe/Tallinn' },
                      { value: 'Europe/Tirane', title: 'Europe/Tirane' },
                      { value: 'Europe/Tiraspol', title: 'Europe/Tiraspol' },
                      { value: 'Europe/Uzhgorod', title: 'Europe/Uzhgorod' },
                      { value: 'Europe/Vaduz', title: 'Europe/Vaduz' },
                      { value: 'Europe/Vatican', title: 'Europe/Vatican' },
                      { value: 'Europe/Vienna', title: 'Europe/Vienna' },
                      { value: 'Europe/Vilnius', title: 'Europe/Vilnius' },
                      { value: 'Europe/Volgograd', title: 'Europe/Volgograd' },
                      { value: 'Europe/Warsaw', title: 'Europe/Warsaw' },
                      { value: 'Europe/Zagreb', title: 'Europe/Zagreb' },
                      { value: 'Europe/Zaporozhye', title: 'Europe/Zaporozhye' },
                      { value: 'Europe/Zurich', title: 'Europe/Zurich' },
                      { value: 'Hongkong', title: 'Hongkong' },
                      { value: 'Iceland', title: 'Iceland' },
                      { value: 'Israel', title: 'Israel' },
                      { value: 'Japan', title: 'Japan' },
                      { value: 'Mexico/BajaNorte', title: 'Mexico/Pacific' },
                      { value: 'Mexico/BajaSur', title: 'Mexico/Mountain' },
                      { value: 'Mexico/General', title: 'Mexico/Central' },
                      { value: 'Navajo', title: 'Navajo' },
                      { value: 'NZ', title: 'NZ' },
                      { value: 'NZ-CHAT', title: 'NZ-CHAT' },
                      { value: 'Singapore', title: 'Singapore' },
                    ]}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4} className={classes.row}>
                <Grid item xs={12}>
                  <TextArea label="Bio" name="Bio" rows="2" fullWidth optional helperText="optional" />
                </Grid>
              </Grid>

              {error?.message && <CommonErrorMessage message={error.message} />}

              <Grid container justify={mobileView && 'center'}>
                <ButtonsContainer>
                  <Button disabled={loading} type="submit">
                    {loading ? <Icon path={mdiLoading} size={1} spin={1} /> : 'Save Profile'}
                  </Button>
                </ButtonsContainer>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}

ProfilePage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    accountId: PropTypes.string.isRequired,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    birthDate: PropTypes.string,
    businessType: PropTypes.string,
    occupation: PropTypes.string,
    certification: PropTypes.string,
    customBusinessType: PropTypes.string,
    timeZoneId: PropTypes.string,
    avatarUrl: PropTypes.string,
    customerLabelPreference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    businessName: PropTypes.string,
    bio: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  editProfile: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

ProfilePage.defaultProps = {
  error: null,
};

const mapStateToProps = ({ account }) => ({
  user: account?.user || {},
  loading: account?.isLoading,
  error: account?.error,
});

const actions = {
  editProfile: userActions.editProfile,
};

export default connect(mapStateToProps, actions)(ProfilePage);
