import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PersonIcon from '@material-ui/icons/Person'

import ParticipantsModal from './ParticipantsModal'

import './Participants.scss'

const Participants = ({ members }) => {
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false)

  return (
    <>
      <div className="chat-header-participants" onClick={() => setIsParticipantsModalOpen(true)}>
        <p className="chat-header-participants__title">
          <PersonIcon /> {members.length} participants
        </p>
      </div>
      <ParticipantsModal members={members} open={isParticipantsModalOpen} onOpenChange={setIsParticipantsModalOpen} />
    </>
  )
}

Participants.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default Participants
