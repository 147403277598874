import React, { useCallback, useState } from 'react';
// import PropTypes from "prop-types";
import styled from 'styled-components';
import { useAccount } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Button from 'components/FormUI/Button';

import { PageTitle } from 'components/UI/Text/TextStyles';
import { toRem } from 'utils/styles';
import { IconUsersClass } from 'components/Icons/IconUsersClass';
import { IconUsdCircle } from 'components/Icons/IconUsdCircle';
import { IconHeart } from 'components/Icons/IconHeart';
import GettingStartedGuide from '../../../../components/App/GettingStarted';

const StyledContainer = styled.div`
  background-color: white;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.3);
  padding: 24px 24px;
`;

const StyledCardContainder = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px -16px 10px -16px;

  ${({ mobileView }) => mobileView && `flex-direction: column`}
`;

const StyledCard = styled.div`
  display: block;
  margin-left: 16px;
  margin-right: 16px;
  flex: 1 1 0;
`;

const StyledCardHeader = styled.h3`
  font-size: ${toRem(15.4)};
  font-weight: 900;
  line-height: 1.56;
  letter-spacing: 0.1px;
  text-align: center;
  color: black;
`;

const StyledCardDescription = styled.p`
  font-size: ${toRem(14)};
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: 0.22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
`;

function IntroScreen() {
  const {
    user: { firstName },
  } = useAccount();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isGetStartedOpen, setIsGetStartedOpen] = useState(false);
  const handeleCloseGetStartedGuide = useCallback(() => setIsGetStartedOpen(false), [isGetStartedOpen]);
  const handeleOpenGetStartedGuide = useCallback(() => setIsGetStartedOpen(true), [isGetStartedOpen]);
  const imagesSize = mobileView ? '130px' : '155px';

  return (
    <StyledContainer>
      <PageTitle mobileView={mobileView}>{`Welcome to Cohere, ${firstName}.`}</PageTitle>
      <PageTitle mobileView={mobileView}>We’re grateful to collaborate.</PageTitle>

      <StyledCardContainder mobileView={mobileView}>
        <StyledCard>
          <IconUsersClass
            style={{
              width: imagesSize,
              height: imagesSize,
              color: '#116582',
            }}
          />
          <StyledCardHeader>Create & Launch Services</StyledCardHeader>
          <StyledCardDescription>
            Easily create and launch LIVE services including: 1:1, group courses & memberships. Services on Cohere are
            called Contributions.
          </StyledCardDescription>
        </StyledCard>
        <StyledCard>
          <IconUsdCircle
            style={{
              width: imagesSize,
              height: imagesSize,
              color: '#116582',
            }}
          />
          <StyledCardHeader>Invite Clients & Earn Revenue</StyledCardHeader>
          <StyledCardDescription>
            Share your Contribution link to invite clients to register and purchase your services. Cohere automatically
            keeps your bookkeeping up to date.
          </StyledCardDescription>
        </StyledCard>
        <StyledCard>
          <IconHeart
            style={{
              width: imagesSize,
              height: imagesSize,
              color: '#116582',
            }}
          />
          <StyledCardHeader>Deliver Transformative Services</StyledCardHeader>
          <StyledCardDescription>
            A one-stop-shop for client scheduling, LIVE video sessions, course materials, recordings, community
            building, and more.
          </StyledCardDescription>
        </StyledCard>
      </StyledCardContainder>

      <Button mobileView={mobileView} autoWidth={mobileView} onClick={handeleOpenGetStartedGuide}>
        Get Started
      </Button>
      <GettingStartedGuide isOpen={isGetStartedOpen} closeHandler={handeleCloseGetStartedGuide} />
    </StyledContainer>
  );
}

// IntroScreen.propTypes = {};

export default IntroScreen;
