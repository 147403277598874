import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Modal from 'components/UI/Modal'
import { BodyText } from 'components/UI/Text/TextStyles'
import SessionTime from './SessionTime'
import { BottomPanel } from './BottomPanel/BottomPanel'

import './Session.scss'

const Session = ({
  number,
  session,
  session: { id, isCompleted, startTime, endTime, attachments, participantId },
  contributionId,
  renderActions,
  executeCalendarScroll,
  setRescheduleMode,
  setCurrentRescheduleSession,
  serviceProviderName,
}) => {
  const [expanded, setExpanded] = useState(true)
  const [isRescheduleModalOpen, setRescheduleModal] = useState(false)

  const handleReschedule = (e) => {
    e.stopPropagation()
    setRescheduleModal(true)
  }

  const handleSubmit = () => {
    executeCalendarScroll()
    setRescheduleModal(false)
    setCurrentRescheduleSession(session)
    setRescheduleMode(true)
  }

  const clientDisable = !moment(startTime).isAfter(moment().add(24, 'h')) || isCompleted
  const isRescheduleDisabled = serviceProviderName ? clientDisable : isCompleted

  return (
    <>
      <ExpansionPanel
        className="one-to-one-session"
        expanded={expanded}
        onChange={(_, newExpanded) => setExpanded(newExpanded)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className="course-session-summary-container">
            <div className="course-session-summary">
              <h3 className="one-to-one-session-summary__header">
                Session&nbsp;
                {number && <span className="one-to-one-session-summary__number-badge">{number}</span>}
              </h3>
              {id && <p className="one-to-one-session-summary__status">{isCompleted ? 'Completed' : 'Upcoming'}</p>}
            </div>
            {session?.id && (
              <button
                type="button"
                onClick={handleReschedule}
                disabled={isRescheduleDisabled}
                className="course-session-summary__reschedule"
              >
                Reschedule
              </button>
            )}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="one-to-one-session-details">
            <h4 className="one-to-one-session-details__header">Session Time</h4>
            <div>
              <div className="one-to-one-session-details-session-times">
                <SessionTime
                  className="one-to-one-session-details-session-times__item"
                  startTime={startTime}
                  endTime={endTime}
                  participantId={participantId}
                  session={session}
                  renderActions={renderActions}
                />
              </div>
              {id && <BottomPanel contributionId={contributionId} sessionId={id} attachments={attachments} />}
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Modal
        isOpen={isRescheduleModalOpen}
        onCancel={() => {
          setRescheduleModal(false)
        }}
        onSubmit={handleSubmit}
        title="Reschedule your session"
        hiddenCancel
        submitTitle="Reschedule Session"
        disableSubmitClick={isRescheduleDisabled}
      >
        {serviceProviderName ? (
          <BodyText>
            You can reschedule sessions 24 hours in advance. If you need to reschedule and it’s 24 hours or less prior
            to your session, please message your coach {serviceProviderName} directly. Per terms and conditions, your
            coach is not obligated to allow a reschedule with less than 24hrs notice.
            <br />
          </BodyText>
        ) : (
          <BodyText>
            To reschedule, please select a new time from your calendar. Also, please confirm with your client that the
            new time works for them prior to making the change. It will automatically update the time for all parties
            once you confirm.
            <br />
            {/* <br/>
          If you need to cancel with a client, your Client will need to cancel on their end.
          At this time, Cohere does not automate refunds and thus requires approval from your Client.
          If you think your client needs a refund, please email us at <a href="mailto:support@cohere.live">support@cohere.live</a>. */}
          </BodyText>
        )}
      </Modal>
    </>
  )
}

Session.defaultProps = {
  session: {},
  renderActions: null,
  serviceProviderName: null,
}

Session.propTypes = {
  number: PropTypes.number.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    isCompleted: PropTypes.bool,
    participantId: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  contributionId: PropTypes.string.isRequired,
  renderActions: PropTypes.func,
  executeCalendarScroll: PropTypes.func.isRequired,
  setRescheduleMode: PropTypes.func.isRequired,
  setCurrentRescheduleSession: PropTypes.func.isRequired,
  serviceProviderName: PropTypes.string,
}

export default Session
