import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';

import { useAccount, useShallowEqualSelector } from 'hooks';

import './SessionTime.scss';
import { UserRoles } from 'helpers/constants';

const SessionTime = ({ className, startTime, endTime, participantId, session, booked, renderActions }) => {
  const { currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;

  const { firstName, lastName } = useShallowEqualSelector(
    state =>
      (state?.contributions?.activeContribution?.participants || []).find(client => client.id === participantId) || {},
  );

  const startTimeMoment = moment(startTime);
  const endTimeMoment = moment(endTime);
  const duration = humanizeDuration(endTimeMoment.diff(startTimeMoment));

  return (
    <div className={classNames(className, 'one-to-one-session-time')}>
      {startTime && (
        <div>
          <p className="one-to-one-session-time__label one-to-one-session-time__label--type--date">Date</p>
          <p className="one-to-one-session-time__value one-to-one-session-time__value--type--date">
            {startTimeMoment.format('MMM Do YYYY')}
          </p>
        </div>
      )}
      {startTime && (
        <div>
          <p className="one-to-one-session-time__label one-to-one-session-time__label--type--start-time">Start Time</p>
          <p className="one-to-one-session-time__value one-to-one-session-time__value--type--start-time">
            {startTimeMoment.format('h:mm a')}
          </p>
        </div>
      )}
      {endTime && (
        <div>
          <p className="one-to-one-session-time__label one-to-one-session-time__label--type--end-time">End Time</p>
          <p className="one-to-one-session-time__value one-to-one-session-time__value--type--end-time">
            {endTimeMoment.format('h:mm a')}
          </p>
        </div>
      )}
      {startTime && (
        <div>
          <p className="one-to-one-session-time__label one-to-one-session-time__label--type--duration">Duration</p>
          <p className="one-to-one-session-time__value one-to-one-session-time__value--type--duration">{duration}</p>
        </div>
      )}
      {isCoach ? (
        <div>
          <p className="one-to-one-session-time__label one-to-one-session-time__label--type--booked">Client</p>
          <p className="one-to-one-session-time__value one-to-one-session-time__value--type--booked">
            {`${firstName} ${lastName}`}
          </p>
        </div>
      ) : (
        booked && (
          <div>
            <p className="one-to-one-session-time__label one-to-one-session-time__label--type--booked">Booked</p>
            <p className="one-to-one-session-time__value one-to-one-session-time__value--type--booked">
              {booked ? 'Yes' : 'No'}
            </p>
          </div>
        )
      )}
      <div className="one-to-one-session-time__actions">{renderActions(session)}</div>
    </div>
  );
};

SessionTime.defaultProps = {
  startTime: '',
  endTime: '',
  participantId: '',
  booked: false,
  session: {},
};

SessionTime.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  className: PropTypes.string.isRequired,
  participantId: PropTypes.string,
  booked: PropTypes.bool,
  renderActions: PropTypes.func.isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    isCompleted: PropTypes.bool,
    participantId: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default SessionTime;
